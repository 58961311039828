import useLayoutStore from './useLayoutStore';
import useLayoutTypes from './useLayoutTypes';

const MIN_CONTAINER_WIDTH = 1190;

const useChartLabels = () => {
  const { isWaveformLayout, isWaterfallLayout } = useLayoutTypes();

  const containerWidth = useLayoutStore((state) => state.containerWidth);

  return isWaveformLayout || isWaterfallLayout || containerWidth >= MIN_CONTAINER_WIDTH;
};

export default useChartLabels;
