import classnames from "classnames";

import {
  useShortTrendRawDataPointsForOneDataPointAsZip,
  useMaximize,
  useUniqueId,
} from "../../../../Hooks";
import useSelectedDataPointsStore from "../../../analysis-raw-data/hooks/useSelectedDataPointsStore";
import type { WaveformChartSectionProps } from "../../../analysis-raw-data/types";

import useChartTimestampStore from "../../hooks/useChartTimestampStore";
import ChartSectionHeader from "../ChartSectionHeader";
import { PlotSpinner, RawDataPlotNoData } from "../PlotPlaceholders";
import WaveformChart from "../WaveformChart";

const WaveformChartSection = ({
  signal,
  dataPoint,
  dataPointTimestamp,
}: WaveformChartSectionProps) => {
  const selectedDataPoints = useSelectedDataPointsStore(
    (store) => store.selectedDataPoints
  );

  const uniqueId = useUniqueId();

  const {
    data = { data: [] },
    isFetching,
    isLoading,
  } = useShortTrendRawDataPointsForOneDataPointAsZip(
    {
      signalId: signal.id as string,
      dataPointId: dataPoint,
    },
    {
      enabled: !!selectedDataPoints.length && !!dataPoint,
      // cacheTime: 0,
    }
  );

  const isDataLoading = isFetching && isLoading;

  const {
    isChartMaximized,
    isChartHidden,
    maximizeAction,
    maximizeIcon,
    maximizeLabel,
  } = useMaximize({
    id: uniqueId,
    page: "raw-data",
  });

  const { findTimestamp } = useChartTimestampStore((store) => ({
    timestamps: store.timestamps,
    findTimestamp: store.findTimestamp,
  }));

  const timestamp = findTimestamp(signal.id);

  return (
    <div
      className={classnames("waveform-chart-section", {
        "raw-data-chart-section--hidden": isChartHidden,
        "raw-data-chart-section--maximized": isChartMaximized,
      })}
    >
      <ChartSectionHeader
        id={uniqueId}
        title="Waveform"
        {...{
          maximizeAction,
          maximizeIcon,
          maximizeLabel,
          timestamp: dataPointTimestamp || timestamp?.timestamp,
        }}
      />

      {isDataLoading && <PlotSpinner />}
      {!isDataLoading && data?.data?.length === 0 && (
        <RawDataPlotNoData signal={signal} />
      )}
      {!isDataLoading && data?.data?.length > 0 && (
        <WaveformChart
          data={data}
          exportId={uniqueId}
          signal={signal}
          style={{ height: "calc(100% - 61px - 53px)" }}
        />
      )}
    </div>
  );
};

export default WaveformChartSection;
