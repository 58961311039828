import type { IStackProps } from "@fluentui/react";
import { Stack } from "@fluentui/react";
import type { CSSProperties } from "react";

import NoDataIcon from "../../../assets/svg/NoDataIcon";

const containerStyle: CSSProperties = {
  height: "100%",
  minHeight: "10em",
};

const iconStyles = { width: "100%", height: 24, fill: "#2c529f" };

const labelStyles = {
  margin: "8px 0px 0px",
  fontSize: 14,
  fontWeight: 600,
  color: "#3B3A39",
};

export type NoDataProps = IStackProps & {
  text?: string;
};

const NoData = ({ text, ...rest }: NoDataProps) => (
  <Stack
    {...rest}
    verticalAlign="center"
    horizontalAlign="center"
    style={{ ...containerStyle, ...rest.style }}
  >
    <NoDataIcon viewBox="0 0 16 18" style={iconStyles} />
    <div style={labelStyles}>{text ?? "No Data"}</div>
  </Stack>
);

export default NoData;
