import orderBy from 'lodash-es/orderBy';
import { DriveLoadEnriched, DriveLoadSignal, ResponseSimplifiedSignalWithConditionKey } from '../../../types';

export interface EnrichedDriveLoadSignal
  extends DriveLoadSignal,
    ResponseSimplifiedSignalWithConditionKey{}

export const prepareTorqueSignals = (
  signals: ResponseSimplifiedSignalWithConditionKey[] = [],
  torqueSignals: DriveLoadSignal[] = [],
): EnrichedDriveLoadSignal[] => {
  if (signals.length === 0 || torqueSignals.length === 0) return [];

  const orderedTorqueSignals: EnrichedDriveLoadSignal[] = orderBy(
    torqueSignals,
    'index',
  ) as EnrichedDriveLoadSignal[];

  orderedTorqueSignals.forEach(({ signalId, index }: DriveLoadSignal, idx: number) => {
    const signalDetails: ResponseSimplifiedSignalWithConditionKey = signals.filter(
      ({ id }) => id === signalId,
    )[0];

    if (signalDetails) {
      orderedTorqueSignals[idx] = {
        signalId,
        index,
        ...signalDetails,
      };
    }
  });

  return orderedTorqueSignals;
};

export interface DriveLoadSignalsMetaData {
  averageSignal: ResponseSimplifiedSignalWithConditionKey;
  dynamicSignal: ResponseSimplifiedSignalWithConditionKey;
}

export const prepareColorChartSignals = (
  signals: ResponseSimplifiedSignalWithConditionKey[] = [],
  driveLoad: DriveLoadEnriched,
): DriveLoadSignalsMetaData => {
  const averageSignalId = driveLoad?.averageLoadSignalId;
  const dynamicSignalId = driveLoad?.dynamicLoadSignalId;

  if (signals.length === 0 || !averageSignalId || !dynamicSignalId)
    return {} as DriveLoadSignalsMetaData;

  const averageSignal = signals.find(
    ({ id }: ResponseSimplifiedSignalWithConditionKey) => id === averageSignalId,
  );
  const dynamicSignal = signals.find(
    ({ id }: ResponseSimplifiedSignalWithConditionKey) => id === dynamicSignalId,
  );

  if (!averageSignal || !dynamicSignal) return {} as DriveLoadSignalsMetaData;

  return { averageSignal, dynamicSignal } as DriveLoadSignalsMetaData;
};
