import type { SVGProps } from 'react';
import React from 'react';

const ImageIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='fluent:image-20-regular'>
        <path
          id='Vector'
          d='M14.25 7.5C14.25 7.69698 14.2112 7.89204 14.1358 8.07403C14.0604 8.25601 13.9499 8.42137 13.8107 8.56066C13.6714 8.69995 13.506 8.81044 13.324 8.88582C13.142 8.9612 12.947 9 12.75 9C12.553 9 12.358 8.9612 12.176 8.88582C11.994 8.81044 11.8286 8.69995 11.6893 8.56066C11.5501 8.42137 11.4396 8.25601 11.3642 8.07403C11.2888 7.89204 11.25 7.69698 11.25 7.5C11.25 7.10218 11.408 6.72064 11.6893 6.43934C11.9706 6.15804 12.3522 6 12.75 6C13.1478 6 13.5294 6.15804 13.8107 6.43934C14.092 6.72064 14.25 7.10218 14.25 7.5ZM13.25 7.5C13.25 7.36739 13.1973 7.24022 13.1036 7.14645C13.0098 7.05268 12.8826 7 12.75 7C12.6174 7 12.4902 7.05268 12.3964 7.14645C12.3027 7.24022 12.25 7.36739 12.25 7.5C12.25 7.63261 12.3027 7.75979 12.3964 7.85355C12.4902 7.94732 12.6174 8 12.75 8C12.8826 8 13.0098 7.94732 13.1036 7.85355C13.1973 7.75979 13.25 7.63261 13.25 7.5ZM3.25 6C3.25 5.20435 3.56607 4.44129 4.12868 3.87868C4.69129 3.31607 5.45435 3 6.25 3H14.25C15.0457 3 15.8087 3.31607 16.3713 3.87868C16.9339 4.44129 17.25 5.20435 17.25 6V14C17.25 14.7957 16.9339 15.5587 16.3713 16.1213C15.8087 16.6839 15.0457 17 14.25 17H6.25C5.45435 17 4.69129 16.6839 4.12868 16.1213C3.56607 15.5587 3.25 14.7957 3.25 14V6ZM6.25 4C5.71957 4 5.21086 4.21071 4.83579 4.58579C4.46071 4.96086 4.25 5.46957 4.25 6V14C4.25 14.373 4.352 14.722 4.53 15.02L9.199 10.432C9.47948 10.1566 9.85689 10.0022 10.25 10.0022C10.6431 10.0022 11.0205 10.1566 11.301 10.432L15.971 15.02C16.1543 14.7114 16.2507 14.3589 16.25 14V6C16.25 5.46957 16.0393 4.96086 15.6642 4.58579C15.2891 4.21071 14.7804 4 14.25 4H6.25ZM6.25 16H14.25C14.62 16 14.965 15.9 15.262 15.726L10.6 11.146C10.5065 11.0544 10.3809 11.0031 10.25 11.0031C10.1191 11.0031 9.99346 11.0544 9.9 11.146L5.238 15.726C5.54478 15.9062 5.89424 16.0008 6.25 16Z'
          fill='#2C529F'
        />
      </g>
    </svg>
  );
};

export default ImageIcon;
