import { PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useBoolean } from "../../../../../Hooks";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { isEmpty, isEqual } from "lodash-es";
import { useEffect, useState } from "react";

import { getApiClient } from "../../../../../modules/core/apiClient/useApiStore";
import {
  TIMED_SETTINGS,
  useSensorMultipleTriggersUpdate,
  useSensorTriggers,
  useSensorTriggersUpdate,
} from "../../../hooks/useSensorRequests";
import { WS_SIDEBAR_QUERY_KEY } from "../../../hooks/useWSSidebarData";
import useSensorStore from "../hooks/useSensorStore";
import AutomaticTriggerSettings from "./AutomaticTriggerSettings";
import { defaultTriggersValues, triggersSchema } from "./config";
import ManualTriggerSettings from "./ManualTriggerSettings";
import { useZodForm } from "../../../../common/Form";
import { notification } from "../../../../common/Notification";
import SensorSelectDialog from "../SensorSelectDialog";
import {
  ProjectSelectable,
  ResponseWirelessOverview,
  WirelessOverviewCorporation,
  WirelessOverviewMachine,
  WirelessOverviewProject,
} from "../types";

export const mapToProjectSelectableByMachineId = (
  corporations: WirelessOverviewCorporation[],
  machineId: string
): ProjectSelectable | null => {
  // Variables para almacenar lo que encontremos
  let targetProject: WirelessOverviewProject | undefined;
  let targetMachine: WirelessOverviewMachine | undefined;

  // Buscar la máquina y su proyecto correspondiente en todas las corporaciones
  corporations.some((corporation) =>
    corporation.companies?.some((company) =>
      company.projects?.some((project) => {
        targetMachine = project.machines?.find(
          (machine) => machine.id === machineId
        );
        if (targetMachine) {
          targetProject = project;
          return true;
        }
        return false;
      })
    )
  );

  if (!targetProject || !targetMachine) {
    return null;
  }

  return {
    id: targetProject.id,
    name: targetProject.name || "Unnamed Project",
    machines:
      targetProject.machines?.map((machine) => ({
        id: machine.id,
        name: machine.name || "Unnamed Machine",
        sensorNodes:
          machine.sensorNodes?.map((sensor) => ({
            id: sensor.id,
            sensorNodeId: sensor.sensorNodeId || "Unknown Sensor ID",
          })) || [],
      })) || [],
  };
};

const TriggersTab = ({ sensor }: any) => {
  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] =
    useBoolean(false);
  const { data: overview = {} as ResponseWirelessOverview } = useQuery(
    [WS_SIDEBAR_QUERY_KEY],
    () =>
      getApiClient()
        .get(`/meta/read/internal/v1/wireless/overview`)
        .then(({ data }) => data as ResponseWirelessOverview)
  );
  const [options] = useState(
    mapToProjectSelectableByMachineId(
      overview.corporations || [],
      sensor.machine.id
    )
  );

  const { updateTriggers } = useSensorStore((store: any) => ({
    updateTriggers: store.updateTriggers,
  }));

  const form = useZodForm({
    schema: triggersSchema,
    defaultValues: defaultTriggersValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
    reset,
  } = form;
  const { sensorNodeId } = sensor;
  const { updateSensorTriggers, isLoading: isUpdating } =
    useSensorTriggersUpdate({
      sensorNodeId,
    });
  const {
    data: preparedData,
    isLoading,
    syncUpdatedData,
    isError,
    refetch,
  } = useSensorTriggers({
    sensorNodeId,
    options: {
      staleTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  });

  const { updateMultipleSensorTriggers, isLoading: loadingMultiple } =
    useSensorMultipleTriggersUpdate();

  useEffect(() => {
    if (!isLoading && preparedData && preparedData?.length > 0) {
      preparedData.forEach((item: any, idx: any) => {
        setValue(`triggers.${idx}.enabled`, item.enabled);
        setValue(
          `triggers.${idx}.intervalInMinutes`,
          `${item.intervalInMinutes || ""}`
        );
        setValue(
          `triggers.${idx}.enabledMeasurementSettings`,
          item.enabledMeasurementSettings || []
        );
      });
    }

    return () => {
      reset(
        {
          manualKey: "1",
          triggers: isEmpty(preparedData)
            ? [...defaultTriggersValues.triggers]
            : [...(preparedData as any)],
        },
        { keepDirty: false }
      );
    };
  }, [preparedData, isLoading]);

  useEffect(() => {
    updateTriggers({
      sensorNodeId,
      updates: { loading: isLoading || isUpdating, refetch },
    });
  }, [isLoading, isUpdating, refetch, sensorNodeId]);

  if (!preparedData) {
    return (
      <Spinner
        size={SpinnerSize.large}
        label="Loading automatic triggers data"
      />
    );
  }

  if (!preparedData && !isError) {
    return (
      <Spinner
        size={SpinnerSize.large}
        label="Loading automatic triggers data"
      />
    );
  }

  const handleMultipleSensorsSave = (selectedSensors: string[]) => {
    handleSubmit((data) => {
      if (loadingMultiple) return;
      const preparedDataToSend: any = {};
      data.triggers.forEach((item, idx) => {
        preparedDataToSend[TIMED_SETTINGS[idx]] = {
          ...item,
          shouldBeUpdated: !isEqual(item, preparedData[idx]),
        };
      });
      updateMultipleSensorTriggers({
        sensorNodeIds: selectedSensors,
        data: preparedDataToSend,
      }).then(
        () => {
          notification.success(`The triggers changes have been saved`);
        },
        () => {
          notification.error(`The triggers changes could not be saved`);
        }
      );
      hidePopup();
    })();
  };

  const onSubmit = handleSubmit((data) => {
    if (isLoading || isUpdating) return;
    const preparedDataToSend: any = {};
    data.triggers.forEach((item, idx) => {
      preparedDataToSend[TIMED_SETTINGS[idx]] = {
        ...item,
        shouldBeUpdated: !isEqual(item, preparedData[idx]),
      };
    });

    updateSensorTriggers({ sensorNodeId, data: preparedDataToSend })
      .then(
        () => {
          syncUpdatedData(data.triggers);
          notification.success(
            `The triggers changes have been saved on ${sensorNodeId}`
          );
        },
        () => {
          notification.error(
            `The triggers changes could not be saved on ${sensorNodeId}`
          );
        }
      )
      .finally(() => refetch());
  });

  const unsavedChanges =
    !isLoading && isDirty && !isEqual(preparedData, watch().triggers);

  return (
    <>
      <div
        className={classNames("tab-wrapper", {
          loading: isLoading || isUpdating,
        })}
      >
        {isError && (
          <div className="sensor-alert-label" style={{ marginBottom: 20 }}>
            Could not fetch triggers data
          </div>
        )}
        {(isLoading || isUpdating) && (
          <Spinner
            size={SpinnerSize.large}
            label={
              isLoading
                ? "Loading automatic triggers data"
                : "Saving automatic triggers data"
            }
          />
        )}
        {unsavedChanges && (
          <div className="sensor-alert-label" style={{ marginBottom: 20 }}>
            Please save the changes before leaving the triggers tab
          </div>
        )}
        <form onSubmit={onSubmit}>
          <AutomaticTriggerSettings form={form} />
        </form>
        <ManualTriggerSettings sensor={sensor} />
        <div className="sensor-save-button">
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <PrimaryButton
              text="Save to sensor"
              disabled={isUpdating || isLoading}
              onClick={onSubmit}
            />
            <PrimaryButton
              text="Select sensors for saving to"
              onClick={showPopup}
            />
          </Stack>
        </div>
      </div>
      {options && (
        <SensorSelectDialog
          isVisible={isPopupVisible}
          options={options}
          isLoading={loadingMultiple}
          onDismiss={hidePopup}
          onSave={handleMultipleSensorsSave}
        />
      )}
    </>
  );
};

export default TriggersTab;
