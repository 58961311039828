import { Label } from "@fluentui/react";
import { format } from "date-fns";

import { setMaxNumberDecimals } from "../../../../../modules/common/components/InteractiveColorBar/methods";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";

import { COLOR_DARK_BLUE, COLOR_GREEN, COLOR_ORANGE } from "./config";
import PerformanceStatus from "./PerformanceStatus";

type PerformanceIndex = {
  id: string;
  color: string;
  title: string;
};

const PERFORMANCE_INDICES = [
  {
    id: "currentPerformance",
    title: "Current",
    color: COLOR_DARK_BLUE,
  },
  {
    id: "bestPerformance",
    title: "Best",
    color: COLOR_GREEN,
  },
  {
    id: "worstPerformance",
    title: "Worst",
    color: COLOR_ORANGE,
  },
];

const PerformanceIndices = (props: any) => {
  function renderPerformanceIndex({ id, title, color }: PerformanceIndex) {
    if (!props[id]) {
      return null;
    }
    const { timeStamp, value } = props[id];
    const date = format(new Date(timeStamp), "dd.MM.Y");
    const number = setMaxNumberDecimals(value, 1);

    return (
      <div key={title} className="performance-index">
        <Label>
          {title}
          <span className="performance-index__label-date">{date}</span>
        </Label>
        <div className="performance-index__data" style={{ background: color }}>
          {number}
        </div>
      </div>
    );
  }
  return (
    <div className="load-and-performance__indices">
      {!!props?.isLoading && <Spin className="m-a" />}
      {props?.noData && <NoData className="m-a" />}
      {!props?.isLoading ? (
        <>
          {PERFORMANCE_INDICES.map(renderPerformanceIndex)}
          <PerformanceStatus progress={props?.currentPerformance?.value || 0} />
        </>
      ) : null}
    </div>
  );
};

export default PerformanceIndices;
