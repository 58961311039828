import classNames from "classnames";

import { STATISTICS_TAB_1, STATISTICS_TAB_2 } from "./config";
import usePerformanceStore from "../../../hooks/usePerformanceStore";

const StatisticsTabs = () => {
  const { statisticsActiveTab, updateStore } = usePerformanceStore(
    (store: any) => ({
      statisticsActiveTab: store.statisticsActiveTab,
      updateStore: store.updateStore,
    })
  );

  return (
    <div className="events-tabs">
      <div
        className={classNames("events-tabs__tab", {
          active: statisticsActiveTab === STATISTICS_TAB_1,
        })}
      >
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            updateStore({ statisticsActiveTab: STATISTICS_TAB_1 });
          }}
        >
          {STATISTICS_TAB_1}
        </a>
      </div>
      <div
        className={classNames("events-tabs__tab", {
          active: statisticsActiveTab === STATISTICS_TAB_2,
        })}
      >
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            updateStore({ statisticsActiveTab: STATISTICS_TAB_2 });
          }}
        >
          {STATISTICS_TAB_2}
        </a>
      </div>
    </div>
  );
};

export default StatisticsTabs;
