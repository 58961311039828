import "./style.scss";

import { format } from "date-fns";

import useWaterfallStore from "../../hooks/useWaterfallStore";

const WaterfallChartTooltip = () => {
  const { chartHoverParams } = useWaterfallStore((state) => ({
    chartHoverParams: state.chartHoverParams,
  }));

  const { event, points } = chartHoverParams || {};
  const point = points?.length > 0 ? points[0] : null;

  if (!point || !event) {
    return null;
  }

  return (
    <div
      className="waterfall-tooltip"
      style={{
        left: event.screenX > 0 ? event?.x + 15 : event?.x + 10,
        top: event?.y,
      }}
    >
      <div className="waterfall-tooltip__row">
        <span>[Hz]:</span>
        <span>{point.x.toFixed(4)}</span>
      </div>
      {point.y && (
        <div className="waterfall-tooltip__row">
          <span>[t]:</span>
          <span>{format(new Date(point.y), "dd MMM yyyy HH:mm")}</span>
        </div>
      )}
      {/* <div className='waterfall-tooltip__row'>
        <span>[z]:</span>
        <span>{point.z}</span>
      </div> */}
    </div>
  );
};

export default WaterfallChartTooltip;
