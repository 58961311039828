import type { ReactElement } from 'react';
import * as ReactRouterDOM from 'react-router-dom';
import { z } from 'zod';

import { Company } from './components/Company';
import { Corporation } from './components/Corporation';
import { Machine } from './components/Machine';
import { Project } from './components/Project';

export enum DashboardType {
  Corporation = 'corporation',
  Company = 'company',
  Project = 'project',
  Machine = 'machine',
}

const schema = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(DashboardType),
});

const dashboards: Record<DashboardType, ReactElement> = {
  corporation: <Corporation />,
  company: <Company />,
  project: <Project />,
  machine: <Machine />,
};

export const Page = () => {
  const { id, type } = ReactRouterDOM.useParams();

  if (!schema.safeParse({ id, type }).success) {
    return <ReactRouterDOM.Navigate replace to='/error' />;
  }

  return dashboards[type as DashboardType];
};
