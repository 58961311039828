import { useImage } from "../../../Hooks";

import ImagePlaceholder from "../ImagePlaceholder";
import { Spin } from "../Spin";

type Props = {
  id?: string | null;
};

type NativeAttrs = Omit<React.ImgHTMLAttributes<unknown>, keyof Props | "src">;

export type ImageProps = Props & NativeAttrs;

const Image = ({ id, ...rest }: ImageProps) => {
  const { url, isLoading } = useImage(id as string);

  if (isLoading)
    return <Spin text="Loading image..." style={{ height: "90%" }} />;

  if (!url) return <ImagePlaceholder />;

  return <img src={url} alt="MachineCVImage" {...rest} />;
};

export default Image;
