import type {
  IColumn,
  IDetailsListProps,
  IDetailsListStyles,
} from "@fluentui/react";
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  mergeStyleSets,
  Selection,
  SelectionMode,
} from "@fluentui/react";
import classnames from "classnames";
import orderBy from "lodash-es/orderBy";
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import { filter } from "../../../common/Table/filter";
import { useColumns } from "../../../common/Table/useColumns";
import { useSort } from "../../../common/Table/useSort";

export type Column = IColumn & {
  isSortable?: boolean;
  isExportable?: boolean;
};

export type Filter = {
  key: string;
  search: string;
  operator: "or" | "and";
};

type FiltersAppliedProps = {
  list: any[];
  isFiltered: boolean;
  selectedItems: any[];
};

export type TableProps = Omit<
  IDetailsListProps,
  | "items"
  | "columns"
  | "selectionMode"
  | "layoutMode"
  | "constrainMode"
  | "onRenderDetailsHeader"
> & {
  persistOpts: {
    key: string;
    version: number;
  };
  columns: Column[];
  items: any[];
  filters?: Filter[];
  className?: string;
  onSelectionChanged?: (selected: any[], selectableItems: any[]) => void;
  onFiltersApplied?: (p: FiltersAppliedProps) => void;
  isGroupedEverything?: boolean;
};

const mainClassNames = mergeStyleSets({
  row: {
    flex: "0 0 auto",
  },
  focusZone: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    minWidth: "100%",
  },
  selectionZone: {
    height: "100%",
    overflow: "hidden",
  },
});

const gridStyles: Partial<IDetailsListStyles> = {
  root: {
    height: "100%",
    selectors: {
      "& [role=grid]": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        height: "100%",
      },
    },
  },
  headerWrapper: {
    flex: "0 0 auto",
    maxWidth: "100%",
  },
  contentWrapper: {
    flex: "1 1 auto",
    overflow: "hidden",
    maxWidth: "100%",
    maxHeight: "100%",
  },
};

const focusZoneProps = {
  className: mainClassNames.focusZone,
  "data-is-scrollable": "true",
} as React.HTMLAttributes<HTMLElement>;

export type TableRef = {
  clearSelection: () => void;
  getSelectedItems: () => any[];
  selectItems: (itemsToSelect: any[]) => void;
  deselectItems: (itemsToDeselect: any[]) => void;
};

const CustomTable = forwardRef<TableRef, TableProps>(function CustomTable(
  {
    items,
    persistOpts,
    columns: _columns,
    filters,
    className,
    onSelectionChanged,
    onFiltersApplied,
    isGroupedEverything = false,
    ...rest
  },
  ref
) {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  // === Selection ===
  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const newSelection = selection.getSelection();
          setSelectedItems(newSelection);
          if (onSelectionChanged) {
            onSelectionChanged(newSelection, selection.getItems());
          }
        },
      }),
    [onSelectionChanged]
  );

  useImperativeHandle(
    ref,
    () => ({
      clearSelection: () => {
        selection.setAllSelected(false);
      },
      getSelectedItems: () => {
        return selection.getSelection();
      },
      selectItems: (itemsToSelect: any[]) => {
        const currentItems = selection.getItems();
        const itemsAux = itemsToSelect.filter((item) =>
          currentItems.some((i) => i.key === item.key)
        );

        itemsAux.forEach((item) => {
          const index = currentItems.findIndex((i) => i.key === item.key);
          if (index !== -1) selection.setIndexSelected(index, true, false);
        });
      },
      deselectItems: (itemsToDeselect: any[]) => {
        itemsToDeselect.forEach((item) => {
          const index = items.findIndex((i) => i === item);
          if (index !== -1) selection.setIndexSelected(index, false, false);
        });
      },
    }),
    [selection]
  );

  // === Columns Options (Order & Resize)  ===
  const {
    columnsOpts,
    columnReorderOpts,
    onColumnResize,
    shouldOmitColumnsOpts,
  } = useColumns({
    columns: _columns,
    persistOpts,
  });

  // === Sort ===
  const { sorter, columns } = useSort({
    columns: _columns,
    columnsOpts,
    selection,
    shouldOmitColumnsOpts,
    persistOpts,
  });

  const sortedItems = useMemo(() => {
    if (sorter.key.length === 0) {
      return items;
    }
    return orderBy(items, sorter.key, sorter.order);
  }, [items, sorter]);

  // === Filter ===
  const filteredItems = useMemo(() => {
    if (!filters || filters.length === 0) {
      onFiltersApplied &&
        onFiltersApplied({
          list: sortedItems,
          isFiltered: false,
          selectedItems: selection.getSelection(),
        });
      return sortedItems;
    }

    const filteredResult = filter(sortedItems, filters);
    onFiltersApplied &&
      onFiltersApplied({
        list: filteredResult,
        isFiltered: true,
        selectedItems: selection.getSelection(),
      });
    return filteredResult;
  }, [sortedItems, filters]);

  return (
    <DetailsList
      items={filteredItems}
      columns={columns}
      setKey="set"
      layoutMode={DetailsListLayoutMode.justified}
      constrainMode={ConstrainMode.horizontalConstrained}
      selectionMode={SelectionMode.multiple}
      selection={selection}
      columnReorderOptions={columnReorderOpts}
      className={classnames("custom-table", className, {
        "disable-select-all-button": isGroupedEverything,
        "disable-unchecked-values":
          isGroupedEverything && selectedItems.length >= 8,
      })}
      styles={mergeStyleSets(gridStyles, {
        root: { display: filteredItems.length > 0 ? "block" : "none" },
      })}
      focusZoneProps={focusZoneProps}
      selectionZoneProps={{
        className: mainClassNames.selectionZone,
      }}
      isSelectedOnFocus={false}
      selectionPreservedOnEmptyClick={false}
      onColumnResize={onColumnResize}
      {...rest}
    />
  );
});

export default CustomTable;
