import { useMemo } from "react";

import { useMachineList } from "../../../Hooks";
import type { MachineListItem, ResponseSimplifiedSignal } from "../../../types";

const useSignalMetaData = (signal: ResponseSimplifiedSignal) => {
  const { data } = useMachineList({ enabled: false });

  const signalMetaData = useMemo(() => {
    const machine = data?.find(
      ({ id }) => id === signal.machineId
    ) as MachineListItem;

    return {
      signal,
      machine,
      project: machine?.project,
      company: machine?.company,
      corporation: machine?.corporation,
    };
  }, [data]);

  return signalMetaData;
};

export default useSignalMetaData;
