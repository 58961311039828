import { useMemo, useState, useEffect } from "react";

import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../../../common/Form";
import BaseDialog from "../../../common/Dialog";

import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import {
  maxLengthType1,
  maxLengthType2,
  textStyle,
} from "../../../../schema/Constants";
import {
  CreateFrequencyCatalogueGroup,
  FrequencyCatalogueGroupPageDetails,
  UpdateFrequencyCatalogueGroup,
} from "./models";
import {
  addFrequencyCatalogueGroup,
  deleteFrequencyCatalogueGroup,
  editFrequencyCatalogueGroup,
} from "./api";

const getSchema = (context: "add" | "edit" | "delete") =>
  z.object({
    groupName: z
      .string()
      .min(1, { message: "This field is required" })
      .max(maxLengthType1, {
        message: `Name must contain at most ${maxLengthType2} character(s)`,
      }),
    description: z
      .string()
      .max(maxLengthType1, {
        message: `Name must contain at most ${maxLengthType1} character(s)`,
      })
      .optional(),
  });
function modifyFieldsBasedOnContext(
  context: "add" | "edit" | "delete"
): FormItemProps[] {
  const fields: FormItemProps[] = [
    {
      name: "groupName",
      type: FormItemType.TextField,
      groupProps: { label: "Group Name" },
      disabled: context === "edit",
    },
    {
      name: "description",
      type: FormItemType.TextField,
      groupProps: { label: "Description" },
    },
  ];

  return fields;
}

type AddOrEditDialogProps = IDialogProps & {
  machineId: string;
  data: undefined | string | FrequencyCatalogueGroupPageDetails;
  show: boolean;
  onSuccess: (hasError: boolean, context: "add" | "edit" | "delete") => void;
  onClose: () => void;
  context: "add" | "edit" | "delete";
};

export const CUDDialogGroup = ({
  machineId,
  data,
  show,
  onSuccess,
  onClose,
  context,
  ...rest
}: AddOrEditDialogProps) => {
  const schema = useMemo(() => getSchema(context), [context]);

  const [isLoading, setLoading] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && data && typeof data !== "string"
      ? {
          defaultValues: {
            groupName: data.name,
            description: data.description,
          },
        }
      : {
          defaultValues: {
            groupName: data as string,
          },
        }),
  });

  useEffect(() => {
    data && typeof data !== "string"
      ? reset({
          groupName: data?.name,
          description: data?.description || "",
        })
      : typeof data === "string"
        ? reset({
            groupName: data,
            description: "",
          })
        : reset({});
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend:
      | CreateFrequencyCatalogueGroup
      | UpdateFrequencyCatalogueGroup =
      context === "add"
        ? ({
            groupName: formData.groupName,
            description: formData.description ?? "",
          } as CreateFrequencyCatalogueGroup)
        : ({
            description: formData.description ?? "",
          } as UpdateFrequencyCatalogueGroup);

    switch (context) {
      case "add":
        await addFrequencyCatalogueGroup(
          machineId,
          toSend as CreateFrequencyCatalogueGroup
        ).then((response) => onSuccess("status" in response, context));
        break;
      case "edit":
        await editFrequencyCatalogueGroup(
          typeof data !== "string" && "id" in data ? data.id : "",
          machineId,
          typeof data !== "string" && "id" in data && { ...toSend }
        ).then((response) => onSuccess("status" in response, context));
        break;
      case "delete":
        console.log(data);
        await deleteFrequencyCatalogueGroup(
          typeof data === "string" && data,
          machineId
        ).then((response) => onSuccess("status" in response, context));
        break;
    }

    handleClose();
  });

  const handleDelete = async () => {
    setLoading(true);

    await deleteFrequencyCatalogueGroup(
      typeof data === "string" && data,
      machineId
    ).then((response) => onSuccess("status" in response, context));

    handleClose();
  };

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {context && (
        <BaseDialog
          {...rest}
          hidden={!show}
          dialogContentProps={{
            type: DialogType.normal,
            title:
              context.charAt(0).toUpperCase() +
              context.slice(1) +
              " Frequency Catalogue Group",
            closeButtonAriaLabel: "Close",
            onDismiss: handleClose,
          }}
        >
          {["add", "edit"].includes(context) ? (
            <form onSubmit={onSubmit}>
              {renderFormItems(modifyFieldsBasedOnContext(context), {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}
              <DialogFooter>
                <PrimaryButton
                  type="submit"
                  text="Save Changes"
                  disabled={isLoading || !isValid}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton
                  styles={{
                    root: { border: "unset", background: "transparent" },
                  }}
                  text="Cancel"
                  onClick={handleClose}
                />
              </DialogFooter>
            </form>
          ) : ["delete"].includes(context) ? (
            <>
              <div style={textStyle}>
                You are about to delete selected Frequency Catalogue Group.
              </div>

              <DialogFooter>
                <PrimaryButton
                  text="Delete"
                  disabled={isLoading}
                  onClick={handleDelete}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton text="Close" onClick={handleClose} />
              </DialogFooter>
            </>
          ) : (
            <></>
          )}
        </BaseDialog>
      )}
    </>
  );
};
