import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { apiService } from '../../modules/common';
import type { DashboardsOverview } from '../../types';

const useDashboardsOverview = (
  options?:
    | (Omit<
        UseQueryOptions<DashboardsOverview, unknown, DashboardsOverview, string[]>,
        'initialData' | 'queryFn' | 'queryKey'
      > & { initialData?: (() => undefined) | undefined })
    | undefined,
) => useQuery(['dashboards-overview'], apiService.machineCV.getDashboardsOverview, options);

export default useDashboardsOverview;
