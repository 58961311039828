import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

type Harmonics = {
  type: string;
  x0: number | string;
  x1: number | string;
  y0: number;
  y1: number;
  xref: string;
  yref: string;
  label: {
    yanchor?: string;
    textangle?: number | undefined;
    textposition: string;
    texttemplate: string;
    font: {
      size: number;
      color: string;
    };
  };
  line: {
    color: string;
    width: number;
    dash?: string;
  };
};

interface HarmonicsStore {
  harmonicsOneList: Harmonics[];
  harmonicsTwoList: Harmonics[];
  harmonicsThreeList: Harmonics[];
  setHarmonicsOneList: (harmonicsSetOne: Harmonics[]) => void;
  setHarmonicsTwoList: (harmonicsSetTwo: Harmonics[]) => void;
  setHarmonicsThreeList: (harmonicsSetThree: Harmonics[]) => void;
}

const useHarmonicsStore = createWithEqualityFn<HarmonicsStore>((set) => ({
  harmonicsOneList: [],
  harmonicsTwoList: [],
  harmonicsThreeList: [],
  setHarmonicsOneList: (harmonicsSetOne) => {
    set({ harmonicsOneList: harmonicsSetOne });
  },
  setHarmonicsTwoList: (harmonicsSetTwo) => {
    set({ harmonicsTwoList: harmonicsSetTwo });
  },
  setHarmonicsThreeList: (harmonicsSetThree) => {
    set({ harmonicsThreeList: harmonicsSetThree });
  },
}), shallow);
export default useHarmonicsStore;
