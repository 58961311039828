import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { apiService } from '../../modules/common';

interface UseShortTrendFFTReducedAsZipArgs {
  signalId: string;
  startDateTime: string | number | Date | undefined;
  endDateTime: string | number | Date | undefined;
  reduction: number;
}

interface UseShortTrendFFTReducedAsZipOptions {
  enabled?: boolean;
  cacheTime?: number;
}

const useShortTrendFFTReducedAsZip = (
  args: UseShortTrendFFTReducedAsZipArgs,
  options: UseShortTrendFFTReducedAsZipOptions,
) => {
  const { enabled, cacheTime } = options || {};

  const [queryProgress, setQueryProgress] = useState(0);

  const [hasStarted, setHasStarted] = useState(false);

  const onProgress = (progress: number) => {
    setQueryProgress(progress);

    if (!hasStarted) {
      setHasStarted(true);
    }
  };

  const { data, isLoading, isError } = useQuery(
    [
      'short-trend-fft-reduced-as-zip',
      args.signalId,
      args.startDateTime as never,
      args.endDateTime as never,
      args.reduction as never,
    ],
    () => apiService.measuredDataRead.getShortTrendFFTReducedAsZip(args, onProgress),
    {
      enabled,
      cacheTime,
    },
  );

  useEffect(() => {
    if (!isLoading) {
      setQueryProgress(0);
    }
  }, [isLoading]);

  return {
    data,
    isLoading,
    isError,
    progress: isLoading ? queryProgress : 0,
  };
};

export default useShortTrendFFTReducedAsZip;
