import type {
  ICheckboxProps,
  IComboBoxProps,
  IDatePickerProps,
  ISpinButtonProps,
  ITextFieldProps,
  IToggleProps,
} from "@fluentui/react";

import { FormDatePicker } from "./FormDatePicker";
import { FormDropdown } from "./FormDropdown";
import { FormTextArea } from "./FormTextArea";
import { FormTextField } from "./FormTextField";

import { FormCheckbox } from "./FormCheckbox";
import { FormRadio } from "./FormRadio";
import { FormTimePicker } from "./FormTimePicker";
import { FormToggle } from "./FormToggle";

import type { BaseFormFieldProps, FormHookProps } from "../types";
import { FormNumberField } from "./FormNumberField";
import { FormNumberFieldV1 } from "./FormNumberFieldV1";
import { FormTextEditor } from "./FormTextEditor";

export enum FormItemType {
  TextField = "TEXT_FIELD",
  NumberField = "NUMBER_FIELD",
  NumberFieldV1 = "NUMBER_FIELD_V1",
  TextArea = "TEXT_AREA",
  TextEditor = "TEXT_EDITOR",
  DatePicker = "DATE_PICKER",
  Dropdown = "DROPDOWN",
  Toggle = "TOGGLE",
  Checkbox = "CHECKBOX",
  TimePicker = "TIME_PICKER",
  Radio = "RADIO",
}

type FormFieldProps = Omit<BaseFormFieldProps, "hookProps"> & { name: string };

type FormTextFieldProps = FormFieldProps &
  ITextFieldProps & {
    type:
      | FormItemType.TextField
      | FormItemType.TextArea
      | FormItemType.TextEditor;
  };

type FormNumberFieldProps = FormFieldProps &
  ISpinButtonProps & {
    type: FormItemType.NumberField;
  };

type FormNumberFieldV1Props = FormFieldProps &
  ISpinButtonProps & {
    type: FormItemType.NumberFieldV1;
  };

type FormDatePickerProps = {
  type: FormItemType.DatePicker;
} & FormFieldProps &
  IDatePickerProps;

type FormTimePickerProps = {
  type: FormItemType.TimePicker;
} & FormFieldProps;

type FormDropdownProps = {
  type: FormItemType.Dropdown;
} & FormFieldProps &
  IComboBoxProps;

type FormToggleProps = {
  type: FormItemType.Toggle;
} & FormFieldProps &
  IToggleProps;

type FormCheckboxProps = {
  type: FormItemType.Checkbox;
} & FormFieldProps &
  ICheckboxProps;

type FormRadioProps = {
  type: FormItemType.Radio;
} & FormFieldProps;

export type FormItemProps =
  | FormTextFieldProps
  | FormNumberFieldProps
  | FormNumberFieldV1Props
  | FormDropdownProps
  | FormDatePickerProps
  | FormTimePickerProps
  | FormToggleProps
  | FormCheckboxProps
  | FormRadioProps;

export const renderFormItems = (
  fields: FormItemProps[],
  hookProps: FormHookProps
) => {
  return fields.map((props: FormItemProps) => {
    switch (props.type) {
      case FormItemType.TextField: {
        const { name, ...rest } = props;
        return (
          <FormTextField
            key={name}
            name={name}
            hookProps={hookProps}
            {...rest}
          />
        );
      }
      case FormItemType.NumberField: {
        const { name, ...rest } = props;
        return (
          <FormNumberField
            key={name}
            name={name}
            hookProps={hookProps}
            {...rest}
          />
        );
      }
      case FormItemType.NumberFieldV1: {
        const { name, ...rest } = props;
        return (
          <FormNumberFieldV1
            key={name}
            name={name}
            hookProps={hookProps}
            {...rest}
          />
        );
      }
      case FormItemType.DatePicker: {
        const { name, ...rest } = props;
        return (
          <FormDatePicker
            key={name}
            name={name}
            hookProps={hookProps}
            {...rest}
          />
        );
      }
      case FormItemType.TextArea: {
        const { name, ...rest } = props;
        return (
          <FormTextArea
            key={name}
            name={name}
            hookProps={hookProps}
            {...rest}
          />
        );
      }
      case FormItemType.TextEditor: {
        const { name, ...rest } = props;
        return (
          <FormTextEditor
            key={name}
            name={name}
            hookProps={hookProps}
            {...rest}
          />
        );
      }
      case FormItemType.Dropdown: {
        const { name, ...rest } = props;
        return (
          <FormDropdown
            key={name}
            name={name}
            hookProps={hookProps}
            {...rest}
          />
        );
      }
      case FormItemType.Toggle: {
        const { name, ...rest } = props;
        return (
          <FormToggle key={name} name={name} hookProps={hookProps} {...rest} />
        );
      }
      case FormItemType.Checkbox: {
        const { name, ...rest } = props;
        return (
          <FormCheckbox
            key={name}
            name={name}
            hookProps={hookProps}
            {...rest}
          />
        );
      }
      case FormItemType.Radio: {
        const { name, ...rest } = props;
        return (
          <FormRadio key={name} name={name} hookProps={hookProps} {...rest} />
        );
      }
      case FormItemType.TimePicker: {
        const { name, ...rest } = props;
        return (
          <FormTimePicker
            key={name}
            name={name}
            hookProps={hookProps}
            {...rest}
          />
        );
      }
      default:
        return null;
    }
  });
};

export const renderFormFieldArrayItems = (
  formFieldsTemplate: FormItemProps[],
  hookProps: FormHookProps,
  name: string,
  index: number | string
) =>
  renderFormItems(
    formFieldsTemplate.map((formField) => ({
      ...formField,
      name: `${name}.${index}.${formField.name}`,
      // name: 'measurement.0.ranges[0]',
    })),
    hookProps
  );
