import { Stack } from "@fluentui/react";
import { useCallback } from "react";

import getPeriodFilters from "../../../analysis-trend-view/utils/getPeriodFilters";
import DataTypeEnum from "../../../common/constants/DataTypeEnum";
import styles from "../../../../Components/Analysis/RawDataPage/RawDataPage.styles";

import useHeaderControlsStore from "../../hooks/useHeaderControlsStore";
import useLayoutTypes from "../../hooks/useLayoutTypes";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import CheckboxTree from "../CheckboxTree";

const DataPointsSidebar = () => {
  const isActiveRawDataPoints = useHeaderControlsStore(
    (store) => store.isRawDataPointsActive
  );
  const period = useHeaderControlsStore((store) => store.period);

  const { isCompareLayout } = useLayoutTypes();

  const selectedSignals = useSelectedSignalsStore(
    (store) => store.selectedSignals
  );

  const getRawSignals = useCallback(() => {
    return (
      selectedSignals.filter(
        (item) => item?.dataType === DataTypeEnum.RawData
      ) || []
    );
  }, [selectedSignals]);

  return (
    <Stack
      horizontal
      style={{
        ...(selectedSignals.length > 1 && {
          ...styles.checkboxTreeWrapper,
        }),
        backgroundColor: "#fff",
      }}
    >
      {selectedSignals.length > 0 &&
        getRawSignals().map((selectedSignal) => {
          const periodFilters = getPeriodFilters(period, selectedSignal);
          return (
            <Stack
              key={selectedSignal.id}
              className="trendViewDataPointsSidebar"
              style={{
                display: isActiveRawDataPoints ? "block" : "none",
                height:
                  selectedSignals.length > 1 && isCompareLayout
                    ? "535px"
                    : "calc(100vh - 62px)",
              }}
            >
              <Stack
                horizontal
                style={{
                  ...styles.checkboxTreeContainer,
                }}
              >
                <CheckboxTree
                  key={selectedSignal.id}
                  customStyle={{ width: "100%" }}
                  signal={selectedSignal}
                  startDateTime={periodFilters.startDate}
                  endDateTime={periodFilters.endDate}
                />
              </Stack>
            </Stack>
          );
        })}
    </Stack>
  );
};

export default DataPointsSidebar;
