import React, { useEffect } from "react";

import type { CommandBarItemProps } from "../../../common/CommandBar";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar";

type ChartCommandBarProps = {
  title: string;
  onRefresh: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const refreshInterval: ReturnType<typeof setInterval> = null;
const ChartCommandBar = ({ title, onRefresh }: ChartCommandBarProps) => {
  // const [isToggled, setIsToggled] = useState(true);

  // useEffect(() => {
  //   if (isToggled) {
  //     onRefresh();
  //     refreshInterval = setInterval(() => onRefresh(), 5 * 60 * 1000);
  //   } else {
  //     clearInterval(refreshInterval);
  //   }
  // }, [isToggled]);

  useEffect(() => {
    return () => clearInterval(refreshInterval);
  }, []);

  const items: CommandBarItemProps[] = [
    {
      key: "addDocuments",
      text: "Refresh",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Refresh" },
      onClick: onRefresh,
    },
  ];

  return (
    <div className="chart-header">
      <div className="chart-header-left">
        <div className="chart-header-title">{title}</div>
        <BaseCommandBar
          items={computeCommandBarItems(items)}
          styles={{ root: { margin: 0, padding: 0 } }}
        />
      </div>
      {/* <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ margin: '0 10px' }}>Auto Refresh</Text>
        <Toggle
          offText='Off'
          styles={{ root: { margin: 0 } }}
          defaultChecked={true}
          // label={undefined}
          onText='On'
          onChange={(_, checked) => {
            setIsToggled(checked as boolean);
          }}
        />
      </div> */}
    </div>
  );
};

export default ChartCommandBar;
