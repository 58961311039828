/* eslint-disable react-hooks/exhaustive-deps */
import {
  IDropdownOption,
  Stack,
  Dropdown,
  Icon,
  DatePicker,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useEffect, useState } from "react";

import {
  CommandBarItemProps,
  CommandBarItemType,
} from "../../common/CommandBar";
import { useTableFilters } from "../../common/Table";
import { notification } from "../../common/Notification";

import ImagesPivotContainer from "../Generic/ImagesPivotContainer";
import {
  ImageDataExtractionDuration,
  ImagesStatisticsResponse,
  ImagesTimeRanges,
  TimeRange,
} from "../models";
import ImagesDataExtractionTable from "./ImagesDataExtractionTable";
import ImagesStatisticsTable from "./ImagesStatisticsTable";
import { ImagesAPI } from "../api";

const getTimeRange = (key: keyof typeof ImagesTimeRanges) => {
  let result: TimeRange = {
    dateFrom: new Date(),
    dateTo: new Date(),
  };

  switch (ImagesTimeRanges[key]) {
    case ImagesTimeRanges.LastHour:
      result.dateFrom.setHours(result.dateFrom.getHours() - 1);
      break;
    case ImagesTimeRanges.Last3Hours:
      result.dateFrom.setHours(result.dateFrom.getHours() - 3);
      break;
    case ImagesTimeRanges.Last6Hours:
      result.dateFrom.setHours(result.dateFrom.getHours() - 6);
      break;
    case ImagesTimeRanges.Last12Hours:
      result.dateFrom.setHours(result.dateFrom.getHours() - 12);
      break;
    case ImagesTimeRanges.LastDay:
      result.dateFrom.setDate(result.dateFrom.getDate() - 1);
      break;
    case ImagesTimeRanges.LastMonth:
      result.dateFrom.setMonth(result.dateFrom.getMonth() - 1);
      break;
  }

  return result;
};

/**
 * Gets the time range command bar items props.
 * @param selectedKey The selected time range key.
 * @param onTimeRangeChanged Method called when the time range selected has changed.
 * @returns The Command bar item props list.
 */
const getTimeRangeBarItems = (
  selectedKey: keyof typeof ImagesTimeRanges,
  timeRange: TimeRange,
  isRefreshing: boolean,
  onTimeRangeChanged: (option: IDropdownOption) => void,
  onDateFromSelected: (date: Date) => void,
  onDateToSelected: (date: Date) => void,
  onRefresh: () => void
): CommandBarItemProps[] => {
  let result: CommandBarItemProps[] = [
    {
      key: "timeRange",
      type: CommandBarItemType.Custom,
      onRender: () => {
        return (
          <Stack.Item align="center">
            <Dropdown
              options={Object.keys(ImagesTimeRanges).map((key) => {
                return {
                  key: key,
                  text: ImagesTimeRanges[key as keyof typeof ImagesTimeRanges],
                };
              })}
              selectedKey={selectedKey}
              onRenderTitle={(options: IDropdownOption[]) => {
                let option = options.at(0);
                return (
                  <Stack
                    horizontal
                    verticalAlign="center"
                    tokens={{ childrenGap: 10 }}
                  >
                    <Icon
                      iconName="Timeline"
                      style={{ color: "rgb(44, 83, 160)" }}
                    />
                    <span>{option.text}</span>
                  </Stack>
                );
              }}
              onChange={(_, option) => onTimeRangeChanged(option)}
              style={{
                minWidth: "11em",
                textAlign: "center",
              }}
            />
          </Stack.Item>
        );
      },
    },
  ];

  const timePickers: CommandBarItemProps[] = [
    {
      key: "dateFrom",
      type: CommandBarItemType.Custom,
      onRender: () => {
        return (
          <Stack.Item align="end">
            <DatePicker
              placeholder="Date From..."
              maxDate={timeRange.dateTo}
              value={timeRange.dateFrom ?? undefined}
              onSelectDate={onDateFromSelected}
              style={{ minWidth: "9em" }}
            />
          </Stack.Item>
        );
      },
    },
    {
      key: "dateTo",
      type: CommandBarItemType.Custom,
      onRender: () => {
        return (
          <Stack.Item align="end">
            <DatePicker
              placeholder="Date To..."
              minDate={timeRange.dateFrom}
              maxDate={new Date()}
              value={timeRange.dateTo ?? undefined}
              onSelectDate={onDateToSelected}
              style={{ minWidth: "9em" }}
            />
          </Stack.Item>
        );
      },
    },
  ];

  const refreshButton: CommandBarItemProps = {
    key: "refresh",
    text: isRefreshing ? "Refreshing..." : "Refresh",
    disabled: isRefreshing,
    type: CommandBarItemType.Button,
    iconProps: { iconName: "Refresh" },
    onRenderIcon: (props, defaultRenderer) =>
      isRefreshing ? (
        <Spinner size={SpinnerSize.xSmall} />
      ) : (
        defaultRenderer(props)
      ),
    onClick: onRefresh,
  };

  ImagesTimeRanges[selectedKey] === ImagesTimeRanges.Custom &&
    result.push(...timePickers);
  result.push(refreshButton);

  return result;
};

/**
 * Gets the Images statistics tables component.
 * @returns The Images statistics tables component.
 */
const ImagesStatisticsTables = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [data, setData] = useState<ImagesStatisticsResponse>(null);
  const [timeRangeKey, setTimeRangeKey] = useState<
    keyof typeof ImagesTimeRanges
  >(Object.keys(ImagesTimeRanges).at(0) as keyof typeof ImagesTimeRanges);
  const [timeRange, setTimeRange] = useState<TimeRange>({
    dateFrom: null,
    dateTo: null,
  });
  const { filters, handleSearch } =
    useTableFilters<ImageDataExtractionDuration>({
      keys: ["fileName"],
    });

  // Sets the time range from time range key
  useEffect(() => {
    let newTimeRange: TimeRange = {
      dateFrom: new Date(),
      dateTo: new Date(),
    };

    if (ImagesTimeRanges[timeRangeKey] !== ImagesTimeRanges.Custom) {
      newTimeRange = getTimeRange(timeRangeKey);
    } else {
      newTimeRange.dateFrom.setHours(newTimeRange.dateFrom.getHours() - 1);
    }

    setTimeRange(newTimeRange);
  }, [timeRangeKey]);

  // Initializes the tables' data.
  useEffect(() => {
    if (!timeRange.dateFrom || !timeRange.dateTo || data) {
      return;
    }

    onRefresh();
  }, [timeRange]);

  // Handlers
  const onRefresh = () => {
    let newTimeRange = { ...timeRange };
    if (ImagesTimeRanges[timeRangeKey] !== ImagesTimeRanges.Custom) {
      newTimeRange = getTimeRange(timeRangeKey);
    }

    setIsRefreshing(true);
    ImagesAPI.getStatistics(newTimeRange.dateFrom, newTimeRange.dateTo).then(
      (response) => {
        setIsRefreshing(false);
        if (response.status !== 200) {
          notification.error(
            `Failure getting images statistics: ${response.statusText}.`
          );
          return;
        }

        setData(response.data);
      }
    );
  };

  return (
    <ImagesPivotContainer
      title={"Images Statistics"}
      hasPermissions={true}
      commandBarItemProps={getTimeRangeBarItems(
        timeRangeKey,
        timeRange,
        isRefreshing,
        (option) =>
          setTimeRangeKey(option.key as keyof typeof ImagesTimeRanges),
        (date) => setTimeRange({ ...timeRange, dateFrom: date }),
        (date) => setTimeRange({ ...timeRange, dateTo: date }),
        onRefresh
      )}
      handleSearch={handleSearch}
    >
      <ImagesStatisticsTable
        tableItems={data?.statistics ?? []}
        isLoading={isRefreshing}
      />
      <ImagesDataExtractionTable
        tableItems={data?.dataExtractionDuration ?? []}
        filters={filters}
        isLoading={isRefreshing}
      />
    </ImagesPivotContainer>
  );
};

export default ImagesStatisticsTables;
