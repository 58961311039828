import { getMonth, getYear } from 'date-fns';
import { omitBy } from 'lodash-es';

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import type { DataPoint } from '../types';

interface TrendChartValuesProps {
  year: string | number | Date | undefined;
  month: string | number | Date | undefined;
}

interface SelectedDataPointsStore {
  selectedDataPoints: DataPoint[];
  trendChartValues: TrendChartValuesProps;
  selectedDataPointsTimestamps: any;
  addSelectedDataPoint: (selectedDataPoint: DataPoint) => void;
  updateSelectedDataPoint: (selectedDataPoint: DataPoint) => void;
  addDataPointBySignal: (selectedDataPoint: DataPoint) => void;
  removeDataPointById: (signal: string | undefined, dataPointId: string) => void;
  removeSelectedDataPoint: (signal: string) => void;
  setTrendChartRequestValues: (value: TrendChartValuesProps) => void;
  clearSelectedDataPoints: () => void;
  setSelectedDataPointsTimestamps: (dataPointId: any, timestamp?: any) => void;
}

const currentYear = getYear(Date.now());
const currentMonth = getMonth(Date.now());

const useSelectedDataPointsStore = createWithEqualityFn<SelectedDataPointsStore>((set) => ({
  selectedDataPointsTimestamps: {},
  selectedDataPoints: [],
  trendChartValues: {
    year: currentYear,
    month: currentMonth,
  },
  setSelectedDataPointsTimestamps: (dataPointId, timestamp) => {
    if (dataPointId) {
      set((state) => ({
        selectedDataPointsTimestamps: omitBy(
          {
            ...state.selectedDataPointsTimestamps,
            [dataPointId]: timestamp,
          },
          (v) => !v,
        ),
      }));
    }
  },
  addSelectedDataPoint: (selectedDataPoint) => {
    set((state) => ({ selectedDataPoints: [...state.selectedDataPoints, selectedDataPoint] }));
  },
  updateSelectedDataPoint: (selectedDataPoint) => {
    set((state) => ({
      selectedDataPoints: state.selectedDataPoints.map((dataPoint) =>
        dataPoint.signal === selectedDataPoint.signal ? selectedDataPoint : dataPoint,
      ),
    }));
  },
  addDataPointBySignal: (selectedDataPoint) => {
    set((state) => {
      // Find the index of the signal in the selectedDataPoints array
      const dataIndex = state.selectedDataPoints.findIndex(
        (dataPoint) => dataPoint.signal === selectedDataPoint.signal,
      );

      // If the signal is found (index is not -1), create a new array with the added data point
      if (dataIndex !== -1) {
        const updatedDataPoints = [
          ...state.selectedDataPoints[dataIndex].dataPoints,
          selectedDataPoint.dataPoints[0],
        ];

        // Return the updated state with the modified dataPoints array
        return {
          selectedDataPoints: [
            ...state.selectedDataPoints.slice(0, dataIndex),
            {
              ...state.selectedDataPoints[dataIndex],
              dataPoints: updatedDataPoints,
            },
            ...state.selectedDataPoints.slice(dataIndex + 1),
          ],
        };
      }

      // If the signal is not found, return the current state without any changes
      return state;
    });
  },
  removeSelectedDataPoint: (signal) => {
    set((state) => ({
      selectedDataPoints: state.selectedDataPoints.filter((item) => item.signal !== signal),
    }));
  },
  removeDataPointById: (signalId, dataPointId) => {
    set((state) => {
      // Find the index of the signal in the selectedDataPoints array
      const dataIndex = state.selectedDataPoints.findIndex(
        (dataPoint) => dataPoint.signal === signalId,
      );

      // If the signal is found (index is not -1)
      if (dataIndex !== -1) {
        // Filter out the dataPointId from the dataPoints array
        const updatedDataPoints = state.selectedDataPoints[dataIndex].dataPoints.filter(
          (item) => item !== dataPointId,
        );

        // Return the updated state with the modified dataPoints array
        return {
          selectedDataPoints: [
            ...state.selectedDataPoints.slice(0, dataIndex),
            {
              ...state.selectedDataPoints[dataIndex],
              dataPoints: updatedDataPoints,
            },
            ...state.selectedDataPoints.slice(dataIndex + 1),
          ],
        };
      }

      // If the signal is not found, return the current state without any changes
      return state;
    });
  },
  setTrendChartRequestValues: (value) => {
    set(() => ({
      trendChartValues: {
        year: value?.year || currentYear,
        month: value?.month || currentMonth,
      },
    }));
  },
  clearSelectedDataPoints: () => set({ selectedDataPoints: [] }),
}), shallow);

export default useSelectedDataPointsStore;
