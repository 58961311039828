import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../../../../modules/core/apiClient/useApiStore';
import type { ResponseSimplifiedSignalList } from '../../../../types';

export const useEvents = ({ eventId, options, year, month }: any) => {
  let apiRoute = `/data/read/v1/events/${eventId}/${year}`;

  if (month) {
    apiRoute = `/data/read/v1/events/${eventId}/${year}/${month}`;
  }

  const {
    data = {},
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['events-list', eventId],
    () =>
      getApiClient()
        .get<ResponseSimplifiedSignalList>(apiRoute)
        .then(({ data }) => {
          return data;
        })
        .catch(() => ({})),
    { staleTime: 0, cacheTime: 0, ...options },
  );

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
