import "./components/styles.scss";

import type { CSSProperties } from "react";

import { useMachineCvOverviewList } from "../../../Hooks";
import { NoData } from "../../common/NoData";
import { Spin } from "../../common/Spin";

import CommandBar from "./components/CommandBar";
import CorporationOverview from "./components/CorporationOverview";
import useOverviewItems from "./hooks/useOverviewItems";

const containerStyle: CSSProperties = {
  background: "#FAF9F8",
  padding: "8px 16px",
};

const placeholderContainer: CSSProperties = {
  height: "100vh",
  ...containerStyle,
};

const stickyHeader: CSSProperties = {
  position: "sticky",
  top: 0,
  zIndex: 100,
  background: "#FAF9F8",
  padding: "0 16px",
};

const Page = () => {
  const { data, isLoading, isError } = useMachineCvOverviewList({
    refetchOnMount: true,
  });

  const filteredData = useOverviewItems(data);

  if (isLoading) {
    return <Spin style={placeholderContainer} />;
  }

  if (isError) {
    return <NoData style={placeholderContainer} />;
  }

  return (
    <>
      <div style={stickyHeader}>
        <CommandBar />
      </div>
      <div style={containerStyle}>
        {filteredData.length === 0 && (
          <NoData style={{ height: "calc(100vh - 80px)" }} />
        )}

        {filteredData.map((corporation) => (
          <CorporationOverview key={`${corporation.id}`} data={corporation} />
        ))}
      </div>
    </>
  );
};

export default Page;
