import { useMutation } from '@tanstack/react-query';

import { getApiClient, validateStatus } from '../../../../modules/core/apiClient/useApiStore';

type InfoUpdateProps = {
  id: string;
  machineId: string;
  group: string;
};

export const useInfoUpdate = () => {
  const { mutateAsync } = useMutation(({ id, group, machineId }: InfoUpdateProps) =>
    getApiClient().put(
      `meta/machinecv/v1/infos/${id}`,
      {
        group,
        id,
        machineId,
        fieldName: '',
        fieldValue: '',
      },
      { validateStatus },
    ),
  );

  return { updateInfoAsync: mutateAsync };
};

type InfoAddProps = {
  machineId: string;
  group?: string;
};

export const useInfoAdd = () => {
  const { mutateAsync } = useMutation((data: InfoAddProps) =>
    getApiClient().post(
      'meta/machinecv/v1/infos',
      { ...data, fieldName: '', fieldValue: '' },
      { validateStatus },
    ),
  );
  return { addInfoAsync: mutateAsync };
};

type InfoEntryAddProps = {
  machineId: string;
  group: string;
  name?: string;
  value?: string;
  fileIds: string[];
};

export const useInfoEntryAdd = () => {
  const { mutateAsync } = useMutation(
    ({ name, value, group, machineId, fileIds }: InfoEntryAddProps) =>
      getApiClient().post(
        'meta/machinecv/v1/infos',
        {
          group,
          machineId,
          fileIds,
          fieldName: name,
          fieldValue: value || '',
        },
        {
          validateStatus,
        },
      ),
  );
  return { addInfoEntryAsync: mutateAsync };
};

type InfoEntryUpdateProps = {
  machineId: string;
  group: string;
  name?: string;
  value?: string;
  fileIds: string[];
  id: string;
};

export const useInfoEntryUpdate = () => {
  const { mutateAsync } = useMutation(
    ({ name, value, group, machineId, fileIds, id }: InfoEntryUpdateProps) =>
      getApiClient().put(
        `meta/machinecv/v1/infos/${id}`,
        {
          group,
          machineId,
          fileIds,
          fieldName: name,
          fieldValue: value || '',
          id,
        },
        { validateStatus },
      ),
  );
  return { updateInfoEntryAsync: mutateAsync };
};

type InfoUpdateGroupNameProps = {
  machineId: string;
  oldGroup: string;
  newGroup: string;
};

export const useUpdateGroupName = () => {
  const { mutateAsync } = useMutation((data: InfoUpdateGroupNameProps) =>
    getApiClient().put(`meta/machinecv/v1/infos/updateGroupName`, data, { validateStatus }),
  );
  return { updateGroupNameAsync: mutateAsync };
};
