import "./style.scss";

import type { IPivotItemProps } from "@fluentui/react";

import type { Mode } from "../../hooks/useModeSelect";
import { modeIcons, useModeSelect } from "../../hooks/useModeSelect";
import { TabMenu } from "../../../../common/TabMenu";

const onRenderItemLink: any = (props: IPivotItemProps) => {
  const ModeIcon = modeIcons[props?.itemKey as Mode];

  return (
    <div className="mode-tab">
      <ModeIcon />
      {props.headerText}
    </div>
  );
};

const MODE_OPTIONS = [
  {
    pivotItemProps: {
      itemKey: "projects",
      onRenderItemLink,
    },
    title: "Projects",
    content: <div />,
  },
  {
    pivotItemProps: {
      itemKey: "dashboards",
      onRenderItemLink,
    },
    title: "Dashboards",
    content: <div />,
  },
];

export const ModeSelect = () => {
  const { mode, setDataMode } = useModeSelect();

  return (
    <TabMenu
      tabs={MODE_OPTIONS}
      pivotProps={{
        className: "mode-select-tab-menu",
        defaultSelectedKey: mode,
        onLinkClick: (tab) => setDataMode(tab?.props?.itemKey as Mode),
        styles: {
          root: {
            display: "flex",
            background: "white",
            marginRight: 14,
          },
          link: { flex: 1, margin: 0, fontSize: 14, fontWeight: 600 },
          linkIsSelected: { background: "#2C529F", color: "#fff" },
          itemContainer: {
            maxHeight: "42px",
            width: "400px",
            lineHeight: "20px",
          },
        },
      }}
    />
  );
};
