import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectUserAccountMemberID = (state: RootState) =>
  state?.userAccount?.response?.memberId;

export const selectUserAccountProfilePicture = (state: RootState) =>
  state?.userAccount?.response?.profilePicture;

export const selectUserAccountInitialProfilePicture = (state: RootState) =>
  state?.userAccount?.initialData?.profilePicture;

export const hasProfilePictureChanged = (state: RootState) =>
  state?.userAccount?.response?.profilePicture !==
  state?.userAccount?.initialData?.profilePicture;

const selectAllUserAccountStoreProperties = (state: RootState) =>
  state.userAccount.response;

export const selectAllUserAccountProperties = createSelector(
  selectAllUserAccountStoreProperties,
  (allUserAccountProperties) => {
    const payload = Object.keys(allUserAccountProperties).reduce(
      (acc, curr) => {
        if (curr !== "profilePicture") {
          acc[curr] = allUserAccountProperties[curr];
        }

        return acc;
      },
      {}
    );

    return payload;
  }
);

export const selectUserAccountContactProperties = createSelector(
  selectAllUserAccountStoreProperties,
  (allUserAccountProperties) => {
    const contactKeys = [
      "email",
      "mobilePhone",
      "countryOrRegion",
      "stateOrProvince",
      "city",
      "streetAddress",
      "zipOrPostalCode",
    ];

    const contactProperties = contactKeys.reduce((acc, curr) => {
      acc[curr] = allUserAccountProperties[curr] || "";

      return acc;
    }, {});

    return contactProperties;
  }
);

type accountInfo = {
  firstName: string;
  lastName: string;
  displayName: string;
  companyName: string;
  department: string;
  jobTitle: string;
};

export const selectUserAccountInformationProperties = createSelector(
  selectAllUserAccountStoreProperties,
  (allUserAccountProperties) => {
    const informationKeys = [
      "firstName",
      "lastName",
      "displayName",
      "companyName",
      "department",
      "jobTitle",
    ];

    const infomationProperties = informationKeys.reduce((acc, curr) => {
      acc[curr] = allUserAccountProperties[curr] || "";

      return acc;
    }, {} as accountInfo);

    return infomationProperties;
  }
);
