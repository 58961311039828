export type MeasurementSettingId =
  | 'MeasSet0'
  | 'MeasSet1'
  | 'MeasSet2'
  | 'MeasSet3'
  | 'MeasSet4'
  | 'MeasSet5'
  | 'MeasSet6'
  | 'MeasSet7';

export type TimedMeasurementType = 'TimedVibration1' | 'TimedVibration2' | 'TimedVibration3';

export interface UpdateTimedSettingsRequest {
  enabled: boolean;
  intervalInMinutes: number;
  enabledMeasurementSettings: MeasurementSettingId[];
}

export interface UpdateTimedSettingsRequestMultiple {
  sensorNodeIds: string[];
  timedMeasurementType: TimedMeasurementType;
  timedMeasurementSettings: UpdateTimedSettingsRequest;
}

type CalculationSettingId =
  | 'CalcId0'
  | 'CalcId1'
  | 'CalcId2'
  | 'CalcId3'
  | 'CalcId4'
  | 'CalcId5'
  | 'CalcId6'
  | 'CalcId7'
  | 'CalcId8'
  | 'CalcId9'
  | 'CalcId10'
  | 'CalcId11'
  | 'CalcId12'
  | 'CalcId13'
  | 'CalcId14';

type GRange = 'TwoG' | 'FourG' | 'EightG' | 'SixteenG';
type DataAxis = 'X' | 'Y' | 'Z' | 'All';

export interface UpdateMeasurementSettingsRequestMultiple {
  sensorNodeIds: string[];
  measurementId: MeasurementSettingId;
  measurementSettings: UpdateMeasurementSettingsRequest;
}

interface UpdateMeasurementSettingsRequest {
  gRange?: GRange;
  dataAxis?: DataAxis;
  dataSampleCount?: number;
  selectedCalculations?: CalculationSettingId[];
}

export type UseSensorSettingsProps = {
  sensorNodeId: string;
  options?: any;
  activeSettings?: any;
  settingsToLoad?: any[];
};

export interface WirelessOverviewSensorNode {
  id: string;
  sensorNodeId?: string;
  deviceModel?: number;
  deviceClass?: number;
  wirelessGatewayId?: string;
  wirelessGatewaySerialNumber?: string;
}

export interface WirelessOverviewGateway {
  id: string;
  serialNumber?: string;
  wifiSSID?: string;
  iMEI?: string;
  wirepassSinkNodeAddress?: string;
  wirepassChannel?: string;
  ethernetMacAddress?: string;
  firmware?: string;
}

export interface WirelessOverviewMachine {
  name?: string;
  id: string;
  sensorNodes?: WirelessOverviewSensorNode[];
}

export interface WirelessOverviewProject {
  name?: string;
  id: string;
  gateways?: WirelessOverviewGateway[];
  machines?: WirelessOverviewMachine[];
}

export interface WirelessOverviewCompany {
  name?: string;
  id: string;
  projects?: WirelessOverviewProject[];
}

export interface WirelessOverviewCorporation {
  name?: string;
  id: string;
  companies?: WirelessOverviewCompany[];
}

export interface ResponseWirelessOverview {
  corporations?: WirelessOverviewCorporation[];
  unassignedGateways?: WirelessOverviewGateway[];
  unassignedSensorNodes?: WirelessOverviewSensorNode[];
}

export interface SensorNodeSelectable {
  id: string;
  sensorNodeId: string;
}

export interface MachineSelectable {
  id: string;
  name: string;
  sensorNodes: SensorNodeSelectable[];
}

export interface ProjectSelectable {
  id: string;
  name: string;
  machines: MachineSelectable[];
}

/// Triggers

export enum TriggerFrequency {
  EveryHour = 'EveryHour',
  EveryTwoHours = 'EveryTwoHours',
  EveryThreeHours = 'EveryThreeHours',
  EveryFourHours = 'EveryFourHours',
  EverySixHours = 'EverySixHours',
  EveryEightHours = 'EveryEightHours',
  EveryTwelveHours = 'EveryTwelveHours',
  EveryDay = 'EveryDay',
  EveryOtherDayFromToday = 'EveryOtherDayFromToday',
  EveryOtherDayFromTomorrow = 'EveryOtherDayFromTomorrow',
}

export interface ProgrammedTrigger {
  id: string;
  name: string;
  frequency: TriggerFrequency;
  hour: number;
  minute: number;
  measurementSets: MeasurementSettingId[];
  delayPerSensorInMinutes: number;
}

export interface ListProjectTriggersResponse {
  projectId: string;
  triggers: ProgrammedTrigger[];
}

export interface CreateProjectTriggerRequest {
  projectId: string;
  name: string;
  frequency: TriggerFrequency;
  hour: number;
  minute: number;
  measurementSets: MeasurementSettingId[];
  delayPerSensorInMinutes: number;
}

export interface ProjectTriggerDto {
  projectId: string;
  hour: number;
  minute: number;
  delayPerSensorInMinutes: number;
  nextTriggerOccurrance: Date;
}

export interface CreateProjectTriggerResponse {
  projectTriggerDto: ProjectTriggerDto;
}
