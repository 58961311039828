import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { treeHandlers, useTreeState } from "react-hyper-tree";

import { useWebAnalysisPermissions } from "../../../Hooks";
import { getApiClient } from "../../../modules/core/apiClient/useApiStore";
import { useWSUrlParams } from "./useWSUrlParams";
import { parseTreeData } from "../utils";

export const WS_SIDEBAR_QUERY_KEY = "ws-sidebar-data";

export const useWSSidebarData = () => {
  const wsParams: any = useWSUrlParams();

  const { data: sidebarData = [], isLoading } = useQuery(
    [WS_SIDEBAR_QUERY_KEY],
    () =>
      getApiClient()
        .get(`/meta/read/internal/v1/wireless/overview`)
        .then(({ data }) => data)
  );

  const { useReadyPermissionGeneral } = useWebAnalysisPermissions();
  const hasWirelessSensorReaderPermissions = useReadyPermissionGeneral({
    permissionRoles: ["WirelessSensorReader", "WirelessSensorContributor"],
  });

  const data = useMemo(
    () =>
      parseTreeData(
        sidebarData?.corporations,
        hasWirelessSensorReaderPermissions
      ),
    [sidebarData]
  );

  const { required, handlers, instance } = useTreeState({
    id: "ws-sidebar-tree",
    data,
  });

  useEffect(() => {
    if (instance?.enhancedData?.length > 0) {
      if (wsParams.projectId) {
        const projectNode = handlers.getNode(wsParams.projectId);
        if (projectNode) {
          if (wsParams.serialNumber) {
            const gatewayNode = handlers.getNode(wsParams.serialNumber);
            gatewayNode?.setSelected(true);
            treeHandlers.trees["ws-sidebar-tree"].handlers.setOpenByPath(
              gatewayNode?.getPath()
            );
          } else {
            projectNode.getChildren()[0].setSelected(true);
            treeHandlers.trees["ws-sidebar-tree"].handlers.setOpenByPath(
              projectNode?.getPath()
            );
          }
        }
      }

      if (wsParams.machineId) {
        const machineNode = handlers.getNode(wsParams.machineId);
        const sensorNode = handlers.getNode(wsParams.sensorNodeId);
        if (machineNode) {
          const pathToOpenNode = wsParams.sensorNodeId
            ? machineNode?.getPath()
            : machineNode?.getParent()?.getPath();
          sensorNode?.setSelected(true);
          treeHandlers.trees["ws-sidebar-tree"].handlers.setOpenByPath(
            pathToOpenNode
          );
        }
      }
    }
  }, [data, instance]);

  return {
    required,
    handlers,
    isLoading,
  };
};
