import type { IStackProps, IStackStyles } from "@fluentui/react";
import { mergeStyleSets, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import classNames from "classnames";
import type { PropsWithChildren } from "react";

import { format } from "../../../common/utils/signalFormatter";
import type { ResponseSimplifiedSignal } from "../../../../types";

import useMachineMetaData from "../../hooks/useMachineMetaData";

const plotContainerStyles: IStackStyles = {
  root: {
    height: 575,
    background: "#FFF",
    borderRadius: 2,
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.13)",
    marginBottom: "1rem",
  },
};

export const TrendViewPlotContainer = ({
  className,
  children,
  ...rest
}: PropsWithChildren<IStackProps>) => (
  <Stack
    {...rest}
    verticalAlign="center"
    horizontalAlign="center"
    className={classNames("trend-view-plot-container", className)}
    styles={mergeStyleSets(plotContainerStyles, rest?.styles)}
  >
    {children}
  </Stack>
);

type TrendViewPlotSpinnerProps = IStackProps & {
  text?: string;
};

export const TrendViewPlotSpinner = ({
  text,
  ...rest
}: TrendViewPlotSpinnerProps) => (
  <TrendViewPlotContainer
    {...rest}
    className={"trend-view-plot-container-loading"}
  >
    <Spinner
      size={SpinnerSize.large}
      label={text ?? "Loading trend view chart..."}
    />
  </TrendViewPlotContainer>
);

export const TrendViewPlotNoData = ({
  signal,
}: {
  signal: ResponseSimplifiedSignal;
}) => (
  <TrendViewPlotContainer className="trend-view-plot-container-no-data">
    <span>
      <b>{format(signal)}</b> has no data
    </span>
  </TrendViewPlotContainer>
);

export const TrendViewGroupedPlotNoData = ({
  machineId,
}: {
  machineId: string;
}) => {
  const { corporation, company, project, machine } =
    useMachineMetaData(machineId);

  if (machineId === "all") {
    return (
      <TrendViewPlotContainer>
        <span>One or more selected signals has no data</span>
      </TrendViewPlotContainer>
    );
  }

  const name = `${corporation?.name} > ${company?.name} > ${project?.name} > ${machine?.name}`;

  return (
    <TrendViewPlotContainer>
      <span>
        One or more selected signals from <b>{name}</b> has no data
      </span>
    </TrendViewPlotContainer>
  );
};
