import type { PropsWithChildren } from 'react';

export default function SectionTitle({ children }: PropsWithChildren) {
  return (
    <div
      style={{
        color: '#3B3A39',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '22px',
        wordWrap: 'break-word',
        marginBottom: '24px',
      }}
    >
      {children}
    </div>
  );
}
