import type { CSSProperties, HtmlHTMLAttributes } from "react";
import { useMemo } from "react";

import { useModeSelect } from "../../Home/HomePage/hooks/useModeSelect";

// Generic Marker

const markerStyle: CSSProperties = {
  height: 40,
  padding: "0 14px",
  background: "#2c529f",
  border: "4px solid white",
  borderRadius: 2,
  color: "white",
  display: "flex",
  alignItems: "center",
  gap: 8,
};

const markerImageStyle: CSSProperties = {
  display: "flex",
};

export type MarkerProps = HtmlHTMLAttributes<unknown> & {
  name: string;
};

const Marker = ({ name, ...rest }: MarkerProps) => {
  const { getModeIcon, getModeColor } = useModeSelect();
  const ModeIcon = getModeIcon();

  return (
    <div
      style={{ cursor: "pointer", background: getModeColor(), ...markerStyle }}
      {...rest}
    >
      <div style={markerImageStyle}>
        <ModeIcon fill={"#FFF"} style={{ height: 17, width: 17 }} />
      </div>
      <div>{name}</div>
    </div>
  );
};

export default Marker;
