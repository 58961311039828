import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

const useSensorStore = createWithEqualityFn((set) => ({
  triggers: {},
  settings: {},
  updateTriggers: ({ sensorNodeId, updates }: any) =>
    set((store: any) => {
      const existingData = store.triggers[sensorNodeId] || {};
      return {
        triggers: {
          ...store.triggers,
          [sensorNodeId]: { ...existingData, ...(updates || {}) },
        },
      };
    }),
  updateSettings: ({ sensorNodeId, updates }: any) =>
    set((store: any) => {
      const existingData = store.settings[sensorNodeId] || {};
      return {
        settings: {
          ...store.settings,
          [sensorNodeId]: { ...existingData, ...(updates || {}) },
        },
      };
    }),
}), shallow);

export default useSensorStore;
