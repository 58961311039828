import type { SVGProps } from 'react';
import React from 'react';

const SearchIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.49997 8.00294e-08C6.54531 -0.00017821 7.56905 0.297544 8.45125 0.858287C9.33346 1.41903 10.0376 2.21957 10.4812 3.16612C10.9248 4.11268 11.0894 5.16604 10.9558 6.2028C10.8223 7.23956 10.396 8.21679 9.72697 9.02L13.854 13.146C13.9426 13.2342 13.9949 13.3524 14.0007 13.4772C14.0065 13.6021 13.9653 13.7246 13.8852 13.8206C13.8051 13.9166 13.692 13.9791 13.5682 13.9958C13.4443 14.0126 13.3186 13.9823 13.216 13.911L13.146 13.854L9.01997 9.727C8.34117 10.2921 7.53626 10.6854 6.67333 10.8736C5.81039 11.0619 4.91481 11.0395 4.06233 10.8085C3.20986 10.5774 2.42557 10.1445 1.77579 9.54624C1.12602 8.94801 0.629872 8.20208 0.329324 7.37156C0.028775 6.54104 -0.0673373 5.65035 0.0491158 4.77483C0.165569 3.89931 0.491162 3.06471 0.998356 2.34163C1.50555 1.61855 2.17943 1.02825 2.96299 0.620658C3.74655 0.213069 4.61674 0.000180997 5.49997 8.00294e-08ZM5.49997 1C4.3065 1 3.16191 1.47411 2.31799 2.31802C1.47408 3.16193 0.999973 4.30653 0.999973 5.5C0.999973 6.69347 1.47408 7.83807 2.31799 8.68198C3.16191 9.5259 4.3065 10 5.49997 10C6.69345 10 7.83804 9.5259 8.68195 8.68198C9.52587 7.83807 9.99997 6.69347 9.99997 5.5C9.99997 4.30653 9.52587 3.16193 8.68195 2.31802C7.83804 1.47411 6.69345 1 5.49997 1Z'
        fill='#2C529F'
      />
    </svg>
  );
};

export default SearchIcon;
