export type HarmonicsState = {
  visible: boolean;
  linesNumber: number;
  linesFreq: number;
  sidebandsNumber: number;
  sidebandsFreq: number;
};

export type Actions =
  | { type: 'SET_VISIBLE'; payload: boolean }
  | { type: 'SET_LINES_NUMBER'; payload: number }
  | { type: 'SET_LINES_FREQ'; payload: number }
  | { type: 'SET_SIDEBANDS_NUMBER'; payload: number }
  | { type: 'SET_SIDEBANDS_FREQ'; payload: number }
  | { type: 'RESET'; payload?: any };

export const harmonicsInitialState: HarmonicsState = {
  visible: false,
  linesNumber: 5,
  linesFreq: 0,
  sidebandsNumber: 3,
  sidebandsFreq: 0,
};

const harmonicsReducer = (state: HarmonicsState, action: Actions): HarmonicsState => {
  switch (action.type) {
    case 'SET_VISIBLE': {
      return {
        ...state,
        visible: action.payload,
      };
    }
    case 'SET_LINES_NUMBER': {
      return {
        ...state,
        linesNumber: action.payload,
      };
    }
    case 'SET_LINES_FREQ': {
      return {
        ...state,
        linesFreq: parseFloat(action.payload.toFixed(4)),
      };
    }
    case 'SET_SIDEBANDS_NUMBER': {
      return {
        ...state,
        sidebandsNumber: action.payload,
      };
    }
    case 'SET_SIDEBANDS_FREQ': {
      return {
        ...state,
        sidebandsFreq: parseFloat(action.payload.toFixed(4)),
      };
    }
    case 'RESET': {
      if (action?.payload) {
        return action.payload;
      }
      return harmonicsInitialState;
    }
  }
};

export default harmonicsReducer;
