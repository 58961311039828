import type { ISpinButtonProps } from '@fluentui/react';
import { SpinButton } from '@fluentui/react';
import { Controller } from 'react-hook-form';

import type { BaseFormFieldProps } from '../types';
import { FormGroup } from './FormGroup';

export type FormNumberFieldV1Props = BaseFormFieldProps &
  ISpinButtonProps & {
    name: string;
  };

export const FormNumberFieldV1 = ({
  name,
  groupProps,
  hookProps: { control },
  ...rest
}: FormNumberFieldV1Props) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        function onSpinButtonChange(_: any, newValue: string | undefined) {
          // Convert string to number before calling onChange
          const numericValue = newValue ? parseFloat(newValue) : 0;
          onChange(numericValue);
        }

        return (
          <SpinButton
            min={rest.min ?? 0}
            max={rest.max ?? 100}
            step={rest.step ?? 1}
            value={value?.toString() ?? '0'} // Convert number to string for display
            incrementButtonAriaLabel='Increase value by 1'
            decrementButtonAriaLabel='Decrease value by 1'
            onChange={onSpinButtonChange}
            onBlur={onBlur}
            {...rest}
          />
        );
      }}
    />
  </FormGroup>
);
