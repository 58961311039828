import { Controller } from 'react-hook-form';

import { FormGroup } from './FormGroup';

export const FormRadio = ({ name, groupProps, hookProps: { control }, options }: any) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue=''
      render={({ field }) => {
        return (
          <>
            {options.map((option: any, idx: number) => {
              const { value, text } = option;
              const radioText = text || option;
              const radioValue = value || option;

              return (
                <span
                  key={radioValue}
                  className='radio-item'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: idx === options.length - 1 ? 0 : 20,
                  }}
                >
                  <label
                    className='radio-label'
                    htmlFor={`${field.name}-${radioValue}`}
                    style={{ marginRight: 5 }}
                  >
                    {radioText}
                  </label>
                  <input
                    className='radio-input'
                    id={`${field.name}-${radioValue}`}
                    type='radio'
                    {...field}
                    value={radioValue}
                    checked={field.value === radioValue}
                  />
                </span>
              );
            })}
          </>
        );
      }}
    />
  </FormGroup>
);
