import type { IColumn, IDetailsRowProps, IDropdownOption, IGroup } from '@fluentui/react';
import {
  DefaultButton,
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  StackItem,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';

interface PaginatedShimmeredDetailsListProps {
  items: any[];
  columns: IColumn[];
  groups: IGroup[];
  selectedOption: string;
  itemsPerPage?: number;
}

const PaginatedShimmeredDetailsList: React.FC<PaginatedShimmeredDetailsListProps> = ({
  items,
  columns,
  groups,
  selectedOption,
  itemsPerPage = 20,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedItems, setPaginatedItems] = useState<any[]>([]);
  const [paginatedGroups, setPaginatedGroups] = useState<IGroup[]>([]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const newPaginatedItems = items.slice(startIndex, endIndex);

    const newPaginatedGroups = groups.reduce((acc: IGroup[], group) => {
      const groupStartIndex = group.startIndex - startIndex;
      const groupEndIndex = group.startIndex + group.count - startIndex;

      if (groupEndIndex > 0 && groupStartIndex < itemsPerPage) {
        acc.push({
          ...group,
          startIndex: Math.max(0, groupStartIndex),
          count: Math.min(groupEndIndex, itemsPerPage) - Math.max(0, groupStartIndex),
        });
      }

      return acc;
    }, []);

    setPaginatedItems(newPaginatedItems);
    setPaginatedGroups(newPaginatedGroups);
  }, [items, groups, currentPage, itemsPerPage]);

  const pageCount = Math.ceil(items.length / itemsPerPage);

  const onRenderRow = (props?: IDetailsRowProps) => {
    if (!props) return null;
    const newProps = { ...props };
    if (newProps.item.id === selectedOption.split(':')[0]) {
      newProps.styles = { root: { background: 'lightgrey' } };
    }
    return <DetailsRow {...newProps} />;
  };

  const onPageChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      setCurrentPage(Number(option.key));
    }
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <ShimmeredDetailsList
        items={paginatedItems}
        columns={columns}
        groups={paginatedGroups}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        groupProps={{
          showEmptyGroups: true,
          headerProps: {
            styles: {
              headerCount: { display: 'none' },
            },
          },
        }}
        onRenderRow={onRenderRow}
      />
      <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign='space-between'>
        <StackItem>
          <DefaultButton
            text='Previous'
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
          />
        </StackItem>
        <StackItem>
          <Dropdown
            selectedKey={currentPage}
            options={Array.from({ length: pageCount }, (_, i) => ({
              key: i + 1,
              text: `Page ${i + 1}`,
            }))}
            styles={{ dropdown: { width: 100 } }}
            onChange={onPageChange}
          />
        </StackItem>
        <StackItem>
          <DefaultButton
            text='Next'
            disabled={currentPage === pageCount}
            onClick={() => setCurrentPage((prev) => Math.min(pageCount, prev + 1))}
          />
        </StackItem>
      </Stack>
    </Stack>
  );
};

export default PaginatedShimmeredDetailsList;
