import "./style.scss";

import { uniqBy } from "lodash-es";
import React, { useEffect } from "react";

import useTrendViewControls from "../../../../../../modules/analysis-trend-view/components/TrendViewControls/useTrendViewControls";
import { TrendViewPlaceholder } from "../../../../../../modules/analysis-trend-view/components/TrendViewPlaceholder";
import usePerformanceStore from "../../../hooks/usePerformanceStore";
import { SuspenseSpin } from "../../../../../Analysis/TrendViewPage/TrendViewPage";
import Pagination from "../../../../../common/Pagination";

const TrendViewSection = React.lazy(
  () =>
    import(
      "../../../../../../modules/analysis-trend-view/components/TrendViewSection/TrendViewSection"
    )
);

const TrendViewGroupedSection = React.lazy(
  () =>
    import(
      "../../../../../../modules/analysis-trend-view/components/TrendViewGroupedSection"
    )
);

export const ChartSection = () => {
  const { controlsStore } = useTrendViewControls();
  const { selectedSignals, period, isGroupedEverything } = usePerformanceStore(
    (store: any) => ({
      isGroupedEverything: store.isGroupedEverything,
      selectedSignals: store.selectedSignals,
      period: store.period,
    })
  );

  useEffect(() => {
    controlsStore.changeAverageTrend(false);
  }, []);

  useEffect(() => {
    controlsStore.changePeriod(period);
  }, [period]);

  return (
    <>
      {selectedSignals.length === 0 && (
        <TrendViewPlaceholder text="Please select a table row" />
      )}
      {isGroupedEverything && selectedSignals.length > 0 ? (
        <React.Suspense fallback={<SuspenseSpin />}>
          <TrendViewGroupedSection
            title="Grouped Signals"
            signals={selectedSignals}
            machineId="all"
            chartStyles={{ loading: { height: "100%" } }}
          />
        </React.Suspense>
      ) : (
        <Pagination.Items
          hidePerPage={true}
          perPage={3}
          styles={{
            root: { marginBottom: "1rem" },
          }}
          items={uniqBy(selectedSignals, ({ id }) => id).map(
            (selectedSignal: any) => ({
              key: selectedSignal.id,
              Element: (
                <React.Suspense fallback={<SuspenseSpin />}>
                  <TrendViewSection
                    disableReloadOnZoom={true}
                    signal={selectedSignal}
                    headerProps={{
                      noTitle: true,
                      noStatistics: true,
                      noMaximize: true,
                    }}
                    chartStyles={{
                      chart: { minHeight: 288, height: "calc(50vh - 350px)" },
                    }}
                    showConditionStatus={false}
                  />
                </React.Suspense>
              ),
            })
          )}
        />
      )}
    </>
  );
};
