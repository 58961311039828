import { mean } from 'lodash-es';

export const getStandardDeviation = (n: number, x: number[]) => {
  const sampleMean = mean(x);

  let sum = 0;
  for (let i = 0; i < n; i++) {
    const valueToAdd = (x[i] - sampleMean) * (x[i] - sampleMean);
    sum += valueToAdd;
  }

  const standardDeviation = Math.sqrt(sum / (n - 1));

  return standardDeviation;
};

export const getPearsonCorrelationCoefficient = (n: number, x: number[], y: number[]) => {
  let sumXY = 0,
    sumX = 0,
    sumY = 0,
    sumXSquare = 0,
    sumYSquare = 0;

  for (let i = 0; i < n; i++) {
    sumXY += x[i] * y[i];
    sumX += x[i];
    sumY += y[i];
    sumXSquare += x[i] * x[i];
    sumYSquare += y[i] * y[i];
  }

  const up = n * sumXY - sumX * sumY;
  const down = Math.sqrt(
    Math.abs(n * sumXSquare - sumX * sumX) * Math.abs(n * sumYSquare - sumY * sumY),
  );

  return up / down;
};
