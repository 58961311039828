import type { IDialogProps } from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import React, { useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import { useLocationSearch } from "../../../../../Hooks";
import { useTagsAdd, useTagsQuery } from "../../hooks/useTagsQuery";
import type { CommentTag } from "../../../../../types";
import Dialog from "../../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../common/Notification";

const dialogAddTagProps = {
  type: DialogType.normal,
  title: "Add tag",
  closeButtonAriaLabel: "Close",
};

const getSchema = (tags: CommentTag[]) =>
  z
    .object({
      name: z.string(),
    })
    .refine(
      ({ name }) => {
        const nameList = tags.map((tag) => tag.name.toLowerCase());
        return !nameList.includes(name.toLowerCase());
      },
      { path: ["name"], message: "Tag already exists" }
    );

const fields: FormItemProps[] = [
  {
    name: "name",
    type: FormItemType.TextField,
    groupProps: { label: "Tag" },
    placeholder: "Tag name",
  },
];

type AddTagDialogProps = IDialogProps;

const AddTagDialog: React.FC<AddTagDialogProps> = (props) => {
  const [{ id: machineId }, ,] = useLocationSearch();
  const [isLoading, setIsLoading] = useState(false);

  const modalProps = useMemo(
    () => ({
      titleAriaId: "dialogLabel",
      subtitleAriaId: "subTextLabel",
      isBlocking: false,
    }),
    []
  );

  const { tags, refetch } = useTagsQuery({ machineId: machineId as string });

  const schema = useMemo(() => getSchema(tags), [tags]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({ schema });

  const onFinish = () => {
    refetch();
    onClose();
    notification.success(`Tag created successfully`);
  };

  const { addTagAsync } = useTagsAdd();

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    addTagAsync({ ...data, name: data.name, machineId: machineId as string })
      .then(() => onFinish())
      .catch(() => notification.error("Error adding tag"))
      .finally(() => setIsLoading(false));
    return;
  });

  const onClose = () => {
    reset();
    props.onDismiss?.();
  };

  return (
    <Dialog
      {...props}
      dialogContentProps={dialogAddTagProps}
      modalProps={modalProps}
    >
      <form onSubmit={onSubmit}>
        {renderFormItems(fields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text="Create"
            disabled={isLoading}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text="Cancel"
            onClick={onClose}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export default AddTagDialog;
