import type { SVGProps } from 'react';
import React from 'react';

const CheckboxFullIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V11C0 11.7957 0.316071 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H11C11.7957 14 12.5587 13.6839 13.1213 13.1213C13.6839 12.5587 14 11.7957 14 11V3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7957 0 11 0H3ZM10.854 4.854L5.854 9.854C5.80756 9.90056 5.75238 9.93751 5.69163 9.96271C5.63089 9.98792 5.56577 10.0009 5.5 10.0009C5.43423 10.0009 5.36911 9.98792 5.30837 9.96271C5.24762 9.93751 5.19245 9.90056 5.146 9.854L3.146 7.854C3.05211 7.76011 2.99937 7.63278 2.99937 7.5C2.99937 7.36723 3.05211 7.23989 3.146 7.146C3.23989 7.05211 3.36722 6.99937 3.5 6.99937C3.63278 6.99937 3.76011 7.05211 3.854 7.146L5.5 8.793L10.146 4.146C10.1925 4.09951 10.2477 4.06264 10.3084 4.03748C10.3692 4.01232 10.4343 3.99937 10.5 3.99937C10.5657 3.99937 10.6308 4.01232 10.6916 4.03748C10.7523 4.06264 10.8075 4.09951 10.854 4.146C10.9005 4.19249 10.9374 4.24768 10.9625 4.30842C10.9877 4.36916 11.0006 4.43426 11.0006 4.5C11.0006 4.56574 10.9877 4.63084 10.9625 4.69158C10.9374 4.75232 10.9005 4.80751 10.854 4.854Z'
        fill='#2C529F'
      />
    </svg>
  );
};

export default CheckboxFullIcon;
