export const trendsGroupingOptions = [
  { key: 'separated', text: 'Separated' },
  { key: 'grouped by machine', text: 'Grouped by machine' },
  { key: 'group everything', text: 'Group everything' },
];

export const periodOptions = [
  { key: 'All data', text: 'All data' },
  { key: 'Last 2 years', text: 'Last 2 years' },
  { key: 'Last year', text: 'Last year' },
  { key: 'Last quarter', text: 'Last quarter' },
  { key: 'Last month', text: 'Last month' },
  { key: 'Last week', text: 'Last week' },
  { key: 'Custom date', text: 'Custom date' },
];

export enum PeriodOptionsEnum {
  LastYear = 'Last year',
  LastQuarter = 'Last quarter',
  LastMonth = 'Last month',
  LastWeek = 'Last week',
  CustomDate = 'Custom date',
}

export const MAX_GROUP_THRESHOLD = 8;
export const CHART_NUMBER_OF_POINTS = 3000;
