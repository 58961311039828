import { useQuery } from "@tanstack/react-query";
import orderBy from "lodash-es/orderBy";

import { getApiClient } from "../../../../modules/core/apiClient/useApiStore";

type UseThreadsQuery = {
  threads: any[];
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

export type UseThreadsQueryOpts = {
  machineId: string;
};

export const useThreadsQuery = (opts: UseThreadsQueryOpts): UseThreadsQuery => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["machine-cv-threads", opts],
    () =>
      getApiClient()
        .get<any[]>(`meta/machinecv/v1/comments/machines/${opts.machineId}`)
        .then(({ data }) => orderBy(data, ["createdAt"], ["desc"])),
    {
      cacheTime: 0,
    }
  );

  return {
    threads: data,
    isLoading,
    isError,
    refetch,
  };
};
