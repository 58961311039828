import type { IObjectWithKey } from "@fluentui/react";
import { useMemo } from "react";

import {
  useLocationSearch,
  useFileUpload,
  useMachineUsersQuery,
} from "../../../Hooks";

import DeleteDialog from "../../../modules/common/components/Dialogs/DeleteDialog";
import AddDocumentDialog from "../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import { getColumns } from "./components/columns";
import { CommandBar } from "./components/CommandBar";
import type { DocumentResponse } from "./hooks/useDocumentsQuery";
import { useDocumentsQuery } from "./hooks/useDocumentsQuery";
import useDocumentsStore from "./hooks/useDocumentsStore";
import type { TableProps } from "../../common/Table";
import Table, { useTableFilters } from "../../common/Table";
import type { UploadedFile } from "../../common/Uploader/types";

const Page = () => {
  const [{ id }, ,] = useLocationSearch();

  const { queryDocuments, isError, isLoading, refetch } = useDocumentsQuery({
    machineId: id || "",
  });

  const { usersList, isLoading: usersLoading } = useMachineUsersQuery({
    machineId: id as string,
  });

  const { filters, handleSearch } = useTableFilters<DocumentResponse>({
    keys: ["name", "description", "type"],
  });

  const { selectedItems, showDelete, showUpload, dispatch } = useDocumentsStore(
    (state) => ({
      showDelete: state.showDelete,
      selectedItems: state.selectedItems as DocumentResponse[],
      showUpload: state.showUpload,
      dispatch: state.dispatch,
    })
  );

  const handleSelect = (items: IObjectWithKey[]) => {
    dispatch({ type: "SET_SELECTION", payload: items });
  };

  const pageLoading = useMemo(
    () => isLoading || usersLoading,
    [isLoading, usersLoading]
  );

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents",
        version: 1,
      },
      header: {
        title: "Documents",
      },
      items: queryDocuments,
      filters,
      isLoading: pageLoading,
      isError,
      columns: getColumns({ users: usersList }),
      onSelectionChanged: (items: IObjectWithKey[]) => handleSelect(items),
    }),
    [filters, queryDocuments, pageLoading, isError]
  );

  const { uploadFilesAsyncWithNotifications } = useFileUpload();

  const onUpload = async (files: UploadedFile[]) => {
    await uploadFilesAsyncWithNotifications({ files, machineId: id || "" });
    refetch();
  };

  return (
    <>
      <div style={{ padding: "0 16px" }}>
        <CommandBar handleSearch={handleSearch} />
        <Table {...tableProps} />
      </div>
      <DeleteDialog
        dialogContentProps={{
          onDismiss: () => dispatch({ type: "SHOW_DELETE", payload: false }),
        }}
        data={{
          ids: selectedItems.map((item) => item?.id),
          name: selectedItems.length === 1 ? selectedItems[0]?.name : "items",
        }}
        hidden={!showDelete}
        route={"/files/v1/files"}
        onSuccess={refetch}
      />
      <AddDocumentDialog
        hidden={!showUpload}
        onSubmit={onUpload}
        onClose={() => dispatch({ type: "SHOW_UPLOAD", payload: false })}
      />
    </>
  );
};

export default Page;
