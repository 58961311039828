import {
  DirectionalHint,
  IconButton,
  Text,
  Toggle,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import type { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Stack } from "@fluentui/react/lib/Stack";
import { format as fnsFormat } from "date-fns";
import { useEffect, useRef, useState } from "react";

import RefreshIcon from "../../../../assets/svg/RefreshIcon";
import PeriodIcon from "../../../../assets/svg/TasksIcon";
import TrendsIcon from "../../../../assets/svg/TrendsIcon";
import { FORMAT } from "../../../analysis-trend-view/utils/getPeriodFilters";
import { refreshOptions } from "../../../common/constants/refreshOptions";
import useRefreshStore from "../../../common/hooks/useRefreshStore";
import type { CommandBarItemProps } from "../../../../Components/common/CommandBar";
import {
  CommandBarItemType,
  computeCommandBarItems,
  default as BaseCommandBar,
} from "../../../../Components/common/CommandBar";

import CustomDateDialog from "../../../common/components/CustomDateDialog";
import {
  periodOptions,
  PeriodOptionsEnum,
  trendsGroupingOptions,
} from "../../constants/controlOptions";
import TrendGroupingEnum from "../../constants/TrendGroupingEnum";
import useGroupingErrorMessages from "../../hooks/useGroupingErrorMessages";
import TrendViewSelectSignalsInfo from "../TrendViewSelectSignalsInfo";
import { ErrorDialog } from "./ErrorDialog";
import ReferenceSignalDropdown from "./ReferenceSignalDropdown";
import { commandBarStyles, dropdownIconStyle } from "./TrendView.styles";
import useTrendViewControls from "./useTrendViewControls";

const styles = {
  headerText: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    minHeight: 30,
  },
};

interface TrendViewControlsProps {
  title: string;
}

export const topControlsStyles = {
  root: {
    background: "#FAF9F8",
    width: "100%",
    zIndex: 9,
    minHeight: 62,
  },
};

const TrendViewControls: React.FC<TrendViewControlsProps> = ({ title }) => {
  const {
    controlsStore,
    selectedSignalsStore: {
      errorMessage,
      isErrorMessageOpen,
      toggleIsErrorMessageOpen,
      changeErrorMessage,
    },
  } = useTrendViewControls();

  const [visibleCustomDateDialog, setVisibleCustomDateDialog] = useState(false);

  const { getGroupByMachineErrorMessage, getGroupEverythingErrorMessage } =
    useGroupingErrorMessages();

  const handleDismiss = () => {
    toggleIsErrorMessageOpen();
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { refreshInterval, setRefreshInterval } = useRefreshStore((store) => ({
    refreshInterval: store.refreshInterval,
    setRefreshInterval: store.setRefreshInterval,
  }));

  useEffect(() => {
    if (wrapperRef.current) {
      // CommandBar needs a wrapper with a width of 100%
      wrapperRef.current.children?.[0]?.setAttribute("style", "width: 100%;");
    }
  }, []);

  const items: CommandBarItemProps[] = [
    {
      key: "trends",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: trendsGroupingOptions,
        selectedKey: controlsStore.trendsGrouping?.key || undefined,
        onChange: (_: never, item: IDropdownOption) => {
          if (item?.key === TrendGroupingEnum.GROUP_BY_MACHINE) {
            const message = getGroupByMachineErrorMessage();
            if (message) {
              toggleIsErrorMessageOpen();
              changeErrorMessage(message);
              return;
            }
          }
          if (item?.key === TrendGroupingEnum.GROUP_EVERYTHING) {
            const message = getGroupEverythingErrorMessage();
            if (message) {
              toggleIsErrorMessageOpen();
              changeErrorMessage(message);
              return;
            }
          }
          controlsStore.changeTrendsGrouping(item);
        },
        onRenderLabelProps: {
          label: "Trends",
          icon: <TrendsIcon fill="#2c529f" style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "reference",
      type: CommandBarItemType.Custom,
      onRender: () => <ReferenceSignalDropdown />,
    },
    {
      key: "period",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: periodOptions,
        disabled: refreshInterval?.key !== "off",
        selectedKey: controlsStore.period?.key || undefined,
        onChange: (_: never, item: IDropdownOption) => {
          if (item?.key !== PeriodOptionsEnum.CustomDate) {
            controlsStore.changePeriod(item);
          } else {
            setVisibleCustomDateDialog(true);
          }
        },
        onRenderLabelProps: {
          label: "Period",
          icon: <PeriodIcon fill="#2c529f" style={dropdownIconStyle} />,
        },
      },
    },
    /// Remove Live data command bar item.
    {
      key: "refresh",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: refreshOptions,
        selectedKey: refreshInterval?.key || undefined,
        onChange: (_: never, item: IDropdownOption) => setRefreshInterval(item),
        onRenderLabelProps: {
          label: "Live data",
          icon: <RefreshIcon fill="#2c529f" style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "toggle",
      type: CommandBarItemType.Button,
      onRender: () => (
        <Toggle
          inlineLabel
          label="Average trend"
          checked={controlsStore.averageTrend}
          styles={{ root: { marginBottom: 0, marginRight: 20 } }}
          onChange={(_, checked) => controlsStore.changeAverageTrend(checked)}
        />
      ),
    },
    {
      key: "toggle_2",
      type: CommandBarItemType.Button,
      onRender: () => (
        <Toggle
          inlineLabel
          label="Connect time axis"
          checked={controlsStore.isTimeAxisConnected}
          styles={{ root: { marginBottom: 0 } }}
          onChange={(_, checked) => controlsStore.changeTimeAxis(checked)}
        />
      ),
    },
  ];

  // Remove Reference command bar item
  if (controlsStore.trendsGrouping?.key !== TrendGroupingEnum.SEPARATED) {
    items.splice(1, 1);
  }

  const onCustomDateChange = ({ start, end }: any) => {
    controlsStore.changePeriod({
      key: PeriodOptionsEnum.CustomDate,
      text: PeriodOptionsEnum.CustomDate,
      startDate: fnsFormat(new Date(start), FORMAT),
      endDate: fnsFormat(new Date(end), FORMAT),
    });
  };

  return (
    <>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        styles={topControlsStyles}
        id="top-controls"
      >
        <Stack horizontal className="sidebar-header">
          <Text style={styles.headerText}>{title}</Text>
          <TooltipHost
            tooltipProps={{
              onRenderContent: TrendViewSelectSignalsInfo,
            }}
            delay={TooltipDelay.zero}
            id="signal-info-tooltip"
            directionalHint={DirectionalHint.bottomLeftEdge}
          >
            <IconButton
              aria-describedby="signal-info-tooltip"
              iconProps={{ iconName: "Info" }}
            />
          </TooltipHost>
        </Stack>
        <div ref={wrapperRef} style={{ width: "100%", marginLeft: 20 }}>
          <BaseCommandBar
            items={computeCommandBarItems(items)}
            styles={commandBarStyles}
          />
        </div>
      </Stack>
      <ErrorDialog
        hidden={!isErrorMessageOpen}
        content={errorMessage}
        onDismiss={handleDismiss}
      />
      <CustomDateDialog
        initialValues={controlsStore.period}
        hidden={!visibleCustomDateDialog}
        setVisible={setVisibleCustomDateDialog}
        onSubmit={onCustomDateChange}
      />
    </>
  );
};

export default TrendViewControls;
