import { useQuery } from '@tanstack/react-query';
import { forEach } from 'lodash-es';

import { WS_SIDEBAR_QUERY_KEY } from './useWSSidebarData';

export const useGatewayDetails = ({ serialNumber }: any) => {
  const { data, isLoading }: any = useQuery([WS_SIDEBAR_QUERY_KEY]);
  const result: any = {
    isLoading,
  };

  forEach(data?.corporations, (corporation) => {
    forEach(corporation?.companies, (company) => {
      forEach(company?.projects, (project) => {
        forEach(project?.gateways, (gateway) => {
          if (gateway?.serialNumber === serialNumber) {
            result.gateway = {
              ...gateway,
              corporation,
              company,
              project,
            };
          }
        });
      });
    });
  });

  return result;
};
