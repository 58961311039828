import { differenceInDays } from 'date-fns';

import { PeriodOptionsEnum } from '../../../analysis-trend-view/constants/controlOptions';
import DataTypeEnum from '../../../common/constants/DataTypeEnum';

export const checkZoomReloadAvailable = (
  signal: any,
  currentRange: any,
  selectedPeriod: any,
  zoomedDaysPeriod: any,
) => {
  const isFastTrend = signal.dataType === DataTypeEnum.FastTrend;
  let isAvailable;

  if (isFastTrend) {
    isAvailable = zoomedDaysPeriod < 1;
  } else {
    if (
      selectedPeriod?.key === PeriodOptionsEnum.CustomDate &&
      selectedPeriod.startDate &&
      selectedPeriod.endDate
    ) {
      isAvailable =
        differenceInDays(new Date(selectedPeriod.endDate), new Date(selectedPeriod.startDate)) >
          32 && zoomedDaysPeriod < 32;
    } else {
      isAvailable =
        selectedPeriod?.key !== PeriodOptionsEnum.LastWeek &&
        selectedPeriod?.key !== PeriodOptionsEnum.LastMonth &&
        zoomedDaysPeriod < 32;
    }
  }

  return isAvailable;
};

export const checkAvailableCache = (cachePeriod: any, currentRange: any) => {
  let isCacheDataAvailable = false;
  if (cachePeriod?.start && cachePeriod?.end) {
    const zoomStart = new Date(currentRange.startIndex).getTime();
    const zoomEnd = new Date(currentRange.endIndex).getTime();
    const cacheInternalPeriodStart = new Date(cachePeriod.start).getTime();
    const cacheInternalPeriodEnd = new Date(cachePeriod.end).getTime();
    isCacheDataAvailable =
      zoomStart >= cacheInternalPeriodStart && zoomEnd <= cacheInternalPeriodEnd;
  }

  return isCacheDataAvailable;
};
