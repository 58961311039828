import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react';
import React from 'react';

import type { TrendViewRawDetails } from '../../types';

interface TrendViewInfoProps {
  rawDetails: TrendViewRawDetails;
  referenceSignalRawDetails: TrendViewRawDetails | undefined;
}

const TrendViewInfo: React.FC<TrendViewInfoProps> = ({ rawDetails, referenceSignalRawDetails }) => {
  return (
    <div
      style={{
        backgroundColor: '#FAF9F8',
        marginTop: 16,
      }}
    >
      <DetailsList
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        items={[
          {
            'All data': 'Reference',
            Last: referenceSignalRawDetails ? referenceSignalRawDetails.all.last : '-',
            Min: referenceSignalRawDetails ? referenceSignalRawDetails.all.minimum : '-',
            Max: referenceSignalRawDetails ? referenceSignalRawDetails.all.maximum : '-',
            Avg: referenceSignalRawDetails ? referenceSignalRawDetails.all.average : '-',
            StDev: referenceSignalRawDetails ? referenceSignalRawDetails.all.stDev : '-',
            RCorr: '-',
          },
          {
            'All data': 'Signal',
            Last: rawDetails.all.last,
            Min: rawDetails.all.minimum,
            Max: rawDetails.all.maximum,
            Avg: rawDetails.all.average,
            StDev: rawDetails.all.stDev,
            RCorr: isNaN(rawDetails.all.rCorr) ? '-' : rawDetails.all.rCorr,
          },
        ]}
      />
      <DetailsList
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        items={[
          {
            'Current view': 'Reference',
            Last: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.last : '-',
            Min: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.minimum : '-',
            Max: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.maximum : '-',
            Avg: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.average : '-',
            StDev: referenceSignalRawDetails ? referenceSignalRawDetails.selectedRange.stDev : '-',
            'R Corr': '-',
          },
          {
            'Current view': 'Signal',
            Last: rawDetails.selectedRange.last,
            Min: rawDetails.selectedRange.minimum,
            Max: rawDetails.selectedRange.maximum,
            Avg: rawDetails.selectedRange.average,
            StDev: rawDetails.selectedRange.stDev,
            'R Corr': isNaN(rawDetails.selectedRange.rCorr) ? '-' : rawDetails.selectedRange.rCorr,
          },
        ]}
      />
    </div>
  );
};

export default TrendViewInfo;
