import type { SVGProps } from 'react';
import React from 'react';

const FluentSchedule: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
      <path
        fill='#2C529F'
        d='M8.5 2a1.5 1.5 0 0 0-1.415 1H5.5A1.5 1.5 0 0 0 4 4.5v12A1.5 1.5 0 0 0 5.5 18h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 14.5 3h-1.585A1.5 1.5 0 0 0 11.5 2h-3ZM8 3.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM5.5 4h1.585A1.5 1.5 0 0 0 8.5 5h3a1.5 1.5 0 0 0 1.415-1H14.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5Zm7.5 8a.5.5 0 0 0-1 0v2.5a.5.5 0 0 0 1 0V12Zm-3-3a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0v-5A.5.5 0 0 1 10 9ZM8 7.5a.5.5 0 1 0-1 0v7a.5.5 0 0 0 1 0v-7Z'
      />
    </svg>
  );
};

export default FluentSchedule;
