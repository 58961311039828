import ChartSymbolIcon from "./ChartSymbolIcon";
import ChartEighthSymbolIconPlain from "./ChartSymbols/ChartEighthSymbolIcon.svg";
import ChartFifthSymbolIconPlain from "./ChartSymbols/ChartFifthSymbolIcon.svg";
import ChartFirstSymbolIconPlain from "./ChartSymbols/ChartFirstSymbolIcon.svg";
import ChartFourthSymbolIconPlain from "./ChartSymbols/ChartFourthSymbolIcon.svg";
import ChartSecondSymbolIconPlain from "./ChartSymbols/ChartSecondSymbolIcon.svg";
import ChartSeventhSymbolIconPlain from "./ChartSymbols/ChartSeventhSymbolIcon.svg";
import ChartSixthSymbolIconPlain from "./ChartSymbols/ChartSixthSymbolIcon.svg";
import ChartThirdSymbolIconPlain from "./ChartSymbols/ChartThirdSymbolIcon.svg";

const generateChartSymbols = (colors: any) => {
  const types = [
    "rectangle",
    "rectangle",
    "diamond",
    "diamond",
    "circle",
    "circle",
    "hexagon",
    "octagon",
  ];
  return colors.map((color: any, index: any) => (
    <ChartSymbolIcon key={index} type={types[index]} color={color} />
  ));
};

export default {
  components: generateChartSymbols([
    "blue",
    "red",
    "pink",
    "green",
    "yellow",
    "purple",
    "lime",
    "cyan",
  ]),
  svgs: [
    ChartFirstSymbolIconPlain,
    ChartSecondSymbolIconPlain,
    ChartThirdSymbolIconPlain,
    ChartFourthSymbolIconPlain,
    ChartFifthSymbolIconPlain,
    ChartSixthSymbolIconPlain,
    ChartSeventhSymbolIconPlain,
    ChartEighthSymbolIconPlain,
  ],
  colors: [
    "#2B88D8",
    "#CC0500",
    "#FF00FF",
    "#AFE085",
    "#D69100",
    "#8B4B8B",
    "#32CD32",
    "#00CED1",
  ],
};
