import { useMutation, useQuery } from "@tanstack/react-query";

import { getApiClient } from "../../../modules/core/apiClient/useApiStore";

export type UseSensorTriggeringQueryProps = {
  projectId: string;
};

export const useSensorTriggeringQuery = (
  { projectId }: UseSensorTriggeringQueryProps,
  options: any = {}
) => {
  const {
    data = {},
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["ws-sensor-triggering"],
    () =>
      getApiClient()
        .get(`/dws/v1/triggers/programmed/projects/${projectId}`)
        .then(({ data }) => data),
    {
      cacheTime: 0,
      retry: false,
      ...options,
    }
  );

  return {
    refetch,
    data,
    isLoading,
    isError,
  };
};

export const useSensorTriggeringUpdate = ({ projectId }: any) => {
  const { mutateAsync } = useMutation((data: any) =>
    getApiClient().put(
      `/dws/v1/triggers/programmed/projects/${projectId}`,
      data
    )
  );

  return { updateSensorTriggeringAsync: mutateAsync };
};

export const useSensorTriggeringCreate = ({ projectId }: any) => {
  const { mutateAsync } = useMutation((data: any) =>
    getApiClient().post(`/dws/v1/triggers/programmed/projects`, {
      ...data,
      projectId,
    })
  );

  return { createSensorTriggeringAsync: mutateAsync };
};

export const useSensorManualTriggering = ({ sensorId }: any) => {
  const { mutateAsync } = useMutation((data: any) =>
    getApiClient().post(`/dws/v1/triggers/on-demand/sensors/${sensorId}`, data)
  );

  return { updateSensorManualTriggering: mutateAsync };
};
