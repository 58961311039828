import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

interface HeaderControlsStore {
  prevTimestamp: number;
  nextTimestamp: number;
  period: any;
  isRawDataPointsActive: boolean;
  setPrevTimestamp: () => void;
  setNextTimestamp: () => void;
  changePeriod: (period: any) => void;
  setActiveRawDataPoints: (isRawDataPointsActive: boolean | undefined) => void;
}

const useHeaderControlsStore = createWithEqualityFn<HeaderControlsStore>((set) => ({
  prevTimestamp: 0,
  nextTimestamp: 0,
  period: { key: 'Last month', text: 'Last month' },
  isRawDataPointsActive: true,
  setPrevTimestamp: () => set((state) => ({ prevTimestamp: state.prevTimestamp + 1 })),
  setNextTimestamp: () => set((state) => ({ nextTimestamp: state.nextTimestamp + 1 })),
  changePeriod: (period: any) => set({ period }),
  setActiveRawDataPoints: (isRawDataPointsActive: boolean | undefined) =>
    set({ isRawDataPointsActive }),
}), shallow);

export default useHeaderControlsStore;
