import {
  Text,
  Stack,
  getTheme,
  Callout,
  mergeStyleSets,
  FontWeights,
  Icon,
} from "@fluentui/react";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { topMenuHeight } from "../../schema/Constants";
import { leftMenuIsExpandContext } from "../Layout/Layout";
import Account from "./Account";
import { UserMenu } from "./UserMenu";
import { useAuth0 } from "@auth0/auth0-react";
import { detailsUsersRBAC } from "../UsersRBCA/api";
import { MyAccountInfo } from "../UsersRBCA/models";

import { notification } from "../common/Notification";

import EditUserProfileDialog from "./EditUserProfileDialog";
import { Link, useNavigate } from "react-router-dom";
import UpdateUserPhotoDialog from "./UpdateUserPhotoDialog";
import dalogLogo from "../../assets/images/dalog-white-logo.svg";
import {
  LocalAccountInfo,
  IsClickedUpdateUserContext,
  IsClickedUpdateProfilePhotoContext,
  IsClickedUpdateUserNotificationsContext,
  IsClickedAccountIconContext,
} from "./context";
import { useAuthorization } from "../../Hooks";
import { usersWithoutPermissions } from "./api";

interface AccountInfoSimple {
  name: string;
  id: string;
}

const theme = getTheme();

const menuTopLeftId = "callout-menuTopLeft";

const textStyle: CSSProperties = {
  color: theme.palette.white,
  textAlign: "center",
  marginRight: "5em", // * Helps to center the title in the WebApp.
};

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    padding: "10px",
  },
  title: {
    marginBottom: 5,
    fontWeight: FontWeights.semilight,
  },
});

const containerStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  background: "var(--dalog-blue)",
  width: `100vw`,
  height: topMenuHeight,
  zIndex: 10,
  padding: "0px 20px 0px 20px",
};

const iconStyle = {
  color: "white",
  fontSize: "24px",
};

const badgeStyle: CSSProperties = {
  position: "absolute",
  top: "-5px",
  right: "-10px",
  backgroundColor: "red",
  color: "white",
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
};

export const TopMenu: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const context = useContext(leftMenuIsExpandContext);
  const [isCalloutUserVisible, setIsCalloutUserVisible] = useState(false);
  const [isModalUserUpdateVisible, setIsModalUserUpdateVisible] =
    useState(false);
  const navigate = useNavigate();
  const [
    isModalUserProfilePhotoUpdateVisible,
    setIsModalUserProfilePhotoUpdateVisible,
  ] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const [myAccount, setMyAccount] = useState<MyAccountInfo>();
  const auth = useAuthorization();
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    if (!isAuthenticated) return;

    let intervalId: NodeJS.Timeout;

    const fetchUsersWithoutPermissions = async () => {
      if (auth.userAdministrator) {
        try {
          const response = await usersWithoutPermissions();
          setUserCount(response.length);
        } catch (error) {
          console.error("Error fetching users without permissions:", error);
        }
      }
    };

    fetchUsersWithoutPermissions(); // Initial call

    if (auth.userAdministrator) {
      intervalId = setInterval(fetchUsersWithoutPermissions, 60000); // Call every minute
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [auth.userAdministrator, isAuthenticated]);

  // Gets the user details.
  useEffect(() => {
    if (!user) return;
    const aux: AccountInfoSimple = {
      name: user?.email ?? "",
      id: user?.sub ?? "",
    };
    sessionStorage.setItem("accountInfoMin", JSON.stringify(aux));
    detailsUsersRBAC(user.sub).then((resp) =>
      "properties" in resp
        ? resp.properties
          ? setMyAccount({
              ...resp.properties,
              pictureBase64: resp.pictureBase64,
            })
          : setMyAccount({
              email: "",
              firstName: "",
              lastName: "",
              memberId: user?.sub ?? "",
              pictureBase64: resp.pictureBase64,
            })
        : notification.warning("Issue catching current user data")
    );
  }, [user]);

  useEffect(() => {
    if (myAccount) {
      sessionStorage.setItem("accountInfo", JSON.stringify(myAccount));
    }
  }, [myAccount]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        context?.setValue(false);
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [context]);

  const onSuccess = (hasError: boolean, displayName: string): void => {
    if (hasError) {
      const message = `Failed updating ${displayName}`;
      notification.error(message);
    } else {
      const message = `${displayName} updated successfully`;
      notification.success(message);
    }
  };

  return (
    <LocalAccountInfo.Provider value={{ val: myAccount, set: setMyAccount }}>
      {isCalloutUserVisible && (
        <Callout
          className={styles.callout}
          ariaLabelledBy={"callout-menuUser"}
          ariaDescribedBy={"callout-menuUser"}
          role="dialog"
          gapSpace={0}
          target={`#${menuTopLeftId}`}
          onDismiss={() => setIsCalloutUserVisible(false)}
          setInitialFocus
        >
          <IsClickedUpdateUserContext.Provider
            value={setIsModalUserUpdateVisible}
          >
            <IsClickedUpdateProfilePhotoContext.Provider
              value={setIsModalUserProfilePhotoUpdateVisible}
            >
              <IsClickedUpdateUserNotificationsContext.Provider
                value={(v) => {
                  navigate("/notifications");
                }}
              >
                <UserMenu handleDismiss={setIsCalloutUserVisible} />
              </IsClickedUpdateUserNotificationsContext.Provider>
            </IsClickedUpdateProfilePhotoContext.Provider>
          </IsClickedUpdateUserContext.Provider>
        </Callout>
      )}
      {isModalUserUpdateVisible && (
        <EditUserProfileDialog
          onSuccess={onSuccess}
          onClose={() => setIsModalUserUpdateVisible(false)}
        />
      )}
      {isModalUserProfilePhotoUpdateVisible && (
        <UpdateUserPhotoDialog
          onSuccess={onSuccess}
          onClose={() => setIsModalUserProfilePhotoUpdateVisible(false)}
        />
      )}
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        style={containerStyle}
      >
        <Stack.Item style={{ width: "7em" }}>
          <img src={dalogLogo} alt="DALOG logo" />
        </Stack.Item>
        <Text as="h1" variant="mediumPlus" style={textStyle}>
          DALOG Data Platform
        </Text>
        <Stack horizontal verticalAlign="center">
          {auth.userAdministrator && userCount > 0 && (
            <Stack.Item style={{ marginRight: "1em" }}>
              <Link to="/users?tab=pending" style={{ textDecoration: "none" }}>
                <Stack.Item
                  style={{ marginRight: "1em", position: "relative" }}
                >
                  <Icon iconName="AddGroup" styles={{ root: iconStyle }} />
                  <div style={badgeStyle}>{userCount}</div>
                </Stack.Item>
              </Link>
            </Stack.Item>
          )}
          <Stack.Item>
            <IsClickedAccountIconContext.Provider
              value={setIsCalloutUserVisible}
            >
              <Account id={menuTopLeftId} />
            </IsClickedAccountIconContext.Provider>
          </Stack.Item>
        </Stack>
      </Stack>
    </LocalAccountInfo.Provider>
  );
};
