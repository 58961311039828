import { useQuery } from "@tanstack/react-query";

import { getApiClient } from "../../../../modules/core/apiClient/useApiStore";

export type DocumentResponse = {
  added: string;
  description: string;
  fileName: string;
  id: string;
  machineId: string;
  md5Hash: string;
  name: string;
  sizeInBytes: number;
  type: string;
  userId: string;
};

type UseDocumentsQuery = {
  queryDocuments: DocumentResponse[];
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

export type UseDocumentsQueryOpts = {
  machineId: string;
};

export const useDocumentsQuery = (
  opts: UseDocumentsQueryOpts
): UseDocumentsQuery => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["machine-cv-documents", opts],
    () =>
      getApiClient()
        .get<DocumentResponse[]>(`files/v1/files/machines/${opts.machineId}`)
        .then(({ data }) => data),
    {
      cacheTime: 0,
    }
  );

  return {
    queryDocuments: data,
    isLoading,
    isError,
    refetch,
  };
};
