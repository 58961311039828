import type { IDropdownOption } from "@fluentui/react";
import { useMemo } from "react";

import { useTrendMeasuredDataInAPeriod } from "../../../Hooks";
import type { ResponseSimplifiedSignal } from "../../../types";

import { CHART_NUMBER_OF_POINTS } from "../constants/controlOptions";
import getPeriodFilters from "../utils/getPeriodFilters";
import useControlsStore from "./useControlsStore";

interface UseSingleTrendMeasuredData {
  machineId: string | undefined;
  signalId: string | undefined;
  queryEnabled: boolean;
  signal: ResponseSimplifiedSignal;
  refreshInterval?: IDropdownOption<any> | undefined;
}

const useSingleTrendViewMeasuredData = ({
  machineId,
  signalId,
  signal,
  queryEnabled,
  refreshInterval,
}: UseSingleTrendMeasuredData) => {
  const period = useControlsStore((store) => store.period);

  const filters = useMemo(
    () => getPeriodFilters(period, signal),
    [
      period?.key,
      period?.startDate,
      period?.endDate,
      signal,
      refreshInterval?.key,
    ]
  );

  const queryResult = useTrendMeasuredDataInAPeriod(
    {
      machineId,
      signalId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      numberOfPoints: CHART_NUMBER_OF_POINTS,
      signalType: signal?.dataType,
      refreshInterval,
    },
    {
      enabled: queryEnabled,
    }
  );

  const plotAxis = useMemo(
    () => ({
      x: (queryResult.data || []).map(({ timeStamp }) => timeStamp),
      y: (queryResult.data || []).map(({ value }) => value),
    }),
    [queryResult.data]
  );

  return { ...queryResult, plotAxis };
};

export default useSingleTrendViewMeasuredData;
