/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { IDropdownOption } from "@fluentui/react";
import {
  DirectionalHint,
  IconButton,
  Text,
  Toggle,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import { format as fnsFormat } from "date-fns";
import * as React from "react";
import { useEffect } from "react";
import { treeHandlers } from "react-hyper-tree";

import LayoutIcon from "../../../../assets/svg/LayoutIcon";
import RefreshIcon from "../../../../assets/svg/RefreshIcon";
import PeriodIcon from "../../../../assets/svg/TasksIcon";
import useSpectrumStore from "../../components/SpectrumChart/useSpectrumStore";
import { layoutOptions } from "../../constants/controlOptions";
import useHeaderControlsStore from "../../hooks/useHeaderControlsStore";
import useLayoutStore from "../../hooks/useLayoutStore";
import useSelectedDataPointsStore from "../../hooks/useSelectedDataPointsStore";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import type { HeaderControlsProps } from "../../types";
import { TrendViewSelectSignalsInfo } from "../../../analysis-trend-view";
import {
  periodOptions,
  PeriodOptionsEnum,
} from "../../../analysis-trend-view/constants/controlOptions";
import { FORMAT } from "../../../analysis-trend-view/utils/getPeriodFilters";
import CustomDateDialog from "../../../common/components/CustomDateDialog";
import DataTypeEnum from "../../../common/constants/DataTypeEnum";
import { refreshOptions } from "../../../common/constants/refreshOptions";
import { dropdownIconStyle } from "../../../../assets/styles";
import type { ResponseSimplifiedSignal } from "../../../../types";
import BaseCommandBar from "../../../../Components/common/CommandBar";
import type { CommandBarItemProps } from "../../../../Components/common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
  dropdownStyles,
} from "../../../../Components/common/CommandBar/methods";

import useRefreshStore from "../../../common/hooks/useRefreshStore";
import { infoIcon, nextIcon, previousIcon } from "../../constants/icons";
import useLayoutTypes from "../../hooks/useLayoutTypes";
import {
  baseCommandBar,
  baseCommandBarWrapper,
  dropdownIcon,
  headerText,
  topControls,
} from "./HeaderControls.styles";

interface CustomLabelProps {
  text: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  disabled: boolean;
}

export const renderCustomLabel = ({
  text,
  icon: CustomIcon,
  disabled,
}: CustomLabelProps) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CustomIcon
        fill={disabled ? "#c8c6c4" : "#2c529f"}
        style={dropdownIcon}
      />
      <span style={{ color: disabled ? "#c8c6c4" : "" }}>{text}</span>
    </div>
  );
};

const HeaderControls = ({ title }: HeaderControlsProps) => {
  const [isCustomPeriodVisible, setIsCustomPeriodVisible] =
    React.useState(false);
  const selectedDataPoints = useSelectedDataPointsStore(
    (store) => store.selectedDataPoints
  );

  const { isTrendLayout, isCompareLayout, isWaterfallLayout } =
    useLayoutTypes();

  const { selectedSignals, removeSelectedSignal } = useSelectedSignalsStore(
    (store) => ({
      selectedSignals: store.selectedSignals,
      removeSelectedSignal: store.removeSelectedSignal,
    })
  );

  const { resetSpectrumStore } = useSpectrumStore((state) => ({
    resetSpectrumStore: state.resetSpectrumStore,
  }));

  const {
    setPrevTimestamp,
    setNextTimestamp,
    period,
    changePeriod,
    setActiveRawDataPoints,
    isRawDataPointsActive,
  } = useHeaderControlsStore((store) => ({
    setPrevTimestamp: store.setPrevTimestamp,
    setNextTimestamp: store.setNextTimestamp,
    period: store.period,
    changePeriod: store.changePeriod,
    setActiveRawDataPoints: store.setActiveRawDataPoints,
    isRawDataPointsActive: store.isRawDataPointsActive,
  }));

  const { refreshInterval, setRefreshInterval } = useRefreshStore((store) => ({
    refreshInterval: store.refreshInterval,
    setRefreshInterval: store.setRefreshInterval,
  }));

  const { layout, changeLayout } = useLayoutStore((store) => ({
    layout: store.layout,
    changeLayout: store.changeLayout,
  }));

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (wrapperRef.current) {
      // CommandBar needs a wrapper with a width of 100%
      wrapperRef.current.children?.[0]?.setAttribute("style", "width: 100%;");
    }
  }, []);

  const hasSelectedDataPoints = Object.values(selectedDataPoints).filter(
    (datapoint) => datapoint?.dataPoints.length > 0
  );

  const handleDropdownChange = (item: IDropdownOption<unknown> | undefined) => {
    const treeHandler = treeHandlers.trees["raw-data-signal-tree"].handlers;
    const trendItemKey = "trend-waveform-spectrum";
    const compareItemKey = "compare-waveform-spectrum";

    // reset spectrum chart store
    resetSpectrumStore();

    if (isTrendLayout && item?.key !== trendItemKey) {
      const itemToRemove = selectedSignals.find(
        (item) => item.dataType === DataTypeEnum.Trend
      );
      if (itemToRemove) {
        treeHandler.setSelected(itemToRemove.id, false);
        removeSelectedSignal(itemToRemove as ResponseSimplifiedSignal);
      }
    }

    if (isCompareLayout && item?.key !== compareItemKey) {
      const itemToRemove = selectedSignals.filter(
        (item) => item.dataType === DataTypeEnum.RawData
      );
      if (itemToRemove.length > 1) {
        itemToRemove.slice(0, -1).forEach((item) => {
          treeHandler.setSelected(item.id, false);
          removeSelectedSignal(item as ResponseSimplifiedSignal);
        });
      }
    }

    changeLayout(item);
  };

  useEffect(() => {
    if (isWaterfallLayout) {
      setActiveRawDataPoints(false);
    }
  }, [isWaterfallLayout]);

  function onPeriodChange(_: never, item: IDropdownOption) {
    if (item.key === "Custom date") {
      setIsCustomPeriodVisible(true);
    } else {
      changePeriod(item);
    }
  }

  const onCustomDateChange = ({ start, end }: any) => {
    changePeriod({
      key: PeriodOptionsEnum.CustomDate,
      text: PeriodOptionsEnum.CustomDate,
      startDate: fnsFormat(new Date(start), FORMAT),
      endDate: fnsFormat(new Date(end), FORMAT),
    });
  };

  const items: CommandBarItemProps[] = [
    {
      key: "layout",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        disabled:
          selectedSignals.length === 0 || hasSelectedDataPoints.length === 0,
        defaultSelectedKey: layout?.key || undefined,
        styles: dropdownStyles,
        title: "layout",
        options: layoutOptions,
        onChange: (_: never, item: IDropdownOption) =>
          handleDropdownChange(item),
        onRenderLabel: () =>
          renderCustomLabel({
            text: "Layout",
            icon: LayoutIcon,
            disabled:
              selectedSignals.length === 0 ||
              hasSelectedDataPoints.length === 0,
          }),
      },
    },
    {
      key: "prev",
      text: "Previous timestamp",
      type: CommandBarItemType.Button,
      iconProps: previousIcon,
      disabled:
        selectedSignals.length === 0 ||
        hasSelectedDataPoints.length === 0 ||
        isWaterfallLayout,
      style: { marginLeft: 16 },
      onClick: () => setNextTimestamp(),
    },
    {
      key: "next",
      text: "Next timestamp",
      type: CommandBarItemType.Button,
      iconProps: nextIcon,
      disabled:
        selectedSignals.length === 0 ||
        hasSelectedDataPoints.length === 0 ||
        isWaterfallLayout,
      style: { marginLeft: 16, marginRight: 26 },
      onClick: () => setPrevTimestamp(),
    },
    {
      key: "period",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: periodOptions,
        selectedKey: period?.key || undefined,
        onChange: onPeriodChange,
        onRenderLabelProps: {
          label: "Period",
          icon: <PeriodIcon fill="#2c529f" style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "refresh",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        options: refreshOptions,
        selectedKey: refreshInterval?.key || undefined,
        onChange: (_: never, item: IDropdownOption) => setRefreshInterval(item),
        onRenderLabelProps: {
          icon: <RefreshIcon fill="#2c529f" style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "toggle",
      type: CommandBarItemType.Button,
      onRender() {
        return (
          <Toggle
            inlineLabel
            label="Data points"
            disabled={selectedSignals.length === 0 || isWaterfallLayout}
            styles={{ root: { marginBottom: 0, marginLeft: 26 } }}
            checked={isRawDataPointsActive}
            onChange={(_, checked) => setActiveRawDataPoints(checked)}
          />
        );
      },
    },
  ];

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={topControls}
      id="top-controls"
    >
      <Stack horizontal className="sidebar-header">
        <Text style={headerText}>{title}</Text>
        <TooltipHost
          tooltipProps={{
            onRenderContent: TrendViewSelectSignalsInfo,
          }}
          delay={TooltipDelay.zero}
          id="signal-info-tooltip"
          directionalHint={DirectionalHint.bottomLeftEdge}
        >
          <IconButton
            aria-describedby="signal-info-tooltip"
            iconProps={infoIcon}
          />
        </TooltipHost>
      </Stack>

      <div ref={wrapperRef} style={baseCommandBarWrapper}>
        <BaseCommandBar
          items={computeCommandBarItems(items)}
          styles={baseCommandBar}
        />
      </div>

      <CustomDateDialog
        initialValues={period}
        hidden={!isCustomPeriodVisible}
        setVisible={setIsCustomPeriodVisible}
        onSubmit={onCustomDateChange}
      />
    </Stack>
  );
};
export default HeaderControls;
