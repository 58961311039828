import type { SVGProps } from 'react';
import React from 'react';

const LastPageIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='9'
      height='11'
      viewBox='0 0 9 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.50004 1.19231e-06C8.61706 -3.78216e-05 8.7304 0.0409728 8.8203 0.115891C8.9102 0.190809 8.97097 0.294887 8.99204 0.410001L9.00004 0.500001V9.5C9.00027 9.62495 8.95371 9.74545 8.86953 9.83778C8.78535 9.93012 8.66965 9.98759 8.54522 9.99888C8.42078 10.0102 8.29663 9.97446 8.19721 9.89878C8.09779 9.8231 8.0303 9.71295 8.00804 9.59L8.00004 9.5V0.500001C8.00004 0.367393 8.05272 0.240216 8.14649 0.146448C8.24025 0.0526797 8.36743 1.19231e-06 8.50004 1.19231e-06ZM0.646039 0.146001C0.728895 0.0633259 0.83813 0.0123391 0.954713 0.00192332C1.0713 -0.00849246 1.18784 0.0223233 1.28404 0.0890014L1.35404 0.146001L5.85404 4.646C5.93671 4.72886 5.9877 4.83809 5.99812 4.95468C6.00853 5.07126 5.97772 5.1878 5.91104 5.284L5.85404 5.354L1.35404 9.854C1.26585 9.94258 1.14768 9.99492 1.02282 10.0007C0.897952 10.0065 0.775451 9.96528 0.679456 9.88523C0.583461 9.80517 0.520935 9.69206 0.504199 9.56818C0.487463 9.44431 0.517731 9.31866 0.589039 9.216L0.646039 9.146L4.79304 5L0.646039 0.854001C0.599476 0.807556 0.562533 0.75238 0.537326 0.691635C0.51212 0.63089 0.499146 0.565768 0.499146 0.500001C0.499146 0.434234 0.51212 0.369113 0.537326 0.308367C0.562533 0.247622 0.599476 0.192447 0.646039 0.146001Z'
        fill='#201F1E'
      />
    </svg>
  );
};

export default LastPageIcon;
