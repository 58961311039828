import type { Report } from '../../../types';

export enum UserActions {
  None,
  ReportAdd,
  ReportEdit,
  ReportDelete,
  TaskAdd,
}

export type UserActionState = {
  action: UserActions;
  report?: Report;
};

export type TaskData = {
  description: string;
};
