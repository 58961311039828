export const getIntervalDuration = (selectedIntervalDuration: string) => {
  switch (selectedIntervalDuration) {
    case '5s':
      return 5 * 1000;
    case '1m':
      return 60 * 1000;
    case '5m':
      return 5 * 60 * 1000;
    case '15m':
      return 15 * 60 * 1000;
    case '30m':
      return 30 * 60 * 1000;
    case '1h':
      return 60 * 60 * 1000;
    default:
      return 0;
  }
};
