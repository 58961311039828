import type { ICommandBarStyles } from '@fluentui/react';

export const dropdownIcon: React.CSSProperties = {
  marginRight: 5,
  width: 16,
  height: 16,
};

export const baseCommandBar: ICommandBarStyles = {
  root: {
    padding: 0,
    alignItems: 'center',
    marginBottom: 0,
  },
  primarySet: {
    alignItems: 'center',
  },
};

export const topControls = {
  root: {
    background: '#FAF9F8',
    width: '100%',
    zIndex: 9,
    minHeight: 62,
  },
};

export const headerText = {
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '24px',
  minHeight: 30,
};

export const baseCommandBarWrapper = { width: 'calc(100% - 325px)', marginLeft: 20 };
