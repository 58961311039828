import type { IDropdownOption } from '@fluentui/react/lib/components/Dropdown';

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export interface Range {
  startIndex: number;
  endIndex: number;
}

export interface ConnectedTimeRange {
  range: Range;
  signalId: string;
}

export interface ControlsStore {
  loadingCharts: boolean;
  setLoadingCharts: (loadingCharts: boolean) => void;
  trendsGrouping: IDropdownOption | undefined;
  referenceSignal: IDropdownOption | undefined;
  period: any;
  isTimeAxisConnected: boolean | undefined;
  averageTrend: boolean | undefined;
  connectedTimeRange: ConnectedTimeRange | null;
  changeTrendsGrouping: (trendsGrouping: IDropdownOption | undefined) => void;
  changeReferenceSignal: (referenceSignal: IDropdownOption | undefined) => void;
  changePeriod: (period: any) => void;
  changeTimeAxis: (isTimeAxisConnected: boolean | undefined) => void;
  setConnectedTimeRange: (connectedTime: ConnectedTimeRange | null) => void;
  changeAverageTrend: (averageTrend: boolean | undefined) => void;
  getReferenceSignal: () => any;
  getPeriod: () => any;
}

const useControlsStore = createWithEqualityFn<ControlsStore>((set, get) => ({
  trendsGrouping: { key: 'separated', text: 'separated' },
  referenceSignal: undefined,
  period: { key: 'Last month', text: 'Last month', startDate: null, endDate: null },
  isTimeAxisConnected: false,
  averageTrend: false,
  connectedTimeRange: null,
  loadingCharts: false,
  getReferenceSignal: () => get().referenceSignal,
  getPeriod: () => get().period,
  changeTrendsGrouping: (trendsGrouping: IDropdownOption | undefined) =>
    set((state) => ({
      ...state,
      trendsGrouping,
      connectedTimeRange: null,
      referenceSignal: trendsGrouping?.key !== 'separated' ? undefined : state.referenceSignal,
    })),
  changeReferenceSignal: (referenceSignal: IDropdownOption | undefined) => set({ referenceSignal }),
  changePeriod: (period: IDropdownOption | undefined) => set({ period, connectedTimeRange: null }),
  changeTimeAxis: (isTimeAxisConnected: boolean | undefined) =>
    set((state) => ({
      ...state,
      isTimeAxisConnected,
      connectedTimeRange: isTimeAxisConnected ? state.connectedTimeRange : null,
    })),
  changeAverageTrend: (averageTrend: boolean | undefined) =>
    set((state) => ({
      ...state,
      averageTrend,
    })),
  setConnectedTimeRange: (connectedTime: ConnectedTimeRange | null) =>
    set((state) => ({
      ...state,
      connectedTimeRange: state.isTimeAxisConnected ? connectedTime : null,
    })),
  setLoadingCharts: (loadingCharts: boolean) =>
    set((state) => ({
      ...state,
      loadingCharts,
    })),
}), shallow);

export default useControlsStore;
