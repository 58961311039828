import { useMemo } from "react";

import { useMachineList } from "../../../Hooks";
import type { MachineListItem } from "../../../types";

const useMachineMetaData = (machineId: string) => {
  const { data } = useMachineList({ enabled: false });

  const metaData = useMemo(() => {
    const machine = data?.find(({ id }) => id === machineId) as MachineListItem;

    return {
      machine,
      project: machine?.project,
      company: machine?.company,
      corporation: machine?.corporation,
    };
  }, [data]);

  return metaData;
};

export default useMachineMetaData;
