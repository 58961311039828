/* eslint-disable jsx-a11y/click-events-have-key-events */

import type { CSSProperties } from "react";
import React from "react";

import Attachment from "../../../../../assets/svg/Attachment";
import { format } from "../../../../../utils";
import { taskStatusFormat } from "../../../../../modules/machine-cv/constants";
import type { TaskOverview } from "../../../../../types";
import type { Column } from "../../../../common/Table/Table";

import type { Edit } from "../../hooks/useTasksStore";

const linkStyle: CSSProperties = {
  color: "#2C529F",
  cursor: "pointer",
  display: "flex",
};

type GetColumnsOpts = { showEdit: (payload: Edit) => void };

export const getColumns = ({ showEdit }: GetColumnsOpts): Column[] => [
  {
    key: "subject",
    fieldName: "subject",
    name: "Subject",
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 100,
    isSortable: true,
    onRender: (item: TaskOverview) => {
      return (
        <span
          tabIndex={0}
          role="button"
          style={linkStyle}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            showEdit({ id: item.id });
          }}
        >
          {item.subject}
        </span>
      );
    },
  },
  {
    key: "description",
    fieldName: "description",
    name: "Description",
    flexGrow: 2,
    calculatedWidth: 0,
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "priority",
    fieldName: "priority",
    name: "Priority",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "status",
    fieldName: "status",
    name: "Status",
    minWidth: 100,
    isSortable: true,
    onRender: (item: TaskOverview) => taskStatusFormat[item.status],
  },
  {
    key: "assignedTo",
    fieldName: "assignedTo",
    name: "Assigned To",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "createdBy",
    fieldName: "createdBy",
    name: "Assigned By",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "createdAt",
    fieldName: "createdAt",
    name: "Created At",
    minWidth: 100,
    isSortable: true,
    onRender: (item: TaskOverview) => format(new Date(item.createdAt)),
  },
  {
    key: "dueDate",
    fieldName: "dueDate",
    name: "Due date",
    minWidth: 100,
    isSortable: true,
    isExportable: true,
    onRender: (item: TaskOverview) => format(new Date(item.dueDate)),
  },
  {
    key: "fileCount",
    fieldName: "fileCount",
    name: "Documents",
    minWidth: 100,
    isSortable: true,
    isExportable: true,
    onRender(item: TaskOverview) {
      return (
        <span
          tabIndex={0}
          role="button"
          style={linkStyle}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            showEdit({ id: item.id });
          }}
        >
          <Attachment style={{ marginRight: 8, verticalAlign: "middle" }} />
          <span style={{ fontSize: 12, textDecoration: "underline" }}>
            ({item.fileCount})
          </span>
        </span>
      );
    },
  },
];
