import { useMemo } from 'react';

import { useMachineList } from './api';
import {
  findLocationTimezone,
  getTimeZoneMillisecondsOffset,
} from '../modules/analysis-trend-view/utils/getRangeFormat';
import { Project } from '../types';

export const useSignalProjectTimezoneOffset = ({ signal }: any) => {
  const machines = useMachineList();
  const project = signal?.machineId
    ? machines?.data?.filter(({ id }) => signal.machineId === id)[0]?.project
    : {};

  return useMemo(() => {
    const projectTimezone = findLocationTimezone(project as Project);
    return getTimeZoneMillisecondsOffset(projectTimezone);
  }, [project]);
};
