import { useState } from "react";
import useConstant from "./useConstant";

type UseBooleanReturn = [
  boolean,
  {
    setTrue: () => void;
    setFalse: () => void;
    toggle: () => void;
  },
];

function useBoolean(initialValue: boolean = false): UseBooleanReturn {
  const [value, setValue] = useState<boolean>(initialValue);

  const setTrue = useConstant(() => () => {
    setValue(true);
  });
  const setFalse = useConstant(() => () => {
    setValue(false);
  });
  const toggle = useConstant(() => () => {
    setValue((prev) => !prev);
  });

  return [value, { setTrue, setFalse, toggle }];
}

export default useBoolean;
