import { TooltipHost } from "@fluentui/react";
import * as ReactRouterDOM from "react-router-dom";

import TaskIcon from "../../../../../assets/svg/TaskIcon";
import { format } from "../../../../../utils";
import { taskStatusFormat } from "../../../../../modules/machine-cv/constants";
import type { TaskOverview } from "../../../../../types";
import type { Column } from "../../../../common/Table/Table";

export const getColumns = (): Column[] => [
  {
    key: "subject",
    fieldName: "subject",
    name: "Subject",
    minWidth: 100,
    flexGrow: 1,
    calculatedWidth: 0,
    isSortable: true,
  },
  {
    key: "description",
    fieldName: "description",
    name: "Description",
    minWidth: 100,
    flexGrow: 1,
    calculatedWidth: 0,
    isSortable: true,
  },
  {
    key: "priority",
    fieldName: "priority",
    name: "Priority",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "status",
    fieldName: "status",
    name: "Status",
    minWidth: 100,
    isSortable: true,
    onRender: (item: TaskOverview) => taskStatusFormat[item.status],
  },
  {
    key: "assignedTo",
    fieldName: "assignedTo",
    name: "Assigned To",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "assignedBy",
    fieldName: "assignedBy",
    name: "Assigned By",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "createdAt",
    fieldName: "createdAt",
    name: "Created At",
    minWidth: 100,
    isSortable: true,
    onRender: (item: TaskOverview) => format(new Date(item.createdAt)),
  },
  {
    key: "dueDate",
    fieldName: "dueDate",
    name: "Due date",
    minWidth: 100,
    isSortable: true,
    isExportable: true,
    onRender: (item: TaskOverview) => format(new Date(item.dueDate)),
  },
  {
    key: "documents",
    fieldName: "documents",
    name: "Documents",
    minWidth: 100,
    maxWidth: 100,
    isSortable: true,
    isExportable: true,
  },
  {
    key: "actions",
    fieldName: "actions",
    name: "Actions",
    minWidth: 100,
    onRender: ({ query }) => (
      <div style={{ display: "flex" }}>
        <TooltipHost content="Tasks" styles={{ root: { display: "flex" } }}>
          <ReactRouterDOM.Link
            to={`/machine-cv/tasks?${query}`}
            className="router-link"
            style={{ margin: "0 5px" }}
          >
            <TaskIcon />
          </ReactRouterDOM.Link>
        </TooltipHost>
      </div>
    ),
  },
];
