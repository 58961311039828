import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../modules/core/apiClient/useApiStore';

type UseUserSettingsProps = {
  id: string;
};

export const useUserSettings = ({ id }: UseUserSettingsProps) => {
  const { data, isLoading, isError, refetch } = useQuery(
    ['user-settings'],
    () =>
      getApiClient()
        .get(`/rbac/v1/users/${id}/settings`)
        .then(({ data }) => data),
    {
      cacheTime: 0,
    },
  );

  return {
    getUserSettings: refetch,
    userSettings: data,
    isLoading,
    isError,
  };
};
