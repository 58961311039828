import { useQuery } from '@tanstack/react-query';
import orderBy from 'lodash-es/orderBy';

import { getApiClient } from '../../../../modules/core/apiClient/useApiStore';
import type { Report } from '../../../../types';

type UseReportsQuery = {
  reports: Report[];
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

export type UseReportsQueryOpts = {
  machineId: string;
};

export const useReportsQuery = (opts: UseReportsQueryOpts): UseReportsQuery => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['machine-cv-reports', opts],
    () =>
      getApiClient()
        .get<Report[]>(`meta/machinecv/v1/logEntries/machines/${opts.machineId}`)
        .then(({ data }) => {
          return orderBy(data, ['createdAt'], ['desc']);
        }),
    {
      cacheTime: 0,
    },
  );

  return {
    reports: data,
    isLoading,
    isError,
    refetch,
  };
};
