import type { SVGProps } from 'react';
import React from 'react';

const PencilIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.181 0.925856C14.9075 0.63823 14.5792 0.408245 14.2154 0.249446C13.8516 0.0906483 13.4598 0.0062478 13.0629 0.00121568C12.666 -0.00381644 12.2721 0.0706216 11.9044 0.220146C11.5368 0.36967 11.2027 0.591257 10.922 0.871856L1.547 10.2469C1.2191 10.5747 0.991532 10.9892 0.890997 11.4419L0.0139963 15.3919C-0.00416633 15.474 -0.0013222 15.5593 0.0222654 15.6401C0.045853 15.7208 0.0894221 15.7943 0.148941 15.8537C0.208459 15.9131 0.282004 15.9566 0.362769 15.98C0.443534 16.0035 0.52891 16.0062 0.610996 15.9879L4.537 15.1149C5.00443 15.0108 5.4325 14.7756 5.771 14.4369L15.129 5.07886C15.6775 4.5301 15.9899 3.78876 15.9997 3.01293C16.0094 2.2371 15.7156 1.48818 15.181 0.925856ZM11.629 1.57986C12.0017 1.22177 12.5 1.02408 13.0169 1.02921C13.5337 1.03435 14.028 1.2419 14.3935 1.60733C14.7591 1.97276 14.9668 2.46693 14.9721 2.98378C14.9775 3.50064 14.78 3.99898 14.422 4.37186L13.751 5.04286L10.958 2.25086L11.629 1.57986ZM10.251 2.95786L13.044 5.75086L5.064 13.7309C4.85982 13.9348 4.60174 14.0763 4.32 14.1389L1.16 14.8409L1.868 11.6589C1.9269 11.3917 2.06121 11.147 2.255 10.9539L10.251 2.95786Z'
        fill='#2C529F'
      />
    </svg>
  );
};

export default PencilIcon;
