import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { apiService } from '../../modules/common';
import { queryClient } from '../../modules/core';
import type { MachineList } from '../../types';

export const useMachineList = (
  options?:
    | (Omit<
        UseQueryOptions<MachineList, unknown, MachineList, string[]>,
        'initialData' | 'queryFn' | 'queryKey'
      > & { initialData?: (() => undefined) | undefined })
    | undefined,
) => {
  return useQuery(['machine-list'], apiService.metaDataRead.getMachineList, options);
};

export const prefetchMachineList = async () => {
  // The results of this query will be cached like a normal query
  await queryClient.prefetchQuery(['machine-list'], apiService.metaDataRead.getMachineList);
};
