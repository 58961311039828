import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

interface Section {
  id: string;
  isVisible: boolean;
  isMaximized?: boolean;
}

interface SectionsStore {
  domIds: Section[];
  maximize: (domId: string) => void;
  minimize: () => void;
  add: (domId: string) => void;
  remove: (domId: string) => void;
  find: (domId: string) => Section | undefined;
  isAnyMaximized: () => boolean;
}

const useSectionsStore = createWithEqualityFn<SectionsStore>((set, get) => ({
  domIds: [],
  // on maximize
  maximize: (domId: string) => {
    set((store) => ({
      domIds: [
        ...store.domIds
          .filter(({ id }) => id !== domId)
          .map((section) => ({ ...section, isVisible: false, isMaximized: false })),
        { id: domId, isVisible: true, isMaximized: true }, // add
      ],
    }));
  },
  // reset
  minimize: () =>
    set((store) => ({
      domIds: store.domIds.map((section) => ({ ...section, isVisible: true, isMaximized: false })),
    })),
  // section on mount
  add: (domId: string) => {
    set((store) => ({
      domIds: [
        ...store.domIds.map((section) => ({ ...section, isVisible: true, isMaximized: false })), // reset/minimize
        { id: domId, isVisible: true, isMaximized: false }, // add
      ],
    }));
  },
  // section on unmount
  remove: (domId: string) =>
    set((store) => ({
      domIds: store.domIds
        .filter(({ id }) => id !== domId) // remove
        .map((section) => ({ ...section, isVisible: true })), // reset/minimize
    })),
  // get current state
  find: (domId: string) => {
    return get().domIds.find(({ id }) => id === domId);
  },
  // check if any section has a false value (used for hiding placeholder)
  isAnyMaximized: () => {
    return get().domIds.some(({ isVisible, isMaximized }) => !isVisible || isMaximized);
  },
}), shallow);

export default useSectionsStore;
