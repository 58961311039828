import "./style.scss";

import { TextField } from "@fluentui/react";
import { Decimal } from "decimal.js";
import { isNumber, uniqueId } from "lodash-es";
import type { CSSProperties } from "react";
import React, { useEffect, useMemo, useRef, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import scrollLock from "scroll-lock";

import {
  generateBackgroundGradient,
  getTicks,
  setMaxNumberDecimals,
} from "../InteractiveColorBar/methods";

export type InteractiveColorValues = {
  top: number;
  bottom: number;
};

type InteractiveColorBarProps = {
  min: number;
  segments: number;
  reset: any;
  style: CSSProperties;
  colorScale: Array<[number, string]>;
  value: number;
  defaultValue: number;
  onChange: (newValue: InteractiveColorValues) => void;
};

const InteractiveColorBar = ({
  min,
  segments,
  onChange,
  reset,
  style,
  colorScale,
  value,
  defaultValue,
}: InteractiveColorBarProps) => {
  const ref: any = useRef();
  const gradient = useMemo(
    () => generateBackgroundGradient(colorScale),
    [colorScale]
  );
  const [bottomValue, setBottomValue] = useState(min);
  const [topValue, setTopValue] = useState(value || defaultValue);
  const [inputBottomValue, setInputBottomValue] = useState(min);
  const [inputTopValue, setInputTopValue] = useState(value || defaultValue);
  const [tooltipValue, setTooltipValue]: any = useState();
  const [tooltipVisible, setTooltipVisible]: any = useState();
  const [tooltipPosition, setTooltipPosition]: any = useState({ y: 0 });
  const prev = useRef({ bottomValue, topValue, reset });

  useEffect(() => {
    if (
      prev.current.bottomValue !== bottomValue ||
      prev.current.topValue !== topValue
    ) {
      prev.current.bottomValue = bottomValue;
      prev.current.topValue = topValue;
      onChange && onChange({ top: topValue, bottom: bottomValue });
    }
  }, [bottomValue, topValue]);

  // reset color bar when reset props is changed
  useEffect(() => {
    if (prev.current.reset !== reset) {
      setBottomValue(min);
      setInputBottomValue(min);
      setTopValue(defaultValue || value);
      setInputTopValue(defaultValue || value);
      prev.current.reset = reset;
    }
  }, [reset]);

  const isValidAsBottomValue = (value: any, min: any, max: any) => {
    return value < max && value >= min;
  };

  const onBottomValueChange = (e: any) => {
    setInputBottomValue(e.target.value);
    if (isValidAsBottomValue(parseFloat(e.target.value), min, topValue)) {
      setBottomValue(parseFloat(e.target.value));
    }
  };

  const onBottomInputBlur = () => {
    if (inputBottomValue !== bottomValue) {
      setInputBottomValue(bottomValue);
    }
  };

  const onTopInputBlur = () => {
    if (inputTopValue !== topValue) {
      setInputTopValue(topValue);
    }
  };

  const isValidAsTopValue = (value: any, min: any) => {
    return value > min;
  };

  const onTopValueChange = (e: any) => {
    setInputTopValue(e.target.value);

    if (isValidAsTopValue(parseFloat(e.target.value), bottomValue)) {
      setTopValue(parseFloat(e.target.value));
    }
  };

  const onWheel = (e: any) => {
    let value = new Decimal(topValue);
    const deltaY = e.deltaY;
    const thresholdSmallNumbers = topValue <= bottomValue + 0.001;
    if (e.deltaY < 0) {
      if (thresholdSmallNumbers) {
        const valueToAdd = deltaY < -5 ? 0.001 : 0.0001;
        value = value.plus(new Decimal(valueToAdd));
      } else {
        const valueToAdd = deltaY < -5 ? 0.01 : 0.001;
        value = value.plus(new Decimal(valueToAdd));
      }
    } else {
      if (thresholdSmallNumbers) {
        const valueToAdd = deltaY > 5 ? 0.001 : 0.0001;
        value = value.minus(new Decimal(valueToAdd));
      } else {
        const valueToAdd = deltaY > 5 ? 0.01 : 0.001;
        value = value.minus(new Decimal(valueToAdd));
      }
    }

    const valueNumber = value.toNumber();

    if (isValidAsTopValue(valueNumber, bottomValue)) {
      if (thresholdSmallNumbers) {
        setTopValue(valueNumber);
        setInputTopValue(valueNumber);
      } else {
        setTopValue(setMaxNumberDecimals(valueNumber, 3));
        setInputTopValue(setMaxNumberDecimals(valueNumber, 3));
      }
    }
  };

  const disablePageScroll = () => {
    scrollLock.disablePageScroll();
  };

  const enablePageScroll = () => {
    scrollLock.enablePageScroll();
  };

  const getValueBasedOnCursorPosition = (e: any) => {
    const boundingClientRect = e.currentTarget.getBoundingClientRect();
    const y = e.pageY - boundingClientRect.top;
    const clickedPositionPx = boundingClientRect.height - y;
    setTooltipPosition({ y: y - 15 });
    const calculatedValue =
      bottomValue +
      (topValue - bottomValue) *
        (clickedPositionPx / boundingClientRect.height);
    let formattedValue;
    if (topValue > 0.001) {
      formattedValue = setMaxNumberDecimals(calculatedValue, 4);
    } else {
      formattedValue = setMaxNumberDecimals(calculatedValue, 5);
    }
    return formattedValue;
  };

  const handleBarClick = (e: any) => {
    const valueFromCursorPosition = getValueBasedOnCursorPosition(e);
    if (
      valueFromCursorPosition > bottomValue &&
      valueFromCursorPosition <= topValue
    ) {
      setTopValue(valueFromCursorPosition);
      setInputTopValue(valueFromCursorPosition);
    }
  };

  return (
    <div className="icb-container" style={style}>
      <TextField
        min={bottomValue + 0.001}
        className="top"
        value={inputTopValue.toString()}
        title={inputTopValue.toString()}
        autoComplete="off"
        type="number"
        step=".001"
        onChange={onTopValueChange}
        onBlur={onTopInputBlur}
      />
      <div className="icb">
        {isNumber(tooltipValue) && tooltipVisible && (
          <div className="icb__tooltip" style={{ top: tooltipPosition.y }}>
            {tooltipValue}
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          ref={ref}
          className="icb__vertical-bar"
          style={gradient}
          onClick={handleBarClick}
          onWheel={onWheel}
          onMouseEnter={disablePageScroll}
          onMouseLeave={() => {
            enablePageScroll();
            setTooltipValue(null);
          }}
          onMouseMove={(e) => {
            const value = getValueBasedOnCursorPosition(e);
            setTooltipValue(value <= topValue ? value : 0);
          }}
          onMouseDown={(e) => {
            setTooltipVisible(true);
            const value = getValueBasedOnCursorPosition(e);
            setTooltipValue(value <= topValue ? value : 0);
          }}
          onMouseUp={() => {
            setTooltipVisible(false);
          }}
        />
        <div className="icb__values">
          <div className="icb__value">
            {setMaxNumberDecimals(bottomValue, 3)}
          </div>
          {getTicks(bottomValue, topValue, segments).map((value: any) => (
            <div key={uniqueId("_tick_key_")} className="icb__value">
              {value}
            </div>
          ))}
          <div className="icb__value">{setMaxNumberDecimals(topValue, 3)}</div>
        </div>
      </div>
      <TextField
        min={min}
        max={topValue - 0.001}
        className="bottom"
        value={inputBottomValue.toString()}
        title={inputBottomValue.toString()}
        autoComplete="off"
        type="number"
        step=".001"
        onChange={onBottomValueChange}
        onBlur={onBottomInputBlur}
      />
    </div>
  );
};

export default InteractiveColorBar;
