import { TooltipHost } from "@fluentui/react";
import * as ReactRouterDOM from "react-router-dom";

import AddDocumentIcon from "../../../../../assets/svg/AddDocumentIcon";
import AddTaskIcon from "../../../../../assets/svg/AddTaskIcon";
import RawDataIcon from "../../../../../assets/svg/RawDataIcon";
import TrendsIcon from "../../../../../assets/svg/TrendsIcon";

export interface MachineCvSensor {
  machineId: string;
  sensorDescription: string;
  sensorNo: string;
  id: string;
  mountingType: string;
  mountingDirection: string;
  sensorSerialNo: string;
}

export interface ListProps {
  listItems: MachineCvSensor[];
  onAddTask: () => void;
  onAddDocument: () => void;
}

interface Column {
  search: string;
  items: any[];
  onAddDocument: () => void;
  onAddTask: () => void;
}

const Action = ({
  id,
  name,
  link,
  icon,
  onClick,
}: ReturnType<typeof getActions>[0]) => {
  const action = onClick ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      tabIndex={0}
      role="button"
      className="router-link"
      style={{ margin: "0 5px", cursor: "pointer" }}
      onClick={onClick}
    >
      {icon}
    </div>
  ) : (
    <ReactRouterDOM.Link
      to={link ?? ""}
      className="router-link"
      style={{ margin: "0 5px" }}
    >
      {icon}
    </ReactRouterDOM.Link>
  );

  return (
    <TooltipHost key={id} content={name} styles={{ root: { display: "flex" } }}>
      {action}
    </TooltipHost>
  );
};

const getActions = ({
  onAddTask,
  onAddDocument,
  search,
  sensorNo,
}: Pick<ListProps, "onAddDocument" | "onAddTask"> & {
  search: string;
  sensorNo: string;
}) => {
  return [
    {
      id: "add_task",
      name: "Add task",
      icon: <AddTaskIcon />,
      onClick: onAddTask,
    },
    {
      id: "add_document",
      name: "Upload document",
      icon: <AddDocumentIcon />,
      onClick: onAddDocument,
    },
    {
      id: "trend",
      name: "Trend",
      icon: <TrendsIcon fill="#2c529f" />,
      link: `/trend-view${search}&sensorNo=${sensorNo}`,
    },
    {
      id: "raw_data",
      name: "Raw data",
      icon: <RawDataIcon />,
      link: `/raw-data${search}&sensorNo=${sensorNo}`,
    },
  ];
};

export const getColumns = ({
  items,
  onAddDocument,
  onAddTask,
  search,
}: Column) => {
  const hasColumnData = (fieldName: string) =>
    items.every((item: any) => item?.[fieldName]);
  return [
    {
      key: "sensorNo",
      fieldName: "sensorNo",
      name: "Sensor No",
      calculatedWidth: 0,
      minWidth: 100,
    },
    {
      key: "sensorDescription",
      fieldName: "sensorDescription",
      name: "Sensor Description",
      flexGrow: 1,
      calculatedWidth: 0,
      minWidth: 100,
    },
    {
      key: "mountingType",
      fieldName: "mountingType",
      name: "Sensor Mounting Type",
      flexGrow: 1,
      calculatedWidth: 0,
      minWidth: 100,
    },
    {
      key: "mountingDirection",
      fieldName: "mountingDirection",
      name: "Sensor Mounting Direction",
      flexGrow: 1,
      calculatedWidth: 0,
      minWidth: 100,
    },
    {
      key: "sensorSerialNo",
      fieldName: "sensorSerialNo",
      name: "Sensor Serial No",
      flexGrow: 1,
      calculatedWidth: 0,
      minWidth: 100,
    },
    {
      key: "actions",
      fieldName: "actions",
      name: "Actions",
      minWidth: 120,
      onRender: (item: MachineCvSensor) => {
        const actions = getActions({
          onAddDocument,
          onAddTask,
          search,
          sensorNo: item?.sensorNo || "No sensor",
        });
        return (
          <div style={{ display: "flex" }}>
            {actions.map((action) => (
              <Action key={action.id} {...action} />
            ))}
          </div>
        );
      },
    },
  ].filter(
    ({ fieldName }) => fieldName === "actions" || hasColumnData(fieldName)
  );
};
