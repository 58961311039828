import type { SVGProps } from 'react';
import React from 'react';

const ListIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='fluent:document-one-page-20-regular'>
        <path
          id='icon'
          d='M6.25 2H14.25C14.7804 2 15.2891 2.21071 15.6642 2.58579C16.0393 2.96086 16.25 3.46957 16.25 4V16C16.25 16.5304 16.0393 17.0391 15.6642 17.4142C15.2891 17.7893 14.7804 18 14.25 18H6.25C5.71957 18 5.21086 17.7893 4.83579 17.4142C4.46071 17.0391 4.25 16.5304 4.25 16V4C4.25 3.46957 4.46071 2.96086 4.83579 2.58579C5.21086 2.21071 5.71957 2 6.25 2ZM6.25 3C5.98478 3 5.73043 3.10536 5.54289 3.29289C5.35536 3.48043 5.25 3.73478 5.25 4V16C5.25 16.2652 5.35536 16.5196 5.54289 16.7071C5.73043 16.8946 5.98478 17 6.25 17H14.25C14.5152 17 14.7696 16.8946 14.9571 16.7071C15.1446 16.5196 15.25 16.2652 15.25 16V4C15.25 3.73478 15.1446 3.48043 14.9571 3.29289C14.7696 3.10536 14.5152 3 14.25 3H6.25ZM13.25 6.5C13.25 6.61703 13.209 6.73036 13.1341 6.82026C13.0592 6.91016 12.9551 6.97094 12.84 6.992L12.75 7H7.75C7.62505 7.00023 7.50455 6.95367 7.41222 6.86949C7.31988 6.78531 7.26241 6.66961 7.25112 6.54518C7.23984 6.42074 7.27554 6.29659 7.35122 6.19717C7.4269 6.09775 7.53705 6.03026 7.66 6.008L7.75 6H12.75C12.8826 6 13.0098 6.05268 13.1036 6.14645C13.1973 6.24021 13.25 6.36739 13.25 6.5ZM13.25 10C13.25 10.117 13.209 10.2304 13.1341 10.3203C13.0592 10.4102 12.9551 10.4709 12.84 10.492L12.75 10.5H7.75C7.62505 10.5002 7.50455 10.4537 7.41222 10.3695C7.31988 10.2853 7.26241 10.1696 7.25112 10.0452C7.23984 9.92074 7.27554 9.79659 7.35122 9.69717C7.4269 9.59775 7.53705 9.53026 7.66 9.508L7.75 9.5H12.75C12.8826 9.5 13.0098 9.55268 13.1036 9.64645C13.1973 9.74021 13.25 9.86739 13.25 10ZM13.25 13.5C13.25 13.617 13.209 13.7304 13.1341 13.8203C13.0592 13.9102 12.9551 13.9709 12.84 13.992L12.75 14H7.75C7.62505 14.0002 7.50455 13.9537 7.41222 13.8695C7.31988 13.7853 7.26241 13.6696 7.25112 13.5452C7.23984 13.4207 7.27554 13.2966 7.35122 13.1972C7.4269 13.0977 7.53705 13.0303 7.66 13.008L7.75 13H12.75C12.8826 13 13.0098 13.0527 13.1036 13.1464C13.1973 13.2402 13.25 13.3674 13.25 13.5Z'
          fill='#2C529F'
        />
      </g>
    </svg>
  );
};

export default ListIcon;
