import React from "react";

import ArrowLeftIcon from "../../../../assets/svg/ArrowLeftIcon";

const TrendViewPlaceholder = ({ text }: any) => (
  <div
    className="placeholder"
    style={{ height: "100%", background: "#FFF", minHeight: "10em" }}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <ArrowLeftIcon style={{ marginRight: 15 }} />
      <div>{text || "Please select a Trend Signal"}</div>
    </div>
  </div>
);

export default TrendViewPlaceholder;
