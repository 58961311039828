import { PrimaryButton } from '@fluentui/react';
import React from 'react';

type UploaderButtonProps = {
  onClick: () => void;
};

const UploaderButton = ({ onClick }: UploaderButtonProps) => {
  return (
    <PrimaryButton
      style={{
        marginTop: 25,
      }}
      text='Add document'
      onClick={onClick}
    />
  );
};

export default UploaderButton;
