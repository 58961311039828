import { uniqBy } from 'lodash-es';

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import type { ResponseSimplifiedSignal, SignalAlert } from '../../../types';

export interface SelectedSignalsStore {
  isLoading: boolean;
  isLoadingMultipleSignals: boolean;
  errorMessage: string;
  isErrorMessageOpen: boolean;
  selectedSignals: ResponseSimplifiedSignal[];
  selectedSignalsAlerts: SignalAlert[];
  addSelectedSignal: (selectedSignal: ResponseSimplifiedSignal) => void;
  addSelectedSignalAlert: (selectedSignalAlert: SignalAlert) => void;
  removeSelectedSignal: (selectedSignal: ResponseSimplifiedSignal) => void;
  clearSelectedSignals: () => void;
  setIsLoadingMultipleSignals: (loading: boolean) => void;
  toggleSelect: () => void;
  changeErrorMessage: (errorMessage: string) => void;
  toggleIsErrorMessageOpen: () => void;
  editSignalValues: (signalId: string | undefined, updatedValues: any) => void;
  getSelectedSignals: () => ResponseSimplifiedSignal[];
  getSelectedSignalsAlerts: () => SignalAlert[];
  getSignalAlertById: (id: string | undefined) => SignalAlert;
}

const useSelectedSignalsStore = createWithEqualityFn<SelectedSignalsStore>((set, get) => ({
  isLoading: false,
  isLoadingMultipleSignals: false,
  errorMessage: '',
  isErrorMessageOpen: false,
  selectedSignals: [],
  selectedSignalsAlerts: [],
  addSelectedSignal: (selectedSignal: ResponseSimplifiedSignal) =>
    set((state) => ({ selectedSignals: [...state.selectedSignals, selectedSignal] })),
  removeSelectedSignal: (selectedSignal: ResponseSimplifiedSignal) =>
    set((state) => ({
      selectedSignals: state.selectedSignals.filter((item) => item.id !== selectedSignal.id),
    })),
  addSelectedSignalAlert: (selectedSignalAlert: SignalAlert) => {
    set((state) => ({
      selectedSignalsAlerts: uniqBy([...state.selectedSignalsAlerts, selectedSignalAlert], 'id'),
    }));
  },
  clearSelectedSignals: () => set({ selectedSignals: [], selectedSignalsAlerts: [] }),
  toggleSelect: () =>
    set((state) => ({
      isLoading: !state.isLoading,
    })),
  changeErrorMessage: (errorMessage: string) => set({ errorMessage }),
  toggleIsErrorMessageOpen: () =>
    set((state) => ({
      isErrorMessageOpen: !state.isErrorMessageOpen,
    })),
  setIsLoadingMultipleSignals: (loading: boolean) =>
    set(() => ({
      isLoadingMultipleSignals: loading,
    })),
  editSignalValues: (signalId, updatedValues) => {
    set((state) => ({
      selectedSignals: state.selectedSignals.map((signal) =>
        signal.id === signalId ? { ...signal, ...updatedValues } : signal,
      ),
    }));
  },
  getSelectedSignals: () => get().selectedSignals,
  getSelectedSignalsAlerts: () => get().selectedSignalsAlerts,
  getSignalAlertById: (id: string | undefined) =>
    get().selectedSignalsAlerts.filter((item) => item?.signalId === id)?.[0] || {} as SignalAlert,
}), shallow);

export default useSelectedSignalsStore;
