import useLayoutStore from './useLayoutStore';

const useLayoutTypes = () => {
  const layout = useLayoutStore((state) => state.layout);

  const isCompareLayout = layout?.key === 'compare-waveform-spectrum';
  const isWaveformLayout = layout?.key === 'waveform-spectrum';
  const isTrendLayout = layout?.key === 'trend-waveform-spectrum';
  const isWaterfallLayout = layout?.key === 'waterfall';

  return {
    isCompareLayout,
    isWaveformLayout,
    isTrendLayout,
    isWaterfallLayout,
  };
};

export default useLayoutTypes;
