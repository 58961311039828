import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import React from "react";

import { notification } from "../../../common/Notification";
import BaseDialog, { DialogSize } from "../../../common/Dialog";

import SensorNodeSelector from "./SensorNodeSelector";
import type { ProjectSelectable, SensorNodeSelectable } from "./types";

interface SensorSelectDialogProps {
  isVisible: boolean;
  onDismiss: () => void;
  onSave: (selectedSensors: string[]) => void;
  options: ProjectSelectable;
  isLoading?: boolean;
  title?: string;
  saveButtonText?: string;
}

const SensorSelectDialog: React.FC<SensorSelectDialogProps> = ({
  isVisible,
  onDismiss,
  onSave,
  options: projectData,
  isLoading = false,
  saveButtonText = "Save to sensors",
}) => {
  const [selectedNodes, setSelectedNodes] = React.useState<string[]>([]);
  const handleSave = () => {
    if (selectedNodes.length === 0) {
      notification.error("Please select at least one sensor node");
      return;
    } else {
      console.log("ON save");
      console.log(selectedNodes);
      onSave(selectedNodes);
    }
  };

  const handleSelectionChange = (selectedNodes: SensorNodeSelectable[]) => {
    console.log("selectedNodes", selectedNodes);
    setSelectedNodes(selectedNodes.map((node) => node.sensorNodeId));
  };

  const handleDialogClose = () => {
    onDismiss();
    setSelectedNodes([]);
  };

  return (
    <BaseDialog
      hidden={!isVisible}
      size={DialogSize.M}
      styles={{
        main: {
          minHeight: "auto",
          selectors: {
            ".ms-Dialog-title": {
              paddingTop: 18,
              paddingBottom: 10,
            },
          },
        },
      }}
      onDismiss={handleDialogClose}
    >
      <SensorNodeSelector
        project={projectData}
        onSelectionChange={handleSelectionChange}
      />
      <DialogFooter>
        <PrimaryButton
          text={saveButtonText}
          onClick={handleSave}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton text="Close" onClick={handleDialogClose} />
      </DialogFooter>
    </BaseDialog>
  );
};

export default SensorSelectDialog;
