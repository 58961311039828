import type { AxiosInstance } from "axios";

import AppLayout from "../../../templates/AppLayout";
import { File } from "./File";

interface FilePreviewTypes {
  axiosInstance: AxiosInstance;
  file: {
    id: any;
    name: any;
  };
}

const FilePreview = ({ axiosInstance, file }: FilePreviewTypes) => (
  <AppLayout axiosInstance={axiosInstance}>
    <File
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      file={file}
    />
  </AppLayout>
);

export default FilePreview;
