import type { AxiosInstance } from 'axios';

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export interface ApiStore {
  apiClient: AxiosInstance | null;
  setApiClient: (apiClient: AxiosInstance) => void;
}

const useApiStore = createWithEqualityFn<ApiStore>((set) => ({
  apiClient: null,
  setApiClient: (apiClient: AxiosInstance) => set({ apiClient }),
}), shallow);

export const getApiClient = () => useApiStore.getState().apiClient as AxiosInstance;

export const validateStatus = (status: number) => status >= 200 && status < 300;

export default useApiStore;
