import { useParams } from 'react-router-dom';

export const useWSUrlParams = () => {
  const { projectId, sensorNodeId, machineId, serialNumber } = useParams();

  return {
    projectId,
    sensorNodeId,
    machineId,
    serialNumber,
  };
};
