import { uniq } from 'lodash-es';

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

type NodeId = string | number;
export interface SidebarStore {
  isExpanded: boolean;
  toggle: () => void;
  addOpenedNode: (nodeId: NodeId) => void;
  removeOpenedNode: (nodeId: NodeId) => void;
  openedNodes: NodeId[];
}

const useWSSidebarStore = createWithEqualityFn<SidebarStore>((set) => ({
  isExpanded: true,
  toggle: () => set((store) => ({ isExpanded: !store.isExpanded })),
  addOpenedNode: (nodeId) =>
    set((store) => ({ openedNodes: uniq([...store.openedNodes, nodeId]) })),
  removeOpenedNode: (nodeId) =>
    set((store) => ({ openedNodes: store.openedNodes.filter((id) => id !== nodeId) })),
  openedNodes: [],
}), shallow);

export default useWSSidebarStore;
