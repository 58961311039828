import type { IColumn } from "@fluentui/react";

import { default as chartSymbolsIcons } from "../../../../assets/svg/ChartSymbolsIcons";
import { format } from "../../../common/utils/signalFormatter";
import type { ResponseSimplifiedSignalList } from "../../../../types";

type GetColumnsOpts = {
  header: string;
  hasProjectAndMachineCols: boolean;
  signals: ResponseSimplifiedSignalList;
};

export const getColumns = ({
  signals,
  header,
  hasProjectAndMachineCols,
}: GetColumnsOpts) => {
  const columns: IColumn[] = [
    {
      key: "name",
      fieldName: "name",
      name: header,
      minWidth: 250,
      onRender: (_, index) => (
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          {chartSymbolsIcons.components[index as number]}
          {format(signals[index as number])}
        </div>
      ),
    },
    {
      key: "unit",
      fieldName: "unit",
      name: "Unit",
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "last",
      fieldName: "last",
      name: "Last",
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "min",
      fieldName: "min",
      name: "Min",
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "max",
      fieldName: "max",
      name: "Max",
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "avg",
      fieldName: "avg",
      name: "Avg",
      minWidth: 50,
      maxWidth: 100,
    },
    {
      key: "stDev",
      fieldName: "stDev",
      name: "StDev",
      minWidth: 50,
      maxWidth: 100,
    },
  ];

  if (hasProjectAndMachineCols) {
    columns.splice(
      2,
      0,
      {
        key: "project",
        fieldName: "project",
        name: "Project",
        minWidth: 100,
      },
      {
        key: "machine",
        fieldName: "machine",
        name: "Machine",
        minWidth: 100,
      }
    );
  }

  return columns;
};
