import { Icon } from "@fluentui/react";
import classNames from "classnames";
import React from "react";
import { treeHandlers } from "react-hyper-tree";
import { useNavigate } from "react-router-dom";

import useWSSidebarStore from "../../../hooks/useWSSidebarStore";
import { EntityType } from "../../../utils";

const SidebarNode = ({ node, onToggle, customToggle }: any) => {
  const { icon, name, link } = node.data;
  const navigate = useNavigate();

  const { addOpenedNode, removeOpenedNode } = useWSSidebarStore((store) => ({
    addOpenedNode: store.addOpenedNode,
    removeOpenedNode: store.removeOpenedNode,
  }));

  const showCloseIcon = node.options.opened && node.hasChildren();
  const showOpenIcon =
    !node.options.opened && (node.hasChildren() || node.asyncNode);

  const handleItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (link) navigate(link);
    if (
      node.data.type === EntityType.Sensor ||
      node.data.type === EntityType.Gateway ||
      node.data.type === EntityType.SensorTriggering
    ) {
      treeHandlers.trees["ws-sidebar-tree"].handlers.unselectAll();
      node.setSelected(true);
    }

    if (node.options.opened) {
      removeOpenedNode(node.id);
    } else {
      addOpenedNode(node.id);
    }

    onToggle(e);
    customToggle && customToggle();
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      key={node.id}
      className={classNames("sidebar-node", {
        selected: !!icon && node?.options?.selected,
      })}
      onClick={handleItemClick}
    >
      <div className="sidebar-content">
        {icon && <div className="node-icon">{icon}</div>}
        {name && <div className="node-text">{name}</div>}
      </div>
      {showCloseIcon && <Icon className="node-chevron" iconName="ChevronUp" />}
      {showOpenIcon && <Icon className="node-chevron" iconName="ChevronDown" />}
    </div>
  );
};

export default SidebarNode;
