import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { apiService } from "../../../../modules/common";
import type { MachineCVCondition } from "../../../../modules/common/services/api/MeasuredDataReadApi";

interface UseConditionOverviewArgs {
  machineId: string;
}

type UseConditionOverviewOptions =
  | (Omit<
      UseQueryOptions<
        MachineCVCondition,
        unknown,
        MachineCVCondition,
        string[]
      >,
      "initialData" | "queryFn" | "queryKey"
    > & { initialData?: (() => undefined) | undefined })
  | undefined;

const useConditionsOverview = (
  args: UseConditionOverviewArgs,
  options?: UseConditionOverviewOptions
) => {
  return useQuery(
    ["conditions-of-machine", args.machineId],
    () => apiService.measuredDataRead.getConditionOverview(args),
    options
  );
};

export default useConditionsOverview;
