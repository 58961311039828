import { useMutation } from '@tanstack/react-query';

import { getApiClient, validateStatus } from '../modules/core/apiClient/useApiStore';

type UseUserSettingsProps = {
  id: string;
};

export const useUserSettingsUpdate = ({ id }: UseUserSettingsProps) => {
  const { mutateAsync } = useMutation((data: any) =>
    getApiClient().put(`/rbac/v1/users/${id}/settings`, data, { validateStatus }),
  );

  return { setUserSettings: mutateAsync };
};
