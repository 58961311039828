import { AnimationClassNames, Checkbox, IconButton, SearchBox, Stack, Text } from '@fluentui/react';
import React, { useMemo, useState } from 'react';

import { componentStyles, styles } from './SensorNodeSelector.styles';
import type { MachineSelectable, ProjectSelectable, SensorNodeSelectable } from './types';

interface SensorNodeSelectorProps {
  project: ProjectSelectable;
  onSelectionChange?: (selectedNodes: SensorNodeSelectable[]) => void;
}

const SensorNodeSelector: React.FC<SensorNodeSelectorProps> = ({ project, onSelectionChange }) => {
  const [searchText, setSearchText] = useState('');
  const [expandedMachines, setExpandedMachines] = useState<Set<string>>(new Set());
  const [selectedNodes, setSelectedNodes] = useState<Set<string>>(new Set());
  const [selectedMachines, setSelectedMachines] = useState<Set<string>>(new Set());

  const notifySelectionChange = (nodes: Set<string>) => {
    if (onSelectionChange) {
      const selectedNodesList: SensorNodeSelectable[] = [];
      project.machines.forEach((machine) => {
        machine.sensorNodes.forEach((sensor) => {
          if (nodes.has(sensor.id)) {
            selectedNodesList.push(sensor);
          }
        });
      });
      onSelectionChange(selectedNodesList);
    }
  };

  const toggleMachineExpanded = (machineId: string) => {
    const newExpanded = new Set(expandedMachines);
    if (newExpanded.has(machineId)) {
      newExpanded.delete(machineId);
    } else {
      newExpanded.add(machineId);
    }
    setExpandedMachines(newExpanded);
  };

  const toggleMachineSelected = (machine: MachineSelectable) => {
    const newSelectedMachines = new Set(selectedMachines);
    const newSelectedNodes = new Set(selectedNodes);

    if (selectedMachines.has(machine.id)) {
      newSelectedMachines.delete(machine.id);
      machine.sensorNodes.forEach((sensor) => {
        newSelectedNodes.delete(sensor.id);
      });
    } else {
      newSelectedMachines.add(machine.id);
      machine.sensorNodes.forEach((sensor) => {
        newSelectedNodes.add(sensor.id);
      });
    }

    setSelectedMachines(newSelectedMachines);
    setSelectedNodes(newSelectedNodes);
    notifySelectionChange(newSelectedNodes);
  };

  const toggleSensorSelected = (sensor: SensorNodeSelectable, machine: MachineSelectable) => {
    const newSelectedNodes = new Set(selectedNodes);
    const newSelectedMachines = new Set(selectedMachines);

    if (selectedNodes.has(sensor.id)) {
      newSelectedNodes.delete(sensor.id);
      const hasSelectedSensors = machine.sensorNodes.some(
        (s) => s.id !== sensor.id && newSelectedNodes.has(s.id),
      );
      if (!hasSelectedSensors) {
        newSelectedMachines.delete(machine.id);
      }
    } else {
      newSelectedNodes.add(sensor.id);
      const allSensorsSelected = machine.sensorNodes.every(
        (s) => s.id === sensor.id || newSelectedNodes.has(s.id),
      );
      if (allSensorsSelected) {
        newSelectedMachines.add(machine.id);
      }
    }

    setSelectedNodes(newSelectedNodes);
    setSelectedMachines(newSelectedMachines);
    notifySelectionChange(newSelectedNodes);
  };

  const getMachineSelectionStatus = (machine: MachineSelectable) => {
    const totalSensors = machine.sensorNodes.length;
    const selectedCount = machine.sensorNodes.filter((sensor) =>
      selectedNodes.has(sensor.id),
    ).length;

    return {
      checked: selectedCount === totalSensors,
      indeterminate: selectedCount > 0 && selectedCount < totalSensors,
    };
  };

  const filteredMachines = useMemo(() => {
    if (!searchText) return project.machines;

    const searchLower = searchText.toLowerCase();
    return project.machines.filter((machine) => {
      const machineMatches = machine.name.toLowerCase().includes(searchLower);
      const sensorMatches = machine.sensorNodes.some((sensor) =>
        sensor.sensorNodeId.toLowerCase().includes(searchLower),
      );
      return machineMatches || sensorMatches;
    });
  }, [project.machines, searchText]);

  return (
    <Stack className={styles.container} tokens={{ childrenGap: 16 }}>
      <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
        <Text variant='xLarge' styles={componentStyles.title}>
          Sensor Selection
        </Text>
        <SearchBox
          className={styles.searchBox}
          placeholder='Search machines or sensors...'
          styles={componentStyles.searchBox}
          onChange={(_, newValue) => setSearchText(newValue || '')}
        />
      </Stack>

      <Stack tokens={{ childrenGap: 8 }}>
        {filteredMachines.map((machine) => {
          const isExpanded = expandedMachines.has(machine.id);
          const selectionStatus = getMachineSelectionStatus(machine);

          return (
            <Stack key={machine.id} className={styles.machineContainer}>
              <Stack
                horizontal
                className={styles.machineHeader}
                onClick={() => toggleMachineExpanded(machine.id)}
              >
                <IconButton
                  iconProps={{
                    iconName: isExpanded ? 'ChevronDown' : 'ChevronRight',
                  }}
                  styles={componentStyles.expandIcon}
                />
                <Checkbox
                  label={`${machine.name} (${machine.sensorNodes.length})`}
                  checked={selectionStatus.checked}
                  indeterminate={selectionStatus.indeterminate}
                  styles={componentStyles.headerCheckbox}
                  onChange={() => toggleMachineSelected(machine)}
                />
              </Stack>

              {isExpanded && (
                <Stack className={AnimationClassNames.slideDownIn20}>
                  {machine.sensorNodes.map((sensor) => (
                    <Stack key={sensor.id} horizontal className={styles.sensorContainer}>
                      <Checkbox
                        label={sensor.sensorNodeId}
                        checked={selectedNodes.has(sensor.id)}
                        onChange={() => toggleSensorSelected(sensor, machine)}
                      />
                    </Stack>
                  ))}
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default SensorNodeSelector;
