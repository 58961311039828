import {
  Text,
  TooltipHost,
  Icon,
  Stack,
  IconButton,
  Breadcrumb,
} from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Status } from "../../../schema/status";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import {
  listAsyncCompaDetails,
  selectCompDetails,
  selectCompanyDetailsError,
  selectCompanyDetailsStatus,
} from "./reducer";

import { Project } from "../../Projects/models";

import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../../common/CommandBar";
import Table, { Column, useTableFilters } from "../../common/Table";

import { notification } from "../../common/Notification";
import { DeleteConfirm } from "./DeleteConfirm";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../../schema/Constants";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import { AddOrEditDialog } from "../../Projects/AddOrEditDialog";
import { Company } from "../models";
import {
  listAsyncCompanies,
  selectCompanies,
  selectCompaniesStatus,
} from "../reducer";
import { setStatus } from "../../Projects/reducer";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";

// --- Columns ---

type GetColumnsOpts = {
  hasActions: boolean;
  onEdit: (project: Project) => void;
  onDelete: (project: Project) => void;
};

const getColumns = ({
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, name }: Project) => (
        <Link to={"../projects/" + id.toString()} style={linkStyle}>
          {name}
        </Link>
      ),
    },
    {
      key: "city",
      name: "City",
      fieldName: "city",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "country",
      name: "Country",
      fieldName: "country",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "continent",
      name: "Continent",
      fieldName: "continent",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (project: Project) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={"Edit"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(project)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(project)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

export const CompanyDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const error = useAppSelector(selectCompanyDetailsError);
  const dispatch = useAppDispatch();
  const auth = useContext(authContext);
  const companiesStatus = useAppSelector(selectCompaniesStatus);
  const compas = useAppSelector(selectCompanies);
  const items = useAppSelector(selectCompDetails);
  const status = useAppSelector(selectCompanyDetailsStatus);
  const hasWritePermission = auth.metaDataContributor;

  useEffect(() => {
    if (companiesStatus === Status.void) dispatch(listAsyncCompanies());
  }, [companiesStatus, dispatch]);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const { filters, handleSearch } = useTableFilters<Project>({
    keys: ["name", "city", "country"],
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listAsyncCompaDetails(id ? id : ""));
  }, [dispatch, id]);

  const [selected, setSelected] = useState<{
    data: Project | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  const onEdit = (project: Project) =>
    setSelected({ data: project, context: "edit" });

  const onDelete = (project: Project) =>
    setSelected({ data: project, context: "delete" });

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{items.name}</Text>,
    },
  ];

  const goBack = () => navigate(-1);
  return (
    <>
      {items ? (
        <>
          <Stack horizontal verticalAlign="center">
            <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
            <Breadcrumb
              items={[
                {
                  key: `corporation-${items.corporation?.number}`,
                  text: items.corporation.name,
                },
                { key: `company-${items.number}`, text: items.name },
              ]}
              styles={{
                root: { margin: 0 },
                item: { fontSize: "14px" },
                chevron: { fontSize: "10px" },
              }}
            />
          </Stack>
          <div style={pageStyle}>
            <BaseCommandBar
              items={computeCommandBarItems(commandBarItems)}
              onSearch={handleSearch}
              styles={commandBarStyles}
            />

            <Table
              persistOpts={{
                key: "table-companyDet",
                version: 2,
              }}
              header={{
                title: "Projects",
              }}
              items={items.projects}
              columns={getColumns({
                hasActions: hasWritePermission,
                onEdit,
                onDelete,
              })}
              filters={filters}
              hasSelection={false}
              isLoading={status === Status.loading}
              isError={status === Status.error}
            />

            <DeleteConfirm
              data={selected?.data}
              show={selected?.context === "delete"}
              onSuccess={(hasError) => {
                if (hasError) {
                  notification.error(
                    `Failed deleting ${selected?.data?.name} project`
                  );
                } else {
                  dispatch(listAsyncCompaDetails(id ? id : ""));
                  notification.success(
                    `${selected?.data?.name} project deleted successfully`
                  );
                }

                setSelected(null);
              }}
              onClose={() => setSelected(null)}
            />
            {["add", "edit"].includes(selected?.context) && (
              <AddOrEditDialog
                options={compas.map((comp: Company) => ({
                  key: comp.id,
                  text: comp.name,
                }))}
                data={selected?.data}
                items={items.projects}
                show={["add", "edit"].includes(selected?.context)}
                onSuccess={(hasError, data, context) => {
                  if (hasError) {
                    const message = `Failed ${
                      context === "add" ? "creating" : "updating"
                    } ${data.name}`;

                    notification.error(message);
                  } else {
                    dispatch(listAsyncCompaDetails(id));
                    dispatch(setStatus(Status.void));
                    const message = `${data.name} ${
                      context === "add" ? "created" : "updated"
                    }  successfully`;

                    notification.success(message);
                  }
                }}
                onClose={() => {
                  setSelected(null);
                }}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <NotFoundRoute />
        </>
      )}
    </>
  );
};
