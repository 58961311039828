import { useQuery } from '@tanstack/react-query';

import type { FileResponse } from '../../../../utils';
import { mapFileResponseToFileData } from '../../../../utils';
import { getApiClient } from '../../../../modules/core/apiClient/useApiStore';
import type { DocumentResponse } from '../../MachineCVDocumentsPage/hooks/useDocumentsQuery';
import type { InfoEntryRequest } from '../../../../types';

type UseMachineCVInfoQuery = {
  data: any[];
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

type InfoProps = {
  machineId: string;
};

const isGroup = (entry: InfoEntryRequest) => {
  return !entry.fieldName && !entry.fieldValue;
};

const parseEntries = (data: InfoEntryRequest[], files: FileResponse[]) => {
  // create groups

  return data
    .filter((entry) => isGroup(entry))
    .map((entry) => ({
      id: entry.id || '',
      name: entry.group,
      entries: data
        .filter((groupEntry) => !isGroup(groupEntry) && entry.group === groupEntry.group)
        .map((entry) => ({
          id: entry.id,
          value: entry.fieldValue,
          name: entry.fieldName,
          files: mapFileResponseToFileData(files.filter((file) => entry.fileIds.includes(file.id))),
        })),
    }));
};

export const useMachineCVInfo = ({ machineId }: InfoProps): UseMachineCVInfoQuery => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['machine-cv-info', machineId],
    () =>
      Promise.allSettled([
        getApiClient().get<InfoEntryRequest[]>(`/meta/machinecv/v1/infos/machines/${machineId}`),
        getApiClient().get<DocumentResponse[]>(`files/v1/files/machines/${machineId}`),
      ]).then((responses) => {
        if (responses.map((response) => response.status).includes('rejected')) return [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const entries = responses[0].value.data;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const files = responses[1]?.value.data;
        return parseEntries(entries, files);
      }),
    { cacheTime: 0 },
  );

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
