/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Label, p50, p100, Picture } from '@amcharts/amcharts5';
import { format } from 'date-fns';

import { getClosestIndex } from '../../../../utils';

import type { XYChartRef } from '..';

interface GroupedTooltipOpts {
  ref: XYChartRef;
  text?: string;
  type?: 'number' | 'date';
  hasReferenceSignal?: boolean;
}

export const createGroupedTooltip = ({
  ref,
  type = 'number',
  hasReferenceSignal,
}: GroupedTooltipOpts) => {
  const { root, chart } = ref;

  const cursor = chart.get('cursor');

  const tooltipLabel = Label.new(root, {
    ariaLabel: 'chart-tooltip',
    html: '',
    centerX: p50,
    centerY: p100,
    x: p50,
    y: p100,
    visible: false,
  });

  chart.plotContainer.children?.each((item) => {
    if (item instanceof Label && item.get('ariaLabel') === 'chart-tooltip') {
      item.dispose();
    }
  });

  chart.plotContainer.children.push(tooltipLabel);

  const updateTooltip = (
    xValue: number,
    yValue: number,
    groupedValues: Record<string, string[]>,
    iconsValues: Record<string, string[]>,
  ) => {
    let tooltipLabelText = '';

    Object.entries(groupedValues).forEach(([unit, values], unitIndex, unitsArray) => {
      const formattedValues = values.map((value, index) => {
        const src = iconsValues[unit][index] || '';
        let referenceShapeIcon = '';

        // === Only show referenceShapeIcon for last unit if there is more than one unit

        if (
          hasReferenceSignal &&
          Object.keys(groupedValues).length > 1 &&
          unitIndex === unitsArray.length - 1
        ) {
          referenceShapeIcon = '<div class="reference-tooltip-shape-icon"></div>';
        }

        return `
          <span class="chart-tooltip__content">
            <img src="${src}"/>
            ${referenceShapeIcon}
            ${parseFloat((+value).toFixed(4))}
          </span>`;
      });

      tooltipLabelText += `
        <div class="chart-tooltip__label">
          <strong>${unit}:</strong>
          ${formattedValues.join('')}
        </div>`;
    });

    const tooltipValue =
      type === 'date' ? format(new Date(xValue), 'dd MMM yyyy HH:mm') : xValue.toFixed(4);

    const htmlContent = `<div class="chart-tooltip">${tooltipLabelText}  <strong>(t):</strong> ${tooltipValue}<br/> <strong>(val):</strong> ${yValue.toFixed(
      4,
    )}</div>`;

    tooltipLabel.set('html', htmlContent);
    tooltipLabel.set('visible', true);
  };

  cursor?.events.on('cursormoved', () => {
    const xAxis = chart.xAxes.getIndex(0);
    const yAxis = chart.yAxes.getIndex(0);

    const selectedXPosition = cursor?.getPrivate('positionX') as number;
    const selectedYPosition = cursor?.getPrivate('positionY') as number;

    // @ts-ignore
    const xValue = xAxis?.positionToValue(xAxis?.toAxisPosition(selectedXPosition));
    // @ts-ignore
    const yValue = yAxis?.positionToValue(yAxis?.toAxisPosition(selectedYPosition));
    const groupedValues: Record<string, string[]> = {};
    const iconsValues: Record<string, string[]> = {};

    chart?.series?.each((serie, index) => {
      const yAxis = serie.get('yAxis');
      const yAxisContainer = yAxis?.children?.getIndex(0);

      let unit = '' as string;

      // @ts-ignore
      for (const item of yAxisContainer?.children || []) {
        if (item instanceof Label && item.get('ariaLabel') === 'yAxisLabel') {
          unit = item.get('text') || '';
          break;
        }
      }

      const dataItemIndex = chart?.series
        ?.getIndex(index)
        // @ts-ignore
        ?.dataItems?.findIndex((item) => item?.get('valueX') >= xValue);

      if (dataItemIndex !== -1) {
        groupedValues[unit] = groupedValues[unit] ?? [];
        iconsValues[unit] = iconsValues[unit] ?? [];

        // @ts-ignore
        yAxisContainer?.children?.each((item) => {
          if (item instanceof Picture && item.get('ariaLabel') === 'y-axis-icon') {
            // @ts-ignore
            iconsValues[unit].push(item.get('src'));
          }
        });

        const closestIdx = getClosestIndex({
          foundIndex: dataItemIndex as number,
          threshold: xValue,
          length: chart?.series?.getIndex(index)?.dataItems.length ?? 0,
          getItem: (idx) => chart?.series?.getIndex(index)?.dataItems?.at(idx)?.get('valueX') ?? -1,
        });

        groupedValues[unit].push(
          // @ts-ignore
          `${chart?.series?.getIndex(index)?.dataItems?.at(closestIdx)?.get('valueY')}`,
        );
      }
    });

    updateTooltip(xValue, yValue, groupedValues, iconsValues);
  });

  cursor?.events.on('cursorhidden', () => {
    tooltipLabel.set('visible', false);
  });
};

export default createGroupedTooltip;
