import { Stack } from "@fluentui/react";
import classNames from "classnames";

import useSectionsStore from "../../../../Hooks/useSectionsStore";
import { useSelectedSignalsStore } from "../../../../modules/analysis-raw-data";
import Placeholder from "../../../../modules/analysis-raw-data/components/Placeholder";
import SpectrumChartSection from "../../../../modules/analysis-raw-data/components/SpectrumChartSection";
import TrendChartSection from "../../../../modules/analysis-raw-data/components/TrendChartSection";
import WaveformChartSection from "../../../../modules/analysis-raw-data/components/WaveformChartSection";
import useDataPoints from "../../../../modules/analysis-raw-data/hooks/useDataPoints";

const TrendViewLayout = () => {
  const selectedSignals = useSelectedSignalsStore(
    (store) => store.selectedSignals
  );

  const getDataPoints = useDataPoints();

  const trendSignal = selectedSignals.find(
    (signal) => signal.dataType === "Trend"
  );
  const rawSignal = selectedSignals.find(
    (signal) => signal.dataType === "ShortTerm"
  );

  const { isAnyMaximized } = useSectionsStore((store) => ({
    domIds: store.domIds,
    isAnyMaximized: store.isAnyMaximized,
  }));

  return (
    <div>
      {trendSignal ? (
        <TrendChartSection signal={trendSignal} />
      ) : (
        <Placeholder
          icon="left"
          text="Please select a Trend Signal"
          isLoading={false}
          style={{ height: 511 }}
        />
      )}
      <hr
        className={classNames("trend-view-layout-divider", {
          "trend-view-layout-divider--hidden": isAnyMaximized(),
        })}
      />
      {rawSignal ? (
        <Stack
          horizontal
          className={classNames("layout-grid", {
            "layout-grid--hidden": isAnyMaximized(),
          })}
        >
          <WaveformChartSection
            signal={rawSignal}
            dataPoint={getDataPoints(rawSignal?.id)[0] as string}
          />
          <SpectrumChartSection
            signal={rawSignal}
            dataPoint={getDataPoints(rawSignal?.id)[0] as string}
          />
        </Stack>
      ) : null}
    </div>
  );
};

export default TrendViewLayout;
