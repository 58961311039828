import {
  Icon,
  TooltipHost,
  Text,
  Breadcrumb,
  Stack,
  IconButton,
} from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Status } from "../../../schema/status";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import {
  listAsyncCorpoDetails,
  selectCorpDetails,
  selectCorporationDetailsError,
  selectCorporationDetailsStatus,
} from "./reducer";
import { Company } from "../../Companies/models";
import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../../common/CommandBar";
import Table, { Column, useTableFilters } from "../../common/Table";
import { notification } from "../../common/Notification";

import { DeleteConfirm } from "./DeleteConfirm";
import { AddOrEditDialog } from "./AddOrEditDialog";
import { listAsyncCompaDetails } from "../../Companies/CompaniesDetails/reducer";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../../schema/Constants";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";
// --- Styles ---

// --- Columns ---

type GetColumnsOpts = {
  hasActions: boolean;
  onEdit: (company: Company) => void;
  onDelete: (company: Company) => void;
};

const getColumns = ({
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, name }: Company) => (
        <Link to={"../companies/" + id.toString()} style={linkStyle}>
          {name}
        </Link>
      ),
    },
    {
      key: "number",
      name: "Number",
      fieldName: "number",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (company: Company) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={"Edit"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(company)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(company)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

export const CorporationDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const error = useAppSelector(selectCorporationDetailsError);
  const dispatch = useAppDispatch();
  const auth = useContext(authContext);
  const items = useAppSelector(selectCorpDetails);
  const status = useAppSelector(selectCorporationDetailsStatus);
  const hasWritePermission = auth.metaDataContributor;

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const { filters, handleSearch } = useTableFilters<Company>({
    keys: ["name", "number"],
  });
  const navigate = useNavigate();

  useEffect(() => {
    //if (status === Status.void)
    dispatch(listAsyncCorpoDetails(id ? id : ""));
  }, [dispatch, id]);

  const [selected, setSelected] = useState<{
    data: Company | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  const onEdit = (company: Company) =>
    setSelected({ data: company, context: "edit" });

  const onDelete = (company: Company) =>
    setSelected({ data: company, context: "delete" });

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{items.name}</Text>,
    },
  ];

  const goBack = () => navigate(-1);
  return (
    <>
      {items ? (
        <>
          <Stack horizontal verticalAlign="center">
            <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
            <Breadcrumb
              items={[{ key: `corporation-${items.number}`, text: items.name }]}
              styles={{
                root: { margin: 0 },
                item: { fontSize: "14px" },
                chevron: { fontSize: "10px" },
              }}
            />
          </Stack>
          <div style={pageStyle}>
            <BaseCommandBar
              items={computeCommandBarItems(commandBarItems)}
              onSearch={handleSearch}
              styles={commandBarStyles}
            />

            <Table
              persistOpts={{
                key: "table-companies",
                version: 2,
              }}
              header={{
                title: "Companies",
              }}
              items={items.companies}
              columns={getColumns({
                hasActions: hasWritePermission,
                onEdit,
                onDelete,
              })}
              filters={filters}
              hasSelection={false}
              isLoading={status === Status.loading}
              isError={status === Status.error}
            />

            <DeleteConfirm
              data={selected?.data}
              show={selected?.context === "delete"}
              onSuccess={(hasError) => {
                if (hasError) {
                  notification.error(
                    `Failed deleting ${selected?.data?.name} company`
                  );
                } else {
                  dispatch(listAsyncCorpoDetails(id ? id : ""));
                  notification.success(
                    `${selected?.data?.name} company deleted successfully`
                  );
                }

                setSelected(null);
              }}
              onClose={() => setSelected(null)}
            />
            <AddOrEditDialog
              data={selected?.data}
              items={items.companies}
              show={["add", "edit"].includes(selected?.context)}
              onSuccess={(hasError, data, context) => {
                if (hasError) {
                  const message = `Failed ${
                    context === "add" ? "creating" : "updating"
                  } ${data.name}`;

                  notification.error(message);
                } else {
                  dispatch(listAsyncCompaDetails(id));

                  const message = `${data.name} ${
                    context === "add" ? "created" : "updated"
                  }  successfully`;

                  notification.success(message);
                }
              }}
              onClose={() => {
                setSelected(null);
              }}
            />
          </div>
        </>
      ) : (
        <>
          <NotFoundRoute />
        </>
      )}
    </>
  );
};
