export type Range = {
  startIndex: number;
  endIndex: number;
};

export const getRangeIndexes = ({
  data,
  start,
  end,
}: {
  data: { date: number }[];
  start: number;
  end: number;
}): Range => ({
  startIndex: getRangeIndex({ data, threshold: start, defaultValue: 0 }),
  endIndex: getRangeIndex({ data, threshold: end, defaultValue: data.length - 1 }),
});

export const getRangeIndex = ({
  data,
  threshold,
  defaultValue,
}: {
  data: { date: number }[];
  threshold: number;
  defaultValue: number;
}) => {
  const index = data.findIndex(({ date }) => date >= threshold);

  if (index === -1) {
    return defaultValue;
  }

  return getClosestIndex({
    threshold,
    foundIndex: index,
    length: data.length,
    getItem: (idx) => data[idx].date,
  });
};

export const getClosestIndex = ({
  threshold,
  foundIndex,
  length,
  getItem,
}: {
  threshold: number;
  foundIndex: number;
  length: number;
  getItem: (idx: number) => number;
}) => {
  switch (foundIndex) {
    case 0: {
      const diff = Math.abs(threshold - getItem(foundIndex));
      const nextDiff = Math.abs(threshold - getItem(foundIndex + 1));

      return diff < nextDiff ? foundIndex : foundIndex + 1;
    }
    case length - 1: {
      const prevDiff = Math.abs(threshold - getItem(foundIndex - 1));
      const diff = Math.abs(threshold - getItem(foundIndex));

      return prevDiff < diff ? foundIndex - 1 : foundIndex;
    }
    default: {
      const prevDiff = Math.abs(threshold - getItem(foundIndex - 1));
      const diff = Math.abs(threshold - getItem(foundIndex));
      const nextDiff = Math.abs(threshold - getItem(foundIndex + 1));

      if (prevDiff < diff && prevDiff < nextDiff) {
        return foundIndex - 1;
      }

      if (nextDiff < prevDiff && nextDiff < diff) {
        return foundIndex + 1;
      }

      return foundIndex;
    }
  }
};
