import type {
  IButtonProps,
  IContextualMenuItem,
  IContextualMenuListProps,
  IRenderFunction,
  ITooltipHostStyles,
} from "@fluentui/react";
import { DefaultButton, SearchBox, TooltipHost } from "@fluentui/react";

import type { CSSProperties } from "react";
import { useCallback, useId, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";

import FastTrendSignalIcon from "../../../../assets/svg/FastTrendSignalIcon";
import RawDataSignalIcon from "../../../../assets/svg/RawDataSignalIcon";
import TrendSignalIcon from "../../../../assets/svg/TrendSignalIcon";

import DataTypeEnum from "../../constants/DataTypeEnum";

const filteredItemsStyle: CSSProperties = {
  width: "100%",
  height: 164,
  minWidth: 250,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const defaultButtonStyle: CSSProperties = {
  border: "unset",
  minWidth: "30px",
  padding: "0px",
  background: "transparent",
};

export type SignalItem = {
  id: string;
  name: string;
  label: string;
  icon: DataTypeEnum;
};

const createMenuItems = (
  items: SignalItem[],
  hasNoneItem: boolean,
  onSelect: (item: Omit<SignalItem, "icon"> | undefined) => void
): IContextualMenuItem[] => {
  if (items.length === 0) {
    return [];
  }

  const menuItems = items.map((child) => ({
    key: child.id,
    data: child.name,
    text: child.label,
    iconProps: {},
    onClick: (
      ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
      { key, data, text }: IContextualMenuItem
    ) => {
      ev.preventDefault();
      onSelect({
        id: key,
        name: data,
        label: text as string,
      });
    },
    onRenderIcon: () => {
      switch (child.icon) {
        case DataTypeEnum.Trend:
          return <TrendSignalIcon />;
        case DataTypeEnum.FastTrend:
          return <FastTrendSignalIcon />;
        case DataTypeEnum.RawData:
          return <RawDataSignalIcon />;
        default:
          return null;
      }
    },
  }));

  if (hasNoneItem) {
    return [
      {
        key: "none",
        text: "None",
        onClick: () => onSelect(undefined),
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...menuItems,
    ];
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return menuItems;
};

const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block", width: "100%" },
};

type SignalsAutocompleteProps = IButtonProps & {
  selectedItems?: any;
  items: SignalItem[];
  hasNoneItem?: boolean;
  onSelectSignal: (item: Omit<SignalItem, "icon"> | undefined) => void;
};

const SignalsAutocomplete = ({
  items: _items,
  selectedItems,
  hasNoneItem = false,
  onSelectSignal,
  ...rest
}: SignalsAutocompleteProps) => {
  const [items, setItems] = useState<IContextualMenuItem[]>(() =>
    createMenuItems(_items, hasNoneItem, onSelectSignal)
  );

  const setInitial = useCallback(
    () => setItems(createMenuItems(_items, hasNoneItem, onSelectSignal)),
    []
  );

  const tooltipId = useId();

  const onChange = useCallback((newValue?: string) => {
    if (!newValue || newValue.trim().length === 0) {
      setInitial();
      return;
    }

    const trimmedValue = newValue?.trim().toLowerCase();

    const filteredItems = createMenuItems(_items, false, onSelectSignal).filter(
      ({ data, text }) =>
        data?.toLowerCase().indexOf(trimmedValue) !== -1 ||
        text?.toLowerCase().indexOf(trimmedValue) !== -1
    );

    if (!filteredItems || !filteredItems.length) {
      filteredItems.push({
        key: "no_results",
        onRender: () => (
          <div key="no_results" style={filteredItemsStyle}>
            <span>No signals found</span>
          </div>
        ),
      });
    }

    setItems(filteredItems);
  }, []);

  const renderMenuList = useCallback(
    (
      menuListProps: IContextualMenuListProps,
      defaultRender: IRenderFunction<IContextualMenuListProps>
    ) => {
      return (
        <div style={{ padding: "8px 0 8px 8px" }} className="search-select-box">
          <div style={{ width: "100%" }}>
            <SearchBox
              ariaLabel="Search"
              placeholder="Search"
              styles={{ root: { marginBottom: 8, marginRight: 8 } }}
              onAbort={setInitial}
              onClear={setInitial}
              onChange={(_, value) => onChange(value)}
            />
          </div>
          <FixedSizeList
            height={165}
            itemCount={menuListProps.items.length}
            itemSize={33}
            width="100%"
            className="signals-autocomplete-list"
          >
            {({ index, style }) => (
              <div
                style={style}
                className={
                  selectedItems?.filter(
                    (item: any) => item.id === menuListProps.items[index].key
                  )[0]
                    ? "selected"
                    : ""
                }
              >
                {defaultRender({
                  ...menuListProps,
                  items: [menuListProps.items[index]],
                })}
              </div>
            )}
          </FixedSizeList>
        </div>
      );
    },
    [onChange, setInitial, selectedItems]
  );

  const menuProps = useMemo(() => {
    if (items.length === 0) {
      return undefined;
    }

    return {
      items,
      hasIcons: true,
      onMenuDismissed: () => setInitial(),
      onRenderMenuList: renderMenuList,
      onRenderContextualMenuItem: (
        itemProps: IContextualMenuItem,
        defaultRender: IRenderFunction<IContextualMenuItem>
      ) => (
        <TooltipHost
          id={tooltipId}
          content={itemProps.text}
          calloutProps={calloutProps}
          styles={hostStyles}
        >
          {defaultRender(itemProps)}
        </TooltipHost>
      ),
    };
  }, [items, renderMenuList]);

  return (
    <DefaultButton
      style={defaultButtonStyle}
      {...rest}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      menuProps={menuProps}
    />
  );
};

export default SignalsAutocomplete;
