import {
  DirectionalHint,
  Text,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { FontIcon } from "@fluentui/react/lib/Icon";

import type { Condition } from "../../../modules/machine-cv/constants";
import { conditionFormat } from "../../../modules/machine-cv/constants";

type SummaryBadgeProps = {
  description?: string;
  value?: string;
  isTooltip?: any;
};

export const SummaryBadge = ({
  description,
  value,
  isTooltip,
}: SummaryBadgeProps) => {
  return (
    <>
      {isTooltip && description && (
        <TooltipHost
          content={description}
          delay={TooltipDelay.zero}
          id="signal-info-tooltip"
          directionalHint={DirectionalHint.bottomCenter}
        >
          <FontIcon aria-describedby="signal-info-tooltip" iconName="Info" />
        </TooltipHost>
      )}
      {!isTooltip && description && (
        <Text
          style={{
            color: "#fff",
            marginRight: "auto",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {description}
        </Text>
      )}
      <Text style={{ color: "#fff" }}>
        {conditionFormat[value as Condition]}
      </Text>
    </>
  );
};
