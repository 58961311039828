import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { apiService } from '../../modules/common';
import { queryClient } from '../../modules/core';
import type { HomeOverview } from '../../types';

const useHomeOverview = (
  options?:
    | (Omit<
        UseQueryOptions<HomeOverview, unknown, HomeOverview, string[]>,
        'initialData' | 'queryFn' | 'queryKey'
      > & { initialData?: (() => undefined) | undefined })
    | undefined,
) => useQuery(['home-overview'], apiService.machineCV.getHomeOverview, options);

export const prefetchMachineCvOverviewList = async () => {
  await queryClient.prefetchQuery(['home-overview'], apiService.machineCV.getHomeOverview);
};

export default useHomeOverview;
