import React, { createContext, useContext, useState } from 'react';

export interface WaveformDifferenceState {
  line1?: number;
  line2?: number;
  rasterized?: boolean;
}

interface WaveformDifferenceContextType {
  differences: { [key: string]: WaveformDifferenceState };
  setLine1: (signalId: string, value: number) => void;
  setLine2: (signalId: string, value: number) => void;
  setRasterized: (signalId: string, value: boolean) => void;
  removeSignal: (signalId: string) => void;
}

const WaveformDifferenceContext = createContext<WaveformDifferenceContextType | undefined>(
  undefined,
);

export const DifferenceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [differences, setDifferences] = useState<{ [key: string]: WaveformDifferenceState }>({});

  const setLine1 = (signalId: string, value: number) => {
    setDifferences((prev) => ({
      ...prev,
      [signalId]: { ...prev[signalId], line1: value },
    }));
  };

  const setLine2 = (signalId: string, value: number) => {
    setDifferences((prev) => ({
      ...prev,
      [signalId]: { ...prev[signalId], line2: value },
    }));
  };

  const setRasterized = (signalId: string, value: boolean) => {
    setDifferences((prev) => ({
      ...prev,
      [signalId]: { ...prev[signalId], rasterized: value },
    }));
  };

  const removeSignal = (signalId: string) => {
    setDifferences((prev) => ({
      ...prev,
      [signalId]: {},
    }));
  };

  return (
    <WaveformDifferenceContext.Provider
      value={{ differences, setLine1, setLine2, setRasterized, removeSignal }}
    >
      {children}
    </WaveformDifferenceContext.Provider>
  );
};

export const useDifference = () => {
  const context = useContext(WaveformDifferenceContext);
  if (!context) {
    throw new Error('useDifference must be used within a DifferenceProvider');
  }
  return context;
};
