import { Text, TooltipHost, Icon, Dialog, DialogType } from "@fluentui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import {
  DashBoardToList,
  PowerBIEmbedItem,
  PowerBIEmbedItemParentType,
  ResponsePowerBIGet,
} from "./models";
import {
  listAsyncDashB,
  selectDashboards,
  selectDashboardsStatus,
} from "./reducer";

import { detailsDashboard } from "./api";
import {
  listAsyncProjs,
  selectProjects,
  selectProjectsStatus,
} from "../Projects/reducer";
import {
  listAsyncMachines,
  selectMachines,
  selectMachinesStatus,
} from "../Machines/reducer";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";

import Table, { Column, useTableFilters } from "../common/Table";
import { notification } from "../common/Notification";
import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../common/CommandBar";

import {
  listAsyncCompanies,
  selectCompanies,
  selectCompaniesStatus,
} from "../Companies/reducer";
import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsStatus,
} from "../Corporations/reducer";
import { DeleteConfirm } from "./DeleteConfirm";
import { PowerBIEmbed } from "powerbi-client-react";
import { Link } from "react-router-dom";
import {
  DashboardAddDialog,
  DashboardEditDialog,
} from "./DashboardAddEditDialogs";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";

export const dashBContext = createContext<PowerBIEmbedItem[] | undefined>(
  undefined
);

export const selDashBDetailsContext = createContext<
  ResponsePowerBIGet | undefined
>(undefined);

type GetColumnsOpts = {
  hasActions: boolean;
  hasView: boolean;
  onEdit: (dBoard: DashBoardToList) => void;
  onDelete: (dBoard: DashBoardToList) => void;
  onView: (dBoard: DashBoardToList) => void;
};

const getColumns = ({
  hasActions,
  hasView,
  onEdit,
  onDelete,
  onView,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "parentName",
      name: "Name",
      fieldName: "parentName",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, parentName, parentType, parentId }: DashBoardToList) => (
        <Link
          to={
            parentType === "Corporation"
              ? "../corporations/" + parentId.toString()
              : parentType === "Company"
                ? "../companies/" + parentId.toString()
                : parentType === "Project"
                  ? "../projects/" + parentId.toString()
                  : "../machines/" + parentId.toString()
          }
          style={linkStyle}
        >
          {parentName}
        </Link>
      ),
    },
    {
      key: "parentType",
      name: "Parent Type",
      fieldName: "parentType",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (dBoard: DashBoardToList) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={"Edit"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(dBoard)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(dBoard)}
              style={iconStyle}
            />
          </TooltipHost>
          {hasView && (
            <TooltipHost
              key={2}
              content={"View"}
              styles={{ root: { display: "flex" } }}
            >
              <Icon
                iconName="View"
                onClick={() => onView(dBoard)}
                style={iconStyle}
              />
            </TooltipHost>
          )}
        </div>
      ),
    });
  }

  return columns;
};

export const DashboardsList: React.FunctionComponent = () => {
  const auth = useContext(authContext);
  const hasWritePermission = auth.powerBiContributor;
  const hasReadPermission = auth.powerBiReader;
  const dashBs = useAppSelector(selectDashboards);
  const dashboardStatus = useAppSelector(selectDashboardsStatus);
  const [status, setStatus] = useState<Status>(Status.loading);
  const corpos = useAppSelector(selectCorporations);
  const compas = useAppSelector(selectCompanies);
  const projs = useAppSelector(selectProjects);
  const machs = useAppSelector(selectMachines);
  const companiesStatus = useAppSelector(selectCompaniesStatus);
  const corporationsStatus = useAppSelector(selectCorporationsStatus);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const [dBoardsItems, setDBoardsItems] = useState<DashBoardToList[]>([]);
  const { filters, handleSearch } = useTableFilters<DashBoardToList>({
    keys: ["parentName", "parentType"],
  });
  const [selected, setSelected] = useState<{
    data: DashBoardToList | null;
    context: "add" | "edit" | "delete" | "view";
    embedToken?: string;
    embedUrl?: string;
  } | null>(null);

  const dispatch = useAppDispatch();

  // Gets the corporations, companies, projects, and machines lists if they are not loaded
  useEffect(() => {
    if (corporationsStatus === Status.void) dispatch(listAsyncCorpo());
    if (companiesStatus === Status.void) dispatch(listAsyncCompanies());
    if (projectStatus === Status.void) dispatch(listAsyncProjs());
    if (machineStatus === Status.void) dispatch(listAsyncMachines());
    if (dashboardStatus === Status.void) dispatch(listAsyncDashB());
  }, [
    dispatch,
    machineStatus,
    projectStatus,
    dashboardStatus,
    corporationsStatus,
    companiesStatus,
  ]);

  // Builds the table items.
  useEffect(() => {
    if (
      dashboardStatus === Status.idle &&
      machineStatus === Status.idle &&
      projectStatus === Status.idle &&
      companiesStatus === Status.idle &&
      corporationsStatus === Status.idle
    ) {
      let toListAux: DashBoardToList[] = [];
      dashBs.forEach((db) => {
        let parentName: string = "";
        let result: DashBoardToList = {
          parentType: db.parentType,
          parentName: "",
          parentId: db.parentId,
          groupId: db.groupId,
          id: db.id,
        };

        switch (PowerBIEmbedItemParentType[db.parentType]) {
          case PowerBIEmbedItemParentType.Corporation:
            parentName =
              corpos.find((corp) => corp.id === db.parentId)?.name || "";
            break;
          case PowerBIEmbedItemParentType.Company:
            parentName =
              compas.find((comp) => comp.id === db.parentId)?.name || "";
            break;
          case PowerBIEmbedItemParentType.Project:
            parentName =
              projs.find((mach) => mach.id === db.parentId)?.name || "";
            break;
          case PowerBIEmbedItemParentType.Machine:
            parentName =
              machs.find((mach) => mach.id === db.parentId)?.dalogId || "";
            break;
        }

        if (parentName) {
          result.parentName = parentName;
          toListAux.push(result);
        }
      });
      setDBoardsItems(toListAux);
      setStatus(Status.idle);
    }
  }, [
    companiesStatus,
    compas,
    corporationsStatus,
    corpos,
    dashBs,
    dashboardStatus,
    dispatch,
    machineStatus,
    machs,
    projectStatus,
    projs,
  ]);

  // Handlers
  const onAdd = () => setSelected({ data: null, context: "add" });

  const onEdit = (dBoard: DashBoardToList) =>
    setSelected({ data: dBoard, context: "edit" });

  const onDelete = (dBoard: DashBoardToList) =>
    setSelected({ data: dBoard, context: "delete" });

  const onClose = () => setSelected(null);

  const onView = async (dBoard: DashBoardToList) => {
    await detailsDashboard(dBoard as PowerBIEmbedItem).then((res) => {
      if (!res) {
        notification.warning(
          `There is no dashboard available for the entity "${dBoard.parentName}".`
        );
        return;
      }

      setSelected({
        data: dBoard,
        context: "view",
        embedToken: res.embedToken,
        embedUrl: res.embedUrl,
      });
    });
  };

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>Dashboards</Text>,
    },
    ...(hasWritePermission
      ? [
          {
            key: "add",
            text: "Add",
            type: CommandBarItemType.Button,
            iconProps: { iconName: "Add" },
            onClick: onAdd,
          },
        ]
      : []),
  ];

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(commandBarItems)}
        onSearch={handleSearch}
        styles={commandBarStyles}
      />

      <Table
        persistOpts={{
          key: "table-dashboards",
          version: 2,
        }}
        header={{
          title: "Dashboards",
        }}
        items={dBoardsItems}
        columns={getColumns({
          hasActions: hasWritePermission,
          hasView: hasReadPermission,
          onEdit,
          onDelete,
          onView,
        })}
        filters={filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
      />

      {selected?.context === "add" && <DashboardAddDialog onClose={onClose} />}
      {selected?.context === "edit" && (
        <DashboardEditDialog entry={selected?.data} onClose={onClose} />
      )}
      <dashBContext.Provider value={dashBs}>
        {selected?.context === "view" && (
          <Dialog
            hidden={false}
            dialogContentProps={{
              title:
                selected?.context.charAt(0).toUpperCase() +
                selected?.context.slice(1) +
                " Dashboard",
              type: DialogType.close,
              onDismiss: onClose,
            }}
            minWidth={"auto"}
            maxWidth={"auto"}
          >
            {selected?.context === "view" && (
              <PowerBIEmbed
                embedConfig={{
                  type: "report",
                  id: selected?.data.id,
                  embedUrl: selected?.embedUrl,
                  accessToken: selected?.embedToken,
                  tokenType: 1,
                  settings: {
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                      pageNavigation: {
                        visible: false,
                      },
                    },
                  },
                }}
                eventHandlers={
                  new Map([
                    ["loaded", () => {}],
                    ["rendered", () => {}],
                    ["error", (event: any) => {}],
                  ])
                }
                cssClassName={"report-style-class-view"}
              />
            )}
          </Dialog>
        )}
      </dashBContext.Provider>
      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              `Failed deleting the dashboard for : ${selected?.data?.parentName} `
            );
          } else {
            dispatch(listAsyncDashB());
            notification.success(
              `${selected?.data?.parentName} dashboard related, deleted successfully.`
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />
    </div>
  );
};
