import type { TaskStatuses } from "../../../types";

export type Filter = "all" | "assignedtome" | "createdbyme";

export type PageActions =
  | { type: "SET_SEARCH"; payload: string }
  | { type: "SET_STATUS"; payload: keyof typeof TaskStatuses | "All" }
  | { type: "SET_FILTER"; payload: Filter };

export type PageState = {
  search: string;
  status: keyof typeof TaskStatuses | "All";
  filter: Filter;
  condition: string;
  searchBy: string;
};

export const pageInitialState: PageState = {
  search: "",
  status: "All",
  filter: "all",
  condition: "All",
  searchBy: "Project",
};

export const pageReducer = (
  state: PageState,
  action: PageActions
): PageState => {
  switch (action.type) {
    case "SET_SEARCH": {
      return {
        ...state,
        search: action.payload,
      };
    }
    case "SET_STATUS": {
      return {
        ...state,
        status: action.payload,
      };
    }
    case "SET_FILTER": {
      return {
        ...state,
        filter: action.payload,
      };
    }
  }
};
