import type { IColumn, IColumnReorderOptions } from "@fluentui/react";
import debounce from "lodash-es/debounce";
import { useCallback, useEffect } from "react";

import { DEFAULT_DEBOUNCE } from "../../../config/constants";
import { usePersistedState } from "../../../Hooks";

import type { Column, TableProps } from "./Table";

const defaultOpts = (columns: Column[]) =>
  columns.map(({ key }) => ({ key, width: undefined as number | undefined }));

const orderOpts = <T extends { key: string }[]>(columns: T, order: string[]) =>
  columns.sort(
    ({ key: aKey }, { key: bKey }) => order.indexOf(aKey) - order.indexOf(bKey)
  );

export type ColumnsOpts = {
  key: string;
  width: number | undefined;
}[];

export type UseColumnsOpts = {
  persistOpts: TableProps["persistOpts"];
  columns: Column[];
};

export const useColumns = ({
  columns: _columns,
  persistOpts: { key, version },
}: UseColumnsOpts) => {
  const [{ state: columnsOpts, version: currentVersion }, setOpts] =
    usePersistedState(key, {
      version,
      state: defaultOpts(_columns),
    });

  useEffect(() => {
    if (currentVersion !== version) {
      setOpts({ state: defaultOpts(_columns), version });
      return;
    }

    if (columnsOpts.length !== _columns.length) {
      const newOpts = orderOpts(
        _columns.map(({ key }) => {
          const targetIdx = columnsOpts.findIndex(
            ({ key: colKey }) => key === colKey
          );

          const width =
            targetIdx === -1 ? undefined : columnsOpts[targetIdx].width;

          return { key, width };
        }),
        columnsOpts.map(({ key }) => key)
      );

      setOpts({ state: newOpts, version });
      return;
    }
  }, [version, _columns.length]);

  useEffect(() => {
    return () => {
      onColumnResize.cancel();
    };
  }, [columnsOpts]);

  const columnReorderOpts: IColumnReorderOptions = {
    handleColumnReorder: (draggedIdx: number, targetIdx: number) => {
      const orderedColumns = orderOpts(
        _columns,
        columnsOpts.map(({ key }) => key)
      );

      const draggedColumn = orderedColumns[draggedIdx];

      const newColumns = [...orderedColumns];
      newColumns.splice(draggedIdx, 1);
      newColumns.splice(targetIdx, 0, draggedColumn);

      const newOpts = orderOpts(
        [...columnsOpts],
        newColumns.map(({ key }) => key)
      );

      setOpts({ state: newOpts, version });
    },
  };

  const handleResizeColumn = (column?: IColumn, newWidth?: number) => {
    if (!column || !newWidth) {
      return;
    }

    const targetIdx = columnsOpts.findIndex(({ key }) => key === column.key);

    if (targetIdx === -1) {
      return;
    }

    const newOpts = [...columnsOpts];
    newOpts[targetIdx] = { ...newOpts[targetIdx], width: newWidth };

    setOpts({ state: newOpts, version });
  };

  const onColumnResize = useCallback(
    debounce(handleResizeColumn, DEFAULT_DEBOUNCE),
    [columnsOpts]
  );

  return {
    columnsOpts,
    columnReorderOpts,
    onColumnResize,
    shouldOmitColumnsOpts: currentVersion !== version,
  };
};
