import { Stack } from "@fluentui/react";
import type { AxiosInstance } from "axios";
import { Outlet } from "react-router-dom";

import WirelessSensorsLayoutHeader from "./Header";
import WirelessSensorsLayoutSidebar from "./Sidebar";
import AppLayout from "../../../../templates/AppLayout";

type WirelessSensorsLayoutProps = {
  axiosInstance: AxiosInstance;
  marginTop?: number;
};

const WirelessSensorsLayout = ({
  axiosInstance,
  marginTop,
}: WirelessSensorsLayoutProps) => {
  // const [, , isValid] = useLocationSearch();
  //
  // if (!isValid) {
  //   return <ReactRouterDOM.Navigate replace to='/error' />;
  // }

  return (
    <AppLayout axiosInstance={axiosInstance}>
      <WirelessSensorsLayoutHeader />
      <Stack horizontal>
        <WirelessSensorsLayoutSidebar />
        {/*<div className='ws-content-wrapper'>{children}</div>*/}
        <div
          className="ws-content-wrapper"
          style={{ height: `calc(100vh - 62px - ${marginTop || 0}px)` }}
        >
          <Outlet />
        </div>
      </Stack>
    </AppLayout>
  );
};

export default WirelessSensorsLayout;
