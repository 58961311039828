export const COLOR_RED = '#F63F4D';
export const COLOR_ORANGE = '#FF7E0D';
export const COLOR_YELLOW = '#FFCC00';
export const COLOR_GREEN = '#66CD7D';
export const COLOR_DARK_GREEN = '#4A9D7F';

export const STATUSES_COLOR: any = {
  Normal: COLOR_GREEN,
  High: COLOR_YELLOW,
  Overload: COLOR_ORANGE,
  Faulty: '#C7B8E7',
  'Not Installed': '#B3B3B3',
};
