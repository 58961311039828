import { setMaxNumberDecimals } from "../../../../../../modules/common/components/InteractiveColorBar/methods";
import KpiTable from "../../Statistics/KpiTable";
import type { Column } from "../../../../../common/Table/Table";

const getColumns = (): Column[] => [
  {
    key: "signalDescription",
    fieldName: "signalDescription",
    name: "Signal",
    isSortable: true,
    isResizable: true,
    minWidth: 40,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) => row?.signalDescription || "-",
  },
  {
    key: "unit",
    fieldName: "unit",
    name: "Unit",
    isSortable: true,
    isResizable: true,
    minWidth: 30,
    calculatedWidth: 0,
    flexGrow: 1,
  },
  {
    key: "best",
    fieldName: "best",
    name: "Best",
    isSortable: true,
    isResizable: true,
    minWidth: 40,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) => (row?.best ? setMaxNumberDecimals(row.best, 3) : "-"),
  },
  {
    key: "current",
    fieldName: "current",
    name: "Current",
    isSortable: true,
    isResizable: true,
    minWidth: 60,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) =>
      row?.current ? setMaxNumberDecimals(row.current, 3) : "-",
  },
  {
    key: "currentBest",
    fieldName: "currentBest",
    name: "Current-Best",
    isSortable: true,
    isResizable: true,
    minWidth: 40,
    calculatedWidth: 0,
    flexGrow: 1,
    onRender: (row) =>
      row?.currentBest || row?.currentBest === 0
        ? setMaxNumberDecimals(row.currentBest, 3)
        : "-",
  },
];

type ModalTableProps = {
  title: string;
  tableHeight: string;
};

const ModalTable = ({ title, tableHeight, ...rest }: ModalTableProps) => {
  const tableProps = {
    tableColumns: getColumns(),
    ...(rest || {}),
  };

  return (
    <div style={{ height: tableHeight, overflow: "hidden" }}>
      <div className="performance-modal__table-wrap-title">{title}</div>

      <div className="performance-modal__table">
        <KpiTable {...tableProps} />
      </div>
    </div>
  );
};

export default ModalTable;
