/* eslint-disable react-hooks/exhaustive-deps */
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import {
  AutomationStatus,
  AutomationStatusType,
  ConnectionStatus,
  ConnectionStatusType,
  ConnectionEntryDetailed,
  MetadataVpnPC,
  MetadataProject,
} from "../../Schema/models";
import FormDialog from "../generic/FormDialog";
import { Utils } from "../../Utils/utils";
import { FormItemSection, FormItemRow } from "../generic/FormDialogComponents";
import { FieldError } from "react-hook-form";
import ControlledComboBox from "../generic/ControlledComboBox";
import { useContext, useEffect, useState } from "react";
import {
  FormItemProps,
  FormItemType,
  renderFormItems,
  useZodForm,
} from "../../../common/Form";
import { DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";

import { z } from "zod";
import isEqual from "lodash.isequal";
import { VpnConnectionsAPI, VpnPCsAPI } from "../../Schema/api";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { AxiosInstance } from "axios";
import { AddEditDialogProps, BasicDialogProps } from "../../Schema/viewModels";
import React from "react";

type AddConnectionDialogProps = BasicDialogProps & {
  projects: MetadataProject[];
};

type EditConnectionDialogProps = AddConnectionDialogProps & {
  item: ConnectionEntryDetailed;
};

type AddEditConnectionDialogProps =
  AddEditDialogProps<ConnectionEntryDetailed> & {
    projects: MetadataProject[];
    axiosInstance: AxiosInstance;
  };

type FormData = {
  id?: string;
  projectId?: string;
  vpnPcId?: string;
  automationStatusKey: string;
  automationStatusComments?: string;
  connectionStatusKey: string;
  connectionStatusComments?: string;
  connectionDetails?: string;
};

const formSchema = z.object({
  automationStatusKey: z.string(),
  automationStatusComments: z.string().optional(),
  connectionStatusKey: z.string(),
  connectionStatusComments: z.string().optional(),
  connectionDetails: z.string().optional(),
});

/**
 * Gets the form props
 * @returns The form item props array.
 */
const getFormProps = (): FormItemProps[] => {
  let result: FormItemProps[] = [
    {
      name: "automationStatusKey",
      type: FormItemType.Dropdown,
      groupProps: { label: "Automation status *" },
      options: Object.keys(AutomationStatusType).map((key) => {
        return {
          key: key,
          text: AutomationStatusType[key as keyof typeof AutomationStatusType],
        };
      }),
      placeholder: "Select the automation status...",
      allowFreeform: false,
    },
    {
      name: "automationStatusComments",
      type: FormItemType.TextArea,
      groupProps: { label: "Automation status comments" },
      multiline: true,
      rows: 2,
      resizable: false,
    },
    {
      name: "connectionStatusKey",
      type: FormItemType.Dropdown,
      groupProps: { label: "Connection status *" },
      options: Object.keys(ConnectionStatusType).map((key) => {
        return {
          key: key,
          text: ConnectionStatusType[key as keyof typeof ConnectionStatusType],
        };
      }),
      placeholder: "Select the connection status...",
      allowFreeform: false,
    },
    {
      name: "connectionStatusComments",
      type: FormItemType.TextArea,
      groupProps: { label: "Connection status comments" },
      multiline: true,
      rows: 2,
      resizable: false,
    },
    {
      name: "connectionDetails",
      type: FormItemType.TextArea,
      groupProps: { label: "Connection details " },
      multiline: true,
      rows: 14,
      resizable: false,
    },
  ];

  return result;
};

/**
 * Gets the initial form values
 * @param item The VPN connection detailed entry.
 * @returns The form data default values
 */
const getInitialValues = (
  item: ConnectionEntryDetailed | undefined
): FormData => {
  let result: FormData = {
    automationStatusKey:
      item?.automationStatus.status ||
      Utils.getAutomationStatusKey(AutomationStatusType.Unknown),
    automationStatusComments: item?.automationStatus.comment || "",
    connectionStatusKey:
      item?.connectionStatus.status ||
      Utils.getConnectionStatusKey(ConnectionStatusType.Unknown),
    connectionStatusComments: item?.connectionStatus.comment || "",
    connectionDetails: item?.connectionDetails || "",
  };

  return result;
};

/**
 * Gets the VPN PC ID
 * @param vpnPCs the VPN PCs list
 * @param id the ID to compare
 * @returns the VPN PC Id if exists. Otherwise an empty string.
 */
const getVpnPcId = (vpnPCs: MetadataVpnPC[], id: string): string => {
  let vpnPC = vpnPCs.find((pc) => pc.id === id);
  return vpnPC?.id || "";
};

/**
 * Gets the Add VPN Connection Dialog component.
 * @param companies The reference companies list.
 * @param projects The reference projects list.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Add VPN Connection dialog component.
 */
export const AddConnectionDialog = ({
  projects,
  onClose,
}: AddConnectionDialogProps) => {
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Handlers
  const onSubmit = (formData: ConnectionEntryDetailed) => {
    if (!formData || !axiosInstance) {
      return;
    }

    setIsLoading(true);
    VpnConnectionsAPI.create(axiosInstance, formData).then((response) => {
      setIsLoading(false);
      if (response.status !== 201) {
        notification.error(
          `Failure adding a VPN connection entry: ${response.statusText}.`
        );
        return;
      }

      notification.success("Success adding a new VPN Connection entry.");
      onClose?.(true);
    });
  };

  return (
    <AddEditConnectionDialog
      axiosInstance={axiosInstance!}
      projects={projects}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

/**
 * Gets the Edit VPN Connection Dialog component.
 * @param item The VPN connection item to edit.
 * @param projects The reference projects list.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Edit VPN Connection dialog component.
 */
export const EditConnectionDialog = ({
  item,
  projects,
  onClose,
}: EditConnectionDialogProps) => {
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Handlers
  const onSubmit = (formData: ConnectionEntryDetailed) => {
    if (!formData || !axiosInstance) {
      return;
    }

    setIsLoading(true);
    VpnConnectionsAPI.update(axiosInstance, formData).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure updating a VPN connection entry: ${response.statusText}.`
        );
        return;
      }

      notification.success("Success updating a new VPN Connection entry.");
      onClose?.(true);
    });
  };

  return (
    <AddEditConnectionDialog
      axiosInstance={axiosInstance!}
      item={item}
      projects={projects}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

/**
 * Gets the Add-Edit VPN Connection dialog.
 * @param item The VPN connection item to edit.
 * @param axiosInstance The axios instance.
 * @param projects The reference projects list.
 * @param isLoading A value indicating whether the form is in loading state.
 * @param onSubmit The method called when the submit button is clicked.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns
 */
const AddEditConnectionDialog = ({
  item,
  axiosInstance,
  projects,
  isLoading,
  onSubmit,
  onClose,
}: AddEditConnectionDialogProps) => {
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("...");
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string>(
    item?.projectId || ""
  );
  const [vpnPCs, setVpnPCs] = useState<MetadataVpnPC[]>([]);
  const [selectedVpnPcId, setSelectedVpnPcId] = useState<string>("");
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema: formSchema,
    ...{
      defaultValues: getInitialValues(item),
    },
  });

  // Gets the VPN PCs list.
  useEffect(() => {
    if (!axiosInstance) {
      return;
    }

    VpnPCsAPI.list(axiosInstance).then((response) => {
      if (response.status !== 200) {
        notification.error(
          `Failure getting VPN PCs list: ${response.statusText}.`
        );
        return;
      }

      setVpnPCs(response.data);
    });
  }, []);

  // Gets the selected VPN PC.
  useEffect(() => {
    let vpnPcId = vpnPCs.find((pc) => pc.id === item?.vpnPcId)?.id || "";
    setSelectedVpnPcId(vpnPcId);
  }, [vpnPCs]);

  // Gets the company information.
  useEffect(() => {
    let project = projects.find((p) => p.id === selectedProjectId);
    if (!project) {
      return;
    }

    setSelectedCompanyName(project.company?.name || "");
  }, [projects, selectedProjectId]);

  // Checks whether the entity data has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    let areEqual =
      isEqual(control._defaultValues, control._formValues) &&
      item?.projectId === selectedProjectId &&
      item?.vpnPcId === selectedVpnPcId;
    setDataHasChanged(!areEqual);
  }, [watch()]);

  // Handlers
  const onSubmitHandler = (formData: FormData) => {
    // Builds the Vpn connection entry.
    let automationStatus: AutomationStatus = {
      status: formData.automationStatusKey as keyof typeof AutomationStatusType,
      comment: formData.automationStatusComments,
    };

    let connectionStatus: ConnectionStatus = {
      status: formData.connectionStatusKey as keyof typeof ConnectionStatusType,
      comment: formData.connectionStatusComments,
    };

    // Sets project name
    let projectName =
      projects.find((p) => p.id === selectedProjectId)?.name ||
      selectedProjectId.toString();

    let newItem: ConnectionEntryDetailed = {
      projectId: selectedProjectId,
      projectName: projectName,
      vpnPcId: selectedVpnPcId!,
      automationStatus: automationStatus,
      connectionStatus: connectionStatus,
      connectionDetails: formData.connectionDetails || "",
      emailDetails: "",
    };

    // Sets the Id and emails details for items to be edited.
    if (item) {
      newItem = { ...newItem, id: item.id, emailDetails: item.emailDetails };
    }

    onSubmit?.(newItem);
  };

  return (
    <FormDialog
      title={`${item ? "Edit" : "Add"} VPN Connection`}
      aria-modal="true"
      size={DialogSize.M}
      onClose={onClose}
    >
      <Stack verticalAlign="center">
        <FormItemSection title={"General Properties"}>
          <FormItemRow label={"Company"}>
            <p className="label-value-text" style={{ paddingBottom: "8px" }}>
              {selectedCompanyName}
            </p>
          </FormItemRow>
          <FormItemRow label={"Project *"}>
            <ControlledComboBox
              options={projects.map((p) => {
                return { key: p.id, text: p.name };
              })}
              initialKey={item?.projectId || ""}
              disabled={false}
              onKeySelected={(key) => setSelectedProjectId(key)}
            />
          </FormItemRow>
          <FormItemRow label={"VPN PC *"}>
            <ControlledComboBox
              options={vpnPCs.map((pc) => {
                return { key: pc.id!, text: pc.name };
              })}
              initialKey={item ? getVpnPcId(vpnPCs, item.vpnPcId!) : ""}
              disabled={false}
              onKeySelected={(key) => setSelectedVpnPcId(key)}
            />
          </FormItemRow>
        </FormItemSection>
        <FormItemSection title={"Connection Properties"}>
          {renderFormItems(getFormProps(), {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
        </FormItemSection>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          className="primary-button"
          disabled={
            !isValid ||
            !dataHasChanged ||
            isLoading ||
            selectedProjectId === "" ||
            selectedVpnPcId === ""
          }
          onClick={handleSubmit(onSubmitHandler)}
          text="Save Changes"
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton
          className="secondary-button"
          onClick={onClose}
          text="Cancel"
        />
      </DialogFooter>
    </FormDialog>
  );
};
