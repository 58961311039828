import type { IDatePickerProps } from '@fluentui/react';
import { DatePicker } from '@fluentui/react';
import { Controller } from 'react-hook-form';

import type { BaseFormFieldProps } from '../types';
import { FormGroup } from './FormGroup';

export type FormDatePickerProps = BaseFormFieldProps &
  IDatePickerProps & {
    name: string;
  };

export const FormDatePicker = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control, errors },
  ...rest
}: FormDatePickerProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value } }) => (
        <DatePicker
          textField={{
            name,
            errorMessage: errors?.[name]?.message,
          }}
          styles={{ root: { width: '100%' } }}
          value={value}
          onSelectDate={(date) => {
            onChange(date);
          }}
          onBlur={onBlur}
          {...rest}
          label={undefined}
        />
      )}
    />
  </FormGroup>
);
