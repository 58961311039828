import {
  DialogType,
  IStackTokens,
  MessageBar,
  MessageBarType,
  Stack,
} from "@fluentui/react";
import FormDialog from "../Generic/FormDialog";

import { DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";

import { useState } from "react";
import { TreeNode, UserNotificationSettingsType } from "./models";
import { UserNotificationState } from "./EditUserNotificationPage";
import FormItemRow from "../Generic/FormItemRow";
import { Text } from "@fluentui/react/lib/Text";
import { buildUserNotificationSettings } from "./utils";
import { UserNotificationsAPI } from "./api";
import { paddingStyle, subtitleStyle, textFieldStyle } from "./globalStyles";

type UserNotificationsConfirmDialogProps = {
  userId: string;
  emailsState: UserNotificationState;
  teamsState: UserNotificationState;
  onClose: () => void;
};

type DialogSectionProps = {
  title: string;
  state: UserNotificationState;
};

/**
 * Gets the entities list as string.
 * @param entitiesList The entities map.
 * @returns The entities as string, separated by commas.
 */
const getEntitiesString = (
  entitiesList: Map<string, TreeNode<boolean>>
): string => {
  let result: string = "";

  let selectedEntities: string[] = Array.from(entitiesList.values())
    .filter((e) => e.value === true)
    ?.map((e) => e.name);

  while (selectedEntities?.length > 0) {
    let entityName = selectedEntities.pop();
    result +=
      selectedEntities.length === 0 ? `${entityName}.` : `${entityName}, `;
  }

  return result;
};

/**
 * Gets a color string, depending on the user notification settings type.
 * @param settingsType The user notification settings type.
 * @returns The string, indicating a CSS color.
 */
const getStateColorString = (
  settingsType: UserNotificationSettingsType
): string => {
  switch (settingsType) {
    case UserNotificationSettingsType.Disabled:
      return "rgb(246, 63, 77)";
    case UserNotificationSettingsType.Enabled:
      return "rgb(102, 205, 125)";
    case UserNotificationSettingsType.Selected:
      return "rgb(255, 126, 13)";
  }
  return "rgb(50, 49, 48)";
};

/**
 * Gets a dialog section component.
 * @param title The dialog title.
 * @param state The user notification state.
 * @returns The dialog section component.
 */
const DialogSection = ({ title, state }: DialogSectionProps) => {
  const stackTokens: IStackTokens = {
    padding: 8,
  };
  const settingsType = UserNotificationSettingsType[state.key];

  return (
    <Stack>
      <Stack.Item>
        <h3 style={subtitleStyle}>{title}</h3>
      </Stack.Item>
      <Stack.Item style={paddingStyle}>
        <FormItemRow label="State:">
          <Text
            variant="medium"
            style={{
              fontWeight: "600",
              color: getStateColorString(settingsType),
            }}
          >
            {settingsType}
          </Text>
        </FormItemRow>
      </Stack.Item>

      {settingsType === UserNotificationSettingsType.Selected && (
        <Stack.Item style={paddingStyle}>
          <Stack>
            <FormItemRow label="Corporations:">
              <Stack
                tokens={stackTokens}
                style={textFieldStyle}
                verticalAlign="center"
              >
                <Text variant="smallPlus">
                  {getEntitiesString(state.tree.corporations)}
                </Text>
              </Stack>
            </FormItemRow>
            <FormItemRow label="Companies:">
              <Stack tokens={stackTokens} style={textFieldStyle}>
                <Text variant="smallPlus">
                  {getEntitiesString(state.tree.getCompanies())}
                </Text>
              </Stack>
            </FormItemRow>
            <FormItemRow label="Projects:">
              <Stack tokens={stackTokens} style={textFieldStyle}>
                <Text variant="smallPlus">
                  {getEntitiesString(state.tree.getProjects())}
                </Text>
              </Stack>
            </FormItemRow>
            <FormItemRow label="Machines:">
              <Stack tokens={stackTokens} style={textFieldStyle}>
                <Text variant="smallPlus">
                  {getEntitiesString(state.tree.getMachines())}
                </Text>
              </Stack>
            </FormItemRow>
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

/**
 * Gets the user notifications confirm dialog.
 * @param userId The logged user ID
 * @param emailsState The emails notification settings state.
 * @param teamsState The Microsoft Teams notification settings state.
 * @param onClose Method called when this dialog needs to be closed.
 * @returns The user notifications confirm dialog.
 */
const UserNotificationsConfirmDialog = ({
  userId,
  emailsState,
  teamsState,
  onClose,
}: UserNotificationsConfirmDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Handlers
  const onSubmit = () => {
    if (!emailsState.key || !teamsState.key) {
      return;
    }

    setIsLoading(true);
    let settings = buildUserNotificationSettings(emailsState, teamsState);
    UserNotificationsAPI.updateUserNotificationSettings(userId, settings).then(
      (response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          notification.error("Failure: Updating user notifications settings.");
          return;
        }

        notification.success("Success: Updating user notifications settings.");
        onClose?.();
      }
    );
  };

  return (
    <FormDialog
      title={"Confirm Settings"}
      isLoading={isLoading}
      isValid={true}
      type={DialogType.normal}
      size={DialogSize.M}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Stack tokens={{ childrenGap: 10 }}>
        <MessageBar messageBarType={MessageBarType.info} isMultiline>
          Please check and confirm whether these are the settings you want to
          submit.
        </MessageBar>
        <DialogSection title={"Notifications by Email"} state={emailsState} />
        <DialogSection
          title={"Notifications by Microsoft Teams"}
          state={teamsState}
        />
      </Stack>
    </FormDialog>
  );
};

export default UserNotificationsConfirmDialog;
