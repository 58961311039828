import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../../../../modules/core/apiClient/useApiStore';
import { getArrBatches } from './methods';

type statisticsArgs = {
  trendOrFastTrendIds: string[];
  periodStart?: string;
  periodEnd?: string;
  dateTime1: string;
  dateTime2: string;
  options?: object;
};

export const useStatistics = (args: statisticsArgs) => {
  const {
    trendOrFastTrendIds = [],
    periodStart,
    periodEnd,
    dateTime1,
    dateTime2,
    options = {},
  } = args;

  const {
    data = [],
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery(
    [
      'performance-statistics',
      ...trendOrFastTrendIds,
      periodStart,
      periodEnd,
      dateTime1,
      dateTime2,
    ],
    () => {
      const batchesIds = getArrBatches(trendOrFastTrendIds, 10);
      const promises: any = [];

      batchesIds.forEach((batchIds) => {
        promises.push(
          getApiClient().post('/data/read/v1/trends/statistics', {
            trendOrFastTrendIds: batchIds,
            periodStart,
            periodEnd,
            dateTime1,
            dateTime2,
          }),
        );
      });

      return Promise.all(promises)
        .then((res) => {
          let result: any = [];
          res.forEach((resItem) => {
            result = [...result, ...resItem.data];
          });
          return result;
        })
        .catch(() => []);
    },
    { ...options },
  );

  return {
    data,
    isLoading: isLoading && isFetching,
    isError,
    refetch,
  };
};
