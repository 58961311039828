import type * as am5 from '@amcharts/amcharts5';
import { Color, p50, Rectangle, RoundedRectangle } from '@amcharts/amcharts5';
import type * as am5xy from '@amcharts/amcharts5/xy';
import { AxisBullet } from '@amcharts/amcharts5/xy';

type SidebandOpts = {
  root: am5.Root;
  chart: am5xy.XYChart;
  xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>;
  value: number;
  color: string;
  label: string;
};

export const sideband = ({ root, chart, xAxis, value, color, label }: SidebandOpts) => {
  const range = xAxis.createAxisRange(
    xAxis.makeDataItem({
      above: true,
    }),
  );

  const rangeLabel = range.get('label');

  rangeLabel?.setAll({
    ariaLabel: label,
    text: value.toFixed(2).toString(),
    fill: Color.fromString(color),
    fontSize: 10,
    layer: 1,
    rotation: -90,
    inside: true,
    dx: -8,
    location: 0.5,
    background: Rectangle.new(root, {
      fill: Color.fromString('#ffffff'),
      height: 5,
    }),
  });

  rangeLabel?.adapters.add('y', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return -1 * chart.plotContainer.getPrivate('height') + 15;
  });

  range.set('value', value);

  range.get('grid')?.setAll({
    strokeOpacity: 1,
    stroke: Color.fromString(color),
    strokeWidth: 1,
    strokeDasharray: 5,
    opacity: 0.5,
    layer: 1,
    location: 1,
  });

  // AxisBullet with label at the top of the chart

  const sidebandButton = RoundedRectangle.new(root, {
    fill: Color.fromString(color),
    stroke: Color.fromString(color),
    opacity: 0.8,
    width: 4,
    height: 4,
    centerY: p50,
    centerX: p50,
    cornerRadiusBL: 2,
    cornerRadiusBR: 2,
    cornerRadiusTL: 2,
    cornerRadiusTR: 2,
    dx: 0,
    dy: -20,
    x: p50,
    layer: 1,
  });

  sidebandButton.toFront();

  sidebandButton.adapters.add('y', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return -1 * chart.plotContainer.getPrivate('height');
  });

  range.set(
    'bullet',
    AxisBullet.new(root, {
      sprite: sidebandButton,
    }),
  );
};
