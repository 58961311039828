import * as machineCV from './MachineCVApi';
import * as MachineCVBreadcrumb from './MachineCVBreadcrumb';
import * as measuredDataDownsampled from './MeasuredDataDownsampled';
import * as measuredDataRead from './MeasuredDataReadApi';
import * as metaDataRead from './MetaDataReadApi';
import * as powerBi from './PowerBiApi';

const apiService = {
  metaDataRead,
  measuredDataRead,
  measuredDataDownsampled,
  machineCV,
  powerBi,
  MachineCVBreadcrumb,
};

export default apiService;
