/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect } from "react";
import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../common/Form";
import { notification } from "../common/Notification";
import BaseDialog from "../common/Dialog";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  Text,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { RequestWirelessGatewayUpdate, WirelessGateway } from "./models";
import { editWirelessGateway } from "./api";
import { maxLengthType1 } from "../../schema/Constants";
import FormItemRow from "../Generic/FormItemRow";
import ControlledComboBox from "../Generic/ControlledComboBox";
import { areObjectsEqual } from "../../schema/Utils";
import { useProjects } from "../../Hooks";
import { ResponseProjectDetails } from "../Projects/models";
import { maxRsshPort, minRsshPort } from "./AddDialog";

const getSchema = (serialNumbers: string[]) =>
  z
    .object({
      id: z.string().optional(),
      serialNumber: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Serial Number must contain at most ${maxLengthType1} character(s)`,
        })
        .refine(
          (val) =>
            serialNumbers
              .map((serialN) => serialN.trim().toLowerCase())
              .findIndex((value) => value === val.trim().toLowerCase()) === -1,
          {
            message: "The serial number already exists",
          }
        ),
      projectId: z.string().optional(),
      ltePlanSize: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType1} character(s)`,
        }),
      publicKey: z.string().optional(),
      rsshPort: z.string().optional(),
      wifiPasswordForAP: z.string().optional(),
      wifiSSID: z.string().optional(),
      iMEI: z.string().optional(),
      wirepassSinkNodeAddress: z.string().optional(),
      wirepassChannel: z.string().optional(),
      ethernetMacAddress: z.string().optional(),
      firmware: z.string().optional(),
      active: z.boolean().optional(),
      installationLocation: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: "Try a number between (1.0:1000.0)",
      }
    )
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.rsshPort);
      },
      {
        path: ["rsshPort"],
        message: `Try a number between (${minRsshPort}:${maxRsshPort})`,
      }
    )
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        const rsshPortNumber = Number(input.rsshPort);
        return (
          !isNaN(rsshPortNumber) &&
          rsshPortNumber >= minRsshPort &&
          rsshPortNumber <= maxRsshPort
        );
      },
      {
        path: ["rsshPort"],
        message: `Try a number between (${minRsshPort}:${maxRsshPort})`,
      }
    );

const gatewayFields: FormItemProps[] = [
  {
    name: "serialNumber",
    type: FormItemType.TextField,
    groupProps: { label: "Serial Number *" },
    disabled: true,
  },
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: "Lte Plan Size *" },
  },
  {
    name: "publicKey",
    type: FormItemType.TextField,
    groupProps: { label: "Public Key" },
  },
  {
    name: "rsshPort",
    type: FormItemType.TextField,
    groupProps: { label: "Rssh Port" },
  },
  {
    name: "wifiPasswordForAP",
    type: FormItemType.TextField,
    groupProps: { label: "Wifi Password For AP" },
  },
  {
    name: "wifiSSID",
    type: FormItemType.TextField,
    groupProps: { label: "Wifi SSID" },
  },
  {
    name: "iMEI",
    type: FormItemType.TextField,
    groupProps: { label: "IMEI" },
  },
  {
    name: "wirepassSinkNodeAddress",
    type: FormItemType.TextField,
    groupProps: { label: "Wirepass Sink Node Editress" },
  },
  {
    name: "wirepassChannel",
    type: FormItemType.TextField,
    groupProps: { label: "Wirepass Channel" },
  },
  {
    name: "ethernetMacAddress",
    type: FormItemType.TextField,
    groupProps: { label: "Ethernet Mac Editress" },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: "Firmware" },
  },
  {
    name: "active",
    type: FormItemType.Checkbox,
    groupProps: { label: "Active" },
  },
  {
    name: "installationLocation",
    type: FormItemType.TextField,
    groupProps: { label: "Installation Location" },
  },
];

type EditDialogProps = IDialogProps & {
  data: WirelessGateway;
  serialNumbers: string[];
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const EditDialog = ({
  data,
  serialNumbers,
  show,
  onSuccess,
  onClose,
  ...rest
}: EditDialogProps) => {
  const { projects } = useProjects();
  const schema = useMemo(
    () => getSchema(serialNumbers.filter((item) => item !== data.serialNumber)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(
    data === null || data === undefined
  );
  const [isLoading, setLoading] = useState(false);
  const [project, setProject] = useState<ResponseProjectDetails | undefined>(
    undefined
  );
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
  });

  // Sets the initial project state value
  useEffect(() => {
    if (projects.size === 0 || !data) {
      return;
    }

    setProject(projects.get(data?.projectId));
  }, [projects.size]);

  useEffect(() => {
    data &&
      reset({
        serialNumber: data.serialNumber,
        id: data.id,
        ltePlanSize: data.ltePlanSize ? String(data.ltePlanSize) : "",
        publicKey: data.publicKey ? data.publicKey : "",
        rsshPort: data.rsshPort ? String(data.rsshPort) : "",
        wifiPasswordForAP: data.wifiPasswordForAP ? data.wifiPasswordForAP : "",
        wifiSSID: data.wifiSSID ? data.wifiSSID : "",
        iMEI: data.iMEI ? data.iMEI : "",
        wirepassSinkNodeAddress: data.wirepassSinkNodeAddress
          ? data.wirepassSinkNodeAddress
          : "",
        wirepassChannel: data.wirepassChannel ? data.wirepassChannel : "",
        ethernetMacAddress: data.ethernetMacAddress
          ? data.ethernetMacAddress
          : "",
        firmware: data.firmware ? data.firmware : "",
        active: data.active ? data.active : false,
        installationLocation: data.installationLocation
          ? data.installationLocation
          : "",
      });
  }, [data, reset]);

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    let areEqual =
      areObjectsEqual(control._defaultValues, control._formValues) &&
      data?.projectId === project?.id;
    setDataHasChanged(!areEqual);
  }, [watch(), project]);

  // Handlers.
  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);
    !project && notification.warning("Un-parented gateway");
    const toSend: RequestWirelessGatewayUpdate = {
      serialNumber: formData.serialNumber,
      id: formData.id,
      projectId: project.id,
      ltePlanSize: Number(formData.ltePlanSize) as number,
      rsshPort: Number(formData.rsshPort) as number,
      publicKey: formData.publicKey,
      wifiPasswordForAP: formData.wifiPasswordForAP,
      wifiSSID: formData.wifiSSID,
      iMEI: formData.iMEI,
      wirepassSinkNodeAddress: formData.wirepassSinkNodeAddress,
      wirepassChannel: formData.wirepassChannel,
      ethernetMacAddress: formData.ethernetMacAddress,
      firmware: formData.firmware,
      active: formData.active,
      installationLocation: formData.installationLocation,
    };
    await editWirelessGateway(toSend).then((response) =>
      onSuccess("status" in response, response.serialNumber)
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Edit new Gateway",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
    >
      {project && (
        <form onSubmit={onSubmit}>
          <FormItemRow label={"Company"} style={{ marginBottom: "0.75em" }}>
            <Text as="p" variant="medium" style={{ fontWeight: 600 }}>
              {project?.company?.name}
            </Text>
          </FormItemRow>
          <FormItemRow label="Project *">
            <ControlledComboBox
              options={Array.from(projects.values()).map((project) => {
                return { key: project.id, text: project.name };
              })}
              selectedKey={project.id}
              disabled={false}
              onKeySelected={(key: string) => setProject(projects.get(key))}
            />
          </FormItemRow>
          {renderFormItems(gatewayFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogFooter>
            <PrimaryButton
              type="submit"
              text="Save Changes"
              disabled={isLoading || !isValid || !project || !dataHasChanged}
              onRenderIcon={() =>
                isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
              }
            />
            <DefaultButton
              styles={{
                root: { border: "unset", background: "transparent" },
              }}
              text="Cancel"
              onClick={handleClose}
            />
          </DialogFooter>
        </form>
      )}
    </BaseDialog>
  );
};
