import { useQuery } from "@tanstack/react-query";

import { usePowerBi } from "../../../../Hooks";
import { apiService } from "../../../../modules/common";
import type { Corporation, PowerBiEmbed } from "../../../../types";

type CorporationData = {
  corporation: Corporation;
  isLoading: boolean;
  isError: boolean;
  dashboard: {
    embed?: PowerBiEmbed;
    isLoading: boolean;
    isError: boolean;
  };
};

type CorporationDataProps = {
  id: string;
};

export const useCorporationData = ({
  id,
}: CorporationDataProps): CorporationData => {
  const {
    data = {} as Corporation,
    isLoading,
    isError,
  } = useQuery(
    ["corporation-data", id],
    () => apiService.metaDataRead.getCorporation(id),
    {
      cacheTime: 0,
    }
  );

  const dashboardResponse = usePowerBi({
    id,
    type: "Corporation",
    options: { cacheTime: 0, retry: 0 },
  });

  return {
    corporation: data,
    isLoading,
    isError,
    dashboard: {
      embed: dashboardResponse.data,
      isLoading: dashboardResponse.isLoading,
      isError: dashboardResponse.isError,
    },
  };
};
