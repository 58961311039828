const escape = /(:|\.|\[|\]|,|=|@)/g;

export type GetProjectElementOpts = {
  corporation: string;
  company: string;
  project: string;
};

export const getProjectElementId = ({ corporation, company, project }: GetProjectElementOpts) =>
  `project-${corporation}-${company}-${project}`.replace(escape, '\\$1');
