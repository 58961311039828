import type { AxiosInstance } from "axios";
import React from "react";
import * as ReactRouterDOM from "react-router-dom";

import { useLocationSearch } from "../../../Hooks";
import AppLayout from "../../../templates/AppLayout";

import Page from "./Page";

interface InfoProps {
  axiosInstance: AxiosInstance;
}

const MachineCVInfo: React.FC<InfoProps> = ({ axiosInstance }) => {
  const [, , isValid] = useLocationSearch();

  if (!isValid) {
    return <ReactRouterDOM.Navigate replace to="/error" />;
  }

  return (
    <AppLayout axiosInstance={axiosInstance}>
      <Page />
    </AppLayout>
  );
};

export default MachineCVInfo;
