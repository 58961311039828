import Table, { Column } from "../../common/Table";
import { ImagesStatistics } from "../models";
import { WithLoadingPanelHOC } from "../../Generic/HOCs";

const tableColumns: Column[] = [
  {
    key: "average",
    name: "Average",
    fieldName: "average",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "median",
    name: "Median",
    fieldName: "median",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "minimum",
    name: "Minimum",
    fieldName: "minimum",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "maximum",
    name: "Maximum",
    fieldName: "maximum",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "count",
    name: "Count",
    fieldName: "count",
    minWidth: 100,
    isSortable: true,
  },
];

type ImagesStatisticsTableProps = {
  tableItems: ImagesStatistics[];
  isLoading: boolean;
};

/**
 * Gets the images statistics table component.
 * @param tableItems The table items.
 * @param isLoading Value determining whether the table is in loading state.
 * @returns The images statistics table component.
 */
const ImagesStatisticsTable = ({
  tableItems,
  isLoading,
}: ImagesStatisticsTableProps) => {
  return WithLoadingPanelHOC(
    !isLoading,
    <Table
      items={tableItems}
      columns={tableColumns}
      header={{ title: "Images statistics" }}
      hasSelection={false}
      persistOpts={{
        key: "images-statistics-table",
        version: 2,
      }}
    />
  );
};

export default ImagesStatisticsTable;
