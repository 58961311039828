import type { AxiosInstance } from "axios";

import AppLayout from "../../../templates/AppLayout";

import TrendViewPage from "./TrendViewPage";

interface TrendViewProps {
  marginTop?: number;
  axiosInstance: AxiosInstance;
}

const TrendView = ({ marginTop = 0, axiosInstance }: TrendViewProps) => {
  return (
    <AppLayout axiosInstance={axiosInstance}>
      <TrendViewPage marginTop={marginTop} />
    </AppLayout>
  );
};

export default TrendView;
