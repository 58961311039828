/* eslint-disable jsx-a11y/click-events-have-key-events */

import { format } from "../../../../utils";
import type { Column } from "../../../common/Table/Table";

export const getColumns = (): Column[] => [
  {
    key: "nodeId",
    fieldName: "nodeId",
    name: "Sensor Serial",
    isSortable: true,
    minWidth: 200,
  },
  // {
  //   key: 'sensorDalogName',
  //   fieldName: 'sensorDalogName',
  //   name: 'Sensor DALOG Name',
  //   minWidth: 100,
  //   isSortable: true,
  // },
  // {
  //   key: 'gatewayName',
  //   fieldName: 'gatewayName',
  //   name: 'Gateway Name',
  //   minWidth: 100,
  //   isSortable: true,
  // },
  {
    key: "status",
    fieldName: "status",
    name: "Status",
    minWidth: 200,
    isSortable: true,
    // isExportable: false,
  },
  {
    key: "lastUpdate",
    fieldName: "lastUpdate",
    name: "Last Update",
    minWidth: 200,
    isSortable: true,
    onRender: (item) => format(new Date(item.lastUpdate)),
  },
  {
    key: "errors",
    fieldName: "errors",
    name: "Comments",
    flexGrow: 2,
    calculatedWidth: 0,
    minWidth: 200,
  },
];
