import { Icon, Text, TooltipHost } from "@fluentui/react";

import BaseCommandBar, {
  computeCommandBarItems,
  CommandBarItemType,
  CommandBarItemProps,
} from "../common/CommandBar";
import { notification } from "../common/Notification";
import Table, { Column, useTableFilters } from "../common/Table";

import { useEffect, useState } from "react";
import { Link as LinkA } from "react-router-dom";
import { useViewport } from "../Layout/Content";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { SubmissionForList } from "./models";
import { format } from "../../schema/Utils";
import { LogSubmissionsList } from "./LogSubmissionsList";
import { removeSubmissions, submissionsList } from "./api";
import DeleteDialog from "../Generic/DeleteDialog";

// --- Columns ---
type GetColumnsOpts = {
  onDelete: (submission: SubmissionForList) => void;
};

const getColumns = ({ onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "sID",
      name: "SID",
      fieldName: "sID",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, sID }: SubmissionForList) => (
        <LinkA to={id} style={linkStyle}>
          {sID}
        </LinkA>
      ),
    },
    {
      key: "createdAt",
      name: "Created At",
      fieldName: "createdAt",
      minWidth: 200,
      isSortable: true,
      onRender: (item: SubmissionForList) => format(new Date(item.createdAt)),
    },
    {
      key: "createdBy",
      name: "Created By",
      fieldName: "createdBy",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (item: SubmissionForList) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(item)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    },
  ];

  return columns;
};

export const SubmissionsList = () => {
  const [isLoading, setLoading] = useState(false);
  const { bigScreen } = useViewport();
  const [items, setItems] = useState<SubmissionForList[]>();
  const { filters, handleSearch } = useTableFilters<SubmissionForList>({
    keys: ["sID", "createdBy"],
  });
  const [showLogs, setShowLogs] = useState(false);

  const [selected, setSelected] = useState<
    | {
        data: SubmissionForList;
        context: "delete";
      }
    | undefined
  >();

  useEffect(() => {
    setLoading(true);
    submissionsList()
      .then((list) => {
        setItems(list);
        setLoading(false);
      })
      .catch((event) => {
        setItems([]);
        notification.error(event);
      });

    return;
  }, []);

  const getCommandBarItems = (title: string): CommandBarItemProps[] => {
    const commandBarItems: CommandBarItemProps[] = [
      {
        key: "title",
        type: CommandBarItemType.Custom,
        onRender: () => <Text style={titleStyle}>{title}</Text>,
      },
      {
        key: "log",
        text: "Log",
        type: CommandBarItemType.Button,
        iconProps: { iconName: "ClipboardList" },
        onClick: () => setShowLogs(true),
      },
    ];

    return commandBarItems;
  };

  const onDelete = (submission: SubmissionForList) =>
    setSelected({ data: submission, context: "delete" });

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(getCommandBarItems("Submissions"))}
        onSearch={handleSearch}
        styles={commandBarStyles}
      />

      <Table
        persistOpts={{
          key: "table-submissions",
          version: 2,
        }}
        header={{
          title: "Submissions",
        }}
        items={items}
        columns={getColumns({
          onDelete: onDelete,
        })}
        filters={filters}
        hasSelection={false}
        isLoading={isLoading}
        isError={false}
        perPage={bigScreen ? 20 : 10}
      />

      <LogSubmissionsList onClose={() => setShowLogs(false)} show={showLogs} />

      {selected?.data && (
        <DeleteDialog
          id={selected.data.id}
          identifier={"Submission Entry"}
          propertyName={"sID"}
          referenceString={selected.data.sID}
          isLoading={isLoading}
          onDelete={() => {
            removeSubmissions(selected.data.id).then((resp) => {
              setSelected(undefined);
              if ("data" in resp) {
                notification.success("Submission deleted");
                setLoading(true);
                submissionsList()
                  .then((list) => {
                    setItems(list);
                    setLoading(false);
                  })
                  .catch((event) => {
                    setItems([]);
                    notification.error(event);
                  });
              } else notification.error("Issue deleting submission.");
            });
          }}
          onClose={() => setSelected(undefined)}
        />
      )}
    </div>
  );
};
