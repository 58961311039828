import type { CSSProperties } from "react";
import { useMemo } from "react";
import * as ReactRouterDOM from "react-router-dom";

import { BookmarkType } from "../../../../../modules/common/components/Bookmarks";
import { NoData } from "../../../../common/NoData";
import { PowerBi } from "../../../../common/PowerBi";
import { Spin } from "../../../../common/Spin";

import { useCorporationData } from "../../hooks/useCorporationData";
import { DashboardHeader } from "../DashboardHeader";

const styles: Record<string, CSSProperties> = {
  wrapper: {
    padding: 32,
  },
  header: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 16,
  },
  powerBiContainer: {
    background: "#fff",
    marginTop: 48,
    marginBottom: 48,
  },
};

export const Corporation = () => {
  const { id } = ReactRouterDOM.useParams();

  const { corporation, dashboard, isLoading, isError } = useCorporationData({
    id: id as string,
  });

  const config = useMemo(() => {
    const { isLoading, isError, embed } = dashboard;

    if (isLoading || isError || !embed) {
      return undefined;
    }

    return {
      id: embed.id,
      url: embed.embedUrl,
      token: embed.embedToken,
    };
  }, [dashboard]);

  if (isLoading) {
    return <Spin style={{ height: "calc(100vh - 50px)" }} />;
  }

  if (isError) {
    return <NoData style={{ height: "calc(100vh - 50px)" }} />;
  }

  return (
    <div style={styles.wrapper}>
      <div style={styles.header}>
        <DashboardHeader
          data={{ ...corporation, type: BookmarkType.Corporation }}
          title={corporation.name}
          subtitle="View all the projects assigned to this dashboard. Check the myriad of machines and browse them using the interactive map or the table list."
        />
      </div>
      <div style={styles.powerBiContainer}>
        <PowerBi
          isLoading={dashboard.isLoading}
          isError={dashboard.isError}
          config={config}
        />
      </div>
    </div>
  );
};
