interface WaterfallHarmonics {
  harmonicsNumber: number;
  harmonicsFreq: number;
  sidebandsNumber: number;
  sidebandsFreq: number;
  color: string;
}

export const createWaterfallHarmonics = ({
  harmonicsNumber,
  harmonicsFreq,
  sidebandsNumber,
  sidebandsFreq,
  color,
}: WaterfallHarmonics) => {
  const shapes = [];

  if (!harmonicsFreq) return [];

  for (let i = 0; i < harmonicsNumber; i++) {
    const harmonicFrequency = (i + 1) * harmonicsFreq;

    shapes.push({
      type: 'line',
      xref: 'x',
      yref: 'paper',
      x0: harmonicFrequency,
      y0: 0,
      x1: harmonicFrequency,
      y1: 1,
      line: {
        color,
        width: 1,
      },
      label: {
        textposition: 'start',
        texttemplate: '%{x0:}',
        textangle: 0,
        yanchor: 'middle',
        font: {
          size: 10,
          color,
        },
      },
    });

    if (sidebandsNumber && sidebandsFreq) {
      for (let j = 0; j < sidebandsNumber; j++) {
        const leftSideband = (harmonicFrequency - (j + 1) * sidebandsFreq).toFixed(2);
        const rightSideband = (harmonicFrequency + (j + 1) * sidebandsFreq).toFixed(2);

        shapes.push({
          type: 'line',
          xref: 'x',
          yref: 'paper',
          y0: 0,
          y1: 1,
          line: {
            color,
            dash: 'dot',
            width: 1,
          },
          label: {
            textposition: 'end',
            texttemplate: '%{x0:}',
            font: {
              size: 10,
              color,
            },
          },
          x0: leftSideband,
          x1: leftSideband,
        });

        shapes.push({
          type: 'line',
          xref: 'x',
          yref: 'paper',
          y0: 0,
          y1: 1,
          line: {
            color,
            dash: 'dot',
            width: 1,
          },
          label: {
            textposition: 'end',
            texttemplate: '%{x0:}',
            font: {
              size: 10,
              color,
            },
          },
          x0: rightSideband,
          x1: rightSideband,
        });
      }
    }
  }

  return shapes;
};

export function selectValuesWithEqualDistance(arr: string[], numValues: number) {
  if (arr.length <= numValues) {
    // If the array has fewer or equal values than requested, return the original array.
    return arr;
  }
  const stepSize = (arr.length - 1) / (numValues - 1);
  const selectedValues = [];
  for (let i = 0; i < numValues; i++) {
    const index = Math.round(i * stepSize);
    selectedValues.push(arr[index]);
  }
  return selectedValues;
}
