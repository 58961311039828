// Types and Interfaces
import type { ICommandBarStyles } from "@fluentui/react";
import { Icon, Text, TooltipHost } from "@fluentui/react";
import type { CSSProperties } from "react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import { notification } from "../../../../common/Notification";
import type { CommandBarItemProps } from "../../../../common/CommandBar";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../common/CommandBar";
import Table, { useTableFilters } from "../../../../common/Table";

import type {
  CreateProjectTriggerRequest,
  ProgrammedTrigger,
} from "../../Sensor/types";
import {
  useAddProjectTrigger,
  useListProjectTriggers,
} from "../hooks/useListProjectTriggers ";
import { TriggerAddDialog } from "./TriggerAddDialog";

type Column = {
  key: string;
  name: string;
  fieldName: string;
  minWidth: number;
  isSortable: boolean;
  isExportable?: boolean;
  onRender?: (item: ProgrammedTrigger) => React.ReactNode;
};

type GetColumnsOpts = {
  hasActions: boolean;
  onDelete: (trigger: ProgrammedTrigger) => void;
};

const titleStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  borderRight: "1px solid #E1DFDD",
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

const commandBarStyles: ICommandBarStyles = {
  root: {
    padding: 0,
    marginBottom: 26,
    background: "transparent",
  },
};

const iconStyle: CSSProperties = {
  fontSize: 16,
  color: "#2c529f",
  margin: "0 5px",
  cursor: "pointer",
};

const getColumns = ({ hasActions, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "frequency",
      name: "Frequency",
      fieldName: "frequency",
      minWidth: 150,
      isSortable: true,
      onRender: (trigger: ProgrammedTrigger) => (
        <span>{trigger.frequency.replace(/([A-Z])/g, " $1").trim()}</span>
      ),
    },

    {
      key: "time",
      name: "Time",
      fieldName: "time",
      minWidth: 100,
      isSortable: false,
      onRender: (trigger: ProgrammedTrigger) => (
        <span>{`${trigger.hour.toString().padStart(2, "0")}:${trigger.minute
          .toString()
          .padStart(2, "0")}`}</span>
      ),
    },
    {
      key: "measurementSets",
      name: "Measurement Sets",
      fieldName: "measurementSets",
      minWidth: 200,
      isSortable: false,
      onRender: (trigger: ProgrammedTrigger) => (
        <span>{trigger.measurementSets.join(", ")}</span>
      ),
    },
    {
      key: "delay",
      name: "Delay (min)",
      fieldName: "delayPerSensorInMinutes",
      minWidth: 100,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (trigger: ProgrammedTrigger) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              style={iconStyle}
              onClick={() => onDelete(trigger)}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

export const TriggersList: React.FC = () => {
  const { projectId }: any = useParams();
  const { triggers, isLoading, error, refetchTriggers } =
    useListProjectTriggers(projectId);
  const { addTriggerAsync } = useAddProjectTrigger();

  const [selected, setSelected] = useState<{
    data: ProgrammedTrigger | undefined;
    context: "add" | "delete";
  }>();

  const onAdd = () => setSelected({ data: undefined, context: "add" });

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>Triggers</Text>,
    },
    {
      key: "add",
      text: "Add",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    },
  ];

  const { filters, handleSearch } = useTableFilters<ProgrammedTrigger>({
    keys: ["name"],
  });

  const onDelete = (trigger: ProgrammedTrigger) => {
    setSelected({ data: trigger, context: "delete" });
  };

  const handleAddSuccess = async (data: CreateProjectTriggerRequest) => {
    try {
      await addTriggerAsync(data);
      refetchTriggers();
      setSelected(undefined);
    } catch (error: any) {
      notification.error(error.response.data);
      console.error("Error adding trigger:", error);
    }
  };
  return (
    <div className="p-4">
      <BaseCommandBar
        items={computeCommandBarItems(commandBarItems)}
        styles={commandBarStyles}
        onSearch={handleSearch}
      />
      <div className="border rounded-lg">
        <Table
          persistOpts={{
            key: "table-triggers",
            version: 2,
          }}
          header={{
            title: "Triggers",
          }}
          columns={getColumns({
            hasActions: true,
            onDelete,
          })}
          items={triggers}
          filters={filters}
          hasSelection={false}
          isLoading={isLoading}
          isError={!!error}
        />
      </div>
      <DeleteDialog
        dialogContentProps={{
          onDismiss: () => setSelected(undefined),
        }}
        data={{
          ids: selected?.data ? [selected.data.id] : [],
          name: selected?.data?.name || "Trigger",
        }}
        hidden={selected?.context !== "delete"}
        route={
          "dws/v1/triggers/programmed/projects/" + projectId + "/triggers/"
        }
        onSuccess={() => {
          refetchTriggers();
          setSelected(undefined);
        }}
      />

      <TriggerAddDialog
        projectId={projectId}
        hidden={selected?.context !== "add"}
        onDismiss={() => {
          setSelected(undefined);
        }}
        onSuccess={handleAddSuccess}
      />
    </div>
  );
};

export default TriggersList;
