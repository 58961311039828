import { Fragment } from "react";

import { useSelectedSignalsStore } from "../../../../modules/analysis-raw-data";
import WaterfallChartSection from "../../../../modules/analysis-raw-data/components/WaterfallChartSection";

const WaterfallLayout = () => {
  const selectedSignals = useSelectedSignalsStore(
    (store) => store.selectedSignals
  );

  return (
    <>
      {selectedSignals.map((selectedSignal) => (
        <div key={selectedSignal.id}>
          <Fragment key={`${selectedSignal.id}`}>
            <WaterfallChartSection signal={selectedSignal} />
          </Fragment>
        </div>
      ))}
    </>
  );
};

export default WaterfallLayout;
