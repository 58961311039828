import { useEffect } from "react";

import MaximizeIcon from "../assets/svg/MaximizeIcon";
import MinimizeIcon from "../assets/svg/MinimizeIcon";
import useHeaderControlsStore from "../modules/analysis-raw-data/hooks/useHeaderControlsStore";
import { maximizeButtonIcon } from "../modules/analysis-raw-data/styles/ChartSection.styles";

import useSectionsStore from "./useSectionsStore";

type UseMaximizeProps = {
  id: string;
  page: string;
};

const useMaximize = ({ id, page }: UseMaximizeProps) => {
  const setActiveRawDataPoints = useHeaderControlsStore(
    (store) => store.setActiveRawDataPoints
  );

  const { add, remove, find, maximize, minimize } = useSectionsStore(
    (store) => ({
      domIds: store.domIds,
      add: store.add,
      remove: store.remove,
      find: store.find,
      maximize: store.maximize,
      minimize: store.minimize,
    })
  );

  const isChartMaximized = find(id)?.isMaximized && find(id)?.isVisible;
  const isChartHidden = !find(id)?.isMaximized && !find(id)?.isVisible;

  useEffect(() => {
    add(id);

    return () => {
      remove(id);
    };
  }, [add, remove, id]);

  const maximizeAction = () => {
    if (isChartMaximized) {
      minimize();

      if (page === "raw-data") {
        setActiveRawDataPoints(true);
      }
    } else {
      maximize(id);

      if (page === "raw-data") {
        setActiveRawDataPoints(false);
      }
    }
  };

  const maximizeIcon = isChartMaximized ? (
    <MinimizeIcon style={maximizeButtonIcon} />
  ) : (
    <MaximizeIcon style={maximizeButtonIcon} />
  );

  const maximizeLabel = isChartMaximized ? "Minimize" : "Maximize";

  return {
    maximizeAction,
    maximizeIcon,
    maximizeLabel,
    isChartMaximized,
    isChartHidden,
    uniqueId: id,
  };
};

export default useMaximize;
