import { Spinner, SpinnerSize } from "@fluentui/react";
import { useEffect } from "react";

import { useMeasurementSettings } from "../../../hooks/useSensorRequests";
import { renderFormFieldArrayItems } from "../../../../common/Form/FormItems/helpers";

import {
  measurementAxesFormField,
  measurementRangeFormFields,
  measurementSampleFormField,
  measurementSelectedCalculationsFormField,
} from "./config";

const SettingsContent = ({
  form,
  index,
  settingName,
  sensorNodeId,
  handleCollapseToggle,
}: any) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = form;

  const { data, isLoading } = useMeasurementSettings(sensorNodeId, settingName);

  useEffect(() => {
    handleCollapseToggle(settingName, true);
  }, []);

  useEffect(() => {
    if (!isLoading && data) {
      setValue(`measurement.${index}.gRange`, data.gRange);
      setValue(`measurement.${index}.dataAxis`, data.dataAxis);
      setValue(`measurement.${index}.dataSampleCount`, data.dataSampleCount);
      setValue(
        `measurement.${index}.selectedCalculations`,
        data.selectedCalculations
      );
    }
    console.log({ data, isLoading });
  }, [data, isLoading]);

  if (isLoading) return <Spinner size={SpinnerSize.small} />;

  return (
    <div className="settings-collapse-wrapper">
      <div className="settings-input-container">
        <div className="settings-title-container">
          <span className="settings-title">Range</span>
        </div>
        <div className="settings-checkbox-container">
          {renderFormFieldArrayItems(
            measurementRangeFormFields,
            { control, errors },
            "measurement",
            index
          )}
        </div>
      </div>
      <div className="settings-input-container">
        <div className="settings-title-container">
          <span className="settings-title">Axes</span>
        </div>
        <div className="settings-checkbox-container">
          {renderFormFieldArrayItems(
            measurementAxesFormField,
            { control, errors },
            "measurement",
            index
          )}
        </div>
      </div>
      <div className="settings-input-container">
        <div className="settings-title-container">
          <span className="settings-title">Calculations</span>
        </div>
        <div className="settings-title-container">
          {renderFormFieldArrayItems(
            measurementSelectedCalculationsFormField,
            { control, errors },
            "measurement",
            index
          )}
        </div>
      </div>
      <div className="settings-input-container">
        <div className="settings-title-container">
          <span className="settings-title">Number of samples</span>
        </div>
        <div className="settings-title-container">
          {renderFormFieldArrayItems(
            measurementSampleFormField,
            { control, errors },
            "measurement",
            index
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsContent;
