import { isEmpty } from "lodash-es";
import { useMemo } from "react";

import { format } from "../../../../../utils";
import { useLocationSearch } from "../../../../../Hooks";
import { valueIncludes } from "../../../../../Hooks";
import { useReportsQuery } from "../../../MachineCVReportsPage/hooks/useReportsQuery";
import type { Report } from "../../../../../types";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";
import type { Column, TableProps } from "../../../../common/Table";
import Table from "../../../../common/Table";

import ReportText from "./ReportText";

const KEYS = ["text", "createdAt", "createdBy"];

const getColumns = (): Column[] => [
  {
    key: "text",
    fieldName: "text",
    name: "Text",
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    isResizable: true,
    onRender: (item: Report) => <ReportText report={item} />,
  },
  {
    key: "createdAt",
    fieldName: "createdAt",
    name: "Created At",
    minWidth: 100,
    isSortable: true,
    isResizable: false,
    onRender: (item) => format(new Date(item.createdAt)),
  },
  {
    key: "createdBy",
    fieldName: "createdBy",
    name: "Created By",
    minWidth: 150,
    isSortable: true,
    isResizable: false,
    isExportable: false,
  },
];

export const ReportsTab = ({ search }: { search: string }) => {
  const [{ id }] = useLocationSearch();
  const { reports, isError, isLoading } = useReportsQuery({
    machineId: id as string,
  });
  const filteredData = useMemo(() => {
    const normalizedSearch = search.trim().toLowerCase();

    return reports?.filter((item: any) =>
      KEYS.reduce(
        (valid, key) => valid || valueIncludes(item[key], normalizedSearch),
        false
      )
    );
  }, [search, reports]);

  const tableProps: TableProps = useMemo(
    () => ({
      persistOpts: {
        key: "table-summary-reports",
        version: 2,
      },
      items: filteredData,
      perPage: 99999,
      hidePerPage: true,
      hasSelection: false,
      isLoading: isLoading,
      isError,
      columns: getColumns(),
    }),
    [isLoading, isError, filteredData]
  );

  return (
    <>
      {isLoading && <Spin />}
      {!isLoading && isEmpty(reports) && <NoData style={{ height: "100%" }} />}
      {!isEmpty(reports) && <Table {...tableProps} />}
    </>
  );
};
