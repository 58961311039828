import { useMemo, useEffect, useState, CSSProperties } from "react";

import { useZodForm, renderFormItems } from "../common/Form";
import { notification } from "../common/Notification";
import BaseDialog, { DialogSize } from "../common/Dialog";

import {
  DialogFooter,
  IDialogProps,
  DialogType,
  Stack,
  StackItem,
  Text,
  Spinner,
  SpinnerSize,
  PrimaryButton,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { Icon as IconF } from "@fluentui/react";
import { z } from "zod";
import { UserDetails, UserUpdate } from "./models";
import { useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import type { FieldError } from "react-hook-form";
import { RolesComponentDetails } from "../Roles/RolesComponentDetails";
import {
  MemberIdRead,
  UserFieldsExtra,
  UserFieldsNonReq,
  getUserFieldsReq,
  defaultImageProfileBase64,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { areObjectsEqual } from "../../schema/Utils";
import { selectUsersRBACError, selectUsersRBACStatus } from "./reducer";
import { detailsUsersRBAC, updateUserRBAC } from "./api";
import { UpdateUserPhoto } from "./UpdateUserPhoto";
import { UserExtendedProperties } from "../ServiceAccount/models";

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "50px",
};

const getSchema = () =>
  z.object({
    email: z
      .string()
      .email({ message: "Invalid email address" })
      .min(1, { message: "This field is required" }),
    lastName: z.string().min(1, { message: "This field is required" }),
    firstName: z.string().min(1, { message: "This field is required" }),
    jobTitle: z.string().optional(),
    companyName: z.string().optional(),
    department: z.string().optional(),
    streetAddress: z.string().optional(),
    city: z.string().optional(),
    stateOrProvince: z.string().optional(),
    zipOrPostalCode: z.string().optional(),
    countryOrRegion: z.string().optional(),
    mobilePhone: z
      .union([
        z.string().length(0, { message: "Invalid number" }),
        z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    memberId: z.string().optional(),
    displayName: z.string().optional(),
    initialPassword: z.string().optional(),
  });

type DetailsDialogProps = IDialogProps & {
  data?: UserDetails;
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const DetailsDialog = ({
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: DetailsDialogProps) => {
  const schema = useMemo(() => getSchema(), []);
  const status = useAppSelector(selectUsersRBACStatus);
  const error = useAppSelector(selectUsersRBACError);
  const [isLoading, setLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [pictureToAdd, setPictureToAdd] = useState(defaultImageProfileBase64);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        email: data.properties?.email ? data.properties?.email : undefined,
        lastName: data.properties?.lastName
          ? data.properties?.lastName
          : undefined,
        firstName: data.properties?.firstName
          ? data.properties?.firstName
          : undefined,
        jobTitle: data.properties?.jobTitle
          ? data.properties?.jobTitle
          : undefined,
        companyName: data.properties?.companyName
          ? data.properties?.companyName
          : undefined,
        department: data.properties?.department
          ? data.properties?.department
          : undefined,
        streetAddress: data.properties?.streetAddress
          ? data.properties?.streetAddress
          : undefined,
        city: data.properties?.city ? data.properties?.city : undefined,
        stateOrProvince: data.properties?.stateOrProvince
          ? data.properties?.stateOrProvince
          : undefined,
        zipOrPostalCode: data.properties?.zipOrPostalCode
          ? data.properties?.zipOrPostalCode
          : undefined,
        countryOrRegion: data.properties?.countryOrRegion
          ? data.properties?.countryOrRegion
          : undefined,
        mobilePhone: data.properties?.mobilePhone
          ? data.properties?.mobilePhone
          : undefined,
        memberId: data.properties?.memberId
          ? data.properties?.memberId
          : undefined,
        displayName: data.properties?.displayName
          ? data.properties?.displayName
          : undefined,
        initialPassword: data?.properties?.initialPassword
          ? data?.properties?.initialPassword
          : undefined,
      },
    }),
  });

  useEffect(() => {
    setIsFormChanged(
      !areObjectsEqual(control._formValues, control._defaultValues)
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  const handleClose = () => {
    setLoading(false);
    setPictureToAdd("");
    onClose?.();
  };

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);

    const aux: UserUpdate = {
      ...(formData as UserExtendedProperties),
    };
    await updateUserRBAC(aux).then((response) =>
      onSuccess("status" in response, "")
    );

    handleClose();
  });

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.close,
        title: "Details User Account",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
      styles={{
        main: {
          "@media (min-width: 480px)": {
            height: "65vh",
          },
        },
      }}
      size={DialogSize.L}
    >
      <div style={pageStyle}>
        <Pivot aria-label="Pivots to edit service account">
          <PivotItem
            headerText={"Properties"}
            itemKey={"properties"}
            onRenderItemLink={() => (
              <Text style={titleStylePivot}>Properties</Text>
            )}
          >
            <Stack horizontalAlign="baseline" verticalAlign="center">
              <div style={{ marginTop: "50px" }}>
                <Text style={titleStyle}>General</Text>
              </div>
              <form onSubmit={onSubmit}>
                <Stack
                  style={{ maxWidth: "70vw", padding: "35px" }}
                  wrap
                  horizontal
                  tokens={{ childrenGap: 8 }}
                  horizontalAlign="stretch"
                  verticalAlign="center"
                >
                  {renderFormItems(
                    [
                      ...MemberIdRead,
                      ...getUserFieldsReq(true),
                      ...UserFieldsExtra,
                      ...UserFieldsNonReq,
                    ],
                    {
                      control,
                      errors: errors as { [schemaProp: string]: FieldError },
                    }
                  ).map((ele) => (
                    <StackItem key={ele.key}> {ele}</StackItem>
                  ))}
                </Stack>
                <DialogFooter>
                  <PrimaryButton
                    type="submit"
                    text="Save Changes"
                    disabled={isLoading || !isValid || !isFormChanged}
                    onRenderIcon={() =>
                      isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                    }
                  />
                </DialogFooter>
              </form>
            </Stack>
          </PivotItem>
          <PivotItem
            headerText={"Roles"}
            itemKey={"roles"}
            onRenderItemLink={() => <Text style={titleStylePivot}>Roles</Text>}
          >
            {RolesComponentDetails({
              data: data,
              memberId: data.properties?.memberId,
              detailsFunction: detailsUsersRBAC,
            })}
          </PivotItem>
          <PivotItem
            headerText={"Picture"}
            itemKey={"setPicture"}
            onRenderItemLink={() => (
              <>
                {pictureToAdd !== defaultImageProfileBase64 && (
                  <>
                    <IconF iconName="SkypeCheck" />
                  </>
                )}
                <Text style={titleStylePivot}>Picture</Text>
              </>
            )}
          >
            <Stack
              horizontalAlign="center"
              style={{ padding: "20px" }}
              tokens={{ childrenGap: 20 }}
            >
              <StackItem
                style={{ padding: "20px", width: "-webkit-fill-available" }}
              >
                <UpdateUserPhoto
                  displayName={
                    data.properties?.displayName
                      ? data.properties?.displayName
                      : ""
                  }
                  memberId={data.properties?.memberId}
                  pictureBase64={
                    data?.pictureBase64 ? data?.pictureBase64 : undefined
                  }
                />
              </StackItem>
            </Stack>
          </PivotItem>
        </Pivot>
      </div>
    </BaseDialog>
  );
};
