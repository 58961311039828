import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

import DalogLogo from '../../../../../assets/images/dalog-logo.svg';
import { watermarkLogos } from '../../../../../modules/analysis-raw-data/constants/chartWatermarkLogos';
import { yAxisWheel } from '../../../../../modules/analysis-trend-view/components/TrendViewGroupedPlot/methods';
import { torqueSignalsChartColors } from './config';
import { createTorqueTooltip } from './createTooltip';

export const createSeriesOneYaxis = ({ ref, xAxis, data, signals }: any) => {
  if (!ref.current) return;

  const { root, chart } = ref.current;

  // Remove prev series
  chart.series.clear();

  // Remove prev y axis
  chart.yAxes.clear();

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      extraTooltipPrecision: 1,
      renderer: am5xy.AxisRendererY.new(root, {}),
    }),
  );

  yAxis.events.on('wheel', (ev: any) => yAxisWheel(ev, yAxis));
  yAxis.zoom(0, 1);
  yAxis.get('renderer').labels.template.set('fontSize', 10);

  for (let index = 0; index < signals.length; index++) {
    const color = (torqueSignalsChartColors[index] || {}).color
      ? am5.color(torqueSignalsChartColors[index].color)
      : null;
    const series = ref.current.chart.series.push(
      am5xy.LineSeries.new(ref.current.root, {
        name: signals[index].signalDescription || signals[index].name || 'No signal name',
        unit: signals[index].unit,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: `value_${index}`,
        valueXField: `date_${index}`,
        stroke: color,
        fill: color,
      }),
    );

    series.data.setAll(data);
  }
};

export const initializeTorqueChart = ({
  ref,
  entityIds,
  handleStartEndChanged,
  data,
  signals,
  legendRef,
  onDataValidated,
}: any) => {
  // ==== X Axis ===
  const xAxis = ref.current.chart.xAxes.push(
    am5xy.DateAxis.new(ref.current.root, {
      id: entityIds.xAxis,
      baseInterval: { timeUnit: 'second', count: 1 },
      renderer: am5xy.AxisRendererX.new(ref.current.root, {}),
      maxDeviation: 0,
      marginTop: 16,
    }),
  );

  xAxis.get('renderer').labels.template.set('fontSize', 10);

  // === Series ===
  createSeriesOneYaxis({ ref, xAxis, data, signals });

  // === Scrollbar ===
  ref.current.chart.get('scrollbarX')?.on('start', handleStartEndChanged);
  ref.current.chart.get('scrollbarX')?.on('end', handleStartEndChanged);

  const legend: am5.Legend = ref.current.chart.children.push(
    am5.Legend.new(ref.current.root, {
      id: entityIds.legend,
      width: am5.percent(100),
      centerX: am5.percent(50),
      x: am5.percent(50),
    }),
  );

  // === Set legend marker icon
  legend.events.on('datavalidated', () => {
    const markers = legend.markers.values;

    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];

      const icon = am5.Picture.new(ref.current.root, {
        width: 16,
        height: 16,
        src: `${torqueSignalsChartColors[i].icon}`,
      });

      marker.children.setAll([icon]);
    }

    onDataValidated?.();
  });

  // === Lower marker opacity when disabled
  legend.markers.template.setup = (marker) => {
    marker.states.create('disabled', {
      opacity: 0.5,
    });
  };

  // === Legend marker label font size
  legend.labels.template.setAll({
    fontSize: 12,
  });

  // === Remove default legend marker
  legend.markerRectangles.template.setAll({
    visible: false,
    width: 0,
    height: 0,
  });

  legend.data.setAll(ref.current.chart.series.values);

  legendRef.current = legend;

  // === watermarks ===
  watermarkLogos.forEach((logoData) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const logo = am5.Picture.new(ref.current.root, {
      src: DalogLogo,
      height: logoData.height,
      opacity: logoData.opacity,
      x: logoData.x,
      y: logoData.y,
      centerX: logoData.centerX,
      centerY: logoData.centerY,
    });

    ref.current?.chart.plotContainer.children.push(logo);
  });

  createTorqueTooltip({
    ref: ref.current,
    type: 'date',
  });
};
