import type { IStackProps } from '@fluentui/react';
import { Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';

export type SpinProps = IStackProps & {
  text?: string;
};

const Spin = ({ text, style, ...rest }: SpinProps) => (
  <Stack
    {...rest}
    verticalAlign='center'
    horizontalAlign='center'
    style={{ minHeight: '10em', ...style }}
    tokens={{ childrenGap: 10 }}
  >
    <Spinner size={SpinnerSize.large} />
    <Text variant='medium' style={{ fontWeight: 600 }}>
      {text ?? 'Loading data...'}
    </Text>
  </Stack>
);

export default Spin;
