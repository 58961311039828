import { Stack } from "@fluentui/react";
import { isEmpty } from "lodash-es";

import { useLocationSearch } from "../../../../Hooks";
import type { Condition } from "../../../../modules/machine-cv/constants";
import { conditionColor } from "../../../../modules/machine-cv/constants";
import { SummaryBadge } from "../SummaryBadge";

import SectionContainer from "../SectionContainer";
import useConditionsOverview from "./hooks";

const badgeContainerStyles = {
  padding: "4px 16px",
  minHeight: 36,
  minWidth: 36,
  display: "flex",
  alignItems: "center",
  width: "100%",
};

export const ConditionsSection = ({
  ...rest
}: React.HTMLAttributes<HTMLElement>) => {
  const [{ id }, ,] = useLocationSearch();

  const { data: condition, isLoading } = useConditionsOverview(
    { machineId: id as string },
    { cacheTime: 0 }
  );

  const hasNoConditionItems = isEmpty(condition?.conditions);

  return (
    <SectionContainer
      {...rest}
      title="Conditions"
      isLoading={isLoading}
      noData={hasNoConditionItems}
    >
      <div className="condition-content-badge-container">
        {condition?.conditions?.map((currentCondition) => (
          <Stack
            key={currentCondition.id}
            horizontal
            horizontalAlign="center"
            style={{
              ...badgeContainerStyles,
              backgroundColor:
                conditionColor[currentCondition.value as Condition],
              borderRadius: "4px",
              alignItems: "center",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              marginRight: "15px",
            }}
          >
            <SummaryBadge
              description={currentCondition.name}
              value={currentCondition.value}
            />
          </Stack>
        ))}
      </div>
    </SectionContainer>
  );
};
