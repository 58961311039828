import type { IDialogProps } from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import type { FC } from "react";
import React, { useEffect, useState } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import {
  getFileIds,
  useFileDelete,
  useFileUpload,
  useLocationSearch,
} from "../../../../../../Hooks";
import AddDocumentDialog from "../../../../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import { FormType } from "../../CommandBar";
import {
  useInfoEntryAdd,
  useInfoEntryUpdate,
} from "../../../hooks/useInfoMutations";
import { useMachineCVInfo } from "../../../hooks/useMachineCVInfo";
import type { Entry, Group } from "../../../../../../types";
import BaseDialog from "../../../../../common/Dialog/Dialog";
import { renderFormItems, useZodForm } from "../../../../../common/Form";
import type { FormItemProps } from "../../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../../common/Notification";
import UploaderButton from "../../../../../common/Uploader/UploaderButton";

import DocumentsTable from "../../DocumentsTable";

const dialogAddContentProps = {
  type: DialogType.normal,
  title: "Add entry",
  closeButtonAriaLabel: "Close",
};

const dialogEditContentProps = (name: string) => ({
  type: DialogType.normal,
  title: name,
  closeButtonAriaLabel: "Close",
});

interface EditEntryDialogProps {
  formType: FormType.Edit;
  entry: Entry;
}

interface AddEntryDialogProps {
  formType: FormType.New;
  entry?: Entry;
}

type EntryDialogProps = IDialogProps & { group: Group } & (
    | EditEntryDialogProps
    | AddEntryDialogProps
  );

const getSchema = (entryNames: string[], entryTitle = "") =>
  z
    .object({
      name: z.string().min(1, { message: "This field is required" }),
      value: z.string().optional(),
    })
    .refine(
      ({ name }) => {
        const refinedInputName = name.toLowerCase().trim();
        const refinedEntryTitle = entryTitle.toLowerCase().trim();

        const nameList = entryNames.map((name) => name.toLowerCase().trim());

        if (refinedEntryTitle === refinedInputName) {
          return true;
        }

        return !nameList.includes(refinedInputName);
      },
      { path: ["name"], message: "Entry name already exists" }
    );

const EntryDialog: FC<EntryDialogProps> = (props) => {
  const { formType, hidden, group } = props;
  const [{ id }] = useLocationSearch();
  const { refetch } = useMachineCVInfo({ machineId: id || "" });

  const groupEntryNames = group.entries.map(({ name }) => name);

  const [isLoading, setIsLoading] = useState(false);

  const [isDocumentsDialogHidden, setIsDocumentsDialogHidden] = useState(true);
  const toggleIsDocumentsDialogHidden = () =>
    setIsDocumentsDialogHidden(!isDocumentsDialogHidden);
  const [files, setFiles] = useState(
    formType === FormType.Edit ? props?.entry?.files : []
  );

  useEffect(() => {
    if (hidden) {
      setFiles([]);
    } else {
      setFiles(formType === FormType.Edit ? props?.entry?.files : []);
    }
    if (formType === FormType.Edit) {
      reset(props.entry);
    }
  }, [hidden]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    ...(formType === FormType.Edit && {
      defaultValues: {
        name: props.entry.name,
        value: props.entry.value,
      },
    }),
    schema: getSchema(groupEntryNames, props?.entry?.name),
  });

  const { uploadFilesAsync } = useFileUpload();
  const { addInfoEntryAsync } = useInfoEntryAdd();
  const { updateInfoEntryAsync } = useInfoEntryUpdate();
  const { deleteFilesAsync } = useFileDelete();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);

    if (formType === FormType.New) {
      const submitData = {
        ...data,
        group: group.name,
        fileIds: successIds,
        machineId: id || "",
      };
      await addInfoEntryAsync(submitData)
        .then(() => {
          if (uploadResponse.length === successIds.length) {
            notification.success("Entry added successfully");
          } else {
            notification.success(
              `Entry added successfully with ${successIds.length} out of ${uploadResponse.length} files`
            );
          }
          onClose();
          refetch();
        })
        .catch(() => {
          notification.error("Failed adding entry");
          deleteFilesAsync(successIds);
        })
        .finally(() => setIsLoading(false));
      return;
    }

    const submitData = {
      ...data,
      group: group.name,
      machineId: id || "",
      fileIds: [
        ...files.filter((file) => !file.isValid).map((file) => file.id),
        ...successIds,
      ],
    };
    await updateInfoEntryAsync({
      ...submitData,
      id: props.entry?.id as string,
    })
      .then(() => {
        if (uploadResponse.length === successIds.length) {
          notification.success("Entry updated successfully");
        } else {
          notification.success(
            `Entry updated successfully with ${successIds.length} out of ${uploadResponse.length} files`
          );
        }
        onClose();
        refetch();
      })
      .catch(() => notification.error("Error updating entry"))
      .finally(() => setIsLoading(false));
  });

  const onClose = () => {
    reset();
    props.onDismiss?.();
  };

  const entryFields: FormItemProps[] = [
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: "Title" },
      placeholder: "Title",
    },
    {
      name: "value",
      type: FormItemType.TextField,
      groupProps: { label: "Value" },
      placeholder: "Value",
    },
  ];

  return (
    <>
      <BaseDialog
        {...props}
        dialogContentProps={
          formType === FormType.New
            ? dialogAddContentProps
            : dialogEditContentProps(props.entry.name)
        }
      >
        <form onSubmit={onSubmit}>
          {renderFormItems(entryFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <UploaderButton onClick={toggleIsDocumentsDialogHidden} />

          <DocumentsTable items={files} setItems={setFiles} />

          <DialogFooter>
            <PrimaryButton
              type="submit"
              text="Save"
              disabled={isLoading}
              onRenderIcon={() =>
                isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
              }
            />
            <DefaultButton
              styles={{
                root: { border: "unset", background: "transparent" },
              }}
              text="Cancel"
              onClick={onClose}
            />
          </DialogFooter>
        </form>
      </BaseDialog>
      <AddDocumentDialog
        hidden={isDocumentsDialogHidden}
        onSubmit={(accepted) => setFiles([...files, ...accepted])}
        onClose={toggleIsDocumentsDialogHidden}
      />
    </>
  );
};

export default EntryDialog;
