import { ComboBox, SelectableOptionMenuItemType } from "@fluentui/react";
import classNames from "classnames";
import { useEffect, useState } from "react";

import useDriveLoadStore from "../../../hooks/useDriveLoadStore";
import { handleComboBoxChange } from "../../../../MachineCVSummaryPage/SignalsSection/methods";
import { onRenderLabel } from "../../../../../common/CommandBar/methods";

const EventNameFilter = () => {
  const { updateDriveLoadStore, eventsTableData, selectedFilterEventsNames } =
    useDriveLoadStore((store: any) => ({
      eventsTableData: store.eventsTableData,
      selectedFilterEventsNames: store.selectedFilterEventsNames,
      updateDriveLoadStore: store.updateDriveLoadStore,
    }));

  const [eventsNamesOptions, setEventsNamesOptions]: any = useState([]);

  useEffect(() => {
    const eventsNamesList: any = [];
    (eventsTableData || []).forEach(({ name }: any) => {
      if (!eventsNamesList.includes(name)) {
        eventsNamesList.push(name);
      }
    });
    updateDriveLoadStore({
      selectedFilterEventsNames: ["selectAll", ...(eventsNamesList || [])],
    });
    setEventsNamesOptions([
      {
        key: "selectAll",
        text: "Select All",
        itemType: SelectableOptionMenuItemType.SelectAll,
      },
      ...eventsNamesList.map((name: any) => ({ key: name, text: name })),
    ]);
  }, [eventsTableData]);

  if (eventsNamesOptions.length <= 1) return null;

  return (
    <ComboBox
      allowFreeform={false}
      allowFreeInput={false}
      className={classNames(
        "layout-dropdown secondary-dropdown combobox-dropdown"
      )}
      calloutProps={{ calloutWidth: undefined, calloutMinWidth: 200 }}
      selectedKey={selectedFilterEventsNames}
      options={eventsNamesOptions}
      multiSelect={true}
      onChange={(e: any, option: any) => {
        handleComboBoxChange({
          selectedOption: option,
          selectedOptions: selectedFilterEventsNames,
          options: eventsNamesOptions,
          onChange: (values: any) => {
            updateDriveLoadStore({ selectedFilterEventsNames: values || [] });
          },
        });
      }}
      onRenderLabel={() =>
        onRenderLabel({
          label: "Event",
          icon: "Event",
        })
      }
    />
  );
};

export default EventNameFilter;
