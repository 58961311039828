import { useEffect, useState } from "react";

import CloudIcon from "../../../../assets/svg/WirelessSensors/CloudIcon";
import GatewayIcon from "../../../../assets/svg/WirelessSensors/GatewayIcon";
import SensorNodeIcon from "../../../../assets/svg/WirelessSensors/SensorNodeIcon";
import type { TreeItem } from "../../components/Tree";
import WirelessSensorsTree from "../../components/Tree";
import type { GatewayTelemetry, Topology } from "../../../../types";

import { useProjectTelemetry } from "../../hooks/useProjectTelemetry";
import { useWSUrlParams } from "../../hooks/useWSUrlParams";

const ProjectTelemetry = () => {
  const { projectId } = useWSUrlParams();
  const { data, isLoading, isError, refetch } = useProjectTelemetry({
    projectId: projectId ?? "",
  });
  const [tree, setTree] = useState<TreeItem>({});

  const gatewayTelemetryToTreeItem = (
    telemetry: GatewayTelemetry
  ): TreeItem => {
    return {
      icon: <GatewayIcon />,
      name: telemetry.gatewayId,
      children: telemetry.topology.children.map(topologyTelemetryToTreeItem),
      condition: telemetry.enabled ? "Ok" : "Alert",
    };
  };

  const topologyTelemetryToTreeItem = (telemetry: Topology): TreeItem => {
    return {
      name: telemetry.value,
      children: telemetry.children.map(topologyTelemetryToTreeItem),
      icon: <SensorNodeIcon />,
      condition: "Ok",
    };
  };

  useEffect(() => {
    if (!isError && !isLoading && data) {
      const treeData: TreeItem = {
        name: "",
        icon: <CloudIcon />,
        children: data.gatewayTelemetries.map((telemetry: GatewayTelemetry) =>
          gatewayTelemetryToTreeItem(telemetry)
        ),
      };
      setTree(treeData);
    }

    return;
  }, [data, isLoading, isError]);

  return (
    <WirelessSensorsTree
      data={tree}
      title={"Project Telemetry"}
      isLoading={isLoading}
      onRefresh={refetch}
    />
  );
};

export default ProjectTelemetry;
