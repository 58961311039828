import { Spinner, SpinnerSize } from "@fluentui/react";
import type { AxiosInstance } from "axios";
import { useEffect, useState } from "react";

import { notification } from "../../../common/Notification";

export enum TriggeringStatsGroupingTypes {
  gateways = "gateways",
  sensors = "sensors",
}

type StatisticsResponseStatus = {
  status?: string;
  text?: string;
};

type WirelessStatsData = {
  result?: string;
  eventType: StatisticsResponseStatus;
};

/**
 * Uses the triggering statistics
 * @param axiosInstance The axios instance
 * @param projectId The project uuid .
 * @param groupBy The statistics grouping type.
 * @returns The statistics image as base64 string and a value indicating whether the data is being fetched or not.
 */
export const useTriggeringStats = (
  axiosInstance: AxiosInstance | null,
  projectId: string | null,
  groupBy: TriggeringStatsGroupingTypes
) => {
  const [stats, setStats] = useState<string | undefined>(undefined);
  const [statsLoading, setStatsLoading] = useState<boolean>(false);

  // Gets the triggering statistics.
  useEffect(() => {
    if (!axiosInstance || !projectId) {
      notification.error(
        "Failure getting triggering statistics by gateways: The projectId or the axios instance are null."
      );
      return;
    }

    setStatsLoading(true);
    axiosInstance
      .get<WirelessStatsData>(`/wirelessStatistics/v1/${projectId}/${groupBy}`)
      .then((response) => {
        setStatsLoading(false);
        if (response.status !== 200) {
          notification.error(
            `Failure getting triggering statistics by gateways: ${response.statusText}.`
          );
          return;
        }

        const svgStr = response.data.result?.replace(/(\n)/gm, "");
        if (svgStr === undefined) {
          return;
        }

        setStats(`data:image/svg+xml;base64,${btoa(svgStr)}`);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, [axiosInstance, projectId]);

  return { stats, statsLoading };
};

/**
 * Uses the loading panel.
 * @param condition The condition to fulfill, to show the wrapped component.
 * @param wrappedComponent The wrapped component.
 * @returns If the condition is true, the wrapped component. Otherwise, the loading panel.
 */
export const useLoadingHOC = (
  condition: boolean,
  wrappedComponent: JSX.Element
): JSX.Element => {
  const result = condition ? (
    wrappedComponent
  ) : (
    <Spinner
      size={SpinnerSize.large}
      label="Loading statistics..."
      style={{ minHeight: "15em" }}
    />
  );

  return result;
};
