/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import { AxiosInstance } from "axios";
import VpnConnectionsSection from "../internal/VpnConnectionsSection";
import { createTheme, loadTheme } from "@fluentui/react";
import "../CSS/index.css";
import { notification } from "../../common/Notification";
import { MetadataProject } from "../Schema/models";
import { VpnConnectionsAPI } from "../Schema/api";
import { VpnConnectionsManagerProps } from "./VpnConnectionsManager.types";

// Contexts
export const AxiosContext = createContext<AxiosInstance | null>(null);

// Theme provider
const defaultTheme = createTheme({
  palette: {
    themePrimary: "#2c53a0",
    themeLighterAlt: "#f4f6fb",
    themeLighter: "#d4ddf0",
    themeLight: "#b1c2e3",
    themeTertiary: "#6f8cc6",
    themeSecondary: "#3d62ac",
    themeDarkAlt: "#274a91",
    themeDark: "#213f7a",
    themeDarker: "#182e5a",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

loadTheme(defaultTheme);

/**
 * Gets the VPN Connections Manager component.
 * @param hasAdminPermissions value determining whether there is administrator permissions.
 * @param axiosInstance The axios instance.
 * @returns The VPN Connections manager component
 */
const VpnConnectionsManager = ({
  hasAdminPermissions,
  axiosInstance,
  onClose,
}: VpnConnectionsManagerProps) => {
  const [projects, setProjects] = useState<MetadataProject[]>([]);

  // Gets the projects list.
  useEffect(() => {
    if (!axiosInstance) {
      notification.error("No action instance object detected.");
      onClose?.();
      return;
    }

    VpnConnectionsAPI.listProjects(axiosInstance).then((response) => {
      if (response.status !== 200) {
        notification.error(
          `Failure getting the projects list: ${response.statusText}.`
        );

        onClose?.();
        return;
      }

      if (response.data.length === 0) {
        notification.error(
          `No projects found. Please check whether you have the appropriate permissions to access to this page.`
        );

        onClose?.();
        return;
      }

      setProjects(response.data);
    });
  }, []);

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {projects?.length > 0 && (
        <VpnConnectionsSection
          hasAdminPermissions={hasAdminPermissions}
          projects={projects}
          onClose={() => onClose?.()}
        />
      )}
    </AxiosContext.Provider>
  );
};

export default VpnConnectionsManager;
