import { Text, TooltipHost, Icon } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import { Company, CompanyToList } from "./models";

import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsStatus,
} from "../Corporations/reducer";

import { notification } from "../common/Notification";
import BaseCommandBar, {
  computeCommandBarItems,
  CommandBarItemProps,
  CommandBarItemType,
} from "../common/CommandBar";
import Table, { useTableFilters, Column } from "../common/Table";

import {
  listAsyncCompanies,
  selectCompanies,
  selectCompaniesError,
  selectCompaniesStatus,
} from "./reducer";
import { DeleteConfirm } from "./DeleteConfirm";
import { AddOrEditDialog } from "./AddOrEditDialog";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { Corporation } from "../Corporations/models";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";

// --- Styles ---

// --- Columns ---

type GetColumnsOpts = {
  hasActions: boolean;
  onEdit: (company: Company) => void;
  onDelete: (company: Company) => void;
};

const getColumns = ({
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, name }: Company) => (
        <Link to={id} style={linkStyle}>
          {name}
        </Link>
      ),
    },
    {
      key: "number",
      name: "Number",
      fieldName: "number",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "corporation",
      name: "Corporation",
      fieldName: "corporation",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (company: Company) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={"Edit"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(company)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(company)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

export const CompaniesList: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const auth = useContext(authContext);
  const items = useAppSelector(selectCompanies);
  const status = useAppSelector(selectCompaniesStatus);
  const corporationsStatus = useAppSelector(selectCorporationsStatus);
  const corpos = useAppSelector(selectCorporations);
  const [itemsWithParent, setItemsWithParent] = useState<CompanyToList[]>([]);
  const error = useAppSelector(selectCompaniesError);

  useEffect(() => {
    if (status === Status.error && items.length < 1) notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, items.length]);

  useEffect(() => {
    dispatch(listAsyncCompanies());
  }, [dispatch]);

  useEffect(() => {
    const toListAux: CompanyToList[] = items.map((com) => {
      const corpName = corpos.find((cor) => cor.id === com.corporationId)?.name;
      return { ...com, corporation: corpName ? corpName : "" };
    });
    setItemsWithParent(toListAux);
  }, [corpos, items]);

  useEffect(() => {
    if (corporationsStatus === Status.void) dispatch(listAsyncCorpo());
  }, [corporationsStatus, dispatch]);

  const hasWritePermission = auth.metaDataContributor;

  const { filters, handleSearch } = useTableFilters<CompanyToList>({
    keys: ["name", "number", "corporation"],
  });

  const [selected, setSelected] = useState<{
    data: CompanyToList | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  const onAdd = () => setSelected({ data: null, context: "add" });

  const onEdit = (company: CompanyToList) =>
    setSelected({ data: company, context: "edit" });

  const onDelete = (company: CompanyToList) =>
    setSelected({ data: company, context: "delete" });

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>Company</Text>,
    },
    ...(hasWritePermission
      ? [
          {
            key: "add",
            text: "Add",
            type: CommandBarItemType.Button,
            iconProps: { iconName: "Add" },
            onClick: onAdd,
          },
        ]
      : []),
  ];

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(commandBarItems)}
        onSearch={handleSearch}
        styles={commandBarStyles}
      />

      <Table
        persistOpts={{
          key: "table-companies",
          version: 2,
        }}
        header={{
          title: "Company",
        }}
        items={itemsWithParent}
        columns={getColumns({
          hasActions: hasWritePermission,
          onEdit,
          onDelete,
        })}
        filters={filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
      />

      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              `Failed deleting ${selected?.data?.name} company`
            );
          } else {
            dispatch(listAsyncCompanies());
            notification.success(
              `${selected?.data?.name} company deleted successfully`
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />

      {["add", "edit"].includes(selected?.context) && (
        <AddOrEditDialog
          options={corpos.map((corp: Corporation) => ({
            key: corp.id,
            text: corp.name,
          }))}
          data={selected?.data}
          items={itemsWithParent}
          show={["add", "edit"].includes(selected?.context)}
          onSuccess={(hasError, data, context) => {
            if (hasError) {
              const message = `Failed ${
                context === "add" ? "creating" : "updating"
              } ${data.name}`;

              notification.error(message);
            } else {
              dispatch(listAsyncCompanies());

              const message = `${data.name} ${
                context === "add" ? "created" : "updated"
              }  successfully`;

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
    </div>
  );
};
