import { useQuery } from '@tanstack/react-query';

import { prepareSignalsWithP12H } from '../../../../modules/analysis-trend-view/components/TrendViewSelectSignals/utils';
import { apiService } from '../../../../modules/common';
import { conditionOrder } from '../SignalsSection/methods';
import { ResponseSimplifiedSignal, ResponseSimplifiedSignalWithConditionKey } from '../../../../types';

export const useSignalsList = (id: string, options?: object) => {
  const {
    data = [],
    isLoading,
    isError,
  } = useQuery<ResponseSimplifiedSignal[]>(
    ['signals-list', id],
    () => apiService.metaDataRead.getSignalsList(id as string),
    options,
  );

  const signals: ResponseSimplifiedSignalWithConditionKey[] = prepareSignalsWithP12H(data).map(
    (item: ResponseSimplifiedSignal) => ({
      ...item,
      conditionKey: conditionOrder[item.condition || 'NoStatus'],
    }),
  );

  return {
    signals,
    isLoading,
    isError,
  };
};
