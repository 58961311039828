import {
  PrimaryButton,
  Stack,
  Text,
  TextField,
  IconButton,
  TooltipHost,
  Spinner,
  SpinnerSize,
  IIconProps,
} from "@fluentui/react";
import { CSSProperties, useState } from "react";
import {
  RegeneratePrimaryKeyServiceAccount,
  subscriptionKeysServiceAccount,
} from "./api";
import { RegenerateSecondaryKeyServiceAccount } from "./api";
import { notification } from "../common/Notification";
import CopyToClipboard from "react-copy-to-clipboard";

const titleStyle: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  borderRight: "1px solid #E1DFDD",
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "20px",
  marginBottom: "auto",
};

const copyIcon: IIconProps = { iconName: "Copy" };

interface SubscriptionKeysInput {
  memberId: string;
  subscriptionId: string;
}

interface keysInfoMain {
  key1: keysInfoManage;
  key2: keysInfoManage;
}

interface keysInfoManage {
  show: boolean;
  key: string;
}

export const SubscriptionKeys = ({
  memberId,
  subscriptionId,
}: SubscriptionKeysInput) => {
  const [keysInfo, setKeysInfo] = useState<keysInfoMain>({
    key1: { key: "********************************", show: false },
    key2: { key: "********************************", show: false },
  });
  const [isLoading1, setIsLoading1] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);

  const [isLoadingReg1, setIsLoadingReg1] = useState<boolean>(false);
  const [isLoadingReg2, setIsLoadingReg2] = useState<boolean>(false);
  return (
    <>
      <Text style={titleStyle}>Subscription of: {subscriptionId}</Text>
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        tokens={{ childrenGap: 20 }}
      >
        <Stack
          style={{ minWidth: "150px" }}
          horizontalAlign="center"
          verticalAlign="end"
          tokens={{ childrenGap: 8 }}
          horizontal
        >
          <TextField
            label="Primary Key"
            readOnly
            value={keysInfo.key1.key}
            size={100}
            onRenderLabel={(props, render) => {
              return (
                <Stack horizontal horizontalAlign="start">
                  <Stack verticalAlign="end">{render!(props)}</Stack>
                  <TooltipHost content={"Regenerate primary key"}>
                    <IconButton
                      iconProps={{
                        iconName: "Sync",
                      }}
                      onRenderIcon={(props, render) =>
                        isLoadingReg1 ? (
                          <Spinner size={SpinnerSize.medium} />
                        ) : (
                          render(props)
                        )
                      }
                      onClick={async () => {
                        setIsLoadingReg1(true);
                        await RegeneratePrimaryKeyServiceAccount(
                          memberId,
                          subscriptionId
                        ).then((response) => {
                          if ("error" in response) {
                            setKeysInfo({
                              ...keysInfo,
                              key1: {
                                key: "--------",
                                show: false,
                              },
                            });
                            notification.warning(
                              "The key couldn't be regenerated"
                            );
                          } else {
                            setKeysInfo({
                              ...keysInfo,
                              key1: {
                                key: response.primaryKey,
                                show: true,
                              },
                            });
                            notification.success("Regenerated Key!");
                          }
                        });
                        setIsLoadingReg1(false);
                      }}
                    />
                  </TooltipHost>
                </Stack>
              );
            }}
            onRenderInput={(props, defaultRenderer) => {
              return (
                <>
                  {defaultRenderer(props)}
                  <TooltipHost content={"Copy key"}>
                    {keysInfo.key1.show ? onPass(props.value as string) : <></>}
                  </TooltipHost>
                </>
              );
            }}
          />
          <div style={{ paddingRight: 10 }}>
            <PrimaryButton
              text={keysInfo.key1.show ? "Hide" : "Show"}
              onClick={async () => {
                setIsLoading1(true);
                !keysInfo.key1.show
                  ? await subscriptionKeysServiceAccount(
                      memberId,
                      subscriptionId
                    ).then((response) => {
                      setKeysInfo({
                        ...keysInfo,
                        key1: {
                          key: response.primaryKey,
                          show: !keysInfo.key1.show,
                        },
                      });
                    })
                  : setTimeout(() => {
                      setKeysInfo({
                        ...keysInfo,
                        key1: {
                          key: "********************************",
                          show: !keysInfo.key1.show,
                        },
                      });
                    }, 50);
                setIsLoading1(false);
              }}
              onRenderText={(props, render) => (
                <>
                  {!isLoading1 ? (
                    render(props)
                  ) : (
                    <Spinner size={SpinnerSize.medium} />
                  )}
                </>
              )}
            />
          </div>
        </Stack>
        <Stack
          style={{ minWidth: "150px" }}
          horizontalAlign="center"
          verticalAlign="end"
          tokens={{ childrenGap: 8 }}
          horizontal
        >
          <TextField
            label="Secondary Key"
            readOnly
            value={keysInfo.key2.key}
            size={100}
            onRenderLabel={(props, render) => {
              return (
                <Stack horizontal horizontalAlign="start">
                  <Stack verticalAlign="end">{render!(props)}</Stack>
                  <TooltipHost content={"Regenerate secondary key"}>
                    <IconButton
                      iconProps={{
                        iconName: "Sync",
                      }}
                      onRenderIcon={(props, render) =>
                        isLoadingReg2 ? (
                          <Spinner size={SpinnerSize.medium} />
                        ) : (
                          render(props)
                        )
                      }
                      onClick={async () => {
                        setIsLoadingReg2(true);
                        await RegenerateSecondaryKeyServiceAccount(
                          memberId,
                          subscriptionId
                        ).then((response) => {
                          if ("error" in response) {
                            setKeysInfo({
                              ...keysInfo,
                              key2: {
                                key: "--------",
                                show: false,
                              },
                            });
                            notification.warning(
                              "The key couldn't be regenerated"
                            );
                          } else {
                            setKeysInfo({
                              ...keysInfo,
                              key2: {
                                key: response.secondaryKey,
                                show: true,
                              },
                            });
                            notification.success("Regenerated Key!");
                          }
                        });
                        setIsLoadingReg2(false);
                      }}
                    />
                  </TooltipHost>
                </Stack>
              );
            }}
            onRenderInput={(props, defaultRenderer) => {
              return (
                <>
                  {defaultRenderer(props)}
                  <TooltipHost content={"Copy key"}>
                    {keysInfo.key2.show ? onPass(props.value as string) : <></>}
                  </TooltipHost>
                </>
              );
            }}
          />
          <div style={{ paddingRight: 10 }}>
            <PrimaryButton
              text={keysInfo.key2.show ? "Hide" : "Show"}
              onClick={async () => {
                setIsLoading2(true);
                !keysInfo.key2.show
                  ? await subscriptionKeysServiceAccount(
                      memberId,
                      subscriptionId
                    ).then((response) => {
                      setKeysInfo({
                        ...keysInfo,
                        key2: {
                          key: response.secondaryKey,
                          show: !keysInfo.key2.show,
                        },
                      });
                    })
                  : setTimeout(() => {
                      setKeysInfo({
                        ...keysInfo,
                        key2: {
                          key: "********************************",
                          show: !keysInfo.key2.show,
                        },
                      });
                    }, 50);
                setIsLoading2(false);
              }}
              onRenderText={(props, render) => (
                <>
                  {!isLoading2 ? (
                    render(props)
                  ) : (
                    <Spinner size={SpinnerSize.medium} />
                  )}
                </>
              )}
            />
          </div>
        </Stack>
      </Stack>
    </>
  );
};

const onPass = (value: string) => {
  return (
    <>
      <Stack horizontal>
        <CopyToClipboard
          text={value}
          onCopy={(text) =>
            !text &&
            notification.warning("You are trying to copy an empty string.")
          }
        >
          <IconButton iconProps={copyIcon} ariaLabel={"icon"}></IconButton>
        </CopyToClipboard>
      </Stack>
    </>
  );
};
