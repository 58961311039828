import type { IColumn } from '@fluentui/react';
import { DEFAULT_CELL_STYLE_PROPS } from '@fluentui/react';

/**
 *  By setting resizable columns, we need to use the width from the column whenever we're rendering a custom row cell.
 *  https://github.com/microsoft/fluentui/blob/master/packages/react/src/components/DetailsList/DetailsRowFields.tsx#L53
 */
export const getRowCellWidth = (column: IColumn): string | number => {
  return typeof column.calculatedWidth === 'undefined'
    ? 'auto'
    : column.calculatedWidth +
        DEFAULT_CELL_STYLE_PROPS.cellLeftPadding +
        DEFAULT_CELL_STYLE_PROPS.cellRightPadding +
        (column.isPadded ? DEFAULT_CELL_STYLE_PROPS.cellExtraRightPadding : 0);
};
