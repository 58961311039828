import { useQuery } from "@tanstack/react-query";

import { useSelectedSignalsStore } from "../../../analysis-trend-view";
import { getApiClient } from "../../../core/apiClient/useApiStore";

type TrendViewMachineAlertsProps = {
  machineId: string;
  signalId: string;
};

type TrendViewMachineAlertsOptions = {
  enabled?: boolean;
};

export const useTrendViewMachineAlerts = (
  args: TrendViewMachineAlertsProps,
  options: TrendViewMachineAlertsOptions
) => {
  const { machineId, signalId } = args;
  const { enabled } = options || {};

  const { addSelectedSignalAlert } = useSelectedSignalsStore((state) => ({
    addSelectedSignalAlert: state.addSelectedSignalAlert,
  }));

  return useQuery(
    ["trend-view-machine-alerts", signalId],
    () =>
      getApiClient()
        .get(`/meta/read/v1/machines/${machineId}/signals/${signalId}`)
        .then(({ data }) => {
          if (data?.alertLevel) addSelectedSignalAlert(data.alertLevel);
          return data.alertLevel;
        }),
    {
      enabled,
    }
  );
};
