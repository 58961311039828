/* eslint-disable react-hooks/exhaustive-deps */
import { DialogFooter, PrimaryButton, Stack, TextField } from "@fluentui/react";
import {
  AutomationStatusType,
  ConnectionStatusType,
  HistoricalChangesItemDetailed,
} from "../../Schema/models";
import { BasicDialogProps } from "../../Schema/viewModels";
import { Utils } from "../../Utils/utils";
import FormDialog from "../generic/FormDialog";
import { FormItemRow, FormItemSection } from "../generic/FormDialogComponents";
import { CSSProperties, useEffect, useState } from "react";

import { DialogSize } from "../../../common/Dialog";

type HistoricalChangesDetailsDialogProps = BasicDialogProps & {
  item: HistoricalChangesItemDetailed;
};

type HistoricalChangesDetailsData = {
  automationStatus: string;
  automationStatusComments: string;
  connectionStatus: string;
  connectionStatusComments: string;
  emailContacts: string;
  connectionDetails: string;
};

const timeStampStyle: CSSProperties = {
  backgroundColor: "#F3F2F1",
  padding: "0.8em",
};

/**
 * Gets the historical changes details dialog component.
 * @param item The historical changes detailed item.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The historical changes details dialog component.
 */
const HistoricalChangesDetailsDialog = ({
  item,
  onClose,
}: HistoricalChangesDetailsDialogProps) => {
  const [data, setData] = useState<HistoricalChangesDetailsData | null>(null);

  // Builds the data object.
  useEffect(() => {
    if (!item) {
      onClose?.();
      return;
    }

    let result: HistoricalChangesDetailsData = {
      automationStatus: AutomationStatusType[item.automationStatus.status],
      automationStatusComments: item.automationStatus.comment || "",
      connectionStatus: ConnectionStatusType[item.connectionStatus.status],
      connectionStatusComments: item.connectionStatus.comment || "",
      emailContacts: item.emailDetails || "",
      connectionDetails: item.connectionDetails || "",
    };

    setData(result);
  }, [item]);

  let timeStamp = new Date(item.timeStamp);

  return (
    <FormDialog
      title={Utils.firstToUpperCase(item.projectName)}
      onClose={onClose}
      size={DialogSize.M}
    >
      <Stack style={timeStampStyle}>
        <p className="label-value-text">
          {Utils.getDate(timeStamp)} {Utils.getTime(timeStamp)}
          {"   "}
          {item.changedBy}
        </p>
      </Stack>
      <FormItemSection title={"Automation Status"}>
        <FormItemRow label={"Status:"}>
          <p className="label-value-text">{data?.automationStatus || ""}</p>
        </FormItemRow>
        <FormItemRow label={"Comments:"}>
          <p className="label-value-text">
            {data?.automationStatusComments || ""}
          </p>
        </FormItemRow>
      </FormItemSection>
      <FormItemSection title={"Connection Status"}>
        <FormItemRow label={"Status:"}>
          <p className="label-value-text">{data?.connectionStatus || ""}</p>
        </FormItemRow>
        <FormItemRow label={"Comments:"}>
          <p className="label-value-text">
            {data?.connectionStatusComments || ""}
          </p>
        </FormItemRow>
      </FormItemSection>
      <FormItemSection title={"Email Contacts"}>
        <TextField
          multiline
          rows={3}
          resizable={false}
          readOnly
          value={data?.emailContacts || ""}
        />
      </FormItemSection>
      <FormItemSection title={"Connection Details"}>
        <TextField
          multiline
          rows={14}
          resizable={false}
          readOnly
          value={data?.connectionDetails || ""}
        />
      </FormItemSection>
      <DialogFooter>
        <PrimaryButton
          className="primary-button"
          text="Done"
          onClick={() => onClose?.()}
        />
      </DialogFooter>
    </FormDialog>
  );
};

export default HistoricalChangesDetailsDialog;
