import type { IDropdownOption, IPersonaProps } from "@fluentui/react";
import { differenceInDays } from "date-fns";
import { useMemo, useState } from "react";

import {
  useMachineUsersQuery,
  useLocationSearch,
  useSearch,
} from "../../../../../../Hooks";
import { convertToTzDate } from "../../../../../../modules/analysis-trend-view/utils/getRangeFormat";
import { ShowMoreGrid } from "../../../../../../modules/common/components/Dashboards/ShowMoreGrid";
import { Comment } from "./Comment";
import { CommentDialog } from "./Comment/CommentDialog";
import { PeoplePicker } from "./PeoplePicker";
import { useTask } from "../../../hooks/useTask";
import useTasksStore from "../../../hooks/useTasksStore";
import type { Comment as IComment } from "../../../../../../types";

import SectionTitle from "../../SectionTitle";
import { CommandBar, commentsCreatedDropdown } from "./CommandBar";

const DEFAULT_LIST_SIZE = 3;

export const filterByTime = (days: number, comments: IComment[]) => {
  if (!days) return comments;

  return comments.filter(
    (comment) =>
      differenceInDays(new Date(), new Date(comment.createdAt)) <= days
  );
};

const onCommentFilter = (
  item: IDropdownOption,
  comments: IComment[],
  selectedKeys: string[]
) => {
  const filteredComments = filterByTime(item.key as number, comments);

  return filteredComments.filter(
    (comment) =>
      !selectedKeys.length || selectedKeys.includes(comment.createdBy)
  );
};

type TaskCommentsProps = { projectTimezone?: any };

export const TaskComments = ({ projectTimezone }: TaskCommentsProps) => {
  const { edit } = useTasksStore((state) => ({
    edit: state.edit,
  }));
  const [{ id }] = useLocationSearch();
  const [showTaskCommentsDialog, setShowTaskCommentsDialog] = useState(false);

  const { task } = useTask({ taskId: edit.id });

  const [filter, setFilter] = useState<IDropdownOption>(
    commentsCreatedDropdown[0]
  );
  const { data, handleSearch } = useSearch<IComment>({
    data: task?.comments,
    keys: ["text"],
  });
  const { usersPersonas } = useMachineUsersQuery({ machineId: id as string });
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const items: IComment[] = useMemo(
    () => onCommentFilter(filter, data, selectedKeys),
    [filter, data, selectedKeys]
  );

  const commentsToShow = useMemo(() => {
    return items.map((item: any) => {
      const convertedDate = convertToTzDate(
        item.createdAt,
        null,
        projectTimezone
      );
      return {
        ...item,
        createdAt: new Date(convertedDate),
      };
    });
  }, [items, projectTimezone]);

  const peoplePickerData: IPersonaProps[] = useMemo(() => {
    const current = data.map((item) => item.createdBy);
    return usersPersonas.filter((user) => current.includes(user.id));
  }, [usersPersonas]);

  return (
    <div>
      <SectionTitle>Comments</SectionTitle>

      <CommandBar
        handleSearch={handleSearch}
        onAdd={() => setShowTaskCommentsDialog(true)}
        onFilter={setFilter}
      />
      <PeoplePicker data={peoplePickerData} onChange={setSelectedKeys} />

      <ShowMoreGrid
        data={commentsToShow}
        sizeProps={{ size: DEFAULT_LIST_SIZE, increment: DEFAULT_LIST_SIZE }}
        renderComponent={(data) => (
          <div style={{ marginTop: 16 }}>
            {data.map((comment, idx) => (
              <Comment key={idx} item={comment} />
            ))}
          </div>
        )}
      />

      <CommentDialog
        id={edit.id}
        hidden={!showTaskCommentsDialog}
        onDismiss={() => setShowTaskCommentsDialog(false)}
      />
    </div>
  );
};
