import { useQuery } from "@tanstack/react-query";
import orderBy from "lodash-es/orderBy";

import { getApiClient } from "../../../../modules/core/apiClient/useApiStore";
import type { MachineCVComment } from "../../../../types";

type UseCommentsQuery = {
  comments: MachineCVComment[];
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

export type UseCommentsQueryOpts = {
  machineId: string;
};

export const useCommentsQuery = (
  opts: UseCommentsQueryOpts
): UseCommentsQuery => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["machine-cv-threads", opts],
    () =>
      getApiClient()
        .get<MachineCVComment[]>(
          `meta/machinecv/v1/comments/machines/${opts.machineId}`
        )
        .then(({ data }) => orderBy(data, ["createdAt"], ["desc"])),
    {
      cacheTime: 0,
    }
  );

  return {
    comments: data,
    isLoading,
    isError,
    refetch,
  };
};
