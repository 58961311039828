import { Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";

import { useSensorDetails } from "../../hooks/useSensorDetails";
import { useWSUrlParams } from "../../hooks/useWSUrlParams";
import SensorTabs from "./SensorTabs";
import { NoData } from "../../../common/NoData";

const Sensor = () => {
  const { sensorNodeId } = useWSUrlParams();
  const { isLoading, sensor } = useSensorDetails({ sensorNodeId });

  return (
    <div className="sensor-container">
      {isLoading ? (
        <Spinner style={{ marginTop: 30 }} size={SpinnerSize.large} />
      ) : (
        <>
          {sensor ? (
            <SensorTabs key={sensorNodeId} sensor={sensor} />
          ) : (
            <NoData style={{ marginTop: 30 }} text="The sensor was not found" />
          )}
        </>
      )}
    </div>
  );
};

export default Sensor;
