import type { IDropdownOption } from '@fluentui/react/lib/components/Dropdown';

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

interface LayoutStoreProps {
  layout: IDropdownOption | undefined;
  isLayoutReset: boolean | undefined;
  containerWidth: number;
  changeLayout: (trendsGrouping: IDropdownOption | undefined) => void;
  setLayoutReset: (value: boolean | undefined) => void;
  setContainerWidth: (value: number) => void;
}

const useLayoutStore = createWithEqualityFn<LayoutStoreProps>((set) => ({
  layout: { key: 'waveform-spectrum', text: 'Waveform and Spectrum', data: { reset: true } },
  //layout: { key: 'waterfall', text: 'Waterfall', data: { reset: true } },
  isLayoutReset: false,
  containerWidth: 0,
  changeLayout: (layout: IDropdownOption | undefined) => set({ layout }),
  setLayoutReset: (value: boolean | undefined) => set({ isLayoutReset: value }),
  setContainerWidth: (value: number) => set({ containerWidth: value }),
}), shallow);

export default useLayoutStore;
