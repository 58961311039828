const DEFAULT_ROWS_PER_PAGE = 25;

export const getDefaultPerPage = () => {
  let localStorageValue: any = localStorage.getItem('rows-per-page') || DEFAULT_ROWS_PER_PAGE;
  try {
    localStorageValue = JSON.parse(localStorageValue);
  } catch (e) {
    return parseInt(localStorageValue || DEFAULT_ROWS_PER_PAGE);
  }
  return parseInt(localStorageValue || DEFAULT_ROWS_PER_PAGE);
};

export const TABLE_LOCAL_STORAGE_SORT_KEY = 'TABLE_LOCAL_STORAGE_SORT_KEY';

export const getLocalStorageSort = (tableSortKey: any) => {
  let sorts = localStorage.getItem(`${TABLE_LOCAL_STORAGE_SORT_KEY}_${tableSortKey}`);
  sorts = JSON.parse(sorts || '[]');
  return sorts;
};

export const setLocalStorageSort = (tableSortKey: any, itemToSave: any) => {
  const existingSorts: any = getLocalStorageSort(tableSortKey) || [];

  const filteredSorts = existingSorts.filter((item: any) => item.key !== itemToSave.key);
  const { isSorted } = itemToSave || {};

  if (isSorted) {
    localStorage.setItem(
      `${TABLE_LOCAL_STORAGE_SORT_KEY}_${tableSortKey}`,
      JSON.stringify([...filteredSorts, itemToSave]),
    );
  } else {
    if (filteredSorts.length === 0) {
      localStorage.removeItem(`${TABLE_LOCAL_STORAGE_SORT_KEY}_${tableSortKey}`);
    } else {
      localStorage.setItem(
        `${TABLE_LOCAL_STORAGE_SORT_KEY}_${tableSortKey}`,
        JSON.stringify(filteredSorts),
      );
    }
  }
};
