import type * as am5xy from '@amcharts/amcharts5/xy';

import type { ConnectedTimeRange } from '../hooks/useControlsStore';

interface ChartData {
  date: number;
}

export const isOutOfSync = (chartData: ChartData[], current?: ConnectedTimeRange['range']) => {
  if (chartData.length === 0 || !current) {
    return false;
  }

  const range: ConnectedTimeRange['range'] = {
    startIndex: chartData[0].date,
    endIndex: chartData[chartData.length - 1].date,
  };

  if (current.startIndex >= range.startIndex && current.endIndex <= range.endIndex) {
    return false;
  }

  return true;
};

export const zoomToDates = (
  axis: am5xy.DateAxis<am5xy.AxisRenderer>,
  chartData: ChartData[],
  current: ConnectedTimeRange['range'],
) => {
  if (isOutOfSync(chartData, current)) {
    return;
  }

  axis.zoomToDates(new Date(current.startIndex), new Date(current.endIndex));
};
