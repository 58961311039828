import type { CSSProperties } from "react";
import { useState } from "react";

import { BookmarkButton } from "../../../../../modules/common/components/BookmarkButton";
import useBookmark from "../../../../../modules/common/components/Bookmarks/useBookmark";
import { computeSubTitle } from "./methods";

type DashboardHeaderProps = {
  data?: any;
  title?: string;
  subtitle?: string;
};

const styles: Record<string, CSSProperties> = {
  title: {
    fontWeight: 600,
    fontSize: 32,
    lineHeight: "40px",
    marginBottom: 16,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    marginBottom: 20,
  },
};

export const DashboardHeader = ({
  data,
  title = "Dalog GmbH",
  subtitle = "Check high view details about your project or machine and a quick check placement on the map.",
}: DashboardHeaderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { toggleBookmark, bookmarks } = useBookmark();
  const addOrRemoveBookmark = () => {
    toggleBookmark({
      bookmark: {
        id: data?.id,
        isDashboard: true,
        name: data?.name,
        type: data?.type,
        subtitle: computeSubTitle(
          data?.corporation?.name,
          data?.company?.name,
          data?.project?.name
        ),
      },
      onStart: () => setIsLoading(true),
      onSuccess: () => setIsLoading(false),
      onFailed: () => setIsLoading(false),
    });
  };
  const isBookmarked = !!bookmarks.filter(
    (item: any) => item.id === data?.id
  )[0];

  return (
    <div>
      <div style={styles.title}>{title}</div>
      <div style={styles.subtitle}>{subtitle}</div>
      <BookmarkButton
        isBookmarked={isBookmarked}
        text={isBookmarked ? "Bookmarked" : "Bookmark"}
        isLoading={isLoading}
        onClick={addOrRemoveBookmark}
      />
    </div>
  );
};
