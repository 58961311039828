import { Text } from "@fluentui/react";

type MarkdownTextProps = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
};

const MarkdownText = ({ text, ...rest }: MarkdownTextProps) => {
  return (
    <div className="markdown-text-container" {...rest}>
      <Text
        variant="medium"
        as="p"
        nowrap={false}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {text ?? ""}
      </Text>
    </div>
  );
};

export default MarkdownText;
