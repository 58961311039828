import type { IDropdownOption } from "@fluentui/react";
import { Dropdown, Spinner, SpinnerSize } from "@fluentui/react";
import { sortBy } from "lodash-es";
import React from "react";
import * as ReactRouterDOM from "react-router-dom";

import { apiService } from "../../../common";
import { useCompanyData } from "../../../../Components/Dashboards/DashboardIFramePage/hooks/useCompanyData";

import { dropdownStyles } from "./commonStyles";

const BreadcrumbItemProject = ({
  companyId,
  selectedProject,
  setSelectedProject,
}: {
  companyId: string;
  selectedProject: any;
  setSelectedProject: any;
}) => {
  const navigate = ReactRouterDOM.useNavigate();
  const location = ReactRouterDOM.useLocation();
  const { pathname } = location;
  const { company, isLoading, isFetched } = useCompanyData({
    id: companyId as string,
  });

  if (isLoading || !isFetched) {
    return <Spinner size={SpinnerSize.small} />;
  }

  const dropdownProjectOptions =
    (sortBy(company?.projects, ({ name }) => name?.toLowerCase()).map((m) => ({
      key: m.id,
      text: m.name,
      selected: m.id === selectedProject?.id,
    })) as IDropdownOption[]) ?? [];

  const handleDropdownChange = (_: any, o: any) => {
    setSelectedProject({
      id: o?.key as string,
      name: o?.text as string,
    });

    if (o?.key) {
      apiService.metaDataRead
        .getProject(o.key)
        .then((data) => {
          if (data && data?.machines && data?.machines.length > 0) {
            const sortedMachines = sortBy(data?.machines, ({ name }) =>
              name?.toLowerCase()
            );

            if (sortedMachines.length > 0) {
              const firstMachineId = sortedMachines[0].id;

              navigate(`${pathname}?id=${firstMachineId}`);
            }
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        options={dropdownProjectOptions}
        selectedKey={selectedProject?.key}
        styles={dropdownStyles}
        onChange={handleDropdownChange}
      />
    </React.Fragment>
  );
};
export default BreadcrumbItemProject;
