import { useMemo } from "react";

import {
  useTrendMeasuredDataInAPeriod,
  useSignalProjectTimezoneOffset,
} from "../../../Hooks";
import { CHART_NUMBER_OF_POINTS } from "../../analysis-trend-view/constants/controlOptions";
import getPeriodFilters from "../../analysis-trend-view/utils/getPeriodFilters";

import useHeaderControlsStore from "./useHeaderControlsStore";

interface UseSingleTrendMeasuredData {
  signal: any;
  queryEnabled: boolean;
}

const useSingleTrendViewMeasuredData = ({
  signal,
  queryEnabled,
}: UseSingleTrendMeasuredData) => {
  const tzOffset = useSignalProjectTimezoneOffset({ signal });

  const period = useHeaderControlsStore((store) => store.period);
  const periodFilters = getPeriodFilters(period, signal);

  const { data, isLoading, isFetching } = useTrendMeasuredDataInAPeriod(
    {
      startDate: periodFilters?.startDate,
      endDate: periodFilters?.endDate,
      machineId: signal?.machineId,
      signalId: signal?.id,
      numberOfPoints: CHART_NUMBER_OF_POINTS,
      signalType: signal?.dataType,
      onlyZip: true,
      refreshInterval: { key: "off", text: "Off" },
    },
    {
      enabled: queryEnabled,
    }
  );

  const memoizedData = useMemo(() => {
    return (
      data?.map(({ timeStamp, value }) => ({
        date: new Date(timeStamp).getTime() + tzOffset,
        value,
      })) || []
    );
  }, [data, tzOffset]);

  return { data: memoizedData, isLoading: isFetching && isLoading };
};

export default useSingleTrendViewMeasuredData;
