import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getApiClient,
  validateStatus,
} from "../../../../../modules/core/apiClient/useApiStore";

import type {
  CreateProjectTriggerRequest,
  CreateProjectTriggerResponse,
  ListProjectTriggersResponse,
} from "../../Sensor/types";

const TRIGGERS_ENDPOINT = "dws/v1/triggers/programmed/projects";

export const useAddProjectTrigger = () => {
  const { mutateAsync } = useMutation((data: CreateProjectTriggerRequest) =>
    getApiClient().post<CreateProjectTriggerResponse>(
      `${TRIGGERS_ENDPOINT}/${data.projectId}/triggers`,
      data,
      { validateStatus }
    )
  );

  return { addTriggerAsync: mutateAsync };
};

export const useListProjectTriggers = (projectId: string) => {
  const { data, isLoading, error, refetch } =
    useQuery<ListProjectTriggersResponse>(
      ["project-triggers", projectId],
      () =>
        getApiClient()
          .get(`${TRIGGERS_ENDPOINT}/${projectId}/triggers`, { validateStatus })
          .then((response) => response.data),
      {
        enabled: !!projectId,
      }
    );

  return {
    triggers: data?.triggers ?? [],
    isLoading,
    error,
    refetchTriggers: refetch,
  };
};
