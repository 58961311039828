import { treeHandlers } from 'react-hyper-tree';
import type { TreeNode } from 'react-hyper-tree/dist/helpers/node';

export const getNext = (node: TreeNode, nodes: any, handlers: any): TreeNode | null => {
  const parent = node.getParent();

  if (!parent) {
    // Is Year

    const nextYearIndex = nodes.findIndex(({ id }: any) => id === node.id) + 1;

    const yearId = nextYearIndex < nodes.length ? nodes[nextYearIndex].id : nodes[0].id;

    let year = handlers.getNode(yearId) as TreeNode;

    while (year.hasChildren()) {
      year = year.getChildren()?.[0];
    }

    return year;
  }

  const children = parent?.getChildren();

  if (children.length === 0) {
    return null;
  }

  const index = children.findIndex(({ id }) => id === node.id);

  if (index + 1 < children.length) {
    let next = children[index + 1];

    while (next.hasChildren()) {
      next = next.getChildren()?.[0];
    }

    return next;
  }

  return getNext(parent, nodes, handlers);
};

export const getPrev = (node: TreeNode, nodes: any, handlers: any): TreeNode | null => {
  const parent = node.getParent();

  if (!parent) {
    // Is Year
    const nextYearIndex = nodes.findIndex(({ id }: any) => id === node.id) - 1;

    const yearId = nextYearIndex >= 0 ? nodes[nextYearIndex].id : nodes[nodes.length - 1].id;

    let year = handlers.getNode(yearId) as TreeNode;

    while (year.hasChildren()) {
      const children = year.getChildren();
      year = children[children.length - 1];
    }

    return year;
  }

  const children = parent.getChildren();

  if (children.length === 0) {
    return null;
  }

  const index = children.findIndex(({ id }) => id === node.id);

  if (index - 1 >= 0) {
    let next = children[index - 1];

    while (next.hasChildren()) {
      const children = next.getChildren();
      next = children[children.length - 1];
    }

    return next;
  }

  return getPrev(parent, nodes, handlers);
};

export const stepHandler = async (
  isNext = true,
  lastSelectedNode: any,
  handlers: any,
  nodes: any,
  setLastSelectedNode: any,
  signalId: any,
) => {
  // We need to use current selected data point
  if (lastSelectedNode.nodeId?.length === 0) {
    return;
  }

  const node = handlers.getNode(lastSelectedNode.nodeId);

  if (node === null) {
    return;
  }

  const next = isNext ? getNext(node, nodes, handlers) : getPrev(node, nodes, handlers);

  if (next === null) {
    return;
  }

  // We need to set current selected data point

  setLastSelectedNode({
    ...lastSelectedNode,
    nodeId: next.id as string,
    timestamp: `${node.getParent()?.getData().id} ${node.getData().name}`,
  });

  node?.getParent()?.getParent()?.getParent()?.setOpened(false);
  node?.getParent()?.getParent()?.setOpened(false);
  node?.getParent()?.setOpened(false);

  await treeHandlers.trees[`datapoints_tree_${signalId}`].handlers.setSelectedByPath(
    next.getPath(),
    true,
  );
};
