import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'

import DashboardIcon from '../../../../assets/svg/DashboardIcon';
import ProjectIcon from '../../../../assets/svg/PlantIcon';

export type Mode = 'projects' | 'dashboards';

type NavigationArgs = {
  id: string;
  slug?: string;
  type?: string;
};

const markerColorFor = {
  projects: '#2c529f',
  dashboards: '#2c529f',
};

export const modeIcons = {
  projects: ProjectIcon,
  dashboards: DashboardIcon,
};

export const useModeSelect = createWithEqualityFn<{
  mode: Mode;
  getModeIcon: any;
  setDataMode: (mode: Mode) => void;
  getModeColor: () => string;
  getMarkerNavigationArgs: ({ id, slug, type }: NavigationArgs) => any;
}>()((set, get) => ({
  mode: 'projects',
  getModeIcon: () => modeIcons[get().mode],
  setDataMode: (mode: Mode) =>
    set(() => ({
      mode,
    })),
  getModeColor: () => {
    return markerColorFor[get().mode];
  },
  getMarkerNavigationArgs: ({ id, slug, type }: NavigationArgs) => {
    const dashboardType = type?.toLowerCase() || 'project';
    return get().mode === 'projects'
      ? { to: '/machine-cv', options: { state: slug } }
      : { to: `/dashboard/${dashboardType}/${id}`, options: {} };
  },
}), shallow);
