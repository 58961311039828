// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import OrganizationChart from "@dabeng/react-orgchart";
import { PrimaryButton, Spinner } from "@fluentui/react";
import { type CSSProperties, useEffect, useState } from "react";

import ChartCommandBar from "./ChartCommandBar";
import NodeComponent from "./NodeComponent";
import type { ConditionStatus } from "../../../../types";

export type TreeItem = {
  name?: string;
  icon?: any;
  condition?: ConditionStatus;
  children?: TreeItem[];
  disabled?: boolean;
};

type TreeButtonProps = {
  loading?: boolean;
  buttonText: string;
  onSend?: () => void;
};

type WirelessSensorsTreeProps = {
  data: TreeItem;
  title: string;
  onRefresh: () => void;
  buttonProps?: TreeButtonProps;
  chartStyle?: CSSProperties;
  isLoading?: boolean;
  split?: boolean;
};

function filterDisabledNodes(node: TreeItem): TreeItem | undefined {
  if (!node || Object.keys(node).length === 0 || node.children === undefined) {
    return {};
  }
  if (node.disabled) {
    return undefined;
  }
  return {
    ...node,
    children: node.children
      .map(filterDisabledNodes)
      .filter((child: any) => child !== undefined) as TreeItem[],
  };
}

function splitChildren(obj: TreeItem | undefined): TreeItem[] | undefined {
  if (!obj || Object.keys(obj).length === 0 || obj.children === undefined) {
    return undefined;
  }

  return obj.children.map((child: TreeItem) => {
    return {
      ...obj,
      children: [child],
    };
  });
}

function countChildrenAndGrandchildren(item: TreeItem): [number, number] {
  let childrenCount = 0;
  let grandchildrenCount = 0;

  if (item.children) {
    childrenCount = item.children.length;
    item.children.forEach((child) => {
      if (child.children) {
        grandchildrenCount += child.children.length;
      }
    });
  }

  return [childrenCount, grandchildrenCount];
}

const WirelessSensorsTree = ({
  data,
  title,
  onRefresh,
  buttonProps,
  chartStyle,
  isLoading,
  split = true,
}: WirelessSensorsTreeProps) => {
  const [object, setObject] = useState<TreeItem[]>();

  useEffect(() => {
    if (data || Object.keys(data).length != 0) {
      const filteredNodes = filterDisabledNodes(data);
      if (filteredNodes) {
        const [nNodes, nSubNodes] =
          countChildrenAndGrandchildren(filteredNodes);
        (nNodes > 10 || nSubNodes > 10) && split
          ? setObject(splitChildren(filteredNodes))
          : setObject([filteredNodes]);
      }
    }

    return;
  }, [data]);

  return (
    <div key={data.name + "-chart"} className="chart-container">
      <ChartCommandBar title={title} onRefresh={onRefresh} />
      <div className="chart-inner-container" style={chartStyle}>
        <div
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <>
            {!isLoading && object ? (
              object.map((item: any, idx: number) => (
                <>
                  <div style={{ height: "auto" }}>
                    <OrganizationChart
                      key={idx}
                      multilineNodes
                      collapsible={true}
                      datasource={item}
                      NodeTemplate={(nodeData: any) => (
                        <NodeComponent
                          {...nodeData}
                          style={{ padding: "10px" }}
                        />
                      )}
                      style={{ padding: "10px" }}
                    />
                  </div>
                </>
              ))
            ) : (
              <Spinner />
            )}
          </>
        </div>
      </div>
      {buttonProps && (
        <PrimaryButton
          text={buttonProps.buttonText}
          className="trigger-button"
          disabled={buttonProps.loading}
          onClick={buttonProps.onSend}
        />
      )}
    </div>
  );
};

export default WirelessSensorsTree;
