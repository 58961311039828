import { useMemo, useState, useEffect } from "react";

import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../common/Form";
import BaseDialog from "../common/Dialog";

import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { RequestSensorAdd, Sensor } from "./models";
import { ResponseMachineDetails } from "../Machines/models";
import { ComboBoxCustomV1, propsCustomComboBoxV1 } from "../Generic/ComboBoxV1";
import { addSensor, deleteSensor, editSensor } from "./api";
import { textStyle } from "../../schema/Constants";

const getSchema = () =>
  z.object({
    sensorId: z.string().optional().nullable(),
    sensorNo: z.string().optional().nullable(),
    sensorSerialNo: z.string().optional().nullable(),
    sensorDescription: z.string().optional().nullable(),
    mountingType: z.string().optional().nullable(),
    mountingDirection: z.string().optional().nullable(),
  });

const fields: FormItemProps[] = [
  {
    name: "sensorId",
    type: FormItemType.TextField,
    groupProps: { label: "Sensor Id" },
    disabled: true,
  },
  {
    name: "sensorNo",
    type: FormItemType.TextField,
    groupProps: { label: "Sensor No" },
  },
  {
    name: "sensorSerialNo",
    type: FormItemType.TextField,
    groupProps: { label: "Sensor Serial No" },
  },
  {
    name: "sensorDescription",
    type: FormItemType.TextField,
    groupProps: { label: "Description" },
  },
  {
    name: "mountingType",
    type: FormItemType.TextField,
    groupProps: { label: "Mounting Type" },
  },
  {
    name: "mountingDirection",
    type: FormItemType.TextField,
    groupProps: { label: "Mounting Direction" },
  },
];

type AddOrEditDialogProps = IDialogProps & {
  machineId: string;
  data: RequestSensorAdd | Sensor | string | undefined;
  machines?: ResponseMachineDetails[];
  show: boolean;
  onSuccess: (hasError: boolean, context: "add" | "edit" | "delete") => void;
  onClose: () => void;
  context: "add" | "edit" | "delete";
};

export const CUDDialog = ({
  machineId,
  data,
  machines,
  show,
  onSuccess,
  onClose,
  context,
  ...rest
}: AddOrEditDialogProps) => {
  const schema = useMemo(() => getSchema(), []);

  const [isLoading, setLoading] = useState(false);
  const [idSelected, setIdSelected] = useState<string>(
    data && typeof data !== "string" && "id" in data && data?.machineId
      ? data?.machineId
      : ""
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data &&
      typeof data !== "string" && {
        defaultValues: {
          sensorId: "id" in data ? data.id : "",
          sensorNo: data?.sensorNo,
          sensorSerialNo: data?.sensorSerialNo,
          sensorDescription: data?.sensorDescription,
          mountingType: data?.mountingType,
          mountingDirection: data?.mountingDirection,
        },
      }),
  });

  useEffect(() => {
    data && typeof data !== "string"
      ? reset({
          sensorId: "id" in data ? data.id : "",
          sensorNo: data?.sensorNo,
          sensorSerialNo: data?.sensorSerialNo,
          sensorDescription: data?.sensorDescription,
          mountingType: data?.mountingType,
          mountingDirection: data?.mountingDirection,
        })
      : reset({
          sensorId: "",
          sensorNo: "",
          sensorSerialNo: "",
          sensorDescription: "",
          mountingType: "",
          mountingDirection: "",
        });
  }, [data, reset]);

  const argsIdField: propsCustomComboBoxV1 = {
    keyMe: 30,
    label: "Select Machine (From same project) * ",
    options: machines
      ?.map((mach) => ({
        key: mach.id,
        text: mach.name,
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    selection: setIdSelected,
    errorMessage: idSelected ? "" : "Select a Machine",
    defaultKey: idSelected,
  };

  const textFComp = ComboBoxCustomV1(argsIdField);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: RequestSensorAdd = {
      sensorNo: formData.sensorNo,
      sensorSerialNo: formData.sensorSerialNo,
      sensorDescription: formData.sensorDescription,
      mountingType: formData.mountingType,
      mountingDirection: formData.mountingDirection,
    };

    switch (context) {
      case "add":
        await addSensor(machineId, toSend).then((response) =>
          onSuccess("status" in response, context)
        );
        break;
      case "edit":
        await editSensor(
          machineId,
          typeof data !== "string" && "id" in data ? data?.id : "",
          typeof data !== "string" &&
            "id" in data && { id: data?.id, machineId: idSelected, ...toSend }
        ).then((response) => onSuccess("status" in response, context));
        break;
      case "delete":
        await deleteSensor(machineId, typeof data === "string" && data).then(
          (response) => onSuccess("status" in response, context)
        );
        break;
    }

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {context && (
        <BaseDialog
          {...rest}
          hidden={!show}
          dialogContentProps={{
            type: DialogType.normal,
            title:
              context.charAt(0).toUpperCase() + context.slice(1) + " Sensor ",
            closeButtonAriaLabel: "Close",
            onDismiss: handleClose,
          }}
        >
          {["add", "edit"].includes(context) ? (
            <form onSubmit={onSubmit}>
              {context === "edit" ? textFComp : <></>}
              {renderFormItems(data ? fields : fields.slice(1), {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}
              <DialogFooter>
                <PrimaryButton
                  type="submit"
                  text="Save Changes"
                  disabled={isLoading || !isValid || idSelected === undefined}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton
                  styles={{
                    root: { border: "unset", background: "transparent" },
                  }}
                  text="Cancel"
                  onClick={handleClose}
                />
              </DialogFooter>
            </form>
          ) : ["delete"].includes(context) ? (
            <>
              <div style={textStyle}>
                You are about to delete selected Sensor.
              </div>

              <DialogFooter>
                <PrimaryButton
                  text="Delete"
                  disabled={isLoading}
                  onClick={onSubmit}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton text="Close" onClick={handleClose} />
              </DialogFooter>
            </>
          ) : (
            <></>
          )}
        </BaseDialog>
      )}
    </>
  );
};
