export const computeSubTitle = (corporationName: any, companyName: any, projectName: any) => {
  let result = '';

  if (corporationName) {
    result = corporationName;
  }

  if (companyName) {
    result = result ? `${result} | ${companyName}` : companyName;
  }

  if (projectName) {
    result = result ? `${result} | ${projectName}` : projectName;
  }

  return result;
};
