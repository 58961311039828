import { useQuery } from "@tanstack/react-query";

import { getApiClient } from "../../../modules/core/apiClient/useApiStore";

interface UseConditionOverviewArgs {
  machineId: string;
}

const useSensors = (args: UseConditionOverviewArgs) => {
  const {
    data = [],
    isLoading,
    isError,
  } = useQuery(["sensors-of-machine", args.machineId], () =>
    getApiClient()
      .get(`/meta/read/v1/machines/${args.machineId}/sensors`)
      .then((data) => data)
  );

  return {
    sensors: data,
    isLoading,
    isError,
  };
};

export default useSensors;
