import type {
  IComboBox,
  IComboBoxOption,
  IToggleStyles,
} from "@fluentui/react";
import {
  ActionButton,
  ComboBox,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  Toggle,
} from "@fluentui/react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import type { FormEvent } from "react";
import React, { useEffect, useMemo, useState } from "react";

import AlertsIcon from "../../../../assets/svg/AlertsIcon";
import {
  AppBreakpoints,
  useBreakpoint,
  useMaximize,
  useUniqueId,
} from "../../../../Hooks";

import { maximizeButton } from "../../../analysis-raw-data/styles/ChartSection.styles";
import { useTrendViewMachineAlerts } from "./useTrendViewAlerts";
import useAlertsStore from "../../../analysis-trend-view/hooks/useAlertsStore";
import type { Condition } from "../../../machine-cv/constants";
import { conditionColor, conditionFormat } from "../../../machine-cv/constants";
import { useWebAnalysisPermissions } from "../../../../Hooks";
import { dropdownIconStyle } from "../../../../assets/styles";
import type { ResponseSimplifiedSignal } from "../../../../types";
import {
  comboBoxStyles,
  onRenderLabel,
} from "../../../../Components/common/CommandBar/methods";
import type { XYChartStyles } from "../../../../Components/common/XYChart/XYChart";

import type { Range } from "../../hooks/useControlsStore";
import useControlsStore from "../../hooks/useControlsStore";
import useSignalMetaData from "../../hooks/useSignalMetaData";
import useSignalMonitoring from "../../hooks/useSignalMonitoring";
import useStatistics from "../../hooks/useStatistics";
import {
  TrendViewInfoHorizontal,
  TrendViewInfoVertical,
} from "../TrendViewInfo";
import TrendViewPlot from "../TrendViewPlot";
import {
  TrendViewPlotNoData,
  TrendViewPlotSpinner,
} from "../TrendViewPlotPlaceholders";

interface TrendViewSectionHeaderProps {
  noTitle: boolean;
  noStatistics: boolean;
  noMaximize: boolean;
}

interface TrendViewSectionProps {
  signal: ResponseSimplifiedSignal;
  headerProps?: TrendViewSectionHeaderProps;
  chartStyles?: XYChartStyles;
  disableReloadOnZoom?: boolean;
  showConditionStatus?: boolean;
}

const badgeContainerStyles = {
  padding: "4px 16px",
  minHeight: 36,
  minWidth: 36,
  display: "flex",
  alignItems: "center",
  width: "100%",
};

const toggleStyles: Partial<IToggleStyles> = {
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    margin: "0 0 0 8px",
  },
  label: {
    marginLeft: "8px",
    fontWeight: "normal",
  },
};

const alertsComboBoxOptions = [
  { key: "indicationLow", text: "Indication Lower Limit" },
  { key: "indicationHigh", text: "Indication Upper Limit" },
  { key: "alertLow", text: "Alert Lower Limit" },
  { key: "alertHigh", text: "Alert Upper Limit" },
  { key: "dangerLow", text: "Danger Lower Limit" },
  { key: "dangerHigh", text: "Danger Upper Limit" },
];

const TrendViewSection: React.FC<TrendViewSectionProps> = ({
  signal,
  headerProps = {},
  chartStyles,
  disableReloadOnZoom,
  showConditionStatus = true,
}) => {
  const { noTitle, noStatistics, noMaximize } =
    headerProps as TrendViewSectionHeaderProps;
  const {
    referenceSignalId,
    connectedTimeRange,
    setConnectedTimeRange,
    period,
    loadingCharts,
    averageTrend,
  } = useControlsStore((state) => ({
    loadingCharts: state.loadingCharts,
    period: state.period,
    referenceSignalId: state.referenceSignal?.key,
    connectedTimeRange: state.connectedTimeRange,
    setConnectedTimeRange: state.setConnectedTimeRange,
    averageTrend: state.averageTrend,
  }));

  const { useReadyPermissionGeneral } = useWebAnalysisPermissions();
  const hasWirelessSensorReaderPermissions = useReadyPermissionGeneral({
    permissionRoles: ["WirelessSensorReader", "WirelessSensorContributor"],
  });

  const { updateAlertsOptions, alertsOptions } = useAlertsStore((state) => ({
    updateAlertsOptions: state.updateAlertsOptions,
    alertsOptions: state.alertsOptions,
  }));

  const uniqueId = useUniqueId();

  const {
    maximizeAction,
    maximizeIcon,
    maximizeLabel,
    isChartMaximized,
    isChartHidden,
  } = useMaximize({ id: uniqueId, page: "trend-view" });

  const { corporation, company, project, machine } = useSignalMetaData(signal);

  const {
    isLoading,
    isSuccessReferenceSignal,
    signalPlotAxis,
    referenceSignalPlotAxis,
  } = useSignalMonitoring(signal);

  const signalId = useMemo(
    () => (averageTrend && signal?.p12h ? signal?.p12h?.id : signal.id),
    [averageTrend]
  );

  const { data: alertsData = {}, isSuccess } = useTrendViewMachineAlerts(
    {
      machineId: signal.machineId as string,
      signalId,
    },
    { enabled: !!signalId }
  );

  const [selectedRange, setSelectedRange] = useState<Range>({
    startIndex: 0,
    endIndex: signalPlotAxis.x.length - 1,
  });

  const setRange = (selectedIndexes: Range, selectedTimes: Range) => {
    setSelectedRange(selectedIndexes);
    setConnectedTimeRange({ range: selectedTimes, signalId: signal.id ?? "" });
  };

  useEffect(() => {
    if (isSuccess) {
      const machineHighLowAlerts = ["alertHigh", "alertLow"].filter(
        (alertType) => alertsData?.[alertType]
      );
      if (machineHighLowAlerts.length > 0) {
        updateAlertsOptions(signalId as string, machineHighLowAlerts);
      }
    }
  }, [isSuccess, signalId]);

  useEffect(() => {
    setSelectedRange({ startIndex: 0, endIndex: signalPlotAxis.x.length - 1 });
  }, [signalPlotAxis.x]);

  const { trendViewRawDetails, referenceSignalRawDetails } = useStatistics({
    signalPlotAxis,
    referenceSignalPlotAxis,
    selectedRange,
    isLoading,
    isSuccessReferenceSignal,
  });

  const [isRawDetailsOpen, setIsRawDetailsOpen] = useState<boolean | undefined>(
    false
  );

  const breakpoint = useBreakpoint();

  const { data: signalsList } = useQuery(
    ["signals-list"],
    () => [] as ResponseSimplifiedSignal[],
    {
      enabled: false,
    }
  );

  const referenceSignal = signalsList?.find(
    (item) => item.id === referenceSignalId
  );

  if (isLoading) {
    return <TrendViewPlotSpinner />;
  }

  if (signalPlotAxis.x.length === 0) {
    return <TrendViewPlotNoData signal={signal} />;
  }

  return (
    <div
      className={classNames("trend-view-section", {
        loading: loadingCharts,
        "trend-view-chart-section--hidden": isChartHidden,
        "trend-view-chart-section--maximized": isChartMaximized,
      })}
    >
      <Stack style={{ width: "100%" }}>
        {loadingCharts && (
          <Spinner
            className="loading-chart-data"
            size={SpinnerSize.large}
            label="Loading data..."
          />
        )}
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal verticalAlign="center">
            {!noTitle && (
              <Text
                style={{
                  borderRight: "1px solid rgb(200, 198, 196)",
                  paddingRight: "16px",
                  marginLeft: "16px",
                  marginRight: "16px",
                  fontWeight: 600,
                }}
              >
                Trend View
              </Text>
            )}
            <ComboBox
              className="layout-dropdown secondary-dropdown"
              styles={comboBoxStyles} // TODO: allow changing styles via prop (dropdown impl)
              calloutProps={{ calloutWidth: undefined, calloutMinWidth: 200 }}
              multiSelect={true}
              selectedKey={alertsOptions[signal.id as string]}
              options={alertsComboBoxOptions}
              onRenderLabel={() =>
                onRenderLabel({
                  label: "Alerts",
                  icon: <AlertsIcon style={dropdownIconStyle} />,
                })
              }
              onChange={(
                _: FormEvent<IComboBox>,
                option: IComboBoxOption | undefined
              ) => {
                const alerts = alertsOptions[signal.id as string] || [];

                updateAlertsOptions(
                  signal.id as string,
                  alerts.includes(option?.key as string)
                    ? alerts.filter(
                        (alertOption) => alertOption !== option?.key
                      )
                    : [...alerts, option?.key as string]
                );
              }}
            />
            {hasWirelessSensorReaderPermissions &&
              signal.sensorSerialNo &&
              signal.sensorSerialNo.trim() !== "" && (
                <StackItem>
                  <strong>Serial No: {signal.sensorSerialNo}</strong>
                </StackItem>
              )}
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 16 }}>
            {!noStatistics && (
              <Toggle
                checked={isRawDetailsOpen}
                label="Statistics"
                styles={toggleStyles}
                onChange={(_, checked) => setIsRawDetailsOpen(checked)}
              />
            )}
            {!noMaximize && (
              <ActionButton
                allowDisabledFocus
                styles={maximizeButton}
                onClick={maximizeAction}
              >
                {maximizeIcon}
                <span>{maximizeLabel}</span>
              </ActionButton>
            )}
            <div
              className={`export-${machine?.id}-${signal.id}`}
              style={{ marginRight: 16 }}
            />

            {showConditionStatus ? (
              <Stack
                horizontal
                horizontalAlign="center"
                style={{
                  ...badgeContainerStyles,
                  width: 200,
                  backgroundColor:
                    conditionColor[signal.condition as Condition],
                }}
              >
                <Text style={{ color: "#fff" }}>
                  {conditionFormat[signal.condition as Condition]}
                </Text>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
        <TrendViewPlot
          isAverageTrend={averageTrend}
          loadingCharts={loadingCharts}
          selectedPeriod={period}
          connectedTimeRange={connectedTimeRange}
          setSelectedRange={setRange}
          signalPlotAxis={signalPlotAxis}
          referenceSignalPlotAxis={referenceSignalPlotAxis}
          referenceSignal={referenceSignal}
          hasReferenceSignal={
            isSuccessReferenceSignal &&
            signal.machineId === referenceSignal?.machineId &&
            referenceSignalPlotAxis?.x.length > 0
          }
          signalId={signalId}
          corporation={corporation}
          company={company}
          project={project}
          machine={machine}
          signal={signal}
          exportSelector={`export-${machine?.id}-${signal.id}`}
          chartStyles={chartStyles}
          disableReloadOnZoom={disableReloadOnZoom}
        />
      </Stack>

      {isRawDetailsOpen &&
        trendViewRawDetails &&
        breakpoint === AppBreakpoints.LaptopLarge && (
          <TrendViewInfoVertical
            rawDetails={trendViewRawDetails}
            referenceSignalRawDetails={referenceSignalRawDetails}
          />
        )}

      {isRawDetailsOpen &&
        trendViewRawDetails &&
        breakpoint !== AppBreakpoints.LaptopLarge && (
          <TrendViewInfoHorizontal
            referenceSignalRawDetails={referenceSignalRawDetails}
            rawDetails={trendViewRawDetails}
          />
        )}
    </div>
  );
};

export default TrendViewSection;
