import type { IPersonaProps } from "@fluentui/react";
import { IconButton, Persona, PersonaSize, Stack } from "@fluentui/react";
import classNames from "classnames";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";

import {
  useLocationSearch,
  useMachineUsersQuery,
  useWebAnalysisPermissions,
} from "../../../../../Hooks";

import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import { CommentDialog } from "./CommentDialog";
import type { MachineCVComment } from "../../../../../types";

import { useCommentsQuery } from "../../hooks/useCommentsQuery";
import useTagsStore from "../../hooks/useTagsStore";
import { Tag } from "../Tags/Tag";
import Files from "./Files";

const postedAt = (date: string) => {
  const daysDiff = dayjs().diff(date, "days");
  const hoursAndMinutes = dayjs(date).format("HH:mm");

  if (!daysDiff) {
    return `today at ${hoursAndMinutes}`;
  }

  if (daysDiff === 1) {
    return `yesterday at ${hoursAndMinutes}`;
  }

  const formattedDate = dayjs(date).format("YYYY-MM-DD");

  return (
    <Stack horizontal className="date-posted">
      <div>{formattedDate}</div>
      <div>{hoursAndMinutes}</div>
    </Stack>
  );
};

type MachineCVCommentProps = {
  comment: MachineCVComment;
};

export const Comment = ({ comment }: MachineCVCommentProps) => {
  const [{ id }, ,] = useLocationSearch();
  const { refetch } = useCommentsQuery({ machineId: id as string });
  const { usersList, usersPersonas } = useMachineUsersQuery({
    machineId: id as string,
  });
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [showReplyDialog, setShowReplyDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { useReadyPermission, checkCreatorUserIDMatch } =
    useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });
  const { addSelectedTag } = useTagsStore((store) => ({
    addSelectedTag: store.addSelectedTag,
  }));

  const hasEditDeleteActions =
    hasMachineCvAdministratorPermission ||
    checkCreatorUserIDMatch(comment?.createdById || "");

  const user = useMemo(() => {
    const userPersona = usersPersonas.find(
      (user) => user.text === comment.createdBy
    );

    return {
      text: userPersona?.text || comment.createdBy,
      createdAt: comment.createdAt,
    };
  }, [usersPersonas]);

  const isDalogMember = usersList.find(
    ({ email, displayName }) =>
      user?.text === displayName && email.includes("@dalog.net")
  );

  const avatarProps: IPersonaProps = {
    ...user,
    size: PersonaSize.size32,
    onRenderPrimaryText: () => null,
    hidePersonaDetails: true,
  };

  const actionOptions = [
    // Temporary removed Reply functionality
    // {
    //   key: 'reply',
    //   text: 'Reply',
    //   onClick: () => setShowReplyDialog(true),
    // },
  ];

  if (hasEditDeleteActions) {
    actionOptions.push(
      {
        key: "edit",
        text: "Edit",
        onClick: () => setShowCommentsDialog(true),
      },
      {
        key: "delete",
        text: "Delete",
        onClick: () => setShowDeleteDialog(true),
      }
    );
  }

  return (
    <>
      <Stack
        className={classNames("comment", {
          reverse: isDalogMember,
        })}
      >
        <Stack horizontal verticalAlign="center" className="avatar-details">
          {isDalogMember ? <div className="dalog-tag">DALOG</div> : null}

          <div>{user?.text}</div>
          <div>{postedAt(comment.createdAt)}</div>
        </Stack>

        <Stack className="comment-content">
          <Persona {...avatarProps} />
          <Stack horizontal className="comment-text-wrap">
            <Stack className="comment-text">
              {comment.tags.length > 0 ? (
                <div className="tags-comment-container">
                  {comment.tags.map((tag: any, idx) => (
                    <Tag
                      key={idx}
                      name={tag.name}
                      id={tag.id}
                      onTagClick={addSelectedTag}
                    />
                  ))}
                </div>
              ) : null}

              {comment.text}

              {comment.files.length !== 0 && <Files files={comment.files} />}
            </Stack>

            {actionOptions?.length > 0 ? (
              <IconButton
                iconProps={{
                  iconName: "MoreVertical",
                }}
                styles={{
                  root: {
                    height: 26,
                    padding: 0,
                  },
                }}
                menuProps={{ items: actionOptions }}
                menuIconProps={{ style: { display: "none" } }}
              />
            ) : null}
          </Stack>
        </Stack>
      </Stack>

      <CommentDialog
        comment={comment}
        hidden={!showCommentsDialog}
        onDismiss={() => setShowCommentsDialog(false)}
      />
      <CommentDialog
        isReply={true}
        comment={{ ...comment, text: `Reply to:\n${comment.text}` }}
        hidden={!showReplyDialog}
        onDismiss={() => setShowReplyDialog(false)}
      />
      <DeleteDialog
        dialogContentProps={{
          onDismiss: () => setShowDeleteDialog(false),
        }}
        data={{
          ids: [comment.id],
          name: "1 comment",
        }}
        hidden={!showDeleteDialog}
        route={`/meta/machinecv/v1/comments`}
        onSuccess={refetch}
      />
    </>
  );
};
