import {
  DefaultPalette,
  IPersonaStyles,
  Persona,
  PersonaSize,
} from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import {
  extract4Initials,
  getObjectUrlFromBase64,
  mapInitialsToNumber,
} from "../../schema/Utils";
import { selectUserAccountInitialProfilePicture } from "../UserAccount/selectors";
import { useAppSelector } from "../../Hooks";
import { IsClickedAccountIconContext, LocalAccountInfo } from "./context";

const styles: IPersonaStyles = {
  root: {
    cursor: "pointer",
  },
  details: {
    cursor: "default",
  },
  primaryText: {
    color: DefaultPalette.white,
    cursor: "default",
  },
  secondaryText: {
    cursor: "default",
  },
  tertiaryText: {
    cursor: "default",
  },
  optionalText: {
    cursor: "default",
  },
  textContent: {
    cursor: "default",
  },
};

const Account = ({ ...rest }: React.HTMLAttributes<HTMLElement>) => {
  const [keyChange, setKeyChange] = useState(1);

  const isClickedAccountIcon = useContext(IsClickedAccountIconContext);
  const myAccount = useContext(LocalAccountInfo);

  const profilePicture = useAppSelector(selectUserAccountInitialProfilePicture);

  useEffect(() => {
    setKeyChange((prev) => prev + 1);
  }, [myAccount?.val?.pictureBase64]);

  function OnClickHandle() {
    return isClickedAccountIcon ? isClickedAccountIcon((prev) => !prev) : null;
  }

  return (
    <Persona
      {...rest}
      key={keyChange}
      styles={styles}
      size={PersonaSize.size32}
      onClick={OnClickHandle}
      imageInitials={extract4Initials(myAccount?.val?.displayName)}
      initialsColor={mapInitialsToNumber(
        extract4Initials(myAccount?.val?.displayName)
      )}
      imageUrl={
        profilePicture ? getObjectUrlFromBase64(profilePicture) : undefined
      }
      hidePersonaDetails={true}
    />
  );
};

export default Account;
