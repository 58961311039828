import type { ICommandBarStyles, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import {
  getDetailsColumnStyles,
  getDetailsHeaderStyles,
  getDetailsRowCheckStyles,
  getTheme,
  mergeStyleSets,
} from '@fluentui/react';
import classnames from 'classnames';
import { useCallback, useMemo } from 'react';

import type { CommandBarItemProps } from '../CommandBar';
import BaseCommandBar, { CommandBarItemType, computeCommandBarItems } from '../CommandBar';
import type { Type } from './export';
import { save } from './export';
import type { TableProps } from './Table';

const theme = getTheme();

const headerClassNames = mergeStyleSets(getDetailsHeaderStyles({ theme }));

const rowClassNames = mergeStyleSets(
  getDetailsRowCheckStyles({ theme, compact: false, isHeader: true, canSelect: true }),
);

const columnClassNames = mergeStyleSets(
  getDetailsColumnStyles({
    theme,
    isActionable: false,
    cellStyleProps: {
      cellLeftPadding: 12,
      cellRightPadding: 8,
      cellExtraRightPadding: 8,
    },
  }),
);

const commandBarStyles: ICommandBarStyles = {
  root: {
    margin: 0,
    padding: 0,
    background: '#f3f2f1',
  },
};

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdownItemSelected: {
    background: 'inherit',
  },
  title: {
    visibility: 'hidden',
    width: 0,
  },
};

const exportOptions: IDropdownOption<Type>[] = [
  { key: 'csv', text: 'CSV', data: 'csv' },
  { key: 'xlsx', text: 'Excel', data: 'xlsx' },
];

type HeaderProps = {
  title: string;
  hasSelection: boolean;
  exportDisabled: boolean;
  data: unknown[];
  columns: TableProps['columns'];
  isExportable?: boolean;
};

const Header = ({
  title,
  hasSelection,
  exportDisabled,
  data,
  columns,
  isExportable = true,
}: HeaderProps) => {
  const selection = hasSelection && (
    <div className={headerClassNames.cellIsCheck}>
      <div className={classnames(rowClassNames.check, headerClassNames.check)} />
    </div>
  );

  const handleSave = useCallback(
    (type: Type) => {
      save({ filename: title, type, data, columns });
    },
    [data, columns],
  );

  const items: CommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'title',
        type: CommandBarItemType.Custom,
        onRender: () => <span className={columnClassNames.cellName}>{title}</span>,
      },
    ],
    [],
  );

  const farItems: CommandBarItemProps[] = useMemo(() => {
    const result = [];
    if (isExportable) {
      result.push({
        key: 'export',
        type: CommandBarItemType.Dropdown,
        onRenderProps: {
          disabled: exportDisabled || data.length === 0,
          options: exportOptions,
          styles: dropdownStyles,
          notifyOnReselect: true,
          onChange: (_: never, item: IDropdownOption<Type>) => {
            handleSave(item.data as Type);
          },
          onRenderLabelProps: {
            label: 'Export',
            icon: 'CloudAdd',
          },
        },
      });
    }
    return result;
  }, [exportDisabled, data.length, handleSave, isExportable]);

  return (
    <div role='row' className={classnames('table-header', headerClassNames.root)}>
      {selection}
      <div className={classnames('table-header__column', columnClassNames.root)}>
        <BaseCommandBar
          items={computeCommandBarItems(items)}
          farItems={computeCommandBarItems(farItems)}
          styles={commandBarStyles}
        />
      </div>
    </div>
  );
};

export default Header;
