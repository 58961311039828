import type { SVGProps } from 'react';
import React from 'react';

const CommentsIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='#ffffff'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M8.47998 10.842H13.4C14.296 10.842 15 10.129 15 9.276V2.566C15 1.713 14.296 1 13.4 1H2.59998C1.70398 1 0.999977 1.713 0.999977 2.566V9.276C0.999977 10.129 1.70398 10.842 2.59998 10.842H4.19998V14H4.20298L4.20498 13.999L8.48098 10.842H8.47998ZM4.79998 14.803C4.5871 14.9599 4.32112 15.027 4.05929 14.9898C3.79745 14.9526 3.5607 14.814 3.39998 14.604C3.27114 14.4343 3.20126 14.2271 3.20098 14.014V11.842H2.60098C1.16498 11.842 0.000977206 10.693 0.000977206 9.276V2.566C-2.27937e-05 1.149 1.16398 0 2.59998 0H13.4C14.836 0 16 1.149 16 2.566V9.276C16 10.694 14.836 11.842 13.4 11.842H8.80998L4.79898 14.803H4.79998Z' />
    </svg>
  );
};

export default CommentsIcon;
