import { DialogType, IDialogContentProps } from "@fluentui/react";
import { PropsWithChildren } from "react";

import BaseDialog, { DialogSize } from "../../../common/Dialog";

type FormDialogProps = {
  onClose: () => void;
  title: string;
  size: DialogSize;
};

/**
 * Gets the Basic Dialog
 * @param title The dialog title
 * @param size The dialog size
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @param children The component's children.
 * @returns The Basic Dialog component.
 */
const FormDialog = ({
  title,
  size,
  onClose,
  children,
}: PropsWithChildren<FormDialogProps>) => {
  const dialogContentProps: IDialogContentProps = {
    type: DialogType.close,
    isMultiline: true,
    title: title,
    closeButtonAriaLabel: "Close",
    onDismiss: onClose,
  };

  return (
    <BaseDialog
      hidden={false}
      dialogContentProps={dialogContentProps}
      size={size}
    >
      {children}
    </BaseDialog>
  );
};

export default FormDialog;
