import { max, mean, min } from 'lodash-es';

import { getStandardDeviation, roundNumber } from '../../../utils';

import type { TrendViewRawDetails } from '../types';

interface GetStatisticsArgs {
  selectedRange: {
    startIndex: number;
    endIndex: number;
  };
  data: string[];
}

const getStatistics = ({ selectedRange, data }: GetStatisticsArgs) => {
  const allValues: number[] = data.map((item) => Number(item));
  const allValuesCount = allValues.length;
  const rangeValues = allValues.slice(selectedRange.startIndex, selectedRange.endIndex + 1);
  const rangeValuesCount = rangeValues.length;

  const rawDetails: TrendViewRawDetails = {
    all: {
      last: roundNumber(allValues[allValuesCount - 1], 2),
      average: roundNumber(mean(allValues), 2),
      maximum: roundNumber(max(allValues)!, 2),
      minimum: roundNumber(min(allValues)!, 2),
      stDev: roundNumber(getStandardDeviation(allValuesCount, allValues), 2),
      rCorr: NaN,
    },
    selectedRange: {
      last: roundNumber(rangeValues[rangeValuesCount - 1], 2),
      average: roundNumber(mean(rangeValues), 2),
      maximum: roundNumber(+max(rangeValues)!, 2),
      minimum: roundNumber(+min(rangeValues)!, 2),
      stDev: roundNumber(getStandardDeviation(rangeValuesCount, rangeValues), 2),
      rCorr: NaN,
    },
  };

  return rawDetails;
};

export default getStatistics;
