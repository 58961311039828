import { Stack, Text } from "@fluentui/react";

import { topControlsStyles } from "../../../../modules/analysis-trend-view/components/TrendViewControls/TrendViewControls";

const styles = {
  headerText: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    minHeight: 30,
  },
};

const WirelessSensorsLayoutHeader = () => {
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={topControlsStyles}
      id="top-controls"
    >
      <Stack horizontal className="ws-sidebar-header">
        <Text style={styles.headerText}>Sensor Configurator</Text>
      </Stack>
    </Stack>
  );
};

export default WirelessSensorsLayoutHeader;
