/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  selectMachinesToList,
  selectMachinesStatus,
  listAsyncMachines,
} from "../Components/Machines/reducer";
import { useAppDispatch, useAppSelector } from "../Hooks";
import { Status } from "../schema/status";

export const useMachines = () => {
  const machines = useAppSelector(selectMachinesToList);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const dispatch = useAppDispatch();

  // Gets the machines list.
  useEffect(() => {
    if (machineStatus !== Status.void) {
      return;
    }

    machineStatus === Status.void && dispatch(listAsyncMachines());
  }, [machineStatus]);

  return machines;
};
