import { Text } from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import classNames from "classnames";

import ReferenceIcon from "../../../../assets/svg/ReferenceIcon";
import SignalsAutocomplete from "../../../common/components/SignalsAutocomplete";

import TrendGroupingEnum from "../../constants/TrendGroupingEnum";
import {
  buttonStyle,
  referenceTextStyles,
  signalAutocompleteStyles,
} from "./TrendView.styles";
import useTrendViewControls from "./useTrendViewControls";

const ReferenceSignalDropdown = () => {
  const {
    data,
    controlsStore,
    selectedSignalsStore: { selectedSignals },
  } = useTrendViewControls();

  const isReferenceSignalDisabled =
    selectedSignals.length === 0 ||
    controlsStore.trendsGrouping?.key !== TrendGroupingEnum.SEPARATED;

  return (
    <div
      style={signalAutocompleteStyles}
      className={classNames("secondary-dropdown", {
        disabled: isReferenceSignalDisabled,
      })}
    >
      <ReferenceIcon
        fill="#2c529f"
        style={{ marginRight: 5, width: 17, height: 17 }}
      />
      <SignalsAutocomplete
        key={data?.length}
        hasNoneItem
        disabled={isReferenceSignalDisabled}
        style={buttonStyle}
        items={data}
        onSelectSignal={(signal) => {
          let result = undefined;
          if (signal) {
            result =
              (data || []).filter((item) => item.id === signal.id)[0] || {};
          }
          const payload = signal
            ? {
                ...result,
                key: signal.id,
                text: signal.label,
              }
            : undefined;

          controlsStore.changeReferenceSignal(payload);
        }}
      >
        <Stack
          horizontal
          style={isReferenceSignalDisabled ? { color: "#A19F9D" } : {}}
        >
          <span style={{ fontWeight: "normal" }}>Reference</span>
          <Text style={referenceTextStyles}>
            {controlsStore.referenceSignal?.text || "None"}
          </Text>
        </Stack>
      </SignalsAutocomplete>
    </div>
  );
};

export default ReferenceSignalDropdown;
