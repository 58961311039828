import "../../../MachineCVTasksPage/components/TaskEdit/style.scss";

import type { IDialogProps } from "@fluentui/react";
import { DefaultButton, DialogFooter, DialogType } from "@fluentui/react";
import React, { useState } from "react";

import { useLocationSearch, useSearch } from "../../../../../Hooks";
import AddTagDialog from "./AddTagDialog";
import { CommandBar } from "./CommandBar";
import { Tag } from "./Tag";
import { useTagsQuery } from "../../hooks/useTagsQuery";
import BaseDialog from "../../../../common/Dialog";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";

const dialogContentProps = {
  type: DialogType.normal,
  title: "Manage tags",
  closeButtonAriaLabel: "Close",
};

export const TagsDialog = (props: IDialogProps) => {
  const [{ id: machineId }, ,] = useLocationSearch();

  const { tags, isError, isLoading } = useTagsQuery({
    machineId: machineId as string,
  });
  const [showAddTagsDialog, setShowAddTagsDialog] = useState(false);

  const { data, handleSearch } = useSearch({
    data: tags,
    keys: ["name"],
  });

  const onClose = () => {
    props.onDismiss?.();
  };

  return (
    <>
      <BaseDialog {...props} dialogContentProps={dialogContentProps}>
        {isLoading && <Spin style={{ marginTop: "50px" }} />}
        {isError && <NoData style={{ marginTop: "50px" }} />}
        <CommandBar
          handleSearch={handleSearch}
          onAdd={() => setShowAddTagsDialog(true)}
        />
        <div className="tags-dialog-container">
          {data.map((tag, idx) => (
            <Tag key={idx} hasDelete name={tag.name} id={tag.id as string} />
          ))}
        </div>
        <DialogFooter>
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text="Cancel"
            onClick={onClose}
          />
        </DialogFooter>
      </BaseDialog>
      <AddTagDialog
        hidden={!showAddTagsDialog}
        onDismiss={() => setShowAddTagsDialog(false)}
      />
    </>
  );
};
