// Original path: src/Components/common/Uploader/Uploader.tsx
import uniqueId from "lodash-es/uniqueId";
import type { PropsWithChildren, ReactElement } from "react";
import React, {
  Children,
  cloneElement,
  isValidElement,
  useRef,
  useState,
} from "react";

import { format } from "../../../utils";

import UploaderButton from "./UploaderButton";

const FILE_MAX_SIZE = 50_000_000; // 50 MB

const getButton = (
  children: PropsWithChildren["children"],
  onClick: () => void
) => {
  const validChildren = Children.toArray(children).filter((child) =>
    isValidElement(child)
  ) as ReactElement[];

  if (Children.count(validChildren) !== 1) {
    return <UploaderButton onClick={onClick} />;
  }

  return cloneElement(validChildren[0], { onClick });
};

export type AcceptedFile = {
  id: string;
  name: string;
  type: string;
  size: number;
  isValid: boolean;
  dateAdded: string;
  file: File;
};

export type UploaderProps = PropsWithChildren<{
  multiple?: boolean;
  accept?: string;
  onChange: (files: AcceptedFile[]) => void;
  maxSize?: number;
}>;

const Uploader = ({
  accept = ".jpg,.jpeg,.png,.pdf",
  maxSize = FILE_MAX_SIZE,
  multiple = true,
  onChange,
  children,
}: UploaderProps) => {
  const [key, setKey] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files = [] } = e.target as unknown as { files: File[] };

    const accepted = [...files].map((file) => {
      const isValid = file.size < maxSize;

      return {
        id: uniqueId("machine-cv-file-"),
        name: file.name,
        type: file.type,
        size: file.size,
        dateAdded: format(new Date()),
        isValid,
        file: isValid ? file : null,
      } as AcceptedFile;
    });

    if (accepted.length > 0) {
      onChange(accepted);
    }

    setKey(!key);
  };

  const button = getButton(children, () => {
    ref.current?.click();
  });

  return (
    <>
      <input
        key={`${key}`}
        ref={ref}
        type="file"
        style={{ display: "none" }}
        multiple={multiple}
        accept={accept}
        onChange={onInputChange}
      />
      {button}
    </>
  );
};

export default Uploader;
