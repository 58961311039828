import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getApiClient,
  validateStatus,
} from "../../../../modules/core/apiClient/useApiStore";
import type { CommentTag } from "../../../../types";

type UseTagsQuery = {
  tags: CommentTag[];
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

type UseTagsQueryProps = {
  machineId: string;
};

export const useTagsQuery = ({
  machineId,
}: UseTagsQueryProps): UseTagsQuery => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["machine-cv-tags"],
    () =>
      getApiClient()
        .get<CommentTag[]>(`meta/machinecv/v1/tags/machines/${machineId}`)
        .then(({ data }) => data),
    {
      cacheTime: 0,
    }
  );

  return {
    tags: data,
    isLoading,
    isError,
    refetch,
  };
};

type TagAddProps = {
  name: string;
  machineId: string;
};

export const useTagsAdd = () => {
  const { mutateAsync } = useMutation((data: TagAddProps) =>
    getApiClient().post("meta/machinecv/v1/tags", data, { validateStatus })
  );

  return { addTagAsync: mutateAsync };
};
