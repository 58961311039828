import { useMemo, useState } from "react";

import {
  getFileIds,
  useFileDelete,
  useFileDownload,
  useFileUpload,
  useLocationSearch,
  useMachineUsersQuery,
} from "../../../../../../Hooks";

import type { DeleteItemDataProps } from "../../../../../../modules/common/components/Dialogs/DeleteDialog";
import DeleteDialog from "../../../../../../modules/common/components/Dialogs/DeleteDialog";
import DownloadDialog from "../../../../../../modules/common/components/Dialogs/DownloadDialog";
import AddDocumentDialog from "../../../../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import type { DocumentResponse } from "../../../../MachineCVDocumentsPage/hooks/useDocumentsQuery";
import { getColumns } from "./columns";
import { CommandBar } from "./CommandBar";
import { useTask } from "../../../hooks/useTask";
import { useTaskUploadDocument } from "../../../hooks/useTaskMutations";
import useTasksStore from "../../../hooks/useTasksStore";
import { notification } from "../../../../../common/Notification";
import type { TableProps } from "../../../../../common/Table";
import Table, { useTableFilters } from "../../../../../common/Table";
import type { UploadedFile } from "../../../../../common/Uploader/types";

import SectionTitle from "../../SectionTitle";

export const TaskDocuments = () => {
  const [{ id }] = useLocationSearch();

  const { edit } = useTasksStore((state) => ({
    edit: state.edit,
  }));

  const { task, getTaskDetails, isLoading, isError } = useTask({
    taskId: edit.id,
  });

  const [showUpload, setShowUpload] = useState(false);
  const [deleteData, setDeleteData] = useState<DeleteItemDataProps>({
    ids: [],
    name: "",
  });

  const { showDownloadDialog } = useFileDownload();

  const { filters, handleSearch } = useTableFilters<DocumentResponse>({
    keys: ["name", "description"],
  });

  const { usersList } = useMachineUsersQuery({ machineId: id as string });

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-edit-task-documents",
        version: 1,
      },
      items: task.files,
      isLoading,
      filters,
      isError,
      perPage: 5,
      hidePerPage: true,
      hasSelection: false,
      columns: getColumns({ onRemove: setDeleteData, users: usersList }),
    }),
    [task.files, filters, isLoading, isError]
  );

  const { uploadFilesAsync } = useFileUpload();
  const { deleteFilesAsync } = useFileDelete();
  const { uploadTaskDocumentsAsync } = useTaskUploadDocument();

  const onUpload = async (files: UploadedFile[]) => {
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });

    const successIds = getFileIds(uploadResponse);
    await uploadTaskDocumentsAsync({ id: edit.id, fileIds: successIds })
      .then(() => {
        if (uploadResponse.length === successIds.length) {
          notification.success("Task updated successfully");
        } else {
          notification.success(
            `Task updated successfully with ${successIds.length} out of ${uploadResponse.length} files`
          );
        }
        getTaskDetails();
      })
      .catch(() => {
        notification.error("Failed updating task");
        deleteFilesAsync(successIds);
      });
  };

  return (
    <div>
      <SectionTitle>Documents</SectionTitle>

      <CommandBar
        handleSearch={handleSearch}
        onUpload={() => setShowUpload(true)}
      />
      <Table {...tableProps} />

      <DownloadDialog hidden={!showDownloadDialog} />
      <AddDocumentDialog
        hidden={!showUpload}
        onSubmit={onUpload}
        onClose={() => setShowUpload(false)}
      />
      <DeleteDialog
        dialogContentProps={{
          onDismiss: () => setDeleteData({ ids: [], name: "" }),
        }}
        data={deleteData}
        hidden={deleteData.ids.length === 0}
        route={`meta/machinecv/v1/tasks/${edit.id}/documents`}
        onSuccess={getTaskDetails}
      />
    </div>
  );
};
