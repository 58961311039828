import type { SVGProps } from 'react';
import React from 'react';

const ArrowLeftIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='13'
      viewBox='0 0 16 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.65897 14.367C7.7075 14.4113 7.76426 14.4455 7.82603 14.4679C7.88779 14.4902 7.95335 14.5001 8.01895 14.4971C8.08456 14.4941 8.14893 14.4782 8.20838 14.4503C8.26784 14.4224 8.32122 14.3831 8.36547 14.3345C8.40973 14.286 8.44399 14.2292 8.46631 14.1675C8.48863 14.1057 8.49856 14.0402 8.49554 13.9745C8.49253 13.9089 8.47662 13.8446 8.44872 13.7851C8.42083 13.7257 8.3815 13.6723 8.33297 13.628L2.16497 7.99803H15.996C16.1286 7.99803 16.2558 7.94535 16.3495 7.85158C16.4433 7.75781 16.496 7.63064 16.496 7.49803C16.496 7.36542 16.4433 7.23824 16.3495 7.14447C16.2558 7.05071 16.1286 6.99803 15.996 6.99803H2.16797L8.33297 1.36903C8.42586 1.27842 8.47989 1.15525 8.48363 1.02554C8.48738 0.895828 8.44055 0.769744 8.35304 0.673927C8.26553 0.57811 8.1442 0.520067 8.01469 0.512061C7.88517 0.504056 7.75762 0.546717 7.65897 0.631028L0.742974 6.94503C0.656178 7.02393 0.589212 7.12219 0.547512 7.23183C0.505811 7.34146 0.490553 7.45939 0.502974 7.57603C0.52138 7.75919 0.60686 7.92909 0.742974 8.05303L7.65897 14.367Z'
        fill='#0078D4'
      />
    </svg>
  );
};

export default ArrowLeftIcon;
