import { Stack, Text } from '@fluentui/react';
import type { PropsWithChildren } from 'react';

import type { FormGroupProps } from '../types';

export const FormGroup = ({
  children,
  label,
  stackProps,
  labelProps,
}: PropsWithChildren<FormGroupProps>) => (
  <Stack
    horizontal
    horizontalAlign='space-between'
    style={{ marginTop: 8, marginBottom: 8 }}
    {...stackProps}
  >
    {label && (
      <Text style={{ minWidth: 160, marginRight: 8 }} {...labelProps}>
        {label}
      </Text>
    )}
    {children}
  </Stack>
);
