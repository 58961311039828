import type { PartialTheme } from '@fluentui/react';

export const theme: PartialTheme = {
  palette: {
    themePrimary: '#2c529f',
    themeLighterAlt: '#f4f6fb',
    themeLighter: '#d4ddef',
    themeLight: '#b1c1e2',
    themeTertiary: '#708cc5',
    themeSecondary: '#3e62aa',
    themeDarkAlt: '#284a8e',
    themeDark: '#223e78',
    themeDarker: '#192e59',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
  components: {
    CommandBar: {
      styles: {
        root: {
          background: 'transparent',
        },
      },
    },
    Spinner: {
      styles: {
        label: {
          fontSize: 14,
          fontWeight: 600,
          color: '#3B3A39',
        },
      },
    },
  },
};
