import type { AxiosInstance } from "axios";
import React, { memo } from "react";

import AppLayout from "../../../templates/AppLayout";

import RawDataPage from "./RawDataPage";

interface RawDataProps {
  axiosInstance: AxiosInstance;
  marginTop: number;
}

const RawData: React.FC<RawDataProps> = memo(
  ({ marginTop = 0, axiosInstance }) => {
    return (
      <AppLayout axiosInstance={axiosInstance}>
        <RawDataPage marginTop={marginTop} />
      </AppLayout>
    );
  }
);

RawData.displayName = "RawData";

export default RawData;
