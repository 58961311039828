import {
  Breadcrumb,
  Icon,
  IconButton,
  Stack,
  TooltipHost,
  Text,
} from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Status } from "../../../schema/status";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import {
  listAsyncWirelessDetails,
  selectWirelessDetails,
  selectWirelessGatewayDetailsStatus,
} from "./reducer";
import { WirelessSensorNode } from "../../SensorNodes/models";
import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../../common/CommandBar";
import Table, { Column, useTableFilters } from "../../common/Table";
import { notification } from "../../common/Notification";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../../schema/Constants";
import { selectProject } from "../../Projects/reducer";
import { DeleteConfirm } from "./DeleteConfirm";
import { resetState } from "../../SensorNodes/reducer";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import { EditDialog } from "../../SensorNodes/EditDialog";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";

type GetColumnsOpts = {
  hasActions: boolean;
  onEdit: (sensorNode: WirelessSensorNode) => void;
  onDelete: (sensorNode: WirelessSensorNode) => void;
};

const getColumns = ({
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "sensorNodeId",
      name: "Sensor Node Id",
      fieldName: "Sensor Node Id",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, sensorNodeId }: WirelessSensorNode) => (
        <Link to={"../sensorNode/" + id.toString()} style={linkStyle}>
          {sensorNodeId}
        </Link>
      ),
    },
    {
      key: "deviceClass",
      name: "Device Class",
      fieldName: "deviceClass",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "deviceModel",
      name: "Device Model",
      fieldName: "deviceModel",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (sensorNode: WirelessSensorNode) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={"Edit"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(sensorNode)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(sensorNode)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

export const WirelessGatewayDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useContext(authContext);
  const items = useAppSelector(selectWirelessDetails);
  const status = useAppSelector(selectWirelessGatewayDetailsStatus);
  const hasWritePermission = auth.metaDataContributor;
  const proj = useAppSelector(selectProject(items.projectId));

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(listAsyncWirelessDetails(id ? id : ""));
  }, [dispatch, id]);

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{items.serialNumber}</Text>,
    },
  ];
  const { filters, handleSearch } = useTableFilters<WirelessSensorNode>({
    keys: ["deviceClass", "deviceModel", "sensorNodeId"],
  });
  const goBack = () => navigate(-1);

  const [selected, setSelected] = useState<{
    data: WirelessSensorNode | null;
    context: "edit" | "delete";
  } | null>(null);

  const onEdit = (sensorNode: WirelessSensorNode) => {
    setSelected({ data: sensorNode, context: "edit" });
  };

  const onDelete = (sensorNode: WirelessSensorNode) =>
    setSelected({ data: sensorNode, context: "delete" });

  return (
    <>
      {items ? (
        <>
          <Stack horizontal verticalAlign="center">
            <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
            {proj ? (
              <>
                <Breadcrumb
                  items={[
                    {
                      key: `corporation-${proj?.corporation.number}`,
                      text: proj?.corporation.name,
                    },
                    {
                      key: `company-${proj?.company.number}`,
                      text: proj?.company.name,
                    },
                    { key: `project-${proj?.name}`, text: proj?.name },
                    {
                      key: `gateway-${items?.serialNumber}`,
                      text: items?.serialNumber,
                    },
                  ]}
                  styles={{
                    root: { margin: 0 },
                    item: { fontSize: "14px" },
                    chevron: { fontSize: "10px" },
                  }}
                />
              </>
            ) : (
              <Breadcrumb
                items={[
                  {
                    key: `un-parented-gateway`,
                    text: "Un-parented Gateway",
                  },
                ]}
                styles={{
                  root: { margin: 0 },
                  item: { fontSize: "14px" },
                  chevron: { fontSize: "10px" },
                }}
              />
            )}
          </Stack>
          <div style={pageStyle}>
            <BaseCommandBar
              items={computeCommandBarItems(commandBarItems)}
              onSearch={handleSearch}
              styles={commandBarStyles}
            />

            <Table
              persistOpts={{
                key: "table-gatewaysDet",
                version: 2,
              }}
              header={{
                title: "Sensors",
              }}
              items={items.sensorNodes}
              columns={getColumns({
                hasActions: hasWritePermission,
                onEdit,
                onDelete,
              })}
              filters={filters}
              hasSelection={false}
              isLoading={status === Status.loading}
              isError={status === Status.error}
            />

            <DeleteConfirm
              data={selected?.data}
              show={selected?.context === "delete"}
              onSuccess={(hasError) => {
                if (hasError) {
                  notification.error(
                    `Failed deleting ${selected?.data?.sensorNodeId} Sensor node`
                  );
                } else {
                  dispatch(resetState);
                  dispatch(listAsyncWirelessDetails(id ? id : ""));
                  notification.success(
                    `${selected?.data?.sensorNodeId} sensor node deleted successfully`
                  );
                }

                setSelected(null);
              }}
              onClose={() => setSelected(null)}
            />

            {selected?.context === "edit" && selected?.data && (
              <EditDialog
                data={selected?.data}
                show={true}
                onSuccess={(hasError) => {
                  if (hasError) {
                    notification.error(
                      "Failed when updating sensor node. Please try again later."
                    );
                  } else {
                    notification.success("Sensor node updated successfully.");
                    dispatch(listAsyncWirelessDetails(id ? id : ""));
                  }
                }}
                onClose={function (): void {
                  setSelected(null);
                }}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <NotFoundRoute />
        </>
      )}
    </>
  );
};
