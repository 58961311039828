import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../../../modules/core/apiClient/useApiStore';
import type { GetMachineTelemetryResponse } from '../../../types';

export type UseMachineTelemetryParams = {
  machineId: string;
};

export const useMachineTelemetry = ({ machineId }: UseMachineTelemetryParams) => {
  const {
    data = {} as GetMachineTelemetryResponse,
    isLoading,
    isError,
    refetch,
  } = useQuery<GetMachineTelemetryResponse>(
    ['ws-machine-telemetry', machineId],
    () =>
      getApiClient()
        .get<GetMachineTelemetryResponse>(`/dws/v1/telemetry/machines/${machineId}`)
        .then(({ data }) => data),
    {
      cacheTime: 0,
    },
  );

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
