import type { ICheckboxProps } from '@fluentui/react';
import { Checkbox } from '@fluentui/react';
import { Controller } from 'react-hook-form';

import type { BaseFormFieldProps } from '../types';
import { FormGroup } from './FormGroup';

export type FormCheckboxProps = BaseFormFieldProps &
  ICheckboxProps & {
    name: string;
  };

export const FormCheckbox = ({
  name,
  groupProps,
  // defaultChecked,
  checked,
  hookProps: { control },
  ...rest
}: FormCheckboxProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue={checked}
      render={({ field: { onChange, value } }) => {
        return (
          <Checkbox
            styles={{ root: { margin: 0 } }}
            defaultChecked={value}
            onChange={(_, checked) => {
              onChange(checked);
            }}
            {...rest}
          />
        );
      }}
    />
  </FormGroup>
);
