import React from 'react';

const ChartSymbolIcon = ({ type, color, size = 16, ...props }: any) => {
  const getPath = () => {
    switch (type) {
      case 'rectangle':
        return <rect x={2.5} y={2} width={11} height={12} rx={1} />;
      case 'diamond':
        return (
          <rect x={8} y={0.58} width={10.493} height={10.493} rx={1} transform='rotate(45 8 .58)' />
        );
      case 'circle':
        return <circle cx={8} cy={8} r={6} />;
      case 'hexagon':
        return <path d='M8 2.155l5.062 2.922v5.846L8 13.845l-5.062-2.922V5.077L8 2.155Z' />;
      case 'octagon':
        return (
          <path d='M7.5 1.289a1 1 0 0 1 1 0l5.062 2.922a1 1 0 0 1 .5.866v5.846a1 1 0 0 1-.5.866L8.5 14.71a1 1 0 0 1-1 0L2.438 11.79a1 1 0 0 1-.5-.866V5.077a1 1 0 0 1 .5-.866L7.5 1.29Z' />
        );
      default:
        return null;
    }
  };

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {React.cloneElement(getPath() as React.ReactElement<any>, {
        fill: `var(--chart-symbol-color-${color})`,
      })}
    </svg>
  );
};

export default ChartSymbolIcon;
