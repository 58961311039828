import "./style.scss";

import {
  COLOR_DARK_GREEN,
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_RED,
  COLOR_YELLOW,
} from "../config";

const performanceSteps = [
  COLOR_RED,
  COLOR_ORANGE,
  COLOR_YELLOW,
  COLOR_GREEN,
  COLOR_DARK_GREEN,
];

const PerformanceStatus = ({ value }: any) => {
  return (
    <div className="performance-status">
      <div
        className="performance-status__indicator"
        style={{ width: `${value * 100}%` }}
      />
      {performanceSteps.map((step) => (
        <div
          key={step}
          className="performance-status__step"
          style={{ backgroundColor: step }}
        />
      ))}
    </div>
  );
};

export default PerformanceStatus;
