import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import type {
  Actions,
  HarmonicsState,
} from "../components/SpectrumChart/harmonicsReducer";
import harmonicsReducer, {
  harmonicsInitialState,
} from "../components/SpectrumChart/harmonicsReducer";

export type MachineHarmonics = {
  harmonicsOne: HarmonicsState;
  harmonicsTwo: HarmonicsState;
  harmonicsThree: HarmonicsState;
  clearHarmonicsTrigger: boolean;
  manualInput: boolean;
  ratioSpeedFromCustomValueRPM: number | undefined;
};

type HarmonicsStore = {
  machines: Record<string, MachineHarmonics>;
  updateHarmonics: (
    machineId: string,
    harmonicsKey: "harmonicsOne" | "harmonicsTwo" | "harmonicsThree",
    action: Actions
  ) => void;
  setClearHarmonicsTrigger: (machineId: string, value: boolean) => void;
  setManualInput: (machineId: string, value: boolean) => void;
  setRatioSpeedFromCustomValueRPM: (
    machineId: string,
    value: number | undefined
  ) => void;
  resetMachine: (machineId: string) => void;
};

const useHarmonicsStoreV0 = createWithEqualityFn<HarmonicsStore>(
  (set) => ({
    machines: {},
    updateHarmonics: (machineId, harmonicsKey, action) =>
      set((state) => {
        const machine =
          state.machines[machineId] || createInitialMachineState();
        const updatedHarmonics = harmonicsReducer(
          machine[harmonicsKey],
          action
        );
        return {
          machines: {
            ...state.machines,
            [machineId]: {
              ...machine,
              [harmonicsKey]: updatedHarmonics,
            },
          },
        };
      }),
    setClearHarmonicsTrigger: (machineId, value) =>
      set((state) => ({
        machines: {
          ...state.machines,
          [machineId]: {
            ...(state.machines[machineId] || createInitialMachineState()),
            clearHarmonicsTrigger: value,
          },
        },
      })),
    setManualInput: (machineId, value) =>
      set((state) => ({
        machines: {
          ...state.machines,
          [machineId]: {
            ...(state.machines[machineId] || createInitialMachineState()),
            manualInput: value,
          },
        },
      })),
    setRatioSpeedFromCustomValueRPM: (machineId, value) =>
      set((state) => ({
        machines: {
          ...state.machines,
          [machineId]: {
            ...(state.machines[machineId] || createInitialMachineState()),
            ratioSpeedFromCustomValueRPM: value,
          },
        },
      })),
    resetMachine: (machineId) =>
      set((state) => ({
        machines: {
          ...state.machines,
          [machineId]: createInitialMachineState(),
        },
      })),
  }),
  shallow
);

function createInitialMachineState(): MachineHarmonics {
  return {
    harmonicsOne: harmonicsInitialState,
    harmonicsTwo: harmonicsInitialState,
    harmonicsThree: harmonicsInitialState,
    clearHarmonicsTrigger: false,
    manualInput: false,
    ratioSpeedFromCustomValueRPM: undefined,
  };
}

export default useHarmonicsStoreV0;
