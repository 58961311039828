import { useMemo, useState } from 'react';

import type {
  FrequencyCatalogueDbEntry,
  FrequencyCatalogueGroupsAndItemsResponse,
} from '../components/FrequencyCatalogueDialog/hooks';

type UseSearchResult = {
  data: {
    items: FrequencyCatalogueDbEntry[];
    groups: IGroup[];
  };
  handleSearch: (value: string) => void;
  search: string;
};

interface IGroup {
  key: string;
  name: string;
  startIndex: number;
  count: number;
}

const valueIncludes = (value: unknown, search: string): boolean =>
  value?.toString().trim().toLowerCase().includes(search) || false;

const useSearchGroupsAndItems = (
  initialData: FrequencyCatalogueGroupsAndItemsResponse,
): UseSearchResult => {
  const [search, setSearch] = useState('');
  const handleSearch = (value: string) => setSearch(value);

  const data = useMemo(() => {
    const normalizedSearch = search.trim().toLowerCase();

    const filteredItems = initialData.items?.filter(
      (item) =>
        !normalizedSearch ||
        valueIncludes(item.itemName, normalizedSearch) ||
        valueIncludes(item.groupName, normalizedSearch) ||
        valueIncludes(item.description, normalizedSearch),
    );

    const groupsMap = new Map<string, IGroup>();
    let currentIndex = 0;

    filteredItems?.forEach((item) => {
      const group = groupsMap.get(item.groupName) || {
        key: item.groupName,
        name: item.groupName,
        startIndex: currentIndex,
        count: 0,
      };
      group.count++;
      groupsMap.set(item.groupName, group);
      currentIndex++;
    });

    const filteredGroups = Array.from(groupsMap.values());

    return {
      items: filteredItems,
      groups: filteredGroups,
    };
  }, [search, initialData]);

  return {
    data,
    handleSearch,
    search,
  };
};

export default useSearchGroupsAndItems;
