import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";


import { YEARLY_EVENTS } from '../components/Events/config';
import { DropdownOption, periodDropdownOptions, viewDropdownOptions } from '../config';
import { DriveLoadEnriched } from '../../../../types';

interface EventData {
  activeTab: string;
  year: any;
  month: any;
}

interface DateRange {
  start?: Date;
  end?: Date;
}

export interface DriveLoadStore {
  period: DropdownOption;
  colorChartView: DropdownOption;
  driveLoad: DriveLoadEnriched;
  averageTrend: boolean;
  showDates: boolean;
  eventsTableLoading: boolean;
  eventsTableData: any[];
  selectedTableEvent: any;
  selectedFilterEventsNames: any[];
  events: EventData;
  isConnectedTimeAxis: boolean;
  torqueChartDates: DateRange;
  trendChartDates: DateRange;
  getStoreValue: (key: keyof DriveLoadStore) => any;
  updateDriveLoadStore: (updatedStoreProperties: Partial<DriveLoadStore>) => void;
}

export const driveLoadStoreInitialValue: Omit<
  DriveLoadStore,
  'getStoreValue' | 'updateDriveLoadStore'
> = {
  period: periodDropdownOptions[4],
  colorChartView: viewDropdownOptions[2],
  driveLoad: {} as DriveLoadEnriched,
  averageTrend: false,
  showDates: false,
  eventsTableLoading: false,
  eventsTableData: [],
  selectedTableEvent: {},
  selectedFilterEventsNames: [],
  events: {
    activeTab: YEARLY_EVENTS,
    year: {},
    month: {},
  },
  isConnectedTimeAxis: false,
  torqueChartDates: {
    start: undefined,
    end: undefined,
  },
  trendChartDates: {
    start: undefined,
    end: undefined,
  },
};

const useDriveLoadStore = createWithEqualityFn<DriveLoadStore>((set, get) => ({
  ...driveLoadStoreInitialValue,
  getStoreValue: (key) => get()[key],
  updateDriveLoadStore: (updatedStoreProperties = {}) =>
    set((store) => ({
      ...store,
      ...updatedStoreProperties,
    })),
}), shallow);

export default useDriveLoadStore;
