import type { PageState } from '../../MachineCVTasksPage/pageReducer';
import type {
  MachineCvOverviewCompany,
  MachineCvOverviewList,
  MachineCvOverviewMachine,
  MachineCvOverviewProject,
} from '../../../../types';

type Filter = (data: MachineCvOverviewList, opts: PageState) => MachineCvOverviewList;

export const filter: Filter = (data, opts) => {
  switch (opts.searchBy) {
    case 'machine':
      return byMachine(data, opts);
    case 'project':
      return byProject(data, opts);
    case 'company':
      return byCompany(data, opts);
    case 'corporation':
      return byCorporation(data, opts);
    default:
      return data;
  }
};

// ---- Machine ----

const handleMachine = (machine: MachineCvOverviewMachine, { condition, search }: PageState) =>
  (condition === 'All' ? true : machine.condition === condition) &&
  (machine.metaData?.name?.toLowerCase().includes(search?.toLowerCase()) ||
    machine.metaData?.customerCode?.toLowerCase().includes(search?.toLowerCase()));

const byMachine: Filter = (data, opts) => {
  const filtered = data.filter(({ companies }) =>
    companies.some(({ projects }) =>
      projects.some(({ machines }) => machines.some((machine) => handleMachine(machine, opts))),
    ),
  );

  for (let corporationId = 0; corporationId < filtered.length; corporationId += 1) {
    filtered[corporationId] = {
      ...filtered[corporationId],
      companies: filtered[corporationId].companies.filter(({ projects }) =>
        projects.some(({ machines }) => machines.some((machine) => handleMachine(machine, opts))),
      ),
    };

    for (let companyId = 0; companyId < filtered[corporationId].companies.length; companyId += 1) {
      filtered[corporationId].companies[companyId] = {
        ...filtered[corporationId].companies[companyId],
        projects: filtered[corporationId].companies[companyId].projects.filter(({ machines }) =>
          machines.some((machine) => handleMachine(machine, opts)),
        ),
      };

      for (
        let projectId = 0;
        projectId < filtered[corporationId].companies[companyId].projects.length;
        projectId += 1
      ) {
        filtered[corporationId].companies[companyId].projects[projectId] = {
          ...filtered[corporationId].companies[companyId].projects[projectId],
          machines: filtered[corporationId].companies[companyId].projects[
            projectId
          ].machines.filter((machine) => handleMachine(machine, opts)),
        };
      }
    }
  }

  return filtered;
};

// ---- Project ----

const handleProject = (project: MachineCvOverviewProject, { search, condition }: PageState) =>
  project.metaData.name?.toLowerCase().includes(search?.toLowerCase()) &&
  project.machines.some((machine) =>
    condition === 'All' ? true : condition === machine.condition,
  );

const byProject: Filter = (data, opts) => {
  const filtered = data.filter(({ companies }) =>
    companies.some(({ projects }) => projects.some((project) => handleProject(project, opts))),
  );

  for (let corporationId = 0; corporationId < filtered.length; corporationId += 1) {
    filtered[corporationId] = {
      ...filtered[corporationId],
      companies: filtered[corporationId].companies.filter(({ projects }) =>
        projects.some((project) => handleProject(project, opts)),
      ),
    };

    for (let companyId = 0; companyId < filtered[corporationId].companies.length; companyId += 1) {
      filtered[corporationId].companies[companyId] = {
        ...filtered[corporationId].companies[companyId],
        projects: filtered[corporationId].companies[companyId].projects.filter((project) =>
          handleProject(project, opts),
        ),
      };

      for (
        let projectId = 0;
        projectId < filtered[corporationId].companies[companyId].projects.length;
        projectId += 1
      ) {
        filtered[corporationId].companies[companyId].projects[projectId] = {
          ...filtered[corporationId].companies[companyId].projects[projectId],
          machines: filtered[corporationId].companies[companyId].projects[
            projectId
          ].machines.filter(({ condition }) =>
            opts.condition === 'All' ? true : opts.condition === condition,
          ),
        };
      }
    }
  }

  return filtered;
};

// ---- Company ----

const handleCompany = (company: MachineCvOverviewCompany, { search, condition }: PageState) =>
  company.name?.toLowerCase().includes(search?.toLowerCase()) &&
  company.projects.some((project) =>
    project.machines.some((machine) =>
      condition === 'All' ? true : condition === machine.condition,
    ),
  );

const byCompany: Filter = (data, opts) => {
  const filtered = data.filter(({ companies }) =>
    companies.some((company) => handleCompany(company, opts)),
  );

  for (let corporationId = 0; corporationId < filtered.length; corporationId += 1) {
    filtered[corporationId] = {
      ...filtered[corporationId],
      companies: filtered[corporationId].companies.filter((company) =>
        handleCompany(company, opts),
      ),
    };

    for (let companyId = 0; companyId < filtered[corporationId].companies.length; companyId += 1) {
      filtered[corporationId].companies[companyId] = {
        ...filtered[corporationId].companies[companyId],
        projects: filtered[corporationId].companies[companyId].projects.filter((project) =>
          project.machines.some((machine) =>
            opts.condition === 'All' ? true : opts.condition === machine.condition,
          ),
        ),
      };

      for (
        let projectId = 0;
        projectId < filtered[corporationId].companies[companyId].projects.length;
        projectId += 1
      ) {
        filtered[corporationId].companies[companyId].projects[projectId] = {
          ...filtered[corporationId].companies[companyId].projects[projectId],
          machines: filtered[corporationId].companies[companyId].projects[
            projectId
          ].machines.filter(({ condition }) =>
            opts.condition === 'All' ? true : opts.condition === condition,
          ),
        };
      }
    }
  }

  return filtered;
};

// ---- Corporation ----

const byCorporation: Filter = (data, opts) => {
  const filtered = data.filter(
    (corporation) =>
      corporation.name?.toLowerCase().includes(opts.search?.toLowerCase()) &&
      corporation.companies.some(({ projects }) =>
        projects.some(({ machines }) =>
          machines.some((machine) =>
            opts.condition === 'All' ? true : opts.condition === machine.condition,
          ),
        ),
      ),
  );

  for (let corporationId = 0; corporationId < filtered.length; corporationId += 1) {
    filtered[corporationId] = {
      ...filtered[corporationId],
      companies: filtered[corporationId].companies.filter(({ projects }) =>
        projects.some(({ machines }) =>
          machines.some((machine) =>
            opts.condition === 'All' ? true : opts.condition === machine.condition,
          ),
        ),
      ),
    };

    for (let companyId = 0; companyId < filtered[corporationId].companies.length; companyId += 1) {
      filtered[corporationId].companies[companyId] = {
        ...filtered[corporationId].companies[companyId],
        projects: filtered[corporationId].companies[companyId].projects.filter((project) =>
          project.machines.some((machine) =>
            opts.condition === 'All' ? true : opts.condition === machine.condition,
          ),
        ),
      };

      for (
        let projectId = 0;
        projectId < filtered[corporationId].companies[companyId].projects.length;
        projectId += 1
      ) {
        filtered[corporationId].companies[companyId].projects[projectId] = {
          ...filtered[corporationId].companies[companyId].projects[projectId],
          machines: filtered[corporationId].companies[companyId].projects[
            projectId
          ].machines.filter(({ condition }) =>
            opts.condition === 'All' ? true : opts.condition === condition,
          ),
        };
      }
    }
  }

  return filtered;
};
