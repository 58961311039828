import { DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { selectMachinesToList } from "../Machines/reducer";
import { useEffect, useState } from "react";
import { MachineToList } from "../Machines/models";
import FormDialog from "../Generic/FormDialog";
import { DialogType, Dropdown, IDropdownOption } from "@fluentui/react";
import { DataloggerDialogsProps } from "./formModels";
import { DModels, D325, DBasic, D850Eco, D850 } from "./models";
import { DataloggersAPI } from "./api";
import { listDataloggersAsync } from "./reducer";

type MachineKeysDifferencesProps = {
  keysToDelete: string[];
  keysToAdd: string[];
};

/**
 * Gets a dropdown item option from a machine's information.
 * @param machine The machine item.
 * @returns The drop down option.
 */
const getDropDownItem = (machine: MachineToList): IDropdownOption => {
  return {
    key: machine.id,
    text: `${machine.dalogId} - ${machine.name}`,
  };
};

/**
 * Calculates and gets the machine keys that should be added and removed to a determined datalogger.
 * @param currentMachineKeys The current machine keys linked to the datalogger.
 * @param newMachineKeys The desired new machine keys linked to the datalogger.
 * @returns An object with the keys to add and delete.
 */
const getMachineKeysDifferencesProps = (
  currentMachineKeys: string[],
  newMachineKeys: string[]
): MachineKeysDifferencesProps => {
  let result: MachineKeysDifferencesProps = {
    keysToAdd: [],
    keysToDelete: [],
  };

  // Gets the keys to add
  newMachineKeys.forEach((newKey) => {
    let existingKey = currentMachineKeys.indexOf(newKey);
    if (existingKey >= 0) {
      return;
    }

    result.keysToAdd.push(newKey);
  });

  // Gets the keys to delete.
  currentMachineKeys.forEach((currentKey) => {
    let existingKey = newMachineKeys.indexOf(currentKey);
    if (existingKey >= 0) {
      return;
    }

    result.keysToDelete.push(currentKey);
  });

  return result;
};

/**
 * Builds the datalogger entry with the new machine Ids information.
 * @param model The model
 * @param entry The current datalogger entry.
 * @param machineIds The selected machine Ids.
 * @returns The updated datalogger entry.
 */
const buildDataloggerEntry = (
  model: DModels,
  entry: D325 | DBasic | D850Eco | D850,
  machineIds: string[]
): D325 | DBasic | D850Eco | D850 => {
  let result: D325 | DBasic | D850Eco | D850 = undefined;
  switch (model) {
    case DModels.D325:
      result = { ...entry, machineIds: machineIds } as D325;
      break;
    case DModels.D555:
    case DModels.D650:
      result = { ...entry, machineIds: machineIds } as DBasic;
      break;
    case DModels.D850Eco:
      result = { ...entry, machineIds: machineIds } as D850Eco;
      break;
    case DModels.D850:
      result = { ...entry, machineIds: machineIds } as D850;
      break;
  }

  return result;
};

/**
 * Gets the datalogger select machines dialog.
 * @param model The datalogger model.
 * @param entry The datalogger table item
 * @param onClose Function called when the dialog needs to be closed (must be implemented).
 * @returns The datalogger select machines dialog.
 */
const DataloggerSelectMachinesDialog = ({
  model,
  entry,
  onClose,
}: DataloggerDialogsProps) => {
  const dispatch = useAppDispatch();
  const machines = useAppSelector(selectMachinesToList);
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [options, setOptions] = useState<IDropdownOption[]>([]);
  const [newSelectedKeys, setNewSelectedKeys] = useState<string[]>([]);
  const [currentSelectedKeys, setCurrentSelectedKeys] = useState<string[]>([]);

  // Gets the machines information.
  useEffect(() => {
    let projectMachines: MachineToList[] = machines.filter(
      (machine) => machine.projectId === entry.projectId
    );

    if (projectMachines.length <= 0) {
      notification.error(
        "The associated project does not have any attached machines. Please add a machine to the project before selecting it to a datalogger."
      );
      onClose?.();
      return;
    }

    // Sets the available options.
    setOptions(
      projectMachines.map((machine) => {
        return getDropDownItem(machine);
      })
    );

    // Sets the selected keys.
    let initialSelectedKeys = [];
    for (let id of entry.machineIds) {
      let machine = projectMachines.find((machine) => machine.id === id);
      if (!machine) {
        continue;
      }

      initialSelectedKeys.push(id);
    }

    setCurrentSelectedKeys([...initialSelectedKeys]);
    setNewSelectedKeys([...initialSelectedKeys]);
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (
    _event: React.FormEvent<HTMLDivElement>,
    item: IDropdownOption
  ) => {
    if (!item) {
      return;
    }

    let newKeys = item.selected
      ? [...newSelectedKeys, item.key as string]
      : newSelectedKeys.filter((key) => key !== item.key);

    let keyDifferences = getMachineKeysDifferencesProps(
      currentSelectedKeys,
      newKeys
    );

    setIsValid(
      keyDifferences.keysToAdd.length !== 0 ||
        keyDifferences.keysToDelete.length !== 0
    );
    setNewSelectedKeys(newKeys);
  };

  const onSubmit = () => {
    setIsLoading(true);
    let newEntry = buildDataloggerEntry(model, entry, newSelectedKeys);
    if (!newEntry) {
      return;
    }

    DataloggersAPI.update(model, newEntry).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure: Updating machines for a ${model} datalogger. Please try again later.`
        );
        return;
      }

      notification.success(
        `Success: Updating machines for a ${model} datalogger. `
      );
      dispatch(listDataloggersAsync(model)());
      onClose();
    });
  };

  return (
    <FormDialog
      title={"Select machines"}
      isLoading={isLoading}
      isValid={isValid}
      type={DialogType.normal}
      size={DialogSize.S}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Dropdown
        multiSelect
        placeholder="Select machines..."
        options={options}
        selectedKeys={newSelectedKeys}
        onChange={onChange}
      />
    </FormDialog>
  );
};

export default DataloggerSelectMachinesDialog;
