import { useState } from "react";
import DeleteDialog from "../../Generic/DeleteDialog";
import { CfgToMachinePinningItem } from "../models";
import { CfgToMachinePinningApi } from "../api";
import { notification } from "../../common/Notification";

type CfgPinningDeleteDialogProps = {
  item: CfgToMachinePinningItem;
  onClose: (listChanged: boolean) => void;
};

/**
 * Gets the CFG to Machine pinning delete dialog component
 * @param item The item to delete
 * @param onClose Method called to close this dialog.
 * @returns The CFG to Machine pinning delete dialog component.
 */
const CfgPinningDeleteDialog = ({
  item,
  onClose,
}: CfgPinningDeleteDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Handlers
  const onDelete = (id: string) => {
    setIsLoading(true);
    CfgToMachinePinningApi.delete(id).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure deleting a CFG to Machine pinning entry: ${response.statusText}.`
        );
        return;
      }

      notification.success("Success deleting a CFG to Machine pinning entry.");
      onClose?.(true);
    });
  };

  return (
    <DeleteDialog
      id={item.id}
      identifier={"CFG to Machine pinning"}
      propertyName={"Configname"}
      referenceString={item.cfgName}
      isLoading={isLoading}
      onDelete={onDelete}
      onClose={() => onClose(false)}
    />
  );
};

export default CfgPinningDeleteDialog;
