import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import type { ResponseSimplifiedSignal } from '../../../types';

interface SelectedSignalsStore {
  selectedSignals: ResponseSimplifiedSignal[];
  machineId_1: string | undefined;
  machineId_2: string | undefined;
  addSelectedSignal: (selectedSignal: ResponseSimplifiedSignal) => void;
  removeSelectedSignal: (selectedSignal: ResponseSimplifiedSignal) => void;
  clearSelectedSignals: () => void;
  clearTrendSignals: () => void;
  clearRawSignals: () => void;
  editSignalValues: (signalId: string | undefined, updatedValues: any) => void;
}

const updateMachineIds = (
  signals: ResponseSimplifiedSignal[],
): [string | undefined, string | undefined] => {
  const uniqueMachineIds = [...new Set(signals.map((signal) => signal.machineId).filter(Boolean))];
  return uniqueMachineIds.sort().slice(0, 2) as [string | undefined, string | undefined];
};

const useSelectedSignalsStore = createWithEqualityFn<SelectedSignalsStore>((set) => ({
  selectedSignals: [],
  machineId_1: undefined,
  machineId_2: undefined,
  addSelectedSignal: (selectedSignal: ResponseSimplifiedSignal) =>
    set((state) => {
      const newSelectedSignals = [...state.selectedSignals, selectedSignal];
      const [machineId_1, machineId_2] = updateMachineIds(newSelectedSignals);
      return { selectedSignals: newSelectedSignals, machineId_1, machineId_2 };
    }),
  removeSelectedSignal: (selectedSignal: ResponseSimplifiedSignal) =>
    set((state) => {
      const newSelectedSignals = state.selectedSignals.filter(
        (item) => item.id !== selectedSignal.id,
      );
      const [machineId_1, machineId_2] = updateMachineIds(newSelectedSignals);
      return { selectedSignals: newSelectedSignals, machineId_1, machineId_2 };
    }),
  clearSelectedSignals: () =>
    set({ selectedSignals: [], machineId_1: undefined, machineId_2: undefined }),
  clearTrendSignals: () =>
    set((state) => {
      const newSelectedSignals = state.selectedSignals.filter((item) => item.dataType !== 'Trend');
      const [machineId_1, machineId_2] = updateMachineIds(newSelectedSignals);
      return { selectedSignals: newSelectedSignals, machineId_1, machineId_2 };
    }),
  clearRawSignals: () =>
    set((state) => {
      const newSelectedSignals = state.selectedSignals.filter(
        (item) => item.dataType !== 'ShortTerm',
      );
      const [machineId_1, machineId_2] = updateMachineIds(newSelectedSignals);
      return { selectedSignals: newSelectedSignals, machineId_1, machineId_2 };
    }),
  editSignalValues: (signalId, updatedValues) =>
    set((state) => {
      const newSelectedSignals = state.selectedSignals.map((signal) =>
        signal.id === signalId ? { ...signal, ...updatedValues } : signal,
      );
      const [machineId_1, machineId_2] = updateMachineIds(newSelectedSignals);
      return { selectedSignals: newSelectedSignals, machineId_1, machineId_2 };
    }),
}), shallow);

export default useSelectedSignalsStore;
