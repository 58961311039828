import JSZip from 'jszip';

import { getApiClient } from '../../../core/apiClient/useApiStore'
import type { DataPoint } from '../../../../types';

interface GetTrendMeasuredDataInAPeriod {
  signalId: string | undefined;
  startDate: string | undefined;
  endDate: string;
  numberOfPoints: number;
}

export const getTrendMeasuredZipDataInAPeriod = async (args: GetTrendMeasuredDataInAPeriod) => {
  const response = await getApiClient().get(
    `data/read/v1/trends/${args.signalId}/zip?startDateTime=${args.startDate}&endDateTime=${args.endDate}`,
    {
      responseType: 'arraybuffer',
    },
  );

  const zip = await JSZip.loadAsync(response.data);
  const jsonString = await zip.file('trendsList.json')?.async('string');
  const json = JSON.parse(jsonString || '[]');
  return json.dataPoints.map(({ t, v }: { t: string; v: number }) => ({
    timeStamp: t,
    value: v,
  })) as DataPoint[];
};

export const getFastTrendMeasuredZipDataInAPeriod = async (args: GetTrendMeasuredDataInAPeriod) => {
  const response = await getApiClient().get(
    `data/read/v1/fasttrends/${args.signalId}/blocksAsZip?startDateTime=${args.startDate}&endDateTime=${args.endDate}`,
    {
      responseType: 'arraybuffer',
    },
  );

  const zip = await JSZip.loadAsync(response.data);
  const jsonString = await zip.file('blocks.json')?.async('string');
  const json = JSON.parse(jsonString || '[]');
  return (json?.blocks || []).reduce((result: any, item: any) => {
    if (item?.dataPoints?.length > 0) {
      result = [...result, ...item.dataPoints];
    }
    return result;
  }, []) as DataPoint[];
};

export const getTrendMeasuredDataInAPeriod = async (args: GetTrendMeasuredDataInAPeriod) => {
  const response = await getApiClient().get(
    `/data/read/v1/trends/${args.signalId}/downsampled/?dateTimeFrom=${args.startDate}&dateTimeUntil=${args.endDate}&numberOfPoints=${args.numberOfPoints}`,
  );

  return response.data as DataPoint[];
};
