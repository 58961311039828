import { getApiClient } from '../../../core/apiClient/useApiStore'
import type { PowerBiEmbed, PowerBiList } from '../../../../types';

export const getPowerBiList = () =>
  getApiClient()
    .get<PowerBiList>('/meta/powerbi/v1/')
    .then(({ data }) => data)
    .catch(() => []);

type GetPowerBiOpts = {
  id: string;
  type: PowerBiEmbed['parentType'];
};

export const getPowerBi = ({ id, type }: GetPowerBiOpts) =>
  getApiClient()
    .get<PowerBiEmbed>(`/meta/powerbi/v1/${type.toLowerCase()}/${id}`)
    .then(({ data }) => data);
