import type {
  ICommandBarStyles,
  IDropdownOption,
  IDropdownStyles,
  ISearchBoxStyles,
} from "@fluentui/react";
import { SearchBox, Text } from "@fluentui/react";
import { debounce } from "lodash-es";
import { useEffect } from "react";

import ColumnsIcon from "../../../../assets/svg/ColumnsIcon";
import FilterIcon from "../../../../assets/svg/FilterIcon";
import LayoutIcon from "../../../../assets/svg/LayoutIcon";
import { DEFAULT_DEBOUNCE } from "../../../../config/constants";
import type { Condition } from "../../../../modules/machine-cv/constants";
import { conditionFormat } from "../../../../modules/machine-cv/constants";
import { dropdownIconStyle } from "../../../../assets/styles";
import BaseCommandBar from "../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar/methods";

import type { Column, Layout, SearchBy } from "../hooks/useOverviewStore";
import useOverviewStore from "../hooks/useOverviewStore";

const layoutOptions: IDropdownOption<Layout>[] = [
  { key: "detailed", text: "Detailed view", data: "detailed" },
  { key: "compact", text: "Compact view", data: "compact" },
];

const conditionOptions: IDropdownOption<Condition | "All">[] = [
  { key: "All", text: "All", data: "All" },
  { key: "Ok", text: "Ok", data: "Ok" },
  { key: "Indication", text: "Indication", data: "Indication" },
  { key: "Alert", text: "Alert", data: "Alert" },
  { key: "Danger", text: "Danger", data: "Danger" },
  {
    key: "NotInstalled",
    text: conditionFormat["NotInstalled"],
    data: "NotInstalled",
  },
  { key: "NoStatus", text: conditionFormat["NoStatus"], data: "NoStatus" },
  { key: "Faulty", text: "Faulty", data: "Faulty" },
];

const columnOptions: IDropdownOption<Column>[] = [
  { key: "dalogId", text: "Dalog Id", data: "dalogId" },
  { key: "machineName", text: "Machine", data: "machineName" },
  { key: "customerCode", text: "Code", data: "customerCode" },
  { key: "conditionName", text: "Condition", data: "conditionName" },
  {
    key: "conditionComment",
    text: "Condition Comment",
    data: "conditionComment",
  },
  { key: "activeTasks", text: "Active Tasks", data: "activeTasks" },
  { key: "taskDescription", text: "Task Description", data: "taskDescription" },
  { key: "taskResponsible", text: "Responsible", data: "taskResponsible" },
  { key: "taskDueDate", text: "Next due date", data: "taskDueDate" },
];

const searchByOptions: IDropdownOption<SearchBy>[] = [
  { key: "machine", text: "Machine", data: "machine" },
  { key: "project", text: "Project", data: "project" },
  { key: "company", text: "Company", data: "company" },
  { key: "corporation", text: "Corporation", data: "corporation" },
];

export const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    display: "flex",
    alignItems: "center",
    selectors: {
      ":hover": {
        backgroundColor: "rgb(243, 242, 241)",
        color: "rgb(32, 31, 30)",
      },
    },
  },
  dropdown: {
    width: "auto",
  },
  title: {
    lineHeight: 32,
    fontWeight: 600,
  },
};

const searchByDropdownStyles: Partial<IDropdownStyles> = {
  root: {
    backgroundColor: "#2c529f !important",
    borderRadius: "2px 0px 0px 2px",
  },
  title: {
    color: "white !important",
    lineHeight: 32,
    fontWeight: 600,
  },
  caretDown: {
    color: "white !important",
  },
};

const searchBoxStyles: ISearchBoxStyles = {
  root: {
    width: 300,
    height: "100%",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 0,
    selectors: {
      ":after": {
        borderWidth: 0,
      },
    },
  },
};

const commandBarStyles: ICommandBarStyles = {
  root: {
    padding: 0,
    alignItems: "center",
    marginBottom: 16,
  },
  primarySet: {
    alignItems: "center",
  },
};

const CommandBar = () => {
  const {
    search,
    changeSearch,
    condition,
    changeCondition,
    searchBy,
    changeSearchBy,
  } = useOverviewStore();

  const { layout, columns, changeLayout, changeColumns } = useOverviewStore(
    (state) => ({
      layout: state.layout,
      columns: state.columns,
      changeLayout: state.changeLayout,
      changeColumns: state.changeColumns,
    })
  );

  const handleSearch = debounce((value: string) => {
    changeSearch(value.trim());
  }, DEFAULT_DEBOUNCE);

  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, []);

  const items: CommandBarItemProps[] = [
    {
      key: "logo",
      type: CommandBarItemType.Custom,
      onRender: () => (
        <Text
          style={{
            fontSize: 18,
            fontWeight: 600,
            borderRight: "1px solid #EDEBE9",
            paddingRight: 32,
            marginRight: 32,
          }}
        >
          Machine CV
        </Text>
      ),
    },
    {
      key: "layout",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: layout,
        styles: dropdownStyles,
        options: layoutOptions,
        onChange: (_: never, option: { data: string }) =>
          changeLayout(option?.data as Layout),
        onRenderLabelProps: {
          label: "Layout",
          icon: <LayoutIcon fill="#2c529f" style={dropdownIconStyle} />,
        },
      },
    },
    {
      key: "condition",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: condition,
        options: conditionOptions,
        onChange: (_: never, option: { data: string }) =>
          changeCondition(option?.data as Condition),
        onRenderLabelProps: {
          label: "Filter by condition",
          icon: <FilterIcon fill="#2c529f" style={dropdownIconStyle} />,
        },
      },
    },
    ...(layout === "compact"
      ? [
          {
            key: "columns",
            className: "secondary-dropdown",
            type: CommandBarItemType.ComboBox,
            onRenderProps: {
              multiSelect: true,
              selectedKey: columns,
              options: columnOptions,
              onChange: (_: never, option: IDropdownOption) =>
                changeColumns(
                  columns.includes(option?.data)
                    ? columns.filter((column) => column !== option?.data)
                    : [...columns, option?.data]
                ),
              onRenderLabelProps: {
                label: "Columns",
                icon: <ColumnsIcon fill="#2c529f" style={dropdownIconStyle} />,
              },
            },
          },
        ]
      : []),
  ];

  const farItems = [
    {
      key: "search-by",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: searchBy,
        options: searchByOptions,
        styles: searchByDropdownStyles,
        onChange: (_: never, option: { data: SearchBy }) =>
          changeSearchBy(option.data),
      },
    },
    {
      key: "search",
      type: CommandBarItemType.Custom,
      onRender: () => (
        <SearchBox
          placeholder={`Search by ${searchBy}`}
          defaultValue={search}
          styles={searchBoxStyles}
          onChange={(_, newValue) => handleSearch(newValue ?? "")}
        />
      ),
    },
  ];

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      farItems={computeCommandBarItems(farItems)}
      styles={commandBarStyles}
    />
  );
};

export default CommandBar;
