import { useMutation } from '@tanstack/react-query';

import { getApiClient } from '../../../modules/core/apiClient/useApiStore';

export type UseGatewayTriggeringParams = {
  gatewayId: string;
};

export const useGatewayTriggering = ({ gatewayId }: UseGatewayTriggeringParams) => {
  const { mutateAsync } = useMutation(() =>
    getApiClient().post(`/dws/v1/triggers/on-demand/gateways/${gatewayId}`, [
      'MeasSet0',
      'MeasSet1',
    ]),
  );

  return { triggerGatewayAsync: mutateAsync };
};
