/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { Layout } from "./Components/Layout/Layout";
import { ThemeProvider } from "@fluentui/react";
import { theme } from "./theme";
import { useAuth0 } from "@auth0/auth0-react";

export const App: React.FunctionComponent = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        },
      });
    }
  }, [isAuthenticated, loginWithRedirect, isLoading, user]);
  return (
    <ThemeProvider theme={theme}>
      <Layout />
    </ThemeProvider>
  );
};
