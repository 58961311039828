import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../../../../modules/core/apiClient/useApiStore';
import useDriveLoadStore, {
  driveLoadStoreInitialValue,
} from './useDriveLoadStore';
import type { DriveLoadEnriched } from '../../../../types';

interface UseMachineDriveLoadOptions {
  staleTime?: number;
  cacheTime?: number;
  [key: string]: any;
}

export const useMachineDriveLoad = (
  machineId: string,
  options: UseMachineDriveLoadOptions = {},
) => {
  const { updateDriveLoadStore } = useDriveLoadStore(
    (store) => ({
      updateDriveLoadStore: store.updateDriveLoadStore,
    }),
  );

  const {
    data = {} as DriveLoadEnriched,
    isLoading,
    isError,
  } = useQuery<DriveLoadEnriched>(
    ['machine-drive-load', machineId],
    () =>
      getApiClient()
        .get<DriveLoadEnriched>(`/meta/read/internal/v1/machines/${machineId}/driveLoad`)
        .then(({ data }) => {
          updateDriveLoadStore({ ...driveLoadStoreInitialValue, driveLoad: data });
          return data;
        })
        .catch(() => ({} as DriveLoadEnriched)),
    { staleTime: 0, cacheTime: 0, ...options },
  );

  return {
    data,
    isLoading,
    isError,
  };
};
