import type { IObjectWithKey } from "@fluentui/react";
import { differenceInHours, endOfDay } from "date-fns";
import { useMemo, useState } from "react";

import { useLocationSearch } from "../../../Hooks";
import DeleteDialog from "../../../modules/common/components/Dialogs/DeleteDialog";
import { getColumns } from "./components/columns";
import { CommandBar } from "./components/CommandBar";
import { ThreadDialog } from "./components/ThreadAdd";
import { useThreadsQuery } from "./hooks/useThreadsQuery";
import useThreadsStore from "./hooks/useThreadsStore";

import { NoData } from "../../common/NoData";
import { Spin } from "../../common/Spin";
import type { TableProps } from "../../common/Table";
import Table, { useTableFilters } from "../../common/Table";

export const filterByTime = (days: number, threads: any[]) => {
  if (!days) return threads;

  const hours = days * 24;
  const today = endOfDay(new Date());

  return threads.filter(
    ({ createdAt }) => differenceInHours(today, new Date(createdAt)) <= hours
  );
};

const Page = () => {
  const [{ id }, ,] = useLocationSearch();
  const [{ search }] = useLocationSearch();

  const { threads, isError, isLoading, refetch } = useThreadsQuery({
    machineId: id || "",
  });

  const { filters, handleSearch } = useTableFilters<any>({
    keys: ["id", "text"],
  });

  const [daysFilter, setDaysFilter] = useState<number>(0);
  const data = useMemo<any[]>(
    () => filterByTime(daysFilter as number, threads),
    [threads, daysFilter]
  );

  const { selectedItems, showDelete, showAdd, dispatch } = useThreadsStore(
    (state) => ({
      showDelete: state.showDelete,
      selectedItems: state.selectedItems as any[],
      showAdd: state.showAdd,
      dispatch: state.dispatch,
    })
  );

  const handleSelect = (items: IObjectWithKey[]) => {
    dispatch({ type: "SET_SELECTION", payload: items });
  };
  // const pageLoading = useMemo(() => isLoading || usersLoading, [isLoading, usersLoading]);

  // TODO: Add range filter for Date
  // TODO: Add sorter (eg. Date)
  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-threads",
        version: 1,
      },
      header: {
        title: "Threads",
      },
      items: data,
      filters,
      // isLoading: pageLoading,
      isLoading,
      isError,
      columns: getColumns({ locationSearch: search }),
      onSelectionChanged: (items: IObjectWithKey[]) => handleSelect(items),
    }),
    [filters, data, isError]
  );

  const onFilter = (days: number) => {
    setDaysFilter(days);
  };

  if (isLoading) {
    return <Spin style={{ height: "calc(100vh - 50px)" }} />;
  }

  if (isError) {
    return <NoData style={{ height: "calc(100vh - 50px)" }} />;
  }

  return (
    <>
      <div style={{ padding: "0 16px" }}>
        <CommandBar handleSearch={handleSearch} onFilter={onFilter} />
        <Table {...tableProps} />
      </div>
      <ThreadDialog
        hidden={!showAdd}
        onDismiss={() => dispatch({ type: "SHOW_ADD", payload: false })}
      />
      <DeleteDialog
        dialogContentProps={{
          onDismiss: () => dispatch({ type: "SHOW_DELETE", payload: false }),
        }}
        data={{
          ids: selectedItems.map((item) => item?.id),
          name: selectedItems.length === 1 ? selectedItems[0]?.text : "items",
        }}
        hidden={!showDelete}
        route={"/meta/machinecv/v1/comments"}
        onSuccess={refetch}
      />
    </>
  );
};

export default Page;
