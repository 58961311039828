import "./index.css";
import "./assets/styles/web-analysis.scss";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { mergeStyles } from "@fluentui/react";
import reportWebVitals from "./reportWebVitals";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import axios from "axios";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { ErrorState } from "./schema/reducer";
import { registerIcons } from "@fluentui/react/lib/Styling";
import Icon from "./Components/Icons/Icons";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

registerIcons({
  icons: {
    Home12: <Icon name="Home" />,
    Home12Blue: <Icon name="Home" color="#2C529F" />,
    TrendView: <Icon name="TrendView" />,
    RawView: <Icon name="RawView" />,
    Corporations: <Icon name="Corporations" />,
    Companies: <Icon name="Companies" />,
    Projects: <Icon name="Projects" />,
    Machines: <Icon name="Machines" />,
    Gateways: <Icon name="Gateways" />,
    SensorsNodes: <Icon name="SensorsNodes" />,
    CorporationsCoral: <Icon name="Corporations" color="coral" />,
    CompaniesCoral: <Icon name="Companies" color="coral" />,
    ProjectsCoral: <Icon name="Projects" color="coral" />,
    MachinesCoral: <Icon name="Machines" color="coral" />,
    GatewaysCoral: <Icon name="Gateways" color="coral" />,
    SensorsNodesCoral: <Icon name="SensorsNodes" color="coral" />,
    DashBoardsManagement: <Icon name="DashBoardsManagement" />,
    MachineCV: <Icon name="MachineCV" />,
    SensorConfiguration: <Icon name="SensorConfiguration" />,
    DashBoards: <Icon name="DashBoards" />,
    Users: <Icon name="Users" />,
    ListUsers: <Icon name="ListUsers" />,
    DeleteMe: <Icon name="Delete" />,
    Reveal: <Icon name="Reveal" />,
    UnReveal: <Icon name="UnReveal" />,
    NoImageIcon: <Icon name="NoImageIcon" />,
    Datalogger: <Icon name="Datalogger" />,
    Link: (
      <Icon name="Link" width="20" height="20" style={{ paddingTop: "3" }} />
    ),
    CloudUpgeared: <Icon name="CloudUpgeared" />,
    ApprovalSubmissions: (
      <Icon
        name="ApprovalSubmissions"
        width="24"
        height="24"
        style={{ padding: "1" }}
      />
    ),
    Images: <Icon name="Images" />,
    VpnIcon: <Icon width="17" height="17" name="VpnIcon" />,
  },
});

initializeIcons();

// Inject some global styles
mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: "100%",
  },
});

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL!,
  headers: { "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_KEY! },
  timeout: 1000 * 60 * 10,
});

class AuthInterceptor {
  private getToken: any = undefined;

  constructor() {
    this.intercept = this.intercept.bind(this);
    this.setAuthGetter = this.setAuthGetter.bind(this);
  }

  setAuthGetter(getToken) {
    this.getToken = getToken;
  }

  async intercept(config) {
    if (!this.getToken) {
      return config;
    }

    try {
      const token = await this.getToken({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
        },
      });
      config.headers["Authorization"] = `Bearer ${token}`;
    } catch (e) {
      console.log("AuthInterceptor", e);
    }
    return config;
  }
}

export const authInterceptor = new AuthInterceptor();
axiosInstance.interceptors.request.use(authInterceptor.intercept);

axiosInstance.interceptors.response.use(undefined, (error) => {
  const statusCode = error.response ? error.response.status : null;
  const errorObj: ErrorState = {
    show: true,
    error: error.response.statusText,
    status: error.response.status,
    text: error.response.data,
  };

  if (statusCode === 401) {
    return errorObj;
  }

  if (statusCode === 403) {
    return errorObj;
  }

  if (statusCode === 404) {
    return errorObj;
  }

  if (statusCode >= 500) {
    return {
      show: true,
      error: "Oops, something is not right",
      status: "Error at request",
      text: "Please try again later.",
    };
  }

  if (statusCode === 400) {
    return errorObj;
  }

  return Promise.reject(error);
});

function AuthInject() {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    authInterceptor.setAuthGetter(getAccessTokenSilently);
    return () => authInterceptor.setAuthGetter(undefined);
  }, [getAccessTokenSilently]);

  return null;
}

const AppProvider = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <AuthInject />
          <App />
        </Auth0Provider>
        ,
      </Provider>
    </BrowserRouter>
  );
};

const rootContainer = document.getElementById("root");
const root = ReactDOM.createRoot(rootContainer);

root.render(<AppProvider />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
