// PDFPreview.tsx
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useScreenSize } from "../../../Hooks";
import { NoData } from "../NoData";
import { Spin } from "../Spin";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type PDFPreviewProps = {
  src: string;
};

export const PDFPreview = ({ src }: PDFPreviewProps) => {
  const [numPages, setNumPages] = useState(0);
  const screenSize = useScreenSize();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <div
      className="pdf-container"
      style={{
        height: "calc(100vh - 120px)", // Ajusta según el espacio que necesites
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Document
        file={src}
        noData={<NoData />}
        loading={<Spin />}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <div key={`page_${index + 1}`} style={{ marginBottom: "20px" }}>
            <Page
              className="pdfPreview"
              scale={screenSize.width <= 1920 ? 1.25 : 2}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </div>
        ))}
      </Document>
    </div>
  );
};
