import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

interface RawDataPointsProps {
  lastSelectedDataPoint: string | undefined;
  isFetchingRawDataPoints: boolean;
  setLastSelectedDataPoint: (value: string) => void;
  setIsFetchingRawDataPoints: (value: boolean) => void;
}

const useRawDataPointsStore = createWithEqualityFn<RawDataPointsProps>((set) => ({
  lastSelectedDataPoint: undefined,
  isFetchingRawDataPoints: false,
  setLastSelectedDataPoint: (value: string) => set({ lastSelectedDataPoint: value }),
  setIsFetchingRawDataPoints: (value: boolean) => set({ isFetchingRawDataPoints: value }),
}), shallow);

export default useRawDataPointsStore;
