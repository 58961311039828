import { useQuery } from '@tanstack/react-query';

import { prepareSignalsWithP12H } from '../../../../modules/analysis-trend-view/components/TrendViewSelectSignals/utils';
import { apiService } from '../../../../modules/common';
import { conditionOrder } from '../SignalsSection/methods';
import type { ResponseSimplifiedSignal } from '../../../../types';

export const useTrendSignalsList = (id: string, options?: object) => {
  const {
    data = [],
    isLoading,
    isError,
  } = useQuery(
    ['signals-list', id],
    () => apiService.metaDataRead.getSignalsList(id as string),
    options,
  );

  const signals: ResponseSimplifiedSignal[] =
    prepareSignalsWithP12H(data)
      .filter((item: any) => ['Trend', 'FastTrend'].includes(item.dataType))
      .map((item: any) => ({
        ...item,
        conditionKey: conditionOrder[item.condition],
      })) || [];

  return {
    signals,
    isLoading,
    isError,
  };
};
