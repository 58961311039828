import { Stack, Text, TooltipHost } from "@fluentui/react";
import type { CSSProperties } from "react";
import { useState } from "react";
import * as ReactRouterDOM from "react-router-dom";

import DalogLogo from "../../../../../assets/svg/DalogLogo";
import FluentClock from "../../../../../assets/svg/FluentClock";
import FluentSchedule from "../../../../../assets/svg/FluentSchedule";
import MachineCvSensors from "../../../../../assets/svg/MachineCvSensors";
import MachineCvSummary from "../../../../../assets/svg/MachineCVSummary";
import MultipleComments from "../../../../../assets/svg/MultipleComments";
import RawDataIcon from "../../../../../assets/svg/RawDataIcon";
import TrendsIcon from "../../../../../assets/svg/TrendsIcon";
import { BookmarkButton } from "../../../../../modules/common/components/BookmarkButton";
import useBookmark from "../../../../../modules/common/components/Bookmarks/useBookmark";
import type { Condition } from "../../../../../modules/machine-cv/constants";
import {
  conditionColor,
  conditionFormat,
} from "../../../../../modules/machine-cv/constants";
import type { MachineCardType as IMachineCard } from ".";
import useOverviewStore from "../../../../MachineCV/MachineCVHomePage/hooks/useOverviewStore";
import Image from "../../../../common/Image";

type MachineCardProps = {
  data: IMachineCard;
};

const badgeContainerStyle: CSSProperties = {
  padding: "4px 8px",
  minHeight: 36,
  minWidth: 36,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};

export const links = [
  { name: "Summary", link: "/machine-cv/summary", icon: <MachineCvSummary /> },
  { name: "Sensors", link: "/machine-cv/sensors", icon: <MachineCvSensors /> },
  { name: "Tasks", link: "/machine-cv/tasks", icon: <FluentClock /> },
  {
    name: "Comments",
    link: "/machine-cv/comments",
    icon: <MultipleComments />,
  },
  { name: "Reports", link: "/machine-cv/reports", icon: <FluentSchedule /> },
  { name: "Trend", link: "/trend-view", icon: <TrendsIcon fill="#2c529f" /> },
  { name: "Raw Data", link: "/raw-data", icon: <RawDataIcon /> },
];

export const MachineCard = ({ data }: MachineCardProps) => {
  const { title, condition, iconId, query, id } = data;
  const { toggleBookmark, bookmarks } = useBookmark();
  const [isLoading, setIsLoading] = useState(false);
  const { changeSearch } = useOverviewStore();
  const addOrRemoveBookmark = () => {
    toggleBookmark({
      bookmark: { ...data },
      onStart: () => setIsLoading(true),
      onSuccess: () => setIsLoading(false),
      onFailed: () => setIsLoading(false),
    });
  };
  const isBookmarked = !!bookmarks.filter(
    (item: any) => item.id === data?.id
  )[0];

  const navigate = ReactRouterDOM.useNavigate();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      className="MachineCard__wrapper"
      onClick={() => {
        changeSearch("");
        navigate(`/machine-cv/summary?id=${id}`);
      }}
    >
      <div className="MachineCard__content">
        <Image
          id={iconId}
          alt={title}
          style={{ objectFit: "contain", margin: "4px auto", maxHeight: 132 }}
        />
        <div className="MachineCard__data">
          <div className="MachineCard__header">
            <div className="MachineCard__logo">
              <DalogLogo />
            </div>
            <BookmarkButton
              isBookmarked={isBookmarked}
              isLoading={isLoading}
              onClick={addOrRemoveBookmark}
            />
          </div>
          <div className="MachineCard__subtitle">{data?.subtitle}</div>
          <div className="MachineCard__title">{title}</div>
          <Stack
            horizontal
            horizontalAlign="center"
            style={{
              ...badgeContainerStyle,
              backgroundColor: conditionColor[condition as Condition],
            }}
          >
            <Text style={{ color: "#fff" }}>Condition</Text>
            <Text style={{ color: "#fff" }}>
              {conditionFormat[condition as Condition]}
            </Text>
          </Stack>
        </div>
      </div>
      <div className="MachineCard__buttons">
        {links.map(({ name, link, icon }) => (
          <TooltipHost
            key={name}
            content={name}
            styles={{ root: { display: "flex", cursor: "pointer" } }}
          >
            <ReactRouterDOM.Link
              to={`${link}?${query}`}
              className="router-link"
              style={{ margin: "0 5px" }}
              onClick={(e) => e.stopPropagation()}
            >
              {icon}
            </ReactRouterDOM.Link>
          </TooltipHost>
        ))}
      </div>
    </div>
  );
};
