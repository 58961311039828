import type { SVGProps } from 'react';
import React from 'react';

const TaskAddIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 17C6.98528 17 9 14.9853 9 12.5C9 10.0147 6.98528 8 4.5 8C2.01472 8 0 10.0147 0 12.5C0 14.9853 2.01472 17 4.5 17ZM4.52856 9.93652C4.25242 9.93652 4.02856 10.1604 4.02856 10.4365V11.9365H2.52856C2.25242 11.9365 2.02856 12.1604 2.02856 12.4365C2.02856 12.7127 2.25242 12.9365 2.52856 12.9365H4.02856V14.4365C4.02856 14.7127 4.25242 14.9365 4.52856 14.9365C4.80471 14.9365 5.02856 14.7127 5.02856 14.4365V12.9365H6.52856C6.80471 12.9365 7.02856 12.7127 7.02856 12.4365C7.02856 12.1604 6.80471 11.9365 6.52856 11.9365H5.02856V10.4365C5.02856 10.1604 4.80471 9.93652 4.52856 9.93652Z'
        fill='#2C529F'
      />
      <path
        d='M14.6569 2.34315C13.1566 0.842855 11.1217 0 9 0C6.87827 0 4.84344 0.842855 3.34315 2.34315C1.84285 3.84344 1 5.87827 1 8C1 8.17312 1.00561 8.34567 1.01673 8.51739C1.31708 8.25449 1.64741 8.02502 2.00195 7.83478C2.02232 6.97183 2.20215 6.11958 2.53284 5.32122C2.88463 4.47194 3.40024 3.70026 4.05025 3.05025C4.70026 2.40024 5.47194 1.88463 6.32122 1.53284C7.1705 1.18106 8.08075 1 9 1C10.8565 1 12.637 1.7375 13.9497 3.05025C15.2625 4.36301 16 6.14348 16 8C16 9.85652 15.2625 11.637 13.9497 12.9497C12.676 14.2235 10.9619 14.9557 9.16521 14.9981C8.97498 15.3526 8.74551 15.6829 8.48261 15.9833C8.65433 15.9944 8.82688 16 9 16C11.1217 16 13.1566 15.1571 14.6569 13.6569C16.1571 12.1566 17 10.1217 17 8C17 5.87827 16.1571 3.84344 14.6569 2.34315Z'
        fill='#2C529F'
      />
      <path
        d='M8.82026 3.11589C8.73036 3.04097 8.61703 2.99996 8.5 3C8.36739 3 8.24021 3.05268 8.14645 3.14645C8.05268 3.24021 8 3.36739 8 3.5V8.5L8.008 8.59C8.02907 8.70511 8.08984 8.80919 8.17974 8.88411C8.26964 8.95903 8.38297 9.00004 8.5 9H11.5L11.59 8.992C11.7129 8.96974 11.8231 8.90225 11.8988 8.80283C11.9745 8.70341 12.0102 8.57926 11.9989 8.45482C11.9876 8.33039 11.9301 8.21469 11.8378 8.13051C11.7455 8.04633 11.6249 7.99977 11.5 8H9V3.5L8.992 3.41C8.97093 3.29489 8.91016 3.19081 8.82026 3.11589Z'
        fill='#2C529F'
      />
    </svg>
  );
};

export default TaskAddIcon;
