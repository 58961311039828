import type { ISpinButtonStyles } from "@fluentui/react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  SpinButton,
} from "@fluentui/react";
import { useRef } from "react";

import useWaterfallStore from "../../hooks/useWaterfallStore";

interface ReductionDialogProps {
  // reduction: number;
  // updateReduction: (value: number) => void;
  hideDialog: boolean;
  toggleDialog: () => void;
}

const ReductionDialog = ({
  // reduction,
  // updateReduction,
  hideDialog,
  toggleDialog,
}: ReductionDialogProps) => {
  const reductionInputRef = useRef<any>(null);

  const { reduction, setReduction } = useWaterfallStore((state) => ({
    reduction: state.reduction,
    setReduction: state.setReduction,
  }));

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Reduction",
    subText: "Please enter the reduction amount.",
    closeButtonAriaLabel: "Close",
  };

  const dialogStyles = {
    main: {
      selectors: {
        ".ms-Dialog-title": {
          paddingBottom: 0,
        },
      },
    },
  };

  const inputStyles: Partial<ISpinButtonStyles> = {
    spinButtonWrapper: { minWidth: 84, width: 84, marginLeft: "auto" },
  };

  const onSetReduction = () => {
    if (!reductionInputRef.current) return;

    const reductionInputValue = Number(reductionInputRef.current.value);
    // updateReduction(reductionInputValue);
    // dispatch({ type: 'SET_REDUCTION', payload: reductionInputValue });
    setReduction(reductionInputValue);
    toggleDialog();
  };

  return (
    <Dialog
      hidden={hideDialog}
      dialogContentProps={dialogContentProps}
      styles={dialogStyles}
      onDismiss={toggleDialog}
    >
      <SpinButton
        componentRef={reductionInputRef}
        label="Reduction amount"
        defaultValue={reduction.toString()}
        min={1}
        max={1000}
        step={1}
        styles={inputStyles}
        incrementButtonAriaLabel="Increase value by 1"
        decrementButtonAriaLabel="Decrease value by 1"
      />

      <DialogFooter>
        <PrimaryButton text="Set reduction" onClick={onSetReduction} />
        <DefaultButton text="Cancel" onClick={toggleDialog} />
      </DialogFooter>
    </Dialog>
  );
};

export default ReductionDialog;
