import { Stack, Text } from "@fluentui/react";
import React from "react";

import FastTrendSignalIcon from "../../../assets/svg/FastTrendSignalIcon";
import RawDataSignalIcon from "../../../assets/svg/RawDataSignalIcon";
import TrendSignalIcon from "../../../assets/svg/TrendSignalIcon";

const TrendViewSelectSignalsInfo = () => {
  return (
    <Stack style={{ background: "#fff", padding: "1rem" }}>
      <Stack horizontal verticalAlign="center" style={{ marginBottom: "8px" }}>
        <TrendSignalIcon />
        <Text style={{ marginLeft: "8px" }}>Trend</Text>
      </Stack>
      <Stack horizontal verticalAlign="center" style={{ marginBottom: "8px" }}>
        <FastTrendSignalIcon />
        <Text style={{ marginLeft: "8px" }}>Fast Trend</Text>
      </Stack>
      <Stack horizontal verticalAlign="center">
        <RawDataSignalIcon />
        <Text style={{ marginLeft: "8px" }}>Raw Data</Text>
      </Stack>
    </Stack>
  );
};

export default TrendViewSelectSignalsInfo;
