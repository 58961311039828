import LazyCollapse from "./LazyCollapse";
import SettingsContent from "./SettingsContent";

const SettingsForm = ({
  onSubmit,
  form,
  activeSettings,
  expandedSettings,
  handleCollapseToggle,
  sensorNodeId,
}: any) => {
  return (
    <form onSubmit={onSubmit}>
      {activeSettings.map((settingName: string, index: number) => {
        const isExpanded = expandedSettings.includes(settingName);
        return (
          <LazyCollapse
            key={`${settingName}-${index}`}
            title={settingName}
            isCollapsed={!isExpanded}
          >
            <SettingsContent
              handleCollapseToggle={handleCollapseToggle}
              form={form}
              index={index}
              settingName={settingName}
              sensorNodeId={sensorNodeId}
            />
          </LazyCollapse>
        );
      })}
    </form>
  );
};

export default SettingsForm;
