import type { IObjectWithKey } from "@fluentui/react";

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

type Actions =
  | { type: "SET_SELECTION"; payload: IObjectWithKey[] }
  | { type: "SHOW_DELETE"; payload: boolean }
  | { type: "SHOW_UPLOAD"; payload: boolean };

export interface DocumentsStore {
  selectedItems: IObjectWithKey[];
  showDelete: boolean;
  showUpload: boolean;
  dispatch: (action: Actions) => void;
}

const reducer = (state: DocumentsStore, action: Actions): DocumentsStore => {
  switch (action.type) {
    case "SET_SELECTION": {
      return {
        ...state,
        selectedItems: action.payload,
      };
    }
    case "SHOW_DELETE": {
      return {
        ...state,
        showDelete: action.payload,
      };
    }
    case "SHOW_UPLOAD": {
      return {
        ...state,
        showUpload: action.payload,
      };
    }
  }
};

const useDocumentsStore = createWithEqualityFn<DocumentsStore>(
  (set) => ({
    selectedItems: [],
    showDelete: false,
    showUpload: false,
    dispatch: (action) => set((state) => reducer(state, action)),
  }),
  shallow
);

export default useDocumentsStore;
