/* eslint-disable jsx-a11y/click-events-have-key-events */

import "../styles.scss";

import { IconButton } from "@fluentui/react";

import TaskAddIcon from "../../../../assets/svg/TaskAddIcon";
import { format } from "../../../../utils";
import { useWebAnalysisPermissions } from "../../../../Hooks";
import { REPORT_CATEGORIES } from "../config";
import ReportText from "../../MachineCVSummaryPage/DetailsSection/ReportsTab/ReportText";
import type { Report } from "../../../../types";
import type { Column } from "../../../common/Table/Table";

type GetColumnsProps = {
  onEdit: (report: Report) => void;
  onDelete: (report: Report) => void;
  onAddTask: (report: Report) => void;
};

type DeleteReportData = Report & {
  createdById: string;
};

type ActionsMenuProps = GetColumnsProps & {
  report: DeleteReportData;
};

const actionIconStyle = {
  root: {
    color: "#2C529F",
    height: 16,
    padding: 0,
  },
  rootHovered: {
    backgroundColor: "transparent",
  },
  rootFocused: {
    backgroundColor: "transparent",
  },
  rootPressed: {
    backgroundColor: "transparent",
  },
};

const ActionsMenu = ({
  report,
  onEdit,
  onDelete,
  onAddTask,
}: ActionsMenuProps) => {
  const isDeleteVisible = Object.values(REPORT_CATEGORIES)
    .map((s) => s.toString())
    .includes(report.category);

  const { useReadyPermission, checkCreatorUserIDMatch } =
    useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });
  const hasEditDeleteActions =
    hasMachineCvAdministratorPermission ||
    checkCreatorUserIDMatch(report.createdById ?? "");

  return (
    <div style={{ display: "flex" }}>
      <TaskAddIcon
        style={{ cursor: "pointer", margin: "0 8px 0 4px" }}
        onClick={() => onAddTask(report)}
      />
      {hasEditDeleteActions && (
        <IconButton
          iconProps={{ iconName: "Edit" }}
          title="Edit"
          ariaLabel="Edit"
          styles={actionIconStyle}
          onClick={() => onEdit(report)}
        />
      )}
      {hasEditDeleteActions && isDeleteVisible && (
        <IconButton
          iconProps={{ iconName: "Delete" }}
          title="Delete"
          ariaLabel="Delete"
          styles={actionIconStyle}
          onClick={() => onDelete(report)}
        />
      )}
    </div>
  );
};

export const getColumns = ({
  onEdit,
  onDelete,
  onAddTask,
}: GetColumnsProps): Column[] => {
  return [
    {
      key: "text",
      fieldName: "text",
      name: "Text",
      flexGrow: 2,
      calculatedWidth: 0,
      minWidth: 200,
      isSortable: true,
      onRender: (report) => <ReportText report={report} />,
    },
    {
      key: "category",
      fieldName: "category",
      name: "Category",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "createdBy",
      fieldName: "createdBy",
      name: "Created By",
      minWidth: 100,
      isSortable: true,
      isExportable: false,
    },
    {
      key: "createdAt",
      fieldName: "createdAt",
      name: "Created At",
      minWidth: 100,
      isSortable: true,
      onRender: (report) => format(new Date(report.createdAt)),
    },
    {
      key: "actions",
      fieldName: "actions",
      name: "Actions",
      minWidth: 100,
      onRender: (report) => (
        <ActionsMenu
          report={report}
          onEdit={onEdit}
          onDelete={onDelete}
          onAddTask={onAddTask}
        />
      ),
    },
  ];
};
