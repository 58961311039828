import type { AxiosInstance } from "axios";

import AppLayout from "../../../templates/AppLayout";

import { Page } from "./Page";

interface DashboardIFrameProps {
  axiosInstance: AxiosInstance;
}

const DashboardIFrame = ({ axiosInstance }: DashboardIFrameProps) => (
  <AppLayout axiosInstance={axiosInstance}>
    <Page />
  </AppLayout>
);

export default DashboardIFrame;
