import React from "react";

import BaseCommandBar from "../../../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../../../common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../../common/CommandBar/methods";

type TaskDocumentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onUpload: () => void;
};

export const CommandBar = ({
  handleSearch,
  onUpload,
}: TaskDocumentsCommandBarProps) => {
  const items: CommandBarItemProps[] = [
    {
      key: "addDocuments",
      text: "Add documents",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onUpload,
    },
  ];

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      onSearch={handleSearch}
    />
  );
};
