export const binarySearch = (array: object[], compareFunc: (x: any) => number) => {
  let start = 0;
  let end = array.length - 1;

  while (end - start > 1) {
    const middle = (end + start) >> 1;

    const result = compareFunc(array[middle]);

    if (result === 0) {
      return middle;
    }

    if (result > 0) {
      end = middle;
    } else {
      start = middle;
    }
  }

  return end;
};
