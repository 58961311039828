import type { CSSProperties } from "react";
import { useEffect, useRef, useState } from "react";
import type { MapRef, ViewportProps } from "react-map-gl";
import ReactMapGL from "react-map-gl";
import type { UseSuperclusterArgument } from "use-supercluster";
import useSupercluster from "use-supercluster";

import { useMap } from "./MapProvider";
import MarkerMap from "./MarkerMap";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

type _AnyProps = { [prop: string]: unknown };

type _Internal = UseSuperclusterArgument<_AnyProps, _AnyProps>;

type Point = _Internal["points"][0];

type Bounds = _Internal["bounds"];

type MapProps = ViewportProps & {
  points: Point[];
  onChange?: (points: Point[]) => void;
  style?: CSSProperties;
};

export const Map = ({ points, onChange, ...rest }: MapProps) => {
  const ref = useRef<MapRef | null>(null);

  const {
    state: { latitude, longitude, zoom },
    actions: { changeViewport },
  } = useMap();

  const [bounds, setBounds] = useState<Bounds>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setBounds(ref.current.getMap().getBounds().toArray().flat());
  }, [ref.current, latitude, longitude, zoom]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 20 },
  });

  useEffect(() => {
    if (!onChange) {
      return;
    }

    const pointsInView = clusters
      ?.map((cluster) => {
        if (cluster.properties.cluster) {
          return supercluster?.getLeaves(cluster.id as number, Infinity);
        }
        return cluster;
      })
      .flat(Infinity) as Point[];

    onChange(pointsInView);
  }, [clusters]);

  return (
    <ReactMapGL
      ref={ref}
      width="100%"
      height="60vh"
      {...rest}
      zoom={zoom}
      latitude={latitude}
      longitude={longitude}
      attributionControl={false}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      mapStyle="mapbox://styles/alexmatei/clee6dffh000z01mq981wm9eu"
      onViewportChange={changeViewport}
    >
      {clusters.map((cluster) => (
        <MarkerMap
          key={cluster.properties.cluster ? cluster.id : cluster.properties.id}
          cluster={cluster}
          supercluster={supercluster}
        />
      ))}
    </ReactMapGL>
  );
};
