import type { IDialogProps } from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import {
  useLocationSearch,
  getFileIds,
  useFileDelete,
  useFileUpload,
} from "../../../../../../../Hooks";
import AddDocumentDialog from "../../../../../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import { getColumns } from "../../../../../MachineCVInfoPage/components/DocumentsTable/columns";
import { useTask } from "../../../../hooks/useTask";
import {
  useTaskPostComment,
  useTaskUpdateComment,
} from "../../../../hooks/useTaskMutations";
import useTasksStore from "../../../../hooks/useTasksStore";
import type { Comment } from "../../../../../../../types";
import BaseDialog, { DialogSize } from "../../../../../../common/Dialog/Dialog";
import { renderFormItems, useZodForm } from "../../../../../../common/Form";
import type { FormItemProps } from "../../../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../../../common/Notification";
import type { TableProps } from "../../../../../../common/Table";
import Table from "../../../../../../common/Table";
import UploaderButton from "../../../../../../common/Uploader/UploaderButton";

const dialogAddContentProps = {
  type: DialogType.normal,
  title: "New comment",
  closeButtonAriaLabel: "Close",
};

const dialogEditContentProps = {
  type: DialogType.normal,
  title: "Edit comment",
  closeButtonAriaLabel: "Close",
};

type CommentDialogProps = {
  isReply?: boolean;
  comment?: Comment;
  id: string;
  onSuccess?: () => void;
} & IDialogProps;

const schema = z.object({
  text: z.string(),
});

export const CommentDialog: FC<CommentDialogProps> = (props) => {
  const { edit } = useTasksStore((state) => ({
    edit: state.edit,
  }));
  const { hidden, comment, isReply } = props;
  const [files, setFiles] = useState(!isReply ? comment?.files || [] : []);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentsDialogHidden, setIsDocumentsDialogHidden] = useState(true);
  const toggleIsDocumentsDialogHidden = () =>
    setIsDocumentsDialogHidden(!isDocumentsDialogHidden);
  const { getTaskDetails } = useTask({ taskId: edit.id });

  useEffect(() => {
    if (hidden) {
      setFiles([]);
    } else {
      setFiles(!isReply ? comment?.files || [] : []);
    }
    reset(comment);
  }, [hidden]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    ...(!!comment && {
      defaultValues: {
        text: comment?.text,
      },
    }),
    schema,
  });

  const taskFields: FormItemProps[] = [
    {
      name: "text",
      type: FormItemType.TextArea,
      placeholder: "Your message goes in here...",
      resizable: true,
    },
  ];

  const [{ id }] = useLocationSearch();
  const { postTaskCommentAsync } = useTaskPostComment();
  const { updateTaskCommentAsync } = useTaskUpdateComment();
  const { uploadFilesAsync } = useFileUpload();
  const { deleteFilesAsync } = useFileDelete();

  const onAdd = async (data: { text?: string }) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);

    const submitData = { ...data, fileIds: successIds };
    await postTaskCommentAsync({ id: edit.id || "", data: submitData })
      .then(() => {
        if (uploadResponse.length === successIds.length) {
          notification.success("Comment successfully posted");
        } else {
          notification.success(
            `Comment successfully posted with ${successIds.length} out of ${uploadResponse.length} files`
          );
        }

        getTaskDetails();
        onClose();
      })
      .catch(() => {
        notification.error("Error posting comment");
        deleteFilesAsync(successIds);
      })
      .finally(() => setIsLoading(false));
  };

  const onEdit = async (data: { text?: string }) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);

    const submitData = {
      ...data,
      fileIds: [
        ...files.filter((file) => !file.isValid).map((file) => file.id),
        ...successIds,
      ],
    };
    await updateTaskCommentAsync({
      id: edit.id || "",
      commentId: comment?.id || "",
      data: submitData,
    })
      .then(() => {
        if (uploadResponse.length === successIds.length) {
          notification.success("Comment successfully updated");
        } else {
          notification.success(
            `Comment successfully updated with ${successIds.length} out of ${uploadResponse.length} files`
          );
        }

        getTaskDetails();
        onClose();
      })
      .catch(() => {
        notification.error("Error updating comment");
        deleteFilesAsync(successIds);
      })
      .finally(() => setIsLoading(false));
  };

  const onSubmit = handleSubmit(async (data) => {
    if (comment && !isReply) {
      onEdit(data);
      return;
    }

    onAdd(data);
  });

  const onClose = () => {
    props.onDismiss?.();
  };

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-edit-task-comments",
        version: 1,
      },
      items: files,
      perPage: 5,
      hidePerPage: true,
      hasSelection: false,
      columns: getColumns({
        onRemove: (fileId) => {
          setFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
      }),
    }),
    [files]
  );

  return (
    <>
      <BaseDialog
        {...props}
        size={DialogSize.AUTO}
        dialogContentProps={
          comment && !isReply ? dialogEditContentProps : dialogAddContentProps
        }
      >
        <form onSubmit={onSubmit}>
          {renderFormItems(taskFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <UploaderButton onClick={toggleIsDocumentsDialogHidden} />
          <Table {...tableProps} />
          <DialogFooter>
            <PrimaryButton
              type="submit"
              text={comment && !isReply ? "Update comment" : "Post comment"}
              disabled={isLoading}
              onRenderIcon={() =>
                isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
              }
            />
            <DefaultButton
              styles={{
                root: { border: "unset", background: "transparent" },
              }}
              text="Cancel"
              onClick={onClose}
            />
          </DialogFooter>
        </form>
      </BaseDialog>
      <AddDocumentDialog
        hidden={isDocumentsDialogHidden}
        onSubmit={(accepted) => setFiles([...files, ...accepted])}
        onClose={toggleIsDocumentsDialogHidden}
      />
    </>
  );
};
