import type { IButtonProps } from "@fluentui/react";
import { ActionButton } from "@fluentui/react";
import classnames from "classnames";
import { models } from "powerbi-client";
import type { EmbedProps } from "powerbi-client-react";
import { EmbedType, PowerBIEmbed } from "powerbi-client-react";
import type { ReactElement } from "react";
import { useMemo, useRef, useState } from "react";
import { useFullscreen, useToggle } from "react-use";

import MaximizeIcon from "../../../assets/svg/MaximizeIcon";
import MinimizeIcon from "../../../assets/svg/MinimizeIcon";

import ImagePlaceholder from "../ImagePlaceholder";
import { Spin } from "../Spin";

const defaultEmbedConfig: models.IReportEmbedConfiguration = {
  type: EmbedType.Report,
  tokenType: models.TokenType.Embed,
  viewMode: models.ViewMode.View,
  settings: {
    panes: {
      filters: {
        expanded: false,
        visible: false,
      },
      pageNavigation: {
        visible: false,
      },
    },
  },
};

export type PowerBiProps = Omit<EmbedProps, "embedConfig"> & {
  config?: {
    id: string;
    url: string;
    token: string;
  };
  isLoading: boolean;
  isError: boolean;
  embedConfig?: Omit<
    models.IReportEmbedConfiguration,
    "id" | "embedUrl" | "accessToken"
  >;
  className?: string;
};

const PowerBi = ({
  isLoading,
  isError,
  config,
  embedConfig: _embedConfig,
  cssClassName,
  className,
  ...rest
}: PowerBiProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [showFullscreen, toogleFullscreen] = useToggle(false);

  const isFullscreen = useFullscreen(ref, showFullscreen, {
    onClose: () => toogleFullscreen(false),
  });

  const [loaded, setLoaded] = useState(false);

  const events = useMemo(
    () => new Map([["loaded", () => setLoaded(true)]]),
    []
  );

  const innerEmbedConfig =
    useMemo<models.IReportEmbedConfiguration | null>(() => {
      if (isLoading || isError || !config) {
        return null;
      }

      return {
        ...defaultEmbedConfig,
        ..._embedConfig,
        id: config.id,
        embedUrl: config.url,
        accessToken: config.token,
      };
    }, [config, isLoading, isError]);

  const fullscreen = useMemo<{
    text: string;
    icon: ReactElement;
    props: IButtonProps;
  }>(() => {
    if (isFullscreen) {
      return {
        text: "Exit fullscreen",
        icon: (
          <MinimizeIcon style={{ width: 21, height: 21, marginRight: 8 }} />
        ),
        props: {
          ariaLabel: "Exit fullscreen",
          title: "Exit fullscreen",
          styles: {
            root: {
              marginRight: 16,
            },
          },
          onClick: () => toogleFullscreen(),
        },
      };
    }

    return {
      text: "Fullscreen",
      icon: <MaximizeIcon style={{ width: 21, height: 21, marginRight: 8 }} />,
      props: {
        ariaLabel: "Fullscreen",
        title: "Fullscreen",
        disabled: isLoading || isError || !innerEmbedConfig || !loaded,
        styles: {
          root: {
            marginRight: 16,
          },
        },
        onClick: () => toogleFullscreen(),
      },
    };
  }, [isFullscreen, isLoading, isError, innerEmbedConfig, loaded]);

  if (isLoading) {
    return <Spin style={{ height: 500 }} />;
  }

  if (isError || !innerEmbedConfig) {
    return (
      <div style={{ height: 500 }}>
        <ImagePlaceholder text="There seems to be an issue loading your iFrame (embed for Project iFrame)" />
      </div>
    );
  }

  return (
    <div ref={ref} className={classnames("power-bi", className)}>
      <div className="power-bi__header">
        <ActionButton {...fullscreen.props}>
          {fullscreen.icon}
          <span>{fullscreen.text}</span>
        </ActionButton>
      </div>
      <PowerBIEmbed
        embedConfig={innerEmbedConfig}
        cssClassName={classnames(
          {
            "power-bi__embed": true,
            "power-bi__embed--fullscreen": isFullscreen,
          },
          cssClassName
        )}
        {...rest}
        eventHandlers={events}
      />
    </div>
  );
};

export default PowerBi;
