export const layoutOptions = [
  {
    key: 'waveform-spectrum',
    text: 'Waveform and Spectrum',
    data: { reset: true },
  },
  {
    key: 'trend-waveform-spectrum',
    text: 'Trend, Waveform and Spectrum',
    data: { reset: true },
  },
  {
    key: 'compare-waveform-spectrum',
    text: 'Compare Waveform and Spectrum',
    data: { reset: true },
  },
  {
    key: 'waterfall',
    text: 'Waterfall',
    data: { reset: true },
  },
];
