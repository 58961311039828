import { useQueryClient } from "@tanstack/react-query";
import { isUndefined } from "lodash-es";
import { useEffect, useMemo, useState } from "react";

import { prefetchTrendMeasuredDataInAPeriod } from "../../../Hooks";
import useRefreshStore from "../../common/hooks/useRefreshStore";
import { getIntervalDuration } from "../../common/utils/getIntervalDuration";
import { useSignalsList } from "../../../Components/MachineCV/MachineCVSummaryPage/hooks/useSignalsList";
import type { ResponseSimplifiedSignal } from "../../../types";

import { CHART_NUMBER_OF_POINTS } from "../constants/controlOptions";
import getPeriodFilters from "../utils/getPeriodFilters";
import useControlsStore from "./useControlsStore";
import useSingleTrendViewMeasuredData from "./useSingleTrendViewMeasuredData";

const useSignalMonitoring = (signal: ResponseSimplifiedSignal) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const { refreshInterval } = useRefreshStore(({ refreshInterval }) => ({
    refreshInterval,
  }));

  const { period, averageTrend } = useControlsStore((store) => ({
    period: store.period,
    averageTrend: store.averageTrend,
  }));

  const signalToRequest = useMemo(() => {
    if (averageTrend && signal?.p12h) {
      return signal.p12h;
    }
    return signal;
  }, [averageTrend, signal?.id]);

  const { signals } = useSignalsList(signalToRequest.machineId as string);

  const filters = useMemo(
    () => getPeriodFilters(period, signalToRequest),
    [
      period?.key,
      period?.startDate,
      period?.endDate,
      signalToRequest,
      refreshInterval?.key,
    ]
  );

  const {
    isLoading: isLoadingSignal,
    plotAxis: signalPlotAxis,
    refetch: refetchSingleTrendViewMeasuredData,
  } = useSingleTrendViewMeasuredData({
    machineId: signalToRequest.machineId,
    signalId: signalToRequest.id,
    queryEnabled: true,
    signal: signalToRequest,
    refreshInterval,
  });

  // This is just the item for the dropdown so it has just the signalId on the key property
  const selectedReferenceSignalId = useControlsStore(
    (state) => state.referenceSignal
  );

  // Here we take the actual object of reference signal
  const queryClient = useQueryClient();

  const referenceSignal = queryClient
    ?.getQueryData<ResponseSimplifiedSignal[]>(["signals-list"])
    ?.find(({ id }) => id === selectedReferenceSignalId?.key);

  const refetchReferenceSignalFilter = useMemo(() => {
    const refSig = signals.find((sig: any) => sig.id === referenceSignal?.id);
    if (averageTrend && refSig?.p12h) {
      return refSig.p12h;
    }
    return referenceSignal;
  }, [averageTrend, referenceSignal?.id]);

  const {
    isFetching: isFetchingReferenceSignal,
    isSuccess: isSuccessReferenceSignal,
    plotAxis: referenceSignalPlotAxis,
    refetch: refetchReferenceSignal,
  } = useSingleTrendViewMeasuredData({
    machineId: signalToRequest.machineId,
    signalId: refetchReferenceSignalFilter?.id,
    queryEnabled: !!refetchReferenceSignalFilter?.id,
    signal: referenceSignal as ResponseSimplifiedSignal,
    refreshInterval,
  });

  useEffect(() => {
    if (isUndefined(referenceSignal)) return;

    const filters = getPeriodFilters(
      period,
      referenceSignal as ResponseSimplifiedSignal
    );

    prefetchTrendMeasuredDataInAPeriod({
      machineId: referenceSignal.machineId,
      signalId: referenceSignal.id,
      startDate: filters.startDate,
      endDate: filters.endDate,
      numberOfPoints: CHART_NUMBER_OF_POINTS,
      signalType: referenceSignal.dataType,
      refreshInterval,
    });
  }, [
    referenceSignal,
    period?.key,
    period?.startDate,
    period?.endDate,
    refreshInterval?.key,
  ]);

  useEffect(() => {
    prefetchTrendMeasuredDataInAPeriod({
      machineId: signalToRequest.machineId,
      signalId: signalToRequest.id,
      startDate: filters.startDate,
      endDate: filters.endDate,
      numberOfPoints: CHART_NUMBER_OF_POINTS,
      signalType: signalToRequest.dataType,
      refreshInterval,
    });
  }, [filters.startDate, filters.endDate]);

  /************************************************************************** */

  // Update currentDateTime based on the refresh interval
  useEffect(() => {
    const intervalDuration = getIntervalDuration(
      refreshInterval?.key as string
    );

    if (intervalDuration === 0) return;

    const nextIntervalTime =
      Math.ceil(currentDateTime.getTime() / intervalDuration) *
      intervalDuration;
    const timeoutDuration = nextIntervalTime - currentDateTime.getTime();

    const timeout = setTimeout(() => {
      setCurrentDateTime(new Date());
    }, timeoutDuration);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentDateTime, refreshInterval?.key, referenceSignal]);

  // Trigger refetchSignalMetadata when currentDateTime changes
  useEffect(() => {
    if (!isUndefined(referenceSignal)) {
      refetchReferenceSignal();
    }

    refetchSingleTrendViewMeasuredData();
  }, [currentDateTime, period?.key, refreshInterval?.key, referenceSignal]);

  return {
    isLoading: isLoadingSignal || isFetchingReferenceSignal,
    isSuccessReferenceSignal,
    signalPlotAxis,
    referenceSignalPlotAxis,
  };
};

export default useSignalMonitoring;
