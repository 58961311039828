import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useQueries } from "@tanstack/react-query";
import { useEffect } from "react";

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import { queryClient } from "../../../core";
import { getApiClient } from "../../../core/apiClient/useApiStore";

import { getHarmonicsKey, removeAxisRanges } from "../SpectrumChart/core";

export type CatalogueStoreData = {
  groupsAndItems: FrequencyCatalogueGroupsAndItemsResponse;
  isOpen: boolean;
  referenceSpeedSignal: any;
  machineName: string;
};

export type FrequencyCatalogueGroupsAndItemsResponse = {
  groups: FrequencyCatalogueGroupPageDetails[];
  items: FrequencyCatalogueDbEntry[];
};

export type FrequencyCatalogueGroupPageDetails = {
  key?: string;
  id: string;
  name: string;
  description: string;
  count: number;
  startIndex: number;
};

export type FrequencyCatalogueDbEntry = {
  key?: string;
  id: string;
  machineId: string;
  groupName: string;
  itemName: string;
  frequencyInHz: number;
  description: string;
};

const defaultCatalogueStoreData = {
  chartType: "Spectrum",
  chart: {
    rootRef: {},
    xAxisRef: {},
    chartRef: {},
  },
  isOpen: false,
};

function generateRandomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

interface CatalogueStoreState {
  keyedCatalogueData: { [key: string]: CatalogueStoreData };
  selectedSignalsStores: { [key: string]: any };
  initSelectedSignalsStore: (ids: string[]) => void;
  setCatalogueData: (
    machineId: string,
    payload: FrequencyCatalogueGroupsAndItemsResponse
  ) => void;
  toggleIsCatalogueOpen: (machineId: string, open: boolean) => void;
  setReferenceSpeedSignal: (machineId: string, payload: any) => void;
  setMachineName: (machineId: string, payload: any) => void;
}

export const useCatalogueStore = createWithEqualityFn<CatalogueStoreState>(
  (set: any, get: any) => ({
    keyedCatalogueData: {},
    selectedSignalsStores: {},
    initSelectedSignalsStore: (ids: any) => {
      const selectedSignalsStores = ids.reduce((acc: any, curr: any) => {
        acc[curr] = defaultCatalogueStoreData;
        return acc;
      }, {});
      set({ selectedSignalsStores });
    },
    toggleIsCatalogueOpen: (machineId: string, open: boolean) => {
      set((store: CatalogueStoreState) => {
        const updatedCatalogueData = Object.keys(
          store.keyedCatalogueData
        ).reduce((acc, key) => {
          acc[key] = {
            ...store.keyedCatalogueData[key],
            isOpen: key === machineId ? open : false,
          };
          return acc;
        }, {} as typeof store.keyedCatalogueData);

        return {
          ...store,
          keyedCatalogueData: updatedCatalogueData,
        };
      });
    },
    setCatalogueData: (
      machineId: string,
      payload: FrequencyCatalogueGroupsAndItemsResponse
    ) => {
      set((store: CatalogueStoreState) => {
        return {
          ...store,
          keyedCatalogueData: {
            ...store.keyedCatalogueData,
            [machineId]: {
              ...store.keyedCatalogueData[machineId],
              groupsAndItems: payload,
            },
          },
        };
      });
    },
    setSelectedSignalValues: (id: string, payload: any) => {
      set((store: any) => {
        return {
          ...store,
          selectedSignalsStores: {
            ...store.selectedSignalsStores,
            [id]: { ...store.selectedSignalsStores[id], ...payload },
          },
        };
      });
    },
    setReferenceSpeedSignal: (machineId: string, payload: any) => {
      set((store: CatalogueStoreState) => {
        return {
          ...store,
          keyedCatalogueData: {
            ...store.keyedCatalogueData,
            [machineId]: {
              ...store.keyedCatalogueData[machineId],
              referenceSpeedSignal: payload,
            },
          },
        };
      });
    },
    setMachineName: (machineId: string, payload: any) => {
      set((store: CatalogueStoreState) => {
        return {
          ...store,
          keyedCatalogueData: {
            ...store.keyedCatalogueData,
            [machineId]: {
              ...store.keyedCatalogueData[machineId],
              machineName: payload,
            },
          },
        };
      });
    },

    createLine: ({
      signalId,
      linesF,
      color,
      key,
    }: {
      signalId: string;
      linesF: number;
      color: string;
      key: string;
      type?: string;
    }) => {
      const selectedSignalStore = get()?.selectedSignalsStores?.[signalId];

      if (selectedSignalStore.chartType === "Waterfall") return;

      const root = selectedSignalStore?.chart?.rootRef?.current;
      const xAxis = selectedSignalStore?.chart?.xAxisRef?.current;
      const chart = selectedSignalStore?.chart?.chartRef?.current;

      if (!root || !xAxis || !chart) return;

      removeAxisRanges({ xAxis, key });

      if (linesF === 0) return;

      const start = 0;

      // === Create Harmonic ===

      const range = xAxis.createAxisRange(
        xAxis.makeDataItem({
          above: true,
        })
      );

      const rangeLabel = range.get("label");

      rangeLabel?.setAll({
        ariaLabel: getHarmonicsKey(key),
        text: `${(start + linesF).toFixed(2)}`,
        fill: am5.color(0xffffff),
        fontSize: 10,
        layer: 100,
        inside: true,
        dy: -15,
        background: am5.RoundedRectangle.new(root, {
          fill: am5.Color.fromString(color),
          cornerRadiusBL: 2,
          cornerRadiusBR: 2,
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
        }),
      });

      range.set("value", start + linesF);

      range.get("grid")?.setAll({
        strokeOpacity: 1,
        stroke: am5.Color.fromString(color),
        strokeWidth: 1,
        layer: 1,
      });

      const topCircle = am5.RoundedRectangle.new(root, {
        fill: am5.Color.fromString(color),
        stroke: am5.Color.fromString(color),
        width: 7,
        height: 7,
        centerY: am5.p50,
        centerX: am5.p50,
        cornerRadiusBL: 4,
        cornerRadiusBR: 4,
        cornerRadiusTL: 4,
        cornerRadiusTR: 4,
        dy: -20,
        layer: 1,
      });

      topCircle.toFront();

      topCircle.adapters?.add("y", () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return -1 * chart.plotContainer.getPrivate("height");
      });

      range.set(
        "bullet",
        am5xy.AxisBullet.new(root, {
          location: 0,
          sprite: topCircle,
        })
      );
    },
    removeXAxisRange: (key: string, signalId: string) => {
      const selectedSignalStore = get()?.selectedSignalsStores?.[signalId];

      const xAxis = selectedSignalStore?.chart?.xAxisRef?.current;

      if (!xAxis) return;
      removeAxisRanges({ xAxis, key });
    },
    updateStore: (updatedStoreProperties: any = {}) =>
      set((store: any) => {
        return {
          ...store,
          ...updatedStoreProperties,
        };
      }),
  }),
  shallow
);

export function useFrequencyCatalogueData(machineIds: string[]) {
  const { setCatalogueData, setReferenceSpeedSignal, setMachineName } =
    useCatalogueStore((store: any) => ({
      setCatalogueData: store.setCatalogueData,
      setReferenceSpeedSignal: store.setReferenceSpeedSignal,
      setMachineName: store.setMachineName,
    }));

  useEffect(() => {
    machineIds.forEach((machineId) => {
      const existingCatalogueData = queryClient.getQueryData([
        "frequency-catalogue",
        machineId,
      ]);
      const existingReferenceSignalData = queryClient.getQueryData([
        "frequency-catalogue-reference-speed-signal",
        machineId,
      ]);
      const existingMachineName = queryClient.getQueryData([
        "frequency-catalogue-machineName",
        machineId,
      ]);
      if (existingCatalogueData && existingReferenceSignalData) {
        setCatalogueData(machineId, existingCatalogueData);
        setReferenceSpeedSignal(machineId, existingReferenceSignalData);
        setMachineName(machineId, existingMachineName);
      }
    });
  }, [machineIds]);

  const queries = machineIds.flatMap((machineId) => [
    {
      queryKey: ["frequency-catalogue", machineId],
      queryFn: () =>
        getApiClient()
          .get<FrequencyCatalogueGroupsAndItemsResponse>(
            `/meta/read/internal/v1/machines/${machineId}/frequencyCatalogueGroup`
          )
          .then(({ data }) => {
            const getRelevantSidebands = (
              item: FrequencyCatalogueDbEntry,
              items: FrequencyCatalogueDbEntry[]
            ) => {
              if (item.itemName.toLowerCase().includes("sideband")) return [];

              const sidebandItems = items.filter(
                (entry) =>
                  entry.itemName.toLowerCase().includes("sideband") &&
                  !entry.groupName.toLowerCase().includes("sideband") &&
                  entry.groupName === item.groupName
              );

              if (sidebandItems.length > 0) {
                return sidebandItems;
              }

              const sameGroupItems = items.filter(
                (entry) => entry.groupName === item.groupName
              );

              const sortedByFrequency = sameGroupItems.sort(
                (a, b) => a.frequencyInHz - b.frequencyInHz
              );

              if (sortedByFrequency.length > 2) {
                const isOneOfSmallest =
                  sortedByFrequency[0].id === item.id ||
                  sortedByFrequency[1].id === item.id;
                if (isOneOfSmallest) {
                  return [];
                }
                return sortedByFrequency.slice(0, 2);
              }

              return [];
            };

            const catalogueData: FrequencyCatalogueGroupsAndItemsResponse = {
              ...data,
              items: data?.items.map((item: any) => ({
                ...item,
                frequencyInHz: item?.frequencyInHz
                  ? parseFloat(item.frequencyInHz.toFixed(3))
                  : undefined,
                key: item?.id,
                color: generateRandomColor(),
                sidebands: getRelevantSidebands(item, data.items),
              })),
            };

            setCatalogueData(machineId, catalogueData);

            return catalogueData;
          }),
      enabled: !!machineId,
      retry: false,
    },
    {
      queryKey: ["frequency-catalogue-reference-speed-signal", machineId],
      queryFn: () =>
        getApiClient()
          .get<any>(`/meta/read/v1/machines/${machineId}`)
          .then(({ data }) => {
            const machineReferenceSpeedSignal = (data?.signals || []).find(
              ({ id }: { id: string }) => id === data?.referenceSpeedSignalId
            );
            const referenceSpeedSignal = {
              name: machineReferenceSpeedSignal?.name || "",
              rpm: data?.referenceSpeedInRpm || 0,
            };
            setReferenceSpeedSignal(machineId, referenceSpeedSignal);
            return referenceSpeedSignal;
          }),
      enabled: !!machineId,
      retry: false,
    },
    {
      queryKey: ["frequency-catalogue-machineName", machineId],
      queryFn: () =>
        getApiClient()
          .get<any>(`/meta/read/v1/machines/${machineId}`)
          .then(({ data }) => {
            setMachineName(machineId, data?.name);
            return data?.name;
          }),
      enabled: !!machineId,
      retry: false,
    },
  ]);

  useQueries({ queries });
}
