import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../../../../modules/core/apiClient/useApiStore';
import type { ResponseSimplifiedSignalList } from '../../../../types';

export const usePerformanceRequest = (machineId: string, options: object = {}) => {
  const {
    data = {},
    isLoading,
    isError,
  } = useQuery(
    ['performance-page', machineId],
    () =>
      getApiClient()
        .get<ResponseSimplifiedSignalList>(
          `/meta/read/internal/v1/machines/${machineId}/performancePage`,
        )
        .then(({ data }) => {
          return data;
        })
        .catch(() => ({})),
    { staleTime: 0, cacheTime: 0, ...options },
  );

  return {
    data,
    isLoading,
    isError,
  };
};
