import { differenceInDays, format } from "date-fns";
import { useRef } from "react";

import { binarySearch } from "../../../../utils";
import {
  checkAvailableCache,
  checkZoomReloadAvailable,
} from "../TrendViewPlot/helper";
import { CHART_NUMBER_OF_POINTS } from "../../constants/controlOptions";
import type { ConnectedTimeRange } from "../../hooks/useControlsStore";
import useControlsStore from "../../hooks/useControlsStore";
import getPeriodFilters, { FORMAT } from "../../utils/getPeriodFilters";
import { apiService } from "../../../common";
import DataTypeEnum from "../../../common/constants/DataTypeEnum";
import useRefreshStore from "../../../common/hooks/useRefreshStore";
import { queryClient } from "../../../core";

import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";

export const useTrendViewGroupedZoomInData = () => {
  const { getPeriod } = useControlsStore((state) => ({
    getPeriod: state.getPeriod,
  }));
  const { setIsLoadingMultipleSignals, getSelectedSignals } =
    useSelectedSignalsStore((state) => ({
      setIsLoadingMultipleSignals: state.setIsLoadingMultipleSignals,
      getSelectedSignals: state.getSelectedSignals,
    }));

  const { refreshInterval } = useRefreshStore(({ refreshInterval }) => ({
    refreshInterval,
  }));

  const stateRef: any = useRef({});

  const handleGroupedZoomChange = (
    currentRange: ConnectedTimeRange["range"]
  ) => {
    const signals = getSelectedSignals() || [];
    const signalsPromises: any = [];
    const startDate = format(new Date(currentRange.startIndex), FORMAT);
    const endDate = format(new Date(currentRange.endIndex), FORMAT);
    const zoomedDaysPeriod = differenceInDays(
      currentRange.endIndex,
      currentRange.startIndex
    );
    const period = getPeriod();

    signals.forEach((signal) => {
      const isZoomReloadAvailable = checkZoomReloadAvailable(
        signal,
        currentRange,
        period,
        zoomedDaysPeriod
      );
      const isCacheDataAvailable = checkAvailableCache(
        signal?.id ? (stateRef.current[signal.id] || {}).cachedPeriod : {},
        currentRange
      );

      if (isZoomReloadAvailable && !isCacheDataAvailable) {
        const periodFilters = getPeriodFilters(period, signal);
        signalsPromises.push({
          promise: apiService.measuredDataDownsampled[
            signal.dataType === DataTypeEnum.FastTrend
              ? "getFastTrendMeasuredZipDataInAPeriod"
              : "getTrendMeasuredZipDataInAPeriod"
          ]({
            startDate,
            endDate,
            signalId: `${signal.id}`,
            numberOfPoints: CHART_NUMBER_OF_POINTS,
          }),
          id: signal.id,
          cacheKey: [
            "trend-measured-data-in-a-period",
            signal.machineId,
            signal.id,
            periodFilters.startDate,
            periodFilters.endDate,
            `${CHART_NUMBER_OF_POINTS}`,
            refreshInterval?.key,
          ],
        });
      }
    });

    if (signalsPromises.length > 0) {
      setIsLoadingMultipleSignals(true);
      Promise.all(
        signalsPromises.map((signalPromise: any) => signalPromise.promise)
      )
        .then((res) => {
          res.forEach((signalRes, idx) => {
            const { cacheKey, id: signalId } = signalsPromises[idx];
            queryClient.setQueryData(cacheKey, (data: any) => {
              stateRef.current[signalId] = stateRef.current[signalId] || {
                data: [],
                cachedPeriod: {},
              };

              if (stateRef.current[signalId].data.length === 0) {
                stateRef.current[signalId].data = [...(<[]>data)];
              }

              stateRef.current[signalId].cachedPeriod = {
                start: startDate,
                end: endDate,
              };

              const cachedData = stateRef.current[signalId].data;

              const startIndex = binarySearch(cachedData, (item) => {
                if (item.timeStamp.includes(startDate)) {
                  return 0;
                }
                return item.timeStamp > startDate ? 1 : -1;
              });
              const lastIndex = binarySearch(cachedData, (item) => {
                if (item.timeStamp.includes(endDate)) {
                  return 0;
                }
                return item.timeStamp > endDate ? 1 : -1;
              });
              const existingDataLeft = [...cachedData].splice(0, startIndex);
              const existingDataRight = [...cachedData].splice(
                lastIndex,
                cachedData.length
              );
              return [...existingDataLeft, ...signalRes, ...existingDataRight];
            });
          });
          setIsLoadingMultipleSignals(false);
        })
        .catch(() => {
          setIsLoadingMultipleSignals(false);
        });
    }
  };

  const handleZoomChange = (currentRange: ConnectedTimeRange["range"]) => {
    handleGroupedZoomChange(currentRange);
  };

  return {
    handleZoomChange,
  };
};
