import type { SVGProps } from 'react';
import React from 'react';

const TrendSignalIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='13'
      viewBox='0 0 16 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13 1.99997C12.4696 1.99997 11.9609 2.21069 11.5858 2.58576C11.2107 2.96083 11 3.46954 11 3.99997C11 4.5304 11.2107 5.03911 11.5858 5.41419C11.9609 5.78926 12.4696 5.99997 13 5.99997C13.5305 5.99997 14.0392 5.78926 14.4142 5.41419C14.7893 5.03911 15 4.5304 15 3.99997C15 3.46954 14.7893 2.96083 14.4142 2.58576C14.0392 2.21069 13.5305 1.99997 13 1.99997ZM10.04 3.50497C10.1635 2.76309 10.5612 2.09467 11.1542 1.63211C11.7472 1.16955 12.4924 0.946615 13.242 1.00746C13.9916 1.0683 14.691 1.40849 15.2017 1.96062C15.7124 2.51276 15.997 3.23657 15.9993 3.98865C16.0015 4.74074 15.7212 5.46623 15.2139 6.02142C14.7065 6.5766 14.0091 6.92096 13.2599 6.98629C12.5106 7.05161 11.7642 6.83313 11.1684 6.37413C10.5726 5.91513 10.171 5.2491 10.043 4.50797C9.71579 4.54119 9.40854 4.68104 9.16858 4.906C8.92862 5.13096 8.76926 5.42856 8.71502 5.75297L8.27203 8.41097C8.17899 8.96873 7.89962 9.47847 7.47953 9.85697C7.05943 10.2355 6.52343 10.4604 5.95902 10.495C5.83478 11.2367 5.43646 11.9048 4.84297 12.3667C4.24949 12.8287 3.50413 13.0509 2.75457 12.9893C2.00501 12.9277 1.30592 12.5868 0.795801 12.0342C0.285683 11.4815 0.00174979 10.7574 0.000250112 10.0054C-0.00124956 9.25327 0.279794 8.52805 0.787704 7.97337C1.29561 7.4187 1.99334 7.07503 2.74265 7.01045C3.49196 6.94587 4.23819 7.16509 4.83352 7.62469C5.42884 8.08428 5.82982 8.75072 5.95702 9.49197C6.28426 9.45876 6.59151 9.31891 6.83147 9.09395C7.07143 8.86898 7.23079 8.57139 7.28503 8.24697L7.72802 5.58897C7.82106 5.03122 8.10043 4.52148 8.52052 4.14297C8.94062 3.76447 9.47662 3.53956 10.041 3.50497H10.04ZM3.00002 7.99997C2.46959 7.99997 1.96088 8.21069 1.58581 8.58576C1.21074 8.96083 1.00002 9.46954 1.00002 9.99997C1.00002 10.5304 1.21074 11.0391 1.58581 11.4142C1.96088 11.7893 2.46959 12 3.00002 12C3.53046 12 4.03917 11.7893 4.41424 11.4142C4.78931 11.0391 5.00002 10.5304 5.00002 9.99997C5.00002 9.46954 4.78931 8.96083 4.41424 8.58576C4.03917 8.21069 3.53046 7.99997 3.00002 7.99997Z'
        fill='#201F1E'
      />
    </svg>
  );
};

export default TrendSignalIcon;
