import type { SVGProps } from 'react';
import React from 'react';

const MinimizeIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='13'
      viewBox='0 0 16 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 3C12 3.26522 12.1054 3.51957 12.2929 3.70711C12.4804 3.89464 12.7348 4 13 4H15C15.1326 4 15.2598 4.05268 15.3536 4.14645C15.4473 4.24021 15.5 4.36739 15.5 4.5C15.5 4.63261 15.4473 4.75979 15.3536 4.85355C15.2598 4.94732 15.1326 5 15 5H13C12.4696 5 11.9609 4.78929 11.5858 4.41421C11.2107 4.03914 11 3.53043 11 3V1C11 0.867392 11.0527 0.740215 11.1464 0.646447C11.2402 0.552679 11.3674 0.5 11.5 0.5C11.6326 0.5 11.7598 0.552679 11.8536 0.646447C11.9473 0.740215 12 0.867392 12 1V3ZM4 13C4 12.7348 3.89464 12.4804 3.70711 12.2929C3.51957 12.1054 3.26522 12 3 12H1C0.867392 12 0.740215 11.9473 0.646447 11.8536C0.552679 11.7598 0.5 11.6326 0.5 11.5C0.5 11.3674 0.552679 11.2402 0.646447 11.1464C0.740215 11.0527 0.867392 11 1 11H3C3.53043 11 4.03914 11.2107 4.41421 11.5858C4.78929 11.9609 5 12.4696 5 13V15C5 15.1326 4.94732 15.2598 4.85355 15.3536C4.75979 15.4473 4.63261 15.5 4.5 15.5C4.36739 15.5 4.24021 15.4473 4.14645 15.3536C4.05268 15.2598 4 15.1326 4 15V13ZM12 13C12 12.7348 12.1054 12.4804 12.2929 12.2929C12.4804 12.1054 12.7348 12 13 12H15C15.1326 12 15.2598 11.9473 15.3536 11.8536C15.4473 11.7598 15.5 11.6326 15.5 11.5C15.5 11.3674 15.4473 11.2402 15.3536 11.1464C15.2598 11.0527 15.1326 11 15 11H13C12.4696 11 11.9609 11.2107 11.5858 11.5858C11.2107 11.9609 11 12.4696 11 13V15C11 15.1326 11.0527 15.2598 11.1464 15.3536C11.2402 15.4473 11.3674 15.5 11.5 15.5C11.6326 15.5 11.7598 15.4473 11.8536 15.3536C11.9473 15.2598 12 15.1326 12 15V13ZM3 4C3.26522 4 3.51957 3.89464 3.70711 3.70711C3.89464 3.51957 4 3.26522 4 3V1C4 0.867392 4.05268 0.740215 4.14645 0.646447C4.24021 0.552679 4.36739 0.5 4.5 0.5C4.63261 0.5 4.75979 0.552679 4.85355 0.646447C4.94732 0.740215 5 0.867392 5 1V3C5 3.53043 4.78929 4.03914 4.41421 4.41421C4.03914 4.78929 3.53043 5 3 5H1C0.867392 5 0.740215 4.94732 0.646447 4.85355C0.552679 4.75979 0.5 4.63261 0.5 4.5C0.5 4.36739 0.552679 4.24021 0.646447 4.14645C0.740215 4.05268 0.867392 4 1 4H3Z'
        fill='#0078D4'
      />
    </svg>
  );
};

export default MinimizeIcon;
