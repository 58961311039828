import type { IDropdownOption } from "@fluentui/react";
import { Dropdown, Spinner, SpinnerSize } from "@fluentui/react";
import { useQuery } from "@tanstack/react-query";
import { sortBy } from "lodash-es";
import * as ReactRouterDOM from "react-router-dom";

import { apiService } from "../../../common";
import type { Project } from "../../../../types";

import { dropdownStyles } from "./commonStyles";

const BreadcrumbItemMachine = ({ machine, selectedProject }: any) => {
  const navigate = ReactRouterDOM.useNavigate();
  const location = ReactRouterDOM.useLocation();
  const { pathname } = location;
  const { data: projectData = {} as Project, isLoading: isLoadingInternal } =
    useQuery(
      ["project-data", selectedProject?.id],
      () => apiService.metaDataRead.getProject(selectedProject?.id),
      {
        // cacheTime: 0,
        enabled: !!selectedProject?.id,
      }
    );

  if (isLoadingInternal) {
    return <Spinner size={SpinnerSize.small} />;
  }

  const dropdownMachineOptions =
    (sortBy(projectData?.machines ?? [], ({ name }) => name?.toLowerCase()).map(
      (m) => ({
        key: m.id,
        text: m.name,
        selected: m.id === machine?.id || m.id,
      })
    ) as IDropdownOption[]) ?? [];

  const handleDropdownChange = (
    _: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption<any> | undefined
  ) => {
    navigate(`${pathname}?id=${option?.key}`);
  };

  return (
    <Dropdown
      options={dropdownMachineOptions}
      selectedKey={machine?.id}
      styles={dropdownStyles}
      onChange={handleDropdownChange}
    />
  );
};
export default BreadcrumbItemMachine;
