import type { ITextFieldProps } from '@fluentui/react';
import { TextField } from '@fluentui/react';
import { Controller } from 'react-hook-form';

import type { BaseFormFieldProps } from '../types';
import { FormGroup } from './FormGroup';

export type FormTextAreaProps = BaseFormFieldProps &
  ITextFieldProps & {
    name: string;
  };

export const FormTextArea = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control, errors },
  ...rest
}: FormTextAreaProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value } }) => (
        <TextField
          multiline
          rows={7}
          resizable={false}
          value={value}
          styles={{ root: { width: '100%' } }}
          errorMessage={errors?.[name]?.message}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
          label={undefined}
        />
      )}
    />
  </FormGroup>
);
