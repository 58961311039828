import type { IDropdownStyles } from '@fluentui/react';

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    '&:focus': {
      '&:after': {
        border: '0 !important',
      },
    },
  },
  dropdownItemsWrapper: {
    maxHeight: '30vh',
    overflowY: 'auto',
  },
  title: {
    background: 'transparent',
    border: 0,
    paddingLeft: 0,
    height: 36,
    lineHeight: 36,
  },
  caretDownWrapper: {
    height: 36,
    lineHeight: 36,
  },
  callout: {
    width: 300,
    display: 'table',
  },
};
