import type React from 'react';

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { persist } from 'zustand/middleware';

import type { Condition } from '../../../../modules/machine-cv/constants';

export type Layout = 'detailed' | 'compact';
export type SearchBy = 'machine' | 'project' | 'company' | 'corporation';

type CompactItemAction = {
  name: string;
  link: string;
  icon: React.ReactElement;
};

export type CompactItem = {
  id: string;
  dalogId: string;
  customerCode: string;
  machineName: string;
  conditionName: string;
  conditionComment: string;
  activeTasks: number;
  taskDescription: string;
  taskResponsible: string;
  taskDueDate?: string;
  actions: CompactItemAction[];
};

export type Column = {
  -readonly [key in keyof CompactItem]-?: key;
}[keyof CompactItem];

const defaultColumns: Column[] = [
  'dalogId',
  'machineName',
  'customerCode',
  'conditionName',
  'conditionName',
  'conditionComment',
  'activeTasks',
  'taskDescription',
  'taskResponsible',
  'taskDueDate',
  'actions',
];

const getInitColumns = () => {
  try {
    const tableOpts = localStorage.getItem('table-overview');

    if (tableOpts !== null) {
      const localStorageColumns = (JSON.parse(tableOpts) as { state: { key: Column }[] }).state.map(
        ({ key }) => key,
      );
      return localStorageColumns?.length > 0 ? localStorageColumns : defaultColumns;
    }

    return defaultColumns;
  } catch {
    return defaultColumns;
  }
};

export interface OverviewStore {
  layout: Layout;
  columns: Column[];
  search: string;
  condition: Condition | 'All';
  searchBy: SearchBy;
  changeLayout: (layout: Layout) => void;
  changeColumns: (columns: Column[]) => void;
  changeCondition: (condition: Condition | 'All') => void;
  changeSearch: (search: string) => void;
  changeSearchBy: (searchBy: SearchBy) => void;
}

const useOverviewStore = createWithEqualityFn<OverviewStore>()(
  persist(
    (set) => ({
      layout: 'detailed',
      columns: getInitColumns(),
      search: '',
      condition: 'All',
      searchBy: 'project',
      changeLayout: (layout: Layout) => set({ layout }),
      changeColumns: (columns: Column[]) => set({ columns }),
      changeCondition: (condition: Condition | 'All') => set({ condition }),
      changeSearch: (search: string) => set({ search }),
      changeSearchBy: (searchBy: SearchBy) => set({ searchBy }),
    }),
    {
      name: 'machine-cv-home-layout',
      partialize(state) {
        return {
          layout: state.layout,
          searchBy: state.searchBy,
          search: state.search,
        };
      },
      merge(persistedState, currentState) {
        const {
          layout = 'detailed',
          searchBy = 'project',
          search = '',
        } = persistedState as OverviewStore;

        return {
          ...currentState,
          layout,
          searchBy,
          search,
        };
      },
    },
  ), shallow
);

export default useOverviewStore;
