import { TooltipHost } from "@fluentui/react";
import React, { useMemo } from "react";
import * as ReactRouterDOM from "react-router-dom";

import FluentSchedule from "../../../../assets/svg/FluentSchedule";
import MachineCvConditions from "../../../../assets/svg/MachineCvConditions";
import MachineCvSensors from "../../../../assets/svg/MachineCvSensors";
import MultipleComments from "../../../../assets/svg/MultipleComments";
import RawDataIcon from "../../../../assets/svg/RawDataIcon";
import TaskIcon from "../../../../assets/svg/TaskIcon";
import TrendsIcon from "../../../../assets/svg/TrendsIcon";
import { format } from "../../../../utils";
import type { Condition } from "../../../../modules/machine-cv/constants";
import {
  conditionColor,
  conditionFormat,
} from "../../../../modules/machine-cv/constants";
import type { MachineCvOverviewProject } from "../../../../types";
import type { Column as TableColumn, TableProps } from "../../../common/Table";
import Table from "../../../common/Table";
import { getRowCellWidth } from "../../../common/Table/cellWidth";

import type { Column, CompactItem } from "../hooks/useOverviewStore";
import useOverviewStore from "../hooks/useOverviewStore";

type CompactColumn = Omit<TableColumn, "key"> & {
  key: Column;
};

const columns: CompactColumn[] = [
  {
    key: "dalogId",
    fieldName: "dalogId",
    name: "Dalog Id",
    isSortable: true,
    minWidth: 100,
  },
  {
    key: "machineName",
    fieldName: "machineName",
    name: "Machine",
    isSortable: true,
    minWidth: 100,
    onRender: (item) => (
      <ReactRouterDOM.Link to={`/machine-cv/summary?id=${item.id}`}>
        {item.machineName}
      </ReactRouterDOM.Link>
    ),
  },
  {
    key: "customerCode",
    fieldName: "customerCode",
    name: "Code",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "conditionName",
    fieldName: "conditionName",
    name: "Condition",
    minWidth: 100,
    isSortable: true,
    onRenderField: (props) => {
      if (!props) {
        return null;
      }

      const { item, itemIndex, column, onRender } = props;

      return (
        <div
          key={column.key}
          role="gridcell"
          style={{
            width: getRowCellWidth(column),
            background: conditionColor[item.conditionName as Condition],
            color: "#fff",
            fontWeight: "600",
            padding: "11px 12px",
          }}
        >
          {onRender(
            {
              ...item,
              conditionName: conditionFormat[item.conditionName as Condition],
            },
            itemIndex,
            column
          )}
        </div>
      );
    },
  },
  {
    key: "conditionComment",
    fieldName: "conditionComment",
    name: "Condition Comment",
    minWidth: 200,
    isSortable: true,
  },
  {
    key: "activeTasks",
    fieldName: "activeTasks",
    name: "Active tasks",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "taskDescription",
    fieldName: "taskDescription",
    name: "Task description",
    minWidth: 200,
    flexGrow: 1,
    calculatedWidth: 0,
    isSortable: true,
  },
  {
    key: "taskResponsible",
    fieldName: "taskResponsible",
    name: "Responsible",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "taskDueDate",
    fieldName: "taskDueDate",
    name: "Next Due Date",
    minWidth: 100,
    isSortable: true,
    onRender: (item: CompactItem) =>
      item.taskDueDate ? format(new Date(item.taskDueDate)) : "",
  },
  {
    key: "actions",
    fieldName: "actions",
    name: "Actions",
    minWidth: 180,
    onRender: (item: CompactItem) => (
      <div style={{ display: "flex" }}>
        {item.actions.map(({ name, link, icon }, idx) => (
          <TooltipHost
            key={idx}
            content={name}
            styles={{ root: { display: "flex" } }}
          >
            <ReactRouterDOM.Link
              to={link}
              className="router-link"
              style={{ margin: "0 5px" }}
            >
              {icon}
            </ReactRouterDOM.Link>
          </TooltipHost>
        ))}
      </div>
    ),
  },
];

type CompactLayoutProps = {
  project: MachineCvOverviewProject;
};

const CompactLayout = ({ project }: CompactLayoutProps) => {
  const _columns = useOverviewStore((state) => state.columns);

  const items = project.machines.map(({ tasks, condition, metaData }) => {
    return {
      id: metaData.id,
      dalogId: metaData.dalogId,
      customerCode: metaData.customerCode,
      machineName: metaData.name,
      conditionName: condition,
      conditionComment: metaData.comment,
      activeTasks: tasks.activeTasksCount,
      taskDescription: tasks.nextDueDescription,
      taskResponsible: tasks.nextAssignedToDisplayName,
      taskDueDate: tasks.nextDueDate,
      actions: [
        {
          name: "Condition",
          link: `./condition?id=${metaData.id}`,
          icon: <MachineCvConditions />,
        },
        {
          name: "Sensors",
          link: `./sensors?id=${metaData.id}`,
          icon: <MachineCvSensors />,
        },
        {
          name: "Tasks",
          link: `./tasks?id=${metaData.id}`,
          icon: <TaskIcon />,
        },
        {
          name: "Comments",
          link: `./comments?id=${metaData.id}`,
          icon: <MultipleComments />,
        },
        {
          name: "Reports",
          link: `./reports?id=${metaData.id}`,
          icon: <FluentSchedule />,
        },
        {
          name: "Trend",
          link: `/trend-view?id=${metaData.id}`,
          icon: <TrendsIcon fill="#2c529f" />,
        },
        {
          name: "Raw data",
          link: `/raw-data?id=${metaData.id}`,
          icon: <RawDataIcon />,
        },
      ],
    };
  });

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        sortKey: project.metaData.id,
        key: "table-overview",
        version: 5,
      },
      items,
      hidePerPage: true,
      columns: columns.filter(({ key }) => _columns.includes(key)),
      hasSelection: false,
      detailsListStyles: {
        root: {
          margin: "0px !important",
        },
      },
    }),
    [items, _columns]
  );

  return <Table {...tableProps} />;
};

export default CompactLayout;
