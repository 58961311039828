import type { DataItem } from '@amcharts/amcharts5';
import type { AxisRenderer, IValueAxisDataItem, ValueAxis } from '@amcharts/amcharts5/xy';
import min from 'lodash-es/min';

/*
  Keys
  Current values: '1', '2'
  Call: getHarmonicsKey('1') returns 'harmonics-1'
  Call: getSidebandsKey('1') returns 'sidebands-1'

  Naming conventions: 
  harmonicsKey  - getHarmonicsKey(harmonicsKey)
  sidebandsKey  - getSidebandsKey(sidebandsKey)                           
  key           - getHarmonicsKey(key) & getSidebandsKey(key)
*/

const HARMONICS_PREFIX = 'harmonics-';

const SIDEBANDS_PREFIX = 'sidebands-';

export const getHarmonicsKey = (key: string) => `${HARMONICS_PREFIX}${key}`;

export const getSidebandsKey = (key: string) => `${SIDEBANDS_PREFIX}${key}`;

/*
  Remove Axis Ranges For Harmonics & Sidebands
  Current values: '1', '2'
  Call: removeAxisRanges({ key: '1', xAxis }) removes axis ranges with label text in ['harmonics-1', 'sidebands-1']
*/

type RemoveAxisRangesOpts = {
  key: string;
  xAxis: ValueAxis<AxisRenderer>;
};

export const removeAxisRanges = ({ key, xAxis }: RemoveAxisRangesOpts) => {
  const texts = [getHarmonicsKey(key), getSidebandsKey(key)];

  const ranges: DataItem<IValueAxisDataItem>[] = [];

  xAxis.axisRanges.each((range) => {
    const text = range.get('label')?.get('ariaLabel');

    if (text && texts.includes(text)) {
      ranges.push(range);
    }
  });

  ranges.forEach((range) => {
    xAxis.axisRanges.removeValue(range);
  });
};

/*
  Get sidebands frequency
  Call: getSidebandsFrequency({ harmonicsKey: '1', xAxis, cursorValue })
*/

type GetSidebandsFrequencyOpts = {
  xAxis: ValueAxis<AxisRenderer>;
  harmonicsKey: string;
  cursorValue: number;
};

export const getSidebandsFrequency = ({
  xAxis,
  cursorValue,
  harmonicsKey,
}: GetSidebandsFrequencyOpts) => {
  return min(
    xAxis?.axisRanges?.values
      .filter((range) => range.get('label')?.get('ariaLabel') === getHarmonicsKey(harmonicsKey))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .map((range) => Math.abs(range.get('value') - cursorValue)),
  ) as number;
};

/*
  Get harmonic positions
  Call: getHarmonicPositions({ linesNumber: 10, linesFreq: 100 })
*/

type GetHarmonicPositionsOpts = {
  linesNumber: number;
  linesFreq: number;
};

export const getHarmonicPositions = ({ linesNumber, linesFreq }: GetHarmonicPositionsOpts) => {
  const positions: number[] = [];

  if (linesNumber === 0 || linesFreq === 0) {
    return positions;
  }

  for (let idx = 0; idx < linesNumber; idx += 1) {
    positions.push(linesFreq * (idx + 1));
  }

  return positions;
};

/*
  Get sidebands positions
*/

type GetSidebandPositionsOpts = {
  linesNumber: number;
  linesFreq: number;
  sidebandsNumber: number;
  sidebandsFreq: number;
};

export const getSidebandPositions = ({
  linesNumber,
  linesFreq,
  sidebandsNumber,
  sidebandsFreq,
}: GetSidebandPositionsOpts) => {
  const positions: number[] = [];

  const harmonicPositions = getHarmonicPositions({ linesNumber, linesFreq });

  if (harmonicPositions.length === 0 || sidebandsNumber === 0 || sidebandsFreq === 0) {
    return positions;
  }

  for (let idx = 0; idx < harmonicPositions.length; idx += 1) {
    const harmonicPosition = harmonicPositions[idx];

    for (let count = 0; count < sidebandsNumber; count += 1) {
      positions.push(harmonicPosition - sidebandsFreq * (count + 1));
      positions.push(harmonicPosition + sidebandsFreq * (count + 1));
    }
  }

  return positions;
};
