import { useMemo } from 'react';

import { getPearsonCorrelationCoefficient, roundNumber } from '../../../utils';

import type { TrendViewRawDetails } from '../types';
import getStatistics from '../utils/getStatistics';

interface UseStatisticsArgs {
  signalPlotAxis: {
    x: string[];
    y: string[];
  };
  referenceSignalPlotAxis: {
    x: string[];
    y: string[];
  };
  selectedRange: {
    startIndex: number;
    endIndex: number;
  };
  isLoading: boolean;
  isSuccessReferenceSignal: boolean;
}

const useStatistics = ({
  signalPlotAxis,
  referenceSignalPlotAxis,
  selectedRange,
  isLoading,
  isSuccessReferenceSignal,
}: UseStatisticsArgs) => {
  const trendViewRawDetails = useMemo<TrendViewRawDetails | undefined>(() => {
    if (isLoading || signalPlotAxis.x.length === 0) return undefined;

    const allValues: number[] = signalPlotAxis.y.map((item) => Number(item));
    const allValuesCount = allValues.length;
    const rangeValues = allValues.slice(selectedRange.startIndex, selectedRange.endIndex + 1);
    const rangeValuesCount = rangeValues.length;

    const rawDetails = getStatistics({ selectedRange, data: signalPlotAxis.y });

    if (isSuccessReferenceSignal) {
      const refAllValues: number[] = referenceSignalPlotAxis.y.map((item) => Number(item));
      const refRangeValues = refAllValues.slice(
        selectedRange.startIndex,
        selectedRange.endIndex + 1,
      );

      rawDetails.all.rCorr = roundNumber(
        getPearsonCorrelationCoefficient(allValuesCount, allValues, refAllValues),
        2,
      );
      rawDetails.selectedRange.rCorr = roundNumber(
        getPearsonCorrelationCoefficient(rangeValuesCount, rangeValues, refRangeValues),
        2,
      );
    }

    return rawDetails;
  }, [signalPlotAxis, selectedRange, isLoading]);

  const referenceSignalRawDetails = useMemo<TrendViewRawDetails | undefined>(() => {
    if (!isSuccessReferenceSignal || referenceSignalPlotAxis.x.length === 0) return undefined;

    const rawDetails = getStatistics({ selectedRange, data: referenceSignalPlotAxis.y });

    return rawDetails;
  }, [referenceSignalPlotAxis, selectedRange, isSuccessReferenceSignal]);

  return { trendViewRawDetails, referenceSignalRawDetails };
};

export default useStatistics;
