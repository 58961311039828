import { useMutation } from '@tanstack/react-query';

import { getApiClient, validateStatus } from '../../../../modules/core/apiClient/useApiStore';
import type { TaskPriorities } from '../../../../types';

export type TaskAddProps = {
  subject?: string;
  description?: string;
  fileIds: string[];
  priority?: TaskPriorities;
  machineId: string;
  assignedTo?: string;
  startDate: Date;
  dueDate: Date;
};

type TaskUpdateProps = {
  id: string;
  // task: Partial<Task>;
  task: any;
};

export const useTaskAdd = () => {
  const { mutateAsync } = useMutation((data: TaskAddProps) =>
    getApiClient().post('meta/machinecv/v1/tasks', data, { validateStatus }),
  );

  return { addTaskAsync: mutateAsync };
};

export const useTaskUpdate = () => {
  const { mutateAsync } = useMutation((data: TaskUpdateProps) =>
    getApiClient().put(`meta/machinecv/v1/tasks/${data.id}`, { ...data.task }, { validateStatus }),
  );

  return { updateTaskAsync: mutateAsync };
};

type TaskUploadProps = {
  id: string;
  fileIds: string[];
};

export const useTaskUploadDocument = () => {
  const { mutateAsync } = useMutation(({ id, fileIds }: TaskUploadProps) =>
    Promise.allSettled(
      fileIds.map((fileId) =>
        getApiClient().post(
          `meta/machinecv/v1/tasks/${id}/documents`,
          { fileId },
          { validateStatus },
        ),
      ),
    ),
  );

  return { uploadTaskDocumentsAsync: mutateAsync };
};

type TaskComment = {
  text?: string;
  fileIds?: string[];
};

type TaskPostCommentProps = {
  id: string;
  data: TaskComment;
};

export const useTaskPostComment = () => {
  const { mutateAsync } = useMutation(({ id, data }: TaskPostCommentProps) =>
    getApiClient().post(`meta/machinecv/v1/tasks/${id}/comments`, { ...data }, { validateStatus }),
  );

  return { postTaskCommentAsync: mutateAsync };
};

type TaskUpdateCommentProps = {
  id: string;
  commentId: string;
  data: TaskComment;
};

export const useTaskUpdateComment = () => {
  const { mutateAsync } = useMutation(({ id, commentId, data }: TaskUpdateCommentProps) =>
    getApiClient().put(
      `meta/machinecv/v1/tasks/${id}/comments/${commentId}`,
      {
        ...data,
        taskCommentId: commentId,
      },
      { validateStatus },
    ),
  );

  return { updateTaskCommentAsync: mutateAsync };
};
