import './accordion.scss';

import { mergeStyles, Stack, Text } from '@fluentui/react';
import { useState } from 'react';

import type { AccordionButtonProps, AccordionItemProps, AccordionProps } from './types';

const AccordionButton = ({ title, subtitle, onRenderSubtitle, ...rest }: AccordionButtonProps) => {
  let subtitleElement: JSX.Element | undefined = onRenderSubtitle?.();
  if (!subtitleElement && subtitle && subtitle.length > 0) {
    subtitleElement = (
      <Text variant='medium' as='span' style={{ fontWeight: 600 }}>
        {subtitle}
      </Text>
    );
  }

  return (
    <Stack {...rest} horizontal horizontalAlign='space-between' verticalAlign='center' wrap={false}>
      <Text variant='medium' as='span' style={{ fontWeight: 400 }}>
        {title}
      </Text>
      {subtitleElement}
    </Stack>
  );
};

const AccordionItem = ({ item, selected, ...rest }: AccordionItemProps) => {
  let className = 'accordion-item';
  let content: JSX.Element | undefined = selected ? item.onRenderContent?.() : undefined;
  if (selected) {
    className = mergeStyles('accordion-item', 'accordion-item-selected');
    if (!content && item.content && item.content.length > 0) {
      content = (
        <Stack className='accordion-content'>
          <Text as='p' variant='small' nowrap={false}>
            {item.content}
          </Text>
        </Stack>
      );
    }
  }

  return (
    <Stack {...rest}>
      <AccordionButton
        title={item.title}
        subtitle={item.overview}
        className={item.disabled === true ? 'accordion-item-disabled' : className}
        onRenderSubtitle={item.onRenderSubtitle}
      />
      <Stack.Item>{content}</Stack.Item>
    </Stack>
  );
};

const Accordion = ({ items, ...rest }: AccordionProps) => {
  const [currentKey, setCurrentKey] = useState<string>('');

  return (
    <Stack {...rest}>
      {items.map((item) => (
        <AccordionItem
          key={item.key}
          item={item}
          selected={item.key === currentKey}
          onClick={() => {
            if (item.disabled === true) return;
            setCurrentKey(currentKey !== item.key ? item.key : '');
          }}
        />
      ))}
    </Stack>
  );
};

export default Accordion;
