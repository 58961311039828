/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from "react";

import type { ContextmenuProps } from "../../types";

const ContextMenu = ({ root, children }: ContextmenuProps) => {
  const [visible, setVisible] = useState(false);
  const contextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = () => {
      setVisible(false);
    };

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();

      // TODO: check why some canvas elements does not have chart-canvas class
      // const canvas = e.target as HTMLElement;
      // const isChartCanvas = canvas.className.includes('chart-canvas');

      // if (!isChartCanvas) return;

      setVisible(true);

      const { clientWidth, clientHeight } = contextRef.current!;
      const { innerWidth: screenW, innerHeight: screenH } = window;
      const { clientX: clickX, clientY: clickY } = e;

      const right = screenW - clickX > clientWidth;
      const top = screenH - clickY > clientHeight;

      contextRef.current!.style.left = right
        ? `${clickX + 5}px`
        : `${clickX - clientWidth - 5}px`;
      contextRef.current!.style.top = top
        ? `${clickY + 5}px`
        : `${clickY - clientHeight - 5}px`;
    };

    document.addEventListener("click", handleClick);
    root?.dom.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("click", handleClick);
      root?.dom.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [root]);

  return (
    <div
      ref={contextRef}
      className="contextMenu"
      style={{ display: visible ? "block" : "none" }}
    >
      {children}
    </div>
  );
};

export default ContextMenu;
