import { useEffect, useState } from "react";

import useHeaderControlsStore from "../../analysis-raw-data/hooks/useHeaderControlsStore";
import useRefreshStore from "../../common/hooks/useRefreshStore";
import { getIntervalDuration } from "../../common/utils/getIntervalDuration";
import type { ResponseSimplifiedSignal } from "../../../types";

import { notification } from "../../../Components/common/Notification";
import {
  useShortTrendMetaDataPointsInACustomTimeRangeAsZip,
  useSignalMetadataById,
} from "../../../Hooks";

import useSelectedSignalsStore from "./useSelectedSignalsStore";

const useSignalMonitoring = ({
  signal,
  signalTimezone,
  startDateTime,
  endDateTime,
  selectedSignals,
}: {
  signal: ResponseSimplifiedSignal;
  signalTimezone: string;
  startDateTime: string | Date;
  endDateTime: string | Date;
  selectedSignals: ResponseSimplifiedSignal[];
}) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [oldTreeDataPointsLength, setOldTreeDataPointsLength] = useState(0);
  const editSignalValues = useSelectedSignalsStore(
    (state) => state.editSignalValues
  );

  const controlsStore = useHeaderControlsStore(
    ({ prevTimestamp, nextTimestamp, period }) => ({
      prevTimestamp,
      nextTimestamp,
      period,
    })
  );

  const { refreshInterval } = useRefreshStore(({ refreshInterval }) => ({
    refreshInterval,
  }));

  // Update currentDateTime based on the refresh interval
  useEffect(() => {
    const intervalDuration = getIntervalDuration(
      refreshInterval?.key as string
    );

    if (intervalDuration === 0) return;

    const nextIntervalTime =
      Math.ceil(currentDateTime.getTime() / intervalDuration) *
      intervalDuration;
    const timeoutDuration = nextIntervalTime - currentDateTime.getTime();

    const timeout = setTimeout(() => {
      setCurrentDateTime(new Date());
    }, timeoutDuration);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentDateTime, refreshInterval]);

  // Fetch signal metadata based on currentDateTime
  const { data: newSignalDetails, refetch: refetchSignalMetadata } =
    useSignalMetadataById(
      { machineId: signal.machineId as string, signalId: signal.id as string },
      { enabled: !!currentDateTime, retry: 0, cacheTime: 0 }
    );

  // Trigger refetchSignalMetadata when currentDateTime changes
  useEffect(() => {
    refetchSignalMetadata();
  }, [currentDateTime, refetchSignalMetadata]);

  // Check for new data points and trigger refetchTreeDataPoints
  useEffect(() => {
    if (
      newSignalDetails?.dataUntil !== undefined &&
      signal?.dataUntil !== undefined &&
      new Date(newSignalDetails.dataUntil).getTime() !==
        new Date(signal.dataUntil).getTime()
    ) {
      editSignalValues(signal.id, {
        dataUntil: newSignalDetails.dataUntil,
      });

      refetchTreeDataPoints();
    }
  }, [newSignalDetails, currentDateTime]);

  // Fetch tree data points based on signal and time range
  const {
    data: { dataPoints, dataPointsLength } = {},
    isLoading,
    isError,
    refetch: refetchTreeDataPoints,
  } = useShortTrendMetaDataPointsInACustomTimeRangeAsZip(
    {
      signalId: signal.id as string,
      signalTimezone,
      startDateTime,
      endDateTime,
    },
    {
      enabled:
        !!selectedSignals.length &&
        !!controlsStore.period &&
        !!startDateTime &&
        !!endDateTime,
      retry: 0,
      cacheTime: 0,
    }
  );

  // Show notification if new data points are available
  useEffect(() => {
    if (
      !isLoading &&
      !isError &&
      dataPoints &&
      newSignalDetails &&
      dataPoints.length > oldTreeDataPointsLength &&
      newSignalDetails?.dataUntil !== undefined &&
      signal?.dataUntil !== undefined &&
      new Date(newSignalDetails.dataUntil).getTime() !==
        new Date(signal.dataUntil).getTime()
    ) {
      notification.success("New data points available!");

      // Update the oldTreeDataPointsLength with the current length
      setOldTreeDataPointsLength(dataPointsLength);
    }
  }, [
    isLoading,
    isError,
    dataPoints,
    newSignalDetails,
    oldTreeDataPointsLength,
  ]);

  // Set oldTreeDataPoints initially when useShortTrendMetaDataPointsInACustomTimeRangeAsZip is called
  useEffect(() => {
    if (!isLoading && !isError && dataPointsLength) {
      setOldTreeDataPointsLength(dataPointsLength);
    }
  }, [isLoading, isError, dataPoints]);

  return { dataPoints, isLoading, isError };
};

export default useSignalMonitoring;
