import { useMemo } from "react";
import type { PointFeature } from "supercluster";

import {
  useDashboardsOverview,
  useMachineCvOverviewList,
} from "../../../../Hooks";
import type { IDashboardCard } from "../../../../modules/common/components/Dashboards/DashboardCard";
import { useModeSelect } from "./useModeSelect";
import { getProjectElementId } from "../../../MachineCV/MachineCVHomePage/selectors";
import type {
  MachineCvOverviewCompany,
  MachineCvOverviewList,
} from "../../../../types";

export type ProjectProperties = {
  cluster: false;
  id: string;
  name: string;
  type: "Project";
  slug?: string;
  uniqId?: string;
};

function getProjectsFromMachineCVOverview(data: MachineCvOverviewList = []) {
  return data.flatMap((corporation) => {
    const corporationName = corporation?.name;
    const companyProjects = corporation?.companies.reduce(
      (acc: any[], company: MachineCvOverviewCompany) => {
        const companyName = company?.name;
        const projects = company?.projects;
        const modifiedProjects = projects.map((item) => ({
          companyName,
          corporationName,
          ...item?.metaData,
        }));
        acc.push(...modifiedProjects);
        return acc;
      },
      []
    );

    return companyProjects;
  }, []);
}

export const useDashboardData = () => {
  const {
    data: machines = [],
    isLoading: isMachineOverviewDataLoading,
    isError: isMachineOverviewDataError,
  } = useMachineCvOverviewList();

  const {
    data = { dashboards: [], locations: [] },
    isLoading: isDashboardDataLoading,
    isError: isDashboardDataError,
  } = useDashboardsOverview({ cacheTime: 0 });

  const { mode } = useModeSelect();

  const projects = getProjectsFromMachineCVOverview(machines);

  const points = useMemo<PointFeature<ProjectProperties>[]>(() => {
    if (mode === "projects") {
      return projects.map<PointFeature<ProjectProperties>>(
        ({
          latitude,
          longitude,
          name,
          id,
          corporationName,
          companyName,
          companyId,
        }) => {
          return {
            type: "Feature",
            properties: {
              id,
              uniqId: companyId + "-" + id,
              name,
              type: "Project",
              cluster: false,
              slug: getProjectElementId({
                corporation: corporationName || "",
                company: companyName || "",
                project: name,
              }),
            },
            geometry: {
              type: "Point",
              coordinates: [longitude as number, latitude as number],
            },
          } as PointFeature<ProjectProperties>;
        }
      );
    }

    return data.locations.reduce<PointFeature<ProjectProperties>[]>(
      (features, { latitude, longitude, projects }) => [
        ...features,
        ...projects.map(
          ({ id, metaInformation: { projectId, projectName } }) => {
            return {
              type: "Feature",
              properties: {
                uniqId: projectId + "-" + id,
                id: projectId,
                name: projectName,
                type: "Project",
                cluster: false,
              },
              geometry: {
                type: "Point",
                coordinates: [longitude as number, latitude as number],
              },
            } as PointFeature<ProjectProperties>;
          }
        ),
      ],
      []
    );
  }, [data.locations, mode, projects]);

  const cards = useMemo<IDashboardCard[]>(() => {
    if (mode === "projects") {
      return projects.map<IDashboardCard>(
        ({ id = "", name, corporationName, companyName }) => ({
          id,
          name,
          type: "Project",
          subtitle: "",
          isBookmarked: false,
          slug: getProjectElementId({
            corporation: corporationName || "",
            company: companyName || "",
            project: name,
          }),
        })
      );
    }
    return data.dashboards.map<IDashboardCard>(
      ({ id, parentId, parentType, metaInformation }) => {
        return {
          uniqId: parentId + "-" + id,
          id: parentId,
          type: parentType,
          name: metaInformation.title,
          subtitle: metaInformation.titleParent,
          isBookmarked: false,
        };
      }
    );
  }, [data.dashboards, mode, projects]);

  return {
    points,
    cards,
    isLoading: isDashboardDataLoading || isMachineOverviewDataLoading,
    isError: isDashboardDataError || isMachineOverviewDataError,
  };
};
