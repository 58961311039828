import type { SVGProps } from 'react';
import React from 'react';

const TasksIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='#ffffff'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0ZM7 0.875C6.19565 0.875 5.39918 1.03343 4.65606 1.34124C3.91294 1.64905 3.23773 2.10021 2.66897 2.66897C2.10021 3.23773 1.64905 3.91294 1.34124 4.65606C1.03343 5.39918 0.875 6.19565 0.875 7C0.875 7.80435 1.03343 8.60082 1.34124 9.34394C1.64905 10.0871 2.10021 10.7623 2.66897 11.331C3.23773 11.8998 3.91294 12.351 4.65606 12.6588C5.39918 12.9666 6.19565 13.125 7 13.125C8.62445 13.125 10.1824 12.4797 11.331 11.331C12.4797 10.1824 13.125 8.62445 13.125 7C13.125 5.37555 12.4797 3.81763 11.331 2.66897C10.1824 1.52031 8.62445 0.875 7 0.875ZM6.5625 2.625C6.6649 2.62497 6.76406 2.66085 6.84273 2.7264C6.92139 2.79196 6.97457 2.88302 6.993 2.98375L7 3.0625V7H9.1875C9.29683 6.9998 9.40227 7.04054 9.48306 7.11419C9.56385 7.18785 9.61414 7.28909 9.62402 7.39797C9.63389 7.50685 9.60265 7.61548 9.53643 7.70248C9.47022 7.78947 9.37383 7.84852 9.26625 7.868L9.1875 7.875H6.5625C6.4601 7.87503 6.36094 7.83915 6.28227 7.7736C6.20361 7.70804 6.15043 7.61697 6.132 7.51625L6.125 7.4375V3.0625C6.125 2.94647 6.17109 2.83519 6.25314 2.75314C6.33519 2.67109 6.44647 2.625 6.5625 2.625Z' />
    </svg>
  );
};

export default TasksIcon;
