import type { Filter } from './Table';

const valueIncludes = (value: unknown, search: string): boolean =>
  value?.toString().trim().toLowerCase().includes(search.trim().toLowerCase()) || false;

export const filter = (items: any[], filters: Filter[]) => {
  const normalized = filters.map(({ key, search, operator }) => ({
    key,
    operator,
    search: search.trim().toLowerCase(),
  }));

  const and = normalized.filter(({ operator }) => operator === 'and');

  const or = normalized.filter(({ operator }) => operator === 'or');

  if (and.length > 0) {
    const filtered = [];

    filtered.push(
      ...items.filter((item) =>
        and.reduce((valid, { key, search }) => valid && valueIncludes(item[key], search), true),
      ),
    );

    if (or.length > 0) {
      return filtered.filter((item) =>
        or.reduce((valid, { key, search }) => valid || valueIncludes(item[key], search), false),
      );
    }

    return filtered;
  }

  return items.filter((item) =>
    or.reduce((valid, { key, search }) => valid || valueIncludes(item[key], search), false),
  );
};
