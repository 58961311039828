import * as am5 from '@amcharts/amcharts5';

export const watermarkLogos = [
  {
    height: 70,
    opacity: 0.06,
    x: am5.percent(50),
    y: am5.percent(50),
    centerX: am5.percent(50),
    centerY: am5.percent(50),
  }, // Center
  {
    height: 30,
    opacity: 0.06,
    x: am5.percent(1),
    y: am5.percent(0),
    centerX: am5.percent(1),
    centerY: am5.percent(0),
  }, // Top-left corner
  {
    height: 30,
    opacity: 0.06,
    x: am5.percent(100),
    y: am5.percent(0),
    centerX: am5.percent(100),
    centerY: am5.percent(0),
  }, // Top-right corner
  {
    height: 30,
    opacity: 0.06,
    x: am5.percent(1),
    y: am5.percent(100),
    centerX: am5.percent(1),
    centerY: am5.percent(100),
  }, // Bottom-left corner
  {
    height: 30,
    opacity: 0.06,
    x: am5.percent(100),
    y: am5.percent(100),
    centerX: am5.percent(100),
    centerY: am5.percent(100),
  }, // Bottom-right corner
];
