import { Column } from "../common/Table";
import { D850OS, DModels } from "./models";
import {
  Icon,
  TooltipHost,
  Text,
  Stack,
  ITooltipHostStyles,
} from "@fluentui/react";
import { iconStyle } from "../../schema/Constants";
import {
  D325TableItem,
  DBasicTableItem,
  D850EcoTableItem,
  D850TableItem,
} from "./tableItemModels";
import React from "react";

export type DataloggerColumnsProps = {
  model: DModels;
  isMetaDataContributor?: boolean;
  onEdit: (
    entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem
  ) => void;
  onDelete: (
    entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem
  ) => void;
  onSelectMachines: (
    entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem
  ) => void;
  onD850LinuxConfig: (
    entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem
  ) => void;

  onGenerateConfig: (
    entry: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem
  ) => void;
};

/**
 * Gets the base columns.
 * @param macAddressHeader The MAC address header name.
 * @returns The base columns array.
 */
const getBaseColumns = (macAddressHeader: string): Column[] => {
  let result: Column[] = [
    {
      key: "macAddress",
      name: macAddressHeader,
      fieldName: "macAddress",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "projectName",
      name: "Project name",
      fieldName: "projectName",
      minWidth: 200,
      isSortable: true,
      onRender: (
        datalogger:
          | D325TableItem
          | DBasicTableItem
          | D850EcoTableItem
          | D850TableItem
      ) => {
        const tooltipId = `tooltip_${datalogger.projectId}`;
        const hostStyles: Partial<ITooltipHostStyles> = {
          root: { display: "inline-block" },
        };

        return (
          <TooltipHost
            content={datalogger.projectName}
            id={tooltipId}
            styles={hostStyles}
          >
            <Text variant="smallPlus" aria-describedby={tooltipId}>
              {datalogger.projectName}
            </Text>
          </TooltipHost>
        );
      },
    },
    {
      key: "machines",
      name: "Machines",
      fieldName: "machines",
      minWidth: 150,
      isSortable: false,
      onRender: (
        datalogger:
          | D325TableItem
          | DBasicTableItem
          | D850EcoTableItem
          | D850TableItem
      ) => {
        return (
          datalogger.machines?.length > 0 && (
            <Stack verticalAlign="center">
              {React.Children.map(datalogger.machines, (machine) => {
                return (
                  <Stack.Item>
                    <Text variant="smallPlus">{machine}</Text>
                  </Stack.Item>
                );
              })}
            </Stack>
          )
        );
      },
    },
  ];

  return result;
};

/**
 * * COLUMNS
 */

const d325Columns: Column[] = [
  ...getBaseColumns("MAC Address"),
  {
    key: "firmwareVersion",
    name: "Firmware Version",
    fieldName: "firmwareVersion",
    minWidth: 150,
    isSortable: true,
    onRender: ({ firmwareVersion }: D325TableItem) => (
      <Stack horizontalAlign="center">
        <Text variant="smallPlus">{firmwareVersion}</Text>
      </Stack>
    ),
  },
];

const dBasicColumns: Column[] = [
  ...d325Columns,
  {
    key: "ipAddress",
    name: "IP Address",
    fieldName: "ipAddress",
    minWidth: 150,
    isSortable: true,
  },
  {
    key: "subnetMask",
    name: "Subnet Mask",
    fieldName: "subnetMask",
    minWidth: 150,
    isSortable: true,
  },
  {
    key: "gateway",
    name: "Gateway",
    fieldName: "gateway",
    minWidth: 150,
    isSortable: true,
  },
];

const d850EcoColumns: Column[] = [
  ...getBaseColumns("Basis Card MAC Address"),
  {
    key: "adjustmentCardType",
    name: "Adjustment Card Type",
    fieldName: "adjustmentCardType",
    minWidth: 150,
    isSortable: true,
    onRender: ({ adjustmentCardType }: D850EcoTableItem) => (
      <Stack horizontalAlign="center">
        <Text variant="smallPlus">{adjustmentCardType}</Text>
      </Stack>
    ),
  },
];

const d850Columns: Column[] = [
  ...d850EcoColumns,
  {
    key: "operativeSystem",
    name: "Operative System",
    fieldName: "operativeSystem",
    minWidth: 150,
    isSortable: true,
    onRender: ({ operativeSystem }: D850TableItem) => (
      <Stack horizontalAlign="center">
        <Text variant="smallPlus">{operativeSystem}</Text>
      </Stack>
    ),
  },
  {
    key: "ipAddress",
    name: "IP Address",
    fieldName: "ipAddress",
    minWidth: 150,
    isSortable: true,
  },
  {
    key: "subnetMask",
    name: "Subnet Mask",
    fieldName: "subnetMask",
    minWidth: 150,
    isSortable: true,
  },
  {
    key: "gateway",
    name: "Gateway",
    fieldName: "gateway",
    minWidth: 150,
    isSortable: true,
  },
];

/**
 * Gets the basic datalogger table basic actions.
 * @param model The datalogger model.
 * @param onEdit The onEdit click event handler.
 * @param onDelete The onDelete click event handler
 * @param onSelectMachines The onSelectMachines click event handler
 * @param onD850LinuxConfig The on D-850 CFG Config click event handler
 * @param onGenerateDdpConfig The DDP configuration generation click event handler
 * @returns The basic item actions table columns array.
 */
const getColumnItemActions = ({
  model,
  onEdit,
  onDelete,
  onSelectMachines,
  onD850LinuxConfig,
  onGenerateConfig,
}: DataloggerColumnsProps): Column[] => {
  let result: Column[] = [
    {
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isSortable: false,
      isExportable: false,
      onRender: (
        datalogger:
          | D325TableItem
          | DBasicTableItem
          | D850EcoTableItem
          | D850TableItem
      ) => {
        let activateLinuxConfigAction = false;
        let activateGenerateConfig = false;

        // Filtrates the actions by items properties
        switch (model) {
          case DModels.D650:
            activateGenerateConfig = true;
            break;

          case DModels.D850:
            if (datalogger.machineIds.length === 1) {
              let d850tableItem = datalogger as D850TableItem;
              activateLinuxConfigAction =
                d850tableItem.operativeSystem === D850OS.Linux;
              activateGenerateConfig = !activateLinuxConfigAction;
            }

            break;
        }

        return (
          <div style={{ display: "flex" }} key={datalogger.id + "actions"}>
            <TooltipHost
              key={datalogger.id + "actions0"}
              content={"Edit"}
              styles={{ root: { display: "flex" } }}
            >
              <Icon
                iconName="Edit"
                onClick={() => onEdit(datalogger)}
                style={iconStyle}
              />
            </TooltipHost>
            <TooltipHost
              key={datalogger.id + "actions1"}
              content={"Delete"}
              styles={{ root: { display: "flex" } }}
            >
              <Icon
                iconName="Delete"
                onClick={() => onDelete(datalogger)}
                style={iconStyle}
              />
            </TooltipHost>
            <TooltipHost
              key={datalogger.id + "actions2"}
              content={"Select machines"}
              styles={{ root: { display: "flex" } }}
            >
              <Icon
                iconName="Link"
                onClick={() => onSelectMachines(datalogger)}
                style={iconStyle}
              />
            </TooltipHost>
            {activateLinuxConfigAction && (
              <TooltipHost
                key={datalogger.id + "actions3"}
                content={"D-850 CFG Convert"}
                styles={{ root: { display: "flex" } }}
              >
                <Icon
                  iconName="ConfigurationSolid"
                  onClick={() => onD850LinuxConfig(datalogger)}
                  style={iconStyle}
                />
              </TooltipHost>
            )}
            {activateGenerateConfig && (
              <TooltipHost
                key={datalogger.id + "actions4"}
                content={"Generate configuration"}
                styles={{ root: { display: "flex" } }}
              >
                <Icon
                  iconName="CloudUpgeared"
                  onClick={() => onGenerateConfig(datalogger)}
                  style={iconStyle}
                />
              </TooltipHost>
            )}
          </div>
        );
      },
    },
  ];

  return result;
};

/**
 * Gets the datalogger table columns.
 * @param model The datalogger model
 * @param onEdit The onEdit click event handler.
 * @param onDelete The onDelete click event handler
 * @param onSelectMachines The onSelectMachines click event handler
 * @returns The table columns array.
 */
export const getDataloggerColumns = ({
  model,
  isMetaDataContributor,
  onEdit,
  onDelete,
  onSelectMachines,
  onD850LinuxConfig,
  onGenerateConfig,
}: DataloggerColumnsProps): Column[] => {
  let result: Column[] = [];
  switch (model) {
    case DModels.D325:
      result.push(...d325Columns);
      break;

    case DModels.D555:
    case DModels.D650:
      result.push(...dBasicColumns);
      break;

    case DModels.D850Eco:
      result.push(...d850EcoColumns);
      break;

    case DModels.D850:
      result.push(...d850Columns);
      break;
  }

  if (!isMetaDataContributor) {
    return result;
  }

  // Adds the actions.
  let actions = getColumnItemActions({
    model,
    onEdit,
    onDelete,
    onSelectMachines,
    onD850LinuxConfig,
    onGenerateConfig,
  });
  result.push(...actions);

  return result;
};
