import { differenceInDays, format } from "date-fns";
import { useRef } from "react";

import { binarySearch } from "../../../../utils";
import {
  checkAvailableCache,
  checkZoomReloadAvailable,
} from "../TrendViewPlot/helper";
import { CHART_NUMBER_OF_POINTS } from "../../constants/controlOptions";
import type { ConnectedTimeRange } from "../../hooks/useControlsStore";
import useControlsStore from "../../hooks/useControlsStore";
import getPeriodFilters, { FORMAT } from "../../utils/getPeriodFilters";
import { apiService } from "../../../common";
import DataTypeEnum from "../../../common/constants/DataTypeEnum";
import useRefreshStore from "../../../common/hooks/useRefreshStore";
import { queryClient } from "../../../core";
import type { ResponseSimplifiedSignal } from "../../../../types";

declare global {
  interface Array<T> {
    findLastIndex(
      predicate: (value: T, index: number, obj: T[]) => unknown,
      thisArg?: any
    ): number;
  }
}

export const useTrendViewZoomInData = ({
  signal,
  selectedPeriod,
}: {
  signal: ResponseSimplifiedSignal;
  selectedPeriod: any;
}) => {
  const stateRef: any = useRef({});

  const { setLoadingCharts, getReferenceSignal } = useControlsStore();

  const { refreshInterval } = useRefreshStore(({ refreshInterval }) => ({
    refreshInterval,
  }));

  const handleZoomChangeForTrend = (currentRange: any) => {
    const referenceSignal = getReferenceSignal();
    const signalsPromises: any = [];
    const startDate = format(new Date(currentRange.startIndex), FORMAT);
    const endDate = format(new Date(currentRange.endIndex), FORMAT);
    const zoomedDaysPeriod = differenceInDays(
      currentRange.endIndex,
      currentRange.startIndex
    );
    const isZoomReloadAvailable = checkZoomReloadAvailable(
      signal,
      currentRange,
      selectedPeriod,
      zoomedDaysPeriod
    );
    const isCacheDataAvailable = checkAvailableCache(
      signal?.id ? (stateRef.current[signal.id] || {}).cachedPeriod : {},
      currentRange
    );

    if (referenceSignal) {
      const isZoomReloadAvailableForReference = checkZoomReloadAvailable(
        referenceSignal,
        currentRange,
        selectedPeriod,
        zoomedDaysPeriod
      );
      const isCacheDataAvailableForReference = checkAvailableCache(
        stateRef.current[referenceSignal.id]?.cachedPeriod || {},
        currentRange
      );

      if (
        isZoomReloadAvailableForReference &&
        !isCacheDataAvailableForReference
      ) {
        const periodReferenceFilters = getPeriodFilters(
          selectedPeriod,
          referenceSignal
        );
        signalsPromises.push({
          promise: apiService.measuredDataDownsampled[
            referenceSignal.dataType === DataTypeEnum.FastTrend
              ? "getFastTrendMeasuredZipDataInAPeriod"
              : "getTrendMeasuredZipDataInAPeriod"
          ]({
            startDate,
            endDate,
            signalId: referenceSignal.id,
            numberOfPoints: CHART_NUMBER_OF_POINTS,
          }),
          id: referenceSignal.id,
          cacheKey: [
            "trend-measured-data-in-a-period",
            referenceSignal.machineId,
            referenceSignal.id,
            periodReferenceFilters.startDate,
            periodReferenceFilters.endDate,
            `${CHART_NUMBER_OF_POINTS}`,
            refreshInterval?.key,
          ],
        });
      }
    }

    if (isZoomReloadAvailable && !isCacheDataAvailable) {
      const periodFilters = getPeriodFilters(selectedPeriod, signal);
      signalsPromises.push({
        promise: apiService.measuredDataDownsampled[
          signal.dataType === DataTypeEnum.FastTrend
            ? "getFastTrendMeasuredZipDataInAPeriod"
            : "getTrendMeasuredZipDataInAPeriod"
        ]({
          startDate,
          endDate,
          signalId: `${signal.id}`,
          numberOfPoints: CHART_NUMBER_OF_POINTS,
        }),
        id: signal.id,
        cacheKey: [
          "trend-measured-data-in-a-period",
          signal.machineId,
          signal.id,
          periodFilters.startDate,
          periodFilters.endDate,
          `${CHART_NUMBER_OF_POINTS}`,
          refreshInterval?.key,
        ],
      });
    }

    if (signalsPromises.length > 0) {
      setLoadingCharts(true);
      Promise.all(
        signalsPromises.map((signalPromise: any) => signalPromise.promise)
      )
        .then((res) => {
          res.forEach((signalRes, idx) => {
            if (signalRes && signalRes.length > 0) {
              const { cacheKey, id: signalId } = signalsPromises[idx];
              queryClient.setQueryData(cacheKey, (data: any) => {
                stateRef.current[signalId] = stateRef.current[signalId] || {
                  data: [],
                  cachedPeriod: {},
                };

                if (stateRef.current[signalId].data.length === 0) {
                  stateRef.current[signalId].data = [...(<[]>data)];
                }

                stateRef.current[signalId].cachedPeriod = {
                  start: startDate,
                  end: endDate,
                };

                const cachedData = stateRef.current[signalId].data;

                const firstPointTimestamp = format(
                  new Date(signalRes[0].timeStamp),
                  FORMAT
                );
                const lastPointTimestamp = format(
                  new Date(signalRes[signalRes.length - 1].timeStamp),
                  FORMAT
                );
                const startIndex = binarySearch(cachedData, (item) => {
                  if (item.timeStamp.includes(firstPointTimestamp)) {
                    return 0;
                  }
                  return item.timeStamp > firstPointTimestamp ? 1 : -1;
                });
                const lastIndex = binarySearch(cachedData, (item) => {
                  if (item.timeStamp.includes(lastPointTimestamp)) {
                    return 0;
                  }
                  return item.timeStamp > lastPointTimestamp ? 1 : -1;
                });
                const existingDataLeft = [...cachedData].splice(0, startIndex);
                const existingDataRight = [...cachedData].splice(
                  lastIndex,
                  cachedData.length
                );
                return [
                  ...existingDataLeft,
                  ...signalRes,
                  ...existingDataRight,
                ];
              });
            }
            setLoadingCharts(false);
          });
          setLoadingCharts(false);
        })
        .catch(() => {
          setLoadingCharts(false);
        });
    }
  };

  const handleZoomChange = (currentRange: ConnectedTimeRange["range"]) => {
    handleZoomChangeForTrend(currentRange);
  };

  return {
    handleZoomChange,
  };
};
