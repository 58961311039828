import { range } from 'lodash-es';

const linspace = (a: number, b: number, n: number) => {
  const every = (b - a) / (n - 1),
    ranged = range(a, b, every);

  return ranged.length == n ? ranged : ranged.concat(b);
};

export default linspace;
