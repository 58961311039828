import type { SVGProps } from 'react';
import React from 'react';

const NextPageIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='7'
      height='12'
      viewBox='0 0 7 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0.646016 0.146986C0.739647 0.0531173 0.866727 0.000279581 0.999309 9.20536e-05C1.13189 -9.54742e-05 1.25912 0.0523826 1.35302 0.145986L6.83702 5.61099C6.88827 5.66208 6.92893 5.72278 6.95667 5.78962C6.98442 5.85646 6.9987 5.92812 6.9987 6.00049C6.9987 6.07285 6.98442 6.14451 6.95667 6.21135C6.92893 6.27819 6.88827 6.33889 6.83702 6.38999L1.35302 11.855C1.25859 11.9459 1.13221 11.9962 1.00111 11.9948C0.870009 11.9935 0.744672 11.9408 0.652095 11.8479C0.559519 11.7551 0.507112 11.6296 0.506162 11.4985C0.505212 11.3674 0.555795 11.2412 0.647016 11.147L5.81202 5.99999L0.647016 0.853986C0.553148 0.760355 0.50031 0.633275 0.500123 0.500693C0.499935 0.368111 0.552413 0.240882 0.646016 0.146986Z'
        fill='#201F1E'
      />
    </svg>
  );
};

export default NextPageIcon;
