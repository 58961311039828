import type { SVGProps } from 'react';
import React from 'react';

const ReferenceIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width={15} height={17} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M5 0a2 2 0 0 0-2 2v6h1V2a1 1 0 0 1 1-1h4v3.5A1.5 1.5 0 0 0 10.5 6H14v8a1 1 0 0 1-1 1h-3v1h3a2 2 0 0 0 2-2V5.414a1.5 1.5 0 0 0-.44-1.06L10.646.439A1.5 1.5 0 0 0 9.586 0H5Zm8.793 5H10.5a.5.5 0 0 1-.5-.5V1.207L13.793 5ZM2.5 9a.5.5 0 1 1 0 1h-1a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h1a.5.5 0 0 1 0 1h-1A1.5 1.5 0 0 1 0 15.5v-5A1.5 1.5 0 0 1 1.5 9h1Zm4 7a.5.5 0 0 0 0 1h1A1.5 1.5 0 0 0 9 15.5v-5A1.5 1.5 0 0 0 7.5 9h-1a.5.5 0 1 0 0 1h1a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-1Zm-2.001-4a.5.5 0 0 1 .5.5l.001 3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5h-.001Zm.001-1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z' />
  </svg>
);

export default ReferenceIcon;
