import { format as fnsFormat } from 'date-fns';
import { forEach } from 'lodash-es';

import { viewDropdownOptions } from '../../config';

export const prepareColorDataChartBySelectedView = ({ data, selectedView }: any) => {
  if (!data || data.length === 0 || !selectedView) return data;

  const groupDataSum = data.reduce((res: any, item: any) => {
    const { x, y, date }: any = item;
    const dateObject = new Date(date);
    let groupKey = fnsFormat(dateObject, 'yyyy LL dd');

    if (selectedView.key === viewDropdownOptions[0].key) {
      groupKey = fnsFormat(dateObject, 'yyyy');
    }

    if (selectedView.key === viewDropdownOptions[1].key) {
      groupKey = fnsFormat(dateObject, 'yyyy LL');
    }

    if (res[groupKey]) {
      const xSum = res[groupKey].x + x;
      const ySum = res[groupKey].y + y;
      const count = res[groupKey].count + 1;
      res[groupKey] = { x: xSum, y: ySum, count };
    } else {
      res[groupKey] = { x, y, count: 1 };
    }
    return res;
  }, {});

  const averageData: any = [];

  forEach(groupDataSum, (item, key) => {
    averageData.push({ date: key, x: item.x / item.count, y: item.y / item.count });
  });

  return averageData;
};
