import { useMemo } from 'react';

import type { MachineCvOverviewList } from '../../../../types';

import { filter } from './filter';
import useOverviewStore from './useOverviewStore';

const useOverviewItems = (data: MachineCvOverviewList | undefined): MachineCvOverviewList => {
  const { condition, search, searchBy } = useOverviewStore(
    (state) => ({
      condition: state.condition,
      search: state.search,
      searchBy: state.searchBy,
    }),
  );

  return useMemo<MachineCvOverviewList>(() => {
    if (data === undefined) return [];
    return filter(data, {
      condition,
      search,
      searchBy,
      status: 'All',
      filter: 'all',
    });
  }, [data, condition, search]);
};

export default useOverviewItems;
