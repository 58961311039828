import { useMemo, useEffect, useState, CSSProperties } from "react";

import { useZodForm, renderFormItems } from "../common/Form";
import BaseDialog, { DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";

import {
  DialogFooter,
  DefaultButton,
  IDialogProps,
  DialogType,
  Stack,
  StackItem,
  Text,
  Spinner,
  SpinnerSize,
  PrimaryButton,
  ChoiceGroup,
  IChoiceGroupOption,
  Pivot,
  PivotItem,
  FontIcon,
} from "@fluentui/react";
import { z } from "zod";
import {
  ServiceAccountDetails,
  ServiceAccountUpdate,
  UserExtendedProperties,
} from "./models";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import {
  listAsyncServiceAccountProducts,
  selectServiceAccountsProducts,
} from "./reducerProducts";
import {
  selectServiceAccountsError,
  selectServiceAccountsStatus,
} from "./reducer";
import { Status } from "../../schema/status";
import { SubscriptionKeys } from "./SubscriptionKeys";
import type { FieldError } from "react-hook-form";
import { RolesComponentDetails } from "../Roles/RolesComponentDetails";
import {
  detailsServiceAccount,
  sendsConfirmationPassServiceAccount,
  updateServiceAccount,
} from "./api";
import {
  MemberIdRead,
  ServiceAccountFieldsReqExtra,
  UserFieldsNonReq,
  getUserFieldsReq,
  maxLengthType3,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { areObjectsEqual } from "../../schema/Utils";

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "50px",
};

const getSchema = () =>
  z.object({
    email: z
      .string()
      .email({ message: "Invalid email address" })
      .min(1, { message: "This field is required" }),
    lastName: z.string().min(1, { message: "This field is required" }),
    firstName: z.string().min(1, { message: "This field is required" }),
    purpose: z
      .string()
      .min(1, { message: "This field is required" })
      .max(maxLengthType3, {
        message: `Name must contain at most ${maxLengthType3} character(s)`,
      }),
    jobTitle: z
      .string()
      .max(maxLengthType3, {
        message: `Name must contain at most ${maxLengthType3} character(s)`,
      })
      .optional(),
    companyName: z
      .string()
      .max(maxLengthType3, {
        message: `Name must contain at most ${maxLengthType3} character(s)`,
      })
      .optional(),
    department: z
      .string()
      .max(maxLengthType3, {
        message: `Name must contain at most ${maxLengthType3} character(s)`,
      })
      .optional(),
    streetAddress: z
      .string()
      .max(maxLengthType3, {
        message: `Name must contain at most ${maxLengthType3} character(s)`,
      })
      .optional(),
    city: z
      .string()
      .max(maxLengthType3, {
        message: `Name must contain at most ${maxLengthType3} character(s)`,
      })
      .optional(),
    stateOrProvince: z
      .string()
      .max(maxLengthType3, {
        message: `Name must contain at most ${maxLengthType3} character(s)`,
      })
      .optional(),
    zipOrPostalCode: z
      .string()
      .max(maxLengthType3, {
        message: `Name must contain at most ${maxLengthType3} character(s)`,
      })
      .optional(),
    countryOrRegion: z
      .string()
      .max(maxLengthType3, {
        message: `Name must contain at most ${maxLengthType3} character(s)`,
      })
      .optional(),
    mobilePhone: z
      .union([
        z.string().length(0, { message: "Invalid number" }),
        z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    memberId: z.string().optional(),
  });

type DetailsDialogProps = IDialogProps & {
  data?: ServiceAccountDetails;
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const DetailsDialog = ({
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: DetailsDialogProps) => {
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getSchema(), []);
  const status = useAppSelector(selectServiceAccountsStatus);
  const error = useAppSelector(selectServiceAccountsError);
  const items = useAppSelector(selectServiceAccountsProducts);
  const [isLoading, setLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [productIdToAdd, setProductIdToAdd] = useState<string>(
    data?.apimSubscriptions?.at(0).scope
  );

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  useEffect(() => {
    dispatch(listAsyncServiceAccountProducts());
  }, [dispatch]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        email: data.ddpProperties.email ? data.ddpProperties.email : "",
        lastName: data.ddpProperties.lastName
          ? data.ddpProperties.lastName
          : "",
        firstName: data.ddpProperties.firstName
          ? data.ddpProperties.firstName
          : "",
        purpose: data.ddpProperties.purpose ? data.ddpProperties.purpose : "",
        jobTitle: data.ddpProperties.jobTitle
          ? data.ddpProperties.jobTitle
          : "",
        companyName: data.ddpProperties.companyName
          ? data.ddpProperties.companyName
          : "",
        department: data.ddpProperties.department
          ? data.ddpProperties.department
          : "",
        streetAddress: data.ddpProperties.streetAddress
          ? data.ddpProperties.streetAddress
          : "",
        city: data.ddpProperties.city ? data.ddpProperties.city : "",
        stateOrProvince: data.ddpProperties.stateOrProvince
          ? data.ddpProperties.stateOrProvince
          : "",
        zipOrPostalCode: data.ddpProperties.zipOrPostalCode
          ? data.ddpProperties.zipOrPostalCode
          : "",
        countryOrRegion: data.ddpProperties.countryOrRegion
          ? data.ddpProperties.countryOrRegion
          : "",
        mobilePhone: data.ddpProperties.mobilePhone
          ? data.ddpProperties.mobilePhone
          : "",
        memberId: data.ddpProperties.memberId
          ? data.ddpProperties.memberId
          : "",
      },
    }),
  });

  useEffect(() => {
    setIsFormChanged(
      !areObjectsEqual(control._formValues, control._defaultValues) ||
        data?.apimSubscriptions?.at(0).scope !== productIdToAdd
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  useEffect(() => {
    data
      ? reset({
          email: data.ddpProperties.email ? data.ddpProperties.email : "",
          lastName: data.ddpProperties.lastName
            ? data.ddpProperties.lastName
            : "",
          firstName: data.ddpProperties.firstName
            ? data.ddpProperties.firstName
            : "",
          purpose: data.ddpProperties.purpose ? data.ddpProperties.purpose : "",
          jobTitle: data.ddpProperties.jobTitle
            ? data.ddpProperties.jobTitle
            : "",
          companyName: data.ddpProperties.companyName
            ? data.ddpProperties.companyName
            : "",
          department: data.ddpProperties.department
            ? data.ddpProperties.department
            : "",
          streetAddress: data.ddpProperties.streetAddress
            ? data.ddpProperties.streetAddress
            : "",
          city: data.ddpProperties.city ? data.ddpProperties.city : "",
          stateOrProvince: data.ddpProperties.stateOrProvince
            ? data.ddpProperties.stateOrProvince
            : "",
          zipOrPostalCode: data.ddpProperties.zipOrPostalCode
            ? data.ddpProperties.zipOrPostalCode
            : "",
          countryOrRegion: data.ddpProperties.countryOrRegion
            ? data.ddpProperties.countryOrRegion
            : "",
          mobilePhone: data.ddpProperties.mobilePhone
            ? data.ddpProperties.mobilePhone
            : "",
          memberId: data.ddpProperties.memberId
            ? data.ddpProperties.memberId
            : "",
        })
      : reset({
          email: "",
          lastName: "",
          firstName: "",
          purpose: "",
          jobTitle: "",
          companyName: "",
          department: "",
          streetAddress: "",
          city: "",
          stateOrProvince: "",
          zipOrPostalCode: "",
          countryOrRegion: "",
          mobilePhone: "",
          memberId: "",
        });
  }, [data, reset]);

  const handleClose = () => {
    setLoading(false);
    setProductIdToAdd("");
    onClose?.();
  };

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);

    const aux: ServiceAccountUpdate = {
      ...(formData as UserExtendedProperties),
      productId: productIdToAdd,
    };
    await updateServiceAccount(aux.memberId, aux).then((response) =>
      onSuccess("status" in response, "")
    );

    handleClose();
  });

  function onChange(ev, option: IChoiceGroupOption): void {
    if (
      option.text.includes("WebApp") === true ||
      option.text.includes("Wireless") === true
    )
      notification.severeWarning(
        "Remind: The selected product is for internal users. "
      );

    setProductIdToAdd(option.key);
  }

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.close,
        title: "Details Service Account",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
      styles={{
        main: {
          "@media (min-width: 480px)": {
            height: "86vh",
          },
        },
      }}
      size={DialogSize.L}
    >
      <div style={pageStyle}>
        <Pivot aria-label="Pivots to edit service account">
          <PivotItem
            headerText={"Properties"}
            itemKey={"properties"}
            onRenderItemLink={() => (
              <Text style={titleStylePivot}>Properties</Text>
            )}
          >
            <Stack horizontalAlign="baseline" verticalAlign="center">
              <div style={{ marginTop: "50px" }}>
                <Text style={titleStyle}>General</Text>
              </div>
              <form onSubmit={onSubmit}>
                <Stack
                  style={{ maxWidth: "70vw", padding: "35px" }}
                  wrap
                  horizontal
                  tokens={{ childrenGap: 8 }}
                  horizontalAlign="stretch"
                  verticalAlign="center"
                >
                  {renderFormItems(
                    [
                      ...MemberIdRead,
                      ...getUserFieldsReq(true),
                      ...ServiceAccountFieldsReqExtra,
                      ...UserFieldsNonReq,
                    ],
                    {
                      control,
                      errors: errors as { [schemaProp: string]: FieldError },
                    }
                  ).map((ele) => (
                    <StackItem key={ele.key}> {ele}</StackItem>
                  ))}
                </Stack>
                <Text style={titleStyle}>Product</Text>
                <Stack
                  style={{ maxWidth: "70vw" }}
                  wrap
                  horizontal
                  tokens={{ childrenGap: 8 }}
                  horizontalAlign="space-evenly"
                  verticalAlign="center"
                >
                  <ChoiceGroup
                    options={items?.map((ele) => {
                      return {
                        key: ele.id,
                        text:
                          ele.displayName.includes("WebApp") ||
                          ele.displayName.includes("Wireless")
                            ? ele.displayName + " (for internal users)"
                            : ele.displayName,
                      };
                    })}
                    onChange={onChange}
                    selectedKey={productIdToAdd}
                  />
                </Stack>
                <Text style={titleStyle}>Confirmation Password Send </Text>
                <Stack
                  style={{ maxWidth: "70vw" }}
                  wrap
                  horizontal
                  tokens={{ childrenGap: 8 }}
                  horizontalAlign="space-evenly"
                  verticalAlign="center"
                >
                  <DefaultButton
                    text="Send"
                    onClick={() =>
                      sendsConfirmationPassServiceAccount(
                        data.ddpProperties.memberId
                      ).then((resp) => {
                        resp.status > 199 && resp.status < 300
                          ? notification.success(
                              "Performed action successfully"
                            )
                          : notification.error("Something went wrong. ");
                      })
                    }
                    onRenderText={(props, render) => (
                      <Stack horizontal verticalAlign="center">
                        <FontIcon aria-label="Compass" iconName="Mail" />
                        <div style={{ alignSelf: "center" }}>
                          {render(props)}
                        </div>
                      </Stack>
                    )}
                  />
                </Stack>
                <DialogFooter>
                  <PrimaryButton
                    type="submit"
                    text="Save Changes"
                    disabled={isLoading || !isValid || !isFormChanged}
                    onRenderIcon={() =>
                      isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                    }
                  />
                </DialogFooter>
              </form>
            </Stack>
          </PivotItem>
          <PivotItem
            headerText={"Subscriptions"}
            itemKey={"subscriptions"}
            onRenderItemLink={() => (
              <Text style={titleStylePivot}>Subscriptions</Text>
            )}
          >
            <div style={{ marginTop: "50px" }}>
              {data.apimSubscriptions?.map((sub) => (
                <div key={sub.id}>
                  <Stack tokens={{ childrenGap: 20 }}>
                    <SubscriptionKeys
                      memberId={data.ddpProperties.memberId}
                      subscriptionId={sub.id.split("/").at(-1)}
                    />
                  </Stack>
                </div>
              ))}
            </div>
          </PivotItem>
          <PivotItem
            headerText={"Roles"}
            itemKey={"roles"}
            onRenderItemLink={() => <Text style={titleStylePivot}>Roles</Text>}
          >
            {RolesComponentDetails({
              data: data,
              memberId: data.ddpProperties.memberId,
              detailsFunction: detailsServiceAccount,
            })}
          </PivotItem>
        </Pivot>
      </div>
    </BaseDialog>
  );
};
