import { topMenuHeight } from "../../schema/Constants";
import { axiosInstance } from "../..";
import TrendView from "./TrendViewPage";

export const TrendViewStyled: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <>
      <TrendView marginTop={topMenuHeight} axiosInstance={axiosInstance} />
    </>
  );
};
