import uniqueId from "lodash-es/uniqueId";
import React, { useEffect, useMemo, useRef } from "react";

import type { ScatterChartData } from "./methods";
import {
  createScatterSeries,
  initializeScatterChart,
} from "./methods";

type ScatterChartProps = {
  data?: ScatterChartData[];
  settings: any;
  showDates: boolean;
};

const ScatterChart = ({
  data,
  showDates,
  settings = {},
}: ScatterChartProps) => {
  const chartRef: any = useRef(null);
  const { rootId, chartId } = useMemo(() => {
    const id = uniqueId("xy-");

    return {
      rootId: `root-${id}`,
      chartId: `chart-${id}`,
    };
  }, []);

  // Creates the chart.
  useEffect(() => {
    const { chart, root, xAxis, yAxis } = initializeScatterChart({
      chartId,
      labelX: "Average load",
      labelY: "Dynamic load",
      settings,
    });

    chartRef.current = {
      chart,
      root,
      xAxis,
      yAxis,
    };

    chart.appear(500, 200);

    return () => {
      root.dispose();
    };
  }, [settings?.background]);

  // Sets the series data.
  useEffect(() => {
    if (!chartRef.current?.chart || !data) {
      return;
    }

    const { root, chart, yAxis, xAxis } = chartRef.current;

    // Re-draws the series data.
    chart.series.clear();
    if (data.length === 0) {
      return;
    }

    createScatterSeries({
      chartId,
      root,
      chart,
      yAxis,
      xAxis,
      showDates,
      data,
    });
  }, [data, showDates]);

  return (
    <div id={rootId} className="colored-scatter-chart h-100">
      <div
        id={chartId}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 2,
          overflow: "hidden",
        }}
      />
    </div>
  );
};

ScatterChart.displayName = "ScatterChart";

export default ScatterChart;
