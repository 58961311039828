import { useQuery } from "@tanstack/react-query";

import { usePowerBi } from "../../../../Hooks";
import { apiService } from "../../../../modules/common";
import type { Machine, PowerBiEmbed, Project } from "../../../../types";

type ProjectData = {
  project: Project & { machines?: Machine[] };
  isLoading: boolean;
  isError: boolean;
  dashboard: {
    embed?: PowerBiEmbed;
    isLoading: boolean;
    isError: boolean;
  };
};

type ProjectDataProps = {
  id: string;
};

export const useProjectData = ({ id }: ProjectDataProps): ProjectData => {
  const {
    data = {} as Project,
    isLoading,
    isError,
  } = useQuery(
    ["project-data", id],
    () => apiService.metaDataRead.getProject(id),
    {
      cacheTime: 0,
    }
  );

  const dashboardResponse = usePowerBi({
    id,
    type: "Project",
    options: { cacheTime: 0, retry: 0 },
  });

  return {
    project: data,
    isLoading,
    isError,
    dashboard: {
      embed: dashboardResponse.data,
      isLoading: dashboardResponse.isLoading,
      isError: dashboardResponse.isError,
    },
  };
};
