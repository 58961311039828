import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { apiService } from '../../modules/common';

interface UseShortTrendFFTForASingleDataPointArgs {
  signalId: string;
  dataPointId: string;
}

interface UseShortTrendFFTData {
  values: { f: number; a: number }[];
  timeStamp: string;
}

type UseShortTrendFFTQueryOptions = Omit<
  UseQueryOptions<UseShortTrendFFTData, unknown, UseShortTrendFFTData, string[]>,
  'initialData' | 'queryFn' | 'queryKey'
> & { initialData?: (() => undefined) | undefined };

const useShortTrendFFTForASingleDataPoint = (
  args: UseShortTrendFFTForASingleDataPointArgs,
  options?: UseShortTrendFFTQueryOptions,
) => {
  const queryKey = ['short-trend-fft-for-a-single-data-point', args.signalId, args.dataPointId];

  const fetchData = async (): Promise<UseShortTrendFFTData> => {
    const data = await apiService.measuredDataRead.getShortTrendFFTForASingleDataPoint(args);
    return data;
  };

  return useQuery(queryKey, fetchData, options);
};

export default useShortTrendFFTForASingleDataPoint;
