/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text } from "@fluentui/react";

import type { Condition } from "../../../../modules/machine-cv/constants";
import {
  conditionColor,
  conditionFormat,
} from "../../../../modules/machine-cv/constants";
import { getRowCellWidth } from "../../../common/Table/cellWidth";
import type { Column } from "../../../common/Table/Table";

export const getColumns = (): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: "Signal",
    minWidth: 50,
    maxWidth: 100,
    isSortable: true,
    isResizable: true,
  },
  {
    key: "sensorNo",
    fieldName: "sensorNo",
    name: "Sensor No",
    minWidth: 50,
    maxWidth: 80,
    isSortable: true,
    isResizable: true,
  },
  {
    key: "signalDescription",
    fieldName: "signalDescription",
    name: "Description",
    minWidth: 50,
    isSortable: true,
    isResizable: true,
    flexGrow: 2,
    onRender: (item: any) => (
      <Text
        variant="smallPlus"
        as="p"
        nowrap={false}
        title={item.signalDescription}
      >
        {item.signalDescription}
      </Text>
    ),
  },
  {
    key: "conditionKey",
    fieldName: "condition",
    name: "Condition",
    minWidth: 100,
    isSortable: true,
    onRenderField: (props) => {
      if (!props) {
        return null;
      }
      const { item, itemIndex, column, onRender } = props;

      return (
        <div
          key={column.key}
          role="gridcell"
          style={{
            width: `calc(${getRowCellWidth(column)}px + 30px)`,
            padding: "0px 12px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              background: conditionColor[item.condition as Condition],
              color: "#fff",
              fontWeight: "600",
              height: "100%",
            }}
          >
            {onRender(
              {
                ...item,
                condition: conditionFormat[item.condition as Condition],
              },
              itemIndex,
              column
            )}
          </div>
        </div>
      );
    },
  },
];
