import { Link } from "@fluentui/react";
import type { CSSProperties } from "react";

import { format } from "../../../../utils";
import type { Column } from "../../../common/Table/Table";

export const linkStyle: CSSProperties = {
  color: "#0078d4",
  cursor: "pointer",
  textDecoration: "underline",
};

type GetColumnsOpts = {
  // users: User[];
  locationSearch: string;
};

export const getColumns = ({ locationSearch }: GetColumnsOpts): Column[] => [
  {
    key: "createdAt",
    fieldName: "createdAt",
    name: "Date",
    minWidth: 100,
    isSortable: true,
    onRender: (item) => format(new Date(item.createdAt)),
  },
  {
    key: "createdBy",
    fieldName: "createdBy",
    name: "Created By",
    minWidth: 100,
    isSortable: true,
    isExportable: false,
    // onRender: (item) => getUserById(users, item.createdBy),
  },
  {
    key: "text",
    fieldName: "text",
    name: "Thread",
    flexGrow: 2,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: (item) => {
      return (
        <Link
          underline
          href={`threads/${item.id}${locationSearch}`}
          style={linkStyle}
        >
          {item.text}
        </Link>
      );
    },
  },
];
