import "./style.scss";

import type * as am5xy from "@amcharts/amcharts5/xy";
import type { IStackProps } from "@fluentui/react";
import { Stack } from "@fluentui/react";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";

import { prefetchTrendMeasuredDataInAPeriod } from "../../../../../Hooks";
import { CHART_NUMBER_OF_POINTS } from "../../../../../modules/analysis-trend-view/constants/controlOptions";
import getPeriodFilters from "../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import TorquePlot from "./Chart";
import useDriveLoadStore from "../../hooks/useDriveLoadStore";
import type { DataPoint } from "../../../../../types";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";
import type { XYChartRef } from "../../../../common/XYChart";

import type { XAxisDates } from "../../config";
import TorqueHeader from "./Header";
import { EnrichedDriveLoadSignal } from "../../methods";

type TorqueChartState = {
  isLoading: boolean;
  hasLoaded: boolean;
};

type TorqueChartProps = IStackProps & {
  signals: EnrichedDriveLoadSignal[];
  machine: any;
  maximizeAction?: () => void;
  maximizeIcon?: JSX.Element;
  maximizeLabel?: string;
  isDriveLoadLayout?: boolean;
};

const TorqueChart = ({
  signals,
  machine,
  maximizeAction,
  maximizeIcon,
  maximizeLabel,
  isDriveLoadLayout = false,
  ...rest
}: TorqueChartProps) => {
  const ref = useRef<XYChartRef | null>(null);
  const [isZoomLoading, setIsZoomLoading] = useState(false);
  const queryClient = useQueryClient();
  const signalsLoadingCount = useIsFetching({
    queryKey: ["trend-measured-data-in-a-period"],
  });
  const [state, setState] = useState<TorqueChartState>({
    isLoading: false,
    hasLoaded: false,
  });
  const {
    period,
    averageTrend,
    isConnectedTimeAxis,
    torqueChartDates,
    trendChartDates,
    selectedTableEvent,
    updateDriveLoadStore,
  } = useDriveLoadStore((store: any) => ({
    period: store.period,
    averageTrend: store.averageTrend,
    isConnectedTimeAxis: store.isConnectedTimeAxis,
    torqueChartDates: store.torqueChartDates,
    trendChartDates: store.trendChartDates,
    selectedTableEvent: store.selectedTableEvent,
    updateDriveLoadStore: store.updateDriveLoadStore,
  }));
  const signalsToRequest = useMemo(() => {
    return averageTrend
      ? signals.filter((item: any) => !!item.p12h).map((item: any) => item.p12h)
      : signals;
  }, [averageTrend]);

  const data = useMemo(() => {
    if (state.isLoading) return [];

    const signalsPlots: any = [];
    for (const signal of signalsToRequest) {
      const periodFilters = getPeriodFilters(period, signal);

      const signalPlot = queryClient.getQueryData([
        "trend-measured-data-in-a-period",
        signal.machineId,
        signal.id,
        periodFilters.startDate,
        periodFilters.endDate,
        CHART_NUMBER_OF_POINTS.toString(),
        "off",
      ]) as DataPoint[];

      signalsPlots.push(signalPlot);
    }

    const filteredSignalPlots = signalsPlots.filter((item: any) => !!item);
    const maxLength = Math.max(
      ...filteredSignalPlots.map(({ length }: any) => length)
    );
    const result: any[] = Array.from({ length: maxLength }).map(() => ({}));
    filteredSignalPlots.forEach((signalPlot: any, index: number) => {
      for (
        let dataPointIndex = 0;
        dataPointIndex < maxLength;
        dataPointIndex++
      ) {
        const dataPoint = signalPlot[dataPointIndex];
        if (dataPoint) {
          result[dataPointIndex][`date_${index}`] = parseISO(
            dataPoint.timeStamp
          ).getTime();
          result[dataPointIndex][`value_${index}`] = dataPoint.value;
        }
      }
    });

    return result;
  }, [
    isZoomLoading,
    state.isLoading,
    signalsToRequest,
    period?.key,
    period?.startDate,
    period?.endDate,
  ]);

  // Fetches new data.
  useEffect(() => {
    if (signalsToRequest.length === 0) return;
    setState({ isLoading: false, hasLoaded: false });
    for (const signal of signalsToRequest) {
      const periodFilters = getPeriodFilters(period, signal);
      prefetchTrendMeasuredDataInAPeriod({
        machineId: signal.machineId!,
        signalId: signal.id!,
        startDate: periodFilters.startDate,
        endDate: periodFilters.endDate,
        numberOfPoints: CHART_NUMBER_OF_POINTS,
        signalType: signal.dataType,
        refreshInterval: { key: "off", text: "Off" },
      });
    }
  }, [signalsToRequest, period?.key, period?.startDate, period?.endDate]);

  // Sets the independent data fetching state.
  useEffect(() => {
    if (!state.isLoading && signalsLoadingCount === 0) return;
    if (state.hasLoaded) return;

    if (signalsLoadingCount > 0) {
      setState({ isLoading: true, hasLoaded: false });
    } else {
      setState({ isLoading: false, hasLoaded: true });
    }
  }, [signalsLoadingCount]);

  // Zooms this chart according to the torque chart dates (if connect time axis is true).
  useEffect(() => {
    if (
      !ref?.current ||
      !trendChartDates?.start ||
      !trendChartDates?.end ||
      !isConnectedTimeAxis
    ) {
      return;
    }

    const xAxis = ref?.current?.chart.xAxes.values.at(
      0
    ) as am5xy.DateAxis<am5xy.AxisRenderer>;
    if (!xAxis) {
      return;
    }

    const start = xAxis.getPrivate("min");
    const end = xAxis.getPrivate("max");
    if (!start || !end) {
      return;
    }

    const startDate = new Date(start);
    const endDate = new Date(end);
    if (trendChartDates.start <= startDate || trendChartDates.end >= endDate) {
      xAxis.zoomToDates(startDate, endDate);
      return;
    }

    xAxis.zoomToDates(trendChartDates.start, trendChartDates.end);
  }, [trendChartDates.start, trendChartDates.end]);

  // Subscribes / unsubscribes to chart events
  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const xAxis = ref.current.chart.xAxes.values.at(
      0
    ) as am5xy.DateAxis<am5xy.AxisRenderer>;
    if (!xAxis) {
      return;
    }

    // Subscribes to events.
    const disposer = xAxis.on("end", onZoomEnd);

    // Unsubscribes from events
    return () => {
      disposer && !disposer.isDisposed() && disposer.dispose();
    };
  });

  // Handlers
  const onDataValidated = () => {
    const xAxis = ref?.current?.chart.xAxes.values.at(
      0
    ) as am5xy.DateAxis<am5xy.AxisRenderer>;
    if (!xAxis) {
      return;
    }

    const start = xAxis.getPrivate("min");
    const end = xAxis.getPrivate("max");

    if (!start || !end) {
      return;
    }

    const result: XAxisDates = {
      start: new Date(start),
      end: new Date(end),
    };

    updateDriveLoadStore({ torqueChartDates: result });
  };

  const onZoomEnd = () => {
    const xAxis = ref?.current?.chart.xAxes.values.at(
      0
    ) as am5xy.DateAxis<am5xy.AxisRenderer>;
    if (!xAxis) {
      return;
    }

    const start = xAxis.getPrivate("selectionMinFinal");
    const end = xAxis.getPrivate("selectionMaxFinal");

    if (!start || !end) {
      return;
    }

    const result: XAxisDates = { start: new Date(start), end: new Date(end) };
    torqueChartDates !== result &&
      updateDriveLoadStore({ torqueChartDates: result });
  };

  return (
    <Stack
      {...rest}
      horizontalAlign="stretch"
      verticalAlign="stretch"
      tokens={{ childrenGap: 5 }}
    >
      <TorqueHeader
        isDriveLoadLayout={isDriveLoadLayout}
        maximizeAction={maximizeAction}
        maximizeIcon={maximizeIcon}
        maximizeLabel={maximizeLabel}
      />
      <div className="torque-drive-load">
        <div className="white-container">
          {state.isLoading && (
            <Spin className="m-a" styles={{ root: { width: "100%" } }} />
          )}
          {!state.isLoading &&
            (signalsToRequest.length === 0 || data?.length === 0) && (
              <NoData className="m-a" />
            )}
          {!state.isLoading && data.length > 0 && (
            <TorquePlot
              ref={ref}
              data={data}
              signals={signalsToRequest}
              machine={machine}
              isZoomLoading={isZoomLoading}
              zoomLoadingStart={() => setIsZoomLoading(true)}
              zoomLoadingEnd={() => setIsZoomLoading(false)}
              selectedTableEvent={selectedTableEvent}
              isMaximized={maximizeLabel === "Minimize" ? true : false}
              onDataValidated={onDataValidated}
            />
          )}
        </div>
      </div>
    </Stack>
  );
};

export default TorqueChart;
