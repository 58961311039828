import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export interface ScrollbarStore {
  scrollbarX: any;
  scrollbarY: any;
  harmonics: any;
  setScrollbarX: (payload: any) => void;
  getScrollbarX: () => any;
  setScrollbarY: (payload: any) => void;
  getScrollbarY: () => any;
  getHarmonics: () => any;
  resetSpectrumStore: () => void;
  setHarmonics: (payload: any) => void;
}

const initialState = {
  harmonics: {
    harmonicsOne: {},
    harmonicsTwo: {},
    harmonicsThree: {},
  },
  scrollbarX: {
    start: 0,
    end: 1,
  },
  scrollbarY: {
    start: 0,
    end: 1,
  },
};

const useSpectrumStore = createWithEqualityFn<ScrollbarStore>((set, get) => ({
  ...initialState,
  getScrollbarX: () => {
    return get().scrollbarX;
  },
  setScrollbarX: (payload) => {
    set((state) => ({ scrollbarX: { ...state.scrollbarX, ...payload } }));
  },
  getScrollbarY: () => {
    return get().scrollbarY;
  },
  setScrollbarY: (payload) => {
    set((state) => ({ scrollbarY: { ...state.scrollbarY, ...payload } }));
  },
  getHarmonics: () => {
    return get().harmonics;
  },
  setHarmonics: (payload) => {
    set((state) => ({ harmonics: { ...state.harmonics, ...payload } }));
  },
  resetSpectrumStore: () => {
    set((state) => ({ ...state, ...initialState }));
  },
}), shallow);

export default useSpectrumStore;
