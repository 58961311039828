import { useEffect, useMemo, useState } from "react";
import { treeHandlers, useTreeState } from "react-hyper-tree";

import { SCROLL_OFFSET_TOP } from "../../../../config/constants";
import {
  useLocationSearch,
  useMachineList,
  useMachineCVBreadcrumb,
  useLastSelectedSignal,
  useLocationQueryParams,
} from "../../../../Hooks";

import { PAGE_TYPE_TREND } from "./TrendViewSelectSignals";
import { breadcrumbDefaultResponse } from "../../../common/services/api/MachineCVBreadcrumb";

import { findTreePath, getTreeData } from "./utils";

const useTrendViewSelectSignals = () => {
  const [locationData, , isValid] = useLocationSearch();
  const [sensorNodeId, setSensorNodeId]: any = useState();
  const urlSignalId = useLocationQueryParams("signal");
  const { getLastSelectedSignal } = useLastSelectedSignal({
    page: PAGE_TYPE_TREND,
  });
  const lastSelectedSignal = useMemo(() => getLastSelectedSignal(), []);
  const openedMachineId = lastSelectedSignal.machineId;
  const [openedMachineNode, setOpenedMachineNode]: any = useState();

  const {
    data: breadcrumbResponse = breadcrumbDefaultResponse,
    isLoading: isBreadcrumbLoading,
  } = useMachineCVBreadcrumb({
    machineId: locationData.id as string,
    options: { enabled: !!locationData.id, retry: 0 },
  });

  const { corporation, company, project, machine } = breadcrumbResponse;

  const { data: list, isLoading } = useMachineList();

  const data = useMemo(
    () =>
      list
        ? getTreeData(list, (sensors: any) => {
            if (locationData?.sensorNo && sensors?.length > 0) {
              const sensorId = (
                sensors.filter(
                  (item: any) => item.name === locationData.sensorNo
                )[0] || {}
              ).id;

              setSensorNodeId(sensorId);
            }

            return sensors;
          })
        : [],
    [list]
  );

  const defaultOpened = useMemo<string[] | undefined>(
    () =>
      isValid && data.length > 0
        ? findTreePath({
            data,
            ...locationData,
            corporation: corporation.name,
            company: company.name,
            project: project.name,
            machine: machine.name,
          })
        : undefined,
    [data, locationData, isValid]
  );

  const { required, handlers, instance } = useTreeState({
    id: "trend-view-signal-tree",
    multipleSelect: true,
    data,
    defaultOpened,
  });

  const defaultSelected = useMemo(() => {
    if (isLoading || !defaultOpened || isBreadcrumbLoading) {
      return null;
    }
    const machine = instance.getNodeById(defaultOpened[3]);
    if (!machine || machine.isLoading()) {
      return null;
    }
    return machine;
  }, [isLoading, defaultOpened, instance]);

  useEffect(() => {
    if (!defaultSelected?.asyncDataLoaded) {
      return;
    }
    let signalNodeId;
    if (urlSignalId) {
      const treeSignalNode = instance.getNodeById(urlSignalId);
      if (treeSignalNode) {
        signalNodeId = urlSignalId;
        treeSignalNode.getParent()?.setOpened(true);
        treeSignalNode.setOpened(true);
      }
    }
    const container =
      document.querySelector<HTMLDivElement>(".sidebar-scrollbar");
    if (!container) {
      return;
    }
    const node = container.querySelector(
      `[data-node-id="${signalNodeId || defaultSelected.id}"]`
    );
    if (!node) {
      return;
    }
    if (signalNodeId) {
      node.querySelector("button")?.click();
    }
    container.scrollTo({
      behavior: "smooth",
      top: node.getBoundingClientRect().top - SCROLL_OFFSET_TOP,
    });
  }, [defaultSelected?.asyncDataLoaded]);

  useEffect(() => {
    if (sensorNodeId) {
      const sensorNode = handlers.getNode(sensorNodeId);
      if (sensorNode) {
        handlers.setOpen(sensorNode);
      }
    }
  }, [defaultSelected?.asyncDataLoaded, sensorNodeId]);

  // if you navigate from raw data view page: open sidebar to the last selected signal from raw data
  // machine level
  useEffect(() => {
    if (openedMachineId && instance?.data?.length > 0) {
      const machineNode = instance.getNodeById(openedMachineId);

      if (machineNode) {
        treeHandlers.trees["trend-view-signal-tree"].handlers.setOpenByPath(
          machineNode.getPath()
        );
        setOpenedMachineNode(machineNode);
      }
    }
  }, [instance, openedMachineId]);

  // if you navigate from raw data view page: open sidebar to the last selected signal from raw data
  // sensor level
  useEffect(() => {
    if (openedMachineNode?.asyncDataLoaded) {
      const sensorNode = openedMachineNode.children.filter((child: any) => {
        return child.data.name === lastSelectedSignal.sensorNo;
      })[0];

      if (sensorNode) {
        sensorNode?.setOpened(true);
      }
    }
  }, [openedMachineNode, openedMachineNode?.asyncDataLoaded]);

  return {
    required,
    handlers,
    isLoading,
    hasData: !!data?.length,
  };
};

export default useTrendViewSelectSignals;
