import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  IconButton,
  PrimaryButton,
  DefaultButton,
  Stack,
  ContextualMenu,
  DialogFooter,
  DialogType,
  mergeStyles,
  mergeStyleSets,
  IDialogStyles,
} from "@fluentui/react";
import { useFileDownload } from "../../../Hooks/useFile";
import { PDFPreview } from "./PDFPreview";
import { Spin } from "../Spin";
import { NoData } from "../NoData";
import BaseDialog, { DialogSize } from "../Dialog";
import DownloadDialog from "../../../modules/common/components/Dialogs/DownloadDialog";
import { blobToBase64, FilePreviewProps, FileType } from ".";

// Styles for basic layout
const modalStyles: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 1)",
  display: "flex",
  flexDirection: "column",
};

const headerStyles = {
  padding: "16px 24px",
  backgroundColor: "white",
  borderBottom: "1px solid #edebe9",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const contentStyles: React.CSSProperties = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  position: "relative",
};

const footerStyles = {
  padding: "16px 24px",
  backgroundColor: "white",
  borderTop: "1px solid #edebe9",
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  gap: "8px",
};

// Styles for dialog layout
const customStyles = mergeStyleSets({
  dialogRoot: mergeStyles([
    {
      ".ms-Dialog-main": {
        maxWidth: "90vw",
        maxHeight: "90vh",
        width: "90vw",
        height: "90vh",
        resize: "both",
        overflow: "hidden",
      },
      ".ms-Modal-scrollableContent": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      ".ms-Dialog-inner": {
        height: "calc(100% - 70px)",
        display: "flex",
        flexDirection: "column",
      },
      ".ms-Dialog-content": {
        flex: "1 1 auto",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
      },
      ".ms-Dialog-footer": {
        flexShrink: 0,
        position: "relative",
        padding: "16px 24px",
        marginTop: "auto",
      },
    },
  ]),
});

const dialogStyles = (): Partial<IDialogStyles> => ({
  root: customStyles.dialogRoot,
});

interface FilePreviewModalProps {
  isOpen: boolean;
  id: string;
  name: string;
  fileType: FileType;
  onClose: () => void;
  useBasicLayout?: boolean;
}

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({
  isOpen,
  id,
  name,
  fileType,
  onClose,
  useBasicLayout = true,
}) => {
  const { downloadFile, getFile, showDownloadDialog } = useFileDownload();
  const [file, setFile] = useState<FilePreviewProps>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getFile({ id })
      .then((fileResponse) => {
        blobToBase64(fileResponse.data)
          .then((res) => {
            setFile({
              src: res as string,
              type:
                fileResponse.data.type === FileType.Pdf
                  ? FileType.Pdf
                  : FileType.Img,
            });
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setIsError(true);
          });
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  if (!isOpen) return null;

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <NoData />;
  }

  const renderContent = () => (
    <div style={useBasicLayout ? contentStyles : { height: "100%" }}>
      {isLoading ? (
        <div>Loading...</div>
      ) : fileType === FileType.Img && file ? (
        <TransformWrapper
          initialScale={1}
          minScale={0.5}
          maxScale={4}
          centerOnInit
          panning={{ activationKeys: [] }}
          doubleClick={{ mode: "reset" }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <Stack
                horizontal
                styles={{
                  root: {
                    position: "absolute",
                    top: "16px",
                    right: "16px",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    padding: "4px",
                    borderRadius: "4px",
                    zIndex: 1,
                  },
                }}
                tokens={{ childrenGap: 8 }}
              >
                <IconButton
                  iconProps={{ iconName: "Add" }}
                  onClick={() => zoomIn()}
                  title="Zoom in"
                />
                <IconButton
                  iconProps={{ iconName: "Remove" }}
                  onClick={() => zoomOut()}
                  title="Zoom out"
                />
                <IconButton
                  iconProps={{ iconName: "Refresh" }}
                  onClick={() => resetTransform()}
                  title="Reset"
                />
              </Stack>
              <TransformComponent
                wrapperStyle={{ width: "100%", height: "100%" }}
                contentStyle={{ width: "100%", height: "100%" }}
              >
                <img
                  src={file.src}
                  alt={name}
                  style={{
                    maxWidth: "90vw",
                    maxHeight: "calc(100vh - 120px)",
                    objectFit: "contain",
                  }}
                  draggable={false}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      ) : fileType === FileType.Pdf && file ? (
        <PDFPreview src={file.src} />
      ) : (
        <div>Error loading file</div>
      )}
    </div>
  );

  if (!useBasicLayout) {
    return (
      <>
        <BaseDialog
          hidden={!isOpen}
          onDismiss={onClose}
          dialogContentProps={{
            type: DialogType.close,
            title: name,
            closeButtonAriaLabel: "Close",
          }}
          modalProps={{
            styles: dialogStyles(),
            allowTouchBodyScroll: true,
            isBlocking: false,
            isDarkOverlay: false,
            dragOptions: {
              moveMenuItemText: "Move",
              closeMenuItemText: "Close",
              menu: ContextualMenu,
            },
          }}
          size={DialogSize.AUTO}
        >
          <div
            style={{
              flex: "1 1 auto",
              minHeight: 0,
              position: "relative",
              overflow: "hidden",
              height: "100%",
            }}
          >
            {renderContent()}
          </div>
          <DialogFooter>
            <PrimaryButton
              text="Download"
              onClick={() => downloadFile({ id, name })}
            />
            <DefaultButton text="Close" onClick={onClose} />
          </DialogFooter>
        </BaseDialog>
        <DownloadDialog hidden={!showDownloadDialog} />
      </>
    );
  }

  return createPortal(
    <div style={modalStyles}>
      <div style={headerStyles}>
        <h2 style={{ margin: 0 }}>{name}</h2>
        <IconButton
          iconProps={{ iconName: "Cancel" }}
          onClick={onClose}
          ariaLabel="Close modal"
        />
      </div>
      {renderContent()}
      <div style={footerStyles}>
        <DefaultButton text="Close" onClick={onClose} />
        <PrimaryButton
          text="Download"
          onClick={() => downloadFile({ id, name })}
        />
      </div>
    </div>,
    document.body
  );
};

export const useFilePreview = (useBasicLayout = true) => {
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState<{
    id: string;
    name: string;
    fileType: FileType;
  } | null>(null);

  const openPreview = useCallback(
    (id: string, name: string, fileType: FileType) => {
      setPreviewData({ id, name, fileType });
      setIsOpen(true);
    },
    []
  );

  const closePreview = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      setPreviewData(null);
    }, 300);
  }, []);

  const PreviewModal = useCallback(() => {
    if (!previewData) return null;

    return (
      <FilePreviewModal
        isOpen={isOpen}
        id={previewData.id}
        name={previewData.name}
        fileType={previewData.fileType}
        onClose={closePreview}
        useBasicLayout={useBasicLayout}
      />
    );
  }, [previewData, isOpen, closePreview, useBasicLayout]);

  return {
    openPreview,
    closePreview,
    PreviewModal,
  };
};
