import type { IDropdownOption } from "@fluentui/react";
import type { UseQueryResult } from "@tanstack/react-query";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { useEffect, useState } from "react";

import {
  useMachineList,
  prefetchTrendMeasuredDataInAPeriod,
} from "../../../Hooks";

import { apiService } from "../../common";
import useRefreshStore from "../../common/hooks/useRefreshStore";
import { getIntervalDuration } from "../../common/utils/getIntervalDuration";
import { queryClient } from "../../core";
import type {
  DataPoint,
  MachineList,
  ResponseSimplifiedSignal,
} from "../../../types";

import { CHART_NUMBER_OF_POINTS } from "../constants/controlOptions";
import type { GroupedSignalsPeriod } from "../utils/getPeriodFilters";
import getPeriodFilters from "../utils/getPeriodFilters";
import {
  findLocationTimezone,
  getTimeZoneMillisecondsOffset,
} from "../utils/getRangeFormat";
import useSelectedSignalsStore from "./useSelectedSignalsStore";

interface GroupedSignalMonitoringProps {
  signals: ResponseSimplifiedSignal[];
  machineId: string;
  period: GroupedSignalsPeriod;
  averageTrend: any;
}

const fetchTrendMeasuredData = async (
  machines: UseQueryResult<MachineList, unknown>,
  signal: ResponseSimplifiedSignal,
  period: GroupedSignalsPeriod,
  refreshInterval?: IDropdownOption<any>
) => {
  const currentTime = new Date();
  const month = currentTime.getMonth() + 1;
  const year = currentTime.getFullYear();
  const periodFilters = getPeriodFilters(period, signal);
  const project = machines?.data?.find(
    ({ id }) => signal.machineId === id
  )?.project;
  const projectTimezone = findLocationTimezone(project);
  const signalProjectTimezoneOffset =
    getTimeZoneMillisecondsOffset(projectTimezone);

  let signalPlot: DataPoint[] = [];

  try {
    if (refreshInterval?.key !== "off") {
      const response =
        await apiService.measuredDataRead.getTrendMeasuredDataInAMonthZip({
          signalId: signal.id ?? "",
          month,
          year,
        });

      signalPlot = response;
    } else {
      signalPlot = (await queryClient.getQueryData([
        "trend-measured-data-in-a-period",
        signal.machineId,
        signal.id,
        periodFilters.startDate,
        periodFilters.endDate,
        CHART_NUMBER_OF_POINTS.toString(),
        refreshInterval?.key,
      ])) as DataPoint[];
    }
  } catch (err) {
    console.error("Error fetching data:", err);
  }

  const signalPlotConvertedDateToTz = (signalPlot || []).map(
    (item: DataPoint) => ({
      ...item,
      timeStamp:
        parseISO(item.timeStamp).getTime() + signalProjectTimezoneOffset,
    })
  );

  return signalPlotConvertedDateToTz;
};

const useGroupedSignalMonitoring = ({
  signals,
  machineId,
  period,
  averageTrend,
}: GroupedSignalMonitoringProps) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [signalsToRequest, setSignalsToRequest] = useState<
    ResponseSimplifiedSignal[]
  >([]);
  const [data, setData] = useState<any[]>([]);

  const queryClient = useQueryClient();
  const machines = useMachineList();

  const queryKey =
    machineId === "all"
      ? ["trend-measured-data-in-a-period"]
      : ["trend-measured-data-in-a-period", machineId];

  const isFetchingSignalsData = useIsFetching({ queryKey });

  const { refreshInterval } = useRefreshStore(({ refreshInterval }) => ({
    refreshInterval,
  }));

  const { isLoadingMultipleSignals } = useSelectedSignalsStore((state) => ({
    isLoadingMultipleSignals: state.isLoadingMultipleSignals,
  }));

  useEffect(() => {
    if (!signals || signals.length === 0) {
      return;
    }

    const result = averageTrend
      ? signals.map((item) => item.p12h || { ...item, noAverageTrend: true })
      : signals;

    setSignalsToRequest(result);
  }, [averageTrend, signals]);

  useEffect(() => {
    if (
      isFetchingSignalsData ||
      isLoadingMultipleSignals ||
      signalsToRequest.length === 0
    )
      return;

    let mount = true;
    Promise.all(
      signalsToRequest.map((signal) =>
        fetchTrendMeasuredData(machines, signal, period, refreshInterval)
      )
    ).then((response) => {
      mount && setData(response);
    });

    // Clears all resources
    return () => {
      mount = false;
    };
  }, [
    isFetchingSignalsData,
    isLoadingMultipleSignals,
    signalsToRequest,
    period?.key,
    period?.startDate,
    period?.endDate,
    refreshInterval?.key,
    currentDateTime,
    queryClient,
  ]);

  useEffect(() => {
    Promise.all(
      signalsToRequest.map((signal) => {
        const periodFilters = getPeriodFilters(period, signal);
        return prefetchTrendMeasuredDataInAPeriod({
          machineId: signal.machineId!,
          signalId: signal.id!,
          startDate: periodFilters.startDate,
          endDate: periodFilters.endDate,
          numberOfPoints: CHART_NUMBER_OF_POINTS,
          signalType: signal.dataType,
          refreshInterval,
        });
      })
    );
  }, [
    signalsToRequest,
    period?.key,
    period?.startDate,
    period?.endDate,
    refreshInterval?.key,
  ]);

  /************************************************************************** */

  // Update currentDateTime based on the refresh interval
  useEffect(() => {
    const intervalDuration = getIntervalDuration(
      refreshInterval?.key as string
    );

    if (intervalDuration === 0) return;

    const nextIntervalTime =
      Math.ceil(currentDateTime.getTime() / intervalDuration) *
      intervalDuration;
    const timeoutDuration = nextIntervalTime - currentDateTime.getTime();

    const timeout = setTimeout(() => {
      setCurrentDateTime(new Date());
    }, timeoutDuration);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentDateTime, refreshInterval?.key]);

  return {
    isLoading: isFetchingSignalsData,
    data,
    signalsToRequest,
  };
};

export default useGroupedSignalMonitoring;
