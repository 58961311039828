import React from 'react';

import type { ResponseSimplifiedSignal } from '../../../types';

import useLayoutTypes from './useLayoutTypes';

type ConditionalWrapperProps = {
  selectedSignals: ResponseSimplifiedSignal[];
  marginTop: number;
};

const useConditionalWrapper = ({ selectedSignals, marginTop }: ConditionalWrapperProps) => {
  const { isCompareLayout } = useLayoutTypes();

  const isConditionalWrapperDiv = selectedSignals.length > 1 && isCompareLayout;

  const ConditionalWrapper = isConditionalWrapperDiv ? 'div' : React.Fragment;

  const conditionalWrapperStyles = isConditionalWrapperDiv && {
    style: {
      height: `calc(100vh - 62px - ${marginTop}px)`,
      width: '100%',
      display: 'flex',
      overflowY: 'auto',
    } as React.CSSProperties,
  };

  return { ConditionalWrapper, conditionalWrapperStyles };
};

export default useConditionalWrapper;
