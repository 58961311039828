import { sortBy } from "lodash-es";

import { getApiClient } from "../../../core/apiClient/useApiStore";
// TODO: add filter as common method
import { filter } from "../../../../Components/MachineCV/MachineCVHomePage/hooks/filter";
import type {
  DashboardsOverview,
  HomeOverview,
  MachineCvOverview,
  MachineCvOverviewCompany,
  MachineCvOverviewList,
  MachineCvOverviewProject,
  ResponseMachineCvOverviewList,
} from "../../../../types";

// Internal Types (use Map instead of Array)

type MachineCvOverviewCompanyMap = Omit<
  MachineCvOverviewCompany,
  "projects"
> & {
  projects: Map<string, MachineCvOverviewProject>;
};

type MachineCvOverviewMap = Omit<MachineCvOverview, "companies"> & {
  companies: Map<string, MachineCvOverviewCompanyMap>;
};

const mapResponse = (
  response: ResponseMachineCvOverviewList
): MachineCvOverviewList => {
  const mapped = new Map<string, MachineCvOverviewMap>();

  for (let idx = 0; idx < response.length; idx += 1) {
    const { corporation, company, projects } = response[idx];

    const hasCorporation = mapped.has(corporation.id as string);

    if (!hasCorporation) {
      mapped.set(corporation.id as string, {
        ...corporation,
        companies: new Map(),
      });
    }

    const currentCorporation = mapped.get(
      corporation.id as string
    ) as MachineCvOverviewMap;

    const hasCompany = currentCorporation.companies.has(company.id as string);

    if (!hasCompany) {
      currentCorporation.companies.set(company.id as string, {
        ...company,
        projects: new Map(),
      });
    }

    const currentCompany = currentCorporation.companies.get(
      company.id as string
    ) as MachineCvOverviewCompanyMap;

    for (let projectId = 0; projectId < projects.length; projectId += 1) {
      const hasProject = currentCompany.projects.has(
        projects[projectId].metaData.id as string
      );

      if (!hasProject) {
        currentCompany.projects.set(projects[projectId].metaData.id as string, {
          ...projects[projectId],
        });
      }
    }
  }

  return sortBy([...mapped.values()], ({ name }) => name.toLowerCase()).map(
    ({ companies, ...corporation }) => ({
      ...corporation,
      companies: sortBy([...companies.values()], ({ name }) =>
        name.toLowerCase()
      ).map(({ projects, ...company }) => ({
        ...company,
        projects: sortBy([...projects.values()], ({ metaData }) =>
          metaData.name.toLowerCase()
        ).map(({ machines, ...project }) => ({
          ...project,
          machines: sortBy(machines, [
            ({ metaData }) => metaData?.name?.toLowerCase(),
            ({ metaData }) => metaData?.customerCode?.toLowerCase(),
          ]),
        })),
      })),
    })
  );
};

export const getMachineCvOverviewList = () =>
  getApiClient()
    .get<ResponseMachineCvOverviewList>("/meta/machinecv/v1/overview")
    .then(({ data }) =>
      filter(mapResponse(data), {
        search: "",
        condition: "All",
        searchBy: "machine",
        status: "All",
        filter: "all",
      })
    );

export const getHomeOverview = () =>
  getApiClient()
    .get<HomeOverview>("/meta/machinecv/v1/home")
    .then(({ data }) => data);

export const getDashboardsOverview = () =>
  getApiClient()
    .get<DashboardsOverview>("/meta/machinecv/v1/dashboards")
    .then(({ data }) => data);
