import { useSessionStorage } from 'react-use';

type User = {
  memberId: string;
  displayName: string;
};

const useCurrentUser = (): User => {
  const [user] = useSessionStorage<User>('accountInfo', { memberId: '', displayName: '' });

  return user;
};

export default useCurrentUser;
