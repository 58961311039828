// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ts from '@mapbox/timespace';
import cityTimezones from 'city-timezones';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { Project } from '../../../types';

dayjs.extend(utc);
dayjs.extend(timezone);

const safeFormat = (value: any) => {
  try {
    return format(value, 'dd MMM yyyy HH:mm');
  } catch {
    return '-';
  }
};

export const getRangeFormat = (start: number, end: number) => {
  return `${safeFormat(start)} — ${safeFormat(end)}`;
};

export const findLocationTimezone = (project?: Project) => {
  let result: string | null = null;
  if (project?.city) {
    const tz = cityTimezones.lookupViaCity(project?.city) || [];
    if (tz[0]) {
      result = tz[0].timezone || null;
    }
  }

  if (project?.longitude && project?.latitude) {
    const tz = ts.getFuzzyLocalTimeFromPoint(new Date(), [project.longitude, project.latitude]);
    result = tz?._z?.name || result;
  }

  return result;
};

export const inDayjsFormat = 'YYYY-MM-DDTHH:mm:ss.SSSSSSS';
export const outDayjsFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const convertToTzDate = (date: any, location?: any, tz?: any) => {
  const utcDate = dayjs.utc(date, inDayjsFormat);
  if (tz) {
    return utcDate.tz(tz).format(outDayjsFormat);
  }

  const locationTz = findLocationTimezone(location);

  if (locationTz) {
    return utcDate.tz(locationTz).format(outDayjsFormat);
  }

  return utcDate.format(outDayjsFormat);
};

export const getTimeZoneMillisecondsOffset = (timeZone: any) => {
  if (!timeZone) return 0;
  const date = new Date();
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
  const offset = utcDate.getTime() - tzDate.getTime();
  return offset * -1;
};
