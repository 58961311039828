import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  Checkbox,
  SpinButton,
  Stack,
  ISpinButtonStyles,
} from "@fluentui/react";

import BaseDialog, { DialogSize } from "../../../common/Dialog";
import { editConfigurationUpdate, UpdateConfiguration } from "./api";

type EditConditionProps = IDialogProps & {
  machineId: string;
  data: UpdateConfiguration;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

const spinButtonStyles: Partial<ISpinButtonStyles> = {
  root: { width: 250 },
};

export const ConfigurationEditor: React.FC<EditConditionProps> = ({
  machineId,
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [maxDataAgeInDaysError, setMaxDataAgeInDaysError] = useState<number>(
    data.maxDataAgeInDaysError
  );
  const [maxDataAgeInDaysWarning, setMaxDataAgeInDaysWarning] =
    useState<number>(data.maxDataAgeInDaysWarning);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setIsActive(data.isActive);
    setMaxDataAgeInDaysError(data.maxDataAgeInDaysError);
    setMaxDataAgeInDaysWarning(data.maxDataAgeInDaysWarning);
  }, [data]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await editConfigurationUpdate(machineId, {
        id: data.id,
        isActive,
        maxDataAgeInDaysError,
        maxDataAgeInDaysWarning,
      });
      onSuccess(!("status" in response));
    } catch (error) {
      console.error("Error for Update Configuration:", error);
      setErrorMessage("Failed to update configuration. Please try again.");
      onSuccess(false);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }, [
    machineId,
    data.id,
    isActive,
    maxDataAgeInDaysError,
    maxDataAgeInDaysWarning,
    onSuccess,
    handleClose,
  ]);

  const isSubmitDisabled = useMemo(() => {
    return (
      isLoading ||
      (isActive === data.isActive &&
        maxDataAgeInDaysError === data.maxDataAgeInDaysError &&
        maxDataAgeInDaysWarning === data.maxDataAgeInDaysWarning)
    );
  }, [
    isLoading,
    isActive,
    data.isActive,
    maxDataAgeInDaysError,
    data.maxDataAgeInDaysError,
    maxDataAgeInDaysWarning,
    data.maxDataAgeInDaysWarning,
  ]);

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Edit configuration",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
      size={DialogSize.AUTO}
    >
      <Stack horizontalAlign="center" verticalAlign="center">
        <Checkbox
          label="Is Active"
          checked={isActive}
          onChange={(_, checked) => setIsActive(checked ?? false)}
        />
        <SpinButton
          label="Max Data Age In Days Warning"
          value={maxDataAgeInDaysWarning.toString()}
          onChange={(_, newValue) =>
            setMaxDataAgeInDaysWarning(Number(newValue))
          }
          min={1}
          styles={spinButtonStyles}
        />
        <SpinButton
          label="Max Data Age In Days Error"
          value={maxDataAgeInDaysError.toString()}
          onChange={(_, newValue) => setMaxDataAgeInDaysError(Number(newValue))}
          min={1}
          styles={spinButtonStyles}
        />
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={handleSubmit}
          text="Save Changes"
          disabled={isSubmitDisabled}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton
          styles={{ root: { border: "unset", background: "transparent" } }}
          text="Cancel"
          onClick={handleClose}
        />
      </DialogFooter>
    </BaseDialog>
  );
};
