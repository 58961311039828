import { DefaultButton, Icon, PrimaryButton } from "@fluentui/react";
import type { CSSProperties } from "react";
import React, { useEffect, useState } from "react";

import { NoData } from "../../../../Components/common/NoData";

export type SizeProps = {
  size: number;
  increment: number;
};

type ShowMoreProps<T> = {
  data: T[];
  sizeProps?: SizeProps;
  renderComponent: (data: T[]) => React.ReactElement;
};

const buttonsContainerStyle: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  margin: "16px auto",
};

export const ShowMoreGrid = <T,>({
  data,
  sizeProps = { size: 6, increment: 3 },
  renderComponent,
}: ShowMoreProps<T>) => {
  const { size, increment } = sizeProps;
  const [listSize, setListSize] = useState(size);

  // Updates the list size
  useEffect(() => {
    setListSize(size);
  }, [size]);

  return (
    <>
      {renderComponent(data.slice(0, listSize))}
      <div style={buttonsContainerStyle}>
        {listSize < data.length && (
          <PrimaryButton
            text="Show more"
            styles={{
              root: { minWidth: "initial", marginRight: "16px" },
              label: { marginRight: "16px" },
            }}
            onClick={() =>
              setListSize(Math.min(listSize + increment, data.length))
            }
          >
            <Icon iconName="ChevronDown" />
          </PrimaryButton>
        )}
        {listSize > size && data.length > size && (
          <DefaultButton
            styles={{
              root: { border: "unset", background: "#fff" },
              label: { marginRight: "16px" },
            }}
            text="Hide"
            onClick={() => setListSize(size)}
          >
            <Icon iconName="ChevronUp" />
          </DefaultButton>
        )}
      </div>

      {data.length === 0 && (
        <NoData
          styles={{
            root: {
              marginBottom: 16,
              marginTop: 16,
            },
          }}
        />
      )}
    </>
  );
};
