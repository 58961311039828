import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'

import { getDefaultPerPage } from './helpers';

export interface TableStore {
  tablePerPage: any;
  setTablePerPage: (value: any) => any;
}
const useTableStore = createWithEqualityFn<TableStore>((set) => ({
  tablePerPage: getDefaultPerPage(),
  setTablePerPage: (value) => {
    set(() => ({ tablePerPage: value }));
  },
}), shallow);

export default useTableStore;
