export const setMaxNumberDecimals = (value: any, max: any) => {
  if (!max) return value;
  let decimalsCount = 0;
  let decimals = '';
  let result = value;
  if (Math.floor(value) !== value) {
    decimals = value.toString().split('.')[1];
    decimalsCount = decimals?.length || 0;
  }

  if (decimalsCount && decimalsCount > max) {
    result = parseFloat(`${Math.floor(value)}.${decimals.slice(0, max)}`);
  }

  return result;
};

export const getTicks = (start = 0, end = 5, segments = 5) => {
  const interval = (end - start) / (segments + 1);
  const equalDistances: any = [];

  for (let i = 1; i <= segments; i++) {
    const numberToAdd = setMaxNumberDecimals(start + interval * i, 3);
    if (
      ![...equalDistances].includes(numberToAdd) &&
      numberToAdd !== setMaxNumberDecimals(start, 3) &&
      numberToAdd !== setMaxNumberDecimals(end, 3)
    ) {
      equalDistances.push(numberToAdd);
    }
  }

  return equalDistances;
};

export const generateBackgroundGradient = (colorScale: any) => {
  if (!colorScale) {
    return {};
  }
  let result = '';
  colorScale.forEach((item: any, idx: any) => {
    const percent = `${item[0] * 100}%`;
    const color = item[1];
    const isLast = colorScale.length - 1 === idx;
    result = result
      ? `${result} ${color} ${percent}${!isLast ? ',' : ''}`
      : `${color} ${percent}${!isLast ? ',' : ''}`;
  });
  return { background: `linear-gradient(0deg, ${result})` };
};
