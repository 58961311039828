import type { SVGProps } from 'react';
import React from 'react';

const ArrowLeftIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='13'
      viewBox='0 0 16 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.837 0.63C8.73887 0.540622 8.60925 0.493887 8.47667 0.500076C8.34408 0.506264 8.21938 0.56487 8.13 0.663C8.04062 0.76113 7.99389 0.890746 8.00008 1.02333C8.00626 1.15592 8.06487 1.28062 8.163 1.37L14.33 7H0.5C0.367392 7 0.240215 7.05268 0.146447 7.14645C0.0526785 7.24022 0 7.36739 0 7.5C0 7.63261 0.0526785 7.75979 0.146447 7.85355C0.240215 7.94732 0.367392 8 0.5 8H14.328L8.163 13.628C8.065 13.7174 8.00653 13.842 8.00043 13.9745C7.99434 14.107 8.04112 14.2365 8.1305 14.3345C8.21988 14.4325 8.34453 14.491 8.47702 14.4971C8.60952 14.5032 8.739 14.4564 8.837 14.367L15.753 8.053C15.8304 7.98297 15.8923 7.89748 15.9347 7.80206C15.977 7.70664 15.9989 7.6034 15.9989 7.499C15.9989 7.3946 15.977 7.29136 15.9347 7.19594C15.8923 7.10052 15.8304 7.01503 15.753 6.945L8.837 0.63Z'
        fill='#0078D4'
      />
    </svg>
  );
};

export default ArrowLeftIcon;
