import * as React from 'react';

const AddTaskIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.5 19a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm.029-7.063a.5.5 0 0 0-.5.5v1.5h-1.5a.5.5 0 0 0 0 1h1.5v1.5a.5.5 0 1 0 1 0v-1.5h1.5a.5.5 0 1 0 0-1h-1.5v-1.5a.5.5 0 0 0-.5-.5Z'
      fill='#2C529F'
    />
    <path
      d='M15.657 4.343a8 8 0 0 0-13.64 6.174c.3-.262.63-.492.985-.682a7 7 0 1 1 7.163 7.163c-.19.355-.42.685-.682.985a8 8 0 0 0 6.174-13.64Z'
      fill='#2C529F'
    />
    <path
      d='M9.82 5.116A.5.5 0 0 0 9 5.5v5l.008.09A.5.5 0 0 0 9.5 11h3l.09-.008A.5.5 0 0 0 12.5 10H10V5.5l-.008-.09a.5.5 0 0 0-.172-.294Z'
      fill='#2C529F'
    />
  </svg>
);

export default AddTaskIcon;
