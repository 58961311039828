import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { persist } from 'zustand/middleware';

export interface InfoStore {
  perRow: number;
  search: string;
  dispatch: (action: Actions) => void;
}

type Actions = { type: 'SET_PER_ROW'; payload: number } | { type: 'SET_SEARCH'; payload: string };

const reducer = (state: InfoStore, action: Actions): InfoStore => {
  switch (action.type) {
    case 'SET_PER_ROW': {
      return {
        ...state,
        perRow: action.payload,
      };
    }
    case 'SET_SEARCH': {
      // TODO: Move search to pageState (local state)
      return {
        ...state,
        search: action.payload,
      };
    }
  }
};

const useInfoStore = createWithEqualityFn<InfoStore>()(
  persist(
    (set) => ({
      perRow: 0,
      search: '',
      dispatch: (action) => set((state) => reducer(state, action)),
    }),
    {
      name: 'machine-cv-info-layout',
      partialize(state) {
        return state.perRow;
      },
      merge(persistedState, currentState) {
        const perRow = (persistedState ?? 0) as number;

        return {
          ...currentState,
          perRow,
        };
      },
    },
  ), shallow
);

export default useInfoStore;
