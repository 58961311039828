import type { IDialogProps } from "@fluentui/react";
import { DefaultButton, DialogFooter, DialogType } from "@fluentui/react";

import BaseDialog, { DialogSize } from "../../../../Components/common/Dialog";

type ErrorDialogProps = IDialogProps & {
  content: string;
  onDismiss: () => void;
};

export const ErrorDialog = ({
  content,
  onDismiss,
  ...rest
}: ErrorDialogProps) => (
  <BaseDialog
    {...rest}
    dialogContentProps={{ title: "Attention!", type: DialogType.close }}
    size={DialogSize.M}
    onDismiss={onDismiss}
  >
    <span dangerouslySetInnerHTML={{ __html: content }} />
    <DialogFooter>
      <DefaultButton text="Close" onClick={onDismiss} />
    </DialogFooter>
  </BaseDialog>
);
