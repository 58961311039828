import { Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import type { PropsWithChildren } from "react";

import { format } from "../../../common/utils/signalFormatter";
import type { ResponseSimplifiedSignal } from "../../../../types";

const PlotContainer = ({ children }: PropsWithChildren) => (
  <Stack
    verticalAlign="center"
    horizontalAlign="center"
    style={{
      height: 511,
      background: "#FFF",
      borderRadius: 2,
    }}
  >
    {children}
  </Stack>
);

export const PlotSpinner = () => (
  <PlotContainer>
    <Spinner size={SpinnerSize.large} label="Loading chart..." />
    <Text style={{ fontSize: 10, display: "block" }}>
      loading time depends on the amount of data
    </Text>
  </PlotContainer>
);

export const RawDataPlotNoData = ({
  signal,
}: {
  signal: ResponseSimplifiedSignal;
}) => (
  <PlotContainer>
    <span>
      <b>{format(signal)}</b> has no data
    </span>
  </PlotContainer>
);
