import type { SVGProps } from 'react';
import React from 'react';

const MaximizeIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='13'
      viewBox='0 0 16 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H4C4.13261 0 4.25979 0.0526785 4.35355 0.146447C4.44732 0.240215 4.5 0.367392 4.5 0.5C4.5 0.632608 4.44732 0.759785 4.35355 0.853553C4.25979 0.947322 4.13261 1 4 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V4C1 4.13261 0.947322 4.25979 0.853553 4.35355C0.759785 4.44732 0.632608 4.5 0.5 4.5C0.367392 4.5 0.240215 4.44732 0.146447 4.35355C0.0526785 4.25979 0 4.13261 0 4V2ZM9.5 0.5C9.5 0.367392 9.55268 0.240215 9.64645 0.146447C9.74022 0.0526785 9.86739 0 10 0H12C12.5304 0 13.0391 0.210714 13.4142 0.585786C13.7893 0.960859 14 1.46957 14 2V4C14 4.13261 13.9473 4.25979 13.8536 4.35355C13.7598 4.44732 13.6326 4.5 13.5 4.5C13.3674 4.5 13.2402 4.44732 13.1464 4.35355C13.0527 4.25979 13 4.13261 13 4V2C13 1.73478 12.8946 1.48043 12.7071 1.29289C12.5196 1.10536 12.2652 1 12 1H10C9.86739 1 9.74022 0.947322 9.64645 0.853553C9.55268 0.759785 9.5 0.632608 9.5 0.5ZM0.5 9.5C0.632608 9.5 0.759785 9.55268 0.853553 9.64645C0.947322 9.74022 1 9.86739 1 10V12C1 12.2652 1.10536 12.5196 1.29289 12.7071C1.48043 12.8946 1.73478 13 2 13H4C4.13261 13 4.25979 13.0527 4.35355 13.1464C4.44732 13.2402 4.5 13.3674 4.5 13.5C4.5 13.6326 4.44732 13.7598 4.35355 13.8536C4.25979 13.9473 4.13261 14 4 14H2C1.46957 14 0.960859 13.7893 0.585786 13.4142C0.210714 13.0391 0 12.5304 0 12V10C0 9.86739 0.0526785 9.74022 0.146447 9.64645C0.240215 9.55268 0.367392 9.5 0.5 9.5ZM13.5 9.5C13.6326 9.5 13.7598 9.55268 13.8536 9.64645C13.9473 9.74022 14 9.86739 14 10V12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14H10C9.86739 14 9.74022 13.9473 9.64645 13.8536C9.55268 13.7598 9.5 13.6326 9.5 13.5C9.5 13.3674 9.55268 13.2402 9.64645 13.1464C9.74022 13.0527 9.86739 13 10 13H12C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12V10C13 9.86739 13.0527 9.74022 13.1464 9.64645C13.2402 9.55268 13.3674 9.5 13.5 9.5Z'
        fill='#0078D4'
      />
    </svg>
  );
};

export default MaximizeIcon;
