import type { AxiosInstance } from "axios";

import AppLayout from "../../../templates/AppLayout";

import Page from "./Page";

interface HomeProps {
  axiosInstance: AxiosInstance;
}

const Home = ({ axiosInstance }: HomeProps) => (
  <AppLayout axiosInstance={axiosInstance}>
    <Page />
  </AppLayout>
);

export default Home;
