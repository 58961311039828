import { each, groupBy, sortBy, uniqueId } from "lodash-es";
import orderBy from "lodash-es/orderBy";
import type { TreeNode } from "react-hyper-tree/dist/helpers/node";

import type { LocationSearchData } from "../../../../Hooks";
import { apiService } from "../../../common";
import { queryClient } from "../../../core";
import type { MachineList } from "../../../../types";

export const getSignalsByMachineId = async (
  machineId: string,
  transformer = (data: any) => data
) => {
  const signalsList = await apiService.metaDataRead.getSignalsList(machineId);

  const previousExpandedMachinesSignalsList =
    (queryClient.getQueryData(["signals-list"]) as TreeNode[]) || [];

  const nextExpandedMachinesSignalsList = [
    ...previousExpandedMachinesSignalsList,
    ...signalsList.map((item) => ({
      ...item,
      id: item.id,
      name: item.name,
    })),
  ];

  queryClient.setQueryData(["signals-list"], nextExpandedMachinesSignalsList);

  const groupedSignals = groupBy(
    signalsList,
    (item: any) => item.sensorNo || "No sensor"
  );

  let orderedGroupedSignals: any = [];
  each(groupedSignals, (group, groupName) => {
    if (groupName) {
      orderedGroupedSignals.push({
        groupedNodeParent: true,
        id: uniqueId("_grouped_signals_id_"),
        name: groupName,
        children: [...group],
      });
    } else {
      orderedGroupedSignals = [...orderedGroupedSignals, ...group];
    }
  });

  return transformer(
    orderBy(orderedGroupedSignals, (item) => {
      if (!isNaN(item.name)) {
        // Convert numeric values to integers
        return parseInt(item.name);
      } else {
        // Assign a high value to non-numeric values
        return item.name;
      }
    }).map((item: any) => ({
      ...item,
      id: item.id,
      name: item.name,
    }))
  );
};

type InternalTreeNodeData = {
  id: string;
  name: string;
  children: Map<string, InternalTreeNodeData>;
};

export const getTreeData = (machines: MachineList, signalsTransformer: any) => {
  const tree = new Map<string, InternalTreeNodeData>();

  for (let idx = 0; idx < machines.length; idx += 1) {
    const { corporation, company, project, id, name } = machines[idx];

    const hasCorporation = tree.has(corporation.id as string);

    if (!hasCorporation) {
      tree.set(corporation.id as string, {
        id: corporation.id as string,
        name: corporation.name,
        children: new Map<string, InternalTreeNodeData>(),
      });
    }

    const currentCorporation = tree.get(
      corporation.id as string
    ) as InternalTreeNodeData;

    const hasCompany = currentCorporation.children.has(company.id as string);

    if (!hasCompany) {
      currentCorporation.children.set(company.id as string, {
        id: company.id as string,
        name: company.name,
        children: new Map(),
      });
    }

    const currentCompany = currentCorporation.children.get(
      company.id as string
    ) as InternalTreeNodeData;

    const hasProject = currentCompany.children.has(project.id as string);

    if (!hasProject) {
      currentCompany.children.set(project.id as string, {
        id: project.id as string,
        name: project.name,
        children: new Map(),
      });
    }

    const currentProject = currentCompany.children.get(
      project.id as string
    ) as InternalTreeNodeData;

    currentProject.children.set(id, {
      id,
      name: name as string,
    } as InternalTreeNodeData);
  }

  return sortBy(
    // corporations
    [...tree.values()].map(({ id, name, children }) => ({
      id,
      name,
      // companies
      children: sortBy(
        [...children.values()].map(({ id, name, children }) => ({
          id,
          name,
          // projects
          children: sortBy(
            [...children.values()].map(({ id, name, children }) => ({
              id,
              name,
              // machines
              children: sortBy(
                [...children.values()].map((machine) => ({
                  ...machine,
                  getChildren: () =>
                    getSignalsByMachineId(
                      machine.id as string,
                      signalsTransformer
                    ),
                })),
                ({ name }) => name?.toLowerCase()
              ),
            })),
            ({ name }) => name?.toLowerCase()
          ),
        })),
        ({ name }) => name?.toLowerCase()
      ),
    })),
    ({ name }) => name?.toLowerCase()
  );
};

type FindTreePathOpts = LocationSearchData & {
  data: ReturnType<typeof getTreeData>;
};

export const findTreePath = ({
  data,
  corporation,
  company,
  project,
  id,
}: FindTreePathOpts) => {
  const currentCorporation = data.find(({ name }) => name === corporation);

  if (!currentCorporation) {
    return undefined;
  }

  const currentCompany = currentCorporation.children.find(
    ({ name }) => name === company
  );

  if (!currentCompany) {
    return undefined;
  }

  const currentProject = currentCompany.children.find(
    ({ name }) => name === project
  );

  if (!currentProject) {
    return undefined;
  }

  return [
    currentCorporation.id,
    currentCompany.id,
    currentProject.id,
    id as string,
  ];
};
