import { axiosInstance } from "../..";
import { default as MachineCVHome } from "./MachineCVHomePage";
import { default as MachineCVDriveLoad } from "./MachineCVDriveLoadPage";
import { default as MachineCVPerformance } from "./MachineCVPerformancePage";
import { default as MachineCVSensors } from "./MachineCVSensorsPage";
import { default as MachineCVTasks } from "./MachineCVTasksPage";
import { default as MachineCVComments } from "./MachineCVCommentsPage";
import { default as MachineCVInfo } from "./MachineCVInfoPage";
import { default as MachineCVDocuments } from "./MachineCVDocumentsPage";
import { default as MachineCVThreads } from "./MachineCVThreadsPage";
import { default as MachineCVReports } from "./MachineCVReportsPage";
import { default as MachineCVSummary } from "./MachineCVSummaryPage";

export const Home = () => <MachineCVHome axiosInstance={axiosInstance} />;

export const DriveLoad = () => (
  <MachineCVDriveLoad axiosInstance={axiosInstance} />
);

export const Performance = () => (
  <MachineCVPerformance axiosInstance={axiosInstance} />
);

export const Sensors = () => <MachineCVSensors axiosInstance={axiosInstance} />;

export const Tasks = () => <MachineCVTasks axiosInstance={axiosInstance} />;

export const Comments = () => (
  <MachineCVComments axiosInstance={axiosInstance} />
);

export const Info = () => <MachineCVInfo axiosInstance={axiosInstance} />;

export const Documents = () => (
  <MachineCVDocuments axiosInstance={axiosInstance} />
);

export const Threads = () => <MachineCVThreads axiosInstance={axiosInstance} />;

export const Reports = () => <MachineCVReports axiosInstance={axiosInstance} />;

export const Summary = () => <MachineCVSummary axiosInstance={axiosInstance} />;
