import type { IButtonStyles } from '@fluentui/react';
import { FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';

const theme = getTheme();

export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: '100%',
  },
  header: [
    theme.fonts.xLarge,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    height: '90%',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
export const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const headerTitle = {
  fontWeight: 600,
  marginLeft: 10,
  display: 'inline-flex',
  alignItems: 'center',
};

export const headerTitleText = {
  wrapper: { height: 25, marginLeft: 10 },
};

export const headerContainer = {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 250px',
  marginBottom: 20,
};

export const maximizeButton = {
  root: {
    justifySelf: 'flex-end',
    marginRight: 20,
  },
  flexContainer: {
    display: 'grid',
    gridTemplateColumns: '20px 1fr',
  },
};

export const maximizeButtonIcon = {
  width: 15,
  height: 15,
  marginRight: 10,
};

export const buttonsContainer = {
  alignItems: 'center',
  justifyContent: 'end',
};

export const timestampText = {
  whiteSpace: 'nowrap' as const,
  marginRight: 20,
};

export const exportElement = {
  marginRight: 10,
};

export const commandBarStyles = { height: 44, marginLeft: 10 };
