import type { SVGProps } from 'react';
import React from 'react';

const AlertsIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='18'
      height='15'
      viewBox='0 0 18 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.56191 1.26201C8.60483 1.18288 8.66832 1.1168 8.74568 1.07076C8.82304 1.02472 8.91139 1.00042 9.00141 1.00042C9.09143 1.00042 9.17978 1.02472 9.25714 1.07076C9.33449 1.1168 9.39799 1.18288 9.44091 1.26201L15.9409 13.262C15.9824 13.3382 16.0033 13.4239 16.0016 13.5107C16 13.5974 15.9757 13.6822 15.9313 13.7568C15.887 13.8314 15.8239 13.8931 15.7485 13.9359C15.673 13.9787 15.5877 14.0011 15.5009 14.001H2.49991C2.41314 14.0011 2.32783 13.9787 2.25236 13.9359C2.17689 13.8931 2.11386 13.8314 2.06947 13.7568C2.02508 13.6822 2.00086 13.5974 1.99918 13.5107C1.99751 13.4239 2.01843 13.3382 2.05991 13.262L8.56291 1.26201H8.56191ZM10.3199 0.785006C9.75291 -0.261994 8.24991 -0.261994 7.68191 0.785006L1.17991 12.786C1.05597 13.0146 0.99357 13.2715 0.998844 13.5314C1.00412 13.7914 1.07688 14.0455 1.20999 14.2689C1.3431 14.4923 1.53199 14.6772 1.75813 14.8055C1.98427 14.9339 2.23989 15.0012 2.49991 15.001H15.5019C15.7618 15.0011 16.0173 14.9336 16.2433 14.8052C16.4693 14.6767 16.6581 14.4918 16.7911 14.2685C16.9241 14.0451 16.9967 13.7911 17.002 13.5312C17.0072 13.2713 16.9448 13.0145 16.8209 12.786L10.3209 0.786006L10.3199 0.785006ZM9.49991 5.50001C9.49991 5.3674 9.44723 5.24022 9.35346 5.14645C9.2597 5.05268 9.13252 5.00001 8.99991 5.00001C8.8673 5.00001 8.74012 5.05268 8.64636 5.14645C8.55259 5.24022 8.49991 5.3674 8.49991 5.50001V9.50001C8.49991 9.63262 8.55259 9.75979 8.64636 9.85356C8.74012 9.94733 8.8673 10 8.99991 10C9.13252 10 9.2597 9.94733 9.35346 9.85356C9.44723 9.75979 9.49991 9.63262 9.49991 9.50001V5.50001ZM9.74991 11.75C9.74991 11.9489 9.67089 12.1397 9.53024 12.2803C9.38959 12.421 9.19882 12.5 8.99991 12.5C8.801 12.5 8.61023 12.421 8.46958 12.2803C8.32893 12.1397 8.24991 11.9489 8.24991 11.75C8.24991 11.5511 8.32893 11.3603 8.46958 11.2197C8.61023 11.079 8.801 11 8.99991 11C9.19882 11 9.38959 11.079 9.53024 11.2197C9.67089 11.3603 9.74991 11.5511 9.74991 11.75Z'
        fill='#3B3A39'
      />
    </svg>
  );
};

export default AlertsIcon;
