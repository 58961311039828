import { Stack } from "@fluentui/react";
import { createContext, useState } from "react";

import { useFileUpload, useLocationSearch } from "../../../Hooks";

import AddDocumentDialog from "../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import { SummaryBadge } from "./SummaryBadge";
import { TaskAddDialog } from "../MachineCVTasksPage/components/TaskAdd";
import type { UploadedFile } from "../../common/Uploader/types";

import { ConditionsSection } from "./ConditionsSection";
import useConditionsOverview from "./ConditionsSection/hooks";
import Overview from "./ConditionsSection/Overview";
import { DetailsSection } from "./DetailsSection";
import { DrawingSection } from "./DrawingSection";
import { SignalsSection } from "./SignalsSection";

export const HideTopSectionContext = createContext(false);

const Page = () => {
  const [{ id }, ,] = useLocationSearch();
  const [addTask, setAddTask] = useState(false);
  const [addDocument, setAddDocument] = useState(false);

  const hideDrawingsAndConditions = JSON.parse(
    localStorage.getItem("hideDrawingsAndConditions") || "false"
  );

  const [hideTopSection, setHideTopSection] = useState(
    hideDrawingsAndConditions
  );

  const { data: condition } = useConditionsOverview(
    { machineId: id as string },
    { cacheTime: 0 }
  );

  const { uploadFilesAsyncWithNotifications } = useFileUpload();
  const onUpload = async (files: UploadedFile[]) => {
    await uploadFilesAsyncWithNotifications({ files, machineId: id || "" });
  };

  function onHideTopSection() {
    setHideTopSection(!hideTopSection);
    localStorage.setItem("hideDrawingsAndConditions", `${!hideTopSection}`);
  }

  return (
    <Stack id="summary-section" horizontalAlign="stretch">
      <Overview
        machine={condition?.machine}
        status={condition?.status}
        title={condition?.machine?.name}
        customBadge={
          <SummaryBadge
            isTooltip
            description={condition?.machine?.comment}
            value={condition?.status}
          />
        }
        hideTopSection={hideTopSection}
        onHideTopSection={onHideTopSection}
        onAddTask={() => setAddTask(true)}
        onAddDocument={() => setAddDocument(true)}
      />
      {!hideTopSection && (
        <Stack
          horizontal
          wrap
          verticalAlign="stretch"
          horizontalAlign="stretch"
          id="summary-section-row-first"
        >
          <DrawingSection
            key={`section-drawings-${condition?.machine?.id}`}
            className="summary-section-drawings"
          />
          <ConditionsSection
            key={`section-conditions-${condition?.machine?.id}`}
            className="summary-section-conditions"
          />
        </Stack>
      )}
      <Stack
        horizontal
        wrap
        verticalAlign="stretch"
        horizontalAlign="stretch"
        id="summary-section-row-second"
      >
        <SignalsSection
          key={`section-signals-${condition?.machine?.id}`}
          className="summary-section-signals"
        />
        <HideTopSectionContext.Provider value={hideTopSection}>
          <DetailsSection
            key={`section-details-${condition?.machine?.id}`}
            className="summary-section-details"
          />
        </HideTopSectionContext.Provider>
      </Stack>
      <TaskAddDialog hidden={!addTask} onDismiss={() => setAddTask(false)} />
      <AddDocumentDialog
        hidden={!addDocument}
        onSubmit={onUpload}
        onClose={() => setAddDocument(false)}
      />
    </Stack>
  );
};

export default Page;
