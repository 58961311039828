import type { PropsWithChildren } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";

export type Viewport = {
  latitude: number;
  longitude: number;
  zoom: number;
};

type MapActions = { type: "SET_VIEWPORT"; payload: Viewport };

export type MapState = Viewport;

type MapContextType = {
  state: MapState;
  actions: {
    changeViewport: (payload: Viewport) => void;
  };
};

export const mapInitialState: MapState = {
  latitude: 17.55,
  longitude: 3.17,
  zoom: 0.9,
};

const mapReducer = (state: MapState, action: MapActions): MapState => {
  switch (action.type) {
    case "SET_VIEWPORT": {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
};

const MapContext = createContext<MapContextType>(
  null as unknown as MapContextType
);

const MapProvider = ({
  children,
  defaultValues,
}: PropsWithChildren<{ defaultValues?: Viewport }>) => {
  const [state, dispatch] = useReducer(
    mapReducer,
    defaultValues ?? mapInitialState
  );

  const changeViewport = useCallback(
    (payload: Viewport) => dispatch({ type: "SET_VIEWPORT", payload }),
    []
  );

  const store = useMemo(
    () => ({
      state,
      actions: { changeViewport },
    }),
    [state, changeViewport]
  );

  return <MapContext.Provider value={store}>{children}</MapContext.Provider>;
};

const useMap = () => useContext(MapContext);

export { MapProvider, useMap };
