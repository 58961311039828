import type { SVGProps } from 'react';
import React from 'react';

const PlantIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 104 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M33.8688 0.200195C35.5968 0.200195 37.2929 0.667396 38.7649 1.5442L39.6289 2.1202L49.8688 9.8002H87.2001C91.0825 9.79966 94.8327 11.2108 97.7517 13.7707C100.671 16.3306 102.559 19.8645 103.066 23.7138L103.168 24.7506L103.2 25.8002V73.8002C103.201 77.8624 101.656 81.7728 98.8798 84.7381C96.1034 87.7034 92.3031 89.5017 88.2496 89.7682L87.2001 89.8002H16.8C12.7379 89.8008 8.8275 88.2563 5.86216 85.4799C2.89682 82.7036 1.09853 78.9032 0.83205 74.8498L0.800049 73.8002V16.2002C0.799423 12.138 2.34394 8.22765 5.1203 5.26231C7.89667 2.29697 11.697 0.498676 15.7504 0.232196L16.8 0.200195H33.8688ZM40.2048 26.0178C38.6274 27.6753 36.5098 28.7148 34.2337 28.949L33.2545 29.0002L7.20005 28.9938V73.8002C7.20016 76.1867 8.08914 78.4875 9.69361 80.2541C11.2981 82.0207 13.503 83.1263 15.8784 83.3554L16.8 83.4002H87.2001C89.5855 83.4002 91.8855 82.5122 93.6519 80.909C95.4184 79.3059 96.5247 77.1025 96.7552 74.7282L96.8 73.8002V25.8002C96.8001 23.4148 95.912 21.1147 94.3089 19.3483C92.7057 17.5819 90.5023 16.4756 88.1281 16.245L87.2001 16.2002H49.5488L40.2048 26.0178ZM33.8753 6.6002H16.8C14.4136 6.60031 12.1127 7.48928 10.3462 9.09376C8.57959 10.6982 7.47395 12.9032 7.24485 15.2786L7.20005 16.2002V22.5938L33.2545 22.6002C33.9519 22.6011 34.6306 22.3742 35.1873 21.9538L35.5713 21.6082L43.6417 13.1282L35.7888 7.2402C35.3745 6.93096 34.8914 6.72674 34.3809 6.645L33.8688 6.6002H33.8753Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default PlantIcon;
