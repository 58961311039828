import {
  Breadcrumb,
  Text,
  IconButton,
  Stack,
  DialogType,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  DefaultButton,
  StackItem,
  IStackTokens,
  IObjectWithKey,
} from "@fluentui/react";
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FilesExtensions,
  ImageUploadExtensions,
  Status,
  TypeMachineExport,
} from "../../../schema/status";
import {
  listAsyncMachDetails,
  selectMachDetails,
  selectMachineDetailsStatus,
} from "./reducer";

import BaseCommandBar, {
  computeCommandBarItems,
} from "../../common/CommandBar";
import { notification } from "../../common/Notification";
import BaseDialog from "../../common/Dialog";
import Table, { TableProps, useTableFilters } from "../../common/Table";

import {
  Configuration,
  Image,
  MachineFixedCondition,
  ResponseSimplifiedEvent,
  ResponseSimplifiedSignal,
  Sensor,
  StandstillSignal,
  UpdateMachine,
} from "../models";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { commandBarStyles, pageStyle } from "../../../schema/Constants";
import { AcceptedFile } from "../../common/Uploader/Uploader";
import {
  AlertLevelsExport,
  getMachineFixedCondition,
  machineExport,
  machineImport,
} from "../api";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";

import { EditStandstill } from "./EditStandstill";
import { alertLevelsImport, alertLevelsList } from "./AlertLevels/api";
import { CUDDialog as CUDDialogALev } from "./AlertLevels/CUDDialog";
import { CUDDialog as CUDDialogSig } from "../../Signals/CUDDialog";
import { CUDDialog as CUDDialogSens } from "../../Sensors/CUDDialog";
import { list } from "../../SensorNodes/api";
import { WirelessSensorNode } from "../../SensorNodes/models";
import { UpdateSignalCondition } from "../../Signals/models";
import { EditCondition } from "../../Signals/EditCondition";
import { EditCondition as EditFixCondition } from "./EditCondition";
import {
  PivotRenderPermissionsBased,
  PropsJsxForPivotsMachineDet,
} from "../../Generic/PivotRenderPermissionsBased";
import {
  AlertLevelPlus,
  getColumns,
  getColumnsAlertLevels,
  getColumnsConditions,
  getColumnsConfigs,
  getColumnsFixCondition,
  getColumnsFrequencyCatalogue,
  getColumnsImages,
  getColumnsSensor,
  getColumnsSignal,
  getColumnsSignalEvent,
  getColumnsStandStill,
  getCommandBarItems,
} from "./columnsHelper";
import {
  listAsyncMachines,
  selectMachineSiblings,
  selectMachinesError,
  selectMachinesStatus,
} from "../reducer";
import { signalsExport } from "./api";
import { IconAndDrawingsMachine } from "./IconAndDrawings/IconAndDrawingsMachine";
import { convertCSVtoXLSX, convertToZipFileName } from "../../../schema/Utils";
import {
  InputUploadImage,
  downloadImage,
  uploadImagesGeneral,
} from "./Images/api";
import saveAs from "file-saver";
import { ListQueue } from "./ListQueue";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";
import { CUDDialog as CUDDialogFrequencyCatalogue } from "./FrequencyCatalogue/CUDDialog";
import { CUDDialogGroup as CUDDialogFrequencyCatalogueGroup } from "./FrequencyCatalogue/CUDDialogGroup";

import {
  FrequencyCatalogueDbEntry,
  FrequencyCatalogueGroupPageDetails,
  FrequencyCatalogueGroupsAndItemsResponse,
} from "./FrequencyCatalogue/models";
import {
  deleteAllFrequencyCatalogueGroups,
  frequencyCatalogueExport,
  frequencyCatalogueGroupDetails,
  frequencyCatalogueImport,
} from "./FrequencyCatalogue/api";
import FrequencyCatalogueGroupTable from "./FrequencyCatalogue/FrequencyCatalogueGroupTable";
import { ConfirmDialog } from "../../Generic/ConfirmDialog";
import { ConfigurationEditor } from "./Configs/ConfigurationEditor";
import { UpdateConfiguration } from "./Configs/api";

// Styles //
const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

const stackTokens: IStackTokens = {
  childrenGap: 50,
};

export type ItemsSelected = ResponseSimplifiedSignal & IObjectWithKey;

export const MachineDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  // const { bigScreen } = useViewport();
  const items = useAppSelector(selectMachDetails);
  const auth = useContext(authContext);
  const status = useAppSelector(selectMachineDetailsStatus);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const machines = useAppSelector(selectMachineSiblings(id));
  const [isLoading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [selected, setSelected] = useState<{
    file: AcceptedFile;
    context:
      | "signals"
      | "sensors"
      | "events"
      | "alert Levels"
      | "images"
      | "frequency catalogue";
  }>();
  const [actionAlertLevel, setActionAlertLevel] = useState<{
    data: AlertLevelPlus | string | undefined;
    context: "add" | "edit" | "delete";
  }>();
  const [actionImages, setActionImages] = useState<{
    data: undefined;
    context: "queue";
  }>();
  const [actionSignals, setActionSignals] = useState<{
    data: ResponseSimplifiedSignal | string | undefined;
    context: "add" | "edit" | "delete";
  }>();
  const [actionSensors, setActionSensors] = useState<{
    data: Sensor | string | undefined;
    context: "add" | "edit" | "delete";
  }>();
  const [actionSignalCondition, setActionSignalsCondition] = useState<{
    data: UpdateSignalCondition;
    context: "edit";
  }>();
  const [actionFixCondition, setActionFixCondition] = useState<{
    data: MachineFixedCondition;
    context: "edit";
  }>();
  const [actionFrequencyCatalogue, setActionFrequencyCatalogue] = useState<{
    data: undefined | string | FrequencyCatalogueDbEntry;
    context: "add" | "edit" | "delete" | "addWithGroup";
  }>();
  const [actionFrequencyCatalogueGroup, setActionFrequencyCatalogueGroup] =
    useState<{
      data: undefined | FrequencyCatalogueGroupPageDetails | string;
      context: "add" | "edit" | "delete";
    }>();
  const [actionFrequencyCatalogueMachine, setActionFrequencyCatalogueMachine] =
    useState<{
      context: "delete";
    }>();

  const [actionEditConfiguration, setActionEditConfiguration] = useState<{
    data: UpdateConfiguration;
    context: "edit";
  }>();

  const [standStillSignals, setStandStillSignals] = useState<{
    primary: { signal: ResponseSimplifiedSignal & StandstillSignal };
    secondary: { signal: ResponseSimplifiedSignal & StandstillSignal };
  }>();
  const [showEditStandstill, setShowEditStandstill] = useState(false);
  const [listAlertLevels, setListAlertLevels] = useState<AlertLevelPlus[]>([]);
  const [sensorNodes, setSensorNodes] = useState<WirelessSensorNode[]>();
  const [fixedCondition, setFixedCondition] =
    useState<MachineFixedCondition[]>();
  const [frequencyCatalogue, setFrequencyCatalogue] =
    useState<FrequencyCatalogueGroupsAndItemsResponse>();
  const statusListMachs = useAppSelector(selectMachinesStatus);
  const error = useAppSelector(selectMachinesError);

  useEffect(() => {
    if (statusListMachs === Status.error && machines?.length < 1)
      notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, machines?.length]);

  useEffect(() => {
    statusListMachs === Status.void && dispatch(listAsyncMachines());
  }, [dispatch, statusListMachs]);

  useEffect(() => {
    list().then((resp) =>
      !("status" in resp)
        ? setSensorNodes(resp.filter((sens) => sens.machineId === id))
        : setSensorNodes([])
    );
  }, [id]);

  useEffect(() => {
    items &&
      items.signals.length > 0 &&
      setStandStillSignals({
        primary: {
          signal: {
            ...items?.signals?.find(
              (ele) => ele.id === items?.standstillSettings?.primary?.signalId
            ),
            ...items?.standstillSettings?.primary,
          },
        },
        secondary: {
          signal: {
            ...items?.signals?.find(
              (ele) => ele.id === items?.standstillSettings?.secondary?.signalId
            ),
            ...items?.standstillSettings?.secondary,
          },
        },
      });

    items &&
      alertLevelsList(id).then((response) => {
        "status" in response
          ? notification.error("Alert levels fetching went wrong.")
          : setListAlertLevels(
              response.map((ele) => {
                const signal = items?.signals?.find(
                  (sig) => sig.id === ele.signalId
                );
                return {
                  ...ele,
                  signalName: signal?.name,
                  sensorSerialNo: signal?.sensorSerialNo,
                  sensorNo: signal?.sensorNo,
                };
              })
            );
      });

    items &&
      frequencyCatalogueGroupDetails(id).then((response) => {
        "status" in response
          ? notification.error("Frequency catalogue fetching went wrong.")
          : setFrequencyCatalogue(response);
      });

    return () => {};
  }, [id, items]);

  useEffect(() => {
    getMachineFixedCondition(id).then((resp) =>
      !("status" in resp)
        ? setFixedCondition([resp])
        : notification.warning("Machine fixed condition is not reachable.")
    );

    dispatch(listAsyncMachDetails(id ? id : ""));

    return () => {};
  }, [id, dispatch]);

  const alertLevelsFilter = useTableFilters<AlertLevelPlus>({
    keys: [
      "signalName",
      "alertHigh",
      "alertLow",
      "conditionGroup",
      "conditionManualEnabled",
      "conditionManualValue",
      "dangerHigh",
      "dangerLow",
      "direction",
      "faultyHigh",
      "faultyLow",
      "indicationHigh",
      "indicationLow",
      "notificationType",
    ],
  });

  const signalFilter = useTableFilters<ResponseSimplifiedSignal>({
    keys: [
      "name",
      "dataFrom",
      "dataUntil",
      "configName",
      "feature",
      "sensorNo",
      "signalDescription",
      "signalGroup",
      "signalType",
      "dataType",
      "unit",
      "sensorSerialNo",
    ],
  });

  const eventFilter = useTableFilters<ResponseSimplifiedEvent>({
    keys: ["dataFrom", "dataUntil", "configName", "source"],
  });

  const sensorFilter = useTableFilters<Sensor>({
    keys: [
      "sensorNo",
      "mountingDirection",
      "mountingType",
      "sensorDescription",
      "sensorSerialNo",
    ],
  });

  const imagesFilter = useTableFilters<Image>({
    keys: [
      "fileName",
      "md5Hash",
      "size",
      "dataUntil",
      "dataFrom",
      "postProcessedTime",
      "creationTime",
    ],
  });

  const configsFilter = useTableFilters<Configuration>({
    keys: [
      "name",
      "boxType",
      "dalogType",
      "operator",
      "isMatCfg",
      "version",
      "time",
      "md5Hash",
      "isActive",
    ],
  });

  const goBack = () => navigate(-1);
  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items: [selected?.file],
      perPage: 5,
      hasSelection: false,
      columns: getColumns(),
      hidePerPage: true,
    }),
    [selected?.file]
  );

  // Handlers
  const handleDownloadClick = (image: Image) => {
    if (!loadingStates[image.id]) {
      setLoadingStates((prevState) => ({
        ...prevState,
        [image.id]: true,
      }));

      // setSelectedItems((prevState) => [...prevState, image]);

      downloadImage(image.id).then((resp) => {
        saveAs(
          new Blob([resp], { type: "application/zip" }),
          convertToZipFileName(image.fileName)
        );
        setLoadingStates((prevState) => ({
          ...prevState,
          [image.id]: false,
        }));
      });
    }
  };

  const onUploadImagesClick = () => {
    let body: InputUploadImage = {
      machineId: id,
      dalogId: items.dalogId,
      file: selected?.file.file,
    };
    let type = selected?.file.name
      .split(".")
      .slice(-1)
      .at(0) as ImageUploadExtensions;

    setLoading(true);
    uploadImagesGeneral(body, type).then((response) => {
      setLoading(false);
      setSelected(undefined);
      if (response.status !== 202) {
        notification.error(response.text);
        return;
      }

      notification.success("Added data successfully.");
      notification.warning(
        "Image processing: It may takes some minutes to display it on the list."
      );
    });
  };

  // Contents
  const signalContent = (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            title: "Signals",
            onChange(files) {
              setSelected({
                context: "signals",
                file: files.at(0),
              });
            },
            xlsxToo: true,
            addButton: auth.metaDataContributor,
            onAdd() {
              setActionSignals({
                data: undefined,
                context: "add",
              });
            },
            importButton: auth.metaDataContributor,
            exportable: auth.metaDataReader,
            onExport() {
              signalsExport(id).then((resp) => {
                if (resp.status === 400) {
                  notification.error("Issue exporting signals");
                } else if (resp.status >= 200 && resp.status < 300) {
                  convertCSVtoXLSX(resp.data, "Signals_" + id + ".xlsx");
                }
              });
            },
            onRefresh() {
              dispatch(listAsyncMachDetails(id ? id : ""));
            },
          })
        )}
        onSearch={signalFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-Signals",
          version: 2,
        }}
        header={{
          title: "Signals",
          isExportable: false,
        }}
        items={items?.signals}
        columns={getColumnsSignal({
          onEditCondition(signalId, condition) {
            setActionSignalsCondition({
              data: { id: signalId, condition: condition },
              context: "edit",
            });
          },
          onEdit(signal) {
            setActionSignals({
              data: signal,
              context: "edit",
            });
            notification.warning("It has effect only on CUSTOM signals.");
          },
          onDelete(signalId) {
            setActionSignals({
              data: signalId,
              context: "delete",
            });
          },
          metaDataContributor: auth.metaDataContributor,
        })}
        filters={signalFilter.filters}
        hasSelection={false}
        //onSelectionChanged={(items: ItemsSelected[]) => setSelectedItems(items)}  // For Bulk Edit Paused
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //onRenderRow={OnRenderRow}   // For Bulk Edit Paused
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );

  const eventsContent = (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            title: "Events",
            onChange(files) {
              setSelected({ context: "events", file: files.at(0) });
            },
            importButton: auth.metaDataContributor,
            xlsxToo: true,
            addButton: auth.metaDataContributor,
            exportable: true,
            onExport() {
              machineExport(id, "events").then((resp) => {
                convertCSVtoXLSX(resp, "Events_" + id + ".xlsx");
              });
            },
          })
        )}
        onSearch={eventFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-events",
          version: 2,
        }}
        header={{
          title: "Events",
          isExportable: false,
        }}
        items={items?.events}
        columns={getColumnsSignalEvent()}
        filters={eventFilter.filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );

  const sensorsContent = (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            title: "Sensors",
            onChange(files) {
              setSelected({
                context: "sensors",
                file: files.at(0),
              });
            },
            addButton: auth.metaDataContributor,
            importButton: auth.metaDataContributor,
            onAdd() {
              setActionSensors({
                data: undefined,
                context: "add",
              });
            },
            xlsxToo: true,
            exportable: auth.metaDataReader,
            onExport() {
              machineExport(id, TypeMachineExport.Sensors).then((resp) => {
                const success = typeof resp === "string";
                !success
                  ? notification.error("Something went wrong on Events export.")
                  : convertCSVtoXLSX(resp, "Sensors_" + id + ".xlsx");
              });
            },
          })
        )}
        onSearch={sensorFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-sensors",
          version: 2,
        }}
        header={{
          title: "Sensors",
          isExportable: false,
        }}
        items={items?.sensors}
        columns={getColumnsSensor({
          hasActions: auth.metaDataContributor,
          onEdit(sensor) {
            setActionSensors({
              data: sensor,
              context: "edit",
            });
          },
          onDelete(sensorId) {
            setActionSensors({
              data: sensorId,
              context: "delete",
            });
          },
        })}
        filters={sensorFilter.filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );

  const imagesContent = (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            title: "Images",
            onChange(files) {
              setSelected({
                context: "images",
                file: files.at(0),
              });
            },
            imagesExts: true,
            importButton: auth.imageContributor,
            onRefresh: () => dispatch(listAsyncMachDetails(id ? id : "")),
            onQueue() {
              setActionImages({
                data: undefined,
                context: "queue",
              });
            },
          })
        )}
        onSearch={imagesFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-images",
          version: 2,
        }}
        header={{
          title: "Images",
        }}
        items={items?.images}
        columns={getColumnsImages({
          handleDownloadClick,
          loadingStates,
        })}
        filters={imagesFilter.filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );

  const configsContent = (
    <>
      <BaseCommandBar
        items={[]}
        onSearch={configsFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-configs",
          version: 2,
        }}
        header={{
          title: "Configs",
        }}
        items={items?.configurations}
        columns={getColumnsConfigs({
          onEdit(config) {
            setActionEditConfiguration({
              data: {
                id: config.id,
                isActive: config.isActive,
                maxDataAgeInDaysError: config.maxDataAgeInDaysError,
                maxDataAgeInDaysWarning: config.maxDataAgeInDaysWarning,
              },
              context: "edit",
            });
          },
        })}
        filters={configsFilter.filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );

  const alertLevelsContent = (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            title: "Alert levels",
            onChange(files) {
              setSelected({
                context: "alert Levels",
                file: files.at(0),
              });
            },
            xlsxToo: true,
            addButton: auth.metaDataContributor,
            importButton: auth.metaDataContributor,
            onAdd() {
              setActionAlertLevel({
                data: undefined,
                context: "add",
              });
            },
            exportable: auth.metaDataReader,
            onExport() {
              AlertLevelsExport(id).then((resp) => {
                convertCSVtoXLSX(resp, "AlertLevels_" + id + ".xlsx");
              });
            },
          })
        )}
        onSearch={alertLevelsFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-alertLevels",
          version: 2,
        }}
        header={{
          title: "Alert Levels",
          isExportable: false,
        }}
        items={listAlertLevels}
        columns={getColumnsAlertLevels({
          hasActions: true,
          onEdit(alertLevel) {
            setActionAlertLevel({
              data: alertLevel,
              context: "edit",
            });
          },
          onDelete(alertLevelId) {
            setActionAlertLevel({
              data: alertLevelId,
              context: "delete",
            });
          },
        })}
        hasSelection={false}
        isLoading={listAlertLevels === undefined}
        hidePerPage
        filters={alertLevelsFilter.filters}
        isError={status === Status.error}
      />
    </>
  );

  const generalConfigsContent = (
    <Stack tokens={stackTokens} styles={{ root: { margin: "50px" } }}>
      <StackItem>
        <IconAndDrawingsMachine
          machine={items as UpdateMachine}
          iconId={items?.iconId}
          machineDrawings={items?.drawingIds}
          onReload={() => dispatch(listAsyncMachDetails(id))}
        />
      </StackItem>

      <StackItem>
        <Text style={titleStylePivot}>Standstill settings:</Text>
        <Stack>
          <Table
            persistOpts={{
              key: "table-standStill",
              version: 2,
            }}
            header={{
              title: "Primary Threshold",
            }}
            items={
              standStillSignals?.primary?.signal
                ? [standStillSignals?.primary?.signal]
                : []
            }
            columns={getColumnsStandStill()}
            hasSelection={false}
            isLoading={status === Status.loading}
            hidePerPage
            isError={status === Status.error || standStillSignals === undefined}
          />

          {items?.standstillSettings?.secondary ? (
            <Table
              persistOpts={{
                key: "table-standStill",
                version: 2,
              }}
              header={{
                title: "Secondary Threshold",
              }}
              items={[standStillSignals?.secondary?.signal]}
              columns={getColumnsStandStill()}
              hasSelection={false}
              isLoading={standStillSignals?.secondary?.signal === undefined}
              hidePerPage
              isError={status === Status.error}
            />
          ) : (
            <></>
          )}

          <PrimaryButton
            text={items?.standstillSettings ? "Update" : "Add"}
            onClick={() => {
              setShowEditStandstill(true);
            }}
          />
        </Stack>
      </StackItem>
      <StackItem>
        <Text style={titleStylePivot}>Machine condition :</Text>
        <Table
          persistOpts={{
            key: "table-condition",
            version: 2,
          }}
          header={{
            title: "Machine condition",
          }}
          items={fixedCondition}
          columns={getColumnsFixCondition({
            onEdit(fixCondition) {
              setActionFixCondition({
                data: fixCondition,
                context: "edit",
              });
            },
          })}
          hasSelection={false}
          isLoading={fixedCondition === undefined}
          hidePerPage
          isError={status === Status.error}
        />
      </StackItem>

      <StackItem>
        <Text style={titleStylePivot}>Machine conditions :</Text>
        <Table
          persistOpts={{
            key: "table-conditions",
            version: 2,
          }}
          header={{
            title: "Conditions",
          }}
          items={items?.conditions}
          columns={getColumnsConditions()}
          hasSelection={false}
          isLoading={items?.conditions === undefined}
          hidePerPage
          isError={status === Status.error}
        />
      </StackItem>
      <StackItem>
        <Text style={titleStylePivot}>
          Frequency Catalogue{" "}
          {items?.referenceSpeedInRpm && (
            <> (Reference Speed In Rpm {items.referenceSpeedInRpm})</>
          )}{" "}
          :
        </Text>

        <BaseCommandBar
          items={computeCommandBarItems(
            getCommandBarItems({
              title: "Frequency Catalogue Groups:",
              xlsxOnly: true,
              addButton: auth.metaDataContributor,
              onAdd() {
                setActionFrequencyCatalogue({
                  data: undefined,
                  context: "addWithGroup",
                });
              },
              onDelete() {
                setActionFrequencyCatalogueMachine({
                  context: "delete",
                });
              },
              onChange(files) {
                setSelected({
                  context: "frequency catalogue",
                  file: files.at(0),
                });
              },
              importButton: auth.metaDataContributor,
              exportable: auth.metaDataReader,
              onExport() {
                frequencyCatalogueExport(id).then((resp) => {
                  convertCSVtoXLSX(resp, "FrequencyCatalogue_" + id + ".xlsx");
                });
              },
            })
          )}
          styles={commandBarStyles}
        />
        <>
          {frequencyCatalogue && (
            <FrequencyCatalogueGroupTable
              groupsAndItems={frequencyCatalogue}
              columns={getColumnsFrequencyCatalogue({
                hasActions: auth.metaDataContributor,
                onEdit(freq) {
                  setActionFrequencyCatalogue({ data: freq, context: "edit" });
                },
                onDelete(freq) {
                  setActionFrequencyCatalogue({
                    data: freq,
                    context: "delete",
                  });
                },
              })}
              onAddItem={(groupName) => {
                setActionFrequencyCatalogue({
                  data: groupName,
                  context: "add",
                });
              }}
              onDeleteGroup={(groupName) => {
                setActionFrequencyCatalogueGroup({
                  data: frequencyCatalogue.groups.find(
                    (group) => group.name === groupName
                  )?.id,
                  context: "delete",
                });
              }}
              onEditGroup={(groupName) => {
                setActionFrequencyCatalogueGroup({
                  data: frequencyCatalogue.groups.find(
                    (group) => group.name === groupName
                  ),
                  context: "edit",
                });
              }}
            />
          )}
        </>
      </StackItem>
    </Stack>
  );

  const arrayPivotItems: PropsJsxForPivotsMachineDet[] = [
    {
      itemKey: "generalConfigs",
      headerText: "General Configs",
      textOnRender: "General Configs",
      content: generalConfigsContent,
      show: auth.metaDataContributor,
    },
    {
      itemKey: "signals",
      headerText: "Signals",
      textOnRender: "Signals [" + items?.signals?.length?.toString() + "]",
      content: signalContent,
      show: true,
    },
    {
      itemKey: "events",
      headerText: "Events",
      textOnRender: "Events [" + items?.events?.length.toString() + "]",
      content: eventsContent,
      show: true,
    },
    {
      itemKey: "sensors",
      headerText: "Sensors",
      textOnRender: "Sensors [" + items?.sensors?.length.toString() + "]",
      content: sensorsContent,
      show: true,
    },
    {
      itemKey: "alertLevels",
      headerText: "Alert Levels",
      textOnRender: listAlertLevels
        ? "Alert Levels  [" + listAlertLevels.length + "]"
        : "Alert Levels  [0]",
      content: alertLevelsContent,
      show: true,
    },
    {
      itemKey: "images",
      headerText: "Images",
      textOnRender: "Images [" + items?.images?.length.toString() + "]",
      content: imagesContent,
      show: auth.metaDataContributor,
    },
    {
      itemKey: "configs",
      headerText: "Configs",
      textOnRender:
        "Configs [" + items?.configurations?.length.toString() + "]",
      content: configsContent,
      show: auth.metaDataContributor,
    },
  ];

  return (
    <>
      {items ? (
        <>
          <Stack horizontal verticalAlign="center">
            <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
            <Breadcrumb
              items={[
                {
                  key: `corporation-${items?.corporation?.number}`,
                  text: items?.corporation?.name,
                },
                {
                  key: `company-${items?.company?.number}`,
                  text: items?.company?.name,
                },
                {
                  key: `project-${items?.project?.name}`,
                  text: items?.project?.name,
                },
                { key: `machine-${items?.dalogId}`, text: items?.dalogId },
              ]}
              styles={{
                root: { margin: 0 },
                item: { fontSize: "14px" },
                chevron: { fontSize: "10px" },
              }}
            />
          </Stack>
          <div style={pageStyle}>
            {PivotRenderPermissionsBased(arrayPivotItems)}
          </div>
          {showEditStandstill && (
            <EditStandstill
              standstillSettings={items?.standstillSettings}
              signals={items?.signals}
              onClose={() => {
                setShowEditStandstill(false);
              }}
              show={showEditStandstill}
              machineId={id}
              onSuccess={() => {
                notification.success("Successfully updated");
                dispatch(listAsyncMachDetails(id ? id : ""));
              }}
            />
          )}
          <BaseDialog
            hidden={
              ![
                "signals",
                "sensors",
                "events",
                "alert Levels",
                "images",
                "frequency catalogue",
              ].includes(selected?.context)
            }
            dialogContentProps={{
              type: DialogType.normal,
              title: "Import " + selected?.context,
              closeButtonAriaLabel: "Close",
              onDismiss: () => setSelected(undefined),
            }}
          >
            <Table {...tableProps}></Table>
            <DialogFooter>
              {["signals", "sensors", "events"].includes(selected?.context) ? (
                <PrimaryButton
                  text="Save Changes"
                  disabled={isLoading}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                  onClick={() => {
                    setLoading(true);
                    machineImport(
                      id,
                      selected?.context,
                      selected?.file.file
                    ).then((res) => {
                      dispatch(listAsyncMachDetails(id));

                      setSelected(undefined);
                      res.status === 200
                        ? notification.success("Added data successfully.")
                        : notification.error(res.text);
                    });
                    setLoading(false);
                  }}
                />
              ) : ["alert Levels"].includes(selected?.context) ? (
                <PrimaryButton
                  text="Save Changes"
                  disabled={isLoading}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                  onClick={() => {
                    setLoading(true);
                    alertLevelsImport(
                      id,
                      selected?.file.name
                        .split(".")
                        .slice(-1)
                        .at(0) as FilesExtensions,
                      selected?.file.file
                    ).then(async (res) => {
                      await alertLevelsList(id).then((response) => {
                        "status" in response
                          ? notification.error(
                              "Alert levels fetching went wrong."
                            )
                          : setListAlertLevels(
                              response.map((ele) => {
                                return {
                                  ...ele,
                                  signalName: items?.signals?.find(
                                    (sig) => sig.id === ele.signalId
                                  ).name,
                                };
                              })
                            );
                      });
                      setLoading(false);
                      setSelected(undefined);
                      res.status === 200
                        ? notification.success("Added data successfully.")
                        : notification.error("Something went wrong.");
                    });
                    //setLoading(false);
                  }}
                />
              ) : ["frequency catalogue"].includes(selected?.context) ? (
                <PrimaryButton
                  text="Save Changes"
                  disabled={isLoading}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                  onClick={() => {
                    setLoading(true);
                    frequencyCatalogueImport(id, selected?.file.file).then(
                      (res) => {
                        if (res.status === 200) {
                          frequencyCatalogueGroupDetails(id).then(
                            (response) => {
                              if ("status" in response) {
                                notification.error(
                                  "Frequency catalogue fetching went wrong."
                                );
                              } else {
                                setFrequencyCatalogue(response);
                                dispatch(listAsyncMachDetails(id));
                              }
                            }
                          );
                          notification.success("Added data successfully.");
                        } else notification.error(res.text);
                        setSelected(undefined);
                        setLoading(false);
                      }
                    );
                  }}
                />
              ) : (
                <PrimaryButton
                  text="Save Changes"
                  disabled={isLoading}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                  onClick={onUploadImagesClick}
                />
              )}

              <DefaultButton
                styles={{
                  root: { border: "unset", background: "transparent" },
                }}
                text="Cancel"
                onClick={() => setSelected(undefined)}
              />
            </DialogFooter>
          </BaseDialog>
          {actionAlertLevel && (
            <CUDDialogALev
              machineId={id}
              data={actionAlertLevel?.data}
              signals={
                ["add"].includes(actionAlertLevel?.context)
                  ? items?.signals.filter(
                      (sig) =>
                        !listAlertLevels.some(
                          (alert) => alert.signalId === sig.id
                        )
                    )
                  : items?.signals
              }
              show={["add", "edit", "delete"].includes(
                actionAlertLevel?.context
              )}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message = `Failed ${
                    context === "add" ? "creating" : "updating"
                  } Alert level`;

                  notification.error(message);
                } else {
                  alertLevelsList(id).then((response) => {
                    "status" in response
                      ? notification.error("Alert levels fetching went wrong.")
                      : setListAlertLevels(
                          response.map((ele) => {
                            return {
                              ...ele,
                              signalName: items?.signals?.find(
                                (sig) => sig.id === ele.signalId
                              ).name,
                            };
                          })
                        );
                  });
                  const message = `Alert level ${context}  successfully`;

                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionAlertLevel(undefined);
              }}
              context={actionAlertLevel?.context}
            />
          )}
          {actionSignals && (
            <CUDDialogSig
              machineId={id}
              data={actionSignals?.data}
              sensors={items?.sensors}
              sensorNodes={sensorNodes}
              show={["add", "edit", "delete"].includes(actionSignals?.context)}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message = `Failed ${
                    context === "add"
                      ? "creating"
                      : context === "edit"
                        ? "updating"
                        : "deleting (It may not be a custom signal.) "
                  } Signal`;

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = `Signal ${context}  successfully`;

                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSignals(undefined);
              }}
              context={actionSignals?.context}
            />
          )}

          {actionImages && (
            <ListQueue
              show={["queue"].includes(actionImages?.context)}
              onClose={function (): void {
                setActionImages(undefined);
              }}
            />
          )}
          {actionSignalCondition && (
            <EditCondition
              machineId={id}
              data={actionSignalCondition?.data}
              show={["edit"].includes(actionSignalCondition?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = `Failed updating Signal condition`;

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = `Signal condition updated successfully`;

                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSignalsCondition(undefined);
              }}
            />
          )}

          {actionEditConfiguration && (
            <ConfigurationEditor
              machineId={id}
              data={actionEditConfiguration?.data}
              show={["edit"].includes(actionEditConfiguration?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = `Failed updating Configuration`;

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = `Configuration updated successfully`;
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionEditConfiguration(undefined);
              }}
            />
          )}
          {actionFixCondition && (
            <EditFixCondition
              machineId={id}
              data={actionFixCondition?.data}
              show={["edit"].includes(actionFixCondition?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = `Failed updating condition`;

                  notification.error(message);
                } else {
                  const message = `Condition updated successfully`;
                  notification.success(message);
                  getMachineFixedCondition(id).then((resp) =>
                    !("status" in resp)
                      ? setFixedCondition([resp])
                      : notification.warning(
                          "Machine condition is not reachable."
                        )
                  );
                }
              }}
              onClose={() => {
                setActionFixCondition(undefined);
              }}
            />
          )}
          {actionSensors && (
            <CUDDialogSens
              machineId={id}
              data={actionSensors?.data}
              machines={machines}
              show={["add", "edit", "delete"].includes(actionSensors?.context)}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message = `Failed ${
                    context === "add" ? "creating" : "updating"
                  } Sensor`;

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = `Sensor ${context}  successfully`;
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSensors(undefined);
              }}
              context={actionSensors?.context}
            />
          )}
          {actionFrequencyCatalogue && (
            <CUDDialogFrequencyCatalogue
              machineId={id}
              data={actionFrequencyCatalogue?.data}
              show={["add", "edit", "delete", "addWithGroup"].includes(
                actionFrequencyCatalogue?.context
              )}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message = `Failed ${
                    context === "add" ? "creating" : "updating"
                  } Frequency Catalogue item`;

                  notification.error(message);
                } else {
                  frequencyCatalogueGroupDetails(id).then((response) => {
                    "status" in response
                      ? notification.error(
                          "Frequency Catalogue fetching went wrong."
                        )
                      : setFrequencyCatalogue(response);
                  });
                  const message = `Frequency Catalogue item ${context}  successfully`;
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionFrequencyCatalogue(undefined);
              }}
              context={actionFrequencyCatalogue?.context}
            />
          )}
          {actionFrequencyCatalogueGroup && (
            <CUDDialogFrequencyCatalogueGroup
              machineId={id}
              data={actionFrequencyCatalogueGroup?.data}
              show={["add", "edit", "delete"].includes(
                actionFrequencyCatalogueGroup?.context
              )}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message = `Failed ${
                    context === "add" ? "creating" : "updating"
                  } Frequency Catalogue Group`;

                  notification.error(message);
                } else {
                  frequencyCatalogueGroupDetails(id).then((response) => {
                    "status" in response
                      ? notification.error(
                          "Frequency Catalogue fetching went wrong."
                        )
                      : setFrequencyCatalogue(response);
                  });
                  const message = `Frequency Catalogue item ${context}  successfully`;
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionFrequencyCatalogueGroup(undefined);
              }}
              context={actionFrequencyCatalogueGroup?.context}
            />
          )}
          {["delete"].includes(actionFrequencyCatalogueMachine?.context) && (
            <ConfirmDialog
              title={"Delete all items of Machine"}
              render={
                <>
                  You are about to delete all frequency catalogue items of the
                  current machine
                </>
              }
              isLoading={isLoading}
              onConfirm={() => {
                deleteAllFrequencyCatalogueGroups(id).then((response) => {
                  if (response.status === 200) {
                    notification.success("All items deleted successfully");
                    setFrequencyCatalogue(undefined);
                  } else {
                    notification.error("Something went wrong");
                  }
                });
                setActionFrequencyCatalogueMachine(undefined);
              }}
              onCancel={() => {
                setActionFrequencyCatalogueMachine(undefined);
              }}
              onClose={() => {
                setActionFrequencyCatalogueMachine(undefined);
              }}
            />
          )}
          {/* {actionSignalsBulk && (
            <EditBulkDialog
              signalsNotCommon={selectedItems}
              data={selectedItems}
              sensors={items.sensors}
              show={["editB"].includes(actionSignalsBulk?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = `Failed updating signals`;

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = `Signals updated successfully`;
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSignalsBulk(undefined);
              }}
            />
          )} */}
        </>
      ) : status === Status.idle || status === Status.error ? (
        <>
          <NotFoundRoute />
        </>
      ) : (
        <>
          <Spinner size={SpinnerSize.large} />
        </>
      )}
    </>
  );
};
