import type { SVGProps } from 'react';
import React from 'react';

const HarmonicsIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='#2C529F'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M0.5 16C0.367392 16 0.240215 15.9473 0.146447 15.8536C0.0526785 15.7598 0 15.6326 0 15.5V0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0C0.632608 0 0.759785 0.0526785 0.853553 0.146447C0.947322 0.240215 1 0.367392 1 0.5V15.5C1 15.6326 0.947322 15.7598 0.853553 15.8536C0.759785 15.9473 0.632608 16 0.5 16ZM5.5 16C5.36739 16 5.24021 15.9473 5.14645 15.8536C5.05268 15.7598 5 15.6326 5 15.5V0.5C5 0.367392 5.05268 0.240215 5.14645 0.146447C5.24021 0.0526785 5.36739 0 5.5 0C5.63261 0 5.75979 0.0526785 5.85355 0.146447C5.94732 0.240215 6 0.367392 6 0.5V15.5C6 15.6326 5.94732 15.7598 5.85355 15.8536C5.75979 15.9473 5.63261 16 5.5 16ZM10 15.5C10 15.6326 10.0527 15.7598 10.1464 15.8536C10.2402 15.9473 10.3674 16 10.5 16C10.6326 16 10.7598 15.9473 10.8536 15.8536C10.9473 15.7598 11 15.6326 11 15.5V0.5C11 0.367392 10.9473 0.240215 10.8536 0.146447C10.7598 0.0526785 10.6326 0 10.5 0C10.3674 0 10.2402 0.0526785 10.1464 0.146447C10.0527 0.240215 10 0.367392 10 0.5V15.5Z' />
    </svg>
  );
};

export default HarmonicsIcon;
