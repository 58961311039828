import { useContext, useEffect, useMemo } from "react";

import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../../../common/Form";

import { IDialogProps, Stack } from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { maxLengthType1 } from "../../../../schema/Constants";
import { RequestWirelessGatewayAddAux } from "../../models";
import { SupportContext } from "../SupportContext";
import { maxRsshPort, minRsshPort } from "../../../Gateways/AddDialog";

const getSchema = (serialNumbers: string[]) =>
  z
    .object({
      projectId: z.string().optional(),
      serialNumber: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Serial Number must contain at most ${maxLengthType1} character(s)`,
        })
        .refine(
          (val) =>
            serialNumbers
              .map((serialN) => serialN.trim().toLowerCase())
              .findIndex((value) => value === val.trim().toLowerCase()) === -1,
          {
            message: "The serial number already exists",
          }
        ),
      adminPassword: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Admin Password must contain at most ${maxLengthType1} character(s)`,
        }),
      userPassword: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `User Password must contain at most ${maxLengthType1} character(s)`,
        }),
      ltePlanSize: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType1} character(s)`,
        }),
      publicKey: z.string().optional(),
      rsshPort: z
        .number()
        .min(
          minRsshPort,
          `Try a number between (${minRsshPort}:${maxRsshPort})`
        )
        .max(
          maxRsshPort,
          `Try a number between (${minRsshPort}:${maxRsshPort})`
        )
        .optional(),
      wifiPasswordForAP: z.string().optional(),
      wifiSSID: z.string().optional(),
      iMEI: z.string().optional(),
      wirepassSinkNodeAddress: z.string().optional(),
      wirepassChannel: z.string().optional(),
      ethernetMacAddress: z.string().optional(),
      firmware: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: "Try a number between (1.0:1000.0)",
      }
    );

const gatewayFields: FormItemProps[] = [
  {
    name: "adminPassword",
    type: FormItemType.TextField,
    groupProps: { label: "Admin Password *" },
  },
  {
    name: "userPassword",
    type: FormItemType.TextField,
    groupProps: { label: "User Password *" },
  },
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: "Lte Plan Size *" },
  },
  {
    name: "publicKey",
    type: FormItemType.TextField,
    groupProps: { label: "Public Key" },
  },
  {
    name: "rsshPort",
    type: FormItemType.TextField,
    groupProps: { label: "Rssh Port" },
  },
  {
    name: "wifiPasswordForAP",
    type: FormItemType.TextField,
    groupProps: { label: "Wifi Password For AP" },
  },
  {
    name: "wifiSSID",
    type: FormItemType.TextField,
    groupProps: { label: "Wifi SSID" },
  },
  {
    name: "iMEI",
    type: FormItemType.TextField,
    groupProps: { label: "IMEI" },
  },
  {
    name: "wirepassSinkNodeAddress",
    type: FormItemType.TextField,
    groupProps: { label: "Wirepass Sink Node Address" },
  },
  {
    name: "wirepassChannel",
    type: FormItemType.TextField,
    groupProps: { label: "Wirepass Channel" },
  },
  {
    name: "ethernetMacAddress",
    type: FormItemType.TextField,
    groupProps: { label: "Ethernet Mac Address" },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: "Firmware" },
  },
];

type AddDialogProps = IDialogProps & {
  serialNumbers: string[];
};

export const AddDialog = ({ serialNumbers, ...rest }: AddDialogProps) => {
  const { support, updateSupportEle } = useContext(SupportContext);

  const toUpdate = support.gatewaysSupportToAdd.find(
    (gateway) => gateway.matchUniqueId === support.matchUniqueId
  );

  const schema = useMemo(
    () =>
      getSchema(serialNumbers.filter((item) => item !== toUpdate.serialNumber)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      ...toUpdate,
      ltePlanSize: toUpdate.ltePlanSize
        ? String(toUpdate.ltePlanSize)
        : undefined,
      rsshPort: toUpdate.rsshPort,
    },
  });

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    const toUp: RequestWirelessGatewayAddAux = {
      ...(control._formValues as RequestWirelessGatewayAddAux),
      matchUniqueId: toUpdate?.matchUniqueId,
      projectId: toUpdate?.projectId,
      ltePlanSize: control._formValues.ltePlanSize
        ? Number(control._formValues.ltePlanSize)
        : undefined,
      rsshPort: control._formValues.rsshPort
        ? Number(control._formValues.rsshPort)
        : undefined,
      isValid,
    };
    isValid && updateSupportEle(toUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  return (
    <Stack>
      {
        <>
          {renderFormItems(gatewayFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
        </>
      }
    </Stack>
  );
};
