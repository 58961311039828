import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useEvent } from 'react-use';

const parseJSON = <T>(value: string | null): T | undefined => {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    return undefined;
  }
};

type Set<T> = Dispatch<SetStateAction<T>>;

const usePersistedState = <T>(key: string, initialValue: T): [T, Set<T>] => {
  const read = useCallback((): T => {
    try {
      const item = localStorage.getItem(key);

      return item ? (parseJSON(item) as T) : initialValue;
    } catch {
      return initialValue;
    }
  }, [key, initialValue]);

  const [state, setState] = useState<T>(read);

  const set: Set<T> = useCallback(
    (value) => {
      try {
        const newState = value instanceof Function ? value(state) : value;

        localStorage.setItem(key, JSON.stringify(newState));

        setState(newState);

        window.dispatchEvent(new Event('local-storage'));
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [key, setState],
  );

  useEffect(() => {
    setState(read());
  }, []);

  const handleStorage = useCallback(
    (event: unknown) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) return;

      setState(read());
    },
    [key, read],
  );

  useEvent('local-storage', handleStorage);

  return [state, set];
};

export default usePersistedState;
