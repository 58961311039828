import { TextField } from "@fluentui/react";
import { MachineSubmission } from "../models";
import { RequestSubmissionMachine } from "../../models";
import { isValidDalogId } from "../../../../schema/Utils";
import { useAppSelector } from "../../../../Hooks";
import { selectMachinesToList } from "../../../Machines/reducer";
import { SupportContext } from "../SupportContext";
import { useContext } from "react";
import { SecondPanelContext } from "../SubmissionsDetails";

interface MachineSupportInputProps {
  context: SecondPanelContext;
  matchUniqueId: string;
}

export const MachineSupportInput: React.FC<MachineSupportInputProps> = ({
  context,
  matchUniqueId,
}) => {
  const machines = useAppSelector(selectMachinesToList);
  const { support, updateSupportEle } = useContext(SupportContext);

  const toUpdate = support?.machinesSupport.find(
    (mach) => mach.matchUniqueId === matchUniqueId
  );

  return (
    <>
      <TextField
        defaultValue={toUpdate?.nameMachine}
        disabled={
          !!context.initialValues.mach.id || context.context !== "Machine"
        }
        label="Machine Name"
        onChange={(ev, newVal) => {
          const machNew: MachineSubmission = {
            name: newVal.trim(),
            uniqueId: context.initialValues.mach.uniqueId,
            gearbox: context.initialValues.mach.gearbox,
          };
          context.updateFunction(machNew, context.initialValues.mach.uniqueId);
        }}
      />

      <TextField
        defaultValue={toUpdate?.dalogId}
        disabled={context.context !== "Machine"}
        label="Dalog Id"
        onChange={(ev, newVal) => {
          const machNew: RequestSubmissionMachine = {
            dalogId: newVal.trim(),
            matchUniqueId: context.initialValues.mach.uniqueId,
            nameMachine: context.initialValues.mach.name,
          };
          updateSupportEle(machNew);
        }}
        onGetErrorMessage={(val) =>
          !isValidDalogId(val)
            ? "The format is XX-XXX-XX."
            : machines.some((ele) => ele.dalogId === val)
              ? "The dalogId already exist"
              : ""
        }
      />
    </>
  );
};
