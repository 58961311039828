import type { Label, Legend } from '@amcharts/amcharts5';
import * as am5 from '@amcharts/amcharts5';
import type { AxisRenderer, DateAxis, ValueAxis, XYSeries } from '@amcharts/amcharts5/xy';

export const getEntity = <T>(id: string) => {
  const entity = am5.registry.entitiesById[id];

  if (!entity) {
    console.warn(`${id} is not registered.`);
  }

  return entity as T;
};

export const getDateAxis = (id: string) => getEntity<DateAxis<AxisRenderer>>(id);

export const getValueAxis = (id: string) => getEntity<ValueAxis<AxisRenderer>>(id);

export const getSeries = (id: string) => getEntity<XYSeries>(id);

export const getLegend = (id: string) => getEntity<Legend>(id);

export const getLabel = (id: string) => getEntity<Label>(id);
