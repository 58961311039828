import type { CSSProperties } from "react";
import React from "react";

import { taskStatusFormat } from "../../../../../modules/machine-cv/constants";
import type { Edit } from "../../../MachineCVTasksPage/hooks/useTasksStore";
import type { TaskOverview } from "../../../../../types";
import type { Column } from "../../../../common/Table/Table";

const linkStyle: CSSProperties = {
  color: "#2C529F",
  cursor: "pointer",
  display: "flex",
};

type GetColumnsOpts = { showEdit: (payload: Edit) => void };

export const getColumns = ({ showEdit }: GetColumnsOpts): Column[] => [
  {
    key: "subject",
    fieldName: "subject",
    name: "Subject",
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 100,
    isSortable: true,
    isResizable: true,
    onRender: (item: TaskOverview) => {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <span
          tabIndex={0}
          role="button"
          style={linkStyle}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            showEdit({ id: item.id });
          }}
        >
          {item.subject}
        </span>
      );
    },
  },
  {
    key: "status",
    fieldName: "status",
    name: "Status",
    minWidth: 100,
    isSortable: true,
    isResizable: false,
    onRender: (item: TaskOverview) => taskStatusFormat[item.status],
  },
  {
    key: "assignedTo",
    fieldName: "assignedTo",
    name: "Assigned To",
    minWidth: 150,
    isResizable: false,
    isSortable: true,
  },
];
