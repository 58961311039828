import { format as fnsFormat } from 'date-fns';

export const format = (date: Date | number, dateFormat = 'Y-MM-dd') => {
  return fnsFormat(date, dateFormat);
};

export const ISOStringToDate = (date: string, withTime?: boolean) => {
  const newDate = new Date(date.split('T')[0]);
  if (!withTime) {
    newDate.setHours(0, 0, 0, 0);
  }
  return newDate;
};
