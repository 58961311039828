import type { IDialogProps } from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import { useLocationSearch } from "../../../../../Hooks";
import { FormType } from "../CommandBar";
import { useInfoAdd, useUpdateGroupName } from "../../hooks/useInfoMutations";
import { useMachineCVInfo } from "../../hooks/useMachineCVInfo";
import type { Group } from "../../../../../types";
import Dialog from "../../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../common/Notification";

const dialogAddContentProps = {
  type: DialogType.normal,
  title: "Create group",
  closeButtonAriaLabel: "Close",
};

const dialogEditContentProps = {
  type: DialogType.normal,
  title: "Edit group",
  closeButtonAriaLabel: "Close",
};

const getSchema = (groups: Group[], groupName = "") =>
  z
    .object({
      group: z.string().min(1, { message: "This field is required" }),
    })
    .refine(
      ({ group }) => {
        const refinedGroupName = groupName.toLowerCase().trim();
        const refinedInputName = group.toLowerCase().trim();

        if (refinedInputName === refinedGroupName) {
          return true;
        }

        const nameList = groups.map((group) => group.name.toLowerCase().trim());
        return !nameList.includes(refinedInputName);
      },
      { path: ["group"], message: "Group name already exists" }
    );

const fields: FormItemProps[] = [
  {
    name: "group",
    type: FormItemType.TextField,
    groupProps: { label: "Group name" },
    placeholder: "Group name",
  },
];

interface EditGroupDialogProps {
  formType: FormType.Edit;
  group: Group;
}

interface AddGroupDialogProps {
  formType: FormType.New;
  group?: Group;
}

type GroupDialogProps = IDialogProps &
  (EditGroupDialogProps | AddGroupDialogProps) & { allGroups: Group[] };

const GroupDialog: React.FC<GroupDialogProps> = (props) => {
  const [{ id }] = useLocationSearch();
  const [isLoading, setIsLoading] = useState(false);

  const { formType, allGroups } = props;
  const modalProps = useMemo(
    () => ({
      titleAriaId: "dialogLabel",
      subtitleAriaId: "subTextLabel",
      isBlocking: false,
    }),
    []
  );

  const { refetch } = useMachineCVInfo({ machineId: id || "" });

  const schema = useMemo(
    () => getSchema(allGroups, props?.group?.name),
    [allGroups]
  );

  useEffect(() => {
    if (formType === FormType.Edit) {
      reset({ group: props.group.name });
    }
  }, [props.hidden]);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useZodForm({
    ...(props.formType === FormType.Edit && {
      defaultValues: {
        group: props.group.name,
      },
    }),
    schema,
  });

  const onFinish = (formType: FormType) => {
    refetch();
    onClose();
    notification.success(
      `Group ${formType === FormType.New ? "created" : "updated"} successfully`
    );
  };

  const { addInfoAsync } = useInfoAdd();
  const { updateGroupNameAsync } = useUpdateGroupName();

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);

    if (formType === FormType.New) {
      addInfoAsync({ ...data, machineId: id as string })
        .then(() => {
          onFinish(FormType.New);
        })
        .catch(() => notification.error("Error creating group"))
        .finally(() => setIsLoading(false));
      return;
    }

    updateGroupNameAsync({
      machineId: id as string,
      newGroup: data.group,
      oldGroup: props.group.name,
    })
      .then(() => {
        onFinish(FormType.Edit);
      })
      .catch(() => notification.error("Error updating group"))
      .finally(() => setIsLoading(false));
  });

  const onClose = () => {
    reset();
    props.onDismiss?.();
  };

  return (
    <Dialog
      {...props}
      dialogContentProps={
        formType === FormType.New
          ? dialogAddContentProps
          : dialogEditContentProps
      }
      modalProps={modalProps}
      minWidth={640}
      maxWidth={640}
    >
      <form onSubmit={onSubmit}>
        {renderFormItems(fields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text="Save"
            disabled={isLoading || !isDirty}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text="Cancel"
            onClick={onClose}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export default GroupDialog;
