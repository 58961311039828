import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";


interface BookmarksStore {
  bookmarks: any;
  isLoading: boolean;
  setBookmarks: (bookmarks: any) => void;
  setIsLoading: (isLoading: boolean) => void;
}

const useBookmarksStore = createWithEqualityFn<BookmarksStore>((set) => ({
  bookmarks: null,
  setBookmarks: (bookmarks) => set({ bookmarks }),
  setIsLoading: (isLoading) => set({ isLoading }),
  isLoading: false,
}), shallow);

export default useBookmarksStore;
