export const FACTOR_LIMITS = {
  min: -100,
  max: 100,
};

export const OFFSET_LIMITS = {
  min: -100,
  max: 100,
};

export const dialogStyles = {
  root: [
    {
      '.ms-Dialog-main': {
        // maxWidth: '100%',
        // resize: 'both',
        // height: '70vh',
      },
    },
    {
      '.ms-Modal-scrollableContent': {
        height: '100%',
        maxHeight: '100%',
      },
    },
    {
      '.ms-Dialog--close': {
        height: '100%',
      },
    },
    {
      '.ms-Dialog-header': {
        height: 'auto',
      },
    },
    {
      '.ms-Dialog-inner': {
        height: 'calc(100% - 63px)',
      },
    },
    {
      '.ms-Dialog-content': {
        height: 'calc(100% - 48px)',
      },
    },
  ],
};

export const spinButtonStyles = {
  root: {
    maxWidth: 140,
  },
};

export const sliderStyles = {
  root: [
    { width: '100%' },
    {
      '.ms-Slider-slideBox': {
        padding: 0,
        ':hover, :active': {
          '.ms-Slider-inactive': {
            background: '#F3F2F1',
          },
          '.ms-Slider-thumb': {
            border: '2px solid #fff',
          },
        },
      },
    },
    {
      '.ms-Slider-inactive': {
        background: '#F3F2F1',
        height: '2px',
      },
    },
    {
      '.ms-Slider-active': {
        background: '#2C529F',
        height: '2px',
      },
    },
    {
      '.ms-Slider-thumb': {
        height: '12px',
        width: '12px',
        top: '-5px',
        background: '#2C529F',
        border: '2px solid #fff',
      },
    },
    {
      '.ms-Slider-zeroTick': {
        display: 'none',
      },
    },
  ],
};
