import { useEffect, useState } from "react";

import { useUserSettings, useUserSettingsUpdate } from "../../../../Hooks";

import useBookmarksStore from "./useBookmarksStore";

const useBookmark = () => {
  const { bookmarks, setBookmarks, isLoading, setIsLoading } =
    useBookmarksStore((store) => store);
  const [initialResponse, setInitialResponse] = useState({});

  const memberInfo = JSON.parse(sessionStorage?.accountInfo || "{}");
  const id = memberInfo?.memberId || "d6fbcf74-1e59-4984-82b6-23f899793a38";
  const { getUserSettings } = useUserSettings({ id });
  const { setUserSettings } = useUserSettingsUpdate({ id });

  useEffect(() => {
    let isMounted = true;
    if (!isLoading && !bookmarks) {
      setIsLoading(true);
      getUserSettings().then(
        (res) => {
          if (isMounted) {
            const bookmarksRes = res?.data?.bookmarks || [];
            setBookmarks(bookmarksRes);
            setIsLoading(false);
            setInitialResponse(res?.data || {});
          }
        },
        () => {
          if (isMounted) {
            setBookmarks(bookmarks || []);
            setIsLoading(false);
            setInitialResponse({});
          }
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const addBookmark = ({ bookmark, onSuccess, onFailed, onStart }: any) => {
    if (isLoading) return;
    const canBeAdd = !bookmarks.filter(
      ({ id, type }: any) => bookmark.id === id && bookmark.type === type
    )[0];

    if (canBeAdd) {
      onStart && onStart();
      setUserSettings({
        ...initialResponse,
        bookmarks: [...bookmarks, bookmark],
      }).then(
        () => {
          setBookmarks([...bookmarks, bookmark]);
          onSuccess && onSuccess();
        },
        () => {
          onFailed && onFailed();
        }
      );
    }
  };

  const removeBookmark = ({ bookmark, onSuccess, onFailed, onStart }: any) => {
    if (isLoading) return;

    const canBeRemove = !!bookmarks.filter(
      ({ id }: any) => bookmark.id === id
    )[0];

    if (canBeRemove) {
      onStart && onStart();
      const updatedBookmarks = bookmarks.filter(
        ({ id }: any) => bookmark.id !== id
      );
      setUserSettings({ bookmarks: updatedBookmarks }).then(
        () => {
          setBookmarks(updatedBookmarks);
          onSuccess && onSuccess();
        },
        () => {
          onFailed && onFailed();
        }
      );
    }
  };

  const toggleBookmark = ({ bookmark, onSuccess, onFailed, onStart }: any) => {
    if (isLoading) return;

    const isBookmarked = !!bookmarks.filter(
      ({ id }: any) => bookmark.id === id
    )[0];
    if (isBookmarked) {
      removeBookmark({ bookmark, onSuccess, onFailed, onStart });
    } else {
      addBookmark({ bookmark, onSuccess, onFailed, onStart });
    }
  };

  return {
    bookmarks: bookmarks || [],
    addBookmark,
    removeBookmark,
    toggleBookmark,
    isLoading,
  };
};

export default useBookmark;
