import BaseCommandBar from "../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar/methods";

type ReportsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onAdd: () => void;
};

export const CommandBar = ({ handleSearch, onAdd }: ReportsCommandBarProps) => {
  const items: CommandBarItemProps[] = [
    {
      key: "createReport",
      text: "Create report",
      className: "secondary-dropdown",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    },
  ];

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      onSearch={handleSearch}
    />
  );
};
