import type { IDialogProps, IDropdownOption } from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { format as fnsDateFormat } from "date-fns";
import type { FC } from "react";
import React, { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import { format } from "../../../../../utils";
import {
  getUsersOptions,
  useMachineUsersQuery,
  useLocationSearch,
  getFileIds,
  useFileDelete,
  useFileUpload,
} from "../../../../../Hooks";

import { FORMAT } from "../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import AddDocumentDialog from "../../../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import { notification } from "../../../../common/Notification";
import { getColumns } from "../../../MachineCVInfoPage/components/DocumentsTable/columns";
import type { TaskAddProps } from "../../hooks/useTaskMutations";
import { useTaskAdd } from "../../hooks/useTaskMutations";
import { TaskPriorities, TaskStatuses } from "../../../../../types";
import BaseDialog, { DialogSize } from "../../../../common/Dialog/Dialog";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../common/Form/FormItems/helpers";
import type { TableProps } from "../../../../common/Table";
import Table from "../../../../common/Table";
import type { UploadedFile } from "../../../../common/Uploader/types";
import UploaderButton from "../../../../common/Uploader/UploaderButton";

export const statusOptions: IDropdownOption[] = [
  { key: "ToDo", text: TaskStatuses.ToDo, data: "ToDo" },
  { key: "InProgress", text: TaskStatuses.InProgress, data: "InProgress" },
  { key: "Done", text: TaskStatuses.Done, data: "Done" },
  { key: "Cancelled", text: TaskStatuses.Cancelled, data: "Cancelled" },
];

export const priorityOptions: IDropdownOption[] = [
  { key: "Low", text: TaskPriorities.Low, data: "Low" },
  { key: "Medium", text: TaskPriorities.Medium, data: "Medium" },
  { key: "Important", text: TaskPriorities.Important, data: "Important" },
  { key: "Urgent", text: TaskPriorities.Urgent, data: "Urgent" },
];

const dialogAddContentProps = {
  type: DialogType.normal,
  title: "Add new task",
  closeButtonAriaLabel: "Close",
};

type TaskAddDialogProps = {
  onSuccess?: () => void;
  description?: string;
} & IDialogProps;

const schema = z
  .object({
    subject: z.string(),
    description: z
      .string()
      .max(512, {
        message: "Description must contain at most 512 character(s)",
      })
      .optional(),
    assignedTo: z.string(),
    priority: z.nativeEnum(TaskPriorities),
    startDate: z.date(),
    dueDate: z.date(),
  })
  .refine(
    (input) => {
      if (!input.startDate || !input.dueDate) {
        return true;
      }
      return input.startDate <= input.dueDate;
    },
    {
      path: ["startDate"],
      message: "The start date must be earlier than the end date.",
    }
  );

export const TaskAddDialog: FC<TaskAddDialogProps> = (props) => {
  const [{ id }] = useLocationSearch();

  const { hidden, onSuccess, description } = props;
  const [isDocumentsDialogHidden, setIsDocumentsDialogHidden] = useState(true);
  const toggleIsDocumentsDialogHidden = () =>
    setIsDocumentsDialogHidden(!isDocumentsDialogHidden);
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (hidden) {
      setFiles([]);
    }
    reset({ description });
  }, [hidden]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    ...(!!description && { defaultValues: { description } }),
    schema,
  });

  const { addTaskAsync } = useTaskAdd();
  const { uploadFilesAsync } = useFileUpload();
  const { deleteFilesAsync } = useFileDelete();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const uploadResponse = await uploadFilesAsync({
      files,
      machineId: id || "",
    });
    const successIds = getFileIds(uploadResponse);

    const submitData: TaskAddProps = {
      ...data,
      dueDate: fnsDateFormat(data.dueDate, FORMAT) as unknown as Date,
      startDate: fnsDateFormat(data.startDate, FORMAT) as unknown as Date,
      fileIds: successIds,
      machineId: id || "",
    };

    await addTaskAsync(submitData)
      .then(() => {
        if (uploadResponse.length === successIds.length) {
          notification.success("Task created successfully");
        } else {
          notification.success(
            `Task created successfully with ${successIds.length} out of ${uploadResponse.length} files`
          );
        }
        onSuccess?.();
        props.onDismiss?.();
      })
      .catch(() => {
        notification.error("Failed creating task");
        deleteFilesAsync(successIds);
      })
      .finally(() => setIsLoading(false));
  });

  const onClose = () => {
    props.onDismiss?.();
  };

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-add-task-documents",
        version: 1,
      },
      items: files,
      perPage: 5,
      hidePerPage: true,
      hasSelection: false,
      columns: getColumns({
        onRemove: (fileId) => {
          setFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
      }),
    }),
    [files]
  );

  const { usersList } = useMachineUsersQuery({ machineId: id as string });

  const taskFields: FormItemProps[] = [
    {
      name: "subject",
      type: FormItemType.TextField,
      groupProps: { label: "Subject" },
    },
    {
      name: "description",
      type: FormItemType.TextArea,
      resizable: true,
      groupProps: { label: "Description" },
    },
    {
      name: "assignedTo",
      type: FormItemType.Dropdown,
      groupProps: { label: "Assign to" },
      options: getUsersOptions(usersList),
    },
    {
      name: "startDate",
      type: FormItemType.DatePicker,
      groupProps: { label: "Start Date" },
      formatDate: (date) => format(date ?? new Date()),
    },
    {
      name: "dueDate",
      type: FormItemType.DatePicker,
      groupProps: { label: "Due Date" },
      formatDate: (date) => format(date ?? new Date()),
    },
    {
      name: "priority",
      type: FormItemType.Dropdown,
      groupProps: { label: "Priority" },
      options: priorityOptions,
    },
  ];

  return (
    <React.Fragment>
      <BaseDialog
        {...props}
        size={DialogSize.AUTO}
        dialogContentProps={dialogAddContentProps}
      >
        <form onSubmit={onSubmit}>
          {renderFormItems(taskFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <UploaderButton onClick={toggleIsDocumentsDialogHidden} />
          <Table {...tableProps} />
          <DialogFooter>
            <PrimaryButton
              type="submit"
              text="Save"
              disabled={isLoading}
              onRenderIcon={() =>
                isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
              }
            />
            <DefaultButton
              styles={{
                root: { border: "unset", background: "transparent" },
              }}
              text="Cancel"
              onClick={onClose}
            />
          </DialogFooter>
        </form>
      </BaseDialog>
      <AddDocumentDialog
        hidden={isDocumentsDialogHidden}
        onSubmit={(accepted) => setFiles([...files, ...accepted])}
        onClose={toggleIsDocumentsDialogHidden}
      />
    </React.Fragment>
  );
};
