import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { apiService } from '../../modules/common';
import type { PowerBiEmbed } from '../../types';

interface UsePowerBiArgs {
  id: string;
  type: PowerBiEmbed['parentType'];
  options?:
    | (Omit<
        UseQueryOptions<PowerBiEmbed, unknown, PowerBiEmbed, string[]>,
        'initialData' | 'queryFn' | 'queryKey'
      > & { initialData?: (() => undefined) | undefined })
    | undefined;
}

const usePowerBi = ({ id, type, options }: UsePowerBiArgs) =>
  useQuery(['powerbi', id], () => apiService.powerBi.getPowerBi({ id, type }), options);

export default usePowerBi;
