import { omit } from "lodash-es";

import DocumentIcon from "../../assets/svg/WirelessSensors/DocumentIcon";
import GearIcon from "../../assets/svg/WirelessSensors/GearIcon";

export const SENSOR_CONFIGURATOR = "sensor-configurator";

export const enum EntityType {
  Corporation = "Corporation",
  Company = "Company",
  Project = "Project",
  Gateway = "Gateway",
  Machine = "Machine",
  Sensor = "Sensor",
  SensorTriggering = "SensorTriggering",
}

export const parseTreeData = (
  data = [],
  hasWirelessSensorReaderPermissions = true
) => {
  return data?.map((corporation: any) => ({
    //corporations
    ...omit(corporation, "companies"),
    id: `corporation-${
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    }`,
    type: EntityType.Corporation,
    corporationId: corporation.id,
    ...(corporation.companies?.length
      ? {
          children: corporation.companies?.map((company: any) => ({
            //companies
            ...omit(company, "projects"),
            id: `company-${
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15)
            }`,
            corporationId: corporation.id,
            companyId: company.id,
            type: EntityType.Company,
            ...(company.projects?.length
              ? {
                  children: company.projects?.map((project: any) => ({
                    //projects
                    ...omit(project, "machines", "gateways"),
                    id: `project-${
                      Math.random().toString(36).substring(2, 15) +
                      Math.random().toString(36).substring(2, 15)
                    }`,
                    link: `${SENSOR_CONFIGURATOR}/project/${project.id}`,
                    type: EntityType.Project,
                    corporationId: corporation.id,
                    companyId: company.id,
                    projectId: project.id,
                    children: [
                      {
                        id: `Sensor Triggering UI-${
                          Math.random().toString(36).substring(2, 15) +
                          Math.random().toString(36).substring(2, 15)
                        }`,
                        name: "Sensor Triggering UI",
                        icon: <GearIcon />,
                        link: `${SENSOR_CONFIGURATOR}/project/${project.id}/sensor-triggering`,
                        type: EntityType.SensorTriggering,
                      },
                      ...(project.gateways?.length
                        ? [
                            {
                              name: "Gateways",
                              id: `Gateways-${
                                Math.random().toString(36).substring(2, 15) +
                                Math.random().toString(36).substring(2, 15)
                              }`,
                              children: project.gateways?.map(
                                (gateway: any) => ({
                                  ...gateway,
                                  id: `gateway-${
                                    Math.random()
                                      .toString(36)
                                      .substring(2, 15) +
                                    Math.random().toString(36).substring(2, 15)
                                  }`,
                                  gatewayId: gateway.id,
                                  type: EntityType.Gateway,
                                  corporationId: corporation.id,
                                  companyId: company.id,
                                  projectId: project.id,
                                  link: `${SENSOR_CONFIGURATOR}/project/${project.id}/gateway/${gateway.serialNumber}`,
                                  name: gateway.serialNumber,
                                  icon: <DocumentIcon />,
                                })
                              ),
                            },
                          ]
                        : []),
                      ...(project.machines?.length
                        ? [
                            {
                              id: `machine_project_${project.id}`,
                              name: "Machines",
                              children: project.machines?.map(
                                (machine: { id: any; sensorNodes: any[] }) => ({
                                  //machines
                                  ...omit(machine, "sensorNodes"),
                                  id: `machine-${
                                    Math.random()
                                      .toString(36)
                                      .substring(2, 15) +
                                    Math.random().toString(36).substring(2, 15)
                                  }`,
                                  type: EntityType.Machine,
                                  link: `${SENSOR_CONFIGURATOR}/machine/${machine.id}`,
                                  corporationId: corporation.id,
                                  companyId: company.id,
                                  projectId: project.id,
                                  ...(machine.sensorNodes?.length
                                    ? {
                                        children: machine.sensorNodes?.map(
                                          (sensor) => ({
                                            //sensors
                                            ...sensor,
                                            id: `sensor-${
                                              Math.random()
                                                .toString(36)
                                                .substring(2, 15) +
                                              Math.random()
                                                .toString(36)
                                                .substring(2, 15)
                                            }`,
                                            sensorId: sensor.id,
                                            name:
                                              sensor.sensorNo &&
                                              sensor.sensorNo.trim() !== "" &&
                                              hasWirelessSensorReaderPermissions
                                                ? sensor.sensorNo +
                                                  " (" +
                                                  sensor.sensorNodeId +
                                                  ") "
                                                : sensor.sensorNodeId,
                                            corporationId: corporation.id,
                                            companyId: company.id,
                                            projectId: project.id,
                                            machineId: machine.id,
                                            icon: <DocumentIcon />,
                                            link: `${SENSOR_CONFIGURATOR}/machine/${machine.id}/sensor/${sensor.sensorNodeId}`,
                                            type: EntityType.Sensor,
                                          })
                                        ),
                                      }
                                    : []),
                                })
                              ),
                            },
                          ]
                        : []),
                    ],
                  })),
                }
              : []),
          })),
        }
      : []),
  }));
};
