import type { IObjectWithKey } from '@fluentui/react';

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import { operationView } from '../DetailsSection/OperationsTab/config';
import { statusOptions } from '../SignalsSection/methods';
import type { ResponseSimplifiedSignalList } from '../../../../types';

export interface SummaryStore {
  operations: any;
  selectedSignals: ResponseSimplifiedSignalList;
  isGroupedEverything: boolean;
  setIsGroupedEverything: (checked: boolean | undefined) => void;
  isWarningModalOpen: boolean;
  setIsWarningModalOpen: (checked: boolean | undefined) => void;
  setSelectedSignals: (selectedSignals: IObjectWithKey[]) => void;
  addToSelectedSignals: (signals: IObjectWithKey[]) => void;
  removeFromSelectedSignals: (signals: IObjectWithKey[]) => void;
  signalsStatus: any;
  setSignalsStatus: (signalsStatus: any) => void;
  updateOperations: (operations: any) => void;
}

const useSummaryStore = createWithEqualityFn<SummaryStore>((set) => ({
  selectedSignals: [],
  signalsStatus: statusOptions
    .map((item) => item.key)
    .filter(
      (option) => !['NotInstalled', 'NoStatus', 'Faulty', 'selectAll'].includes(option as string),
    ),
  operations: {
    loadingSignals: true,
    signal: undefined,
    view: operationView[0],
    year: undefined,
    month: undefined,
  },
  isGroupedEverything: false,
  setIsGroupedEverything: (checked) => set(() => ({ isGroupedEverything: checked })),
  isWarningModalOpen: false,
  setIsWarningModalOpen: (checked) => set(() => ({ isWarningModalOpen: checked })),
  updateOperations: (operations) =>
    set((store) => ({ operations: { ...store.operations, ...operations } })),
  setSelectedSignals: (selectedSignalsInput: IObjectWithKey[]) => {
    set(() => ({ selectedSignals: selectedSignalsInput as ResponseSimplifiedSignalList }));
  },
  addToSelectedSignals: (signals: IObjectWithKey[]) =>
    set((store) => {
      const existingKeys = new Set(store.selectedSignals.map((signal) => signal.id));
      const newSignals = signals.filter((signal) => !existingKeys.has(signal.key as string));
      return {
        selectedSignals: [...store.selectedSignals, ...newSignals] as ResponseSimplifiedSignalList,
      };
    }),
  removeFromSelectedSignals: (signals: IObjectWithKey[]) =>
    set((store) => ({
      selectedSignals: store.selectedSignals.filter(
        (signal) => !signals.some((s) => s.key === signal.id),
      ) as ResponseSimplifiedSignalList,
    })),
  setSignalsStatus: (signalsStatus: any) => set(() => ({ signalsStatus })),
}), shallow);

export default useSummaryStore;
