import type { IDropdownOption } from "@fluentui/react";
import { useMemo } from "react";

import useThreadsStore, { ThreadCreated } from "../hooks/useThreadsStore";

import BaseCommandBar from "../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar/methods";

type DocumentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onFilter: (days: number) => void;
};

export const threadCreatedDropdown: IDropdownOption[] = [
  { key: 1, text: ThreadCreated.Today },
  { key: 7, text: ThreadCreated.PastWeek },
  { key: 30, text: ThreadCreated.PastMonth },
  { key: 365, text: ThreadCreated.PastYear },
  { key: 0, text: ThreadCreated.AllTime },
];

export const CommandBar = ({
  handleSearch,
  onFilter,
}: DocumentsCommandBarProps) => {
  const onDelete = () => dispatch({ type: "SHOW_DELETE", payload: true });
  const onAdd = () => dispatch({ type: "SHOW_ADD", payload: true });

  const { selectedItems, dispatch } = useThreadsStore((state) => ({
    selectedItems: state.selectedItems as any[],
    dispatch: state.dispatch,
  }));

  const items: CommandBarItemProps[] = useMemo(
    () => [
      {
        key: "newThread",
        text: "New thread",
        type: CommandBarItemType.Button,
        iconProps: { iconName: "Add" },
        onClick: onAdd,
      },
      {
        key: "createdFilter",
        type: CommandBarItemType.Dropdown,
        onRenderProps: {
          defaultSelectedKey: threadCreatedDropdown[4].key,
          options: threadCreatedDropdown,
          onChange: (_: never, item: IDropdownOption) =>
            onFilter(item.key as number),
          onRenderLabelProps: {
            label: "Time",
            icon: "Clock",
          },
        },
      },
      {
        key: "delete",
        text: "Delete",
        type: CommandBarItemType.Button,
        iconProps: { iconName: "Delete" },
        onClick: onDelete,
        ...(selectedItems.length === 0 && {
          disabled: true,
        }),
      },
    ],
    [selectedItems.length]
  );

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      onSearch={handleSearch}
    />
  );
};
