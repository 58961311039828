/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";

import RawDataSignalIcon from "../../../../../../assets/svg/RawDataSignalIcon";
import { format } from "../../../../../../utils";
import { TrendViewPlotSpinner } from "../../../../../../modules/analysis-trend-view/components/TrendViewPlotPlaceholders";
import useDriveLoadStore from "../../../hooks/useDriveLoadStore";
import { useSignalsDataBlocks } from "../../../hooks/useSignalsDataBlocks";
import type { ResponseSimplifiedSignal } from "../../../../../../types";
import { NoData } from "../../../../../common/NoData";
import type {
  XYChartRef,
  XYChartStyles,
} from "../../../../../common/XYChart/XYChart";

import MopChartContainer from "../MopChartContainer";
import SignalsRawChart from "./SignalsRawChart";

type MopRawChartsProps = React.HTMLAttributes<HTMLDivElement> & {
  machineId: string;
  signals: ResponseSimplifiedSignal[];
  selectedSignals: ResponseSimplifiedSignal[];
  onSelectedChange: (signals: ResponseSimplifiedSignal[]) => void;
  chartStyles?: XYChartStyles;
};

const MopRawChart = ({
  machineId,
  signals,
  selectedSignals,
  onSelectedChange,
  chartStyles,
  ...rest
}: MopRawChartsProps) => {
  const ref = useRef<XYChartRef | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { driveLoad, period, selectedTableEvent } = useDriveLoadStore(
    (store: any) => ({
      period: store.period,
      driveLoad: store.driveLoad,
      selectedTableEvent: store.selectedTableEvent,
    })
  );

  const { data, isLoading, selectedMetadata } = useSignalsDataBlocks({
    eventId: driveLoad?.eventId,
    signals: selectedSignals,
    period,
    eventDate: selectedTableEvent?.timeStamp,
  });

  // Refreshes the chart.
  useEffect(() => {
    !refresh && setRefresh(true);
  }, [data]);

  let content: JSX.Element | null = null;
  if (isLoading) {
    content = (
      <TrendViewPlotSpinner
        text={"Fetching new data..."}
        styles={{ root: { height: "100%", width: "100%", boxShadow: "none" } }}
      />
    );
  } else if (!selectedSignals || selectedSignals.length === 0) {
    content = <NoData className="m-a" text="No signals selected" />;
  } else if (!data || data.size === 0) {
    content = <NoData className="m-a" />;
  } else {
    content = (
      <SignalsRawChart
        {...rest}
        ref={ref}
        key={`signals-raw-chart-${machineId}`}
        className="grouped-chart-container"
        data={data}
        refresh={refresh}
        styles={chartStyles}
        onDrawFinished={() => setRefresh(false)}
      />
    );
  }

  return (
    <MopChartContainer
      key={`signals-raw-chart-checkboxes-${machineId}`}
      floatingText={
        selectedMetadata.size > 0
          ? format(
              new Date(Array.from(selectedMetadata.values()).at(0)!.timeStamp),
              "dd MMM yyyy HH:mm"
            )
          : undefined
      }
      signals={signals}
      selectedSignals={selectedSignals}
      icon={RawDataSignalIcon}
      chart={ref?.current?.chart}
      onSelectedChanged={onSelectedChange}
    >
      {content}
    </MopChartContainer>
  );
};

export default MopRawChart;
