import type { Scrollbar } from '@amcharts/amcharts5';
import * as am5 from '@amcharts/amcharts5';
import type * as am5xy from '@amcharts/amcharts5/xy';

const scrollbarColor = am5.color(0x2c529f);

const scrollbarBulletSettings = {
  fill: scrollbarColor,
  stroke: am5.color(0xffffff),
  strokeWidth: 3,
  width: 10,
  height: 10,
  dx: 10,
  dy: 8,
  scale: 0.8,
};

export const createScrollbar = (
  root: am5.Root,
  chart: am5xy.XYChart,
  settings: {
    type: 'scrollbarX' | 'scrollbarY';
    orientation: 'horizontal' | 'vertical';
    minBullet?: am5.IButtonSettings;
    thumb?: am5.IRoundedRectangleSettings;
    scrollbarTransformer?: (scrollbar: Scrollbar, axis: any) => void;
    position?: any;
  },
) => {
  const scrollbar = chart.set(
    settings.type,
    am5.Scrollbar.new(root, {
      exportable: false,
      orientation: settings.orientation,
      maxHeight: settings.type === 'scrollbarX' ? 3 : undefined,
      maxWidth: settings.type === 'scrollbarY' ? 3 : undefined,
      background: am5.Rectangle.new(root, {
        fill: am5.color(0xf3f2f1),
        fillOpacity: 0.5,
      }),
      start: settings.position?.start,
      end: settings.position?.end,
    }),
  );
  scrollbar.startGrip.setAll({
    background: am5.Circle.new(root, {
      ...scrollbarBulletSettings,
    }),
  });

  scrollbar.endGrip.setAll({
    background: am5.Circle.new(root, {
      ...scrollbarBulletSettings,
    }),
    ...settings.minBullet,
  });

  scrollbar.thumb.setAll({
    ...settings.thumb,
    fill: scrollbarColor,
  });

  settings.scrollbarTransformer?.(scrollbar, settings.type === 'scrollbarX' ? 'x' : 'y');

  scrollbar.startGrip.children.dispose();
  scrollbar.endGrip.children.dispose();
};
