import { z } from 'zod';

export const defaultTriggersValues = {
  manualKey: '1',
  triggers: [
    { intervalInMinutes: '', enabledMeasurementSettings: [], enabled: false },
    { intervalInMinutes: '', enabledMeasurementSettings: [], enabled: false },
    { intervalInMinutes: '', enabledMeasurementSettings: [], enabled: false },
  ],
};

export const triggersSchema = z.object({
  manualKey: z.any(),
  triggers: z.array(
    z.object({
      intervalInMinutes: z.any(),
      enabledMeasurementSettings: z.any(),
      enabled: z.any(),
    }),
  ),
});
