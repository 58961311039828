import type { IToggleProps } from '@fluentui/react';
import { Toggle } from '@fluentui/react';
import { Controller } from 'react-hook-form';

import type { BaseFormFieldProps } from '../types';
import { FormGroup } from './FormGroup';

export type FormToggleProps = BaseFormFieldProps &
  IToggleProps & {
    name: string;
  };

export const FormToggle = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control },
  ...rest
}: FormToggleProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Toggle
            offText='Off'
            styles={{ root: { margin: 0 } }}
            checked={value}
            onText='On'
            onChange={(_, checked) => {
              onChange(checked);
            }}
            onBlur={onBlur}
            {...rest}
            label={undefined}
          />
        );
      }}
    />
  </FormGroup>
);
