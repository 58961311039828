import dayjs from 'dayjs';

import type { ResponseSimplifiedSignal } from '../../../types'

import TrendPeriodEnum from '../constants/TrendPeriodEnum';

export const FORMAT = "Y-MM-dd'T'HH:mm:ss.SSS";
export const SHORT_FORMAT = 'Y-MM-dd';

const DAY_JS_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export type GroupedSignalsPeriod = {
  key?: TrendPeriodEnum;
  startDate: string;
  endDate: string;
};

const max = (signalStart: string, periodStart: string) => {
  const signalStartMS = new Date(signalStart).getTime();
  const periodStartMS = new Date(periodStart).getTime();

  return signalStartMS > periodStartMS ? signalStart : periodStart;
};

const getPeriodFilters = (
  period: GroupedSignalsPeriod,
  signal: ResponseSimplifiedSignal = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataFrom: new Date(null).toISOString(),
    dataUntil: new Date().toISOString(),
  } as unknown as ResponseSimplifiedSignal,
) => {
  const periodKey = period?.key;

  const signalStartDateFormatted = dayjs(signal.dataFrom).format(DAY_JS_FORMAT);
  const signalEndDateFormatted = dayjs(signal.dataUntil).format(DAY_JS_FORMAT);

  switch (periodKey) {
    case TrendPeriodEnum.LAST_WEEK: {
      const oneWeekBeforeFormatted = dayjs(signalEndDateFormatted)
        .subtract(1, 'week')
        .format(DAY_JS_FORMAT);

      return {
        startDate: max(signalStartDateFormatted, oneWeekBeforeFormatted),
        endDate: signalEndDateFormatted,
      };
    }
    case TrendPeriodEnum.LAST_MONTH: {
      const oneMonthBeforeFormatted = dayjs(signalEndDateFormatted)
        .subtract(1, 'month')
        .format(DAY_JS_FORMAT);

      return {
        startDate: max(signalStartDateFormatted, oneMonthBeforeFormatted),
        endDate: signalEndDateFormatted,
      };
    }
    case TrendPeriodEnum.LAST_QUARTER: {
      const oneQuarterBeforeFormatted = dayjs(signalEndDateFormatted)
        .subtract(3, 'months')
        .format(DAY_JS_FORMAT);

      return {
        startDate: max(signalStartDateFormatted, oneQuarterBeforeFormatted),
        endDate: signalEndDateFormatted,
      };
    }
    case TrendPeriodEnum.LAST_YEAR: {
      const oneYearBeforeFormatted = dayjs(signalEndDateFormatted)
        .subtract(1, 'year')
        .format(DAY_JS_FORMAT);

      return {
        startDate: max(signalStartDateFormatted, oneYearBeforeFormatted),
        endDate: signalEndDateFormatted,
      };
    }
    case TrendPeriodEnum.LAST_2_YEARS: {
      const twoYearsBeforeFormatted = dayjs(signalEndDateFormatted)
        .subtract(2, 'years')
        .format(DAY_JS_FORMAT);

      return {
        startDate: max(signalStartDateFormatted, twoYearsBeforeFormatted),
        endDate: signalEndDateFormatted,
      };
    }
    case TrendPeriodEnum.CUSTOM_DATE: {
      return {
        startDate: period.startDate,
        endDate: period.endDate,
      };
    }
    case TrendPeriodEnum.ALL_DATA: {
      return {
        startDate: signalStartDateFormatted,
        endDate: signalEndDateFormatted,
      };
    }
    default: {
      return {
        startDate: '',
        endDate: '',
      };
    }
  }
};

export default getPeriodFilters;
