import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react';
import { isNaN } from 'lodash-es';
import React from 'react';

import type { TrendViewRawDetails } from '../../types';

interface TrendViewInfoProps {
  rawDetails: TrendViewRawDetails;
  referenceSignalRawDetails: TrendViewRawDetails | undefined;
}

const allDataColumns = [
  {
    key: 'All data',
    fieldName: 'All data',
    name: 'All data',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'Signal',
    fieldName: 'Signal',
    name: 'Signal',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'Reference',
    fieldName: 'Reference',
    name: 'Reference',
    minWidth: 100,
    maxWidth: 100,
  },
];

const statisticsDataColumns = [
  {
    key: 'Current view',
    fieldName: 'Current view',
    name: 'Current view',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'Signal',
    fieldName: 'Signal',
    name: 'Signal',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'Reference',
    fieldName: 'Reference',
    name: 'Reference',
    minWidth: 100,
    maxWidth: 100,
  },
];

const TrendViewInfo: React.FC<TrendViewInfoProps> = ({ rawDetails, referenceSignalRawDetails }) => {
  return (
    <div
      style={{
        backgroundColor: '#FAF9F8',
      }}
    >
      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        columns={allDataColumns}
        items={[
          {
            'All data': 'Last',
            Signal: rawDetails.all.last,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.last : '-',
          },
          {
            'All data': 'Min',
            Signal: rawDetails.all.minimum,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.minimum : '-',
          },
          {
            'All data': 'Max',
            Signal: rawDetails.all.maximum,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.maximum : '-',
          },
          {
            'All data': 'Avg',
            Signal: rawDetails.all.average,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.average : '-',
          },
          {
            'All data': 'StDev',
            Signal: rawDetails.all.stDev,
            Reference: referenceSignalRawDetails ? referenceSignalRawDetails.all.stDev : '-',
          },
          {
            'All data': 'R Corr',
            Signal: isNaN(rawDetails.all.rCorr) ? '-' : rawDetails.all.rCorr,
            Reference: '-',
          },
        ]}
      />
      <DetailsList
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        columns={statisticsDataColumns}
        items={[
          {
            'Current view': 'Last',
            Signal: rawDetails.selectedRange.last,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.last
              : '-',
          },
          {
            'Current view': 'Min',
            Signal: rawDetails.selectedRange.minimum,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.minimum
              : '-',
          },
          {
            'Current view': 'Max',
            Signal: rawDetails.selectedRange.maximum,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.maximum
              : '-',
          },
          {
            'Current view': 'Avg',
            Signal: rawDetails.selectedRange.average,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.average
              : '-',
          },
          {
            'Current view': 'StDev',
            Signal: rawDetails.selectedRange.stDev,
            Reference: referenceSignalRawDetails
              ? referenceSignalRawDetails.selectedRange.stDev
              : '-',
          },
          {
            'Current view': 'R Corr',
            Signal: isNaN(rawDetails.selectedRange.rCorr) ? '-' : rawDetails.selectedRange.rCorr,
            Reference: '-',
          },
        ]}
      />
    </div>
  );
};

export default TrendViewInfo;
