/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect } from "react";
import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../common/Form";
import BaseDialog from "../common/Dialog";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  IComboBoxOption,
} from "@fluentui/react";
import { z } from "zod";
import { Company } from "./models";
import { maxLengthType1 } from "../../schema/Constants";
import { addCompany, editCompany } from "./api";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import { selectCorporationDetailsStatus } from "../Corporations/CorporationDetails/reducer";
import { listAsyncCorpo } from "../Corporations/reducer";
import type { FieldError } from "react-hook-form";
import FormItemRow from "../Generic/FormItemRow";
import ControlledComboBox from "../Generic/ControlledComboBox";
import { areObjectsEqual } from "../../schema/Utils";

const getSchema = (companies: Company[]) =>
  z
    .object({
      name: z
        .string()
        .min(1, { message: "This field is required" })
        .max(maxLengthType1, {
          message: `Name must contain at most ${maxLengthType1} character(s)`,
        }),
      number: z
        .string()
        .max(maxLengthType1, {
          message: `Number must contain at most ${maxLengthType1} character(s)`,
        })
        .optional(),
    })
    .superRefine((input, ctx) => {
      let issueData: z.IssueData = {
        code: z.ZodIssueCode.custom,
        path: [],
        message: "",
      };

      // Name
      if (input.name && input.name.length > 0) {
        let existingCompany = companies.find(
          (c) => c.name.trim().toLowerCase() === input.name.trim().toLowerCase()
        );
        if (existingCompany) {
          issueData = {
            ...issueData,
            path: ["name"],
            message: "The company name already exists.",
          };

          ctx.addIssue(issueData);
        }
      }

      // Number
      if (input.number && input.number.length > 0) {
        let existingCompany = companies.find(
          (c) => c.number === input.number.trim()
        );
        if (existingCompany) {
          issueData = {
            ...issueData,
            path: ["number"],
            message: "The number already exists.",
          };
          ctx.addIssue(issueData);
        }
      }
    });

type AddOrEditDialogProps = IDialogProps & {
  options: IComboBoxOption[];
  data: Company | null;
  items: Company[];
  show: boolean;
  onSuccess: (
    hasError: boolean,
    data: Company,
    context: "add" | "edit"
  ) => void;
  onClose: () => void;
};

export const AddOrEditDialog = ({
  options,
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(
    data === null || data === undefined
  );
  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ name }) =>
        data
          ? name.trim().toLowerCase() !== data.name.trim().toLowerCase()
          : true
      ),
    [items, data]
  );
  const schema = useMemo(() => getSchema(filteredItems), [filteredItems]);
  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCorporationDetailsStatus);
  const dispatch = useAppDispatch();
  const [idSelected, setIdSelected] = useState<string>(
    data ? data.corporationId : ""
  );
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: { name: data?.name || "", number: data?.number || "" },
  });

  useEffect(() => {
    if (statusParent !== Status.idle) dispatch(listAsyncCorpo());
  }, [dispatch, statusParent]);

  useEffect(() => {
    data ? reset(data) : reset({ name: "", number: "" });
  }, [data, reset]);

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    let areEqual =
      areObjectsEqual(control._defaultValues, control._formValues) &&
      data?.corporationId === idSelected;
    setDataHasChanged(!areEqual);
  }, [watch(), idSelected]);

  const companiesFields: FormItemProps[] = [
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: "Name *" },
    },
    {
      name: "number",
      type: FormItemType.TextField,
      groupProps: { label: "Number" },
    },
  ];

  // Handlers
  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: Company = {
      corporationId: idSelected,
      id: data && data.id,
      name: formData.name,
      number: formData.number,
    };
    const mutation = data ? editCompany : addCompany;
    await mutation(toSend).then((response) =>
      onSuccess(
        "status" in response,
        formData as Company,
        data ? "edit" : "add"
      )
    );

    handleClose();
  });

  const handleClose = () => {
    // reset state
    setLoading(false);
    setIdSelected(undefined);
    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: data ? "Edit new company" : "Add new company",
        closeButtonAriaLabel: "Close",
        onDismiss: handleClose,
      }}
    >
      <form onSubmit={onSubmit}>
        <FormItemRow label="Corporation: *">
          <ControlledComboBox
            options={options}
            selectedKey={idSelected}
            disabled={false}
            onKeySelected={(key: string) => setIdSelected(key)}
          />
        </FormItemRow>
        {renderFormItems(companiesFields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text="Save Changes"
            disabled={isLoading || !isValid || !idSelected || !dataHasChanged}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text="Cancel"
            onClick={handleClose}
          />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};
