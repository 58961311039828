import { Label, p50, p100 } from '@amcharts/amcharts5';
import { format } from 'date-fns';

import { getClosestIndex } from '../../../../../utils';
import { torqueSignalsChartColors } from './config';

export const createTorqueTooltip = ({ ref, type = 'number' }: any) => {
  const { root, chart } = ref;

  const cursor = chart.get('cursor');

  const tooltipLabel = Label.new(root, {
    ariaLabel: 'chart-tooltip',
    html: '',
    centerX: p50,
    centerY: p100,
    x: p50,
    y: p100,
    visible: false,
  });

  chart.plotContainer.children?.each((item: any) => {
    if (item instanceof Label && item.get('ariaLabel') === 'chart-tooltip') {
      item.dispose();
    }
  });

  chart.plotContainer.children.push(tooltipLabel);

  const updateTooltip = (
    xValue: number,
    yValue: number,
    groupedValues: Record<string, string[]>,
    iconsValues: Record<string, string[]>,
  ) => {
    let tooltipLabelText = '';

    Object.entries(groupedValues).forEach(([unit, values]) => {
      const formattedValues = values.map((value, index) => {
        const src = iconsValues[unit][index] || '';
        return `
          <span class="chart-tooltip__content">
            <img src="${src}"/>
            ${parseFloat((+value).toFixed(4))}
          </span>`;
      });

      tooltipLabelText += `
        <div class="chart-tooltip__label">
          <strong>${unit || 123}:</strong>
          ${formattedValues.join('')}
        </div>`;
    });

    const tooltipValue =
      type === 'date' ? format(new Date(xValue), 'dd MMM yyyy HH:mm') : xValue.toFixed(4);

    const htmlContent = `<div class="chart-tooltip">${tooltipLabelText}  <strong>(t):</strong> ${tooltipValue}<br/> <strong>(val):</strong> ${yValue.toFixed(
      4,
    )}</div>`;

    tooltipLabel.set('html', htmlContent);
    tooltipLabel.set('visible', true);
  };

  cursor?.events.on('cursormoved', () => {
    const xAxis = chart.xAxes.getIndex(0);
    const yAxis = chart.yAxes.getIndex(0);

    const selectedXPosition = cursor?.getPrivate('positionX') as number;
    const selectedYPosition = cursor?.getPrivate('positionY') as number;

    const xValue = xAxis?.positionToValue(xAxis?.toAxisPosition(selectedXPosition));
    const yValue = yAxis?.positionToValue(yAxis?.toAxisPosition(selectedYPosition));
    const groupedValues: Record<string, string[]> = {};
    const iconsValues: Record<string, string[]> = {};

    chart?.series?.each((serie: any, index: any) => {
      const unit = serie?._settings?.unit || '';

      const dataItemIndex = chart?.series
        ?.getIndex(index)
        ?.dataItems?.findIndex((item: any) => item?.get('valueX') >= xValue);

      if (dataItemIndex !== -1) {
        groupedValues[unit] = groupedValues[unit] ?? [];
        iconsValues[unit] = iconsValues[unit] ?? [];

        iconsValues[unit].push(torqueSignalsChartColors[index]?.icon);

        const closestIdx = getClosestIndex({
          foundIndex: dataItemIndex as number,
          threshold: xValue,
          length: chart?.series?.getIndex(index)?.dataItems.length ?? 0,
          getItem: (idx) => chart?.series?.getIndex(index)?.dataItems?.at(idx)?.get('valueX') ?? -1,
        });

        groupedValues[unit].push(
          `${chart?.series?.getIndex(index)?.dataItems?.at(closestIdx)?.get('valueY')}`,
        );
      }
    });

    updateTooltip(xValue, yValue, groupedValues, iconsValues);
  });

  cursor?.events.on('cursorhidden', () => {
    tooltipLabel.set('visible', false);
  });
};
