enum TrendPeriodEnum {
  LAST_WEEK = 'Last week',
  LAST_MONTH = 'Last month',
  LAST_QUARTER = 'Last quarter',
  LAST_YEAR = 'Last year',
  LAST_2_YEARS = 'Last 2 years',
  ALL_DATA = 'All data',
  CUSTOM_DATE = 'Custom date',
}

export default TrendPeriodEnum;
