import "./style.scss";
import { useCallback } from "react";
import type { UploadedFile } from "../Uploader/types";
import { useFilePreview } from "./useFilePreview";
import { FileType } from ".";
import { useFileDownload } from "../../../Hooks/useFile";
import { Link, ILinkProps } from "@fluentui/react";

export type CommentFileProps = {
  file: UploadedFile;
  className?: string;
  useBasicLayout?: boolean;
};

export const File = ({
  file,
  className = "file-name",
  useBasicLayout = false,
}: CommentFileProps) => {
  const { openPreview, PreviewModal } = useFilePreview(
    useBasicLayout ? useBasicLayout : false
  );
  const { getFile } = useFileDownload();

  const handlePreview = useCallback<Required<ILinkProps>["onClick"]>(
    async (ev) => {
      if (ev) {
        ev.preventDefault();
        ev.stopPropagation();
      }

      if (file.isValid) return;

      try {
        const fileResponse = await getFile({ id: file.id });
        const fileType =
          fileResponse.data.type === "application/pdf"
            ? FileType.Pdf
            : FileType.Img;
        openPreview(file.id, file.name, fileType);
      } catch (error) {
        console.error("Error getting file type:", error);
        openPreview(file.id, file.name, FileType.Img);
      }
    },
    [file, getFile, openPreview]
  );

  return (
    <>
      <Link
        className={className}
        onClick={handlePreview}
        style={{ cursor: "pointer" }}
      >
        {file.name}
      </Link>
      <PreviewModal />
    </>
  );
};
