import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";


import { breadcrumbDefaultResponse } from '../modules/common/services/api/MachineCVBreadcrumb';
import type { BreadcrumbInterface } from '../types';

import useMachineCVBreadcrumb from './api/useMachineCVBreadcrumb';
import { useLocationSearch } from './useLocationSearch';

type useReadyPermissionArgs = {
  permissionRoles: string | string[];
  machineId?: string;
};

interface PermissionsStore {
  permissions: any;
  setPermissions: (permissions: object) => void;
  useReadyPermission: ({ permissionRoles, machineId }: useReadyPermissionArgs) => boolean;
  useReadyPermissionGeneral: (permissionRoles: any) => boolean;
  checkCreatorUserIDMatch: (id: string) => boolean;
}

function hasRole(permissions: PermissionExpanded[], roles: string[]): boolean {
  for (const permission of permissions) {
    if (roles.includes(permission.role)) {
      return true;
    }
  }
  return false;
}

interface PermissionExpanded {
  id: string;
  role: string;
  scope: string;
  scopeResource: string | null;
  expiresAtUtc: Date | null;
}

// For testing permission purposes
// const initialPermissions: PermissionExpanded[] = [
//   {
//     id: '08ce0bb3-fb8e-484d-b974-1091207b9fb4',
//     role: 'WirelessSensorContributor',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: '613b85f0-29b7-4ab3-afe3-1554ec424e3c',
//     role: 'ImageContributor',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: '73b05d01-6e68-4d79-96f2-15d4217aa913',
//     role: 'PowerBiContributor',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: 'a6a1bfcb-64c3-4791-8b97-193008e2d48c',
//     role: 'MachineCvAdministrator',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: '836c40bb-4f84-4bce-a615-689c613e481f',
//     role: 'MeasuredDataContributor',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: 'f0e1c2ff-1b58-423f-b053-704b87e6d820',
//     role: 'MeasuredDataTier2Reader',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: 'f464e33a-2883-4460-a2aa-944865e94339',
//     role: 'AuditReader',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: '62e00b4e-ff0d-4b93-a42e-a7ea2af3ff0c',
//     role: 'MachineCvContributor',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: '3e0126dc-ac6b-43d3-9413-ce254b05592e',
//     role: 'SubmissionAdministrator',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: 'd366c3a4-51b1-4ed8-9fed-d43a190bf261',
//     role: 'MetaDataContributor',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
//   {
//     id: '623c7d9c-6966-48e5-b2c1-fefc9bbddc4f',
//     role: 'UserAdministrator',
//     scope: 'Root',
//     scopeResource: null,
//     expiresAtUtc: null,
//   },
// ];

const useWebAnalysisPermissions = createWithEqualityFn<PermissionsStore>((set, get) => ({
  permissions: null,
  setPermissions: (permissions) => set(() => ({ permissions })),
  useReadyPermission: ({ permissionRoles, machineId = '' }) => {
    const data = get()?.permissions?.data || [];
    const loading = !!get()?.permissions?.loading;

    const [{ id: locationId }, ,] = useLocationSearch();
    const id = machineId || locationId;

    const { data: breadcrumbResponse = breadcrumbDefaultResponse, isLoading: isBreadcrumbLoading } =
      useMachineCVBreadcrumb({
        machineId: id || '',
        options: { enabled: !!id, retry: 0 },
      });

    if (loading || isBreadcrumbLoading) return false;

    const _permissionRoles = Array.isArray(permissionRoles) ? permissionRoles : [permissionRoles];

    const isPermissionRootScoped = data?.find(
      ({ role, scope }: any) => _permissionRoles.includes(role) && scope === 'Root',
    );

    if (isPermissionRootScoped) return true;

    const breadcrumbNames = Object.keys(breadcrumbResponse).map(
      (key) => breadcrumbResponse?.[key as keyof BreadcrumbInterface]?.name,
    );

    const apiPermission = data.find((item: any) => {
      const scopeResource = item?.scopeResource || '';
      const role = item?.role || '';

      const hasScopeAccess = scopeResource
        .split(', ')
        .every((term: string) => breadcrumbNames.includes(term));

      return _permissionRoles.includes(role) && hasScopeAccess;
    });

    return !!apiPermission;
  },
  useReadyPermissionGeneral: ({ permissionRoles }) => {
    const data = get()?.permissions?.data || [];
    // const data = initialPermissions;
    const _permissionRoles = Array.isArray(permissionRoles) ? permissionRoles : [permissionRoles];

    return hasRole(data, _permissionRoles);
  },
  checkCreatorUserIDMatch: (id) => {
    const accountInfo = JSON.parse(sessionStorage?.accountInfo || '{}');
    const userId = accountInfo?.memberId || 'web-analysis-net';

    return userId === id;
  },
}), shallow);

export default useWebAnalysisPermissions;
