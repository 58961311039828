/* eslint-disable react-hooks/exhaustive-deps */
import {
  DatePicker,
  defaultDatePickerStrings,
  DialogType,
} from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";

import FormDialog, { FormDialogProps } from "../../../Generic/FormDialog";
import MachineFormItems from "../../../Generic/MachineFormItems";
import { getMachineDetails } from "../../../Machines/MachineDetails/api";
import { Image, MachineToList } from "../../../Machines/models";
import { DownloadImagesProgressDialog } from "./DownloadImagesProgressDialog";
import { notification } from "../../../common/Notification";
import { DialogSize } from "../../../common/Dialog";

type FormData = {
  machine?: MachineToList;
  from?: Date;
  to?: Date;
};

type DateLimitations = {
  minDate?: Date;
  maxDate?: Date;
};

type DownloadImagesDialogProps = Omit<
  FormDialogProps,
  | "title"
  | "isLoading"
  | "isValid"
  | "type"
  | "size"
  | "onSubmit"
  | "submitButtonText"
>;

const sortByAscending = (a: Image, b: Image) => {
  if (a.dataUntil > b.dataUntil) return 1;
  else if (a.dataUntil < b.dataUntil) return -1;

  return 0;
};

const DownloadImagesDialog = ({ ...rest }: DownloadImagesDialogProps) => {
  const [formData, setFormData] = useState<FormData>({});
  const [limitations, setLimitations] = useState<DateLimitations>({
    maxDate: new Date(Date.now()),
  });
  const [images, setImages] = useState<Image[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDownload, setStartDownload] = useState<boolean>(false);
  const disabled: boolean = useMemo(() => {
    return isLoading || !images || images.length === 0;
  }, [isLoading, images]);

  const isValid: boolean = useMemo(() => {
    return (
      formData.machine !== undefined &&
      formData.from !== undefined &&
      formData.to !== undefined &&
      images?.length > 0 &&
      disabled === false
    );
  }, [formData, images, disabled]);

  // Gets the machine details, to set the dates limitations
  useEffect(() => {
    if (!formData.machine) {
      return;
    }

    let mount = true;
    setIsLoading(true);
    getMachineDetails(formData.machine.id).then((response) => {
      if (!mount) {
        return;
      }

      const newImages = response.images?.sort(sortByAscending);
      if (!newImages || newImages.length === 0) {
        return;
      }

      const maxDate = new Date(newImages.at(newImages.length - 1).dataUntil);
      const minDate = new Date(newImages.at(0).dataUntil);
      const now = new Date(Date.now());

      setIsLoading(false);
      setFormData({
        ...formData,
        from: minDate,
        to: maxDate <= now ? maxDate : now,
      });
      setLimitations({ minDate, maxDate });
      setImages(newImages);
    });

    // Cleans all resources
    return () => {
      mount = false;
    };
  }, [formData.machine?.id]);

  // Handlers
  const onMachineSelected = (machine?: MachineToList) =>
    setFormData({ ...formData, machine });

  const onFromChange = (date: Date | null | undefined) => {
    if (!date) {
      return;
    }

    setFormData({ ...formData, from: date });
  };

  const onToChange = (date: Date | null | undefined) => {
    if (!date) {
      return;
    }

    setFormData({ ...formData, to: date });
  };

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    const imagesToDownload = images.filter(
      (i) =>
        new Date(i.dataUntil) >= formData.from &&
        new Date(i.dataUntil) <= formData.to
    );
    if (imagesToDownload.length === 0) {
      notification.warning(
        "There are no images available for the selected time range."
      );
      return;
    }

    setImages(imagesToDownload);
    setStartDownload(true);
  };

  return (
    <React.Fragment>
      <FormDialog
        {...rest}
        title={"Download Images"}
        isLoading={false}
        isValid={isValid}
        type={DialogType.normal}
        size={DialogSize.S}
        onSubmit={onSubmit}
        submitButtonText={"Start Download"}
      >
        <MachineFormItems onMachineSelected={onMachineSelected} />
        <DatePicker
          disabled={disabled}
          label="From"
          ariaLabel="Select a date"
          placeholder="Select a date..."
          strings={defaultDatePickerStrings}
          value={formData.from}
          minDate={limitations.minDate}
          maxDate={formData.to}
          onSelectDate={onFromChange}
        />
        <DatePicker
          disabled={disabled}
          label="To"
          ariaLabel="Select a date"
          placeholder="Select a date..."
          strings={defaultDatePickerStrings}
          value={formData.to}
          minDate={formData.from}
          maxDate={limitations.maxDate}
          onSelectDate={onToChange}
        />
      </FormDialog>
      {startDownload === true && (
        <DownloadImagesProgressDialog
          images={images}
          onClose={() => setStartDownload(false)}
        />
      )}
    </React.Fragment>
  );
};

export default DownloadImagesDialog;
