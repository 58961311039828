import './style.scss';

import { Label } from '@fluentui/react';

import { PERFORMANCE_SPECTRUM } from '../config';

const PerformanceStatus = ({ progress, withTitleProgress = false }: any) => {
  if (!progress) {
    return null;
  }

  function renderSpectrum(step: string) {
    return (
      <div key={step} className='performance-status__step' style={{ backgroundColor: step }} />
    );
  }

  return (
    <div>
      <Label>
        Performance
        {withTitleProgress ? (
          <span>
            :<strong> {progress}</strong>
          </span>
        ) : null}
      </Label>

      <div className='performance-status'>
        <div className='performance-status__indicator' style={{ width: `${progress}%` }} />
        {PERFORMANCE_SPECTRUM.map(renderSpectrum)}
      </div>
    </div>
  );
};

export default PerformanceStatus;
