/* eslint-disable react-hooks/exhaustive-deps */
import {
  DialogType,
  IStackStyles,
  ITextStyles,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import saveAs from "file-saver";
import { useEffect, useState } from "react";

import { convertToZipFileName } from "../../../../schema/Utils";
import { downloadImage } from "../../../Machines/MachineDetails/Images/api";
import { Image } from "../../../Machines/models";
import BaseDialog, {
  BaseDialogProps,
  DialogSize,
} from "../../../common/Dialog";

type DownloadImagesProgressDialogProps = Omit<
  BaseDialogProps,
  "hidden" | "dialogContentProps" | "size"
> & {
  images: Image[];
  onClose: () => void;
};

const containerStyles: IStackStyles = {
  root: { minHeight: "8em", minWidth: "8em", padding: "1em", rowGap: "2em" },
};

const spanStyles: ITextStyles = {
  root: {
    fontWeight: "600",
  },
};

export const DownloadImagesProgressDialog = ({
  images,
  onClose,
  ...rest
}: DownloadImagesProgressDialogProps) => {
  const [imagesProcessed, setImagesProcessed] = useState<number>(0);

  // Downloads the images.
  useEffect(() => {
    if (images.length === 0 || imagesProcessed === images.length) {
      onClose();
      return;
    }

    let mount = true;
    const image = images.at(imagesProcessed);
    downloadImage(image.id)
      .then(
        (response) =>
          mount === true &&
          saveAs(
            new Blob([response], { type: "application/zip" }),
            convertToZipFileName(image.fileName)
          )
      )
      .then(() => mount === true && setImagesProcessed(imagesProcessed + 1));

    // Cleans all resources
    return () => {
      mount = false;
    };
  }, [imagesProcessed]);

  return (
    <BaseDialog
      {...rest}
      hidden={false}
      size={DialogSize.AUTO}
      dialogContentProps={{
        title: "",
        type: DialogType.close,
        onDismiss: onClose,
      }}
    >
      <Stack
        role="presentation"
        horizontalAlign="center"
        verticalAlign="center"
        styles={containerStyles}
      >
        <Stack.Item>
          <Spinner size={SpinnerSize.large} />
        </Stack.Item>
        <Text variant="medium" styles={spanStyles}>{`Downloading ${
          imagesProcessed + 1
        } of ${images.length ?? 0} images...`}</Text>
      </Stack>
    </BaseDialog>
  );
};
