import React from "react";

import ArrowLeftIcon from "../../../assets/svg/ArrowLeftIcon";

const WirelessSensorsPlaceholder = () => (
  <div className="placeholder" style={{ height: "100%", background: "#FFF" }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <ArrowLeftIcon style={{ marginRight: 15 }} />
      <div>Please select a Sensor</div>
    </div>
  </div>
);

export default WirelessSensorsPlaceholder;
