import { axiosInstance } from "../../..";
import { CompanyDetailsWithChilds } from "../models";

export async function fetchData(
  id: string
): Promise<CompanyDetailsWithChilds | any> {
  const response = await axiosInstance.get<CompanyDetailsWithChilds | any>(
    `/meta/read/v1/companies/${id}`
  );
  if (response.data) return response.data;
  else return response;
}
