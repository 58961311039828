import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { apiService } from '../../modules/common';
import type { DataPoint } from '../../types';

interface UseSignalMetadataByIdArgs {
  machineId: string;
  signalId: string;
}

type SignalDetails = {
  dataUntil?: string | number | Date;
  dataPoints?: DataPoint[];
};

type UseSignalMetadataByIdOptions =
  | (Omit<
      UseQueryOptions<SignalDetails, unknown, SignalDetails, string[]>,
      'initialData' | 'queryFn' | 'queryKey'
    > & { initialData?: () => undefined })
  | undefined;

const useSignalMetadataById = (
  args: UseSignalMetadataByIdArgs,
  options?: UseSignalMetadataByIdOptions,
) => {
  return useQuery(
    ['signal-metadata', args.machineId, args.signalId],
    () => apiService.measuredDataRead.getSignalMetadataById(args),
    options,
  );
};

export default useSignalMetadataById;
