/* eslint-disable jsx-a11y/click-events-have-key-events */

import { format, formatFileSize } from "../../../../utils";

import type { User } from "../../../../Hooks/useUsers";
import { getUserById } from "../../../../Hooks/useUsers";
import { File } from "../../../common/Preview/File";
import type { Column } from "../../../common/Table/Table";

type GetColumnsOpts = {
  users: User[];
};

export const getColumns = ({ users }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: "Name",
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 150,
    isSortable: true,
    onRender: (item) => {
      return <File file={item} />;
    },
  },
  {
    key: "addedBy",
    fieldName: "addedBy",
    name: "Added By",
    minWidth: 100,
    isSortable: true,
    isExportable: false,
    onRender: (item) => getUserById(users, item.memberId),
  },
  {
    key: "added",
    fieldName: "added",
    name: "Date Added",
    minWidth: 100,
    isSortable: true,
    onRender: (item) => format(new Date(item.added)),
  },
  {
    key: "description",
    fieldName: "description",
    name: "Description",
    flexGrow: 2,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
  },
  {
    key: "type",
    fieldName: "type",
    name: "Type",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "sizeInBytes",
    fieldName: "sizeInBytes",
    name: "Size",
    minWidth: 100,
    isSortable: true,
    onRender: (item) => formatFileSize(item.sizeInBytes),
  },
];
