import type { IColumn, ISelection } from "@fluentui/react";
import { useMemo, useState } from "react";

import { getLocalStorageSort, setLocalStorageSort } from "./helpers";

import type { Column } from "./Table";
import type { ColumnsOpts } from "./useColumns";

type Order = "asc" | "desc";

// orderBy params
export type Sorter = {
  key: ((rowItem: any) => string | number)[];
  order: Order[];
};

export type UseSortOpts = {
  columns: Column[];
  columnsOpts: ColumnsOpts;
  selection: ISelection;
  shouldOmitColumnsOpts: boolean;
  persistOpts: any;
};

export type UseSort = {
  sorter: Sorter;
  columns: IColumn[];
};

type Sort = Required<Pick<IColumn, "isSorted" | "isSortedDescending">>;

type State = Map<IColumn["key"], Sort>;

const getNext = ({ isSorted, isSortedDescending }: Sort): Sort => {
  const isAsc = isSorted;
  const isDesc = isSorted && isSortedDescending;

  if (isDesc) {
    return { isSorted: false, isSortedDescending: false };
  }

  if (isAsc) {
    return { isSorted: true, isSortedDescending: true };
  }

  return { isSorted: true, isSortedDescending: false };
};

export const useSort = ({
  columns: _columns,
  columnsOpts,
  selection,
  shouldOmitColumnsOpts,
  persistOpts,
}: UseSortOpts): UseSort => {
  const persistSortTableKey: any = persistOpts?.sortKey || persistOpts?.key;
  const [sort, setSort] = useState<State>(() => {
    const state: State = new Map();
    const localStorageSorts: any =
      getLocalStorageSort(persistSortTableKey) || [];

    _columns.forEach(
      ({
        isSortable,
        isSorted = false,
        isSortedDescending = false,
        key,
      }: Column) => {
        if (isSortable) {
          const localStorageSortColumn = localStorageSorts.filter(
            (item: any) => item.key === key
          )[0];
          if (localStorageSortColumn) {
            state.set(key, {
              isSorted: localStorageSortColumn.isSorted,
              isSortedDescending: localStorageSortColumn.isSortedDescending,
            });
          } else {
            state.set(key, { isSorted, isSortedDescending });
          }
        }
      }
    );

    return state;
  });

  const columns = useMemo<IColumn[]>(() => {
    const order = shouldOmitColumnsOpts
      ? _columns.map(({ key }) => key)
      : columnsOpts.map(({ key }) => key);

    return _columns
      .sort(
        ({ key: aKey }, { key: bKey }) =>
          order.indexOf(aKey) - order.indexOf(bKey)
      )
      .map(({ key, isSortable, isResizable = true, ...rest }: Column) => ({
        key,
        ...rest,
        ...(isSortable && {
          ...(sort.get(key) as Sort),
          onColumnClick: (_, column) => {
            selection.setAllSelected(false);
            handler(column);

            if (persistSortTableKey) {
              const { isSorted, isSortedDescending } = getNext({
                isSorted: column.isSorted || false,
                isSortedDescending: column.isSortedDescending || false,
              });
              setLocalStorageSort(persistSortTableKey, {
                isSorted,
                isSortedDescending,
                key: column.key,
              });
            }
          },
        }),
        isResizable,
      }));
  }, [sort, columnsOpts, _columns, shouldOmitColumnsOpts]);

  const sorter = useMemo<Sorter>(() => {
    const current: { key: string; order: Order }[] = [];

    sort.forEach(({ isSorted, isSortedDescending }, key) => {
      if (isSorted || isSortedDescending) {
        current.push({
          key,
          order: isSorted && isSortedDescending ? "desc" : "asc",
        });
      }
    });

    return current.reduceRight<Sorter>(
      (result, { order, key }) => ({
        order: [...result.order, order],
        key: [
          ...result.key,
          (rowItem) => {
            if (typeof rowItem[key] === "number") {
              return rowItem[key];
            }

            return `${rowItem[key]}`.toLowerCase();
          },
        ],
      }),
      { order: [], key: [] }
    );
  }, [sort]);

  const handler = ({ key }: IColumn) => {
    const item = sort.get(key);

    if (!item) {
      return;
    }

    const current = new Map(sort).set(key, getNext(item));

    setSort(current);
  };

  return {
    sorter,
    columns,
  };
};
