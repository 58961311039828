import type { CSSProperties } from "react";
import { useMemo } from "react";
import * as ReactRouterDOM from "react-router-dom";
import type { PointFeature } from "supercluster";

import { AppBreakpoints, useBreakpoint } from "../../../../../Hooks";
import { BookmarkType } from "../../../../../modules/common/components/Bookmarks";
import type { ProjectProperties } from "../../../../Home/HomePage/hooks/useDashboardData";
import { Map, MapProvider } from "../../../../common/Map";
import { NoData } from "../../../../common/NoData";
import { PowerBi } from "../../../../common/PowerBi";
import { Spin } from "../../../../common/Spin";

import { useProjectData } from "../../hooks/useProjectData";
import { DashboardHeader } from "../DashboardHeader";

const styles: Record<string, CSSProperties> = {
  wrapper: {
    padding: 32,
  },
  header: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 16,
  },
  powerBiContainer: {
    background: "#fff",
    marginTop: 48,
    marginBottom: 48,
  },
};

export const Project = () => {
  const { id } = ReactRouterDOM.useParams();

  const { project, isLoading, isError, dashboard } = useProjectData({
    id: id || "",
  });

  const { name, longitude, latitude } = project;

  const breakpoint = useBreakpoint();

  const mapStyle = useMemo<CSSProperties>(() => {
    if (
      [
        AppBreakpoints.LaptopLarge,
        AppBreakpoints.LaptopMedium,
        AppBreakpoints.LaptopSmall,
      ].includes(breakpoint as AppBreakpoints)
    ) {
      return {
        gridColumn: 3,
      };
    }

    return {
      gridColumn: "span 2",
    };
  }, [breakpoint]);

  const config = useMemo(() => {
    const { isLoading, isError, embed } = dashboard;

    if (isLoading || isError || !embed) {
      return undefined;
    }

    return {
      id: embed.id,
      url: embed.embedUrl,
      token: embed.embedToken,
    };
  }, [dashboard]);

  if (isLoading) {
    return <Spin style={{ height: "calc(100vh - 50px)" }} />;
  }

  if (isError) {
    return <NoData style={{ height: "calc(100vh - 50px)" }} />;
  }

  const points: PointFeature<ProjectProperties>[] = [
    {
      type: "Feature",
      properties: {
        id: id as string,
        name,
        type: "Project",
        cluster: false,
      },
      geometry: {
        type: "Point",
        coordinates: [longitude as number, latitude as number],
      },
    },
  ];

  return (
    <div style={styles.wrapper}>
      <div style={styles.header}>
        <DashboardHeader
          title={project.name}
          data={{ ...project, type: BookmarkType.Project }}
        />
        <MapProvider
          defaultValues={{
            latitude: latitude as number,
            longitude: longitude as number,
            zoom: 7,
          }}
        >
          <Map points={points} height={230} style={mapStyle} />
        </MapProvider>
      </div>
      <div style={styles.powerBiContainer}>
        <PowerBi
          isLoading={dashboard.isLoading}
          isError={dashboard.isError}
          config={config}
        />
      </div>
    </div>
  );
};
