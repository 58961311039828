import * as _ from "lodash-es";
import * as React from "react";
import { treeHandlers } from "react-hyper-tree";
import type { TreeNode } from "react-hyper-tree/dist/helpers/node";

import useLayoutTypes from "../../../hooks/useLayoutTypes";
import useSelectedDataPointsStore from "../../../hooks/useSelectedDataPointsStore";
import useSelectedSignalsStore from "../../../hooks/useSelectedSignalsStore";
import type { CustomSelectNodeProps } from "../../../types";
import { queryClient } from "../../../../core";
import type { ResponseSimplifiedSignal } from "../../../../../types";
import { useBoolean } from "../../../../../Hooks";

const useCustomSelectNode = ({
  node,
  handlers,
  onToggle,
  onSelect,
}: CustomSelectNodeProps) => {
  const {
    selectedSignals,
    clearTrendSignals,
    removeSelectedSignal,
    addSelectedSignal,
    clearRawSignals,
  } = useSelectedSignalsStore((store) => ({
    selectedSignals: store.selectedSignals,
    clearTrendSignals: store.clearTrendSignals,
    removeSelectedSignal: store.removeSelectedSignal,
    addSelectedSignal: store.addSelectedSignal,
    clearRawSignals: store.clearRawSignals,
  }));

  const {
    selectedDataPoints,
    removeSelectedDataPoint,
    clearSelectedDataPoints,
  } = useSelectedDataPointsStore((store) => ({
    selectedDataPoints: store.selectedDataPoints,
    removeSelectedDataPoint: store.removeSelectedDataPoint,
    clearSelectedDataPoints: store.clearSelectedDataPoints,
  }));

  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] =
    useBoolean(false);

  const {
    isCompareLayout,
    isWaveformLayout,
    isTrendLayout,
    isWaterfallLayout,
  } = useLayoutTypes();

  const treeHandler = treeHandlers.trees["raw-data-signal-tree"].handlers;

  const uncollapseSignals = React.useCallback(() => {
    const previousExpandedMachinesSignalsList =
      (queryClient.getQueryData(["signals-list"]) as TreeNode[]) || [];
    const nextExpandedMachinesSignalsList = [
      ...previousExpandedMachinesSignalsList,
      ...node.getChildren().map((item) => item.getData()),
    ];

    queryClient.setQueryData(["signals-list"], nextExpandedMachinesSignalsList);
  }, [node]);

  const collapseSignals = React.useCallback(() => {
    const previousExpandedMachinesSignalsList =
      (queryClient.getQueryData(["signals-list"]) as TreeNode[]) || [];
    const nextExpandedMachinesSignalsList = _.differenceWith(
      previousExpandedMachinesSignalsList,
      node.getChildren().map((item) => item.getData()),
      _.isEqual
    );

    queryClient.setQueryData(["signals-list"], nextExpandedMachinesSignalsList);
  }, [node]);

  const itemsToDeselectFromTreeByType = (type: string) => {
    return selectedSignals.filter((signal) => signal.dataType === type);
  };

  const hasMultipleDataPoints = () => {
    for (const item of selectedDataPoints) {
      if (item.dataPoints.length > 1) {
        return true;
      }
    }
    return false;
  };

  const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!node.hasChildren() && !node.asyncNode) {
      if (!node.isSelected()) {
        const containsMultipleDataPoints = hasMultipleDataPoints();

        if (containsMultipleDataPoints) {
          // alert('You can only select one data point at a time');
          showPopup();
          return;
        }

        /* Remove all Trend signals if more than 1 is selected */
        if (
          node.getData().dataType === "Trend" &&
          selectedSignals.filter((signal) => signal.dataType === "Trend")
            .length > 0
        ) {
          clearTrendSignals();
          itemsToDeselectFromTreeByType("Trend").forEach((signal) => {
            treeHandler.setSelected(signal.id, false);
          });
        }

        /* Remove all Raw signals if more than 1 is selected and waveform-spectrum layout is cuurently on or if there is at least one Raw Signal selected and trend-waveform-spectrum or waterfall layout is currently on */
        if (
          (node.getData().dataType === "ShortTerm" &&
            (isWaveformLayout || isWaterfallLayout) &&
            selectedSignals.length === 1) ||
          (node.getData().dataType === "ShortTerm" &&
            isTrendLayout &&
            itemsToDeselectFromTreeByType("ShortTerm").length > 0)
        ) {
          clearRawSignals();
          itemsToDeselectFromTreeByType("ShortTerm").forEach((signal) => {
            treeHandler.setSelected(signal.id, false);
          });
        }

        /* Remove last Raw signal if more than 2 is selected and compare-waveform-spectrum layout is selected */
        if (
          node.getData().dataType === "ShortTerm" &&
          isCompareLayout &&
          selectedSignals.length === 2
        ) {
          treeHandler.setSelected(
            itemsToDeselectFromTreeByType("ShortTerm")[
              itemsToDeselectFromTreeByType("ShortTerm").length - 1
            ].id,
            false
          );

          removeSelectedSignal(
            itemsToDeselectFromTreeByType("ShortTerm")[
              itemsToDeselectFromTreeByType("ShortTerm").length - 1
            ]
          );
        }

        onSelect(e);

        if (node.isSelected()) {
          addSelectedSignal(node.getData() as ResponseSimplifiedSignal);
          clearSelectedDataPoints();
        } else {
          removeSelectedSignal(node.getData() as ResponseSimplifiedSignal);
          removeSelectedDataPoint(node.getData().id);
        }
      } else {
        // The signal is unselected
        onSelect(e);
        removeSelectedSignal(node.getData() as ResponseSimplifiedSignal);
        removeSelectedDataPoint(node.getData().id);
      }
    }
  };

  const handleItemSelect = (nodeId: string) => {
    const node = handlers.getNode(nodeId) as TreeNode;
    const selectedSignals = useSelectedSignalsStore.getState().selectedSignals;

    const itemsToDeselectFromTreeByType = (type: string) => {
      return selectedSignals.filter((signal) => signal.dataType === type);
    };

    if (!node.isSelected()) {
      /* Remove all Trend signals if more than 1 is selected */
      if (
        node.getData().dataType === "Trend" &&
        selectedSignals.filter((signal) => signal.dataType === "Trend").length >
          0
      ) {
        clearTrendSignals();
        itemsToDeselectFromTreeByType("Trend").forEach((signal) => {
          treeHandler.setSelected(signal.id, false);
        });
      }

      /* Remove all Raw signals if more than 1 is selected and waveform-spectrum layout is cuurently on or if there is at least one Raw Signal selected and trend-waveform-spectrum layout is currently on */
      if (
        (node.getData().dataType === "ShortTerm" &&
          isWaveformLayout &&
          selectedSignals.length === 1) ||
        (node.getData().dataType === "ShortTerm" &&
          isTrendLayout &&
          itemsToDeselectFromTreeByType("ShortTerm").length > 0)
      ) {
        clearRawSignals();
        itemsToDeselectFromTreeByType("ShortTerm").forEach((signal) => {
          handlers.setSelected(signal.id as string, false);
        });
      }

      /* Remove last Raw signal if more than 2 is selected and compare-waveform-spectrum layout is selected */
      if (
        node.getData().dataType === "ShortTerm" &&
        isCompareLayout &&
        selectedSignals.length === 2
      ) {
        treeHandler.setSelected(
          itemsToDeselectFromTreeByType("ShortTerm")[
            itemsToDeselectFromTreeByType("ShortTerm").length - 1
          ].id,
          false
        );
        removeSelectedSignal(
          itemsToDeselectFromTreeByType("ShortTerm")[
            itemsToDeselectFromTreeByType("ShortTerm").length - 1
          ]
        );
      }

      handlers.setSelected(node, true);

      if (node.isSelected()) {
        addSelectedSignal(node.getData() as ResponseSimplifiedSignal);
      } else {
        removeSelectedSignal(node.getData() as ResponseSimplifiedSignal);
        removeSelectedDataPoint(node.getData().id);
      }
    } else {
      // The signal is unselected
      handlers.setSelected(node, false);
      removeSelectedSignal(node.getData() as ResponseSimplifiedSignal);
      removeSelectedDataPoint(node.getData().id);
    }
  };

  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // We are at the machine level
    if (node.asyncNode) {
      if (!node.isOpened()) {
        // We are uncollapsing children (signals)
        uncollapseSignals();
      } else {
        // We are collapsing children (signals)
        collapseSignals();
      }
    }

    onToggle(e);
  };

  const isLeafNode = !node.hasChildren() && !node.asyncNode;
  const showEmptyIcon = isLeafNode && !node.isSelected();
  const showFullIcon = isLeafNode && node.isSelected();
  const showCloseIcon =
    node.options.opened && (node.hasChildren() || node.asyncNode);
  const showOpenIcon =
    !node.options.opened && (node.hasChildren() || node.asyncNode);

  const handleItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isLeafNode) {
      handleSelect(e);
    } else {
      handleToggle(e);
    }
  };

  return {
    isLeafNode,
    showEmptyIcon,
    showFullIcon,
    showCloseIcon,
    showOpenIcon,
    handleSelect,
    handleToggle,
    handleItemClick,
    handleItemSelect,
    isPopupVisible,
    showPopup,
    hidePopup,
  };
};

export default useCustomSelectNode;
