import type { CSSProperties } from "react";
import { useMemo } from "react";

import type { HomeOverviewTask } from "../../../../../types";
import type { CommandBarItemProps } from "../../../../common/CommandBar";
import CommandBar, { CommandBarItemType } from "../../../../common/CommandBar";
import type { TableProps } from "../../../../common/Table";
import Table, { useTableFilters } from "../../../../common/Table";

import { getColumns } from "./columns";

type Task = Omit<HomeOverviewTask, "metaInformation"> & { machineName: string };

type MyTasksProps = {
  items: Task[];
  isLoading: boolean;
  isError: boolean;
};

const containerTitleStyle: CSSProperties = {
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "22px",
  alignSelf: "center",
};

export const MyTasks = ({ items, isLoading, isError }: MyTasksProps) => {
  const { filters, handleSearch } = useTableFilters<Task>({
    keys: [
      "subject",
      "description",
      "documents",
      "createdAt",
      "dueDate",
      "machineName",
    ],
  });

  const tableProps: TableProps = useMemo(
    () => ({
      persistOpts: {
        key: "table-my-tasks",
        version: 3,
      },
      header: {
        title: "My Tasks",
      },
      items,
      filters,
      isLoading,
      isError,
      hasSelection: false,
      columns: getColumns(),
    }),
    [items, filters, isError, isLoading]
  );

  const headerItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <div style={containerTitleStyle}>My Tasks</div>,
    },
  ];

  return (
    <div
      className="my-tasks-container"
      style={{
        marginBottom: 80,
      }}
    >
      <CommandBar items={headerItems} onSearch={handleSearch} />
      <Table {...tableProps} />
    </div>
  );
};
