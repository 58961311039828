import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { MetadataVpnPC } from "../../Schema/models";
import FormDialog from "../generic/FormDialog";
import { useContext, useEffect, useState } from "react";
import { AddEditDialogProps, BasicDialogProps } from "../../Schema/viewModels";
import { FormItemRow } from "../generic/FormDialogComponents";

import { DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";

import { VpnPCsAPI } from "../../Schema/api";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";

type EditVpnPcDialogProps = BasicDialogProps & {
  item: MetadataVpnPC;
};

/**
 * Gets the Add VPN PC dialog component.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Add VPN PC dialog component.
 */
export const AddVpnPcDialog = ({ onClose }: BasicDialogProps) => {
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Method called when the submit button is clicked.
  const onSubmitHandler = (data: MetadataVpnPC) => {
    if (!axiosInstance) {
      return;
    }

    setIsLoading(true);
    VpnPCsAPI.create(axiosInstance, data).then((response) => {
      setIsLoading(false);
      if (response.status !== 201) {
        notification.error(
          `Failure creating a VPN PC: ${response.statusText}.`
        );
        return;
      }

      notification.success("Success creating a VPN PC.");
      onClose?.(true);
    });
  };

  return (
    <AddEditVpnPcDialog
      isLoading={isLoading}
      onSubmit={onSubmitHandler}
      onClose={onClose}
    />
  );
};

/**
 * Gets the Edit VPN PC dialog component.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Edit VPN PC dialog component.
 */
export const EditVpnPcDialog = ({ item, onClose }: EditVpnPcDialogProps) => {
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);

  // Method called when the submit button is clicked.
  const onSubmitHandler = (data: MetadataVpnPC) => {
    if (!axiosInstance) {
      return;
    }

    setIsLoading(true);
    VpnPCsAPI.update(axiosInstance, data).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          `Failure updating a VPN PC: ${response.statusText}.`
        );
        return;
      }

      notification.success("Success updating a VPN PC.");
      onClose?.(true);
    });
  };

  return (
    <AddEditVpnPcDialog
      item={item}
      isLoading={isLoading}
      onSubmit={onSubmitHandler}
      onClose={onClose}
    />
  );
};

/**
 * Gets the Add Edit VPN PC dialog component.
 * @param item The VPN PC item.
 * @param isLoading A value indicating whether the form is in loading state.
 * @param onSubmit The method called when the submit button is clicked.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The Add Edit VPN PC dialog component.
 */
const AddEditVpnPcDialog = ({
  item,
  isLoading,
  onSubmit,
  onClose,
}: AddEditDialogProps<MetadataVpnPC>) => {
  const [isValid, setIsValid] = useState(false);
  const [pcName, setPcName] = useState<string>(item ? item.name : "");
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);

  // Checks whether the PC name is valid.
  useEffect(() => {
    let result = pcName !== "";
    if (item) {
      result = result && item.name.trim() !== pcName.trim();
    }

    setIsValid(result);
  }, [item, pcName]);

  const onSubmitHandler = () => {
    let newItem: MetadataVpnPC = { name: pcName.trim() };
    if (item) {
      newItem = { ...newItem, id: item.id };
    }

    onSubmit?.(newItem);
  };

  const onBlurHandler = () => {
    !dataHasChanged && setDataHasChanged(true);
  };

  return (
    <FormDialog
      title={`${item ? "Edit" : "Add"} VPN PC`}
      size={DialogSize.S}
      onClose={onClose}
    >
      <FormItemRow label={"VPN PC Name *"}>
        <TextField
          value={pcName}
          onChange={(_, newValue) => setPcName(newValue || "")}
          onBlur={onBlurHandler}
          errorMessage={
            dataHasChanged && pcName === "" ? "This field is required." : ""
          }
        />
      </FormItemRow>
      <DialogFooter>
        <PrimaryButton
          className="primary-button"
          disabled={isLoading || !isValid}
          text="Save Changes"
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
          onClick={onSubmitHandler}
        />
        <DefaultButton
          className="secondary-button"
          text="Cancel"
          disabled={isLoading}
          onClick={onClose}
        />
      </DialogFooter>
    </FormDialog>
  );
};
