import { useLocationSearch, useSearch } from "../../../Hooks";
import CommandBar from "./components/CommandBar";
import MachineCVInfoMachineDetails from "./components/MachineCVInfoMachineDetails";
import useInfoStore from "./hooks/useInfoStore";
import { useMachineCVInfo } from "./hooks/useMachineCVInfo";
import type { Group } from "../../../types";
import { NoData } from "../../common/NoData";
import { Spin } from "../../common/Spin";

const Page = () => {
  const [{ id }] = useLocationSearch();

  const { perRow } = useInfoStore((state) => ({
    perRow: state.perRow,
  }));

  const {
    data = [],
    isLoading,
    isError,
  } = useMachineCVInfo({ machineId: id || "" });

  const { data: items, handleSearch } = useSearch<Group>({
    data,
    keys: ["name"],
  });

  return (
    <>
      <div style={{ padding: "0px 16px", marginBottom: 32 }}>
        <CommandBar handleSearch={handleSearch} allGroups={items} />
        {isLoading && <Spin style={{ marginTop: "50px" }} />}
        {isError && <NoData style={{ marginTop: "50px" }} />}
        <div
          style={
            perRow
              ? {
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }
              : {
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
                  justifyContent: "space-between",
                  columnGap: "10px",
                }
          }
        >
          {!isLoading &&
            items
              .sort((a, b) =>
                a.name === "Customer Feedback"
                  ? 1
                  : b.name === "Customer Feedback"
                    ? -1
                    : 0
              )
              .map((group) => (
                <MachineCVInfoMachineDetails
                  key={group.id}
                  group={group}
                  allGroups={items}
                />
              ))}
        </div>
      </div>
    </>
  );
};

export default Page;
