import { Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { Tree } from "react-hyper-tree";

import SidebarNode from "./SidebarNode";
import { useWSSidebarData } from "../../../hooks/useWSSidebarData";
import { NoData } from "../../../../common/NoData";

export const WSSidebar = () => {
  const { required, handlers, isLoading } = useWSSidebarData();

  if (isLoading) {
    return (
      <Spinner size={SpinnerSize.large} label="Loading signal selection..." />
    );
  }

  if (required.data.length === 0) {
    return (
      <NoData
        text="There is no data!"
        styles={{
          root: {
            width: "100%",
          },
        }}
      />
    );
  }

  return (
    <Tree
      {...required}
      {...handlers}
      disableLines
      depthGap={10}
      renderNode={(props) => <SidebarNode {...props} />}
    />
  );
};
