import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../../../../modules/core/apiClient/useApiStore';
import type { TaskOverviewList } from '../../../../types';

type UseTaskQuery = {
  items: TaskOverviewList;
  isLoading: boolean;
  isError: boolean;
};

export type UseTaskQueryOpts = {
  machineId: string;
  reload?: boolean;
};

export const useTaskQuery = (opts: UseTaskQueryOpts): UseTaskQuery => {
  const {
    data = [],
    isLoading,
    isError,
  } = useQuery(
    ['machine-cv-task-list', opts],
    () =>
      getApiClient()
        .get<TaskOverviewList>(`/meta/machinecv/v1/tasks/overview/${opts.machineId}`)
        .then(({ data }) => data),
    {
      cacheTime: 0,
    },
  );

  return {
    items: data,
    isLoading,
    isError,
  };
};
