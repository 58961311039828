import type { IDialogProps } from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import type { FC } from "react";
import React, { useState } from "react";
import { z } from "zod";

import { useLocationSearch } from "../../../../../Hooks";
import { useThreadAdd } from "../../hooks/useThreadMutations";
import { useThreadsQuery } from "../../hooks/useThreadsQuery";
import BaseDialog from "../../../../common/Dialog/Dialog";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../common/Notification";
import { FieldError } from "react-hook-form";

const dialogAddContentProps = {
  type: DialogType.normal,
  title: "New thread",
  closeButtonAriaLabel: "Close",
};

type ThreadDialogProps = {
  onSuccess?: () => void;
} & IDialogProps;

const schema = z.object({
  text: z.string(),
  // message: z.string(),
});

const threadFields: FormItemProps[] = [
  {
    name: "text",
    type: FormItemType.TextField,
    placeholder: "Thread title",
    groupProps: { label: "Title" },
  },
];

export const ThreadDialog: FC<ThreadDialogProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    schema,
    defaultValues: {
      text: "",
    },
  });

  const [{ id }] = useLocationSearch();
  const { addThreadAsync } = useThreadAdd();
  const { refetch } = useThreadsQuery({ machineId: id as string });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const submitData = {
      ...data,
      text: data.text,
      machineId: id || "",
    };
    await addThreadAsync(submitData)
      .then(() => {
        refetch();
        onClose();
        reset();
        notification.success("Thread created successfully");
      })
      .catch(() => {
        notification.error("Error creating thread");
      })
      .finally(() => setIsLoading(false));
  });

  const onClose = () => {
    props.onDismiss?.();
  };

  return (
    <>
      <BaseDialog {...props} dialogContentProps={dialogAddContentProps}>
        <form onSubmit={onSubmit}>
          {renderFormItems(threadFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogFooter>
            <PrimaryButton
              type="submit"
              text="Create"
              disabled={isLoading}
              onRenderIcon={() =>
                isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
              }
            />
            <DefaultButton
              styles={{
                root: { border: "unset", background: "transparent" },
              }}
              text="Cancel"
              onClick={onClose}
            />
          </DialogFooter>
        </form>
      </BaseDialog>
    </>
  );
};
