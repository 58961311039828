import type { IButtonProps, IStackProps, ITextProps } from "@fluentui/react";
import { DefaultButton, Dropdown, Stack, Text } from "@fluentui/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";

import FirstPageIcon from "../../../assets/svg/FirstPageIcon";
import LastPageIcon from "../../../assets/svg/LastPageIcon";
import NextPageIcon from "../../../assets/svg/NextPageIcon";
import PreviousPageIcon from "../../../assets/svg/PreviousPageIcon";
import { perPageOptions } from "./constants";

type To = "PREVIOUS_PAGE" | "NEXT_PAGE" | "FIRST_PAGE" | "LAST_PAGE";

export type PaginationProps = Omit<IStackProps, "onChange"> & {
  total: number;
  perPage: number;
  current: number;
  hidePerPage: boolean;
  onChange?: (page: number) => void;
  setPerPage?: (page: number) => void;
};

const infoStyles: ITextProps["styles"] = {
  root: {
    backgroundColor: "#fff",
    borderRadius: 2,
    color: "rgb(50, 49, 48)",
    cursor: "default",
    padding: "4px 8px",
    height: 32,
    minWidth: 52,
    lineHeight: 21,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const btnStyles: IButtonProps["styles"] = {
  root: {
    border: 0,
    minWidth: 52,
  },
};

const Pagination = ({
  perPage,
  total,
  current,
  onChange,
  setPerPage,
  hidePerPage,
  ...rest
}: PaginationProps) => {
  const pages = useMemo(() => Math.ceil(total / perPage), [total, perPage]);

  const canPrevious = current === 0;

  const canNext = current === pages - 1;

  useEffect(() => {
    if (pages > 0 && current > pages - 1) {
      change("PREVIOUS_PAGE");
    }
  }, [pages, current]);

  const change = (to: To) => {
    switch (to) {
      case "PREVIOUS_PAGE":
      case "FIRST_PAGE": {
        if (current !== 0) {
          const index = to === "FIRST_PAGE" ? 0 : current - 1;

          onChange?.(index);
        }

        break;
      }
      case "NEXT_PAGE":
      case "LAST_PAGE": {
        if (current !== pages - 1) {
          const index = to === "LAST_PAGE" ? pages - 1 : current + 1;

          onChange?.(index);
        }

        break;
      }
    }
  };

  if (pages <= 1 && hidePerPage) {
    return null;
  }

  return (
    <Stack
      horizontal
      as={"nav"}
      tokens={{
        childrenGap: 8,
      }}
      verticalAlign="center"
      className="pagination"
      aria-label="pagination navigation"
      {...rest}
    >
      <DefaultButton
        styles={btnStyles}
        disabled={canPrevious}
        aria-label="Go to first page"
        onClick={() => change("FIRST_PAGE")}
      >
        <FirstPageIcon />
      </DefaultButton>
      <DefaultButton
        styles={btnStyles}
        disabled={canPrevious}
        aria-label="Go to previous page"
        onClick={() => change("PREVIOUS_PAGE")}
      >
        <PreviousPageIcon />
      </DefaultButton>
      <Text styles={infoStyles}>
        {current + 1} / {pages}
      </Text>
      <DefaultButton
        styles={btnStyles}
        disabled={canNext}
        aria-label="Go to next page"
        onClick={() => change("NEXT_PAGE")}
      >
        <NextPageIcon />
      </DefaultButton>
      <DefaultButton
        styles={btnStyles}
        disabled={canNext}
        aria-label="Go to last page"
        onClick={() => change("LAST_PAGE")}
      >
        <LastPageIcon />
      </DefaultButton>
      {!hidePerPage && (
        <div className="table-per-page">
          <span>Per page:</span>
          <Dropdown
            disabled={true}
            placeholder="Select an option"
            selectedKey={perPage ? perPage : undefined}
            options={perPageOptions}
            onChange={(event, value: any) => {
              if (setPerPage) {
                setPerPage(parseInt(value.key, 10));
              }
            }}
          />
        </div>
      )}
    </Stack>
  );
};

type PaginationItem = {
  key: string;
  Element: JSX.Element;
};

export type PaginationItemsProps = Omit<
  PaginationProps,
  "total" | "current"
> & {
  items: PaginationItem[];
};

const PaginationItems = ({
  items,
  perPage = 3,
  ...rest
}: PaginationItemsProps) => {
  const [page, setPage] = useState(0);

  const data = items
    .slice(page * perPage, (page + 1) * perPage)
    .map(({ key, Element }) => <Fragment key={key}>{Element}</Fragment>);

  return (
    <div>
      {data}
      <Pagination
        current={page}
        total={items.length}
        perPage={perPage}
        onChange={(page) => setPage(page)}
        {...rest}
      />
    </div>
  );
};

export default Pagination;

Pagination.Items = PaginationItems;
