import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

interface ChartTimestamp {
  signalId: string | undefined;
  timestamp: string;
}

interface ChartTimestampStore {
  timestamps: ChartTimestamp[];
  trendChartTimestamp: string | null;
  addTimestamp: (signalId: string | undefined, timestamp: string) => void;
  removeTimestamp: (signalId: string | undefined) => void;
  findTimestamp: (signalId: string | undefined) => ChartTimestamp | undefined;
  setTrendChartTimestamp: (timestamp: string) => void;
}

const useChartTimestampStore = createWithEqualityFn<ChartTimestampStore>((set, get) => ({
  timestamps: [],
  trendChartTimestamp: null,

  addTimestamp: (signalId, timestamp) => {
    set((state) => {
      const updatedTimestamps = state.timestamps.map((timestampObj) => {
        if (timestampObj.signalId === signalId) {
          return { ...timestampObj, timestamp };
        }
        return timestampObj;
      });

      if (updatedTimestamps.some((timestampObj) => timestampObj.signalId === signalId)) {
        return { timestamps: updatedTimestamps };
      }

      return { timestamps: [...updatedTimestamps, { signalId, timestamp }] };
    });
  },

  removeTimestamp: (signalId) => {
    set((state) => ({
      timestamps: state.timestamps.filter((timestampObj) => timestampObj.signalId !== signalId),
    }));
  },

  findTimestamp: (signalId) => {
    return get().timestamps.find((timestampObj) => timestampObj.signalId === signalId);
  },

  setTrendChartTimestamp: (timestamp) => {
    set({ trendChartTimestamp: timestamp });
  },
}), shallow);

export default useChartTimestampStore;
