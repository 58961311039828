import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../../../modules/core/apiClient/useApiStore';
import type { GetProjectTelemetryResponse } from '../../../types';

export type UseProjectTelemetryParams = {
  projectId: string;
};

export const useProjectTelemetry = ({ projectId }: UseProjectTelemetryParams) => {
  const {
    data = {} as GetProjectTelemetryResponse,
    isLoading,
    isError,
    refetch,
  } = useQuery<GetProjectTelemetryResponse>(
    ['ws-project-telemetry', projectId],
    () =>
      getApiClient()
        .get<GetProjectTelemetryResponse>(`/dws/v1/telemetry/projects/${projectId}`)
        .then(({ data }) => data),
    {
      cacheTime: 0,
    },
  );

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
