import classNames from "classnames";
import React from "react";

import { STATUSES_COLOR } from "./config";
import PerformanceStatus from "./PerformanceStatus";
import useDriveLoadStore from "../../hooks/useDriveLoadStore";

const LoadAndPerformance = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { driveLoad = {} } = useDriveLoadStore((store: any) => ({
    driveLoad: store.driveLoad,
  }));

  return (
    <div {...rest} className={classNames("load-and-performance", className)}>
      <div className="semibold-title mb-8">Load & Performance</div>
      <div className="white-container">
        <div className="mb-8">
          <div className="body-text mb-8">Average</div>
          <div
            className="status-box"
            style={{
              backgroundColor: STATUSES_COLOR[driveLoad.currentAverageLoad],
            }}
          >
            {driveLoad.currentAverageLoad}
          </div>
        </div>
        <div className="mb-8">
          <div className="body-text mb-8">Dynamic</div>
          <div
            className="status-box"
            style={{
              backgroundColor: STATUSES_COLOR[driveLoad.currentDynamicLoad],
            }}
          >
            {driveLoad.currentDynamicLoad}
          </div>
        </div>
        <div className="mb-8">
          <div className="body-text mb-8">Performance</div>
          <PerformanceStatus value={driveLoad.currentPerformance} />
        </div>
      </div>
    </div>
  );
};

export default LoadAndPerformance;
