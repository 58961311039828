import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../../../modules/core/apiClient/useApiStore';

export const useGatewaySensorsProfiles = ({ gatewayId }: any) => {
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['ws-gateway-sensors-profiles'],
    () =>
      getApiClient()
        .get(`/dws/v1/triggers/statuses/per-gateway/${gatewayId}`)
        .then(({ data }) => data.results),
    {
      cacheTime: 0,
    },
  );

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
