import type { IDialogProps, IDropdownOption } from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import type { FC } from "react";
import React, { useEffect } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import BaseDialog from "../../../../common/Dialog";
import { renderFormItems, useZodForm } from "../../../../common/Form";
import type { FormItemProps } from "../../../../common/Form";
import { FormItemType } from "../../../../common/Form";

import type { CreateProjectTriggerRequest } from "../../Sensor/types";
import { TriggerFrequency } from "../../Sensor/types";

enum MeasurementSettingId {
  MeasSet0 = "MeasSet0",
  MeasSet1 = "MeasSet1",
  MeasSet2 = "MeasSet2",
  MeasSet3 = "MeasSet3",
  MeasSet4 = "MeasSet4",
  MeasSet5 = "MeasSet5",
  MeasSet6 = "MeasSet6",
  MeasSet7 = "MeasSet7",
}

const dialogContentProps = {
  type: DialogType.normal,
  title: "Add new trigger",
  closeButtonAriaLabel: "Close",
};

interface TriggerAddDialogProps extends IDialogProps {
  projectId: string;
  onSuccess?: (data: CreateProjectTriggerRequest) => void;
}

const schema = z.object({
  projectId: z.string().uuid(),
  name: z.string().min(1, "Name is required"),
  frequency: z.nativeEnum(TriggerFrequency),
  hour: z.number().min(0).max(23),
  minute: z.number().min(0).max(59),
  measurementSets: z
    .array(z.nativeEnum(MeasurementSettingId))
    .min(1, "At least one measurement set is required"),
  delayPerSensorInMinutes: z.number().min(0),
});

export const TriggerAddDialog: FC<TriggerAddDialogProps> = ({
  projectId,
  hidden,
  onSuccess,
  onDismiss,
  ...props
}) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    schema,
    defaultValues: {
      projectId,
      hour: 3,
      minute: 0,
      delayPerSensorInMinutes: 5,
      measurementSets: [MeasurementSettingId.MeasSet1],
    },
  });

  useEffect(() => {
    if (hidden) {
      reset();
    }
  }, [hidden, reset]);

  const frequencyOptions: IDropdownOption[] = Object.values(
    TriggerFrequency
  ).map((freq) => ({
    key: freq,
    text: freq.replace(/([A-Z])/g, " $1").trim(),
    data: freq,
  }));

  const measurementSetOptions: IDropdownOption[] = Object.keys(
    MeasurementSettingId
  )
    .filter((key) => isNaN(Number(key)))
    .map((set) => ({
      key: set,
      text: set,
      data: MeasurementSettingId[set as keyof typeof MeasurementSettingId],
    }));
  const triggerFields: FormItemProps[] = [
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: "Name" },
      defaultValue: "Daily raw data",
    },
    {
      name: "frequency",
      type: FormItemType.Dropdown,
      groupProps: { label: "Frequency" },
      options: frequencyOptions,
      defaultValue: TriggerFrequency.EveryDay,
    },
    {
      name: "hour",
      type: FormItemType.NumberFieldV1,
      groupProps: { label: "Hour" },
      min: 0,
      max: 23,
    },
    {
      name: "minute",
      type: FormItemType.NumberFieldV1,
      groupProps: { label: "Minute" },
      min: 0,
      max: 59,
    },
    {
      name: "measurementSets",
      type: FormItemType.Dropdown,
      groupProps: { label: "Measurement Sets" },
      options: measurementSetOptions,
      multiSelect: true,
      required: true,
    },
    {
      name: "delayPerSensorInMinutes",
      type: FormItemType.NumberFieldV1,
      groupProps: { label: "Delay per Sensor (minutes)" },
      min: 0,
      max: 30,
    },
  ];

  // TODO: This type creates a typescript error CreateProjectTriggerRequest
  const onSubmit = handleSubmit(async (data: any) => {
    console.log("Creating trigger:", data);
    try {
      onSuccess?.(data);
      onDismiss?.();
    } catch (error) {
      // Handle error
      console.error("Error creating trigger:", error);
    }
  });

  return (
    <BaseDialog
      {...props}
      hidden={hidden}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={onSubmit}>
        {renderFormItems(triggerFields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}

        <DialogFooter>
          <PrimaryButton type="submit" text="Save" disabled={!isValid} />
          <DefaultButton
            text="Cancel"
            onClick={onDismiss as React.MouseEventHandler<HTMLButtonElement>}
          />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};
