/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { parseISO } from 'date-fns';
import JSZip from 'jszip';
import { useEffect, useState } from 'react';

import type { GroupedSignalsPeriod } from '../../../../modules/analysis-trend-view/utils/getPeriodFilters';
import getPeriodFilters from '../../../../modules/analysis-trend-view/utils/getPeriodFilters';
import { format as signalFormatter } from '../../../../modules/common/utils/signalFormatter';
import { getApiClient } from '../../../../modules/core/apiClient/useApiStore';
import type { DataPoint, ResponseSimplifiedSignal } from '../../../../types';

import type { MopSignalData } from '../config';

type GetDataProps = {
  signal: ResponseSimplifiedSignal;
  period?: GroupedSignalsPeriod;
};

export const useSignalsDataPoints = (
  signals: ResponseSimplifiedSignal[],
  period: GroupedSignalsPeriod,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Map<string, MopSignalData<DataPoint>>>(
    new Map<string, MopSignalData<DataPoint>>([]),
  );

  // Updates the signals data.
  useEffect(() => {
    if (!signals || !period?.key) {
      return;
    }

    if (signals.length === 0) {
      setData(new Map<string, MopSignalData<DataPoint>>([]));
      return;
    }

    const result = new Map<string, MopSignalData<DataPoint>>(
      signals.length !== data.size ? data : [],
    );

    // Removes the unselected signal(s)
    if (signals.length < data.size) {
      const ids = Array.from(result.keys()).filter((id) => !signals.map((s) => s.id).includes(id));
      ids.forEach((id) => result.delete(id));
      setData(result);
      return;
    }

    // Adds the new selected signal(s)
    setIsLoading(true);
    let mount = true;
    const newSignals =
      signals.length !== data.size
        ? signals.filter((signal) => signal?.id && !result.has(signal.id))
        : [...signals];
    Promise.all(newSignals.map((signal) => getData({ signal, period })))
      .then((response) => {
        response.forEach((signalData) => {
          if (signalData?.data && signalData.data.length > 0) {
            result.set(signalData.id, signalData);
          }
        });
      })
      .finally(() => {
        if (!mount) return;
        setIsLoading(false);
        setData(result);
      });

    // Clears all resources
    return () => {
      mount = false;
    };
  }, [signals?.length, period?.key, period?.startDate, period?.endDate]);

  return { data, isLoading };
};

const getData = async ({
  signal,
  period,
}: GetDataProps): Promise<MopSignalData<DataPoint> | undefined> => {
  if (!signal?.id || !period?.key) {
    return undefined;
  }

  const periodFilters = getPeriodFilters(period, signal);
  return getApiClient()
    .get(
      `data/read/v1/trends/${signal.id}/zip?startDateTime=${periodFilters.startDate}&endDateTime=${periodFilters.endDate}`,
      {
        responseType: 'arraybuffer',
      },
    )
    .then(async (response) => {
      const zip = await JSZip.loadAsync(response.data);
      const jsonString = await zip.file('trendsList.json')?.async('string');
      const json = JSON.parse(jsonString || '[]');
      const result: MopSignalData<DataPoint> = {
        id: signal.id!,
        name: signalFormatter(signal),
        unit: `(${signal.unit || '-'})`,
        data: json.dataPoints.map(({ t, v }: { t: string; v: number }) => ({
          timeStamp: parseISO(t).getTime(),
          value: v,
        })) as DataPoint[],
      };

      return result;
    });
};
