import type { IStackProps, ITextProps } from '@fluentui/react';
import type { Control, FieldError } from 'react-hook-form';

export type FormHookProps = {
  errors?: {
    [schemaProp: string]: FieldError;
  };
  // errors?: FieldErrors<any>;
  control: Control<any>;
};

export type FormGroupProps = {
  label?: string;
  labelProps?: Partial<ITextProps>;
  stackProps?: Partial<IStackProps>;
};

export type BaseFormFieldProps = {
  groupProps?: FormGroupProps;
  hookProps: FormHookProps;
};

export enum FormErrorMessages {
  Required = 'This field is required',
}
