import Table, { Column } from "../common/Table";
import BaseDialog, { DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";
import { theme } from "../../theme";

import {
  PrimaryButton,
  Stack,
  Text,
  IFacepilePersona,
  IconButton,
  Facepile,
  IIconProps,
  TooltipHost,
  Icon,
  DialogType,
  DialogFooter,
  DefaultButton,
  Spinner,
  SpinnerSize,
  Dialog,
  MessageBarType,
} from "@fluentui/react";

import { useState } from "react";
import { AddRole } from "./AddRole";
import { AddRolePersona } from "./AddRolePersona";
import {
  PermissionExpanded,
  ServiceAccountAddRole,
  ServiceAccountDetails,
} from "../ServiceAccount/models";
import { iconStyle, textStyle, titleStyle } from "../../schema/Constants";
import { Personas } from "../../schema/personas";

import {
  addManyPermissionsApi,
  deletePermission,
  removePermissionsOfMember,
} from "../Permissions/api";
import { RequestRolesAddMany } from "../Permissions/models";
import { UserDetails } from "../UsersRBCA/models";
import { SimpleDeleteConfirm } from "../Generic/SimpleDeleteConfirm";
import {
  ServiceAccountAddRoleToShow,
  personaProps,
} from "../ServiceAccount/AddDialog";

const deleteAllRolesIcon: IIconProps = { iconName: "DeleteTable" };

type GetColumnsOpts = {
  hasActions: boolean;
  onDelete: (serviceAccount: ServiceAccountAddRole) => void;
};

type GetColumnsCurrentRolesOpts = {
  hasActions: boolean;
  handleRoleDeletion: (role: PermissionExpanded) => void;
};

const getColumnsCurrent = ({
  hasActions,
  handleRoleDeletion,
}: GetColumnsCurrentRolesOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "role",
      name: "Role",
      fieldName: "role",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "scope",
      name: "Scope",
      fieldName: "scope",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "scopeResource",
      name: "Scope Resource",
      fieldName: "scopeResource",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "expiresAtUtc",
      name: "Expires At Utc",
      fieldName: "expiresAtUtc",
      minWidth: 100,
      isSortable: true,
    },
  ];
  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (role: PermissionExpanded) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => handleRoleDeletion(role)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }
  return columns;
};

const getColumns = ({ hasActions, onDelete }: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "role",
      name: "Role",
      fieldName: "role",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "scopeLevel",
      name: "Scope Level",
      fieldName: "scopeLevel",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "resource",
      name: "Resource",
      fieldName: "resource",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "expiresAtUtc",
      name: "Expires at UTC",
      fieldName: "expiresAtUtc",
      minWidth: 100,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (role: ServiceAccountAddRole) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={1}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(role)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

type RolesComponentDetProps = {
  data?: ServiceAccountDetails | UserDetails;
  memberId: string;
  detailsFunction: (id: string) => Promise<any>;
  onSuccess?: () => void;
};

export const RolesComponentDetails = ({
  data,
  memberId,
  detailsFunction,
  onSuccess,
}: RolesComponentDetProps) => {
  const [rolesToAdd, setRolesToAdd] = useState<ServiceAccountAddRoleToShow[]>(
    []
  );
  const [showAddRole, setShowAddRole] = useState(false);
  const [showAddRolePersona, setShowAddRolePersona] = useState(false);

  const [showDialogRoles, setShowDialogRoles] = useState(false);

  const [persona, setPersona] = useState<personaProps>();
  const [dataNew, setDataNew] = useState<ServiceAccountDetails | UserDetails>(
    data
  );
  const [showBaseDialog, setShowBaseDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState<{
    data: PermissionExpanded | undefined;
    context: "deleteSingleRole";
  }>();

  const onCLickPersona = (ev, persona) => {
    setShowAddRolePersona((prv) => !prv);
    setPersona(persona.data);
  };

  const onDeleteCurrentRole = async (role: PermissionExpanded) => {
    await deletePermission(role.id);
    await detailsFunction(memberId).then((resp) => {
      if ("permissions" in resp) setDataNew(resp);
      else
        notification.warning(
          "Problem reaching the response, please try it again later."
        );
    });

    notification.success("Permission deleted successfully.");
  };

  const onDelete = (roles: ServiceAccountAddRoleToShow) => {
    setRolesToAdd(
      rolesToAdd.filter(
        (rol) =>
          rol.roleId !== roles.roleId ||
          rol.scopeLevelId !== roles.scopeLevelId ||
          rol.scopeResourceId !== roles.scopeResourceId
      )
    );
  };

  const personas: IFacepilePersona[] = Personas.allPersonas.map((eleF, ind) => {
    return {
      personaName: eleF.name,
      imageInitials: eleF.imageInitials,
      data: { persona: eleF.personaRoles, root: eleF.root },
      onClick: onCLickPersona,
      initialsColor: ind,
    };
  });

  return (
    <div style={{ marginTop: "50px" }}>
      <Stack verticalFill>
        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign="start">
          <Text style={titleStyle}>Current</Text>
          <TooltipHost
            content={
              !dataNew?.permissions || dataNew?.permissions.length < 1
                ? "No current values to delete"
                : "Delete all current roles"
            }
          ></TooltipHost>
          <Stack grow styles={{ root: { color: theme.palette.white } }}>
            .
          </Stack>
          <Stack horizontal>
            <DefaultButton
              text="Remove All Roles"
              onClick={() => setShowBaseDialog(true)}
              disabled={
                !dataNew?.permissions || dataNew?.permissions.length < 1
              }
              style={{ marginRight: "20px" }}
            />
            <PrimaryButton
              text="Append"
              onClick={() => setShowDialogRoles(true)}
            />
          </Stack>
        </Stack>
      </Stack>
      <Table
        persistOpts={{
          key: "table-roles",
          version: 2,
        }}
        header={{
          title: "",
        }}
        items={dataNew?.permissions ? dataNew.permissions : []}
        columns={getColumnsCurrent({
          hasActions: true,
          handleRoleDeletion(role) {
            setSelected({ context: "deleteSingleRole", data: role });
          },
        })}
        hasSelection={false}
      />

      <Dialog
        hidden={!showDialogRoles}
        dialogContentProps={{
          title: "Append",
          type: DialogType.close,
          onDismiss: () => {
            setShowDialogRoles(false);
            if (rolesToAdd.length > 0)
              notification.open({
                type: MessageBarType.warning,
                message: "Info: Some roles to add were not updated.",
              });
          },
        }}
        minWidth={"70vw"}
        maxWidth={"70vw"}
      >
        <div style={{ minWidth: "300px", padding: "40px" }}>
          <AddRole
            show={showAddRole}
            onClose={() => setShowAddRole((prv) => !prv)}
            rolesToAdd={rolesToAdd}
            setRolesToAdd={setRolesToAdd}
          ></AddRole>
          {persona && (
            <AddRolePersona
              key={Math.random()}
              show={showAddRolePersona}
              onClose={() => setShowAddRolePersona((prv) => !prv)}
              rolesToAdd={rolesToAdd}
              setRolesToAdd={setRolesToAdd}
              persona={persona}
            ></AddRolePersona>
          )}

          <Stack verticalFill>
            <Stack
              horizontal
              tokens={{ childrenGap: 20 }}
              horizontalAlign="start"
            >
              <Text style={titleStyle}>To Append</Text>
              <TooltipHost
                content={
                  !rolesToAdd || rolesToAdd.length < 1
                    ? "No values to delete"
                    : "Delete all roles to add."
                }
              >
                <IconButton
                  iconProps={deleteAllRolesIcon}
                  onClick={() => setRolesToAdd([])}
                  disabled={!rolesToAdd || rolesToAdd.length < 1}
                />
              </TooltipHost>

              <PrimaryButton
                text="Single role"
                onClick={() => setShowAddRole((prv) => !prv)}
              />
              <Facepile personas={personas} overflowPersonas={personas} />
              <Stack grow styles={{ root: { color: theme.palette.white } }}>
                .
              </Stack>
              <Stack horizontal horizontalAlign="end">
                <PrimaryButton
                  text="Append"
                  onClick={async () => {
                    setIsLoading(true);
                    const addRolesPerUser: RequestRolesAddMany = {
                      memberAndOrServiceAccountIds: [memberId],
                      roles: rolesToAdd,
                    };
                    await addManyPermissionsApi(addRolesPerUser).then((res) => {
                      if (!(res.status < 200 && res.status > 210))
                        notification.success("Updated roles successfully");
                      else notification.success("Something went wrong");
                    });

                    await detailsFunction(memberId).then((resp) => {
                      if ("permissions" in resp) {
                        setDataNew(resp);
                        setRolesToAdd([]);
                      } else
                        notification.warning(
                          "Problem reaching the response, please try it again later."
                        );
                      setShowDialogRoles(false);
                      setIsLoading(false);
                      onSuccess && onSuccess();
                    });
                  }}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                  disabled={!rolesToAdd || rolesToAdd.length < 1}
                />
              </Stack>
            </Stack>
            <Table
              persistOpts={{
                key: "table-rolesAndScopes",
                version: 2,
              }}
              header={{
                title: "",
              }}
              items={rolesToAdd}
              columns={getColumns({
                hasActions: true,
                onDelete,
              })}
              hasSelection={false}
              isLoading={showAddRole}
              isError={rolesToAdd.length === 0}
            />
          </Stack>
        </div>
      </Dialog>
      <BaseDialog
        hidden={!showBaseDialog}
        dialogContentProps={{
          title: "Attention!",
          type: DialogType.close,
          onDismiss: () => setShowBaseDialog(false),
        }}
        size={DialogSize.M}
      >
        <div style={textStyle}>
          You are about to delete all permissions of the selected user.
        </div>
        <DialogFooter>
          <PrimaryButton
            text="Delete"
            onClick={async () => {
              setIsLoading(true);
              await removePermissionsOfMember(memberId).then((res) => {
                if (!(res.status < 200 && res.status > 210)) {
                  notification.success("Deleted successfully");
                } else notification.error("Something went wrong");
                setIsLoading(false);
                setShowBaseDialog(false);
              });
              await detailsFunction(memberId).then((resp) => {
                if ("permissions" in resp) setDataNew(resp);
                else
                  notification.warning(
                    "Problem reaching the response, please try it again later."
                  );
              });
            }}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            text="Close"
            onClick={() => {
              setShowBaseDialog(false);
            }}
          />
        </DialogFooter>
      </BaseDialog>

      {selected?.context === "deleteSingleRole" && (
        <SimpleDeleteConfirm
          show={selected?.context === "deleteSingleRole"}
          onClose={() => setSelected(undefined)}
          text="the role selected"
          handleOk={() => onDeleteCurrentRole(selected.data)}
        />
      )}
    </div>
  );
};
