import { useState } from "react";

import LayoutIcon from "../../../../assets/svg/LayoutIcon";
import useWebAnalysisPermissions from "../../../../Hooks/useWebAnalysisPermissions";
import GroupDialog from "./dialogs/GroupDialog";
import useInfoStore from "../hooks/useInfoStore";
import { dropdownIconStyle } from "../../../../assets/styles";
import type { Group } from "../../../../types";
import BaseCommandBar from "../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../common/CommandBar/methods";
import {
  buttonStyles,
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar/methods";

const menuItems = [
  {
    key: "0",
    text: "Auto align",
    style: {},
  },
  {
    key: "1",
    text: "Single item per row",
    style: {},
  },
  {
    key: "2",
    text: "Two items per row",
    style: {},
  },
  {
    key: "3",
    text: "Three items per row",
    style: {},
  },
];

export enum FormType {
  Edit = "Edit",
  New = "New",
}

type InfosCommandBarProps = {
  handleSearch?: (value: string) => void;
  allGroups: Group[];
};

const CommandBar = ({ handleSearch, allGroups }: InfosCommandBarProps) => {
  const { perRow, dispatch } = useInfoStore((state) => ({
    perRow: state.perRow,
    dispatch: state.dispatch,
  }));

  const [isAddGroupDialogHidden, setIsAddGroupDialogHidden] = useState(true);
  const toggleIsAddGroupDialogHidden = () =>
    setIsAddGroupDialogHidden(!isAddGroupDialogHidden);

  const { useReadyPermission } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });

  function getItems(
    hasMachineCvAdministratorPermission: boolean
  ): CommandBarItemProps[] {
    const baseItems: CommandBarItemProps[] = [
      {
        key: "layout",
        text: "Layout",
        type: CommandBarItemType.Dropdown,
        onRenderProps: {
          defaultSelectedKey: `${perRow}`,
          options: menuItems,
          onChange: (_: never, item: { key: string }) =>
            dispatch({
              type: "SET_PER_ROW",
              payload: parseInt((item?.key as string) || "0"),
            }),
          onRenderLabelProps: {
            label: "Layout",
            icon: (
              <LayoutIcon fill="rgb(44, 82, 159)" style={dropdownIconStyle} />
            ),
          },
        },
      },
    ];

    if (hasMachineCvAdministratorPermission) {
      const newGroupItem: CommandBarItemProps = {
        key: "new",
        text: "New group",
        className: "secondary-dropdown",
        type: CommandBarItemType.Button,
        iconProps: { iconName: "Add" },
        buttonStyles,
        onClick: toggleIsAddGroupDialogHidden,
      };

      return [newGroupItem, ...baseItems];
    }

    return baseItems;
  }

  const items = getItems(hasMachineCvAdministratorPermission);

  return (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(items)}
        onSearch={handleSearch}
      />
      <GroupDialog
        formType={FormType.New}
        hidden={isAddGroupDialogHidden}
        allGroups={allGroups}
        onDismiss={toggleIsAddGroupDialogHidden}
      />
    </>
  );
};

export default CommandBar;
