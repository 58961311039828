export const setChartDisabled = (chart: any, disabled: boolean) => {
  const scrollbar = chart.get('scrollbarX');
  scrollbar.startGrip.set('draggable', !disabled);
  scrollbar.endGrip.set('draggable', !disabled);
  scrollbar.thumb.set('draggable', !disabled);
  chart.set('panX', !disabled);
  chart.set('panY', !disabled);
  chart.set('wheelX', disabled ? 'none' : 'zoomY');
  chart.set('wheelY', disabled ? 'none' : 'zoomX');
  chart.set('disabled', disabled);
};
