import type { SVGProps } from 'react';
import React from 'react';

const DocumentIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 0H10C10.5304 0 11.0391 0.210714 11.4142 0.585786C11.7893 0.960859 12 1.46957 12 2V14C12 14.5304 11.7893 15.0391 11.4142 15.4142C11.0391 15.7893 10.5304 16 10 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0ZM2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H10C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V2C11 1.73478 10.8946 1.48043 10.7071 1.29289C10.5196 1.10536 10.2652 1 10 1H2ZM9 4.5C9.00004 4.61703 8.95903 4.73036 8.88411 4.82026C8.80919 4.91016 8.70511 4.97094 8.59 4.992L8.5 5H3.5C3.37505 5.00023 3.25455 4.95367 3.16222 4.86949C3.06988 4.78531 3.01241 4.66961 3.00112 4.54518C2.98984 4.42074 3.02554 4.29659 3.10122 4.19717C3.1769 4.09775 3.28705 4.03026 3.41 4.008L3.5 4H8.5C8.63261 4 8.75979 4.05268 8.85355 4.14645C8.94732 4.24021 9 4.36739 9 4.5ZM9 8C9.00004 8.11703 8.95903 8.23036 8.88411 8.32026C8.80919 8.41016 8.70511 8.47093 8.59 8.492L8.5 8.5H3.5C3.37505 8.50023 3.25455 8.45367 3.16222 8.36949C3.06988 8.28531 3.01241 8.16961 3.00112 8.04518C2.98984 7.92074 3.02554 7.79659 3.10122 7.69717C3.1769 7.59775 3.28705 7.53026 3.41 7.508L3.5 7.5H8.5C8.63261 7.5 8.75979 7.55268 8.85355 7.64645C8.94732 7.74021 9 7.86739 9 8ZM9 11.5C9.00004 11.617 8.95903 11.7304 8.88411 11.8203C8.80919 11.9102 8.70511 11.9709 8.59 11.992L8.5 12H3.5C3.37505 12.0002 3.25455 11.9537 3.16222 11.8695C3.06988 11.7853 3.01241 11.6696 3.00112 11.5452C2.98984 11.4207 3.02554 11.2966 3.10122 11.1972C3.1769 11.0977 3.28705 11.0303 3.41 11.008L3.5 11H8.5C8.63261 11 8.75979 11.0527 8.85355 11.1464C8.94732 11.2402 9 11.3674 9 11.5Z'
        fill='#3B3A39'
      />
    </svg>
  );
};

export default DocumentIcon;
