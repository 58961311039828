import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import DalogLogo from "../../../assets/svg/dalogWaterfallLogo.svg";
import type { IColorScale } from "../components/WaterfallChart/config";
import { colorScaleOptions } from "../components/WaterfallChart/config";

interface WaterfallStore {
  yaxisStartDate: string;
  yaxisEndDate: string;
  waterfallData: any[];
  resetColorBar: Date;
  colorScale: IColorScale;
  layout: any;
  chartHoverParams: any;
  interpolation: string;
  reduction: number;
  plotType: string;
  setWaterfallData: (data: any[]) => void;
  setResetColorBar: (date: Date) => void;
  setColorScale: (scale: IColorScale) => void;
  setLayout: (layout: any) => void;
  setChartHoverParams: (params: any) => void;
  setInterpolation: (interpolation: string) => void;
  setReduction: (reduction: number) => void;
  setPlotType: (plotType: string) => void;
  setYaxisDate: (dates: any) => void;
}

const watermarkImages = [
  {
    source: DalogLogo,
    x: 0.005,
    y: 1,
    sizex: 0.14,
    sizey: 0.14,
    xanchor: "left",
    yanchor: "top",
    opacity: 0.06,
  },
  {
    source: DalogLogo,
    x: 1,
    y: 1,
    sizex: 0.14,
    sizey: 0.14,
    xanchor: "right",
    yanchor: "top",
    opacity: 0.06,
  },
  {
    source: DalogLogo,
    x: 0.005,
    y: 0.007,
    sizex: 0.14,
    sizey: 0.14,
    xanchor: "left",
    yanchor: "bottom",
    opacity: 0.06,
  },
  {
    source: DalogLogo,
    x: 1,
    y: 0.005,
    sizex: 0.14,
    sizey: 0.14,
    xanchor: "right",
    yanchor: "bottom",
    opacity: 0.06,
  },
  {
    source: DalogLogo,
    x: 0.5,
    y: 0.5,
    sizex: 0.3,
    sizey: 0.3,
    xanchor: "center",
    yanchor: "middle",
    opacity: 0.06,
  },
];

const useWaterfallStore = createWithEqualityFn<WaterfallStore>(
  (set) => ({
    yaxisStartDate: "",
    yaxisEndDate: "",
    waterfallData: [],
    resetColorBar: new Date(),
    colorScale: colorScaleOptions[0],
    layout: {
      height: 600,
      responsive: true,
      autosize: true,
      width: "100%",
      uirevision: true,
      yaxis: {
        type: "category",
        tickfont: {
          size: 10,
        },
        nticks: 10,
        autorange: true,
        autosize: true,
      },
      xaxis: {
        title: {
          text: "Frequency (Hz)",
          standoff: 20,
          font: {
            size: 10,
          },
        },
        tickfont: {
          size: 10,
        },
        showline: true,
        ticks: "outside",
        showticklabels: true,
        autorange: true,
      },
      scene: {
        xaxis: {
          gridcolor: "rgb(255, 255, 255)",
          zerolinecolor: "rgb(255, 255, 255)",
          showbackground: true,
          backgroundcolor: "rgb(230, 230,230)",
        },
        yaxis: {
          gridcolor: "rgb(255, 255, 255)",
          zerolinecolor: "rgb(255, 255, 255)",
          showbackground: true,
          backgroundcolor: "rgb(230, 230, 230)",
        },
        zaxis: {
          gridcolor: "rgb(255, 255, 255)",
          zerolinecolor: "rgb(255, 255, 255)",
          showbackground: true,
          backgroundcolor: "rgb(230, 230,230)",
        },
        aspectratio: {
          x: 6,
          y: 4,
          z: 1,
        },
        aspectmode: "manual",
        camera: {
          eye: { x: 0, y: 4.5, z: 1.25 },
        },
      },
      margin: {
        l: 80,
        r: 0,
        t: 0,
      },
      images: watermarkImages,
    },
    chartHoverParams: undefined,
    interpolation: "best",
    reduction: 10,
    plotType: "heatmap",
    setWaterfallData: (data) => set({ waterfallData: data }),
    setResetColorBar: (date) => set({ resetColorBar: date }),
    setColorScale: (scale) => set({ colorScale: scale }),
    setLayout: (layout) => set({ layout }),
    setChartHoverParams: (params) => set({ chartHoverParams: params }),
    setInterpolation: (interpolation) => set({ interpolation }),
    setReduction: (reduction) => set({ reduction }),
    setPlotType: (plotType) => set({ plotType }),
    setYaxisDate: ({ start, end }) =>
      set((store) => ({
        yaxisStartDate: start || store.yaxisStartDate,
        yaxisEndDate: end || store.yaxisEndDate,
      })),
  }),
  shallow
);

export default useWaterfallStore;
