/* eslint-disable @typescript-eslint/ban-ts-comment */
export default function roundNumber(num: number, scale: number) {
  if (!('' + num).includes('e')) {
    // @ts-ignore
    return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
  } else {
    const arr = ('' + num).split('e');
    let sig = '';
    if (+arr[1] + scale > 0) {
      sig = '+';
    }
    // @ts-ignore
    return +(Math.round(+arr[0] + 'e' + sig + (+arr[1] + scale)) + 'e-' + scale);
  }
}
