import { sortBy } from "lodash-es";

import { useState } from "react";
import { Marker as MapGlMarker } from "react-map-gl";
import { useNavigate } from "react-router-dom";
import type useSupercluster from "use-supercluster";

import { useModeSelect } from "../../Home/HomePage/hooks/useModeSelect";
import useOverviewStore from "../../MachineCV/MachineCVHomePage/hooks/useOverviewStore";
import Marker from "./Marker";
type _Internal = ReturnType<typeof useSupercluster>;

type MapMarkerProps = {
  supercluster: _Internal["supercluster"];
  cluster: _Internal["clusters"][0];
};

// TODO: should be generic (used only in Dashboard Page)
const MarkerMap = ({ cluster, supercluster }: MapMarkerProps) => {
  const navigate = useNavigate();
  const { changeSearch } = useOverviewStore();
  const { getMarkerNavigationArgs, getModeColor } = useModeSelect();

  const [isPopupVisible, setPopupVisible] = useState(false);

  const [longitude, latitude] = cluster.geometry.coordinates;

  const {
    cluster: isCluster,
    id,
    name,
    slug = "",
  } = cluster.properties as {
    cluster: boolean;
    id: string;
    name: string;
    slug: string;
  };

  if (isCluster) {
    const { point_count: count } = cluster.properties as {
      point_count: number;
    };

    const clusterPoints = sortBy(
      supercluster?.getLeaves(cluster.id as number, Infinity).map((point) => ({
        id: point?.properties?.id,
        uniqId: point?.properties?.uniqId as string,
        name: point?.properties?.name as string,
        slug: point?.properties?.slug as string,
      })),
      [({ name }) => name?.toLowerCase()]
    );

    return (
      <MapGlMarker
        key={`cluster-${cluster.id}`}
        latitude={latitude}
        longitude={longitude}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          role="button"
          tabIndex={0}
          className="marker-cluster"
          style={{ background: getModeColor() }}
          onClick={() => {
            setPopupVisible(!isPopupVisible);
          }}
        >
          {count}
          {isPopupVisible && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              role="button"
              tabIndex={-1}
              className="marker-cluster-popup-list"
              onClick={(e) => e.stopPropagation()}
            >
              {clusterPoints?.map(({ id, uniqId, name, slug }) => {
                const { to, options } = getMarkerNavigationArgs({ id, slug });
                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <div
                    key={uniqId}
                    role="button"
                    tabIndex={-1}
                    className="marker-cluster-popup-list-item"
                    onClick={() => {
                      changeSearch("");
                      navigate(to, options);
                    }}
                  >
                    <div>{name}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </MapGlMarker>
    );
  }

  const { to, options } = getMarkerNavigationArgs({ id, slug });

  return (
    <MapGlMarker
      key={cluster.properties?.uniqId || cluster.properties?.id}
      latitude={latitude}
      longitude={longitude}
    >
      <Marker
        name={name}
        onClick={() => {
          changeSearch("");
          navigate(to, options);
        }}
      />
    </MapGlMarker>
  );
};

export default MarkerMap;
