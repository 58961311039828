import * as am5 from '@amcharts/amcharts5';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import type { XYChart } from '@amcharts/amcharts5/xy';

import ExportIcon from '../../../assets/svg/ExportIcon';
import { format } from '../../../utils';
import type { ResponseSimplifiedSignal } from '../../../types';

type BreadcrumbLabelProps = {
  root: am5.Root;
  container: Element;
  corporation?: string;
  company?: string;
  project?: string;
  machine?: string;
};

const getBreadCrumbLabel = ({
  root,
  container,
  corporation,
  company,
  project,
  machine,
}: BreadcrumbLabelProps): am5.Label => {
  container.classList.add('breadcrumb-hidden');
  const label = `${corporation}  〉 ${company}  〉 ${project}  〉 [bold #3B3A39]${machine}[/]`;

  return am5.Label.new(root, {
    id: `${root.dom.id}-footer-label`,
    text: label,
    x: 0,
    centerX: 0,
    fontSize: 8,
    fill: am5.color('#878583'),
    oversizedBehavior: 'wrap-no-break',
    textAlign: 'left',
    paddingBottom: 5,
  });
};

export const createChartExport = ({
  root,
  chart,
  selector,
  corporation,
  company,
  project,
  machine,
}: {
  root: am5.Root;
  chart: XYChart;
  signal?: ResponseSimplifiedSignal;
  selector: string | undefined;
  corporation: string | undefined;
  company: string | undefined;
  project: string | undefined;
  machine: string | undefined;
}) => {
  if (selector) {
    const exportButton = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {
        container: document.querySelector(`.${selector}`) as HTMLElement,
        useDefaultCSS: false,
        items: [
          {
            label: 'Export Options',
            type: 'separator',
          },
          {
            label: 'PNG',
            type: 'format',
            exportType: 'image',
            format: 'png',
          },
          {
            label: 'JPG',
            type: 'format',
            exportType: 'image',
            format: 'jpg',
          },
          // {
          //   label: 'Edit Options',
          //   type: 'separator',
          // },
          // {
          //   type: 'custom',
          //   label: 'Annotate',
          //   callback: function () {
          //     close();
          //     annotator.toggle();
          //   },
          // },
        ],
      }),
      filePrefix: `DALOG_${format(new Date())}`,
      title: 'Export',
    });

    const node = document.createElement('div');

    node.innerHTML = `<div class='amcharts-export-button'>
    <span class='amcharts-export-button__icon'>${ExportIcon}</span>
      <span class='amcharts-export-button__text'>Save as</span>
   </div>`;

    exportButton
      .get('menu')
      ?.get('container')
      ?.children.item(0)
      ?.children.item(0)
      ?.replaceChildren(node);

    // Adds the breadcrumb when export starts.
    exportButton.events.on('downloadstarted', () => {
      const container = document.querySelector('[id*="breadcrumb-container"]');
      if (container && corporation !== undefined) {
        chart.bottomAxesContainer.children.push(
          getBreadCrumbLabel({
            root,
            container,
            corporation,
            company,
            project,
            machine,
          }),
        );
      }
    });

    // Removes the breadcrumb after export.
    exportButton.events.on('exportfinished', () => {
      const container = document.querySelector('[id*="breadcrumb-container"]');
      chart.bottomAxesContainer.children.each((child) => {
        if (child instanceof am5.Label && child.get('id') === `${root.dom.id}-footer-label`) {
          child.dispose();
        }
      });

      container && container.classList.remove('breadcrumb-hidden');
    });
  }
};
