import { useMemo } from 'react';
import * as ReactRouterDOM from 'react-router-dom';

type DeepNonNullable<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

export type LocationSearchData = {
  search: string;
  // Breadcrumb
  corporation: string;
  company: string;
  project: string;
  machine: string;
  // Machine Id
  id: string | null;
  // Sensor No
  sensorNo: string | null;
};

export const getQuery: UseLocationSearch['1'] = ({ corporation, company, project, machine, id }) =>
  `corporation=${corporation}&company=${company}&project=${project}&machine=${machine}&id=${id}`;

export const parseParams = (params: URLSearchParams): Omit<LocationSearchData, 'search'> => ({
  corporation: params.get('corporation') || '-',
  company: params.get('company') || '-',
  project: params.get('project') || '-',
  machine: params.get('machine') || '-',
  sensorNo: params.get('sensorNo') || '-',
  id: params.get('id'),
});

export type UseLocationSearch = [
  LocationSearchData,
  (data: DeepNonNullable<Omit<LocationSearchData, 'search'>>) => string,
  boolean,
];

export const useLocationQueryParams = (paramKey: any) => {
  const location = ReactRouterDOM.useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  return params.get(paramKey);
};

export const useLocationSearch = (): UseLocationSearch => {
  const location = ReactRouterDOM.useLocation();

  const params = useMemo(() => new URLSearchParams(location.search), [location]);

  const data: LocationSearchData = useMemo(
    () => ({
      search: location.search,
      ...parseParams(params),
    }),
    [location, params],
  );

  const isValid = useMemo(() => Object.values(data).every((value) => value), [data]);

  return [data, getQuery, isValid];
};
