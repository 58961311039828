import { useCallback, useEffect, useMemo, useState } from "react";
import { treeHandlers, useTreeState } from "react-hyper-tree";

import { SCROLL_OFFSET_TOP } from "../../../..//config/constants";
import {
  useLocationSearch,
  useMachineList,
  useLastSelectedSignal,
} from "../../../../Hooks";

import { PAGE_TYPE_RAW_DATA } from "./SignalsList";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";

import { getTreeData } from "./utils";
import { useQuery } from "@tanstack/react-query";

const useSignalsList = () => {
  const [locationData] = useLocationSearch();
  const [sensorNodeId, setSensorNodeId] = useState<string | undefined>();

  const { getLastSelectedSignal } = useLastSelectedSignal({
    page: PAGE_TYPE_RAW_DATA,
  });
  const lastSelectedSignal = useMemo(
    () => getLastSelectedSignal(),
    [getLastSelectedSignal]
  );

  const [openedMachineNode, setOpenedMachineNode] = useState<any>(null);
  const { isLoading: isSignalListLoading, data: signalsList } = useQuery(
    ["signals-list"],
    () => [],
    {
      enabled: false,
    }
  );

  const hasSignals = !isSignalListLoading && signalsList.length > 0;

  const signalId = locationData?.id || lastSelectedSignal?.signalId;
  const machineId = locationData?.id || lastSelectedSignal?.machineId;

  const selectedSignals = useSelectedSignalsStore(
    (store) => store.selectedSignals
  );

  const { data: list, isLoading: isMachineListLoading } = useMachineList();

  const data = useMemo(() => {
    if (!list) return [];
    return getTreeData(list, (sensors: any) => {
      if (locationData?.sensorNo && sensors?.length > 0) {
        const sensor = sensors.find(
          (item: any) => item.name === locationData.sensorNo
        );
        if (sensor) setSensorNodeId(sensor.id);
      }
      return sensors;
    });
  }, [list, locationData]);

  const { required, handlers, instance } = useTreeState({
    id: "raw-data-signal-tree",
    multipleSelect: true,
    data,
  });

  const defaultSelected = useMemo(() => {
    if (isMachineListLoading || !data.length || !hasSignals) return null;

    const machine = instance.getNodeById(signalId);

    return machine && !machine.isLoading() ? machine : null;
  }, [isMachineListLoading, instance, signalId, data.length, hasSignals]);

  const scrollToNode = useCallback((nodeId: string | number) => {
    const container =
      document.querySelector<HTMLDivElement>(".sidebar-scrollbar");
    if (!container) return;

    const node = container.querySelector(`[data-node-id="${nodeId}"]`);
    if (!node) return;

    container.scrollTo({
      behavior: "smooth",
      top: node.getBoundingClientRect().top - SCROLL_OFFSET_TOP,
    });
  }, []);

  useEffect(() => {
    if (!hasSignals) return;

    if (signalId) {
      const treeSignalNode = instance.getNodeById(signalId);
      if (treeSignalNode) {
        const parent = treeSignalNode.getParent();
        if (parent) parent.setOpened(true);
        treeSignalNode.setOpened(true);
      }
    }

    const nodeId = signalId || defaultSelected?.id;

    scrollToNode(nodeId);
  }, [hasSignals, signalId, instance, scrollToNode, defaultSelected]);

  useEffect(() => {
    if (sensorNodeId) {
      const sensorNode = handlers.getNode(sensorNodeId);
      if (sensorNode) handlers.setOpen(sensorNode);
    }
  }, [defaultSelected?.asyncDataLoaded, sensorNodeId, handlers]);

  useEffect(() => {
    if (machineId && instance?.data?.length > 0 && !openedMachineNode) {
      const machineNode = instance.getNodeById(machineId);
      if (machineNode) {
        treeHandlers.trees["raw-data-signal-tree"].handlers.setOpenByPath(
          machineNode.getPath()
        );
        setOpenedMachineNode(machineNode);
      }
    }
  }, [instance, machineId, selectedSignals, openedMachineNode]);

  useEffect(() => {
    if (openedMachineNode?.asyncDataLoaded) {
      const sensorNode = openedMachineNode.children.find(
        (child: any) => child.data.name === lastSelectedSignal.sensorNo
      );
      if (sensorNode) sensorNode.setOpened(true);
    }
  }, [
    openedMachineNode,
    openedMachineNode?.asyncDataLoaded,
    lastSelectedSignal.sensorNo,
  ]);

  return {
    required,
    handlers,
    hasData: !!data.length,
    isLoading: isMachineListLoading || isSignalListLoading,
  };
};

export default useSignalsList;
