import { IconButton, Stack, TooltipHost } from "@fluentui/react";
import React, { useState } from "react";

import { useLocationSearch } from "../../../../../Hooks";
import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import { FormType } from "../CommandBar";
import EntryDialog from "../dialogs/EntryDialog/EntryDialog";
import { useMachineCVInfo } from "../../hooks/useMachineCVInfo";
import type { Entry, Group } from "../../../../../types";

interface ActionButtonsProps {
  item: Entry;
  group: Group;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, group }) => {
  const [{ id }] = useLocationSearch();
  const { refetch } = useMachineCVInfo({ machineId: id || "" });

  const [isDialogHidden, setIsDialogHidden] = useState(true);
  const toggleIsDialogHidden = () => setIsDialogHidden(!isDialogHidden);

  const [isDeleteEntryDialogHidden, setIsDeleteEntryDialogHidden] =
    useState(true);
  const toggleIsDeleteEntryDialogHidden = () =>
    setIsDeleteEntryDialogHidden(!isDeleteEntryDialogHidden);

  return (
    <Stack horizontal>
      <TooltipHost content="Edit entry">
        <IconButton
          styles={{ root: { height: "auto" } }}
          iconProps={{ iconName: "Edit" }}
          onClick={toggleIsDialogHidden}
        />
      </TooltipHost>
      <TooltipHost content="Delete entry">
        <IconButton
          styles={{ root: { height: "auto" } }}
          iconProps={{ iconName: "Delete" }}
          onClick={toggleIsDeleteEntryDialogHidden}
        />
      </TooltipHost>
      <EntryDialog
        formType={FormType.Edit}
        hidden={isDialogHidden}
        entry={item}
        group={group}
        onDismiss={toggleIsDialogHidden}
      />
      <DeleteDialog
        dialogContentProps={{
          onDismiss: toggleIsDeleteEntryDialogHidden,
        }}
        data={{ ids: [item.id as string], name: item.name }}
        hidden={isDeleteEntryDialogHidden}
        route={"meta/machinecv/v1/infos"}
        onSuccess={refetch}
      />
    </Stack>
  );
};

export default ActionButtons;
