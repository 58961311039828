import { DirectionalHint } from "@fluentui/react";
import type { IPersonaProps } from "@fluentui/react/lib/Persona";
import type { IBasePickerSuggestionsProps } from "@fluentui/react/lib/Pickers";
import { NormalPeoplePicker } from "@fluentui/react/lib/Pickers";

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: "Suggested People",
  mostRecentlyUsedHeaderText: "Suggested People",
  noResultsFoundText: "No results found",
};

type PeoplePickerProps = {
  data: IPersonaProps[];
  onChange: (ids: string[]) => void;
};

export const PeoplePicker = ({ data, onChange }: PeoplePickerProps) => {
  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

      filteredPersonas = removeDuplicates(
        filteredPersonas,
        currentPersonas as IPersonaProps[]
      );
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filteredPersonas;
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return data.filter((item) => item.text?.includes(filterText));
  };

  const returnMostRecentlyUsed = (
    currentPersonas: IPersonaProps[] | undefined
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return removeDuplicates(data, currentPersonas as IPersonaProps[]);
  };

  const onPickerChange = (items: IPersonaProps[] | undefined) => {
    onChange(items?.map((item) => item.id) as string[]);
  };

  return (
    <NormalPeoplePicker
      removeButtonAriaLabel="Remove"
      pickerSuggestionsProps={suggestionProps}
      pickerCalloutProps={{ directionalHint: DirectionalHint.bottomRightEdge }}
      onChange={onPickerChange}
      onResolveSuggestions={onFilterChanged}
      onEmptyResolveSuggestions={returnMostRecentlyUsed}
    />
  );
};

function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[]
) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(
  persona: IPersonaProps,
  personas: IPersonaProps[]
) {
  if (!Array.isArray(personas)) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}
