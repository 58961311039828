import { DefaultButton } from "@fluentui/react";
import type { CSSProperties } from "react";

import { useMap } from "../../../../common/Map";

const containerStyles: CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "start",
  marginBottom: 8,
  gap: 8,
};

const buttonStyle: CSSProperties = {
  border: 0,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  background: "white",
  borderRadius: 2,
  height: 32,
  padding: 8,
};

const labelStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: 8,
};

const continents = [
  { name: "Africa", center: [8.8701, 19.7472] },
  { name: "Asia", center: [34.0479, 100.6197] },
  { name: "Australia", center: [-25.2744, 133.7751] },
  { name: "Europe", center: [54.906667, 25.32] },
  { name: "North America", center: [54.526, -105.2551] },
  { name: "South America", center: [-14.235, -51.9253] },
];

export const Continents = () => {
  const {
    actions: { changeViewport },
  } = useMap();

  return (
    <div style={containerStyles}>
      <div style={labelStyles}>Go to:</div>
      {continents.map(({ name, center }) => (
        <DefaultButton
          key={name}
          style={buttonStyle}
          onClick={() => {
            changeViewport({
              latitude: center[0],
              longitude: center[1],
              zoom: 3,
            });
          }}
        >
          {name}
        </DefaultButton>
      ))}
    </div>
  );
};
