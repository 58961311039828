import { uniqueId } from "lodash-es";
import React from "react";

import { DashboardCard } from "../Dashboards/DashboardCard";
import { ShowMoreGrid } from "../Dashboards/ShowMoreGrid";
import { MachineCard } from "../../../../Components/Home/HomePage/components/Machines/MachineCard";
import { Spin } from "../../../../Components/common/Spin";

export enum BookmarkType {
  Corporation = "Corporation",
  Company = "Company",
  Machine = "Machine",
  Project = "Project",
}

const Bookmarks = ({
  transformer = (data: any) => data,
  title,
  bookmarks = [],
  isLoading,
  gridStyle,
  sizeProps,
}: any) => {
  const transformedData = transformer(bookmarks);

  if (transformedData?.length === 0 && isLoading) {
    return (
      <div style={{ marginBottom: 80 }}>
        <Spin />
      </div>
    );
  }

  if (transformedData?.length === 0) return null;

  return (
    <div
      className="dashboards-container"
      style={{
        marginBottom: 80,
      }}
    >
      <div style={{ fontWeight: 600, fontSize: 16, marginBottom: 16 }}>
        {title || "Bookmarked"}
      </div>
      <ShowMoreGrid
        data={transformedData}
        sizeProps={sizeProps}
        renderComponent={(data) => (
          <div style={gridStyle}>
            {data.map((item: any) => {
              const itemId = uniqueId("_bookmark_");
              return item.isDashboard ? (
                <DashboardCard key={itemId} data={item} />
              ) : (
                <MachineCard key={itemId} data={item} />
              );
            })}
          </div>
        )}
      />
    </div>
  );
};

export default Bookmarks;
