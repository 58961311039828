import type {
  IButtonProps,
  IButtonStyles,
  IComboBoxStyles,
  ICommandBarItemProps,
  IDropdownStyles,
  IIconProps,
} from "@fluentui/react";
import {
  ComboBox,
  CommandBarButton,
  Icon,
  Label,
  mergeStyleSets,
  Stack,
} from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import classNames from "classnames";
import { omit } from "lodash-es";

import Uploader from "../Uploader";

const iconStyles: IIconProps["styles"] = {
  root: {
    margin: "0 4px",
    color: "rgb(44, 82, 159)",
    cursor: "default",
    fontSize: 16,
  },
};

export const overflowButtonStyles: IButtonProps = {
  styles: {
    root: {
      background: "transparent",
    },
  },
  menuProps: {
    items: [],
    styles: {
      list: {
        padding: 8,
      },
      subComponentStyles: {
        callout: {},
        menuItem: {
          item: {
            padding: "4px 0",
          },
        },
      },
    },
  },
};

export const buttonStyles: IButtonStyles = {
  root: {
    background: "transparent",
  },
};

export const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    display: "flex",
    alignItems: "center",
    padding: "2px 8px",
    userSelect: "none",
    selectors: {
      ":hover": {
        backgroundColor: "#F3F2F1",
        color: "rgb(32, 31, 30)",
      },
      ":active": {
        backgroundColor: "#EDEBE9",
        // '& > div:not([title="layout"]) span': {
        //   color: '#EB3F3D !important',
        // },
      },
    },
  },
  dropdown: {
    width: "auto",
  },
  title: {
    lineHeight: 32,
    fontWeight: 600,
  },
};

export const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    border: 0,
    padding: 0,
    margin: 0,
    selectors: {
      "&::after": {
        border: 0,
      },
    },
  },
  container: {
    display: "flex",
    marginRight: 36,
    selectors: {
      ":hover": {
        backgroundColor: "rgb(243, 242, 241)",
        color: "rgb(32, 31, 30)",
      },
    },
  },
  input: { display: "none" },
  optionsContainer: {
    selectors: {
      ".ms-Checkbox-checkbox": {
        height: 15,
        width: 15,
        marginRight: 8,
      },
    },
  },
};

export const onRenderLabel = ({
  label,
  icon: img,
}: {
  label: string;
  icon: string | JSX.Element;
}) => {
  const icon =
    typeof img === "string" ? (
      <Icon iconName={img} title={img} ariaLabel={img} styles={iconStyles} />
    ) : (
      img
    );

  return (
    <Stack horizontal verticalAlign="center">
      {icon}
      <Label styles={{ root: { fontWeight: 400 } }}>{label}</Label>
    </Stack>
  );
};

export enum CommandBarItemType {
  Button = "Button",
  Upload = "Upload",
  Dropdown = "Dropdown",
  ComboBox = "ComboBox",
  Custom = "Custom",
}

type BaseItemProps = {
  key: string;
  type: CommandBarItemType;
};

export type CommandBarItemProps = ICommandBarItemProps & BaseItemProps;

export const computeCommandBarItems = (
  items: CommandBarItemProps[]
): ICommandBarItemProps[] => {
  const computedItems = items.map((item: CommandBarItemProps) => {
    switch (item.type) {
      case CommandBarItemType.Button: {
        return {
          buttonStyles,
          ...omit(item, "type"),
        };
      }
      case CommandBarItemType.Upload: {
        const { text = "Import", onRenderProps } = item;

        return {
          onRender: () => (
            <Uploader {...onRenderProps}>
              <CommandBarButton
                iconProps={{ iconName: "Upload" }}
                styles={buttonStyles}
              >
                {text}
              </CommandBarButton>
            </Uploader>
          ),
          ...omit(item, "type", "onRenderProps"),
        };
      }
      case CommandBarItemType.Dropdown: {
        const { onRenderProps } = item;
        const { onRenderLabelProps = {}, styles, ...rest } = onRenderProps;
        const { label, icon } = onRenderLabelProps;

        return {
          onRender: () => (
            <Dropdown
              className={classNames("layout-dropdown secondary-dropdown", {
                disabled: rest.disabled,
              })}
              styles={mergeStyleSets(dropdownStyles, styles)}
              calloutProps={{
                calloutWidth: undefined,
                calloutMinWidth: 200,
                preventDismissOnEvent: (event) => event.type !== "click",
              }}
              onRenderLabel={() => onRenderLabel({ label, icon })}
              {...rest}
            />
          ),
          ...omit(item, "type", "onRenderProps"),
        };
      }
      case CommandBarItemType.ComboBox: {
        const { onRenderProps } = item;
        const { onRenderLabelProps = {} } = onRenderProps;
        const { label, icon } = onRenderLabelProps;

        return {
          onRender: () => (
            <ComboBox
              className="layout-dropdown"
              styles={comboBoxStyles} // TODO: allow changing styles via prop (dropdown impl)
              calloutProps={{ calloutWidth: undefined, calloutMinWidth: 200 }}
              onRenderLabel={() => onRenderLabel({ label, icon })}
              {...onRenderProps}
            />
          ),
          ...omit(item, "type", "onRenderProps"),
        };
      }
      case CommandBarItemType.Custom: {
        return { ...omit(item, "type") };
      }
    }
  });

  return computedItems as ICommandBarItemProps[];
};
