import { useMutation } from '@tanstack/react-query';

import { getApiClient, validateStatus } from '../../../../modules/core/apiClient/useApiStore';

export type ReportAddProps = {
  fileIds?: string[];
  text: string;
  machineId: string;
  createdBy: string;
  category: string;
  createdAt?: string;
};

export const useReportAdd = () => {
  const { mutateAsync } = useMutation((data: ReportAddProps) =>
    getApiClient().post('meta/machinecv/v1/logEntries', data, { validateStatus }),
  );

  return { addReportAsync: mutateAsync };
};

export const useReportUpdate = (id: string) => {
  const { mutateAsync } = useMutation((data: ReportAddProps) =>
    getApiClient().put(`meta/machinecv/v1/logEntries/${id}`, { ...data, id }, { validateStatus }),
  );

  return { updateReportAsync: mutateAsync };
};
