import { differenceInDays, format } from 'date-fns';
import { useRef } from 'react';


import { binarySearch } from '../../../../../utils';
import {
  checkAvailableCache,
  checkZoomReloadAvailable,
} from '../../../../../modules/analysis-trend-view/components/TrendViewPlot/helper';
import { CHART_NUMBER_OF_POINTS } from '../../../../../modules/analysis-trend-view/constants/controlOptions';
import getPeriodFilters, { FORMAT } from '../../../../../modules/analysis-trend-view/utils/getPeriodFilters';
import { apiService } from '../../../../../modules/common';
import DataTypeEnum from '../../../../../modules/common/constants/DataTypeEnum';
import { queryClient } from '../../../../../modules/core';
import useDriveLoadStore from '../../hooks/useDriveLoadStore';

export const useTorqueChartZoom = () => {
  const stateRef: any = useRef({});

  const { getStoreValue } = useDriveLoadStore(
    (store: any) => ({
      getStoreValue: store.getStoreValue,
    }),
  );

  const handleGroupedZoomChange = ({ currentRange, signals, onStart, onEnd }: any) => {
    const signalsPromises: any = [];
    const startDate = format(new Date(currentRange.startIndex), FORMAT);
    const endDate = format(new Date(currentRange.endIndex), FORMAT);
    const zoomedDaysPeriod = differenceInDays(currentRange.endIndex, currentRange.startIndex);
    const period = getStoreValue('period');

    signals.forEach((signal: any) => {
      const isZoomReloadAvailable = checkZoomReloadAvailable(
        signal,
        currentRange,
        period,
        zoomedDaysPeriod,
      );
      const isCacheDataAvailable = checkAvailableCache(
        signal?.id ? (stateRef.current[signal.id] || {}).cachedPeriod : {},
        currentRange,
      );

      if (isZoomReloadAvailable && !isCacheDataAvailable) {
        const periodFilters = getPeriodFilters(period, signal);
        signalsPromises.push({
          promise: apiService.measuredDataDownsampled[
            signal.dataType === DataTypeEnum.FastTrend
              ? 'getFastTrendMeasuredZipDataInAPeriod'
              : 'getTrendMeasuredZipDataInAPeriod'
          ]({
            startDate,
            endDate,
            signalId: `${signal.id}`,
            numberOfPoints: CHART_NUMBER_OF_POINTS,
          }),
          id: signal.id,
          cacheKey: [
            'trend-measured-data-in-a-period',
            signal.machineId,
            signal.id,
            periodFilters.startDate,
            periodFilters.endDate,
            `${CHART_NUMBER_OF_POINTS}`,
            'off',
          ],
        });
      }
    });

    if (signalsPromises.length > 0) {
      onStart && onStart();
      Promise.all(signalsPromises.map((signalPromise: any) => signalPromise.promise))
        .then((res) => {
          res.forEach((signalRes, idx) => {
            const { cacheKey, id: signalId } = signalsPromises[idx];

            queryClient.setQueryData(cacheKey, (data: any) => {
              stateRef.current[signalId] = stateRef.current[signalId] || {
                data: [],
                cachedPeriod: {},
              };

              if (stateRef.current[signalId].data.length === 0) {
                stateRef.current[signalId].data = [...(<[]>data)];
              }

              stateRef.current[signalId].cachedPeriod = {
                start: startDate,
                end: endDate,
              };

              const cachedData = stateRef.current[signalId].data;

              const startIndex = binarySearch(cachedData, (item) => {
                if (item.timeStamp.includes(startDate)) {
                  return 0;
                }
                return item.timeStamp > startDate ? 1 : -1;
              });
              const lastIndex = binarySearch(cachedData, (item) => {
                if (item.timeStamp.includes(endDate)) {
                  return 0;
                }
                return item.timeStamp > endDate ? 1 : -1;
              });
              const existingDataLeft = [...cachedData].splice(0, startIndex);
              const existingDataRight = [...cachedData].splice(lastIndex, cachedData.length);
              return [...existingDataLeft, ...signalRes, ...existingDataRight];
            });
          });
          onEnd && onEnd();
        })
        .catch(() => {
          onEnd && onEnd();
        });
    }
  };

  const handleZoomChange = ({ currentRange, signals, onStart, onEnd }: any) => {
    handleGroupedZoomChange({ currentRange, signals, onStart, onEnd });
  };

  return {
    handleZoomChange,
  };
};
