import type { CSSProperties } from "react";

import { Map, MapProvider } from "../../../../common/Map";
import { Spin } from "../../../../common/Spin";

import { useDashboardData } from "../../hooks/useDashboardData";
import { Continents } from "./Continents";
import { ModeSelect } from "./ModeSelect";

const dashboardMenuStyles: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
};

export default function HomepageMap() {
  const { points, isLoading, isError } = useDashboardData();

  if (isError) {
    return null;
  }

  if (isLoading) {
    return <Spin style={{ height: "50vh" }} />;
  }

  return (
    <MapProvider>
      <div style={dashboardMenuStyles}>
        <ModeSelect />
        <Continents />
      </div>
      <Map points={points} style={{ marginBottom: 80 }} />
    </MapProvider>
  );
}
