import { format } from './date';

export type FileResponse = {
  id: string;
  name: string;
  added: string;
  type: string;
  sizeInBytes: number;
  description: string;
};

export const mapFileResponseToFileData = (files: FileResponse[]) =>
  files.map((file) => {
    const { id, name, added, type, sizeInBytes, description } = file;
    return {
      id,
      name,
      type,
      size: sizeInBytes,
      description,
      isValid: false,
      dateAdded: format(new Date(added)),
    };
  });

export const formatFileSize = (size: number) => {
  for (const unit of ['B', 'KB', 'MB']) {
    if (size < 1024) return `${size.toFixed(2)} ${unit}`;
    size /= 1024.0;
  }
};
