/* eslint-disable react-hooks/exhaustive-deps */
import { Pivot, PivotItem } from "@fluentui/react";
import { pageStyle } from "../../schema/Constants";
import CfgPinningPivotSection from "./CfgPinning/CfgPinningPivotSection";
import RenderPivotWithIcon from "../Generic/RenderPivotWithIcon";
import ImagesPivotSection from "./Images/ImagesPivotSection";
import ParkedImagesList from "./ParkedImages/ParkedImagesList";
import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../Hooks";
import { Status } from "../../schema/status";
import { selectMachinesStatus, listAsyncMachines } from "../Machines/reducer";
import { selectProjectsStatus, listAsyncProjs } from "../Projects/reducer";

type ImagesSectionProps = {
  hasWritePermissions: boolean;
};

/**
 * Gets the images section component.
 * @param hasWritePermissions Value determining whether the user has permissions to write this data
 * @returns The images section component
 */
const ImagesSection = ({ hasWritePermissions }: ImagesSectionProps) => {
  const projectStatus = useAppSelector(selectProjectsStatus);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const dispatch = useAppDispatch();

  // Gets the projects and machines list.
  useEffect(() => {
    projectStatus === Status.void && dispatch(listAsyncProjs());
    machineStatus === Status.void && dispatch(listAsyncMachines());
  }, []);

  return (
    <section style={pageStyle}>
      <Pivot
        aria-label="Images pivot"
        styles={{
          root: {
            borderBottom: "1px solid rgb(225, 223, 221)",
            margin: "0.25em 0em 0.25em 0em",
            paddingBottom: "0.25em",
          },
        }}
      >
        <PivotItem
          headerText="Images"
          itemIcon="ExploreData"
          onRenderItemLink={RenderPivotWithIcon}
        >
          <ImagesPivotSection hasAddPermissions={hasWritePermissions} />
        </PivotItem>
        <PivotItem
          headerText="CFG Pinning"
          itemIcon="NotePinned"
          onRenderItemLink={RenderPivotWithIcon}
        >
          <CfgPinningPivotSection hasWritePermissions={hasWritePermissions} />
        </PivotItem>
        <PivotItem
          headerText="Parked Images"
          itemIcon="DataConnectionLibrary"
          onRenderItemLink={RenderPivotWithIcon}
        >
          <ParkedImagesList hasSearchPermissions={hasWritePermissions} />
        </PivotItem>
      </Pivot>
    </section>
  );
};

export default ImagesSection;
