import { groupBy } from "lodash-es";
import { useMemo } from "react";

import { useMachineList } from "../../../Hooks";
import type { ResponseSimplifiedSignal } from "../../../types";

import { MAX_GROUP_THRESHOLD } from "../constants/controlOptions";
import useSelectedSignalsStore from "../hooks/useSelectedSignalsStore";

const useGroupingErrorMessages = () => {
  const { data: machineList } = useMachineList({ enabled: false });

  const selectedSignalsStore = useSelectedSignalsStore();

  const signalsGroupedByMachine = useMemo(
    () =>
      groupBy(selectedSignalsStore.selectedSignals, (value) => value.machineId),
    [selectedSignalsStore.selectedSignals]
  );

  const getGroupByMachineErrorMessage = (calculationThreshold = 0) => {
    const machineIds = [] as string[];

    Object.entries(signalsGroupedByMachine).forEach(([machineId, signals]) => {
      if (signals.length > MAX_GROUP_THRESHOLD - calculationThreshold) {
        machineIds.push(machineId);
      }
    });

    const machineNames = machineIds
      .map((machineId) => machineList?.find((item) => item.id === machineId))
      .map((machine) => machine?.name);

    if (machineNames.length > 0) {
      return `You have more than <b>${MAX_GROUP_THRESHOLD} signals selected</b> for machines: <b>${machineNames.join(
        ", "
      )}</b>. Please deselect some signals and then group them again.`;
    }

    return "";
  };

  const getSignalGroupByMachineErrorMessage = (
    signal: ResponseSimplifiedSignal
  ) => {
    const entry = Object.entries(signalsGroupedByMachine).find(
      ([machineId]) => machineId === signal.machineId
    );

    if (entry === undefined) return "";

    const [machineId, signals] = entry;
    const machine = machineList?.find((item) => item.id === machineId);

    if (signals.length === MAX_GROUP_THRESHOLD) {
      return `You have already selected <b>${MAX_GROUP_THRESHOLD} signals</b> for the machine: <b>${machine?.name}</b>. Please deselect some signals and then group them again.`;
    }

    return "";
  };

  const getGroupEverythingErrorMessage = (calculationThreshold = 0) => {
    if (
      selectedSignalsStore.selectedSignals.length >
      MAX_GROUP_THRESHOLD - calculationThreshold
    ) {
      return `You cannot have more than <b>${MAX_GROUP_THRESHOLD} signals selected</b>. Please remove signals until there are <b>maximum ${MAX_GROUP_THRESHOLD}</b>.`;
    }

    return "";
  };

  return {
    getGroupByMachineErrorMessage,
    getSignalGroupByMachineErrorMessage,
    getGroupEverythingErrorMessage,
  };
};

export default useGroupingErrorMessages;
