import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { apiService } from '../../modules/common';
import { queryClient } from '../../modules/core';
import type { MachineCvOverviewList } from '../../types';

const useMachineCvOverviewList = (
  options?:
    | (Omit<
        UseQueryOptions<MachineCvOverviewList, unknown, MachineCvOverviewList, string[]>,
        'initialData' | 'queryFn' | 'queryKey'
      > & { initialData?: (() => undefined) | undefined })
    | undefined,
) => {
  return useQuery(
    ['machine-cv-overview-list'],
    apiService.machineCV.getMachineCvOverviewList,
    options,
  );
};

export const prefetchMachineCvOverviewList = async () => {
  // The results of this query will be cached like a normal query
  await queryClient.prefetchQuery(
    ['machine-cv-overview-list'],
    apiService.machineCV.getMachineCvOverviewList,
  );
};

export default useMachineCvOverviewList;
