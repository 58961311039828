import type { IDialogProps } from "@fluentui/react";
import { DialogFooter, PrimaryButton } from "@fluentui/react";
import type { FC } from "react";
import React from "react";

import BaseDialog from "../../../../common/Dialog/Dialog";
import { File } from "../../../../common/Preview/File";
import type { Column } from "../../../../common/Table";

import DocumentsTable from "../DocumentsTable";

const getColumns = (): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: "Name",
    calculatedWidth: 0,
    minWidth: 250,
    isSortable: true,
    onRender: (item) => {
      return <File file={item} />;
    },
  },
  {
    key: "description",
    fieldName: "description",
    name: "Description",
    minWidth: 100,
    isSortable: true,
    onRender: ({ description }) => {
      return <span style={{ fontSize: 13 }}>{description}</span>;
    },
  },
  {
    key: "dateAdded",
    fieldName: "dateAdded",
    name: "Date Added",
    minWidth: 100,
    isSortable: true,
    onRender: ({ dateAdded }) => {
      return <span style={{ fontSize: 13 }}>{dateAdded}</span>;
    },
  },
];
interface DocumentsViewTableProps {
  files: any[];
  onDismiss: () => void;
}

type DocumentsViewDialogProps = IDialogProps & DocumentsViewTableProps;

const DocumentsViewDialog: FC<DocumentsViewDialogProps> = (props) => {
  const { files, onDismiss } = props;

  return (
    <>
      <BaseDialog {...props}>
        <DocumentsTable items={files} columns={getColumns()} />

        <DialogFooter>
          <PrimaryButton text="Close" disabled={false} onClick={onDismiss} />
        </DialogFooter>
      </BaseDialog>
    </>
  );
};

export default DocumentsViewDialog;
