import { default as Page } from "./HomePage";
import { axiosInstance } from "../..";

import { useAppSelector } from "../../Hooks";
import { selectUserSettings } from "../Settings/selectors";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { useContext } from "react";
import { WelcomeMessage } from "../Generic/WelcomeMessage";
import { IStackStyles, Stack } from "@fluentui/react";

const hasNoPermissions = (auth: any): boolean => {
  const keys = Object.keys(auth) as Array<keyof any>;

  return keys
    .filter(
      (key) => key !== "accessToken" && key !== "loading" && key !== "auth"
    )
    .every((key) => auth[key] === false);
};

export const Home: React.FunctionComponent = () => {
  const settings = useAppSelector(selectUserSettings);
  const auth = useContext(authContext);

  const containerStyles: IStackStyles = {
    root: {
      verticalAlign: "center",
    },
  };

  if (hasNoPermissions(auth)) {
    return (
      <Stack styles={containerStyles}>
        <WelcomeMessage />
      </Stack>
    );
  }

  return (
    <>
      <Page
        axiosInstance={axiosInstance}
        settings={{
          ...settings.dashboardSettings,
          gridItemsCount: settings?.list?.gridItemsCount,
        }}
      />
    </>
  );
};
