import { useRef } from "react";

type InitFn<T> = () => T;

export default function useConstant<T>(init: T | InitFn<T>): T {
  const ref = useRef<T | null>(null);

  if (!ref?.current) {
    ref.current = typeof init === "function" ? (init as InitFn<T>)() : init;
  }

  return ref.current as T;
}
