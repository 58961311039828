import {
  Breadcrumb,
  DefaultButton,
  DirectionalHint,
  IStackTokens,
  IconButton,
  PrimaryButton,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import {
  CompanySubmission,
  CorporationSubmission,
  GatewaySubmission,
  MachineSubmission,
  ProjectSubmission,
  SensorNodesSubmission,
  SubmissionWithSId,
} from "./models";
import { useNavigate, useParams } from "react-router-dom";
import ChevronItemWithChildren from "./ChevronItemWithChildren";
import ChatPanel from "./ChatPanel";
import {
  approveSubmission,
  rejectSubmission,
  sendSubmissionsComment,
  submissionsDetails,
} from "../api";
import DetailsPanel from "./DetailsPanel";
import { RequestApproveSubmissionAdd, SubmissionSupport } from "../models";
import { WirelessGateway } from "../../Gateways/models";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import {
  listAsyncGateways,
  selectWirelessGateways,
  selectWirelessGatewaysStatus,
} from "../../Gateways/reducer";
import {
  listAsyncProjs,
  selectProjects,
  selectProjectsStatus,
} from "../../Projects/reducer";
import { Status } from "../../../schema/status";
import { isValidDalogId } from "../../../schema/Utils";
import { Corporation } from "../../Corporations/models";
import { Company } from "../../../types";
import { Project } from "../../Projects/models";
import { MachineToList } from "../../Machines/models";
import {
  listAsyncMachines,
  selectMachinesStatus,
  selectMachinesToList,
} from "../../Machines/reducer";
import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsStatus,
} from "../../Corporations/reducer";
import {
  listAsyncCompanies,
  selectCompanies,
  selectCompaniesStatus,
} from "../../Companies/reducer";
import { notification } from "../../common/Notification";
import { SupportProvider } from "./SupportContext";

const stackTokens: IStackTokens = { childrenGap: 10 };

export interface SubmissionToUpdateByParts {
  corpo: CorporationSubmission;
  comp?: CompanySubmission;
  proj?: ProjectSubmission;
  mach?: MachineSubmission;
  gat?: GatewaySubmission;
  sen?: SensorNodesSubmission;
}

export interface SecondPanelContext {
  context:
    | "Corporation"
    | "Company"
    | "Project"
    | "Machine"
    | "Sensor Node"
    | "Gateway";
  updateFunction: (
    partToUpdate:
      | CorporationSubmission
      | CompanySubmission
      | ProjectSubmission
      | GatewaySubmission
      | MachineSubmission
      | SensorNodesSubmission,
    uniqueId: string
  ) => void;
  initialValues: SubmissionToUpdateByParts;
  matchUniqueId?: string;
}

const generateUniqueId = (): string => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

const addUniqueIdsToSubmission = (
  corporationData: CorporationSubmission
): CorporationSubmission => {
  const updatedCompanies = corporationData.companies?.map((company) => ({
    ...company,
    uniqueId: generateUniqueId(),
    projects: company.projects?.map((project) => ({
      ...project,
      uniqueId: generateUniqueId(),
      machines: project.machines?.map((machine) => ({
        ...machine,
        uniqueId: generateUniqueId(),
        sensors: machine.sensors?.map((sensor) => ({
          ...sensor,
          uniqueId: generateUniqueId(),
        })),
      })),
      gateways: project.gateways?.map((gateway) => ({
        ...gateway,
        uniqueId: generateUniqueId(),
      })),
    })),
  }));
  return {
    ...corporationData,
    companies: updatedCompanies,
    uniqueId: generateUniqueId(),
  };
};

function updatePartOfSubmission(
  corporationSub: CorporationSubmission,
  uniqueId: string,
  partToUpdate:
    | CorporationSubmission
    | CompanySubmission
    | ProjectSubmission
    | GatewaySubmission
    | MachineSubmission
    | SensorNodesSubmission
): CorporationSubmission {
  if (corporationSub.uniqueId === uniqueId) {
    return { ...corporationSub, ...partToUpdate };
  }

  const updateCompany = (company: CompanySubmission): CompanySubmission => {
    if (company.uniqueId === uniqueId) {
      return { ...company, ...partToUpdate };
    } else {
      const updatedProjects = company.projects?.map(updateProject);
      return { ...company, projects: updatedProjects };
    }
  };

  const updateProject = (project: ProjectSubmission): ProjectSubmission => {
    if (project.uniqueId === uniqueId) {
      return { ...project, ...partToUpdate };
    } else {
      const updatedMachines = project.machines?.map(updateMachine);
      const updatedGateways = project.gateways?.map(updateGateway);
      return {
        ...project,
        machines: updatedMachines,
        gateways: updatedGateways,
      };
    }
  };

  const updateMachine = (machine: MachineSubmission): MachineSubmission => {
    if (machine.uniqueId === uniqueId) {
      return { ...machine, ...partToUpdate };
    } else {
      const updatedSensors = machine.sensors?.map(updateSensor);
      return { ...machine, sensors: updatedSensors };
    }
  };

  const updateSensor = (
    sensor: SensorNodesSubmission
  ): SensorNodesSubmission => {
    if (sensor.uniqueId === uniqueId) {
      return { ...sensor, ...partToUpdate };
    } else {
      return sensor;
    }
  };

  const updateGateway = (gateway: GatewaySubmission): GatewaySubmission => {
    return gateway.uniqueId === uniqueId
      ? { ...gateway, ...partToUpdate }
      : gateway;
  };

  const updatedCompanies = corporationSub.companies?.map(updateCompany);

  return { ...corporationSub, companies: updatedCompanies };
}

function createSubmissionSupport(
  corporationSubmission: CorporationSubmission,
  submissionSupport: SubmissionSupport | null,
  wirelessGateways: WirelessGateway[]
): SubmissionSupport {
  if (!submissionSupport) {
    submissionSupport = {
      gatewaysSupportToAdd: [],
      gatewaysSupportToUpdate: [],
      machinesSupport: [],
    };
  }

  // Step 1: Process MachineSubmissions
  if (corporationSubmission.companies) {
    for (const company of corporationSubmission.companies) {
      if (company.projects) {
        for (const project of company.projects) {
          if (project.machines) {
            for (const machine of project.machines) {
              if (!machine.id) {
                const existingMachineIndex =
                  submissionSupport.machinesSupport.findIndex(
                    (support) => support.matchUniqueId === machine.uniqueId
                  );
                if (existingMachineIndex !== -1) {
                  submissionSupport.machinesSupport[existingMachineIndex] = {
                    dalogId:
                      submissionSupport.machinesSupport[existingMachineIndex]
                        .dalogId,
                    nameMachine: machine.name,
                    matchUniqueId: machine.uniqueId,
                  };
                } else {
                  submissionSupport.machinesSupport.push({
                    dalogId: "",
                    nameMachine: machine.name,
                    matchUniqueId: machine.uniqueId,
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  // Step 2: Process GatewaySubmissions
  if (corporationSubmission.companies) {
    for (const company of corporationSubmission.companies) {
      if (company.projects) {
        for (const project of company.projects) {
          if (project.gateways) {
            for (const gateway of project.gateways) {
              const gatewayExists = wirelessGateways.some(
                (wirelessGateway) =>
                  wirelessGateway.serialNumber === gateway.serialNumber
              );

              if (gatewayExists) {
                const existingUpdateIndex =
                  submissionSupport.gatewaysSupportToUpdate.findIndex(
                    (update) => update.matchUniqueId === gateway.uniqueId
                  );

                const existingAddIndex =
                  submissionSupport.gatewaysSupportToAdd.findIndex(
                    (add) => add.matchUniqueId === gateway.uniqueId
                  );
                if (existingAddIndex !== -1) {
                  submissionSupport.gatewaysSupportToAdd.splice(
                    existingAddIndex,
                    1
                  );
                }

                const myGat = wirelessGateways.find(
                  (wirelessGateway) =>
                    wirelessGateway.serialNumber === gateway.serialNumber
                );
                if (existingUpdateIndex !== -1) {
                  submissionSupport.gatewaysSupportToUpdate[
                    existingUpdateIndex
                  ] = {
                    ...myGat,
                    ...submissionSupport.gatewaysSupportToUpdate[
                      existingUpdateIndex
                    ],
                    serialNumber: gateway.serialNumber,
                    matchUniqueId: gateway.uniqueId,
                  };
                } else {
                  submissionSupport.gatewaysSupportToUpdate.push({
                    ...myGat,
                    serialNumber: gateway.serialNumber,
                    matchUniqueId: gateway.uniqueId,
                    isValid: false,
                  });
                }
              } else {
                const existingAddIndex =
                  submissionSupport.gatewaysSupportToAdd.findIndex(
                    (add) => add.matchUniqueId === gateway.uniqueId
                  );

                const existingUpdateIndex =
                  submissionSupport.gatewaysSupportToUpdate.findIndex(
                    (update) => update.matchUniqueId === gateway.uniqueId
                  );

                if (existingUpdateIndex !== -1) {
                  submissionSupport.gatewaysSupportToUpdate.splice(
                    existingUpdateIndex,
                    1
                  );
                }

                if (existingAddIndex !== -1) {
                  submissionSupport.gatewaysSupportToAdd[existingAddIndex] = {
                    ...submissionSupport.gatewaysSupportToAdd[existingAddIndex],
                    serialNumber: gateway.serialNumber,
                    projectId: "",
                    matchUniqueId: gateway.uniqueId,
                  };
                } else {
                  submissionSupport.gatewaysSupportToAdd.push({
                    serialNumber: gateway.serialNumber,
                    projectId: "123e4567-e89b-12d3-a456-426614174000",
                    ltePlanSize: 0,
                    adminPassword: "",
                    userPassword: "",
                    matchUniqueId: gateway.uniqueId,
                    isValid: false,
                  });
                }
              }
            }
          }
        }
      }
    }
  }
  return submissionSupport;
}

interface SubmissionsDetailsProps {
  isNotLog?: boolean;
}

export const SubmissionsDetails: React.FC<SubmissionsDetailsProps> = ({
  isNotLog,
}) => {
  const [item, setItem] = useState<SubmissionWithSId>();
  const [support, setSupport] = useState<SubmissionSupport>();
  const navigate = useNavigate();
  const { id } = useParams();
  const [secondPanelContext, setSecondPanelContext] =
    useState<SecondPanelContext>();
  const gatsList = useAppSelector(selectWirelessGateways);
  const status = useAppSelector(selectWirelessGatewaysStatus);
  const dispatch = useAppDispatch();
  const projs = useAppSelector(selectProjects);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const machines = useAppSelector(selectMachinesToList);
  const corpos = useAppSelector(selectCorporations);
  const corporationStatus = useAppSelector(selectCorporationsStatus);
  const companies = useAppSelector(selectCompanies);
  const statusCompanies = useAppSelector(selectCompaniesStatus);
  const [feedback, setFeedback] = useState<string>();
  // const { bigScreen } = useViewport();

  // Gets the machines and projects list.
  useEffect(() => {
    if (
      machineStatus !== Status.void &&
      projectStatus !== Status.void &&
      corporationStatus !== Status.void &&
      statusCompanies !== Status.void
    ) {
      return;
    }

    machineStatus === Status.void && dispatch(listAsyncMachines());
    projectStatus === Status.void && dispatch(listAsyncProjs());
    corporationStatus === Status.void && dispatch(listAsyncCorpo());
    statusCompanies === Status.void && dispatch(listAsyncCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineStatus, projectStatus, corporationStatus, statusCompanies]);

  useEffect(() => {
    submissionsDetails(id).then((list) => {
      const corpoSubmission = addUniqueIdsToSubmission(list.submission);
      setItem({
        ...list,
        submission: corpoSubmission,
        messages: list.messages,
      });
    });
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (status === Status.void && projectStatus === Status.idle)
      dispatch(listAsyncGateways(projs));
  }, [dispatch, projectStatus, status, projs]);

  useEffect(() => {
    if (status === Status.idle && item && gatsList.length > 0) {
      setSupport(createSubmissionSupport(item.submission, support, gatsList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, item, gatsList]);

  useEffect(() => {
    support &&
      setFeedback(
        ValidateInput(
          item.submission,
          corpos,
          companies,
          projs,
          machines,
          support
        )
      );

    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [support, item?.submission]);

  const goBack = () => navigate(-1);

  const updateRecall = (
    partToUpdate:
      | CorporationSubmission
      | CompanySubmission
      | ProjectSubmission
      | GatewaySubmission
      | MachineSubmission
      | SensorNodesSubmission,
    uniqueId: string
  ): void =>
    setItem((ele) => {
      return {
        ...ele,
        submission: updatePartOfSubmission(
          ele.submission,
          uniqueId,
          partToUpdate
        ),
      };
    });

  const SubmitApproval = () => {
    const toApprove: RequestApproveSubmissionAdd = {
      submissionToUpdate: item.submission,
      supportForSubmission: support,
    };
    approveSubmission(id, toApprove).then((resp) => {
      if ("data" in resp) {
        notification.success(
          "Submission" + item.sID + " successfully performed"
        );
        navigate("/submissions");
      } else notification.error("Error: " + resp.Error);
    });
    dispatch(listAsyncProjs());
  };

  function isValidMachineSupport(mach: any, support: any): boolean {
    const machineSupport = support?.machinesSupport?.find(
      (ele) => ele.matchUniqueId === mach.uniqueId
    );

    return (
      !isValidDalogId(machineSupport?.dalogId) ||
      machines.some((ele) => ele.dalogId === machineSupport.dalogId)
    );
  }

  return (
    <>
      {item && (
        <>
          <Stack
            horizontal
            className="breadcrumb-container"
            horizontalAlign="space-between"
            verticalAlign="center"
            style={{ padding: "8px 16px", borderBottom: "1px solid #EDEBE9" }}
          >
            <Stack
              horizontal
              verticalAlign="center"
              style={{ width: `calc(100% - ${50}px - 32px)` }}
            >
              <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
              <Breadcrumb
                items={[
                  {
                    key: `submissions-key`,
                    text: `Submissions`,
                  },
                  { key: `submission-${item.id}`, text: item.sID },
                ]}
                styles={{
                  root: { margin: 0 },
                  item: { fontSize: "14px" },
                  chevron: { fontSize: "10px" },
                }}
              />
            </Stack>
            {isNotLog && (
              <Stack horizontal>
                <DefaultButton
                  text="Reject"
                  style={{ width: 100, marginRight: 10 }}
                  onClick={() =>
                    rejectSubmission(id).then((resp) => {
                      if ("data" in resp) {
                        notification.success(
                          "Submission " + item.sID + " successfully rejected."
                        );
                        navigate("/submissions");
                      } else notification.error("Error" + resp.Error);
                    })
                  }
                />
                <TooltipHost
                  content={feedback}
                  directionalHint={DirectionalHint.topCenter}
                >
                  <PrimaryButton
                    text="Approve"
                    style={{ width: 100 }}
                    onClick={SubmitApproval}
                    disabled={feedback ? true : false}
                  />
                </TooltipHost>
              </Stack>
            )}
          </Stack>
          <Stack horizontal tokens={{ padding: 20, childrenGap: 20 }}>
            <Stack.Item styles={{ root: { width: "30%" } }}>
              <ChevronItemWithChildren
                key={item?.submission?.name + "chevron"}
                name={item?.submission?.name}
                parentIconName="Corporations"
                chevron={
                  item?.submission?.companies &&
                  item?.submission?.companies?.length > 0
                    ? true
                    : false
                }
                onClick={() =>
                  setSecondPanelContext({
                    context: "Corporation",
                    updateFunction: updateRecall,
                    initialValues: { corpo: item.submission },
                  })
                }
              >
                {item.submission.companies &&
                  item.submission.companies.length > 0 && (
                    <>
                      {item.submission.companies.map((ele) => (
                        <ChevronItemWithChildren
                          key={ele.name + "chevron"}
                          name={ele.name}
                          parentIconName="Companies"
                          chevron={ele.projects && ele.projects.length > 0}
                          onClick={() =>
                            setSecondPanelContext({
                              context: "Company",
                              updateFunction: updateRecall,
                              initialValues: {
                                corpo: item.submission,
                                comp: ele,
                              },
                            })
                          }
                        >
                          {ele.projects && ele.projects.length > 0 && (
                            <>
                              {ele.projects.map((pro) => (
                                <ChevronItemWithChildren
                                  key={pro.name + "chevron"}
                                  name={pro.name}
                                  parentIconName="Projects"
                                  chevron={
                                    (pro.gateways && pro.gateways.length > 0) ||
                                    (pro.machines && pro.machines.length > 0)
                                  }
                                  onClick={() =>
                                    setSecondPanelContext({
                                      context: "Project",
                                      updateFunction: updateRecall,
                                      initialValues: {
                                        corpo: item.submission,
                                        comp: ele,
                                        proj: pro,
                                      },
                                    })
                                  }
                                >
                                  {
                                    <>
                                      {pro.machines &&
                                        pro.machines.length > 0 && (
                                          <>
                                            {pro.machines.map((mach) => (
                                              <ChevronItemWithChildren
                                                key={mach.name + "chevron"}
                                                name={mach.name}
                                                parentIconName="Machines"
                                                chevron={
                                                  mach.sensors &&
                                                  mach.sensors.length > 0
                                                }
                                                onClick={() => {
                                                  setSupport((prevSupport) => ({
                                                    ...prevSupport,
                                                    matchUniqueId:
                                                      mach.uniqueId,
                                                  }));
                                                  setSecondPanelContext({
                                                    context: "Machine",
                                                    updateFunction:
                                                      updateRecall,
                                                    initialValues: {
                                                      corpo: item.submission,
                                                      comp: ele,
                                                      proj: pro,
                                                      mach: mach,
                                                    },
                                                  });
                                                }}
                                                pending={isValidMachineSupport(
                                                  mach,
                                                  support
                                                )}
                                              >
                                                {mach.sensors &&
                                                  mach.sensors.length > 0 && (
                                                    <>
                                                      {mach.sensors.map(
                                                        (sen) => (
                                                          <ChevronItemWithChildren
                                                            key={
                                                              sen.sensorSerialNumber +
                                                              "chevron"
                                                            }
                                                            name={
                                                              sen.sensorSerialNumber
                                                            }
                                                            parentIconName="SensorsNodes"
                                                            chevron={false}
                                                            onClick={() => {
                                                              setSecondPanelContext(
                                                                {
                                                                  context:
                                                                    "Sensor Node",
                                                                  updateFunction:
                                                                    updateRecall,
                                                                  initialValues:
                                                                    {
                                                                      corpo:
                                                                        item.submission,
                                                                      comp: ele,
                                                                      proj: pro,
                                                                      mach: mach,
                                                                      sen: sen,
                                                                    },
                                                                }
                                                              );
                                                            }}
                                                          />
                                                        )
                                                      )}
                                                    </>
                                                  )}
                                              </ChevronItemWithChildren>
                                            ))}
                                          </>
                                        )}

                                      {pro.gateways &&
                                        pro.gateways.length > 0 && (
                                          <>
                                            {pro.gateways.map((gat) => (
                                              <ChevronItemWithChildren
                                                key={
                                                  gat.serialNumber + "chevron"
                                                }
                                                name={gat.serialNumber}
                                                parentIconName="Gateways"
                                                chevron={false}
                                                pending={
                                                  gatsList.some(
                                                    (g) =>
                                                      g.serialNumber ===
                                                      gat.serialNumber
                                                  )
                                                    ? !support?.gatewaysSupportToUpdate?.find(
                                                        (ele) =>
                                                          ele.matchUniqueId ===
                                                            gat.uniqueId &&
                                                          ele.serialNumber ===
                                                            gat.serialNumber
                                                      )?.isValid
                                                    : !support?.gatewaysSupportToAdd?.find(
                                                        (ele) =>
                                                          ele.matchUniqueId ===
                                                            gat.uniqueId &&
                                                          ele.serialNumber ===
                                                            gat.serialNumber
                                                      )?.isValid
                                                }
                                                onClick={() => {
                                                  setSupport((prevSupport) => ({
                                                    ...prevSupport,
                                                    matchUniqueId: gat.uniqueId,
                                                  }));
                                                  setSecondPanelContext({
                                                    context: "Gateway",
                                                    updateFunction:
                                                      updateRecall,
                                                    initialValues: {
                                                      corpo: item.submission,
                                                      comp: ele,
                                                      proj: pro,
                                                      gat: gat,
                                                    },
                                                    matchUniqueId: gat.uniqueId,
                                                  });
                                                }}
                                              />
                                            ))}
                                          </>
                                        )}
                                    </>
                                  }
                                </ChevronItemWithChildren>
                              ))}
                            </>
                          )}
                        </ChevronItemWithChildren>
                      ))}
                    </>
                  )}
              </ChevronItemWithChildren>
            </Stack.Item>
            <Stack.Item
              styles={{
                root: {
                  width: "35%",
                  paddingLeft: "40px",
                },
              }}
            >
              <Text variant={"xLarge"} nowrap>
                {secondPanelContext?.context
                  ? secondPanelContext.context
                  : "Select an item"}
              </Text>

              <Stack
                styles={{
                  root: {
                    display: "flex",
                    alignItems: "flex-start",
                  },
                }}
              >
                {secondPanelContext && (
                  <SupportProvider support={support} setSupport={setSupport}>
                    <DetailsPanel context={secondPanelContext} />
                  </SupportProvider>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item styles={{ root: { width: "35%" } }}>
              <Stack tokens={stackTokens}>
                <ChatPanel
                  messages={item.messages}
                  me={item.createdBy}
                  onSent={(mess) =>
                    sendSubmissionsComment(id, mess).then((resp) =>
                      !("data" in resp)
                        ? notification.error(
                            "Some issue sending the submission comment"
                          )
                        : submissionsDetails(id).then((list) => {
                            const corpoSubmission = addUniqueIdsToSubmission(
                              list.submission
                            );
                            setItem({
                              ...list,
                              submission: corpoSubmission,
                              messages: list.messages,
                            });
                          })
                    )
                  }
                />
              </Stack>
            </Stack.Item>
          </Stack>
        </>
      )}
    </>
  );
};

function ValidateInput(
  submission: CorporationSubmission,
  corpos: Corporation[],
  companies: Company[],
  projs: Project[],
  machs: MachineToList[],
  supportRaw: SubmissionSupport
): string | undefined {
  if (submission.id) {
    const existing = corpos.some((ele) => ele.id === submission.id);
    if (!existing)
      return (
        "Selected corporation does not exist id: " +
        submission.id +
        " : It may mean that the corporation was deleted."
      );
    if (!submission?.companies?.length)
      return "Since you are providing a Corporation Id: The 'companies' list is either null or empty. Please provide valid companies information.";
  } else {
    const existing = corpos.some((ele) => ele.name === submission.name);
    if (existing)
      return (
        "Name of corporation " +
        submission.name +
        ", you want to add, already exist"
      );
  }

  for (const company of submission.companies) {
    if (company.id) {
      const existing = companies.some((ele) => ele.id === company.id);
      if (!existing)
        return (
          "Selected company does not exist id: " +
          company.id +
          " : It may mean that the company was deleted."
        );
      if (!company?.projects?.length)
        return "Since you are providing a Company Id: The 'projects' list is either null or empty. Please provide valid project information.";
    }

    for (const project of company.projects) {
      if (project.id) {
        const existing = projs.some((ele) => ele.id === project.id);
        if (!existing)
          return (
            "Selected project does not exist id: " +
            project.id +
            " : It may mean that the project was deleted."
          );

        if (!project?.machines?.length && !project?.gateways?.length)
          return "Since you are providing a Project Id: The 'machines' || 'gateways' list is either null or empty. Please provide valid 'machines' || 'gateways' information.";
      }

      for (const machine of project.machines) {
        if (machine.id) {
          const existing = machs.some((ele) => ele.id === machine.id);

          if (!existing)
            return (
              "Selected machine does not exist id: " +
              machine.id +
              " : It may mean that the machine was deleted."
            );

          if (machine.sensors === null || machine.sensors.length === 0)
            return "Since you are providing a Machine Id: The 'sensors' list is either null or empty. Please provide valid 'sensors' information.";
        }
        if (!machine.name || machine.name.trim() === "") {
          return `machines: Name  cannot be undefined or empty.`;
        }
      }
    }
  }

  const supportFilter = filterGatewaySupport(supportRaw, submission);

  // Validate gatewaysSupportToAdd
  for (const gateway of supportFilter.gatewaysSupportToAdd) {
    if (!gateway.serialNumber || gateway.serialNumber.trim() === "") {
      return `gateways: serialNumber cannot be undefined or empty`;
    }
    if (!gateway.ltePlanSize) {
      return `gateways: ltePlanSize cannot be undefined or empty for serialNumber: ${gateway.serialNumber}`;
    }
    if (gateway.isValid === false) {
      return `gateways: isValid is false for serialNumber: ${gateway.serialNumber}`;
    }
  }

  // Validate gatewaysSupportToUpdate
  for (const gateway of supportFilter.gatewaysSupportToUpdate) {
    if (!gateway.serialNumber || gateway.serialNumber.trim() === "") {
      return `gateways: serialNumber cannot be undefined or empty`;
    }
    if (!gateway.ltePlanSize) {
      return `gateways: ltePlanSize cannot be undefined or empty for serialNumber: ${gateway.serialNumber}`;
    }
    if (!gateway.id || gateway.id.trim() === "") {
      return `gateways: id cannot be undefined or empty for serialNumber: ${gateway.serialNumber}`;
    }
    if (gateway.isValid === false) {
      return `gateways: isValid is false for serialNumber: ${gateway.serialNumber}`;
    }
  }

  // Validate machinesSupport
  for (const machine of supportFilter.machinesSupport) {
    if (!machine.dalogId || machine.dalogId.trim() === "") {
      return `machines: dalogId cannot be undefined or empty. name: ${machine.nameMachine}`;
    }
    if (!isValidDalogId(machine.dalogId))
      return `machines: dalogId ${machine.nameMachine} is not valid. Format (xx-xxx-xx)`;
    if (machs.some((ele) => ele.dalogId === machine.dalogId))
      return `machines: dalogId ${machine.nameMachine} already exist.`;
  }

  return undefined;
}

function filterGatewaySupport(
  submissionSupport: SubmissionSupport,
  corporationSubmission: CorporationSubmission
): SubmissionSupport {
  const validGatewayIds = new Set<string>();

  // Collect all valid gateway uniqueIds
  corporationSubmission.companies?.forEach((company) => {
    company.projects?.forEach((project) => {
      project.gateways?.forEach((gateway) => {
        validGatewayIds.add(gateway.uniqueId);
      });
    });
  });

  // Filter gatewaysSupportToAdd
  const filteredGatewaysSupportToAdd =
    submissionSupport.gatewaysSupportToAdd.filter((gateway) =>
      validGatewayIds.has(gateway.matchUniqueId)
    );

  // Filter gatewaysSupportToUpdate
  const filteredGatewaysSupportToUpdate =
    submissionSupport.gatewaysSupportToUpdate.filter((gateway) =>
      validGatewayIds.has(gateway.matchUniqueId)
    );

  return {
    ...submissionSupport,
    gatewaysSupportToAdd: filteredGatewaysSupportToAdd,
    gatewaysSupportToUpdate: filteredGatewaysSupportToUpdate,
  };
}
