import type { SVGProps } from 'react';
import React from 'react';

const FirstPageIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='9'
      height='11'
      viewBox='0 0 9 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1 1.19231e-06C0.882975 -3.78216e-05 0.769643 0.0409728 0.679742 0.115891C0.58984 0.190809 0.529065 0.294887 0.508001 0.410001L0.500001 0.500001V9.5C0.499771 9.62495 0.54633 9.74545 0.630509 9.83778C0.714689 9.93012 0.830387 9.98759 0.954822 9.99888C1.07926 10.0102 1.20341 9.97446 1.30283 9.89878C1.40225 9.8231 1.46974 9.71295 1.492 9.59L1.5 9.5V0.500001C1.5 0.367393 1.44732 0.240216 1.35355 0.146448C1.25979 0.0526797 1.13261 1.19231e-06 1 1.19231e-06ZM8.854 0.146001C8.77115 0.0633259 8.66191 0.0123391 8.54533 0.00192332C8.42874 -0.00849246 8.3122 0.0223233 8.216 0.0890014L8.146 0.146001L3.646 4.646C3.56333 4.72886 3.51234 4.83809 3.50192 4.95468C3.49151 5.07126 3.52232 5.1878 3.589 5.284L3.646 5.354L8.146 9.854C8.23419 9.94258 8.35236 9.99492 8.47722 10.0007C8.60209 10.0065 8.72459 9.96528 8.82058 9.88523C8.91658 9.80517 8.97911 9.69206 8.99584 9.56818C9.01258 9.44431 8.98231 9.31866 8.911 9.216L8.854 9.146L4.707 5L8.854 0.854001C8.90056 0.807556 8.93751 0.75238 8.96271 0.691635C8.98792 0.63089 9.00089 0.565768 9.00089 0.500001C9.00089 0.434234 8.98792 0.369113 8.96271 0.308367C8.93751 0.247622 8.90056 0.192447 8.854 0.146001Z'
        fill='#201F1E'
      />
    </svg>
  );
};

export default FirstPageIcon;
