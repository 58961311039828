/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import JSZip from "jszip";
import { useEffect, useState } from "react";

import { linspace } from "../../../../Hooks";
import type { GroupedSignalsPeriod } from "../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { format as signalFormatter } from "../../../../modules/common/utils/signalFormatter";
import { getApiClient } from "../../../../modules/core/apiClient/useApiStore";
import { notification } from "../../../common/Notification";
import type {
  DataBlockPoint,
  ResponseSimplifiedSignal,
} from "../../../../types";

import type { MachineEvent } from "../components/Events/EventsTable/types";
import type { MopSignalData } from "../config";

type SignalDataBlocks = {
  signalId: string;
  data: DataBlockPoint[];
};

type MachineEventsResponse = {
  events: MachineEvent[];
};

type MachineEvents = {
  events: MachineEvent[];
  refDate?: Date;
};

type DataBlockResponse = {
  dataPoints: number[];
  id: string;
  sampleRateInHz: number;
  signalId: string;
  timeStamp: string;
  value: number;
};

type DataBlockMetadata = {
  id: string;
  timeStamp: string;
};

type UseSignalDataBlocksProps = {
  signals: ResponseSimplifiedSignal[];
  eventId?: string;
  eventDate?: string;
  period?: GroupedSignalsPeriod;
};

const dataBlockTimeLapse = 780 * 1000; // milliseconds

const getMetadata = async (
  signal: ResponseSimplifiedSignal
): Promise<MopSignalData<DataBlockMetadata> | undefined> => {
  if (!signal?.id) {
    return undefined;
  }

  return getApiClient()
    .get(`/data/read/v1/shorttrends/${signal.id}`)
    .then((response) => {
      const metadata = response.data.dataPoints as DataBlockMetadata[];
      const result: MopSignalData<DataBlockMetadata> = {
        id: signal.id!,
        unit: `(${signal.unit || "-"})`,
        name: signalFormatter(signal),
        data: [],
      };

      if (!metadata || metadata.length === 0) {
        return result;
      }

      return { ...result, data: metadata };
    });
};

const getEvents = async (
  eventId: string,
  year: number,
  month: number
): Promise<MachineEventsResponse | undefined> => {
  if (!eventId || !year || !month) {
    return undefined;
  }

  return getApiClient()
    .get<MachineEventsResponse>(
      `/data/read/v1/events/${eventId}/${year}/${month}`
    )
    .then((response) => {
      if (response.status !== 200) {
        notification.error("Failure fetching events list.");
        return;
      }

      return response.data;
    });
};

const getDataBlocksData = async (
  signalId: string,
  dataBlockId: string
): Promise<SignalDataBlocks> => {
  return getApiClient()
    .get(`/data/read/v1/shorttrends/${signalId}/${dataBlockId}/raw/zip`, {
      responseType: "arraybuffer",
    })
    .then(async (response) => {
      if (!response.data.byteLength) {
        return {
          signalId,
          data: [],
        };
      }

      const zip = await JSZip.loadAsync(response.data);
      const fileName = `${dataBlockId}.json`;
      const jsonString = await zip.file(fileName)?.async("string");
      const jsonData = JSON.parse(jsonString || "[]") as DataBlockResponse;
      const floatValues = linspace(
        0,
        (jsonData?.dataPoints?.length * 1) / jsonData?.sampleRateInHz,
        jsonData?.dataPoints?.length
      );

      const formattedData: DataBlockPoint[] =
        jsonData?.dataPoints?.map(
          (datapoint: number, index: string | number) => ({
            date: floatValues[Number(index)],
            value: datapoint,
          })
        ) || [];

      return {
        signalId,
        data: formattedData,
      };
    });
};

const getDataBlockFromDate = (
  dataBlocks: DataBlockMetadata[],
  date?: Date
): DataBlockMetadata | undefined => {
  if (!date) return undefined;

  const index = dataBlocks.findIndex((dataBlock) => {
    const dateStart = new Date(dataBlock.timeStamp);
    const dateEnd = new Date(dateStart.getTime() + dataBlockTimeLapse);
    return date >= dateStart && date <= dateEnd;
  });

  return index >= 0 ? dataBlocks[index] : undefined;
};

const getDataBlockFromLatestEvent = (
  dataBlocks: DataBlockMetadata[],
  events: MachineEvent[]
): DataBlockMetadata | undefined => {
  // Sorts the events (latest first)
  const machineEvents = [...events].sort((a, b) => {
    if (a.timeStamp > b.timeStamp) return -1;
    else if (a.timeStamp < b.timeStamp) return 1;
    return 0;
  });

  let result: DataBlockMetadata | undefined = undefined;

  // Loops through all events to get the latest one present in the data blocks data.
  for (let i = 0; i < machineEvents?.length; i++) {
    if (!machineEvents[i]?.timeStamp) {
      continue;
    }

    const eventDate = new Date(machineEvents[i].timeStamp);
    const dataBlock = getDataBlockFromDate(dataBlocks, eventDate);
    if (!dataBlock) {
      continue;
    }

    result = dataBlock;
    break;
  }

  return result;
};

export const useSignalsDataBlocks = ({
  signals,
  period,
  eventDate,
  eventId,
}: UseSignalDataBlocksProps) => {
  const [isLoadingMetadata, setIsLoadingMetadata] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [machineEvents, setMachineEvents] = useState<MachineEvents>({
    events: [],
  });
  const [metadata, setMetadataData] = useState<
    Map<string, MopSignalData<DataBlockMetadata>>
  >(new Map<string, MopSignalData<DataBlockMetadata>>([]));
  const [currentEventDate, setCurrentEventDate] = useState<string | undefined>(
    eventDate
  );
  const [selectedMetadata, setSelectedMetadata] = useState<
    Map<string, DataBlockMetadata>
  >(new Map<string, DataBlockMetadata>([]));
  const [data, setData] = useState<Map<string, MopSignalData<DataBlockPoint>>>(
    new Map<string, MopSignalData<DataBlockPoint>>([])
  );

  // Updates the data blocks metadata.
  useEffect(() => {
    if (!signals || signals?.length === 0) {
      setMetadataData(new Map<string, MopSignalData<DataBlockMetadata>>([]));
      return;
    }

    const result: Map<string, MopSignalData<DataBlockMetadata>> = new Map<
      string,
      MopSignalData<DataBlockMetadata>
    >([]);

    // Adds the already existing signals data blocks.
    if (metadata.size > 0) {
      for (const signal of signals) {
        if (!signal.id) {
          continue;
        }

        const item = metadata.get(signal.id);
        item && result.set(signal.id, item);
      }
    }

    // Gets the new selected signals datablocks metadata
    const newSignals = [
      ...signals.filter(
        (signal) => signal.id !== undefined && !metadata.has(signal.id)
      ),
    ];
    if (newSignals.length === 0) {
      setMetadataData(result);
      return;
    }

    setIsLoadingMetadata(true);
    let mount = true;
    Promise.all(newSignals.map((signal) => getMetadata(signal!)))
      .then((response) => {
        if (!mount) return;
        response.forEach((signalData) => {
          signalData?.data &&
            signalData.data.length > 0 &&
            result.set(signalData.id, signalData);
        });

        setMetadataData(result);
      })
      .finally(() => {
        setIsLoadingMetadata(false);
      });

    // Clears all resources
    return () => {
      mount = false;
    };
  }, [signals?.length]);

  // Updates the selected data blocks metadata from time stamp
  useEffect(() => {
    if (!eventDate) {
      return;
    }

    if (!metadata || metadata.size === 0) {
      setSelectedMetadata(new Map<string, DataBlockMetadata>([]));
      return;
    }

    // Gets the data blocks (if exist)
    const selectedDataBlocks: Map<string, DataBlockMetadata> = new Map<
      string,
      DataBlockMetadata
    >([]);
    Array.from(metadata.keys()).forEach((signalId) => {
      const dataBlocks = metadata.get(signalId)?.data;
      if (!dataBlocks || dataBlocks.length === 0) {
        return;
      }

      // Gets the selected data block from a determined custom period or time stamp
      const result: DataBlockMetadata | undefined = getDataBlockFromDate(
        dataBlocks,
        new Date(eventDate)
      );

      result && selectedDataBlocks.set(signalId, result);
    });

    setMachineEvents({ refDate: new Date(eventDate), events: [] });
    setSelectedMetadata(selectedDataBlocks);
  }, [metadata, eventDate]);

  // Updates the selected data block from period
  useEffect(() => {
    if (!eventId || eventDate) {
      return;
    }

    // Evaluates special cases when there is no metadata, or no period.
    if (!metadata || metadata.size === 0) {
      setSelectedMetadata(new Map<string, DataBlockMetadata>([]));
      return;
    } else if (!period?.startDate || !period?.endDate) {
      const selectedDataBlocks: Map<string, DataBlockMetadata> = new Map<
        string,
        DataBlockMetadata
      >([]);
      Array.from(metadata.keys()).forEach((signalId) => {
        const dataBlocksList = metadata.get(signalId)?.data;
        if (!dataBlocksList || dataBlocksList.length === 0) {
          return;
        }

        selectedDataBlocks.set(
          signalId,
          dataBlocksList[dataBlocksList.length - 1]
        );
      });

      setSelectedMetadata(selectedDataBlocks);
      return;
    }

    const endDate = new Date(period.endDate);

    // Validates whether the reference event time stamp has changed.
    if (machineEvents.refDate) {
      const sameYear =
        machineEvents.refDate.getFullYear() === endDate.getFullYear();
      const sameMonth = machineEvents.refDate.getMonth() === endDate.getMonth();

      // Searches for selected data blocks within the saved events, if the end date is among the current events list time lapse.
      if (sameYear && sameMonth && machineEvents.events.length > 0) {
        const events = machineEvents.events.filter(
          (e) => new Date(e.timeStamp) <= endDate
        );
        const selectedDataBlocks: Map<string, DataBlockMetadata> = new Map<
          string,
          DataBlockMetadata
        >([]);
        Array.from(metadata.keys()).forEach((signalId) => {
          const dataBlocksList = metadata.get(signalId)?.data;
          if (!dataBlocksList || dataBlocksList.length === 0) {
            return;
          }

          const result: DataBlockMetadata | undefined =
            getDataBlockFromLatestEvent(dataBlocksList, events);

          result && selectedDataBlocks.set(signalId, result);
        });

        setMachineEvents({ refDate: endDate, events });
        setSelectedMetadata(selectedDataBlocks);
        return;
      }
    }

    // Gets the events list for the selected year and month.
    let mount = true;
    const startDate = new Date(period.startDate);
    setIsLoadingData(true);
    getEvents(eventId, endDate.getFullYear(), endDate.getMonth() + 1)
      .then((response) => {
        if (!mount || !response) {
          return;
        }

        // Orders the sorted events within range (latest first).
        const events = response.events
          .filter(
            (ev) =>
              new Date(ev.timeStamp) >= startDate &&
              new Date(ev.timeStamp) <= endDate
          )
          .sort((a, b) => {
            if (a.timeStamp > b.timeStamp) return -1;
            else if (a.timeStamp < b.timeStamp) return 1;
            return 0;
          });

        if (!events || events.length === 0) {
          setMachineEvents({ events: [] });
          return;
        }

        // Searches for the latest available event data.
        const selectedDataBlocks: Map<string, DataBlockMetadata> = new Map<
          string,
          DataBlockMetadata
        >([]);
        Array.from(metadata.keys()).forEach((signalId) => {
          const dataBlocksList = metadata.get(signalId)?.data;
          if (!dataBlocksList || dataBlocksList.length === 0) {
            return;
          }

          const result: DataBlockMetadata | undefined =
            getDataBlockFromLatestEvent(dataBlocksList, events);

          result && selectedDataBlocks.set(signalId, result);
        });

        setMachineEvents({ refDate: endDate, events });
        setSelectedMetadata(selectedDataBlocks);
      })
      .finally(() => setIsLoadingData(false));

    // Clears all resources
    return () => {
      mount = false;
    };
  }, [eventId, period?.startDate, period?.endDate, eventDate, metadata]);

  // Updates the data blocks data.
  useEffect(() => {
    if (selectedMetadata.size === 0) {
      setData(new Map<string, MopSignalData<DataBlockPoint>>([]));
      return;
    }

    const result: Map<string, MopSignalData<DataBlockPoint>> = new Map<
      string,
      MopSignalData<DataBlockPoint>
    >([]);

    // Adds the already existing signals data blocks, if the time stamp was not changed.
    if (eventDate === currentEventDate) {
      Array.from(selectedMetadata.keys())
        .filter((signalId) => data.has(signalId))
        .forEach((signalId) => {
          const item = data.get(signalId);
          item && result.set(signalId, item);
        });
    } else {
      setCurrentEventDate(eventDate);
    }

    // Searches for new signal Ids for data fetching.
    const newIds = Array.from(selectedMetadata.keys()).filter(
      (signalId) => !result.has(signalId)
    );
    if (newIds.length === 0) {
      setData(result);
      return;
    }

    // Gets the new selected signals datablocks.
    setIsLoadingData(true);
    let mount = true;
    Promise.all(
      newIds.map((signalId) =>
        getDataBlocksData(signalId, selectedMetadata.get(signalId)!.id)
      )
    )
      .then((response) => {
        response.forEach((dataBlock) => {
          const signalData = metadata.get(dataBlock.signalId);
          signalData &&
            result.set(dataBlock.signalId, {
              ...signalData,
              data: dataBlock.data,
            });
        });
      })
      .finally(() => {
        if (!mount) return;
        setIsLoadingData(false);
        setData(result);
      });

    // Clears all resources
    return () => {
      mount = false;
    };
  }, [selectedMetadata]);

  return {
    data,
    selectedMetadata,
    isLoading: isLoadingMetadata || isLoadingData,
  };
};
