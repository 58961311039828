export interface DropdownOption {
  key: string;
  text: string;
}

export const periodDropdownOptions: DropdownOption[] = [
  { key: 'All data', text: 'All data' },
  { key: 'Last 2 years', text: 'Last 2 years' },
  { key: 'Last year', text: 'Last year' },
  { key: 'Last quarter', text: 'Last quarter' },
  { key: 'Last month', text: 'Last month' },
  { key: 'Last week', text: 'Last week' },
  { key: 'Custom date', text: 'Custom date' },
];

export const viewDropdownOptions: DropdownOption[] = [
  { key: 'Yearly', text: 'Yearly' },
  { key: 'Monthly', text: 'Monthly' },
  { key: 'Daily', text: 'Daily' },
];

export type MopSignalData<T> = {
  id: string;
  name: string;
  unit: string;
  data?: T[];
};

export type XAxisDates = {
  start?: Date;
  end?: Date;
};
