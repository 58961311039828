import type { SVGProps } from 'react';
import React from 'react';

const CloudIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='40'
      height='27'
      viewBox='0 0 40 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20 0.600006C26.7608 0.600006 30.5961 5.21521 31.1529 10.7904H31.3209C35.6744 10.7904 39.2001 14.4192 39.2001 18.8952C39.2001 23.3712 35.6721 27 31.3233 27H8.67685C4.32565 27 0.800049 23.3712 0.800049 18.8952C0.800049 14.568 4.09525 11.0328 8.24485 10.8024L8.84725 10.7904C9.40885 5.17921 13.2392 0.600006 20 0.600006ZM20 3.00001C14.9264 3.00001 11.7224 6.15841 11.2352 11.028C11.1763 11.6204 10.8993 12.1698 10.458 12.5694C10.0167 12.969 9.4426 13.1904 8.84725 13.1904H8.67925C5.66485 13.1904 3.20005 15.7296 3.20005 18.8952C3.20005 22.0632 5.66725 24.6 8.67685 24.6H31.3233C34.3329 24.6 36.8 22.0608 36.8 18.8952C36.8 15.7272 34.3329 13.1904 31.3233 13.1904H31.1529C30.5579 13.1904 29.9841 12.9694 29.5429 12.5703C29.1016 12.1712 28.8243 11.6224 28.7649 11.0304C28.2801 6.18001 25.0616 3.00001 20 3.00001Z'
        fill='#2C529F'
      />
    </svg>
  );
};

export default CloudIcon;
