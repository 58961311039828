import {
  DialogFooter,
  IComboBoxOption,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import {
  useZodForm,
  renderFormItems,
  FormItemProps,
  FormItemType,
} from "../../common/Form";
import {
  D325TableItem,
  DBasicTableItem,
  D850EcoTableItem,
  D850TableItem,
} from "../tableItemModels";
import { FieldError } from "react-hook-form";
import { z } from "zod";
import { useState } from "react";
import ConfigText from "./ConfigText";
import { Text } from "@fluentui/react/lib/Text";

type DdpConfigFormProps = {
  tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem;
  onClose: () => void;
};

type DialogFormProps = {
  show: boolean;
  onSubmit: (formData: FormData) => void;
};

type FormData = {
  baseUrl: string;
  apiKey: string;
};

type ConfigData = {
  machineId: string;
  dataloggerId: string;
  apiKey: string;
  baseUrl: string;
};

const baseUrls: string[] = [
  "api.dalog.net/dev",
  "api.dalog.net/uat",
  "api.dalog.net",
];

/**
 * The form schema
 */
const formSchema = z
  .object({
    baseUrl: z.string(),
    apiKey: z.string(),
  })
  .refine(
    (config) => {
      const valid = new RegExp(/^[a-f,\d]{32}$/);
      return valid.test(config.apiKey);
    },
    {
      path: ["apiKey"],
      message: "The API key must be an UUID without separators.",
    }
  );

/**
 * Gets the form item props
 * @param urls The URLs list.
 * @returns The form item props list.
 */
const getFormItemProps = (urls: IComboBoxOption[]): FormItemProps[] => {
  return [
    {
      name: "baseUrl",
      type: FormItemType.Dropdown,
      options: urls,
      groupProps: { label: "Base URL *" },
    },
    {
      name: "apiKey",
      type: FormItemType.TextField,
      groupProps: { label: "API Key *" },
    },
  ];
};

/**
 * Gets the configuration HTML element
 * @param config The configuration data
 * @returns The configuration HTML element
 */
const getConfigHtmlText = (config: ConfigData): JSX.Element => {
  if (!config) {
    return <></>;
  }

  return (
    <Stack tokens={{ padding: 10 }}>
      <Text variant="smallPlus">
        {"[DDP]"}
        <br />
        {"clean=TRUE"}
        <br />
        {"token0=--insecure"}
        <br />
        {`token1=-F "machineId=${config.machineId}`}
        <br />
        {`token2=-H "Cache-Control:no-cache"`}
        <br />
        {`token3=-H "Ocp-Apim-Subscription-Key:${config.apiKey}"`}
        <br />
        {`token4=-H "BoxId:${config.dataloggerId}"`}
        <br />
        {`url=https://${config.baseUrl}/files/v1/images/gzip`}
        <br />
        {"lastSave=0"}
        <br />
        {"cleanBefore=0"}
        <br />
        {"curlStart=0"}
        <br />
      </Text>
    </Stack>
  );
};

/**
 * Gets the Dialog form component
 * @param show Value indicating whether to show this form.
 * @param isLoading value indicating whether the form is in loading state
 * @param onSubmit Method called when the submit button is clicked.

 * @returns The Dialog Form component.
 */
const DialogForm = ({ show, onSubmit }: DialogFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useZodForm({
    mode: "onChange",
    schema: formSchema,
    defaultValues: {
      baseUrl: "",
      apiKey: "",
    },
  });

  return (
    <div hidden={!show}>
      {renderFormItems(
        getFormItemProps(
          baseUrls.map((url) => {
            return { key: url, text: url };
          })
        ),
        {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        }
      )}
      <DialogFooter>
        <PrimaryButton
          text="Generate"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        />
      </DialogFooter>
    </div>
  );
};

/**
 * Gets the DDP configuration form component.
 * @param tableItem The datalogger table item
 * @param onClose Method called when this dialog needs to be closed.
 * @returns The DDP configuration form component.
 */
const DdpConfigForm = ({ tableItem, onClose }: DdpConfigFormProps) => {
  const [configData, setConfigData] = useState<ConfigData>(undefined);

  // Function called when the submit button is clicked.
  const onSubmit = (formData: FormData) => {
    let configData: ConfigData = {
      machineId: tableItem.machineIds?.[0],
      dataloggerId: tableItem.id,
      baseUrl: formData.baseUrl.trim(),
      apiKey: formData.apiKey,
    };

    setConfigData(configData);
  };

  return (
    <section>
      <DialogForm show={!configData} onSubmit={onSubmit} />
      <ConfigText
        show={configData !== undefined}
        htmlText={getConfigHtmlText(configData)}
        onClose={onClose}
      />
    </section>
  );
};

export default DdpConfigForm;
