import type {
  ICommandBarProps,
  ICommandBarStyles,
  ISearchBoxStyles,
} from "@fluentui/react";
import { CommandBar, SearchBox } from "@fluentui/react";

import type { CommandBarItemProps } from "./methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
  overflowButtonStyles,
} from "./methods";

const commandBarStyles: ICommandBarStyles = {
  root: {
    padding: 0,
    marginTop: 16,
    background: "transparent",
    alignItems: "center",
  },
  primarySet: {
    height: "100%",
  },
};

const searchBoxStyles: ISearchBoxStyles = {
  root: {
    width: 300,
  },
};

export type BaseCommandBarProps = ICommandBarProps & {
  onSearch?: (value: string) => void;
};

// TODO: We need to add search value (maybe searchProps)
const BaseCommandBar = ({
  onSearch,
  farItems: _farItems,
  ...rest
}: BaseCommandBarProps) => {
  const innerFarItems = [
    ...(_farItems ?? []),
    ...(onSearch
      ? [
          {
            key: "search",
            onRender: () => (
              <SearchBox
                placeholder="Search"
                styles={searchBoxStyles}
                onChange={(_, newValue) => onSearch(newValue ?? "")}
              />
            ),
          },
        ]
      : []),
  ].filter(Boolean);

  return (
    <CommandBar
      styles={commandBarStyles}
      overflowButtonProps={overflowButtonStyles}
      farItems={innerFarItems}
      {...rest}
    />
  );
};

export default BaseCommandBar;

export { type CommandBarItemProps, CommandBarItemType, computeCommandBarItems };
