import { mergeStyleSets, Text } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { isEmpty } from "lodash-es";
import React, { useEffect, useMemo, useState } from "react";

import CalendarIcon from "../../../../../assets/svg/CalendarIcon";
import { useLocationSearch } from "../../../../../Hooks";
import { dropdownIconStyle } from "../../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import { YEARLY_EVENTS } from "./config";
import EventNameFilter from "./EventsTable/EventNameFilter";
import useDriveLoadStore from "../../hooks/useDriveLoadStore";
import {
  generateMonthsOptions,
  generateYearsOptions,
} from "../../../MachineCVSummaryPage/DetailsSection/OperationsTab/methods";
import { useTrendSignalsList } from "../../../MachineCVSummaryPage/hooks/useTrendSignalsList";
import {
  dropdownStyles,
  onRenderLabel,
} from "../../../../common/CommandBar/methods";

const EventsHeader = ({ ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  const [{ id }, ,]: any = useLocationSearch();
  const {
    events: { year, month, activeTab },
    updateDriveLoadStore,
  } = useDriveLoadStore((store: any) => ({
    events: store.events,
    updateDriveLoadStore: store.updateDriveLoadStore,
  }));

  const { signals, isLoading: loadingSignals } = useTrendSignalsList(
    id as string
  );
  const operationSignal = useMemo(() => {
    return signals.filter((signal: any) => {
      return signal.name === "operation_hours_OP";
    })[0];
  }, [signals, loadingSignals]);

  const { dataFrom, dataUntil } = operationSignal || {};
  const years: any = generateYearsOptions(dataFrom, dataUntil) || [];
  const [months, setMonths] = useState(
    generateMonthsOptions(
      year?.start || years[0].start,
      year?.end || years[0].end
    ) || []
  );

  useEffect(() => {
    const selectedYear = isEmpty(year) ? years[0] : year;
    const selectedMonth = isEmpty(month) ? months[0] : month;
    updateDriveLoadStore({
      events: { year: selectedYear, month: selectedMonth, activeTab },
    });
  }, []);

  return (
    <div {...rest} className="torque-header">
      <Text
        variant="mediumPlus"
        as="h2"
        styles={{
          root: { fontWeight: 600, marginLeft: "1em", paddingRight: "1em" },
        }}
      >
        Events
      </Text>
      <Dropdown
        calloutProps={{ calloutMinWidth: 100 }}
        className="layout-dropdown secondary-dropdown"
        styles={mergeStyleSets(dropdownStyles, { root: { minWidth: 130 } })}
        options={years.map(({ year }: any) => ({ text: year, key: year }))}
        selectedKey={year.year}
        onChange={(_, option) => {
          const selectedYear = years.filter(
            ({ year }: any) => year === option?.key
          )[0];
          if (selectedYear.year !== year.year) {
            const newMonthOptions =
              generateMonthsOptions(selectedYear.start, selectedYear.end) || [];
            setMonths(newMonthOptions);
            updateDriveLoadStore({
              events: {
                month: newMonthOptions[0],
                year: selectedYear,
                activeTab,
              },
            });
          }
        }}
        onRenderLabel={() =>
          onRenderLabel({
            label: "Year",
            icon: <CalendarIcon fill="#2c529f" style={dropdownIconStyle} />,
          })
        }
      />
      <Dropdown
        disabled={activeTab === YEARLY_EVENTS}
        calloutProps={{ calloutMinWidth: 200 }}
        className="layout-dropdown secondary-dropdown"
        styles={mergeStyleSets(dropdownStyles, { root: { minWidth: 156 } })}
        options={months.map(({ month }: any) => ({ text: month, key: month }))}
        selectedKey={month.month}
        onChange={(ev: any, option: any) => {
          const selectedMonth = months.filter(
            ({ month }: any) => month === option.key
          )[0];
          updateDriveLoadStore({
            events: { year, month: selectedMonth, activeTab },
          });
        }}
        onRenderLabel={() =>
          onRenderLabel({
            label: "Month",
            icon: <CalendarIcon fill="#2c529f" style={dropdownIconStyle} />,
          })
        }
      />
      <EventNameFilter />
    </div>
  );
};

export default EventsHeader;
