import { mergeStyleSets } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { format as fnsFormat } from "date-fns";
import { useState } from "react";

import PeriodIcon from "../../../../../../assets/svg/TasksIcon";
import { dropdownIconStyle } from "../../../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import { FORMAT } from "../../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import CustomDateDialog from "../../../../../../modules/common/components/CustomDateDialog";
import { periodDropdownOptions } from "../../../../MachineCVDriveLoadPage/config";
import usePerformanceStore from "../../../hooks/usePerformanceStore";
import {
  dropdownStyles,
  onRenderLabel,
} from "../../../../../common/CommandBar/methods";

const Header = () => {
  const [isCustomPeriodVisible, setIsCustomPeriodVisible] = useState(false);
  const { period, updateStore } = usePerformanceStore((store: any) => ({
    period: store.period,
    updateStore: store.updateStore,
  }));

  const onPeriodChange = (ev: any, selectedItem: any) => {
    if (selectedItem.key === periodDropdownOptions[6].key) {
      setIsCustomPeriodVisible(true);
    } else {
      updateStore({ period: selectedItem });
    }
  };

  const onCustomDateChange = ({ start, end }: any) => {
    updateStore({
      period: {
        ...periodDropdownOptions[6],
        startDate: fnsFormat(new Date(start), FORMAT),
        endDate: fnsFormat(new Date(end), FORMAT),
      },
    });
  };

  return (
    <div className="mb-8 torque-header">
      <div className="semibold-title">Load & Performance</div>
      <Dropdown
        className="layout-dropdown secondary-dropdown"
        styles={mergeStyleSets(dropdownStyles)}
        calloutProps={{ calloutMinWidth: 200 }}
        options={periodDropdownOptions}
        selectedKey={period.key}
        onChange={onPeriodChange}
        onRenderLabel={() =>
          onRenderLabel({
            label: "Period",
            icon: <PeriodIcon fill="#2c529f" style={dropdownIconStyle} />,
          })
        }
      />
      <CustomDateDialog
        initialValues={period}
        hidden={!isCustomPeriodVisible}
        setVisible={setIsCustomPeriodVisible}
        onSubmit={onCustomDateChange}
      />
    </div>
  );
};

export default Header;
