import { useQuery } from '@tanstack/react-query';
import orderBy from 'lodash-es/orderBy';

import { mapFileResponseToFileData } from '../../../../utils';
import { getApiClient } from '../../../../modules/core/apiClient/useApiStore';

type UseTask = {
  getTaskDetails: () => void;
  task: any;
  isLoading: boolean;
  isError: boolean;
};

export type UseTaskOpts = {
  taskId: string;
  reload?: boolean;
};

export const useTask = (opts: UseTaskOpts): UseTask => {
  const { data, isLoading, isError, refetch } = useQuery(
    ['machine-cv-task', opts],
    () =>
      getApiClient()
        .get(`/meta/machinecv/v1/tasks/${opts.taskId}`)
        .then(({ data }) => {
          const comments = orderBy(data.comments, ['createdAt'], ['desc']);
          return {
            ...data,
            files: orderBy(data.files, ['created'], ['desc']),
            comments: comments.map((comment) => ({
              ...comment,
              files: mapFileResponseToFileData(comment.files),
            })),
          };
        }),
    // axios.get<Task>(`http://localhost:3001/tasks/${opts.taskId}`).then(({ data }) => data),
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  return {
    getTaskDetails: refetch,
    task: data,
    isLoading,
    isError,
  };
};
