import type { SVGProps } from 'react';
import React from 'react';

const TrendsIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width={15} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M11.5 0a.5.5 0 1 0 0 1h1.793L8.5 5.793 5.854 3.146a.5.5 0 0 0-.708 0l-5 5a.5.5 0 1 0 .708.708L5.5 4.207l2.646 2.647a.5.5 0 0 0 .708 0L14 1.707V3.5a.5.5 0 0 0 1 0v-3a.5.5 0 0 0-.5-.5h-3Zm1 7a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 1 0v-8a.5.5 0 0 0-.5-.5Zm-8 2a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 1 0v-6a.5.5 0 0 0-.5-.5ZM8 11.5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0v-4Zm-8 1a.5.5 0 0 1 1 0v3a.5.5 0 0 1-1 0v-3Z' />
  </svg>
);

export default TrendsIcon;
