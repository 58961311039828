import "./style.scss";

import classNames from "classnames";

import { DAILY_EVENTS, YEARLY_EVENTS } from "./config";
import useDriveLoadStore from "../../hooks/useDriveLoadStore";

const EventsTabs = () => {
  const { events, updateDriveLoadStore } = useDriveLoadStore((store: any) => ({
    events: store.events,
    updateDriveLoadStore: store.updateDriveLoadStore,
  }));

  const { activeTab } = events;

  return (
    <div className="events-tabs">
      <div
        className={classNames("events-tabs__tab", {
          active: activeTab === YEARLY_EVENTS,
        })}
      >
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            updateDriveLoadStore({
              events: { ...events, activeTab: YEARLY_EVENTS },
            });
          }}
        >
          {YEARLY_EVENTS}
        </a>
      </div>
      <div
        className={classNames("events-tabs__tab", {
          active: activeTab === DAILY_EVENTS,
        })}
      >
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            updateDriveLoadStore({
              events: { ...events, activeTab: DAILY_EVENTS },
            });
          }}
        >
          {DAILY_EVENTS}
        </a>
      </div>
    </div>
  );
};

export default EventsTabs;
