import type { TaskStatuses } from '../../../types';

export const conditionColor = {
  Ok: '#66CD7D',
  Indication: '#FFCC00',
  Alert: '#FF7E0D',
  Danger: '#F63F4D',
  NotInstalled: '#5A71A9',
  NoStatus: '#B3B3B3',
  Faulty: '#C7B8E7',
};

export const conditionFormat = {
  Ok: 'Ok',
  Indication: 'Indication',
  Alert: 'Alert',
  Danger: 'Danger',
  NotInstalled: 'Not Installed',
  NoStatus: 'No Status',
  Faulty: 'Faulty',
};

export type Condition = keyof typeof conditionColor;

export const taskStatusFormat: Record<TaskStatuses | 'All', string> = {
  All: 'All',
  Done: 'Done',
  Cancelled: 'Cancelled',
  InProgress: 'In progress',
  ToDo: 'To do',
};
