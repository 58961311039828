import type { FC } from "react";
import { useMemo } from "react";

import type { TableProps } from "../../../../common/Table";
import Table from "../../../../common/Table";

import { getColumns } from "./columns";

type DocumentsTableProps = {
  items: any[];
  setItems?: (prev: any) => void;
  columns?: any[];
};

const DocumentsTable: FC<DocumentsTableProps> = ({
  items = [],
  setItems,
  columns,
}) => {
  const onRemove = (fileId: string) => {
    if (!setItems) return;

    setItems((prev: any) => [
      ...prev.filter(({ id }: { id: string }) => id !== fileId),
    ]);
  };

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items,
      perPage: 5,
      hidePerPage: true,
      hasSelection: false,
      columns:
        columns ||
        getColumns({
          onRemove,
        }),
    }),
    [items]
  );
  return <Table {...tableProps} />;
};

export default DocumentsTable;
