import type { SVGProps } from 'react';
import React from 'react';

const TaskIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.5 0C10.6217 0 12.6566 0.842855 14.1569 2.34315C15.6571 3.84344 16.5 5.87827 16.5 8C16.5 10.1217 15.6571 12.1566 14.1569 13.6569C12.6566 15.1571 10.6217 16 8.5 16C6.37827 16 4.34344 15.1571 2.84315 13.6569C1.34285 12.1566 0.5 10.1217 0.5 8C0.5 5.87827 1.34285 3.84344 2.84315 2.34315C4.34344 0.842855 6.37827 0 8.5 0ZM8.5 1C7.58075 1 6.6705 1.18106 5.82122 1.53284C4.97194 1.88463 4.20026 2.40024 3.55025 3.05025C2.90024 3.70026 2.38463 4.47194 2.03284 5.32122C1.68106 6.1705 1.5 7.08075 1.5 8C1.5 8.91925 1.68106 9.8295 2.03284 10.6788C2.38463 11.5281 2.90024 12.2997 3.55025 12.9497C4.20026 13.5998 4.97194 14.1154 5.82122 14.4672C6.6705 14.8189 7.58075 15 8.5 15C10.3565 15 12.137 14.2625 13.4497 12.9497C14.7625 11.637 15.5 9.85652 15.5 8C15.5 6.14348 14.7625 4.36301 13.4497 3.05025C12.137 1.7375 10.3565 1 8.5 1ZM8 3C8.11703 2.99996 8.23036 3.04097 8.32026 3.11589C8.41016 3.19081 8.47093 3.29489 8.492 3.41L8.5 3.5V8H11C11.1249 7.99977 11.2455 8.04633 11.3378 8.13051C11.4301 8.21469 11.4876 8.33039 11.4989 8.45482C11.5102 8.57926 11.4745 8.70341 11.3988 8.80283C11.3231 8.90225 11.2129 8.96974 11.09 8.992L11 9H8C7.88297 9.00004 7.76964 8.95903 7.67974 8.88411C7.58984 8.80919 7.52907 8.70511 7.508 8.59L7.5 8.5V3.5C7.5 3.36739 7.55268 3.24021 7.64645 3.14645C7.74021 3.05268 7.86739 3 8 3Z'
        fill='#2C529F'
      />
    </svg>
  );
};

export default TaskIcon;
