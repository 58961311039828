import type { IStackProps } from "@fluentui/react";
import { Stack, Text } from "@fluentui/react";
import classNames from "classnames";
import { endOfDay, format as fnsFormat, startOfDay } from "date-fns";
import { isEmpty } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";

import type { GroupedSignalsPeriod } from "../../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { FORMAT } from "../../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { getColumns } from "./columns";
import { periodDropdownOptions } from "../../../config";
import useDriveLoadStore from "../../../hooks/useDriveLoadStore";
import { NoData } from "../../../../../common/NoData";
import { Spin } from "../../../../../common/Spin";
import type { TableProps } from "../../../../../common/Table";
import Table from "../../../../../common/Table";

const EventsTable = ({ ...rest }: IStackProps) => {
  const {
    eventsTableData: data,
    eventsTableLoading: isLoading,
    updateDriveLoadStore,
    selectedTableEvent,
    selectedFilterEventsNames,
    period,
  } = useDriveLoadStore((store: any) => ({
    eventsTableData: store.eventsTableData,
    eventsTableLoading: store.eventsTableLoading,
    updateDriveLoadStore: store.updateDriveLoadStore,
    selectedTableEvent: store.selectedTableEvent,
    selectedFilterEventsNames: store.selectedFilterEventsNames,
    period: store.period,
  }));
  const [filteredData, setFilteredData] = useState<any[]>(data);
  const [latestPeriod, setLatestPeriod] = useState<
    GroupedSignalsPeriod | undefined
  >(period);

  const onRowClick = useCallback(
    (rowData: any) => {
      const eventToSave = isEmpty(selectedTableEvent)
        ? rowData
        : rowData.timeStamp === selectedTableEvent.timeStamp
        ? {}
        : rowData;

      if (isEmpty(eventToSave)) {
        updateDriveLoadStore({ selectedTableEvent: {}, period: latestPeriod });
        return;
      }

      const timeStampToDate = new Date(eventToSave.timeStamp);
      const startOfDayDate = startOfDay(
        timeStampToDate.setDate(timeStampToDate.getDate() - 1)
      );
      const endOfDayDate = endOfDay(
        timeStampToDate.setDate(timeStampToDate.getDate() + 1)
      );

      updateDriveLoadStore({
        selectedTableEvent: eventToSave,
        period: {
          ...periodDropdownOptions[6],
          startDate: fnsFormat(new Date(startOfDayDate), FORMAT),
          endDate: fnsFormat(new Date(endOfDayDate), FORMAT),
        },
      });
    },
    [selectedTableEvent, period]
  );

  const tableProps: TableProps = useMemo(() => {
    return {
      persistOpts: {
        key: `table-drive-load-events`,
        version: 1,
      },
      items: filteredData,
      perPage: 99999,
      hidePerPage: true,
      isLoading,
      isError: false,
      hasSelection: false,
      setKey: "none",
      onRenderRow: (props: any, defaultRender: any) => {
        return defaultRender({
          ...props,
          className: classNames(props.className, {
            selected: selectedTableEvent?.timeStamp === props?.item?.timeStamp,
          }),
          onClick: () => onRowClick(props?.item),
        });
      },
      columns: getColumns(),
      onShouldVirtualize: () => false,
    };
  }, [onRowClick, filteredData]);

  useEffect(() => {
    if (isEmpty(selectedTableEvent)) {
      return;
    }

    updateDriveLoadStore({
      selectedTableEvent: {},
      period: { ...periodDropdownOptions[4] },
    });
  }, [isLoading]);

  // Updates the filtered data.
  useEffect(() => {
    if (selectedFilterEventsNames.includes("selectAll")) {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter(({ name }: any) => selectedFilterEventsNames.includes(name))
      );
    }
    return;
  }, [selectedFilterEventsNames, data]);

  useEffect(() => {
    if (!isEmpty(selectedTableEvent)) {
      return;
    }

    setLatestPeriod(period);
  }, [period]);

  let content: JSX.Element | null = null;
  if (isLoading) {
    content = <Spin style={{ height: "100%" }} />;
  } else if (isEmpty(filteredData)) {
    content = <NoData styles={{ root: { height: "100%" } }} />;
  } else {
    content = <Table {...tableProps} />;
  }
  return (
    <Stack {...rest} horizontalAlign="stretch" tokens={{ childrenGap: 5 }}>
      <Stack
        key="events-table-header"
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: 20 }}
        role="menubar"
        styles={{ root: { margin: "0em 0em 0.4em 1em" } }}
      >
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          styles={{ root: { height: "2.15em" } }}
        >
          <Text
            variant="mediumPlus"
            as="h2"
            styles={{ root: { fontWeight: 600 } }}
          >
            Events Table
          </Text>
        </Stack>
      </Stack>
      <div
        key="events-table-content"
        className={classNames("table-drive-load-events", {
          "data-section": !isEmpty(filteredData),
        })}
      >
        {content}
      </div>
    </Stack>
  );
};

export default EventsTable;
