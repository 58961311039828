import { Stack, Text } from "@fluentui/react";

import {
  conditionColor,
  conditionFormat,
} from "../../../../../modules/machine-cv/constants";
import type { MachineCvOverviewMachine } from "../../../../../types";

const Condition = ({
  conditions,
}: // comment,
{
  conditions: MachineCvOverviewMachine["conditions"];
  // comment: string;
}) => (
  <>
    {/*<>{comment}</>*/}
    {conditions?.map(({ name, value }) => (
      <Stack
        key={name}
        horizontal
        horizontalAlign="space-between"
        style={{
          color: "#fff",
          padding: "4px 16px",
          backgroundColor: conditionColor[value],
        }}
      >
        <Text style={{ color: "inherit" }}>{name}</Text>
        <Text style={{ color: "inherit", fontWeight: 600 }}>
          {conditionFormat[value]}
        </Text>
      </Stack>
    ))}
  </>
);

export default Condition;
