import { useMemo } from 'react';

import { setMaxNumberDecimals } from '../../common/components/InteractiveColorBar/methods';

import type { WaterfallChartInterface } from '../types';

const useMinMaxValue = ({ data }: WaterfallChartInterface) => {
  const [minValue, maxValue, rms] = useMemo(() => {
    if (data.length === 0) return [undefined, undefined, 0];

    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;
    const allValues: any = [];

    data.forEach(({ values }) => {
      values.forEach(({ a }) => {
        min = Math.min(min, a);
        max = Math.max(max, a);
        allValues.push(a);
      });
    });

    const sumOfSquares = allValues.reduce((sum: any, num: any) => sum + num ** 2, 0);
    const meanSquare = sumOfSquares / allValues.length;
    const rms = Math.sqrt(meanSquare);

    return [setMaxNumberDecimals(min, 3), setMaxNumberDecimals(max, 3), rms * 1.2];
  }, [data]);

  return [minValue, maxValue, rms];
};

export default useMinMaxValue;
