import "../../styles.scss";

import {
  DetailsListLayoutMode,
  IconButton,
  Stack,
  Text,
} from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";

import {
  useWebAnalysisPermissions,
  useLocationSearch,
} from "../../../../../Hooks";

import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import { FormType } from "../CommandBar";
import GroupDialog from "../dialogs/GroupDialog";
import { getColumns } from "./columns";
import useInfoStore from "../../hooks/useInfoStore";
import { useMachineCVInfo } from "../../hooks/useMachineCVInfo";
import type { Group } from "../../../../../types";
import type { TableProps } from "../../../../common/Table/Table";
import Table from "../../../../common/Table/Table";

import EntryDialog from "../dialogs/EntryDialog/EntryDialog";

const iconStyles = {
  root: {
    color: "#fff",
  },
};

const iconButtonStyles = {
  root: {
    selectors: {
      ":hover": { background: "#0078D4 !important" },
    },
  },
};

interface MachineCVInfoMachineDetailsProps {
  group: Group;
  allGroups: Group[];
}

const MachineCVInfoMachineDetails: React.FC<
  MachineCVInfoMachineDetailsProps
> = ({ group, allGroups }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [show3Dots, setShow3Dots] = useState(false);
  const [{ id }] = useLocationSearch();
  const { refetch } = useMachineCVInfo({ machineId: id || "" });
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const { useReadyPermission } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermissions = useReadyPermission({
    permissionRoles: ["MachineCvAdministrator"],
  });

  const hasMachineCvContributorPermissions = useReadyPermission({
    permissionRoles: ["MachineCvContributor"],
  });

  const [isAddEntryDialogHidden, setIsAddEntryDialogHidden] = useState(true);
  const toggleIsAddEntryDialogHidden = () =>
    setIsAddEntryDialogHidden(!isAddEntryDialogHidden);

  const [isEditGroupDialogHidden, setIsEditGroupDialogHidden] = useState(true);
  const toggleIsEditGroupDialogHidden = () =>
    setIsEditGroupDialogHidden(!isEditGroupDialogHidden);

  const [isDeleteGroupDialogHidden, setIsDeleteGroupDialogHidden] =
    useState(true);
  const toggleIsDeleteGroupDialogHidden = () =>
    setIsDeleteGroupDialogHidden(!isDeleteGroupDialogHidden);

  useEffect(() => {
    setShow3Dots(
      hasMachineCvAdministratorPermissions ||
        (hasMachineCvContributorPermissions &&
          group.name.trim() === "Customer Feedback")
    );

    return;
  }, [
    hasMachineCvAdministratorPermissions,
    hasMachineCvContributorPermissions,
    group.name,
  ]);

  const infoStore = useInfoStore();

  const groupEntryIDs = group.entries.map(({ id }) => id as string);

  const style = [
    {},
    {
      width: "100%",
    },
    {
      width: "calc(50% - 5px)",
    },
    {
      width: "calc(33.33% - 5px)",
    },
  ];

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-info" + group.name,
        version: 1,
      },
      items: group.entries,
      layoutMode: DetailsListLayoutMode.justified,
      hasSelection: false,
      columns: getColumns(group, show3Dots),
      detailsListStyles: { root: { margin: "0 !important" } },
      styles: {
        root: { margin: 0 },
      },
    }),
    [group, show3Dots]
  );

  const groupMenuActions = [
    {
      key: "add_entry",
      text: "Add Entry",
      onClick: () => toggleIsAddEntryDialogHidden(),
    },
  ];

  group.name.trim() !== "Customer Feedback" &&
    groupMenuActions.push(
      {
        key: "edit_group",
        text: "Edit Group",
        onClick: () => toggleIsEditGroupDialogHidden(),
      },
      {
        key: "delete_group",
        text: "Delete Group",
        onClick: () => toggleIsDeleteGroupDialogHidden(),
      }
    );

  return (
    <div
      className="info-machine-details"
      style={{
        ...style[infoStore.perRow],
        minHeight: !group?.entries?.length && isOpen ? "260px" : "0px",
      }}
    >
      <Stack
        horizontal
        style={{
          background: "#2C529F",
          padding: "0px 8px",
          cursor: "pointer",
          marginTop: "10px",
        }}
        horizontalAlign="space-between"
        verticalAlign="center"
        onClick={toggleIsOpen}
      >
        <Text style={{ color: "#fff" }}>{group.name}</Text>
        <Stack horizontal>
          {show3Dots && (
            <IconButton
              iconProps={{
                iconName: "More",
                styles: iconStyles,
              }}
              styles={iconButtonStyles}
              style={{ color: "#fff" }}
              menuProps={{
                shouldFocusOnMount: true,
                items: groupMenuActions,
              }}
              menuIconProps={{ style: { display: "none" } }}
            />
          )}

          <IconButton
            className="toggle-overview-button"
            iconProps={{
              iconName: !isOpen ? "ChevronDown" : "ChevronUp",
              styles: iconStyles,
            }}
            styles={iconButtonStyles}
            onClick={toggleIsOpen}
          />
        </Stack>
      </Stack>
      {isOpen && <Table {...tableProps} className="info-table" />}
      <EntryDialog
        formType={FormType.New}
        hidden={isAddEntryDialogHidden}
        group={group}
        onDismiss={toggleIsAddEntryDialogHidden}
      />
      <GroupDialog
        formType={FormType.Edit}
        hidden={isEditGroupDialogHidden}
        group={group}
        allGroups={allGroups}
        onDismiss={toggleIsEditGroupDialogHidden}
      />
      <DeleteDialog
        dialogContentProps={{
          onDismiss: toggleIsDeleteGroupDialogHidden,
        }}
        data={{ ids: [group.id, ...groupEntryIDs], name: group.name }}
        hidden={isDeleteGroupDialogHidden}
        route={"meta/machinecv/v1/infos"}
        onSuccess={refetch}
      />
    </div>
  );
};

export default MachineCVInfoMachineDetails;
