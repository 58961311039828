import * as am5 from '@amcharts/amcharts5';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import * as am5xy from '@amcharts/amcharts5/xy';

const AMCHARTS_LICENSE = process.env.REACT_APP_AMCHARTS_LICENSE;

const fontFamily = '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif';

export type ScatterChartData = {
  x: number;
  y: number;
  date: string;
};

type InitializeScatterChartProps = {
  chartId: string;
  labelX: string;
  labelY: string;
  settings?: any;
};

type CreateScatterSeriesProps = {
  chartId: string;
  root: am5.Root;
  chart: am5xy.XYChart;
  xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>;
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>;
  showDates?: boolean;
  data?: ScatterChartData[];
};

const chartSettings = {
  fillEarliest: am5.Color.fromRGB(185, 198, 193),
  fillLatest: am5.Color.fromRGB(54, 80, 145),
  strokeEarliest: am5.Color.fromRGB(142, 147, 142),
  strokeLatest: am5.Color.fromRGB(43, 62, 115),
  radiusEarliest: 6,
  radiusLatest: 14,
};

export const initializeScatterChart = ({
  chartId,
  labelX,
  labelY,
  settings,
}: InitializeScatterChartProps) => {
  am5.addLicense(`${AMCHARTS_LICENSE}`);
  const root = am5.Root.new(chartId, {});

  // Creates the theme.
  const myTheme = am5.Theme.new(root);
  myTheme.rule('AxisLabel')?.setAll({
    fontFamily: fontFamily,
  });

  myTheme.rule('Label')?.setAll({
    fontSize: 12,
    fontFamily: fontFamily,
  });

  root.setThemes([am5themes_Responsive.new(root), myTheme]);

  const cursor = am5xy.XYCursor.new(root, {});

  cursor.lineX.set('visible', false);
  cursor.lineY.set('visible', false);

  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      cursor,
      wheelY: 'none',
      wheelX: 'none',
      pinchZoomX: false,
      pinchZoomY: false,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  );

  // Set Plot background to API background
  chart.plotContainer.children.push(
    am5.Picture.new(root, {
      src: settings.background,
      width: am5.p100,
      height: am5.p100,
    }),
  );

  // Labels
  chart.bottomAxesContainer.children.push(
    am5.Label.new(root, {
      id: `${chartId}_axisX_label`,
      text: labelX,
      x: am5.p50,
      centerX: am5.p50,
      fontWeight: 'normal',
      fontSize: 12,
      textAlign: 'center',
    }),
  );

  chart.leftAxesContainer.children.push(
    am5.Label.new(root, {
      id: `${chartId}_axisY_label`,
      text: labelY,
      y: am5.p50,
      centerX: am5.p50,
      rotation: -90,
      fontWeight: 'normal',
      fontSize: 12,
      textAlign: 'center',
    }),
  );

  const { xMax = 1.3, xMin = 0.2, yMax = 1.3, yMin = 0.1 } = settings || {};

  // Axes
  const xAxis = chart.xAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {}),
      visible: false,
      strictMinMax: true,
      min: xMin,
      max: xMax,
    }),
  );

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      visible: false,
      strictMinMax: true,
      min: yMin,
      max: yMax,
    }),
  );

  yAxis.get('renderer').grid.template.set('forceHidden', true);
  xAxis.get('renderer').grid.template.set('forceHidden', true);

  createScatterSeries({ chartId, chart, root, xAxis, yAxis });

  return { chart, root, yAxis, xAxis };
};

export const createScatterSeries = ({
  chartId,
  root,
  chart,
  xAxis,
  yAxis,
  showDates = false,
  data = [],
}: CreateScatterSeriesProps) => {
  if (chart.series.length > 0) {
    chart.series.clear();
  }
  const series = chart.series.push(
    am5xy.LineSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: 'y',
      valueXField: 'x',
      valueField: 'date',
    }),
  );

  series.strokes.template.set('visible', false);

  // Adds the legends.
  const legendId = `scatter-chart-legends-${chartId}`;
  const legend = chart.bottomAxesContainer.children.values.find(
    (child) => child.get('id') === legendId,
  );
  if (!legend) {
    const legendsContainer = am5.Container.new(root, {
      id: legendId,
      position: 'absolute',
      layout: root.horizontalLayout,
      x: am5.percent(100),
      y: am5.percent(0),
      centerX: am5.percent(100),
      centerY: am5.percent(0),
    });

    appendLegend(
      root,
      legendsContainer,
      chartSettings.radiusLatest,
      chartSettings.fillLatest,
      chartSettings.strokeLatest,
      'Latest date',
    );

    appendLegend(
      root,
      legendsContainer,
      chartSettings.radiusEarliest,
      chartSettings.fillEarliest,
      chartSettings.strokeEarliest,
      'Earliest date',
    );

    chart.bottomAxesContainer.set('paddingTop', 4);
    chart.bottomAxesContainer.children.push(legendsContainer);
  }

  // Adds bullets when data is validated
  series.events.once('datavalidated', () => {
    if (series.data.length === 0) {
      return;
    }

    for (const item of series.data) {
      const index = series.data.indexOf(item);
      if (index < 0) continue;

      const bullet = am5.Container.new(root, {});

      // Adds the circle
      bullet.children.push(
        am5.Circle.new(root, {
          fill: getFillFromIndex(data.length, index),
          stroke: getStrokeFromIndex(data.length, index),
          strokeWidth: 1,
          centerX: am5.percent(50),
          centerY: am5.percent(50),
          radius: getRadiusFromIndex(data.length, index),
          layer: data.length - index,
          tooltipText: '{date}',
          tooltip: am5.Tooltip.new(root, {
            dy: -10,
          }),
        }),
      );

      // Adds the label
      if (showDates === true) {
        bullet.children.push(
          am5.Label.new(root, {
            text: '{date}',
            centerX: am5.percent(50),
            centerY: am5.percent(150),
            populateText: true,
            textAlign: 'center',
            fontSize: '12px',
            fill: am5.Color.fromRGB(59, 58, 57),
            background: am5.RoundedRectangle.new(root, {
              fill: am5.Color.fromRGB(59, 58, 57),
              fillOpacity: 0.15,
              cornerRadiusBL: 2,
              cornerRadiusBR: 2,
              cornerRadiusTL: 2,
              cornerRadiusTR: 2,
            }),
            paddingBottom: 3,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 3,
            layer: data.length - 1 - index,
          }),
        );
      }

      const dataItem = series.dataItems.at(index);
      dataItem && series.addBullet(dataItem, am5.Bullet.new(root, { sprite: bullet }));
    }
  });

  series.data.setAll(data);
  return series;
};

const appendLegend = (
  root: am5.Root,
  chart: am5.Container,
  radius: number,
  fill: am5.Color,
  stroke: am5.Color,
  text: string,
) => {
  const container = am5.Container.new(root, {
    layout: root.horizontalLayout,
    y: am5.percent(0),
    centerX: am5.percent(100),
    centerY: am5.percent(0),
  });

  container.children.push(
    am5.Circle.new(root, {
      fill,
      stroke,
      strokeWidth: 1,
      y: am5.percent(50),
      centerX: am5.percent(50),
      centerY: am5.percent(50),
      radius,
    }),
  );

  container.children.push(
    am5.Label.new(root, {
      text,
      fontSize: '12px',
      fontWeight: 'normal',
    }),
  );

  chart.children.push(container);
};

const getFillFromIndex = (length: number, index: number): am5.Color => {
  if (length === 0) return chartSettings.fillLatest;
  if (index < 0) return chartSettings.fillEarliest;
  if (index > length - 1) return chartSettings.fillLatest;
  if (length === 1) return chartSettings.fillLatest;

  const position = index / (length - 1);
  return am5.Color.interpolate(position, chartSettings.fillEarliest, chartSettings.fillLatest);
};

const getStrokeFromIndex = (length: number, index: number): am5.Color => {
  if (length === 0) return chartSettings.strokeLatest;
  if (index < 0) return chartSettings.strokeEarliest;
  if (index > length - 1) return chartSettings.strokeLatest;
  if (length === 1) return chartSettings.strokeLatest;

  const position = index / (length - 1);
  return am5.Color.interpolate(position, chartSettings.strokeEarliest, chartSettings.strokeLatest);
};

const getRadiusFromIndex = (length: number, index: number): number => {
  const min = chartSettings.radiusEarliest;
  const max = chartSettings.radiusLatest;
  if (length === 0) return max;
  if (index < 0) return min;
  if (index > length - 1) return max;
  if (length === 1) return max;

  return Math.floor(min + ((max - min) * index) / (length - 1));
};
