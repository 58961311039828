import { createBreakpoint } from 'react-use';

export enum AppBreakpoints {
  LaptopLarge = 'LaptopLarge',
  LaptopMedium = 'LaptopMedium',
  LaptopSmall = 'LaptopSmall',
  Tablet = 'Tablet',
}

export const useBreakpoint = createBreakpoint({
  LaptopLarge: 1440,
  LaptopMedium: 1280,
  LaptopSmall: 1024,
  Tablet: 768,
});
