import type { SVGProps } from 'react';
import React from 'react';

const FilterIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='14'
      height='9'
      viewBox='0 0 14 9'
      fill='#0078D4'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M4.49995 8H9.49995C9.62489 7.99977 9.7454 8.04633 9.83773 8.13051C9.93006 8.21469 9.98753 8.33039 9.99882 8.45482C10.0101 8.57926 9.9744 8.70341 9.89873 8.80283C9.82305 8.90226 9.71289 8.96974 9.58995 8.992L9.49995 9H4.49995C4.375 9.00023 4.2545 8.95367 4.16216 8.86949C4.06983 8.78531 4.01236 8.66962 4.00107 8.54518C3.98978 8.42074 4.02549 8.29659 4.10117 8.19717C4.17684 8.09775 4.287 8.03026 4.40995 8.008L4.49995 8H9.49995H4.49995ZM2.49995 4H11.4999C11.6249 3.99977 11.7454 4.04633 11.8377 4.13051C11.9301 4.21469 11.9875 4.33039 11.9988 4.45482C12.0101 4.57926 11.9744 4.70341 11.8987 4.80283C11.8231 4.90226 11.7129 4.96974 11.5899 4.992L11.4999 5H2.49995C2.375 5.00023 2.25449 4.95367 2.16216 4.86949C2.06983 4.78531 2.01236 4.66961 2.00107 4.54518C1.98978 4.42074 2.02549 4.29659 2.10117 4.19717C2.17684 4.09775 2.287 4.03026 2.40995 4.008L2.49995 4H11.4999H2.49995ZM0.499945 8.48188e-07H13.4999C13.6249 -0.000229165 13.7454 0.0463299 13.8377 0.130509C13.9301 0.214689 13.9875 0.330387 13.9988 0.454822C14.0101 0.579258 13.9744 0.703411 13.8987 0.802833C13.8231 0.902255 13.7129 0.969741 13.5899 0.992001L13.4999 1H0.499945C0.374999 1.00023 0.254495 0.953672 0.162162 0.869493C0.069829 0.785313 0.0123591 0.669614 0.00106967 0.545179C-0.0102198 0.420744 0.0254892 0.296591 0.101165 0.197169C0.17684 0.0977471 0.286998 0.030261 0.409945 0.00800075L0.499945 8.48188e-07H13.4999H0.499945Z' />
    </svg>
  );
};

export default FilterIcon;
