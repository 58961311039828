import type { SVGProps } from 'react';
import React from 'react';

const DalogLogo: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='68'
      height='15'
      viewBox='0 0 68 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0.347656 1.92166H4.9805C9.30605 1.96933 12.7325 3.60466 12.6869 8.06766C12.2855 12.8803 9.51605 14.005 4.86844 13.7713L0.347656 13.7213V1.92166ZM4.57912 11.2137L5.74311 11.2553C7.46903 11.3387 8.64972 10.5737 8.67316 8.276C8.6966 5.97833 7.62958 4.79666 5.6227 4.755L4.53899 4.79666L4.57912 11.2137Z'
        fill='#2852A1'
      />
      <path
        d='M12.8475 14.2137H16.8612L20.1525 5.33833L23.8452 14.2137H27.8589L22.4002 1.92166H17.9851L12.8475 14.2137Z'
        fill='#2852A1'
      />
      <path
        d='M21.4668 13.875C21.1319 14.1333 20.7173 14.2863 20.2681 14.2863C19.1571 14.2863 18.2561 13.3507 18.2561 12.1977C18.2561 11.044 19.1571 10.109 20.2681 10.109C21.3791 10.109 22.2801 11.044 22.2801 12.1977C22.2798 12.8847 21.96 13.494 21.4668 13.875Z'
        fill='#EC3A3D'
      />
      <path d='M33.3177 10.922V1.92166H28.9025V14.2137H37.9736V10.922H33.3177Z' fill='#2852A1' />
      <path
        d='M45.0305 1.87666C48.6101 1.92166 51.3279 4.19833 51.3099 7.99466C51.2916 11.7563 49.0885 14.134 44.972 14.1127C40.8558 14.092 38.6643 11.7247 38.6826 7.96233C38.6823 4.408 40.878 1.85533 45.0305 1.87666ZM45.0016 10.741C46.6302 10.769 47.2078 8.95766 47.2454 8.06766C47.1385 6.54666 46.2821 5.57466 45.0016 5.46333C43.4994 5.54666 42.5358 6.519 42.5894 8.06766C42.6431 9.269 43.3655 10.769 45.0016 10.741Z'
        fill='#2852A1'
      />
      <path
        d='M64.1401 13.5717C62.1461 14.0573 60.6591 14.09 59.5776 14.09C56.4343 14.09 53.0654 12.4 53.0654 8.12567C53.0654 4.25833 56.1563 1.925 60.2102 2.05033C61.7486 2.04566 62.9912 2.44866 64.1401 2.86966V5.78633C63.1598 5.236 61.3144 4.86966 60.5711 4.86966C58.0193 4.86966 56.9324 6.189 56.9189 8.217C56.9086 9.75867 57.711 11.4533 60.0092 11.4533C60.4317 11.4533 60.8055 11.3747 61.0929 11.245L61.0951 10.5087L64.1401 10.495V13.5717Z'
        fill='#2852A1'
      />
      <path d='M64.1401 7.04666H59.6078V9.505H64.1401V7.04666Z' fill='#EC3A3D' />
      <path
        d='M64.7621 1.624C64.7621 0.828331 65.386 0.180664 66.1521 0.180664C66.9186 0.180664 67.5425 0.828331 67.5425 1.624C67.5425 2.41966 66.9186 3.06733 66.1521 3.06733C65.386 3.067 64.7621 2.41933 64.7621 1.624ZM67.2034 1.624C67.2034 0.948331 66.7337 0.460664 66.1521 0.460664C65.5706 0.460664 65.1012 0.948331 65.1012 1.624C65.1012 2.29966 65.571 2.78733 66.1521 2.78733C66.7337 2.787 67.2034 2.29933 67.2034 1.624ZM65.6169 0.800331H66.2022C66.5798 0.800331 66.7841 0.936331 66.7841 1.28C66.7841 1.56 66.6299 1.696 66.3721 1.71966L66.7953 2.43533H66.4951L66.087 1.73166H65.9097V2.43533H65.6169V0.800331ZM65.9097 1.5H66.1679C66.341 1.5 66.4912 1.476 66.4912 1.25633C66.4912 1.06433 66.322 1.03233 66.1717 1.03233H65.91L65.9097 1.5Z'
        fill='#2852A1'
      />
    </svg>
  );
};

export default DalogLogo;
