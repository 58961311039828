import { mergeStyleSets } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import classNames from "classnames";
import { isEmpty } from "lodash-es";
import { useEffect, useState } from "react";

import CalendarIcon from "../../../../../assets/svg/CalendarIcon";
import LayoutIcon from "../../../../../assets/svg/LayoutIcon";
import { dropdownIconStyle } from "../../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import { operationView } from "./config";
import { generateMonthsOptions, generateYearsOptions } from "./methods";
import useSummaryStore from "../../hooks/useSummaryStore";
import {
  dropdownStyles,
  onRenderLabel,
} from "../../../../common/CommandBar/methods";

const OperationCommandBar = () => {
  const { updateOperations, operations } = useSummaryStore((state) => ({
    updateOperations: state.updateOperations,
    operations: state.operations,
  }));

  const { month, signal: operationSignal = {}, view, year } = operations;
  const [yearsOptions, setYearsOptions]: any = useState([]);
  const [monthsOptions, setMonthsOptions]: any = useState([]);

  useEffect(() => {
    const years =
      generateYearsOptions(
        operationSignal.dataFrom,
        operationSignal.dataUntil
      ) || [];
    let months: any = [];

    if (years.length > 0) {
      months = generateMonthsOptions(years[0].start, years[0].end);
      if (!year) {
        updateOperations({
          year: { key: years[0].year, text: years[0].year, ...years[0] },
          month: { key: months[0].month, text: months[0].month, ...months[0] },
        });
      } else {
        const isAvailableYear = years.filter(
          (yearItem: any) => yearItem.year === year.year
        )[0];
        if (isAvailableYear) {
          const isAvailableMonth = month
            ? months.filter(
                (monthItem: any) => monthItem.month === month.month
              )[0]
            : false;
          updateOperations({
            year: { key: years[0].year, text: years[0].year, ...years[0] },
            month: isAvailableMonth
              ? { ...month }
              : { key: months[0].month, text: months[0].month, ...months[0] },
          });
        } else {
          updateOperations({
            year: { key: years[0].year, text: years[0].year, ...years[0] },
            month: {
              key: months[0].month,
              text: months[0].month,
              ...months[0],
            },
          });
        }
      }
    }

    setYearsOptions(years);
    setMonthsOptions(months);
  }, [operationSignal.dataFrom, operationSignal.dataUntil]);

  const onYearChange = (ev: any, item: any) => {
    const selectedOption =
      yearsOptions.filter(({ year }: any) => year === item.key)[0] || {};
    const yearMonths = generateMonthsOptions(
      selectedOption.start,
      selectedOption.end
    );
    const selectedYearMonth = month
      ? yearMonths.filter((yearMonth) => yearMonth.month === month.key)[0] ||
        yearMonths[0]
      : yearMonths[0];
    updateOperations({
      year: { ...item, ...selectedOption },
      month: {
        key: selectedYearMonth.month,
        text: selectedYearMonth.month,
        ...selectedYearMonth,
      },
    });
    setMonthsOptions(yearMonths);
  };

  const onMonthChange = (ev: any, item: any) => {
    const selectedOption =
      monthsOptions.filter(({ month }: any) => month === item.key)[0] || {};
    updateOperations({ month: { ...item, ...selectedOption } });
  };

  const isDisabled = isEmpty(operationSignal);

  return (
    <div className="operation-command-bar">
      <Dropdown
        disabled={isDisabled}
        calloutProps={{ calloutMinWidth: 100 }}
        className={classNames("layout-dropdown secondary-dropdown", {
          disabled: isDisabled,
        })}
        styles={mergeStyleSets(dropdownStyles, { root: { minWidth: 156 } })}
        options={operationView}
        selectedKey={view.key}
        onChange={(ev: any, item: any) => updateOperations({ view: item })}
        onRenderLabel={() =>
          onRenderLabel({
            label: "View",
            icon: <LayoutIcon fill="#2c529f" style={dropdownIconStyle} />,
          })
        }
      />
      <Dropdown
        disabled={isDisabled}
        calloutProps={{ calloutMinWidth: 100 }}
        className={classNames("layout-dropdown secondary-dropdown", {
          disabled: isDisabled,
        })}
        styles={mergeStyleSets(dropdownStyles, { root: { minWidth: 156 } })}
        options={yearsOptions.map(({ year }: any) => ({
          key: year,
          text: year,
        }))}
        selectedKey={year?.key}
        onChange={onYearChange}
        onRenderLabel={() =>
          onRenderLabel({
            label: "Year",
            icon: <CalendarIcon fill="#2c529f" style={dropdownIconStyle} />,
          })
        }
      />
      <Dropdown
        calloutProps={{ calloutMinWidth: 200 }}
        disabled={view?.key === operationView[0].key || isDisabled}
        className={classNames("layout-dropdown secondary-dropdown", {
          disabled: view?.key === operationView[0].key || isDisabled,
        })}
        styles={mergeStyleSets(dropdownStyles, { root: { minWidth: 156 } })}
        options={monthsOptions.map(({ month }: any) => ({
          key: month,
          text: month,
        }))}
        selectedKey={month?.key}
        onChange={onMonthChange}
        onRenderLabel={() =>
          onRenderLabel({
            label: "Month",
            icon: <CalendarIcon fill="#2c529f" style={dropdownIconStyle} />,
          })
        }
      />
    </div>
  );
};

export default OperationCommandBar;
