import { useQuery } from '@tanstack/react-query';

import { getApiClient } from '../../../modules/core/apiClient/useApiStore';
import type { GetGatewayTelemetryResponse } from '../../../types';

export type UseGatewayTelemetryParams = {
  gatewayId: string;
};

export const useGatewayTelemetry = ({ gatewayId }: UseGatewayTelemetryParams) => {
  const {
    data = {} as GetGatewayTelemetryResponse,
    isLoading,
    isError,
  } = useQuery<GetGatewayTelemetryResponse>(
    ['ws-gateway-telemetry', gatewayId],
    () =>
      getApiClient()
        .get<GetGatewayTelemetryResponse>(`/dws/v1/telemetry/gateways/${gatewayId}`)
        .then(({ data }) => data),
    {
      cacheTime: 0,
    },
  );
  return {
    data,
    isLoading,
    isError,
  };
};
