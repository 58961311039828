export enum REPORT_CATEGORIES {
  Report = 'Report',
  Alert = 'Alert',
  Information = 'Information',
}

export const REPORT_CATEGORY_OPTIONS = [
  {
    text: REPORT_CATEGORIES.Report,
    key: REPORT_CATEGORIES.Report,
  },
  {
    text: REPORT_CATEGORIES.Alert,
    key: REPORT_CATEGORIES.Alert,
  },
  {
    text: REPORT_CATEGORIES.Information,
    key: REPORT_CATEGORIES.Information,
  },
];
