import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { isEmpty } from "lodash-es";

import { default as chartSymbols } from "../../../../assets/svg/ChartSymbolsIcons";
import type { CreateSeriesOpts } from "../TrendViewGroupedPlot/config";
import { format as signalFormatter } from "../../../common/utils/signalFormatter";

export const yAxisWheel = (
  ev: any,
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>
) => {
  ev.originalEvent.preventDefault();
  const delta = ev.originalEvent.deltaY;
  let start = yAxis.get("start");
  let end = yAxis.get("end");

  if (start === undefined || end === undefined) return;
  const scrollStep = 0.01 * (end - start);
  if (delta < 0) {
    start -= scrollStep;
    end -= scrollStep;
  } else {
    start += scrollStep;
    end += scrollStep;
  }

  if (end <= 0 || end >= 2.0) return;
  if (start >= 1 || start <= -1) return;
  yAxis.zoom(start, end);
};

export const removePreviousSeries = ({ ref }: { ref: any }) => {
  const count = ref.current.chart.yAxes.length;

  for (let idx = 0; idx < count; idx++) {
    const currentYAxes = ref.current.chart.yAxes.pop();

    if (currentYAxes) {
      const currentSeries = currentYAxes.series.at(0);

      if (currentSeries) {
        ref.current.chart.series.removeValue(currentSeries);
      }
    }
  }
};

export const createSeries = ({
  ref,
  xAxis,
  data,
  signals,
  numberOfSignals,
  yAxisSettings = {},
  getSelectedAxisStartEnd,
}: CreateSeriesOpts & { yAxisSettings?: any } & {
  getSelectedAxisStartEnd?: any;
}) => {
  if (!ref.current) return;

  const { root, chart } = ref.current;

  for (let index = 0; index < numberOfSignals; index++) {
    // === Y Axis ===

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          //Commented since we have the ChartLayoutModal;
          // Uncommented, to try a better with scrolling.
          pan: "zoom",
          stroke: am5.color(chartSymbols.colors[index]),
          strokeOpacity: 1,
          opacity: 1,
        }),
        maxDeviation: 1,
        autoZoom: false,
        marginRight: 0,
        ...yAxisSettings,
      })
    );

    //Commented since we have the ChartLayoutModal;
    // Uncommented, to try a better with scrolling.
    yAxis.events.on("wheel", (ev) => yAxisWheel(ev, yAxis));
    //

    yAxis
      .get("renderer")
      .labels.template.set("fill", am5.color(chartSymbols.colors[index]));
    yAxis.get("renderer").labels.template.set("fontSize", 10);

    //Since we have the ChartLayoutModal we no longed need to sync the axes;
    // Uncommented, to try a better with scrolling.

    if (index > 0) {
      const refYAxis = chart.yAxes.getIndex(0);
      if (refYAxis && yAxisSettings.strictMinMax !== true) {
        yAxis.set(
          "syncWithAxis",
          refYAxis as am5xy.ValueAxis<am5xy.AxisRenderer>
        );
      }
    }
    //

    const container = am5.Container.new(root, {
      ariaLabel: "yAxisLabelContainer",
      layout: root.verticalLayout,
      height: am5.percent(100),
      centerX: am5.p100,
    });

    yAxis.children.unshift(container);

    container.children.unshift(
      am5.Label.new(ref.current.root, {
        ariaLabel: "yAxisLabel",
        rotation: -90,
        y: am5.p50,
        centerX: am5.p50,
        width: am5.percent(100),
        text: `(${signals[index]?.unit || "-"})`,
        fill: am5.color(chartSymbols.colors[index]),
        fontSize: 10,
        marginRight: 0,
        marginBottom: 0,
      })
    );

    container.children.unshift(
      am5.Picture.new(root, {
        ariaLabel: "y-axis-icon",
        width: 16,
        height: 16,
        x: 5,
        src: `${chartSymbols.svgs[index]}`,
      })
    );

    // === Series ===

    const series = ref.current.chart.series.push(
      am5xy.LineSeries.new(ref.current.root, {
        name: signalFormatter(signals[index]),
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: `value_${index}`,
        valueXField: `date_${index}`,
        stroke: am5.color(chartSymbols.colors[index]),
        fill: am5.color(chartSymbols.colors[index]),
      })
    );

    series.data.setAll(data);

    let start = 0;
    let end = 1;

    if (getSelectedAxisStartEnd) {
      const layoutStoreAxisSettings = getSelectedAxisStartEnd(index);

      start = layoutStoreAxisSettings?.start || 0;
      end = layoutStoreAxisSettings?.end || 1;
    }

    yAxis.zoom(start, end);
  }
};

export const getDataLastTimestamp = (lastDataPoint: any = {}) => {
  if (isEmpty(lastDataPoint)) return null;

  let biggestTimestamp;

  for (const property in lastDataPoint) {
    if (property.includes("date") && lastDataPoint[property]) {
      biggestTimestamp = biggestTimestamp || lastDataPoint[property];
      if (new Date(biggestTimestamp) < new Date(lastDataPoint[property])) {
        biggestTimestamp = lastDataPoint[property];
      }
    }
  }

  return biggestTimestamp;
};

export const getDataFirstTimestamp = (firstDataPoint: any = {}) => {
  if (isEmpty(firstDataPoint)) return null;

  let smallestTimestamp;

  for (const property in firstDataPoint) {
    if (property.includes("date") && firstDataPoint[property]) {
      smallestTimestamp = smallestTimestamp || firstDataPoint[property];
      if (new Date(smallestTimestamp) > new Date(firstDataPoint[property])) {
        smallestTimestamp = firstDataPoint[property];
      }
    }
  }

  return smallestTimestamp;
};
