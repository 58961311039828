import { Fragment, useEffect } from "react";

import { useSelectedSignalsStore } from "../../../../modules/analysis-raw-data";
import useSpectrumStore from "../../../../modules/analysis-raw-data/components/SpectrumChart/useSpectrumStore";
import SpectrumChartSection from "../../../../modules/analysis-raw-data/components/SpectrumChartSection";
import WaveformChartSection from "../../../../modules/analysis-raw-data/components/WaveformChartSection";
import useDataPoints from "../../../../modules/analysis-raw-data/hooks/useDataPoints";
import { NoData } from "../../../common/NoData";

const WaveformLayout = () => {
  const selectedSignals = useSelectedSignalsStore(
    (store) => store.selectedSignals
  );
  const getDataPoints = useDataPoints();

  const { resetSpectrumStore } = useSpectrumStore((state) => ({
    resetSpectrumStore: state.resetSpectrumStore,
  }));

  useEffect(() => {
    return () => resetSpectrumStore();
  }, []);

  return (
    <>
      {selectedSignals.map((selectedSignal) => (
        <div key={selectedSignal.id}>
          {getDataPoints(selectedSignal.id).length === 0 && (
            <NoData
              text="There is no data"
              styles={{
                root: {
                  width: "100%",
                  height: 500,
                },
              }}
            />
          )}

          {getDataPoints(selectedSignal.id).map((dataPoint) => (
            <Fragment key={`${selectedSignal.id}-${dataPoint}`}>
              <WaveformChartSection
                signal={selectedSignal}
                dataPoint={dataPoint}
              />
              <SpectrumChartSection
                signal={selectedSignal}
                dataPoint={dataPoint}
              />
            </Fragment>
          ))}
        </div>
      ))}
    </>
  );
};

export default WaveformLayout;
