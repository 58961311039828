export const COLOR_DARK_BLUE = '#2C529F';
export const COLOR_ORANGE = '#FF7E0D';
export const COLOR_YELLOW = '#FFCC00';
export const COLOR_GREEN = '#66CD7D';
export const COLOR_DARK_GREEN = '#4A9D7F';
export const COLOR_RED = '#F63F4D';

export const STATUSES_COLOR: any = {
  Current: COLOR_DARK_BLUE,
  Best: COLOR_GREEN,
  Worst: COLOR_ORANGE,
  Performance: COLOR_DARK_BLUE,
};

export const PERFORMANCE_SPECTRUM = [
  COLOR_RED,
  COLOR_ORANGE,
  COLOR_YELLOW,
  COLOR_GREEN,
  COLOR_DARK_GREEN,
];
