/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable-next-line jsx-a11y/click-events-have-key-events

import type { IButtonStyles, ITag } from "@fluentui/react";
import { IconButton } from "@fluentui/react";
import classNames from "classnames";
import React, { useState } from "react";

import { useLocationSearch } from "../../../../../Hooks";
import { cancelIcon } from "../../../../../modules/analysis-raw-data/constants/icons";
import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import { useCommentsQuery } from "../../hooks/useCommentsQuery";
import { useTagsQuery } from "../../hooks/useTagsQuery";
import { theme } from "../../../../../theme";

type TagProps = {
  name: string;
  id: string;
  hasDelete?: boolean;
  onTagClick?: (tag: ITag) => void;
};

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme?.palette?.neutralPrimary,
    marginLeft: "auto",
    height: "initial",
    width: "initial",
    padding: 0,
    // marginTop: '4px',
    // marginRight: '2px',
  },
  icon: {
    fontSize: "10px",
    margin: "0 0 0 10px",
    color: "#605E5C",
  },
  rootHovered: {
    cursor: "pointer",
    backgroundColor: "transparent",
    color: theme?.palette?.neutralPrimary,
  },
};

export const Tag = ({ name, id, hasDelete, onTagClick }: TagProps) => {
  const [{ id: machineId }, ,] = useLocationSearch();

  const [isDeleteTagDialogHidden, setIsDeleteTagDialogHidden] = useState(true);
  const toggleIsDeleteTagDialogHidden = () =>
    setIsDeleteTagDialogHidden(!isDeleteTagDialogHidden);
  const { refetch } = useTagsQuery({ machineId: machineId as string });
  const { refetch: refetchComments } = useCommentsQuery({
    machineId: machineId as string,
  });

  const onTagNameClick = () => {
    if (!onTagClick) {
      return;
    }

    onTagClick({ key: name, name });
  };

  return (
    <div className="tag-container">
      <span
        className={classNames("tag", {
          pointer: onTagClick,
        })}
        onClick={onTagNameClick}
      >
        {name}
      </span>
      {hasDelete && (
        <>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            // ariaLabel='Close popup modal'
            onClick={toggleIsDeleteTagDialogHidden}
          />
          <DeleteDialog
            dialogContentProps={{
              onDismiss: toggleIsDeleteTagDialogHidden,
            }}
            data={{ ids: [id as string], name }}
            hidden={isDeleteTagDialogHidden}
            route={"meta/machinecv/v1/tags"}
            onSuccess={() => {
              refetch();
              refetchComments();
            }}
          />
        </>
      )}
    </div>
  );
};
