import { useQuery } from '@tanstack/react-query';
import { forEach } from 'lodash-es';

import { WS_SIDEBAR_QUERY_KEY } from './useWSSidebarData';

export const useSensorDetails = ({ sensorNodeId }: any) => {
  const { data, isLoading }: any = useQuery([WS_SIDEBAR_QUERY_KEY]);
  const result: any = {
    isLoading,
  };

  forEach(data?.corporations, (corporation) => {
    forEach(corporation?.companies, (company) => {
      forEach(company?.projects, (project) => {
        forEach(project?.machines, (machine) => {
          forEach(machine?.sensorNodes, (sensorNode) => {
            if (sensorNode?.sensorNodeId === sensorNodeId) {
              result.sensor = {
                serialNumber: sensorNode.wirelessGatewaySerialNumber,
                ...sensorNode,
                corporation,
                company,
                project,
                machine,
              };
            }
          });
        });
      });
    });
  });

  return result;
};
