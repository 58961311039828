import type { SVGProps } from 'react';
import React from 'react';

const DashboardIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0.5 3C0.5 2.20435 0.816071 1.44129 1.37868 0.87868C1.94129 0.316071 2.70435 0 3.5 0H11.5C12.2957 0 13.0587 0.316071 13.6213 0.87868C14.1839 1.44129 14.5 2.20435 14.5 3V11C14.5 11.7957 14.1839 12.5587 13.6213 13.1213C13.0587 13.6839 12.2957 14 11.5 14H3.5C2.70435 14 1.94129 13.6839 1.37868 13.1213C0.816071 12.5587 0.5 11.7957 0.5 11V3ZM3.5 1C2.96957 1 2.46086 1.21071 2.08579 1.58579C1.71071 1.96086 1.5 2.46957 1.5 3V4.058L7 4.005V1H3.5ZM8 1V9H13.5V3C13.5 2.46957 13.2893 1.96086 12.9142 1.58579C12.5391 1.21071 12.0304 1 11.5 1H8ZM13.5 10H8V13H11.5C12.0304 13 12.5391 12.7893 12.9142 12.4142C13.2893 12.0391 13.5 11.5304 13.5 11V10ZM7 13V5.005L1.5 5.058V11C1.5 11.5304 1.71071 12.0391 2.08579 12.4142C2.46086 12.7893 2.96957 13 3.5 13H7Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default DashboardIcon;
