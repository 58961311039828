import {
  DefaultButton,
  DialogFooter,
  DialogType,
  IDialogProps,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { CSSProperties, useState } from "react";
import BaseDialog, { DialogSize } from "../common/Dialog";

const textStyle: CSSProperties = {
  fontSize: 14,
  lineHeight: "20px",
  marginBottom: 16,
};

type SimpleDeleteProps = IDialogProps & {
  show: boolean;
  handleOk: () => void;
  onClose: () => void;
  text: string;
};

export const SimpleDeleteConfirm = ({
  show,
  handleOk,
  onClose,
  text,
  ...rest
}: SimpleDeleteProps) => {
  const [isLoading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    handleOk();
    handleClose();
  };

  const handleClose = () => {
    setLoading(false);
    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        title: "Attention!",
        type: DialogType.close,
        onDismiss: handleClose,
      }}
      size={DialogSize.M}
    >
      <div style={textStyle}>You are about to delete {text}</div>
      <DialogFooter>
        <PrimaryButton
          text="Delete"
          disabled={isLoading}
          onClick={handleDelete}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton text="Close" onClick={handleClose} />
      </DialogFooter>
    </BaseDialog>
  );
};
