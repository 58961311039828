import { Breadcrumb, IconButton, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import * as ReactRouterDOM from "react-router-dom";

import { getProjectElementId } from "../../../../Components/MachineCV/MachineCVHomePage/selectors";

import BreadcrumbItemMachine from "./BreadcrumbItemMachine";
import BreadcrumbItemProject from "./BreadcrumbItemProject";

const MachineCVBreadcrumb = ({ data, width }: { width: number; data: any }) => {
  const navigate = ReactRouterDOM.useNavigate();
  const { corporation, company, project, machine } = data;
  const [selectedProject, setSelectedProject] = useState(project);
  useEffect(() => {
    setSelectedProject(project);
  }, [data]);
  const state = getProjectElementId({
    corporation: corporation.name,
    company: company.name,
    project: selectedProject.name,
  });
  const goBack = () => navigate("/machine-cv", { state });

  const breadcrumbItems = [
    { key: `corporation-${corporation?.name}`, text: corporation?.name },
    { key: `company-${company?.name}`, text: company?.name },
    {
      key: `selectedProject-${selectedProject?.name}`,
      text: selectedProject?.name,
      onRenderContent: () => (
        <BreadcrumbItemProject
          companyId={company?.id}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      ),
    },
    {
      key: `machine-${machine?.name}`,
      text: machine?.name,
      onRenderContent: () => (
        <BreadcrumbItemMachine
          selectedProject={selectedProject}
          machine={machine}
        />
      ),
    },
  ];

  return (
    <Stack
      horizontal
      verticalAlign="center"
      style={{ width: `calc(100% - ${width}px - 32px)` }}
    >
      <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
      <div style={{ width: "100%", display: "flex" }}>
        <Breadcrumb
          items={breadcrumbItems}
          styles={{
            root: { margin: 0, width: "100%" },
            item: { fontSize: "14px" },
            chevron: { fontSize: "10px" },
          }}
        />
      </div>
    </Stack>
  );
};

export default MachineCVBreadcrumb;
