/* eslint-disable @typescript-eslint/ban-ts-comment */
import { saveAs } from 'file-saver';
import * as xlsx from 'xlsx';

import { format } from '../../../utils';

import type { TableProps } from './Table';

export type Type = Extract<xlsx.BookType, 'csv' | 'xlsx'>;

const config: Record<Type, { mimeType: string; extension: string }> = {
  csv: {
    mimeType: 'text/csv',
    extension: '.csv',
  },
  xlsx: {
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    extension: '.xlsx',
  },
};

const WRAP_PADDING = 3;

type GetOpts =
  | { success: false }
  | {
      success: true;
      data: unknown[];
      header: string[];
      wrap: xlsx.ColInfo[];
    };

const getOpts = (data: unknown[], columns: TableProps['columns']): GetOpts => {
  const filterColumns = columns.filter(
    ({ isExportable = true }: TableProps['columns'][0]) => isExportable,
  );

  const innerColumns = filterColumns.reduce((map, { key, name }) => ({ ...map, [key]: name }), {});

  if (Object.keys(innerColumns).length === 0) {
    return { success: false };
  }

  const innerWrap = filterColumns.reduce(
    (prev, { name }) => ({ ...prev, [name]: { wch: `${name}`.length + WRAP_PADDING } }),
    {},
  );

  const innerData = data.map((item) =>
    Object.entries(item as any).reduce((prev, [key, value]) => {
      // @ts-ignore
      if (innerColumns[key]) {
        // @ts-ignore
        const currentKey = innerColumns[key];
        // @ts-ignore
        innerWrap[currentKey].wch = Math.max(
          // @ts-ignore
          innerWrap[currentKey].wch,
          `${value}`.length + WRAP_PADDING,
        );
        return { ...prev, [`${currentKey}`]: value };
      }
      return prev;
    }, {}),
  );

  return {
    success: true,
    data: innerData,
    header: filterColumns.map(({ name }) => name),
    wrap: Object.values(innerWrap),
  };
};

type ExportOpts = {
  type: Type;
  filename: string;
  data: unknown[];
  columns: TableProps['columns'];
};

export const save = ({ type, filename, data, columns }: ExportOpts) => {
  const { mimeType, extension } = config[type];

  const opts = getOpts(data, columns);

  if (!opts.success) {
    return;
  }

  const workSheet: xlsx.WorkSheet = xlsx.utils.json_to_sheet(opts.data, {
    header: opts.header,
  });

  workSheet['!cols'] = opts.wrap;

  const workBook: xlsx.WorkBook = {
    Sheets: {
      data: workSheet,
    },
    SheetNames: ['data'],
  };

  const file = xlsx.write(workBook, { bookType: type, type: 'array' });

  saveAs(
    new Blob([file], { type: mimeType }),
    `DALOG_${format(new Date())}_${filename}${extension}`,
  );
};
