/* eslint-disable jsx-a11y/click-events-have-key-events */
import type {
  IButtonProps,
  IRenderFunction,
  ITooltipHostStyles,
} from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  IconButton,
  Spinner,
  TooltipHost,
} from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";

import { useCallback, useId, useMemo } from "react";

import CheckboxEmptyIcon from "../../../../../assets/svg/CheckboxEmptyIcon";
import CheckboxFullIcon from "../../../../../assets/svg/CheckboxFullIcon";
import ClearIcon from "../../../../../assets/svg/ClearIcon";
import FastTrendSignalIcon from "../../../../../assets/svg/FastTrendSignalIcon";
import NoDataIcon from "../../../../../assets/svg/NoDataIcon";
import RawDataSignalIcon from "../../../../../assets/svg/RawDataSignalIcon";
import SearchIcon from "../../../../../assets/svg/SearchIcon";
import TrendSignalIcon from "../../../../../assets/svg/TrendSignalIcon";
import useLayoutStore from "../../../hooks/useLayoutStore";
import useLayoutTypes from "../../../hooks/useLayoutTypes";
import useSelectedDataPointsStore from "../../../hooks/useSelectedDataPointsStore";
import useSelectedSignalsStore from "../../../hooks/useSelectedSignalsStore";
import type { CustomSelectNodeProps } from "../../../types";
import SignalsAutocomplete from "../../../../common/components/SignalsAutocomplete";
import DataTypeEnum from "../../../../common/constants/DataTypeEnum";
import { formatNode } from "../../../../common/utils/signalFormatter";
import BaseDialog, {
  DialogSize,
} from "../../../../../Components/common/Dialog";
import type { ResponseSimplifiedSignal } from "../../../../../types";

import useCustomSelectNode from "./useCustomSelectNode";

const ClearAllIcon = () => <ClearIcon />;

const calloutProps = { gapSpace: 0 };

const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

// Fix for the tooltip not showing on the node label for safari.
const labelStyles: React.CSSProperties = {
  visibility: "visible",
};

const eraseButtonStyles = {
  root: {
    color: "#000000",
    padding: 0,
    margin: 0,
  },

  rootHovered: {
    background: "transparent",
  },

  rootDisabled: {
    background: "transparent",
  },
};

const CustomSelectNode: React.FC<CustomSelectNodeProps> = ({
  node,
  onToggle,
  onSelect,
  handlers,
  ...props
}) => {
  const {
    showEmptyIcon,
    showFullIcon,
    showCloseIcon,
    showOpenIcon,
    handleSelect,
    handleToggle,
    handleItemClick,
    handleItemSelect,
    isPopupVisible,
    hidePopup,
  } = useCustomSelectNode({ handlers, node, onToggle, onSelect, ...props });

  const { removeSelectedSignal } = useSelectedSignalsStore((store) => ({
    removeSelectedSignal: store.removeSelectedSignal,
  }));

  const tooltipId = useId();
  const layout = useLayoutStore((store) => store.layout);
  const { isTrendLayout } = useLayoutTypes();
  const removeSelectedDataPoint = useSelectedDataPointsStore(
    (store) => store.removeSelectedDataPoint
  );
  const clearSelectedSignals = useCallback(() => {
    node.getChildren().forEach((child) => {
      handlers.setSelected(child.id, false);
      removeSelectedSignal(child as unknown as ResponseSimplifiedSignal);
      removeSelectedDataPoint(child.getData().id);
      if (child?.getChildren) {
        const subChildren = child.getChildren() || [];
        subChildren.forEach((subChild) => {
          handlers.setSelected(subChild.id, false);
          removeSelectedSignal(subChild as unknown as ResponseSimplifiedSignal);
          removeSelectedDataPoint(subChild.getData().id);
        });
      }
    });
  }, [node, handlers, removeSelectedSignal, removeSelectedDataPoint]);

  const getNodeOptions = useCallback(() => {
    let options: any = [];
    const children = node?.getChildren ? node.getChildren() : [];
    children.forEach((item) => {
      const subChildren = item?.getChildren ? item?.getChildren() || [] : [];
      if (subChildren.length > 0) {
        options = [...options, ...subChildren];
      } else {
        options = [...options, item];
      }
    });
    return options
      .filter(
        (child: any) =>
          isTrendLayout ||
          (child.getData().dataType === "ShortTerm" &&
            !child.data.groupedNodeParent)
      )
      .map((child: any) => ({
        ...child,
        id: child.getData().id,
        name: child.getData().name,
        label: formatNode(child),
        icon: child.getData().dataType,
      }));
  }, [node]);

  const checkClearCheckboxAvailable = useCallback(() => {
    return !!(
      node?.getChildren &&
      node.getChildren().find((child) => {
        if (child.isSelected()) {
          return true;
        }
        if (child?.getChildren && child.getChildren().length > 0) {
          return child.getChildren().find((subChild) => {
            return subChild?.isSelected && subChild?.isSelected();
          });
        }
        return false;
      })
    );
  }, [node]);

  const renderName = useCallback(() => {
    const label = formatNode(node);
    if (!node.options.leaf) {
      return <span>{label}</span>;
    }

    return (
      <TooltipHost
        id={tooltipId}
        content={label}
        calloutProps={calloutProps}
        styles={hostStyles}
      >
        <span style={labelStyles}>{label}</span>
      </TooltipHost>
    );
  }, [node, tooltipId]);

  // groupedNodeParent
  const isNodeVisible = useMemo(() => {
    let isVisible = true;
    if (
      (layout?.key !== "trend-waveform-spectrum" &&
        node.options.leaf &&
        node.data.dataType !== "ShortTerm") ||
      node.data.dataType === "FastTrend"
    ) {
      isVisible = false;
    }
    if (
      layout?.key !== "trend-waveform-spectrum" &&
      node.data.groupedNodeParent &&
      node.data.children.filter(
        (item: any) =>
          item.dataType === "Trend" || item.dataType === "FastTrend"
      ).length === node.data.children.length
    ) {
      isVisible = false;
    }
    return isVisible;
  }, [layout, node]);

  return (
    <div
      data-node-id={node.id}
      style={{
        display: isNodeVisible ? "block" : "none",
      }}
    >
      <BaseDialog
        hidden={!isPopupVisible}
        dialogContentProps={{ title: "Attention!", type: DialogType.close }}
        size={DialogSize.S}
        styles={{
          main: {
            minHeight: "auto",
            selectors: {
              ".ms-Dialog-title": {
                paddingTop: 18,
                paddingBottom: 10,
              },
            },
          },
        }}
        onDismiss={hidePopup}
      >
        To select another signal, you need to deselect a data point first.
        <DialogFooter>
          <DefaultButton text="Close" onClick={hidePopup} />
        </DialogFooter>
      </BaseDialog>
      <div
        key={node.data.title}
        className="tree-node raw-data-signals-sidebar"
        role="button"
        tabIndex={0}
        style={{ cursor: "pointer" }}
        onClick={handleToggle}
      >
        {showFullIcon && (
          <DefaultButton style={styles.nodeButton} onClick={handleSelect}>
            <CheckboxFullIcon />
          </DefaultButton>
        )}
        {showEmptyIcon && (
          <DefaultButton style={styles.nodeButton} onClick={handleSelect}>
            <CheckboxEmptyIcon />
          </DefaultButton>
        )}

        <div className="node-content-wrapper">
          <div
            className="titles"
            role="button"
            tabIndex={0}
            onClick={handleItemClick}
          >
            {node.getData().dataType === DataTypeEnum.Trend && (
              <TrendSignalIcon style={{ marginRight: 10, minWidth: 17 }} />
            )}
            {node.getData().dataType === DataTypeEnum.FastTrend && (
              <FastTrendSignalIcon style={{ marginRight: 10, minWidth: 17 }} />
            )}
            {node.getData().dataType === DataTypeEnum.RawData && (
              <RawDataSignalIcon style={{ marginRight: 10, minWidth: 17 }} />
            )}
            <div className="node-title">{renderName()}</div>
          </div>
          {node.isLoading() && <Spinner />}
          {node.asyncNode &&
            node.asyncDataLoaded &&
            (node.getChildren().length > 0 ? (
              <div>
                <IconButton
                  title="Clear Selected Signals"
                  ariaLabel="Clear Signals"
                  style={{
                    marginRight: 4,
                    filter: checkClearCheckboxAvailable()
                      ? "none"
                      : "grayscale(1)",
                    opacity: checkClearCheckboxAvailable() ? 1 : 0.7,
                  }}
                  styles={eraseButtonStyles}
                  disabled={!checkClearCheckboxAvailable()}
                  onRenderIcon={ClearAllIcon as IRenderFunction<IButtonProps>}
                  onClick={(e) => {
                    e.stopPropagation();
                    clearSelectedSignals();
                  }}
                />
                <SignalsAutocomplete
                  items={getNodeOptions()}
                  selectedItems={(getNodeOptions() || []).filter(
                    (item: any) => item.options.selected
                  )}
                  onSelectSignal={(signal) => {
                    if (signal) {
                      handleItemSelect(signal.id);
                    }
                  }}
                  onRenderMenuIcon={() => <SearchIcon />}
                />
              </div>
            ) : (
              <TooltipHost
                id={tooltipId}
                content={"No data"}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <DefaultButton disabled style={styles.tooltipHost}>
                  <NoDataIcon />
                </DefaultButton>
              </TooltipHost>
            ))}
        </div>

        {showCloseIcon && (
          <DefaultButton style={styles.nodeButton} onClick={handleToggle}>
            <Icon iconName="ChevronUp" />
          </DefaultButton>
        )}
        {showOpenIcon && (
          <DefaultButton style={styles.nodeButton} onClick={handleToggle}>
            <Icon iconName="ChevronDown" />
          </DefaultButton>
        )}
      </div>
    </div>
  );
};

const styles = {
  nodeButton: {
    border: "unset",
    minWidth: "30px",
    padding: "0px",
    background: "transparent",
  },
  tooltipHost: {
    border: "unset",
    minWidth: "30px",
    padding: "0px",
    background: "transparent",
  },
};

export default CustomSelectNode;
