import type { SVGProps } from 'react';
import React from 'react';

const MachineCvSensors: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      fill='none'
      viewBox='0 0 21 20'
      {...props}
    >
      <path
        fill='#2C529F'
        d='M17.61 7.384c.41.409.796.877 1.133 1.365a.5.5 0 01-.823.568A8.613 8.613 0 004.724 8.091c-.348.348-.693.77-1.007 1.222a.5.5 0 01-.821-.57 10 10 0 011.12-1.36 9.612 9.612 0 0113.594.001zm-2.236 1.973a6.64 6.64 0 011.194 1.656.5.5 0 01-.89.455 5.645 5.645 0 00-1.01-1.404 5.45 5.45 0 00-8.716 1.39.5.5 0 11-.892-.452 6.45 6.45 0 0110.315-1.645h-.001zm-1.71 2.497c.356.354.648.79.85 1.254a.5.5 0 01-.915.401 3.136 3.136 0 00-.64-.948 3.035 3.035 0 00-4.29 0c-.267.27-.481.589-.631.938a.5.5 0 11-.916-.401c.2-.463.485-.885.84-1.244a4.033 4.033 0 015.703 0zm-1.923 1.933a1.3 1.3 0 010 1.836 1.297 1.297 0 110-1.836z'
      />
    </svg>
  );
};

export default MachineCvSensors;
