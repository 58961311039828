import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";


export interface SidebarStore {
  isExpanded: boolean;
  toggle: () => void;
}

const useSidebarStore = createWithEqualityFn<SidebarStore>((set) => ({
  isExpanded: true,
  toggle: () => set((store) => ({ isExpanded: !store.isExpanded })),
}), shallow);

export default useSidebarStore;
