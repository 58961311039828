export const getWorstBestCurrentPerformance = (performanceSignalData: object[] = []) => {
  let bestPerformance: any;
  let worstPerformance: any;
  let currentPerformance: any;

  performanceSignalData?.forEach((item: any) => {
    bestPerformance = bestPerformance || item;
    bestPerformance = item.value > bestPerformance.value ? item : bestPerformance;

    worstPerformance = worstPerformance || item;
    worstPerformance = item.value < worstPerformance.value ? item : worstPerformance;

    currentPerformance = currentPerformance || item;
    currentPerformance = item.timeStamp > currentPerformance.timeStamp ? item : currentPerformance;
  });

  return {
    currentPerformance,
    bestPerformance,
    worstPerformance,
  };
};
