import { useMutation } from "@tanstack/react-query";

import {
  getApiClient,
  validateStatus,
} from "../../../../modules/core/apiClient/useApiStore";
import type { MachineCVComment } from "../../../../types";

export type CommentAddProps = {
  machineId: string;
  data: Partial<MachineCVComment>;
};

export const useCommentAdd = () => {
  const { mutateAsync } = useMutation(({ machineId, data }: CommentAddProps) =>
    getApiClient().post(
      "meta/machinecv/v1/comments",
      {
        machineId,
        ...data,
      },
      { validateStatus }
    )
  );

  return { addCommentAsync: mutateAsync };
};

export type CommentUpdateProps = {
  id: string;
  data: Partial<MachineCVComment>;
};

export const useCommentUpdate = () => {
  const { mutateAsync } = useMutation(({ id, data }: CommentUpdateProps) =>
    getApiClient().put(`meta/machinecv/v1/comments/${id}`, data, {
      validateStatus,
    })
  );

  return { updateCommentAsync: mutateAsync };
};
