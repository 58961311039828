/* eslint-disable @typescript-eslint/no-explicit-any */
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import type { IStackStyles } from "@fluentui/react";
import { isEmpty } from "lodash-es";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMeasure } from "react-use";

import DalogLogo from "../../../../../../assets/images/dalog-logo.svg";
import { createRange } from "../../../../../../modules/analysis-raw-data/components/TrendChart/TrendChart";
import { watermarkLogos } from "../../../../../../modules/analysis-raw-data/constants/chartWatermarkLogos";
import useChartLabels from "../../../../../../modules/analysis-raw-data/hooks/useChartLabels";
import useLayoutSettingsStore from "../../../../../../modules/analysis-trend-view/components/ChartLayoutModal/useLayoutSettingsStore";
import type { MopSignalData } from "../../../config";
import type { DataPoint } from "../../../../../../types";
import { Spin } from "../../../../../common/Spin";
import { type XYChartRef, XYChart } from "../../../../../common/XYChart";
import { createGroupedTooltip } from "../../../../../common/XYChart/tooltip";

import { setLegendMarkers, updateSeries } from "../methods";
import type { BaseChartProps } from "../types";

type SignalsTrendChartProps = BaseChartProps & {
  data: Map<string, MopSignalData<DataPoint>>;
  selectedTableEvent?: any;
  onDataValidated?: () => void;
};

const spinStyles: IStackStyles = {
  root: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    background: "rgb(255, 255, 255)",
    zIndex: 2,
  },
};

const SignalsTrendChart = React.forwardRef<XYChartRef, SignalsTrendChartProps>(
  (
    {
      data,
      refresh,
      onDrawFinished,
      selectedTableEvent,
      onDataValidated,
      styles,
      ...rest
    }: SignalsTrendChartProps,
    ref: any
  ) => {
    const [contentRef] = useMeasure<HTMLDivElement>();
    const shouldShowChartLabels = useChartLabels();
    const rootRef = useRef<am5.Root | null>(null);
    const chartRef = useRef<am5xy.XYChart | null>(null);
    const xAxisRef = useRef<am5xy.DateAxis<am5xy.AxisRenderer> | null>(null);
    const legendRef = useRef<am5.Legend | null>(null);
    const [isDrawing, setIsDrawing] = useState<boolean>(false);

    const { resetSettings, syncSettingsMode, updateStore } =
      useLayoutSettingsStore((store: any) => ({
        resetSettings: store.resetSettings,
        syncSettingsMode: store.syncSettingsMode,
        updateStore: store.updateStore,
      }));

    const signalsMinMaxValues = useMemo(() => {
      let min = 0;
      let max = 100;
      if (syncSettingsMode !== "syncAll") {
        return { min, max };
      }
      for (const [, signalData] of data) {
        if (signalData.data) {
          for (const point of signalData.data) {
            const value = parseFloat(point.value);
            if (!isNaN(value)) {
              if (value < min) {
                min = value;
              }
              if (value > max) {
                max = value;
              }
            }
          }
        }
      }

      return {
        min,
        max,
      };
    }, [data, syncSettingsMode]);

    const updateYAxesSettings = (chart: am5xy.XYChart, settings: any) => {
      if (!chart) return;

      chart.yAxes.each((yAxis: any) => {
        if (settings.strictMinMax) {
          yAxis.set("min", settings.min);
          yAxis.set("max", settings.max);
        } else {
          yAxis.set("min", undefined);
          yAxis.set("max", undefined);
        }
        yAxis.zoom(0, 1);
      });
    };

    // Instantiates the chart.
    useEffect(() => {
      if (!ref?.current) return;

      const root = ref.current.root;
      const chart = ref.current.chart;

      // === Padding ===
      chart.set("paddingRight", 10);

      // === Sending grid lines behind the chart series lines ===
      chart.gridContainer.toBack();

      // === Setting 0 base grid line opacity ===
      const myTheme = am5.Theme.new(root);

      myTheme.rule("Grid", ["base"]).setAll({
        strokeOpacity: 0.1,
      });

      root.setThemes([myTheme]);

      // === X Axis ===
      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: "second", count: 1 },
          renderer: am5xy.AxisRendererX.new(ref.current.root, {}),
          maxDeviation: 0,
          marginTop: 16,
        })
      );

      // === X Axis grid opacity ===
      const xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.setAll({
        strokeOpacity: 0.01,
      });
      xRenderer.labels.template.set("fontSize", 10);

      // === Legend ===
      const legend: am5.Legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(0),
          x: 0,
          paddingTop: 15,
          paddingBottom: 5,
          layout: root.gridLayout,
        })
      );

      // === Lower marker opacity when disabled

      legend.markers.template.setup = (marker) => {
        marker.states.create("disabled", {
          opacity: 0.5,
        });
      };

      // === Legend marker label font size

      legend.labels.template.setAll({
        fontSize: 12,
      });

      // === Remove default legend marker

      legend.markerRectangles.template.setAll({
        visible: false,
        width: 0,
        height: 0,
      });

      // === Footer Label ===

      watermarkLogos.forEach((logoData) => {
        const logo = am5.Picture.new(root, {
          src: DalogLogo,
          height: logoData.height,
          opacity: logoData.opacity,
          x: logoData.x,
          y: logoData.y,
          centerX: logoData.centerX,
          centerY: logoData.centerY,
        });

        chart.plotContainer.children.push(logo);
      });

      createGroupedTooltip({
        ref: ref.current,
        type: "date",
      });

      rootRef.current = root;
      chartRef.current = chart;
      xAxisRef.current = xAxis;
      legendRef.current = legend;

      function openChartLayout(e: { preventDefault: () => void }) {
        e.preventDefault();
        updateStore({ isChartLayoutOpen: true });
      }

      ref.current.root.dom.addEventListener("contextmenu", openChartLayout);

      return () => {
        ref?.current?.root?.dom?.removeEventListener(
          "contextmenu",
          openChartLayout
        );
        ref.current = null;
      };
    }, []);

    // Starts refreshing the data.
    useEffect(() => {
      if (!refresh || !chartRef?.current || !xAxisRef?.current) {
        return;
      }

      // Updates the data.
      chartRef.current.hide(1000).then(() => setIsDrawing(true));
    }, [refresh]);

    // Updates the data.
    useEffect(() => {
      if (
        !isDrawing ||
        !rootRef?.current ||
        !chartRef?.current ||
        !legendRef?.current ||
        !xAxisRef?.current
      ) {
        return;
      }

      // Event handler
      legendRef.current.events.once("datavalidated", () => {
        if (
          !rootRef?.current ||
          !chartRef?.current ||
          !legendRef?.current ||
          !xAxisRef?.current
        ) {
          return;
        }

        setLegendMarkers(rootRef.current, legendRef.current);
        chartRef?.current?.show(1000);
        onDataValidated?.();
      });

      const yAxisSettings =
        syncSettingsMode === "syncAll"
          ? { ...signalsMinMaxValues, strictMinMax: true }
          : {};
      // Updates the series.
      updateSeries(
        rootRef.current,
        chartRef.current,
        xAxisRef.current,
        legendRef.current,
        data,
        "timeStamp",
        "value",
        yAxisSettings
      ).then((series) => {
        legendRef?.current?.data.setAll(series);
        setIsDrawing(false);
        onDrawFinished?.();
      });

      // Syncs the settings.
      ref.current.chart.zoomOutButton.events.on("click", () =>
        resetSettings("all")
      );

      const yAxes = chartRef.current?.yAxes;
      if (yAxes) {
        const lastIndex = yAxes.length - 1;
        yAxes.each((axis, index) => {
          const renderer = axis.get("renderer") as am5xy.AxisRendererY;
          if (index !== lastIndex) {
            renderer.grid.template.setAll({
              visible: false,
            });
          } else {
            renderer.grid.template.setAll({
              visible: true,
              strokeOpacity: 0.1,
            });
          }
        });
      }
    }, [isDrawing, data]);

    // New effect for updating Y axes settings
    useEffect(() => {
      if (!chartRef?.current) return;

      const yAxisSettings =
        syncSettingsMode === "syncAll"
          ? { ...signalsMinMaxValues, strictMinMax: true }
          : { strictMinMax: false };

      updateYAxesSettings(chartRef.current, yAxisSettings);
    }, [syncSettingsMode, signalsMinMaxValues]);

    // Draws the table event line.
    useEffect(() => {
      if (!xAxisRef?.current) return;

      if (!isEmpty(selectedTableEvent)) {
        createRange({
          value: new Date(selectedTableEvent.timeStamp).getTime(),
          color: am5.color(0x000000),
          root: ref.current.root,
          chart: ref.current.chart,
          xAxis: xAxisRef.current,
        });
      } else {
        if (xAxisRef.current.axisRanges.values.length > 0) {
          xAxisRef.current.axisRanges.removeIndex(0);
        }
      }
    }, [selectedTableEvent]);

    return (
      <div {...rest} ref={contentRef}>
        {isDrawing && <Spin text="Refreshing..." styles={spinStyles} />}
        <XYChart
          ref={ref}
          exportSelector={""}
          isMinimized={!shouldShowChartLabels}
          customSettings={{
            hideYscrollbar: true,
          }}
          styles={{
            root: {
              height: "100%",
              minHeight: "200px",
              ...styles?.root,
            },
            chart: {
              height: "100%",
              ...styles?.chart,
            },
          }}
        />
      </div>
    );
  }
);

SignalsTrendChart.displayName = "SignalsTrendChart";

export default SignalsTrendChart;
