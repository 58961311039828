import { Icon, Stack, Text, TooltipHost } from "@fluentui/react";
import { useFieldArray } from "react-hook-form";

import { TIMED_SETTINGS } from "../../../hooks/useSensorRequests";
import { measurementSettingOptions } from "./ManualTriggerSettings";
import Collapse from "../../../../common/Collapse";
import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import {
  FormItemType,
  renderFormFieldArrayItems,
} from "../../../../common/Form/FormItems/helpers";

const timeFormField: FormItemProps[] = [
  {
    name: "intervalInMinutes",
    type: FormItemType.TextField,
    description: "Time in minutes",
  },
];

const measurementFormField: FormItemProps[] = [
  {
    name: "enabledMeasurementSettings",
    placeholder: "Select measurements",
    type: FormItemType.Dropdown,
    multiSelect: true,
    options: measurementSettingOptions,
    styles: { root: { width: 300 } },
    dropdownWidth: 300,
  },
];

const toggleFormField: FormItemProps[] = [
  {
    name: "enabled",
    type: FormItemType.Toggle,
  },
];

const AutomaticTriggerSettings = ({ form }: any) => {
  const {
    formState: { errors },
    control,
  } = form;

  const { fields } = useFieldArray({
    control,
    name: "triggers",
  });

  return (
    <>
      <div className="tab-subtitle">Automatic Trigger Settings</div>
      {fields.map((field, index) => (
        <Collapse
          key={index}
          title={TIMED_SETTINGS[index]}
          isCollapsed={false}
          headerContent={
            <>
              {renderFormFieldArrayItems(
                toggleFormField,
                { control, errors },
                "triggers",
                index
              )}
            </>
          }
        >
          <div className="triggers-collapse-wrapper">
            <div className="triggers-input-container">
              {renderFormFieldArrayItems(
                timeFormField,
                { control, errors },
                "triggers",
                index
              )}
            </div>
            <div className="triggers-input-container">
              {renderFormFieldArrayItems(
                measurementFormField,
                { control, errors },
                "triggers",
                index
              )}
              <TooltipHost
                tooltipProps={{
                  maxWidth: "600px",
                  onRenderContent: () => (
                    <Stack>
                      <Stack>
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            marginBottom: 10,
                          }}
                        >
                          Measurement type
                        </Text>
                        <Text>
                          Measurement ID 0 = Trend data | Triaxial |
                          Acceleration and Vibration Velocity <br />
                          Measurement ID 1 = Raw data | Triaxial | Acceleration
                          | 36864 samples
                        </Text>
                      </Stack>
                    </Stack>
                  ),
                }}
                styles={{ root: { margin: "12px 0 0 10px" } }}
              >
                <Icon
                  iconName={"Info"}
                  styles={{ root: { fontSize: 16, color: "rgb(44, 82, 159)" } }}
                ></Icon>
              </TooltipHost>
            </div>
          </div>
        </Collapse>
      ))}
    </>
  );
};

export default AutomaticTriggerSettings;
