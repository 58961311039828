import { getApiClient } from "../../../core/apiClient/useApiStore";
import type { BreadcrumbInterface } from "../../../../types";

type GetMachineCVBreadcrumbOpts = {
  machineId: string;
};

export const breadcrumbDefaultResponse = {
  corporation: { name: "", id: "" },
  company: { name: "", id: "" },
  project: { name: "", id: "" },
  machine: { name: "", id: "" },
};

export const getMachineCVBreadcrumb = ({
  machineId,
}: GetMachineCVBreadcrumbOpts) =>
  getApiClient()
    .get<BreadcrumbInterface>(
      `/meta/machinecv/v1/parentInfos/machines/${machineId}`
    )
    .then(({ data }) => data || {})
    .catch(() => breadcrumbDefaultResponse);
