import type {
  IColumn,
  IDetailsList,
  IShimmeredDetailsListProps,
} from "@fluentui/react";
import {
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
} from "@fluentui/react";
import classnames from "classnames";
import orderBy from "lodash-es/orderBy";
import { useEffect, useMemo, useRef } from "react";

import { NoData } from "../NoData";
import Pagination from "../Pagination";
import { filter } from "./filter";
import Header from "./Header";
import { useColumns } from "./useColumns";
import { usePagination } from "./usePagination";
import { useSort } from "./useSort";

const EMPTY_LIST: never[] = [];

// === Types ===

export type Column = IColumn & {
  isSortable?: boolean;
  isExportable?: boolean;
};

export type Filter = {
  key: string;
  search: string;
  operator: "or" | "and";
};

type FiltersAppliedProps = {
  list: any;
  isFiltered: boolean;
};

export type TableProps = Omit<
  IShimmeredDetailsListProps,
  | "listProps"
  | "selectionMode"
  | "enableShimmer"
  | "shimmerLines"
  | "selectionPreservedOnEmptyClick"
  | "isSelectedOnFocus"
  | "columnReorderOptions"
  | "onColumnResize"
> & {
  header?: {
    title: string;
    isExportable?: boolean;
  };
  persistOpts: {
    key: string;
    version: number;
  };
  columns: Column[];
  perPage?: number;
  filters?: Filter[];
  isLoading?: boolean;
  isError?: boolean;
  resetPagination?: boolean;
  hasSelection?: boolean;
  hidePerPage?: boolean;
  onSelectionChanged?: (
    selected: ReturnType<Selection["getSelection"]>
  ) => void;
  onFiltersApplied?: (p: FiltersAppliedProps) => void;
};

const Table = ({
  items,
  persistOpts,
  filters,
  header: _header,
  isLoading = false,
  isError = false,
  perPage: _perPage,
  columns: _columns,
  resetPagination = false,
  hasSelection = true,
  className,
  onSelectionChanged,
  hidePerPage = false,
  onFiltersApplied,
  ...rest
}: TableProps) => {
  const ref = useRef<IDetailsList | null>(null);

  // === Selection ===

  const selection: Selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () =>
          onSelectionChanged?.(selection.getSelection()),
      }),
    []
  );

  // === Columns Options (Order & Resize)  ===

  const {
    columnsOpts,
    columnReorderOpts,
    onColumnResize,
    shouldOmitColumnsOpts,
  } = useColumns({
    columns: _columns,
    persistOpts,
  });

  // === Data ===

  const rawItems = items || EMPTY_LIST;

  // === Sort ===

  const { sorter, columns } = useSort({
    columns: _columns,
    columnsOpts,
    selection,
    shouldOmitColumnsOpts,
    persistOpts,
  });

  const sortedItems = useMemo(() => {
    if (sorter.key.length === 0) {
      return rawItems;
    }

    return orderBy(rawItems, sorter.key, sorter.order);
  }, [rawItems, sorter]);

  // === Filter ===

  const filteredItems = useMemo(() => {
    if (!filters || filters.length === 0) {
      onFiltersApplied &&
        onFiltersApplied({ list: sortedItems, isFiltered: false });
      return sortedItems;
    }

    const filteredResult = filter([...sortedItems], filters);

    onFiltersApplied &&
      onFiltersApplied({ list: filteredResult, isFiltered: true });

    return filteredResult;
  }, [sortedItems, filters, onFiltersApplied]);

  // === Pagination ===

  const { total, current, perPage, onChanged, setPerPage } = usePagination({
    total: filteredItems.length,
    isLoading,
    resetPagination,
    perPage: _perPage,
  });

  const pageItems = useMemo(
    () => filteredItems.slice(current * perPage, (current + 1) * perPage),
    [filteredItems, current, perPage]
  );

  const hasNoItems = isError || (!isLoading && filteredItems.length === 0);

  const pagination = !hasNoItems && (
    <Pagination
      hidePerPage={hidePerPage}
      setPerPage={setPerPage}
      current={current}
      total={total}
      perPage={perPage}
      styles={{
        root: {
          paddingBottom: 16,
        },
      }}
      onChange={(page) => {
        selection.setAllSelected(false);
        onChanged(page);
      }}
    />
  );

  const onRenderDetailsFooter = () =>
    hasNoItems ? (
      <NoData
        className="table-no-data"
        styles={{
          root: {
            marginTop: 16,
            marginBottom: 32,
          },
        }}
      />
    ) : null;

  const header = !!_header && (
    <Header
      data={filteredItems}
      columns={columns}
      title={_header.title}
      isExportable={_header.isExportable}
      hasSelection={hasSelection}
      exportDisabled={isLoading || isError}
    />
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (shouldOmitColumnsOpts) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const internalColumns = ref.current._columnOverrides;

    for (const { key, width } of columnsOpts) {
      if (!internalColumns[key] || !width) {
        continue;
      }

      const column = columns.find(({ key: colKey }) => colKey === key);

      if (!column) {
        continue;
      }

      const { currentWidth, calculatedWidth } = internalColumns[key];

      if (![currentWidth, calculatedWidth].filter(Boolean).includes(width)) {
        ref.current?.updateColumn(column, { width });
      }
    }
  }, [columns, columnsOpts]);

  return (
    <>
      {header}
      <ShimmeredDetailsList
        className={classnames("table", className, {
          "table--loading": isLoading,
          "table--has-header": !!header,
        })}
        items={pageItems}
        columns={columns}
        componentRef={ref}
        isSelectedOnFocus={false}
        selectionPreservedOnEmptyClick={false}
        selection={selection}
        shimmerLines={perPage}
        enableShimmer={isLoading}
        layoutMode={rest.layoutMode ?? DetailsListLayoutMode.fixedColumns}
        columnReorderOptions={columnReorderOpts}
        listProps={{
          renderedWindowsBehind: 0,
          renderedWindowsAhead: 0,
        }}
        flexMargin={1}
        onRenderDetailsFooter={onRenderDetailsFooter}
        onColumnResize={onColumnResize}
        {...(!hasSelection && { selectionMode: SelectionMode.none })}
        {...rest}
      />
      {pagination}
    </>
  );
};

export default Table;
