import { useMutation } from "@tanstack/react-query";

import {
  getApiClient,
  validateStatus,
} from "../../../../modules/core/apiClient/useApiStore";

export type ThreadAddProps = {
  machineId: string;
  text: string;
};

export const useThreadAdd = () => {
  const { mutateAsync } = useMutation((data: ThreadAddProps) =>
    getApiClient().post("meta/machinecv/v1/comments", data, { validateStatus })
  );

  return { addThreadAsync: mutateAsync };
};
