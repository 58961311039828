import BaseDialog, { DialogSize } from "../../common/Dialog";
import {
  DialogType,
  DialogFooter,
  IDialogProps,
  PrimaryButton,
  Spinner,
  DefaultButton,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { useState } from "react";
import { Company } from "../../Companies/models";
import { deleteCompany } from "../../Companies/api";
import { textStyle } from "../../../schema/Constants";

type DeleteDialogProps = IDialogProps & {
  data: Company;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

export const DeleteConfirm = ({
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: DeleteDialogProps) => {
  const [isValid, setValid] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (!isValid) {
      return;
    }

    setLoading(true);

    await deleteCompany(data).then((response) =>
      onSuccess("status" in response)
    );

    handleClose();
  };

  const validate = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = (e.target as HTMLInputElement).value;

    const parsedValue = value.trim().toLowerCase();

    setValid(data.name.toLowerCase() === parsedValue);
  };

  const handleClose = () => {
    // reset state
    setLoading(false);

    setValid(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        title: "Attention!",
        type: DialogType.close,
        onDismiss: handleClose,
      }}
      size={DialogSize.M}
    >
      <div style={textStyle}>You are about to delete selected company.</div>
      <TextField
        required
        label="Please confirm typing the name:"
        onChange={validate}
      />
      <DialogFooter>
        <PrimaryButton
          text="Delete"
          disabled={isLoading || !isValid}
          onClick={handleDelete}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton text="Close" onClick={handleClose} />
      </DialogFooter>
    </BaseDialog>
  );
};
