import type { SVGProps } from 'react';
import React from 'react';

const PreviousPageIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='7'
      height='13'
      viewBox='0 0 7 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.35294 12.854C6.25931 12.9479 6.13223 13.0007 5.99965 13.0009C5.86707 13.0011 5.73984 12.9486 5.64594 12.855L0.161942 7.39001C0.110542 7.3389 0.069752 7.27813 0.0419183 7.2112C0.0140846 7.14427 -0.000244141 7.0725 -0.000244141 7.00001C-0.000244141 6.92753 0.0140846 6.85575 0.0419183 6.78882C0.069752 6.72189 0.110542 6.66112 0.161942 6.61001L5.64594 1.14601C5.69213 1.09833 5.74736 1.06032 5.8084 1.0342C5.86944 1.00809 5.93506 0.994391 6.00145 0.99391C6.06784 0.993429 6.13366 1.00617 6.19507 1.0314C6.25648 1.05663 6.31225 1.09383 6.35913 1.14084C6.40601 1.18785 6.44305 1.24373 6.46811 1.30521C6.49316 1.36669 6.50572 1.43255 6.50505 1.49893C6.50438 1.56532 6.4905 1.63091 6.46421 1.69187C6.43792 1.75284 6.39976 1.80795 6.35194 1.85401L1.18794 7.00001L6.35194 12.147C6.44581 12.2406 6.49865 12.3677 6.49884 12.5003C6.49902 12.6329 6.44654 12.7601 6.35294 12.854Z'
        fill='#201F1E'
      />
    </svg>
  );
};

export default PreviousPageIcon;
