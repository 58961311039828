import {
  DefaultButton,
  DialogFooter,
  DialogType,
  IconButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Link,
} from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";

import { notification } from "../common/Notification";
import Uploader from "../common/Uploader";
import BaseDialog from "../common/Dialog";
import Table, { TableProps, Column } from "../common/Table";

import { formatFileSize } from "../../schema/Utils";

type GetColumnsOpts = {
  onRemove: (id: string) => void;
};

const getColumns = ({ onRemove }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: "Name",
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: ({ name }) => {
      // TODO: should we use Link from react-router-dom?
      return (
        <Link underline href="#" style={{ fontSize: 13 }}>
          {name}
        </Link>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: "Size",
    minWidth: 100,
    isSortable: true,
    onRender: ({ size, isValid }) => {
      return (
        <span
          style={{ fontSize: 13, color: isValid ? "currentColor" : "#ff6a66" }}
        >
          {isValid ? formatFileSize(size) : "Too large"}
        </span>
      );
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: "Actions",
    minWidth: 100,
    onRender: ({ id }) => (
      <>
        <IconButton
          iconProps={{ iconName: "Delete" }}
          title="Delete"
          ariaLabel="Delete"
          onClick={() => onRemove(id)}
        />
      </>
    ),
  },
];

export type AddDocumentBase = {
  description: string;
};

export type UploadedFile<T = AddDocumentBase> = AcceptedFile & T;

const dialogStyles = {
  root: { height: "75%" },
};

type AcceptedFile = {
  id: string;
  name: string;
  type: string;
  size: number;
  isValid: boolean;
  dateAdded: string;
  file: File;
};

type AddDocumentDialogProps = {
  hidden: boolean;
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit: (files: UploadedFile[]) => void;
  extension: string;
};

const AddDocumentDialogOneFile = ({
  hidden,
  isSubmitting,
  onClose,
  onSubmit,
  extension,
}: AddDocumentDialogProps) => {
  const [localFiles, setLocalFiles] = useState<AcceptedFile[]>([]);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog-machine-metadata",
        version: 1,
      },
      items: localFiles.sort((a, b) => (a.name > b.name ? 1 : -1)),
      hasSelection: false,
      columns: getColumns({
        onRemove: (fileId) => {
          setLocalFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
      }),
      hidePerPage: true,
    }),
    [localFiles]
  );

  useEffect(() => {
    if (hidden) {
      setLocalFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden]);

  const onFormSubmit = (data) => {
    const files = localFiles.map((file) => ({ ...file, ...data }));
    onSubmit(files);
  };

  const isDisabled = useMemo(
    () => localFiles.filter(({ isValid }) => isValid).length === 0,
    [localFiles]
  );

  const onUpload = (accepted: AcceptedFile[]) => {
    const aux = [...accepted];
    const unique = aux.filter(
      (obj, index) =>
        aux.findIndex(
          (item) => item.name === obj.name && item.size === obj.size
        ) === index
    );
    !(unique.length === aux.length) &&
      notification.warning("Some file(s) are already added");
    setLocalFiles(unique);
  };

  return (
    <BaseDialog
      hidden={hidden}
      styles={dialogStyles}
      dialogContentProps={{
        type: DialogType.close,
        title: "Add new document",
        onDismiss: onClose,
      }}
    >
      <Uploader onChange={onUpload} accept={extension} multiple={false} />
      <Table {...tableProps} />
      <DialogFooter>
        <PrimaryButton
          text="Upload"
          onClick={onFormSubmit}
          disabled={isDisabled || isSubmitting}
          onRenderIcon={() =>
            isSubmitting ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton text="Cancel" onClick={onClose} />
      </DialogFooter>
    </BaseDialog>
  );
};

export default AddDocumentDialogOneFile;
