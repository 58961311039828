/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Label, p50, p100 } from '@amcharts/amcharts5';
import { format } from 'date-fns';

import { getClosestIndex } from '../../../../utils';

import type { XYChartRef } from '..';

interface DefaultTooltipOpts {
  ref: XYChartRef;
  text?: string;
  type?: 'number' | 'date';
}

export const createDefaultTooltip = ({ ref, text, type = 'number' }: DefaultTooltipOpts) => {
  const { root, chart } = ref;

  const cursor = chart.get('cursor');

  const tooltipLabel = Label.new(root, {
    ariaLabel: 'chart-tooltip',
    html: '',
    centerX: p50,
    centerY: p100,
    x: p50,
    y: p100,
    visible: false,
  });

  chart.plotContainer.children.push(tooltipLabel);

  const updateTooltip = (xValue: number, groupedValues: Record<string, string[]>) => {
    let tooltipLabelText = '';

    Object.entries(groupedValues).forEach(([unit, values]) => {
      const formattedValues = values.map((value) => {
        return `
        <span class="chart-tooltip__content">
          ${parseFloat((+value).toFixed(4))}
        </span>`;
      });

      tooltipLabelText += `
      <div class="chart-tooltip__label">
        <strong style="margin-right: 3px;">(${unit}):</strong>
        ${formattedValues}
      </div>`;
    });

    const tooltipValue =
      type === 'date' ? format(new Date(xValue), 'dd MMM yyyy HH:mm') : xValue.toFixed(4);

    const htmlContent = `<div class="chart-tooltip">${tooltipLabelText}  <strong>(${text}):</strong> ${tooltipValue}</div>`;

    tooltipLabel.set('html', htmlContent);
    tooltipLabel.set('visible', true);
  };

  cursor?.events.on('cursormoved', () => {
    const xAxis = chart.xAxes.getIndex(0);
    const selectedXPosition = cursor?.getPrivate('positionX') as number;
    // @ts-ignore
    const xValue = xAxis?.positionToValue(xAxis?.toAxisPosition(selectedXPosition));
    const groupedValues: Record<string, string[]> = {};

    chart?.series?.each((serie, index) => {
      const yAxis = serie.get('yAxis');

      let unit = '' as string;

      // @ts-ignore
      for (const item of yAxis?.children || []) {
        if (item instanceof Label && item.get('ariaLabel') === 'yAxisLabel') {
          unit = item.get('ariaValueText') || '';
          break;
        }
      }

      const dataItemIndex = chart?.series
        ?.getIndex(index)
        // @ts-ignore
        ?.dataItems?.findIndex((item) => item?.get('valueX') >= xValue);

      if (dataItemIndex !== -1) {
        groupedValues[unit] = groupedValues[unit] ?? [];

        const closestIdx = getClosestIndex({
          foundIndex: dataItemIndex as number,
          threshold: xValue,
          length: chart?.series?.getIndex(index)?.dataItems.length ?? 0,
          getItem: (idx) => chart?.series?.getIndex(index)?.dataItems?.at(idx)?.get('valueX') ?? -1,
        });

        groupedValues[unit].push(
          // @ts-ignore
          `${chart?.series?.getIndex(index)?.dataItems?.at(closestIdx)?.get('valueY')}`,
        );
      }
    });

    updateTooltip(xValue, groupedValues);
  });

  cursor?.events.on('cursorhidden', () => {
    tooltipLabel.set('visible', false);
  });
};

export default createDefaultTooltip;
