import useSelectedDataPointsStore from './useSelectedDataPointsStore';

const useDataPoints = () => {
  const { selectedDataPoints } = useSelectedDataPointsStore(
    (store) => ({
      selectedDataPoints: store.selectedDataPoints,
    }),
  );

  return (signalId: string | undefined) => {
    const dataPoint = selectedDataPoints.find((dataPoint) => dataPoint?.signal === signalId);
    return dataPoint?.dataPoints || [];
  };
};

export const useDataPointsWithTimestamps = () => {
  const { selectedDataPoints, selectedDataPointsTimestamps } = useSelectedDataPointsStore(
    (store) => ({
      selectedDataPoints: store.selectedDataPoints,
      selectedDataPointsTimestamps: store.selectedDataPointsTimestamps,
    }),
  );

  return (signalId: string | undefined) => {
    const dataPoint = selectedDataPoints.find((dataPoint) => dataPoint?.signal === signalId);
    return (
      dataPoint?.dataPoints.map((item) => ({
        id: item,
        timestamp: selectedDataPointsTimestamps[item],
      })) || []
    );
  };
};

export default useDataPoints;
