import type { SVGProps } from 'react';
import React from 'react';

const MachineIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='#ffffff'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M3.703 4.04185C3.5818 3.98801 3.44417 3.98453 3.32039 4.03216C3.19662 4.0798 3.09684 4.17465 3.043 4.29585C2.98916 4.41706 2.98568 4.55469 3.03331 4.67846C3.08094 4.80223 3.1758 4.90202 3.297 4.95585L7.5 6.82385V11.4999C7.5 11.6325 7.55268 11.7596 7.64645 11.8534C7.74021 11.9472 7.86739 11.9999 8 11.9999C8.13261 11.9999 8.25979 11.9472 8.35355 11.8534C8.44732 11.7596 8.5 11.6325 8.5 11.4999V6.82385L12.703 4.95585C12.763 4.9292 12.8172 4.89098 12.8624 4.84338C12.9077 4.79579 12.9431 4.73975 12.9667 4.67846C12.9903 4.61717 13.0016 4.55184 12.9999 4.48619C12.9982 4.42055 12.9837 4.35587 12.957 4.29585C12.9303 4.23584 12.8921 4.18166 12.8445 4.13642C12.7969 4.09118 12.7409 4.05575 12.6796 4.03216C12.6183 4.00858 12.553 3.99729 12.4873 3.99896C12.4217 4.00062 12.357 4.0152 12.297 4.04185L8 5.95185L3.703 4.04185ZM9.3 0.480853C8.46548 0.147007 7.53452 0.147007 6.7 0.480853L0.943 2.78385C0.664751 2.89513 0.426212 3.08721 0.258135 3.33531C0.0900584 3.58342 0.000149762 3.87618 2.09356e-08 4.17585V11.8219C-5.00639e-05 12.1217 0.0897655 12.4147 0.257855 12.663C0.425944 12.9113 0.664593 13.1035 0.943 13.2149L6.7 15.5179C7.53452 15.8517 8.46548 15.8517 9.3 15.5179L15.057 13.2149C15.3354 13.1035 15.5741 12.9113 15.7421 12.663C15.9102 12.4147 16 12.1217 16 11.8219V4.17585C15.9999 3.87618 15.9099 3.58342 15.7419 3.33531C15.5738 3.08721 15.3352 2.89513 15.057 2.78385L9.3 0.479853V0.480853ZM7.072 1.40885C7.66804 1.17043 8.33296 1.17043 8.929 1.40885L14.686 3.71185C14.7787 3.749 14.8581 3.81305 14.9141 3.89574C14.9701 3.97844 15 4.076 15 4.17585V11.8219C15.0002 11.9219 14.9703 12.0197 14.9144 12.1026C14.8584 12.1854 14.7788 12.2496 14.686 12.2869L8.928 14.5899C8.33225 14.828 7.66775 14.828 7.072 14.5899L1.314 12.2869C1.22115 12.2496 1.1416 12.1854 1.08563 12.1026C1.02965 12.0197 0.999823 11.9219 1 11.8219V4.17585C1.00002 4.076 1.02994 3.97844 1.08591 3.89574C1.14187 3.81305 1.22131 3.749 1.314 3.71185L7.072 1.40985V1.40885Z' />
    </svg>
  );
};

export default MachineIcon;
