import React from 'react';

const SchemeIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='17'
      fill='none'
      viewBox='0 0 18 17'
      {...props}
    >
      <path
        fill='currentColor'
        d='M8.75 4.5a.75.75 0 100-1.5.75.75 0 000 1.5zm3 1a.75.75 0 100-1.5.75.75 0 000 1.5zm2.5 1.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm-.75 3.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.25 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.697.992C9.943-.423 7.143-.288 4.77 1.166c-1.21.742-2.517 2.303-3.254 3.893-.37.8-.617 1.646-.614 2.434.004.801.268 1.566.938 2.112.61.498 1.142.748 1.656.773.518.026.922-.184 1.238-.37.07-.041.135-.081.197-.119.242-.147.44-.268.69-.346.28-.09.637-.122 1.155.036.19.058.306.144.386.244.086.106.155.254.206.466.052.213.08.464.1.76.009.11.016.237.023.369.012.192.023.397.039.586.054.67.17 1.436.574 2.132.414.713 1.1 1.303 2.203 1.68 1.602.548 3.066.103 4.206-.8 1.126-.891 1.956-2.24 2.37-3.589 1.316-4.273-.21-8.393-4.186-10.435zM5.292 2.019C7.357.754 9.784.619 12.24 1.881c3.507 1.802 4.873 5.4 3.688 9.252-.366 1.19-1.094 2.354-2.036 3.1-.93.735-2.043 1.053-3.261.637-.904-.31-1.384-.757-1.663-1.236-.288-.497-.39-1.078-.442-1.712-.015-.189-.025-.36-.035-.534a28.37 28.37 0 00-.025-.41 5.672 5.672 0 00-.126-.925 2.17 2.17 0 00-.4-.86 1.748 1.748 0 00-.872-.57c-.702-.215-1.265-.187-1.75-.034-.375.12-.698.317-.946.47l-.146.087c-.285.169-.472.244-.68.234-.213-.01-.543-.118-1.074-.55-.385-.314-.567-.763-.57-1.34-.002-.591.186-1.288.52-2.01.675-1.453 1.863-2.844 2.87-3.461z'
      ></path>
    </svg>
  );
};

export default SchemeIcon;
